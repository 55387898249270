import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
} from "@angular/core";

import { constEnv } from "common/constants.env";
import { AlertService } from "ngx-alerts";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { DOCUMENT } from "@angular/common";
import { TitleService } from "common/services/title.service";
import { IsLoadingService } from "@service-work/is-loading";
import { HelpSupportService } from "common/services/store/helpSupport.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-add",
  templateUrl: "./add.component.html",
  styleUrls: ["./add.component.css"],
})
export class AddComponent implements OnInit {
  addForm: FormGroup;
  loading = false;
  submitted = false;
  errorMessage: string = "";
  cs = constEnv;

  @ViewChild("profileFileInput", { static: true }) profileFileInput: ElementRef;
  attachmentFileData: File = null;

  listUrl = `${this.cs.STORE_URL}/support`;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private formBuilder: FormBuilder,
    private isLoadingService: IsLoadingService,
    private router: Router,
    private alert: AlertService,
    private titleService: TitleService,
    private hs: HelpSupportService
  ) {}

  ngOnInit() {
    this.document.body.classList.add("add_support-ticket");

    this.createForm();
  }

  createForm() {
    this.addForm = this.formBuilder.group({
      // Email: ['', Validators.required],
      // PhoneNumber: ['', Validators.required],
      Subject: ["", Validators.required],
      Description: ["", Validators.required],
      Attachment: [""],
    });
  }

  getErrorEmail() {
    return this.addForm.get("Email").hasError("required")
      ? ""
      : this.addForm.get("Email").hasError("pattern")
      ? this.titleService.getTranslatedString("EmailInValid")
      : "";
  }

  FileProgress(profileFileInput: any) {
    const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
    const maxSizeMB = 5;

    const selectedFile = profileFileInput.target.files[0];
    this.errorMessage = "";
    if (selectedFile && allowedFileTypes.includes(selectedFile.type)) {
      if (selectedFile.size <= maxSizeMB * 1024 * 1024) {
        this.attachmentFileData = selectedFile as File;
        this.errorMessage = "";
      } else {
        this.errorMessage = "File size exceeds the maximum limit of 5 MB.";
      }
    } else {
      this.errorMessage =
        "Invalid file type. Please select a PNG, JPG, or JPEG image.";
    }
  }

  isFormValid(): boolean {
    if (this.addForm.invalid) {
      return false;
    }

    if (this.errorMessage.trim() === "") {
      return false;
    }

    return true;
  }

  onSubmit() {
    if (this.addForm.invalid) {
      return;
    }

    if (this.errorMessage) {
      return;
    }
    this.loading = true;
    this.isLoadingService.add();

    const formData = new FormData();
    formData.append("Email", this.addForm.value.Email);
    formData.append("PhoneNumber", this.addForm.value.PhoneNumber);
    formData.append("Subject", this.addForm.value.Subject);
    formData.append("Description", this.addForm.value.Description);
    formData.append("Attachment", this.attachmentFileData);

    this.hs.add(formData).subscribe(
      (data) => {
        this.alert.success(data["message"]);
        this.submitted = false;
        this.addForm.reset();
        this.loading = false;
        this.router.navigate([`${this.listUrl}`]);
      },
      (err) => {
        const msg =
          typeof err.error.message !== "undefined" ? err.error.message : "";
        if (msg) {
          this.alert.danger(msg);
        }
        this.loading = false;
        this.isLoadingService.remove();
      }
    );
  }
}
