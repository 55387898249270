import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { constEnv } from 'common/constants.env';
import { AuthGuard } from 'common/_helpers/auth.guard';

import { PaymentActionComponent } from './payment-action/payment-action.component';

const baseUrl = constEnv.BASE_URL;

const routes: Routes = [
  {
    path: 'payment',
    children: [
       { path: 'success', component: PaymentActionComponent, data: {page: 'success'} },
       { path: 'decline', component: PaymentActionComponent, data: {page: 'decline'} },
       { path: 'cancel', redirectTo: `/cart?o=cancel`, pathMatch: 'full' },
       { path: '', redirectTo: '/home', pathMatch: 'full' },
       { path: '**', redirectTo: '/home', pathMatch: 'full' }
    ],
 },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PaymentRoutingModule { }
