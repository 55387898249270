import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { constEnv } from "common/constants.env";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService } from "@full-fledged/alerts";
// import { AlertService } from 'common/services/alert.service';
import { DeliveryCompanyService } from "common/services/delivery-company.service";
import { first } from "rxjs/operators";
import { TitleService } from "common/services/title.service";
import { IsLoadingService } from "@service-work/is-loading";

@Component({
  selector: "app-dc-forgot-password",
  templateUrl: "./dc-forgot-password.component.html",
  styleUrls: ["./dc-forgot-password.component.css"],
})
export class DcForgotPasswordComponent implements OnInit, OnDestroy {
  FGForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  constantConfig = constEnv;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    // public constantConfig: ConstansService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private dcService: DeliveryCompanyService,
    private titleService: TitleService,
    private isLoadingService: IsLoadingService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Forgot_Password");
    this.document.body.classList.add("forgot-password-body");

    this.FGForm = this.formBuilder.group({
      email: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern(
            "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"
          ),
        ])
      ),
    });
  }

  ngOnDestroy(): void {
    this.document.body.classList.remove("forgot-password-body");
  }

  get f() {
    return this.FGForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    // this.alertService.clear();

    // stop here if form is invalid
    if (this.FGForm.invalid) {
      return;
    }

    this.loading = true;
    this.isLoadingService.add();

    this.dcService
      .forgotPassword(this.FGForm.value.email)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data["status"]) {
            this.alertService.success(data["message"]);
            this.submitted = false;
            this.FGForm.reset();
          } else {
            this.alertService.danger(data["message"]);
          }
          this.isLoadingService.remove();
          this.loading = false;
          this.router.navigate([
            `${this.constantConfig.DELIVERY_COMPANY_URL}/login`,
          ]);
        },
        (err) => {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          if (msg) {
            this.alertService.danger(msg);
          }
          this.isLoadingService.remove();
          this.loading = false;
        }
      );
  }

  getErrorEmail() {
    return this.FGForm.get("email").hasError("required")
      ? ""
      : this.FGForm.get("email").hasError("pattern")
      ? "EmailInValid"
      : // ? this.titleService.getTranslatedString("EmailInValid")
        "";
  }
}
