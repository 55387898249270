import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ModuleWithProviders } from "@angular/core";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";

import { JwtInterceptor } from "common/_helpers/jwt.interceptor";
import { ErrorInterceptor } from "common/_helpers/error.interceptor";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthPagesModule } from "./auth-pages/auth-pages.module";
import { AlertModule } from "ngx-alerts";

import { PagesModule } from "./pages/pages.module";
import { BsModalRef } from "ngx-bootstrap/modal";

const appProviders = [
  BsModalRef,
  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AuthPagesModule,
    PagesModule,
    AlertModule.forRoot({ maxMessages: 1, timeout: 5000, position: "right" }),
  ],
  providers: appProviders,
  bootstrap: [AppComponent],
})
export class AppModule {}

@NgModule({})
export class StoreSharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AppModule,
      providers: appProviders,
    };
  }
}
