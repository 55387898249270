import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { constEnv } from "common/constants.env";
import { NavService } from "common/services/nav.service";
import { NavItem } from "common/_models/nav-item";

import { GeneralService } from "common/services/general.service";
import { AuthenticationService } from "common/services/authentication.service";
import { LanguageService } from "common/services/language.service";
import { IsLoadingService } from "@service-work/is-loading";
import { BsModalRef, ModalDirective } from "ngx-bootstrap";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AlertService } from "ngx-alerts";
import { StoreUserService } from "common/services/store/storeUser.service";
import { first } from "rxjs/operators";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit, AfterViewInit {
  prifilePic = `${constEnv.IMAGE_PATH}/assets/profile-photo.png`;

  config = constEnv;

  currentUserProfile;

  @ViewChild("appDrawer", { static: false }) appDrawer: ElementRef;

  navItems: NavItem[] = [
    {
      displayName: "Dashboard",
      iconName: "home",
      route: `${this.config.STORE_URL}/dashboard`,
    },
    {
      displayName: "STORE_Products",
      iconName: "library_books",
      route: `${this.config.STORE_URL}/product/lists`,
      children: [
        {
          displayName: "Lists",
          iconName: "dns",
          route: `${this.config.STORE_URL}/product/lists`,
        },
        // { displayName: 'SubCategory', iconName: 'category', route: `${this.config.STORE_URL}/product/sub-category` }
        {
          displayName: "Specification",
          iconName: "category",
          route: `${this.config.STORE_URL}/product/specification`,
        },
        {
          displayName: "Last Minute Deals",
          iconName: "local_offer",
          route: `${this.config.STORE_URL}/product/last-minute-deal`,
        },
      ],
    },
    {
      displayName: "Orders",
      iconName: "local_mall",
      route: `${this.config.STORE_URL}/order/lists`,
    },
    {
      displayName: "Drivers",
      iconName: "person",
      route: `${this.config.STORE_URL}/driver/lists`,
    },
    {
      displayName: "DRIVER_Vehicles",
      iconName: "delivery_dining",
      route: `${this.config.STORE_URL}/vehicle`,
    },
    {
      displayName: "Preferred Delivery Company",
      iconName: "local_shipping",
      route: `${this.config.STORE_URL}/prefrred-delivery-company`,
    },
    {
      displayName: "Zone",
      iconName: "fence",
      route: `${this.config.STORE_URL}/delivery-zone`,
    },
    {
      displayName: "ShippingRate",
      iconName: "corporate_fare",
      route: `${this.config.STORE_URL}/shipping-rate`,
    },
    // { displayName: 'GodsView', iconName: 'person_pin', route: `${this.config.STORE_URL}/aerial-view` },
    {
      displayName: "STORE_Earnings",
      iconName: "account_balance_wallet",
      route: `${this.config.STORE_URL}/earnings`,
    },
    {
      displayName: "Potential List",
      iconName: "favorite",
      route: `${this.config.STORE_URL}/potential-list`,
    },
    // { displayName: 'Wishlist', iconName: 'favorite', route: `${this.config.STORE_URL}/wishlists` },
    {
      displayName: "PromoCode",
      iconName: "local_offer",
      route: `${this.config.STORE_URL}/promo-code`,
    },
    // { displayName: 'Jobs', iconName: 'dashboard', route: '' },
    // { displayName: 'Designer\'s Jobs', iconName: 'color_lens', route: '' },
    // { displayName: 'Offers', iconName: 'card_giftcard', route: `${this.config.STORE_URL}/offers` },
    {
      displayName: "Return/Replace Requests",
      iconName: "assignment_return",
      route: `${this.config.STORE_URL}/return-request`,
    },
    {
      displayName: "Cancel Requests",
      iconName: "cancel_presentation",
      route: `${this.config.STORE_URL}/cancel-request`,
    },
    // { displayName: 'Messages', iconName: 'markunread', route: '' },
    {
      displayName: "STORE_Feedbacks",
      iconName: "grade",
      route: `${this.config.STORE_URL}/feedback`,
    },
    {
      displayName: "STORE_Notifications",
      iconName: "notifications",
      route: `${this.config.STORE_URL}/notifications`,
    },
    // { displayName: 'Reports', iconName: 'description', route: '' },
    {
      displayName: "STORE_Settings",
      iconName: "settings",
      route: `${this.config.STORE_URL}/settings/store`,
    },
  ];

  modalRef: BsModalRef;

  @ViewChild("openPhotoModal", { static: false })
  openPhotoModal: ModalDirective;
  showOpenPhotoModal: boolean = false;

  changeProfilePicFrm: FormGroup;
  @ViewChild("fileInput", { static: true }) fileInput: ElementRef;

  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;

  constructor(
    private navService: NavService,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private langService: LanguageService,
    private isLoadingService: IsLoadingService,
    private gs: GeneralService,
    private alertService: AlertService,
    private fb: FormBuilder,
    private storeUserService: StoreUserService
  ) {
    this.changeProfilePicFrm = this.fb.group({
      profilePhoto: null,
    });
  }

  ngOnInit() {
    this.currentUserProfile =
      this.authenticationService.currentUserProfileValue;

    const defaultSetting = this.gs.getDefaultSettings();
    const deliveryHandledId =
      typeof defaultSetting["DeliveryHandleId"] !== "undefined"
        ? defaultSetting["DeliveryHandleId"]
        : 0;

    if (parseInt(deliveryHandledId) === 0) {
      this.navItems = this.navItems.filter(
        (el) => el.displayName !== "Zone" && el.displayName !== "ShippingRate"
      );
    }
  }

  ngAfterViewInit() {
    this.navService.appDrawer = this.appDrawer;
  }

  logout() {
    this.isLoadingService.add();
    // this.langService.changeLanguage(this.langService.defaultLanguage);
    this.authenticationService.logout();
    const loginURL = `${this.config.STORE_URL}/login`;

    const settingLang = this.gs.getSettings("default_language");

    const changeLangCode =
      settingLang !== "" ? settingLang : this.langService.defaultLanguage;

    this.langService.getAvailableLanguages().map((lang) => {
      if (changeLangCode === lang.lang_code) {
        this.langService._setLanguage(lang.lang_code);
        localStorage.setItem("defaultLang", JSON.stringify(lang));
      }
    });

    this.langService
      .getLanguageTranslation(this.langService.getCurrentLanguage())
      .subscribe((translationsJSON) => {
        this.langService.setLanguageTranslation(translationsJSON);
        window.location.href = loginURL;
      });
  }

  openModal() {
    this.showOpenPhotoModal = true;
  }

  onHidden() {
    this.showOpenPhotoModal = false;
    this.changeProfilePicFrm.reset();
    this.fileData = null;
    this.previewUrl = null;
    this.fileUploadProgress = null;
  }

  fileProgress(fileInput: any) {
    this.fileData = fileInput.target.files[0] as File;
    const allowedImageTypes = ["image/jpeg", "image/jpg", "image/png"];
    if (allowedImageTypes.includes(this.fileData.type)) {
      this.preview();
    } else {
      this.alertService.danger("Please upload only JPG, JPEG, or PNG image.");
      fileInput.target.value = null;
      this.fileData = null;
      this.previewUrl = null;
      this.changeProfilePicFrm.get("profilePhoto").setValue(null);
    }
  }

  preview() {
    // Show preview
    const mimeType = this.fileData.type;
    const reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
      this.changeProfilePicFrm.get("profilePhoto").setValue({
        filename: this.fileData.name,
        filetype: this.fileData.type,
        value: this.previewUrl.split(",")[1],
      });
    };
  }

  onSubmit() {
    this.isLoadingService.add();
    const formData = new FormData();
    formData.append("postedfile", this.fileData);
    this.storeUserService
      .changeProfilePhoto(formData)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data["status"] === true) {
            this.alertService.success(data["message"]);
            this.showOpenPhotoModal = false;
            location.reload();
          } else {
            this.alertService.danger(data["message"]);
          }
          this.isLoadingService.remove();
        },
        (err) => {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          this.alertService.danger(msg);
          this.isLoadingService.remove();
        }
      );
  }
}
