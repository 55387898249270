import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EarningsRoutingModule } from "./earnings-routing.module";

import { ReactiveFormsModule } from "@angular/forms";

import { TranslateModule } from "@ngx-translate/core";
import { ComponentModule } from "common/_components/components.module";
import { MaterialModule } from "common/modules/material.module";
import { ModalModule } from "ngx-bootstrap/modal";

import { DataTablesModule } from "angular-datatables";
import { EarningsComponent } from "./earnings/earnings.component";
import { TemplateDefaultModule } from "../../shared/template-default/template-default.module";
import { MyDirectiveModule } from "common/directives/my-directive.module";

import { PaginationModule } from "ngx-bootstrap/pagination";

import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { PipeModule } from "common/_pipe/pipe.module";

@NgModule({
  declarations: [EarningsComponent],
  imports: [
    CommonModule,
    EarningsRoutingModule,
    ReactiveFormsModule,
    ComponentModule,
    TranslateModule,
    DataTablesModule,
    TemplateDefaultModule,
    MaterialModule,
    MyDirectiveModule,
    ModalModule,
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PipeModule,
  ],
})
export class EarningsModule {}
