import { Component, OnInit } from "@angular/core";
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from "@angular/router";
import { IsLoadingService } from "@service-work/is-loading";
import { constEnv } from "common/constants.env";
import { SiteDesignerService } from "common/services/site/siteDesigner.service";
import { TitleService } from "common/services/title.service";
import { AlertService } from "ngx-alerts";
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { filter } from "rxjs/operators";
import { Paging } from "../../category/category.component";

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.css"],
})
export class ListComponent implements OnInit {
  config = constEnv;
  pageTitle = "";
  pagination: Paging;
  itemPerPage = 12;

  lists: DesignerPick[] = null;
  navSubscription;

  params = {
    PageNo: 0,
    PageSize: this.itemPerPage,
  };

  designerCatalogUrl = this.config.BASE_URL + "/designer-catalog/";

  constructor(
    private isLoadingService: IsLoadingService,
    private titleService: TitleService,
    private alert: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private designerCatalogService: SiteDesignerService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("DesignerCatalog");

    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }

    this.pagination = {
      totalCount: 0,
      totalRowsAfterFiltering: 0,
      pageSize: this.itemPerPage,
      currentPage: 0,
      totalPages: 0,
      previousPage: "",
      nextPage: "",
    };

    this.navSubscription = this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError
        )
      )
      .subscribe((event) => {
        // If it's the start of navigation, `add()` a loading indicator
        if (event instanceof NavigationStart) {
          this.isLoadingService.add();
          return;
        }

        if (event instanceof NavigationEnd) {
          this.getDesignerCatalogLists();
        }

        // Else navigation has ended, so `remove()` a loading indicator
        this.isLoadingService.remove();
      });

    this.route.queryParams.subscribe((param) => {
      this.params.PageNo =
        typeof param.PageNo !== "undefined" ? param.PageNo : "";
      this.params.PageSize =
        typeof param.PageSize !== "undefined"
          ? param.PageSize
          : this.itemPerPage;
    });

    this.getDesignerCatalogLists();
  }

  ngOnDestroy() {
    this.navSubscription.unsubscribe();
  }

  getDesignerCatalogLists() {
    this.isLoadingService.add();

    this.designerCatalogService.getDesignerCatalogList(this.params).subscribe(
      (data) => {
        let res = data as DesignerPicksResponse;
        if (res.status === true) {
          this.lists = res.data as DesignerPick[];

          this.pagination = JSON.parse(res.paging) as Paging;
        } else {
          this.alert.danger(res.message);
        }
        this.isLoadingService.remove();
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          // this.alert.danger(err.error.message);
        }
        this.lists = [];
        this.isLoadingService.remove();
      }
    );
  }

  pageChange(paging: PageChangedEvent) {
    paging.page = paging.page > 0 ? paging.page - 1 : 0;
    paging.itemsPerPage = paging.itemsPerPage
      ? paging.itemsPerPage
      : this.itemPerPage;

    this.router.navigate([], {
      queryParams: {
        PageNo: paging.page,
        PageSize: paging.itemsPerPage,
      },
      queryParamsHandling: "merge",
    });
  }
}

interface DesignerPicksResponse {
  status: boolean;
  message: string;
  data: DesignerPick[];
  paging: string;
}

interface DesignerPick {
  catalogId: number;
  catalogName: string;
  catalogDescription: string;
  spaceType: string;
  catalogCoverImage: string;
  designerId: number;
  designerName: string;
  designerProfileImage: string;
  likeCount: number;
  isLiked: boolean;
}
