import { Directive, ElementRef, HostListener, Renderer2 } from "@angular/core";

@Directive({
  selector: "[appDropdownToggle]",
})
export class DropdownToggleDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener("click", ["$event"])
  onClick(event: Event): void {
    event.preventDefault();
    console.log("object");

    setTimeout(() => {
      const $el = this.el.nativeElement;
      const $parent = this.findClosestParent($el, ".dropdown-menu");
      console.log($parent);
      // Toggle class on the clicked element
      this.renderer.addClass($el, "active-dropdown");

      // Close other dropdowns if necessary
      if (!$el.nextElementSibling.classList.contains("show")) {
        //   $el
        //     .closest(".dropdown-menu")
        //     .querySelectorAll(".show")
        //     .forEach((element: HTMLElement) => {
        //       element.classList.remove("show");
        //     });
      }

      // Toggle the 'show' class on the submenu
      const $subMenu = $el.nextElementSibling;
      console.log($subMenu);
      this.renderer.addClass($subMenu, "show");

      // Toggle 'show' class on the parent list item
      this.renderer.addClass($el.parentElement, "show");

      // Adjust position if not in navbar
      if ($parent && !$parent.parentElement.classList.contains("navbar-nav")) {
        const offsetTop = $el.offsetTop;
        const offsetLeft = $parent.offsetWidth - 4;
        this.renderer.setStyle($el.nextElementSibling, "top", `${offsetTop}px`);
        this.renderer.setStyle(
          $el.nextElementSibling,
          "left",
          `${offsetLeft}px`
        );
      }
    }, 1000);
    // return false;
  }

  private findClosestParent(
    element: HTMLElement,
    selector: string
  ): HTMLElement | null {
    let currentElement: HTMLElement | null = element;
    while (currentElement) {
      if (currentElement.matches(selector)) {
        return currentElement;
      }
      currentElement = currentElement.parentElement;
    }
    return null;
  }
}
