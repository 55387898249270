import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DriversRoutingModule } from "./drivers-routing.module";

import { AlertModule } from "ngx-alerts";
import { ComponentModule } from "common/_components/components.module";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "common/modules/material.module";
import { DataTablesModule } from "angular-datatables";
import { TemplateDefaultModule } from "../../shared/template-default/template-default.module";
import { ModalModule } from "ngx-bootstrap/modal";

import { Ng2TelInputModule } from "ng2-tel-input";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { NgSelectModule } from "@ng-select/ng-select";
import { AgmCoreModule } from "@agm/core";

import { ListsComponent } from "./lists/lists.component";
import { DocumentsComponent } from "./documents/documents.component";
import { ActionsComponent } from "./actions/actions.component";
import { constEnv } from "common/constants.env";

@NgModule({
  declarations: [ListsComponent, DocumentsComponent, ActionsComponent],
  imports: [
    CommonModule,
    AlertModule.forRoot({ maxMessages: 1, timeout: 2500, position: "right" }),
    ComponentModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot(),
    ModalModule.forRoot(),
    MaterialModule,
    TemplateDefaultModule,
    DataTablesModule,
    DriversRoutingModule,
    Ng2TelInputModule,
    NgxIntlTelInputModule,
    NgSelectModule,
    // AgmCoreModule.forRoot({
    //   apiKey: constEnv.googleMapAPIKey,
    //   libraries: ["places"],
    // }),
    ComponentModule,
  ],
})
export class DriversModule {}
