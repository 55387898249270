export class HelpSupport {

    acceptedDate: string;
    backgroundColor: string;
    customerAddress: string;
    customerEmail: string;
    customerImage: string;
    customerName: string;
    customerPhone: string;
    
    id: number;
    isAccepted: boolean;
    isClosed: boolean;
    itemStatus: string;
    lastUpdated: string;
    productImage: string;
    productName: string;
    statusColor: string;
    statusName: string;
    subject: string;
    ticketId: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
