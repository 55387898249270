import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { constEnv } from 'common/constants.env';
import { map } from 'rxjs/operators';
import { LanguageService } from '../language.service';

@Injectable({ providedIn: 'root' })
export class SiteGeneralService {
    configConstant = constEnv;

    constructor(private http: HttpClient, private ls: LanguageService) {
    }

    getHome() {
        const langId = this.ls.getCurrentLanguageId();
        const param = new HttpParams()
        .set('LanguageId', langId);

        return this.http.get(`${this.configConstant.apiSiteURL}/home`,{
            params: param
        })
            .pipe(map(data => {
                return data;
            }));
    }

}
