import { Component, OnInit, ViewChild } from '@angular/core';

import { constEnv } from 'common/constants.env';
import { DataTableDirective } from 'angular-datatables';
import { TitleService } from 'common/services/title.service';
import { AlertService } from 'ngx-alerts';
import { IsLoadingService } from '@service-work/is-loading';
import { FeedbackService } from 'common/services/store/feedback.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  config = constEnv;

  hasLists = false;
  lists: FeedbackList[] = [];
  counters: FeedbackCounter = null;
  feedbackProgress: FeedbackProgress[] = null;

  feedbackUrl = `${this.config.STORE_URL}/feedback`;
  feedbackType: number;

  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};

  constructor(
    private titleService: TitleService,    
    private alert: AlertService,
    private isLoadingService: IsLoadingService,
    private feedback: FeedbackService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.titleService.setTitle('STORE_Feedbacks');

    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }

    const type = this.route.snapshot.params.type
    // this.setFeedbackType( (typeof type !== 'undefined') ? type : 3 );
    this.feedbackType = (typeof type !== 'undefined') ? type : 3;
    this.createDataTable();
  }

  setFeedbackType( type: number ) {
    this.feedbackType = type;
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => dtInstance.draw());
  }

  createDataTable() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      ordering: false,
      lengthChange: false,
      searching: false,
      serverSide: true,
      processing: true,
      // pageLength: -1,
      language: {
        paginate: {
          previous: '<',
          first: '<<',
          last: '>>',
          next: '>'
        }
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.hasLists = true;

        // const searchparams = this.searchForm.value;
        // if (searchparams.filterText !== '') {
        //   dataTablesParameters.search.column = searchparams.filterBy;
        //   dataTablesParameters.search.value = searchparams.filterText;
        // }

        // dataTablesParameters.search.dateTimeForm = '';
        // dataTablesParameters.search.dateTimeTo = '';
        // if (searchparams.orderFilterDates !== '') {
        //   const oDates = searchparams.orderFilterDates;
        //   dataTablesParameters.search.dateTimeForm = this.datePipe.transform( oDates[0], this.dateFormat );
        //   dataTablesParameters.search.dateTimeTo = this.datePipe.transform( oDates[1], this.dateFormat );
        // }

        let paging: Paginate = null;

        this.feedback.getFeedbacks(this.feedbackType, dataTablesParameters)
          .subscribe(data => {
            const res = data as FeedbackListResponse;

            if (res.status === true) {
              const feedbackData = res.data as FeedbackData;

              this.lists = feedbackData.Reviews;
              this.counters = feedbackData.Counter;

              this.feedbackProgress = [
                { label: '5', bg: 'success', review: (this.counters.fiveReviewsCount / this.counters.totalReviewsCount) * 100 },
                { label: '4', bg: 'info', review: (this.counters.fourReviewsCount / this.counters.totalReviewsCount) * 100 },
                { label: '3', bg: 'warning', review: (this.counters.threeReviewsCount / this.counters.totalReviewsCount) * 100 },
                { label: '2', bg: 'teal', review: (this.counters.twoReviewsCount / this.counters.totalReviewsCount) * 100 },
                { label: '1', bg: 'danger', review: (this.counters.oneReviewsCount / this.counters.totalReviewsCount) * 100 },                
              ] as FeedbackProgress[];

              paging = JSON.parse(res.paging) as Paginate;
            } else {
              paging = {
                totalCount: 0,
                totalRowsAfterFiltering: 0,
              } as Paginate;

              this.alert.danger(data['message']);
              this.lists = [];
            }

            callback({
              recordsTotal: paging.totalCount,
              recordsFiltered: paging.totalRowsAfterFiltering,
              data: []
            });

            // this.loader.hideLoader();
          },
            err => {
              const msg = (typeof err.error.Message !== 'undefined') ? err.error.Message : err.error.message;
              if (msg !== '') {
                // this.alert.danger(msg);
                callback({
                  recordsTotal: 0,
                  recordsFiltered: 0,
                  data: []
                });
                this.lists = [];
              }
              // this.loader.hideLoader();
            });
      }
    };
  }

}

interface FeedbackListResponse {
  status: boolean;
  message: string;
  paging: string;
  data: FeedbackData;
}

interface Paginate {
  totalCount: number;
  totalRowsAfterFiltering: number;
  pageSize: number;
  currentPage: number;
  totalPages: number;
  previousPage: string;
  nextPage: string;
}

interface FeedbackData {
  Counter: FeedbackCounter;
  Reviews: FeedbackList[];
}

interface FeedbackCounter {
  fiveReviewsCount: number;
  fourReviewsCount: number;
  oneReviewsCount: number;
  threeReviewsCount: number;
  twoReviewsCount: number;
  totalReviewsCount: number;
  avgReviewsCount: number;
}

interface FeedbackList{
  date: string;
  feedbackId: number;
  id: number;
  imageUrl: string;
  name: number;
  rate: number;
  remarks: string;
  type: string;
  orderBaseDetailId: number;
  productName: string;
}

interface FeedbackProgress {
  label: string;
  bg: string;
  review: number;
}