import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { constEnv } from 'common/constants.env';
import { AuthGuard } from 'common/_helpers/auth.guard';

import { ListComponent } from './list/list.component';
import { AddProductComponent } from './add-product/add-product.component';

import { EditComponent } from './edit-product/edit/edit.component';
import { AttributesComponent } from './edit-product/attributes/attributes.component';
import { VariationsComponent } from './edit-product/variations/variations.component';
import { InventoryComponent } from './inventory/inventory.component';
import { InventoryViewComponent } from './inventory-view/inventory-view.component';

const STORE_ENDPOINT = `${constEnv.storeEndPoint}/product`;

const routes: Routes = [
  {
    path: `${STORE_ENDPOINT}` ,
    children: [
      { path: 'lists' , component: ListComponent },
      { path: 'add' , component: AddProductComponent },
      {
        path: 'edit',
        children: [
          {
            path: ':id',
            children: [
              { path: 'attributes', component: AttributesComponent },
              { path: 'variations', component: VariationsComponent },
              { path: '', component: EditComponent }
            ]
          },
          {path: '', component: EditComponent  }
       ]
      },
      {
        path: ':id',
        children: [
          {
            path: 'inventory',
            children: [
              { path: 'view/:variationId', component: InventoryViewComponent },
              { path: '', component: InventoryComponent }
            ]
          },
          { path: '', component: InventoryComponent }
       ]
      },
      { path: '' , redirectTo: `${STORE_ENDPOINT}/lists`, pathMatch: 'full' },
    ],
    canActivate: [AuthGuard]
 },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductsRoutingModule { }
