import { Component, OnInit, ViewChild } from "@angular/core";

import { constEnv } from "common/constants.env";

import { DataTableDirective } from "angular-datatables";
import { Config } from "datatables.net";
import { TitleService } from "common/services/title.service";
import { first, filter } from "rxjs/operators";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { AlertService } from "@full-fledged/alerts";
import { IsLoadingService } from "@service-work/is-loading";
import { VehicleService } from "common/services/store/vehicle.service";
import { Vehicle } from "common/_models/store/vehicle.model";
import { MatDialog } from "@angular/material/dialog";
import { ModalDirective } from "ngx-bootstrap/modal";
import { ConfirmDialogComponent } from "common/_components/confirm-dialog/confirm-dialog.component";
import { Router } from "@angular/router";
import { Paging } from "src/app/e-commerce/category/category.component";

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.css"],
})
export class ListComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;

  // dtOptions: DataTables.Settings = {};
  dtOptions: Config = {};

  hasOrders = false;
  lists: Vehicle[] = [];

  driverLists: DriverList[] = [];

  assignDriverFrom: FormGroup;
  loading = false;

  @ViewChild("autoShownModal", { static: false })
  autoShownModal: ModalDirective;
  isModalShown = false;

  config = constEnv;

  vehicleBaseUrl = `${this.config.STORE_URL}/vehicle`;

  constructor(
    private titleService: TitleService,
    private alert: AlertService,
    private dialog: MatDialog,
    private isLoadingService: IsLoadingService,
    private router: Router,
    private fb: FormBuilder,
    private vehicle: VehicleService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("DRIVER_Vehicles");

    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }

    this.createDataTable();
  }

  openPage(type, vehicleId) {
    const url = `${this.vehicleBaseUrl}/${type}/${vehicleId}`;
    this.router.navigate([url]);
  }

  createDataTable() {
    this.dtOptions = {
      pagingType: "full_numbers",
      ordering: false,
      lengthChange: false,
      searching: false,
      serverSide: true,
      processing: true,
      // pageLength: -1,
      language: {
        paginate: {
          previous: "<",
          first: "<<",
          last: ">>",
          next: ">",
        },
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.hasOrders = true;

        let paging: Paging = null;

        this.vehicle
          .getAll(dataTablesParameters)
          .pipe(first())
          .subscribe(
            (data) => {
              const res = data as VehicleListResponse;

              if (res.status === true) {
                this.lists = res.data.map((item) =>
                  new Vehicle().deserialize(item)
                );

                paging = JSON.parse(res.paging) as Paging;
              } else {
                paging = {
                  totalCount: 0,
                  totalRowsAfterFiltering: 0,
                } as Paging;

                this.alert.danger(data["message"]);
                this.lists = [];
              }

              callback({
                recordsTotal: paging.totalCount,
                recordsFiltered: paging.totalRowsAfterFiltering,
                data: [],
              });

              this.isLoadingService.remove();
            },
            (err) => {
              const msg =
                typeof err.error.Message !== "undefined"
                  ? err.error.Message
                  : err.error.message;

              // this.alert.danger(msg);
              this.isLoadingService.remove();

              callback({
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              });
            }
          );
      },
    };
  }

  changeStatus(vehicleId, status) {
    this.isLoadingService.add();
    this.vehicle
      .vehicleChangeStatus(vehicleId, status === true ? 1 : 0)
      .subscribe(
        (data) => {
          if (data["status"] === true) {
            this.datatableElement.dtInstance.then(
              // (dtInstance: DataTables.Api) => dtInstance.draw()
              (dtInstance) => dtInstance.draw()
            );
          } else {
            this.alert.danger(data["message"]);
          }
          this.isLoadingService.remove();
        },
        (err) => {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;

          this.alert.danger(msg);
          this.isLoadingService.remove();
        }
      );
  }

  removeVehicle(vehicleId) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm",
        message: "DeleteConfirm",
      },
    });

    confirmDialog.afterClosed().subscribe((result) => {
      if (result === true) {
        this.isLoadingService.add();
        this.vehicle.deleteVehicle(vehicleId).subscribe(
          (data) => {
            if (data["status"] === true) {
              this.alert.success(data["message"]);
            } else {
              this.alert.danger(data["message"]);
            }
            this.datatableElement.dtInstance.then(
              // (dtInstance: DataTables.Api) => dtInstance.draw()
              (dtInstance) => dtInstance.draw()
            );
            this.isLoadingService.remove();
            // this.loadProductLists();
          },
          (err) => {
            if (typeof err.error !== "undefined") {
              const msg =
                typeof err.error.Message !== "undefined"
                  ? err.error.Message
                  : err.error.message;
              this.alert.danger(msg);
            }
            this.isLoadingService.remove();
          }
        );
      }
    });
  }

  removeDriverFromVehicle(vehicleId) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm",
        message: "RetractVehicleConfr",
      },
    });

    confirmDialog.afterClosed().subscribe((result) => {
      if (result === true) {
        this.isLoadingService.add();
        this.vehicle.retractVehicle(vehicleId).subscribe(
          (data) => {
            if (data["status"] === true) {
              this.alert.success(data["message"]);
            } else {
              this.alert.danger(data["message"]);
            }
            this.datatableElement.dtInstance.then(
              // (dtInstance: DataTables.Api) => dtInstance.draw()
              (dtInstance) => dtInstance.draw()
            );
            this.isLoadingService.remove();
            // this.loadProductLists();
          },
          (err) => {
            if (typeof err.error !== "undefined") {
              const msg =
                typeof err.error.Message !== "undefined"
                  ? err.error.Message
                  : err.error.message;
              this.alert.danger(msg);
            }
            this.isLoadingService.remove();
          }
        );
      }
    });
  }

  showModal(vehicleId): void {
    this.assignDriverFrom = this.fb.group({
      vehicleId: [vehicleId],
      driverId: [null, Validators.required],
    });

    this.isLoadingService.add();
    this.vehicle.getDriverListForVehicle().subscribe(
      (data) => {
        if (data["status"] === true) {
          this.driverLists = data["data"] as DriverList[];
          this.isModalShown = true;
        } else {
          this.alert.danger(data["message"]);
        }
        this.isLoadingService.remove();
      },
      (err) => {
        if (typeof err.error !== "undefined") {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;

          this.alert.danger(msg);
        }
        this.isLoadingService.remove();
      }
    );
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }

  onSubmit() {
    if (this.assignDriverFrom.invalid) {
      return;
    }

    this.loading = true;

    const vehicleId = this.assignDriverFrom.value.vehicleId;
    const driverId = this.assignDriverFrom.value.driverId;

    this.vehicle.assignDriver(vehicleId, driverId).subscribe(
      (data) => {
        if (data["status"] === true) {
          this.alert.success(data["message"]);
          this.hideModal();
          // this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) =>
          this.datatableElement.dtInstance.then((dtInstance) =>
            dtInstance.draw()
          );
        } else {
          this.alert.danger(data["message"]);
        }
        this.loading = false;
        this.isLoadingService.remove();
      },
      (err) => {
        if (typeof err.error !== "undefined") {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;

          this.alert.danger(msg);
        }
        this.loading = false;
        this.isLoadingService.remove();
      }
    );
  }
}

interface VehicleListResponse {
  status: boolean;
  message: string;
  data: Vehicle[];
  paging: string;
}

interface DriverList {
  id: number;
  name: string;
}
