import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { constEnv } from 'common/constants.env';
import { AuthGuard } from 'common/_helpers/auth.guard';

import { ListComponent } from './list/list.component';
import { DetailComponent } from './detail/detail.component';

const STORE_ENDPOINT = constEnv.storeEndPoint;

const routes: Routes = [
  {
    path: `${STORE_ENDPOINT}/return-request`,
    children: [
      { path: '', component: ListComponent },
      { path: ':id', component: DetailComponent }
    ],
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReturnRequestRoutingModule { }
