import {
  Component,
  OnInit,
  TemplateRef,
  ChangeDetectorRef,
  ViewChild,
} from "@angular/core";

import { constEnv } from "common/constants.env";
import { CategoryService } from "common/services/store/category.service";
import { AlertService } from "@full-fledged/alerts";
import { LoaderService } from "common/services/loader.service";
import { first } from "rxjs/operators";

import { DataTableDirective } from "angular-datatables";
import { Config } from "datatables.net";

// import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap';
import { Subscription, combineLatest } from "rxjs";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { TitleService } from "common/services/title.service";
import { ConfirmDialogComponent } from "common/_components/confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.css"],
})
export class ListComponent implements OnInit {
  categoryLists: any;
  hasCategory = false;

  // modalRef: BsModalRef;
  subscriptions: Subscription[] = [];

  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;

  // dtOptions: DataTables.Settings = {};
  dtOptions: Config = {};

  selectedCatId = 0;

  searchForm: FormGroup;
  submitted = false;

  FiltersOptions = [{ optionKey: "CategoryName", optionValue: "CategoryName" }];

  config = constEnv;

  constructor(
    private catService: CategoryService,
    private alert: AlertService,
    private loader: LoaderService,
    // private modalService: BsModalService,
    private changeDetection: ChangeDetectorRef,
    private fb: FormBuilder,
    private titleService: TitleService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.titleService.setTitle("SubCategoryName");
    this.makeSearchFrom();

    this.createDataTable();
  }

  makeSearchFrom() {
    this.searchForm = this.fb.group({
      filterBy: [this.FiltersOptions[0].optionKey, Validators.required],
      filterText: ["", Validators.required],
    });
  }

  createDataTable() {
    this.dtOptions = {
      pagingType: "full_numbers",
      ordering: false,
      lengthChange: false,
      searching: false,
      serverSide: true,
      processing: true,
      // pageLength: 2,
      language: {
        paginate: {
          previous: "<",
          first: "<<",
          last: ">>",
          next: ">",
        },
      },
      ajax: (dataTablesParameters: any, callback) => {
        const searchparams = this.searchForm.value;
        if (searchparams.filterText !== "") {
          dataTablesParameters.search.column = searchparams.filterBy;
          dataTablesParameters.search.value = searchparams.filterText;
        }

        this.catService
          .getSubCategoryAll(dataTablesParameters)
          .pipe(first())
          .subscribe(
            (data) => {
              let paging: any;
              if (data["status"] === true) {
                this.categoryLists = data["data"];
                if (this.categoryLists.length > 0) {
                  this.hasCategory = true;
                } else {
                  this.hasCategory = false;
                }
                paging = JSON.parse(data["paging"]);
                // paging = { totalCount: this.categoryLists.length, totalRowsAfterFiltering: this.categoryLists.length };
              } else {
                this.alert.danger(data["message"]);
                this.categoryLists = [];
                this.hasCategory = false;
              }

              if (this.categoryLists.length > 0) {
                this.hasCategory = true;
              }

              callback({
                recordsTotal: paging.totalCount,
                recordsFiltered: paging.totalRowsAfterFiltering,
                data: [],
              });

              this.loader.hideLoader();
            },
            (err) => {
              if (typeof err.error !== "undefined") {
                const msg =
                  typeof err.error.Message !== "undefined"
                    ? err.error.Message
                    : err.error.message;
                this.alert.danger(msg);
                this.hasCategory = false;
                this.categoryLists = [];
              }

              callback({
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              });

              this.loader.hideLoader();
            }
          );
      },
      // columns: [{ data: 'id' }, { data: 'firstName' }, { data: 'lastName' }]
    };
  }

  openModal(template: TemplateRef<any>, categoryId) {
    // const _combine = combineLatest(
    //   this.modalService.onShow,
    //   this.modalService.onShown,
    //   this.modalService.onHide,
    //   this.modalService.onHidden
    // ).subscribe(() => this.changeDetection.markForCheck());
    // this.subscriptions.push(
    //   this.modalService.onShow.subscribe((reason: string) => {
    //     this.selectedCatId = categoryId;
    //   })
    // );
    // this.subscriptions.push(
    //   this.modalService.onHidden.subscribe((reason: string) => {
    //     this.selectedCatId = 0;
    //     this.unsubscribe();
    //   })
    // );
    // this.subscriptions.push(_combine);
    // this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  deleteSubCategory(subCateId) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm",
        message: "DeleteConfirm",
      },
    });

    confirmDialog.afterClosed().subscribe((result) => {
      if (result === true) {
        if (subCateId > 0) {
          this.loader.showLoader();
          this.catService.deleteSubCategory(subCateId).subscribe(
            (data) => {
              if (data["status"] === true) {
                this.alert.success(data["message"]);
              } else {
                this.alert.danger(data["message"]);
              }
              // this.modalRef.hide();
              this.datatableElement.dtInstance.then(
                // (dtInstance: DataTables.Api) => dtInstance.draw()
                (dtInstance) => dtInstance.draw()
              );
              this.loader.hideLoader();
              // this.loadCategoryData();
            },
            (err) => {
              if (typeof err.error !== "undefined") {
                const msg =
                  typeof err.error.Message !== "undefined"
                    ? err.error.Message
                    : err.error.message;
                this.alert.danger(msg);
              }
              // this.modalRef.hide();
              this.loader.hideLoader();
            }
          );
        }
      }
    });
  }

  changeStatus(subCateId, catStatus) {
    if (subCateId > 0) {
      this.loader.showLoader();
      this.catService
        .changeStatusSubCategory(subCateId, catStatus ? 1 : 0)
        .subscribe(
          (data) => {
            if (data["status"] === true) {
              this.alert.success(data["message"]);
            } else {
              this.alert.danger(data["message"]);
            }
            this.datatableElement.dtInstance.then(
              // (dtInstance: DataTables.Api) => dtInstance.draw()
              (dtInstance) => dtInstance.draw()
            );
            this.loader.hideLoader();
            // this.loadCategoryData();
          },
          (err) => {
            if (typeof err.error !== "undefined") {
              const msg =
                typeof err.error.Message !== "undefined"
                  ? err.error.Message
                  : err.error.message;
              this.alert.danger(msg);
            }
            this.loader.hideLoader();
          }
        );
    }
  }

  get f() {
    return this.searchForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.searchForm.invalid) {
      return;
    }

    // this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) =>
    this.datatableElement.dtInstance.then((dtInstance) => dtInstance.draw());
  }

  resetFrom() {
    this.submitted = false;
    // this.searchForm.reset();

    // this.searchForm.get('filterBy').setValue( this.productFiltersOptions[0].optionKey );
    this.searchForm.get("filterText").setValue("");

    // this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) =>
    this.datatableElement.dtInstance.then((dtInstance) => dtInstance.draw());
  }
}
