import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { constEnv } from 'common/constants.env';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// import { AlertService } from 'common/services/alert.service';
import { AlertService } from 'ngx-alerts';
import { CustomValidator } from 'common/_helpers/custom.validator';
import { first } from 'rxjs/operators';
import { TitleService } from 'common/services/title.service';
import { IsLoadingService } from '@service-work/is-loading';
import { UserService } from 'common/services/driver/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  RPForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  accessToken: string;

  constantConfig = constEnv;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private userService: UserService,
    private titleService: TitleService,
    private isLoadingService: IsLoadingService,
  ) { }

  ngOnInit() {
    this.titleService.setTitle('ResetPassword');
    if ( this.isLoadingService.isLoading$() ) {
      this.isLoadingService.remove();
    }
    this.document.body.classList.add('reset-password-body');

    this.accessToken = this.route.snapshot.params.token;

    this.RPForm = this.formBuilder.group({
      newPassword: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(16)
      ])),
      confirmPassword: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(16)
      ])),
    }, {validator: CustomValidator.MatchPassword});
  }

  ngOnDestroy(): void {
    this.document.body.classList.remove('reset-password-body');
  }

  getErrorNewPassword() {
    return this.RPForm.get('newPassword').hasError('required') ? '' :
      this.RPForm.get('newPassword').hasError('minlength') ? this.titleService.getTranslatedString('PasswordFormate') :
      this.RPForm.get('newPassword').hasError('maxlength') ? this.titleService.getTranslatedString('PasswordFormate') : '';
  }

  getErrorConfirmPassword() {
    return this.RPForm.get('confirmPassword').hasError('required') ? '' :
      this.RPForm.get('confirmPassword').hasError('minlength') ? this.titleService.getTranslatedString('PasswordFormate') :
      this.RPForm.get('confirmPassword').hasError('maxlength') ? this.titleService.getTranslatedString('PasswordFormate') :
      this.RPForm.get('confirmPassword').hasError('confirmPassword') ? this.titleService.getTranslatedString('NewPasswordAndConfirmPasswordMustBeSame') : '';
  }

  get f() { return this.RPForm.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    // this.alertService.clear();

    // stop here if form is invalid
    if (this.RPForm.invalid) {
      return;
    }

    this.loading = true;
    this.isLoadingService.add();
    
    this.userService.resetPassword( this.accessToken, this.RPForm.value.newPassword )
      .pipe(first())
      .subscribe(data => {
        this.alertService.success(data['message']);
        this.submitted = false;
        this.RPForm.reset();
        this.loading = false;
        this.isLoadingService.remove();
        // this.router.navigate([`${this.constantConfig.STORE_URL}/login`]);
      },
        err => {
          const msg = (typeof err.error.message !== 'undefined') ? err.error.message : '';
          if (msg) {
            // this.alertService.error(msg);
            this.alertService.danger(msg);
          }
          this.isLoadingService.remove();
          this.loading = false;
        });
  }

}
