import {
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
} from "@angular/core";
import { LoaderService } from "common/services/loader.service";
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { CustomerService } from "common/services/customers/customer.service";
import { first } from "rxjs/operators";
import { constEnv } from "common/constants.env";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Subscription, combineLatest } from "rxjs";
import { AlertService } from "@full-fledged/alerts";
import { IsLoadingService } from "@service-work/is-loading";
import { TitleService } from "common/services/title.service";
import { CartService } from "common/services/site/cartservice";

@Component({
  selector: "app-wishlist",
  templateUrl: "./wishlist.component.html",
  styleUrls: ["./wishlist.component.css"],
})
export class WishlistComponent implements OnInit {
  itemsPerPage = 9;
  currentPage = 0;
  myWishlists: any;
  totalRows = 0;
  noWishlistsMsg = "";
  config = constEnv;
  modalRef: BsModalRef;
  subscriptions: Subscription[] = [];
  selectedWishlistId: any;

  constructor(
    private ls: LoaderService,
    private cs: CustomerService,
    private modalService: BsModalService,
    private titleService: TitleService,
    private changeDetection: ChangeDetectorRef,
    private alert: AlertService,
    private isLoadingService: IsLoadingService,
    private cart: CartService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("USER_My_Wishlist");
    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }
    const paging = { page: 0, itemsPerPage: this.itemsPerPage };
    this.loadMyWishlists(paging);
  }
  loadMyWishlists(paging: PageChangedEvent) {
    this.ls.showLoader();
    paging.page = paging.page > 0 ? paging.page - 1 : 0;
    paging.itemsPerPage = paging.itemsPerPage
      ? paging.itemsPerPage
      : this.itemsPerPage;
    this.currentPage = paging.page;

    this.cs
      .getCustomerWishList(paging)
      .pipe(first())
      .subscribe(
        (res) => {
          // console.log(res);
          if (res["status"] === true) {
            this.myWishlists = res["data"];
            const paging = JSON.parse(res["paging"]);
            this.totalRows = paging.totalCount;
          } else {
            this.myWishlists = [];
            // this.alert.error( res['message'] );
            this.noWishlistsMsg = res["message"];
          }
          this.ls.hideLoader();
        },
        (err) => {
          this.myWishlists = [];
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          this.myWishlists = [];
          this.totalRows = 0;
          if (msg !== "") {
            // this.alert.error(msg);
            this.noWishlistsMsg = msg;
          }
          this.ls.hideLoader();
        }
      );
  }
  openModal(template: TemplateRef<any>) {
    const _combine = combineLatest(
      this.modalService.onShow,
      this.modalService.onShown,
      this.modalService.onHide,
      this.modalService.onHidden
    ).subscribe(() => this.changeDetection.markForCheck());

    this.subscriptions.push(
      this.modalService.onShow.subscribe((reason: string) => {})
    );

    this.subscriptions.push(
      this.modalService.onHidden.subscribe((reason: string) => {
        this.unsubscribe();
      })
    );

    this.subscriptions.push(_combine);

    this.modalRef = this.modalService.show(template, { class: "modal-sm" });
  }
  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }
  removeFromWishlist(wishlistId) {
    if (wishlistId > 0) {
      this.ls.showLoader();
      this.cs.removeCustomerWishlist(wishlistId).subscribe(
        (data) => {
          if (data["status"] === true) {
            this.alert.success(data["message"]);
          } else {
            this.alert.danger(data["message"]);
          }
          this.cart.sendCartCountEvent();
          this.modalRef.hide();
          this.ls.hideLoader();
          const paging = {
            page: this.currentPage,
            itemsPerPage: this.itemsPerPage,
          };
          this.loadMyWishlists(paging);
        },
        (err) => {
          const mgs =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          if (mgs !== null) {
            this.alert.danger(mgs);
          }
          this.modalRef.hide();
          this.ls.hideLoader();
        }
      );
    }
  }
}
