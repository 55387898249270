import { Component, OnInit, OnDestroy, Inject, ViewChild } from "@angular/core";
import { AlertService } from "@full-fledged/alerts";
import { constEnv } from "../../../../common/constants.env";

import { DOCUMENT } from "@angular/common";

import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { first } from "rxjs/operators";

import { AuthenticationService } from "common/services/authentication.service";
import { LanguageService } from "common/services/language.service";
import { LoaderService } from "common/services/loader.service";
import { TitleService } from "common/services/title.service";
import { IsLoadingService } from "@service-work/is-loading";
import { Observable } from "rxjs";
import { CustomerService } from "common/services/customers/customer.service";

// import { AuthService } from "angularx-social-login";
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialUser,
} from "angularx-social-login";
import { BsModalRef, ModalDirective } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
import { FirebaseAuthService } from "common/services/firebase-auth.service";
import firebase from "firebase/compat";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  accountForm: FormGroup;
  accountSubmitted = false;
  socialuser: SocialUser;
  validMobileNo = true;

  @ViewChild("autoShownModal", { static: false })
  autoShownModal: ModalDirective;

  modalRef: BsModalRef;
  socialSubcription: Subscription;

  countryCode: string;
  countryIsoCode: string;

  hasAccountEmail = false;
  hasSocialData = {
    firstName: false,
    lastName: false,
  };

  constantConfig = constEnv;
  isLoading: Observable<boolean>;

  public showPassword: boolean = false;
  public phoneInputPadding: string = "0px";

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private alert: AlertService,
    private titleService: TitleService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private langService: LanguageService,
    private isLoadingService: IsLoadingService,
    private storeUser: CustomerService,
    // private authService: AuthService,
    private firebaseAuthService: FirebaseAuthService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Sign_In");

    // this.returnUrl = this.route.snapshot.queryParams.returnUrl || `${this.constantConfig.CUSTOMER_URL}`;
    this.returnUrl =
      this.route.snapshot.queryParams.returnUrl ||
      `${this.constantConfig.BASE_URL}home`;

    if (this.authenticationService.currentUserValue) {
      this.router.navigate([this.returnUrl]);
    }
    this.isLoading = this.isLoadingService.isLoading$();
    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }
    //this.document.body.classList.add('login-body');

    this.makeLoginForm();

    // this.socialSubcription = this.authService.authState.subscribe((user) => {
    //   this.socialuser = user;
    //   if (user !== null) {
    //     this.checkSocialLogin(user);
    //   }
    // });
  }

  makeLoginForm() {
    this.loginForm = this.formBuilder.group({
      email: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern(
            "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"
          ),
        ])
      ),
      password: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(16),
        ])
      ),
    });
  }

  ngOnDestroy(): void {
    this.document.body.classList.remove("login-body");

    // this.socialSubcription.unsubscribe();
  }

  get f() {
    return this.loginForm.controls;
  }

  getErrorEmail() {
    return this.loginForm.get("email").hasError("required")
      ? ""
      : this.loginForm.get("email").hasError("pattern")
      ? "EmailInValid"
      : // ? this.titleService.getTranslatedString("EmailInValid")
        "";
  }

  getErrorPassword() {
    return this.loginForm.get("password").hasError("required")
      ? ""
      : this.loginForm.get("password").hasError("minlength")
      ? "PasswordFormate"
      : this.loginForm.get("password").hasError("maxlength")
      ? "PasswordFormate"
      : "";
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.isLoadingService.add();

    this.authenticationService
      .login(this.f.email.value, this.f.password.value, "Customer")
      .pipe(first())
      .subscribe(
        (data) => {
          const userdetail =
            typeof data?.userdetails !== "undefined" ? data?.userdetails : [];

          this.langService.changeLanguage(userdetail.languageCode);

          this.storeUser
            .getProfile()
            .pipe(first())
            .subscribe(
              (profile) => {
                let redirectURL = this.returnUrl;
                //redirectURL = `${constEnv.apiCustomerURL}/settings/store`;

                if (userdetail.isFirstimeLogin) {
                  redirectURL = `${constEnv.CUSTOMER_URL}/change-password`;
                }

                setTimeout(() => {
                  window.location.href = redirectURL;
                }, 2500);
              },
              (err) => {
                this.alert.danger(err.error.message);
                this.loading = false;
                this.isLoadingService.remove();
              }
            );
        },
        (err) => {
          let errorMsg = "";
          if (typeof err.error.Message !== "undefined") {
            errorMsg = err.error.Message;
          } else if (typeof err.error.error_description !== "undefined") {
            errorMsg = err.error.error_description;
          } else if (typeof err.error.message !== "undefined") {
            errorMsg = err.error.message;
          }
          this.submitted = false;
          if (errorMsg == "Password not match") {
            this.loginForm.get("password").reset();
          } else {
            this.loginForm.reset();
          }
          if (errorMsg !== "") {
            // this.alertService.error(errorMsg);
            this.alert.danger(errorMsg);
          }
          this.loading = false;
          this.isLoadingService.remove();
        }
      );
    /*setTimeout( () => {
      this.isLoadingService.remove();
      this.loading = false;
    }, 1500);*/
  }

  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }
}
