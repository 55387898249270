import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';
import { MaterialModule } from 'common/modules/material.module';
import { TranslateModule } from '@ngx-translate/core';

import { ComponentModule } from 'common/_components/components.module';
import { TemplateDefaultModule } from '../../shared/template-default/template-default.module';
import { DataTablesModule } from 'angular-datatables';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { MyDirectiveModule } from 'common/directives/my-directive.module';

import { HelpSupportRoutingModule } from './help-support-routing.module';
import { ListComponent } from './list/list.component';
import { AddComponent } from './add/add.component';


@NgModule({
  declarations: [ListComponent, AddComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    ComponentModule,
    TemplateDefaultModule,
    DataTablesModule,
    MaterialModule,
    Ng2TelInputModule,
    NgxIntlTelInputModule,
    MyDirectiveModule,
    BsDatepickerModule.forRoot(),
    HelpSupportRoutingModule
  ]
})
export class HelpSupportModule { }
