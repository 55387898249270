import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { IsLoadingService } from "@service-work/is-loading";
import { OfferService } from "common/services/store/offer.service";
import { TitleService } from "common/services/title.service";
import { Offers } from "common/_models/store/offers";
import { AlertService } from "@full-fledged/alerts";

@Component({
  selector: "app-lists",
  templateUrl: "./lists.component.html",
  styleUrls: ["./lists.component.css"],
})
export class ListsComponent implements OnInit {
  lists: Offers[] = [];

  constructor(
    private titleService: TitleService,
    private fb: FormBuilder,
    private alert: AlertService,
    private isLoadingService: IsLoadingService,
    private offers: OfferService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Offers");

    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }

    this.loadOffers();
  }

  loadOffers() {
    this.isLoadingService.add();

    this.offers
      .getOffers()
      .pipe()
      .subscribe(
        (res) => {
          const data = res as OfferListsResponse;
          if (data.status) {
            this.lists = data.data.map((item) =>
              new Offers().deserialize(item)
            );
          } else {
            this.alert.danger(data.message);
          }

          this.isLoadingService.remove();
        },
        (err) => {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          if (msg !== "") {
            this.alert.danger(err.error.message);
          }
          this.isLoadingService.remove();
        }
      );
  }
}

interface OfferListsResponse {
  status: boolean;
  message: string;
  data: Offers[];
}
