import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
  TemplateRef,
  ChangeDetectorRef,
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
// import { MapsAPILoader } from "@agm/core";
import { ActivatedRoute, Router } from "@angular/router";
import { constEnv } from "common/constants.env";
import { LoaderService } from "common/services/loader.service";
import { CustomerService } from "common/services/customers/customer.service";
import { AlertService } from "@full-fledged/alerts";
import { first } from "rxjs/operators";
import { Subscription, combineLatest } from "rxjs";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { AddressInfo } from "common/_components/maps/interfaces/address-info";
import { GeneralService } from "common/services/general.service";

interface Marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}

@Component({
  selector: "app-add-address",
  templateUrl: "./add-address.component.html",
  styleUrls: ["./add-address.component.css"],
})
export class AddAddressComponent implements OnInit {
  addressForm: FormGroup;
  action: string;
  id: number;
  markers: Marker[] = [];
  zoom: number;
  lat: number;
  lng: number;

  private geoCoder;
  config = constEnv;
  submitted: boolean;
  listUrl: string;
  subscriptions: Subscription[] = [];
  selectedAddressId = 0;

  @ViewChild("search", { static: false })
  public searchElementRef: ElementRef;

  selectedType = "home";
  addressTypes = [
    { name: "home", value: "Home" },
    { name: "office", value: "USER_Office" },
    { name: "other", value: "USER_Other" },
  ];
  loading = false;
  modalRef: BsModalRef;

  countryLists: countryList[] = [];
  stateLists: stateList[] = [];
  cityLists: cityList[] = [];

  pageTitle: string = "";

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    // private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private ls: LoaderService,
    private customer: CustomerService,
    private general: GeneralService,
    private alert: AlertService,
    private router: Router // private modalService: BsModalService, // private changeDetection: ChangeDetectorRef
  ) {
    this.action = route.snapshot.params.action;
    this.listUrl = `${this.config.CUSTOMER_URL}/address`;
  }

  ngOnInit() {
    this.zoom = 8;

    this.lat = 51.673858;
    this.lng = 7.815982;

    this.makeForm();
    this.checkValidAction();
    this.setActionVariables();
  }
  private makeForm() {
    // const zipCodeRegex: RegExp =/^(?!.*[\s-]{2,})[A-Za-z0-9][A-Za-z\d\- ]{0,22}[A-Za-z\d]$/;

    this.addressForm = this.fb.group({
      AddressId: [0],
      Location: ["", Validators.required],
      Longitude: [""],
      Latitude: [""],
      AddressLine: ["", Validators.required],
      AddressLine1: ["", Validators.required],
      AddressLine2: [""],
      Landmark: [""],
      ZipCode: ["", [Validators.required, this.zipCodeValidator()]],
      type: [this.selectedType],
      CountryName: [null, Validators.required],
      StateName: [null, Validators.required],
      CityName: [null, Validators.required],
      additionalAddressLine: [""],
    });
  }

  get f() {
    return this.addressForm.controls;
  }

  zipCodeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (!value) return null;
      if (
        value.startsWith(" ") ||
        value.endsWith(" ") ||
        value.startsWith("-") ||
        value.endsWith("-")
      ) {
        return { invalidZipCode: { value: control.value } };
      }
      const spacesCount = (value.match(/ /g) || []).length;
      const dashesCount = (value.match(/-/g) || []).length;

      if (spacesCount > 1 || dashesCount > 1) {
        return { invalidZipCode: { value: control.value } };
      }

      const regex = /^[a-zA-Z0-9\- ]+$/;
      if (!regex.test(value)) {
        return { invalidZipCode: { value: control.value } };
      }

      return null;
    };
  }

  private setActionVariables() {
    // if (this.action === 'add') {
    //   this.pageTitle = 'Add Address';
    //   this.buttonActionText = 'USER_SAVE_ADDRESS';
    // } else {
    //   this.pageTitle = 'Edit Address';
    //   this.buttonActionText = 'USER_SAVE_ADDRESS';
    // }
    // this.placeAutoCompleteField();
    //this.titleService.setTitle(this.pageTitle);

    this.getCountryList();
  }
  // private placeAutoCompleteField() {
  //   this.mapsAPILoader.load().then(() => {
  //     if (this.action === "add") {
  //       //this.setCurrentLocation();
  //     }

  //     this.geoCoder = new google.maps.Geocoder();

  //     const autocomplete = new google.maps.places.Autocomplete(
  //       this.searchElementRef.nativeElement,
  //       {
  //         types: ["address"],
  //       }
  //     );
  //     autocomplete.addListener("place_changed", () => {
  //       this.ngZone.run(() => {
  //         // get the place result
  //         const place: google.maps.places.PlaceResult = autocomplete.getPlace();

  //         // verify result
  //         if (place.geometry === undefined || place.geometry === null) {
  //           return;
  //         }

  //         // set latitude, longitude and zoom
  //         this.lat = place.geometry.location.lat();
  //         this.lng = place.geometry.location.lng();
  //         this.zoom = 12;
  //         this.markers = [];
  //         this.createMarker(this.lat, this.lng, "", true);
  //         this.getAddress(this.lat, this.lng);
  //       });
  //     });
  //   });
  // }
  // private setCurrentLocation() {
  //   if ("geolocation" in navigator) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       this.lat = position.coords.latitude;
  //       this.lng = position.coords.longitude;
  //       this.zoom = 12;

  //       this.createMarker(this.lat, this.lng, "", true);
  //       this.getAddress(this.lat, this.lng);
  //     });
  //   }
  // }
  createMarker(lat, lng, label = "", draggable = false) {
    this.markers.push({
      lat,
      lng,
      label,
      draggable,
    });
  }
  // markerDragEnd(m: Marker, $event: MouseEvent) {
  //   this.lat = $event["coords"].lat;
  //   this.lng = $event["coords"].lng;

  //   this.getAddress(this.lat, this.lng);
  // }

  isArabic(text: string): boolean {
    const arRergEx =
      /[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufc3f]|[\ufe70-\ufefc]/;

    return arRergEx.test(text) ? true : false;
  }

  // getAddress(latitude, longitude) {
  //   this.geoCoder.geocode(
  //     { location: { lat: latitude, lng: longitude } },
  //     (results, status) => {
  //       if (status === "OK") {
  //         let isArabicWord = false;
  //         if (results[0]) {
  //           const addressComponent = results[0].address_components;

  //           let city = "";
  //           let state_name = "";
  //           addressComponent.forEach((adrComponent) => {
  //             const types = adrComponent.types;
  //             if (types.indexOf("administrative_area_level_1") !== -1) {
  //               state_name = adrComponent.long_name;
  //             }

  //             if (types.indexOf("administrative_area_level_2") !== -1) {
  //               city = adrComponent.long_name;
  //             }
  //           });

  //           if (city !== "") {
  //             isArabicWord = this.isArabic(city);
  //           }

  //           if (state_name !== "") {
  //             isArabicWord = this.isArabic(state_name);
  //           }
  //         }

  //         let resultAddr = isArabicWord ? results[1] : results[0];

  //         if (resultAddr) {
  //           this.zoom = 12;
  //           this.addressForm
  //             .get("Location")
  //             .setValue(resultAddr.formatted_address);
  //           if (typeof resultAddr.address_components !== "undefined") {
  //             const addressComponent = resultAddr.address_components;

  //             addressComponent.forEach((adrComponent) => {
  //               const types = adrComponent.types;
  //               if (types.indexOf("country") !== -1) {
  //                 this.addressForm
  //                   .get("CountryName")
  //                   .setValue(adrComponent.long_name);
  //               }
  //               if (types.indexOf("administrative_area_level_1") !== -1) {
  //                 this.addressForm
  //                   .get("StateName")
  //                   .setValue(adrComponent.long_name);
  //               }

  //               if (types.indexOf("administrative_area_level_2") !== -1) {
  //                 this.addressForm
  //                   .get("CityName")
  //                   .setValue(adrComponent.long_name);
  //               } else {
  //                 this.addressForm.get("CityName").setValue("");
  //               }
  //               if (types.indexOf("postal_code") !== -1) {
  //                 this.addressForm
  //                   .get("ZipCode")
  //                   .setValue(adrComponent.long_name);
  //               }
  //             });

  //             this.lat = resultAddr.geometry.location.lat();
  //             this.lng = resultAddr.geometry.location.lng();

  //             this.addressForm.get("Latitude").setValue(this.lat);
  //             this.addressForm.get("Longitude").setValue(this.lng);
  //           }
  //         } else {
  //           window.alert("No results found");
  //         }
  //       } else {
  //         window.alert("Geocoder failed due to: " + status);
  //       }
  //     }
  //   );
  // }
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.addressForm.invalid) {
      return;
    }

    // const country = this.countryLists.find(country => country.countryId == this.addressForm.value.CountryName);
    // const state = this.stateLists.find(state => state.stateId == this.addressForm.value.StateName);
    // const city = this.cityLists.find(city => city.cityId == this.addressForm.value.CityName);

    // this.addressForm.patchValue({
    //   CountryName: country.countryName,
    //   StateName: state.stateName,
    //   CityName: city.cityName
    // });

    this.loading = true;
    this.ls.showLoader();

    this.customer
      .updateCustomerAddress(this.addressForm.value)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data["status"] === true) {
            this.alert.success(data["message"]);
            this.ls.hideLoader();
            // setTimeout(() => {
            const returnUrl = this.route.snapshot.queryParams["returnUrl"];

            if (returnUrl) {
              this.router.navigateByUrl(returnUrl);
            } else {
              this.router.navigate([`${this.listUrl}`]);
            }
            // }, 1000);
          } else {
            this.alert.danger(data["message"]);
            this.ls.hideLoader();
          }
        },
        (err) => {
          if (typeof err.error !== "undefined") {
            const msg =
              typeof err.error.Message !== "undefined"
                ? err.error.Message
                : err.error.message;
            this.alert.danger(msg);
          }
          this.ls.hideLoader();
          this.loading = false;
        }
      );
  }
  private checkValidAction() {
    if (this.action !== "add" && this.action !== "edit") {
      this.router.navigate([this.listUrl]);
      return false;
    }

    this.pageTitle = "USER_Add_New_Address";
    if (this.action === "edit") {
      const id = this.route.snapshot.params.id;
      this.id = typeof id !== "undefined" ? id : 0;
      if (this.id <= 0) {
        this.router.navigate([this.listUrl]);
        return false;
      }

      this.pageTitle = "UpdateAddress";

      this.getAddressById();
    }
  }
  getAddressById() {
    this.ls.showLoader();
    this.customer
      .getCustomerAddressese(this.id)
      .pipe(first())
      .subscribe(
        (res) => {
          console.log(res);
          if (res["status"] === true) {
            this.setFromValues(res["data"]);
          } else {
            this.alert.danger(res["message"]);
          }
          this.ls.hideLoader();
        },
        (err) => {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          if (msg !== "") {
            this.alert.danger(msg);
          }
          this.ls.hideLoader();
        }
      );
  }
  private setFromValues(formData) {
    // console.log(formData);
    this.onCountryChange(formData.countryName);
    this.onStateChange(formData.stateName);

    this.addressForm.get("AddressId").setValue(formData.addressId);
    this.addressForm.get("Location").setValue(formData.location);
    this.addressForm.get("Latitude").setValue(formData.latitude);
    this.addressForm.get("Longitude").setValue(formData.longitude);
    this.addressForm.get("AddressLine").setValue(formData.addressLine);
    this.addressForm.get("ZipCode").setValue(formData.zipCode);
    this.addressForm.get("AddressLine1").setValue(formData.addressLine1);
    this.addressForm.get("AddressLine2").setValue(formData.addressLine2);
    this.addressForm.get("Landmark").setValue(formData.landmark);
    this.addressForm.get("type").setValue(formData.type);
    this.addressForm
      .get("additionalAddressLine")
      .setValue(formData.additionalAddressLine);

    // this.addressForm.get("CountryName").setValue(formData.countryName);
    // this.addressForm.get("StateName").setValue(formData.stateName);
    // this.addressForm.get("CityName").setValue(formData.cityName);

    // setTimeout(() => {
    this.addressForm
      .get("CountryName")
      .setValue(
        formData.countryName > 0 ? parseInt(formData.countryName) : null
      );
    this.addressForm
      .get("StateName")
      .setValue(formData.stateName > 0 ? parseInt(formData.stateName) : null);
    this.addressForm
      .get("CityName")
      .setValue(formData.cityName > 0 ? parseInt(formData.cityName) : null);
    // }, 2000);

    this.lat = parseFloat(formData.latitude);
    this.lng = parseFloat(formData.longitude);
    this.zoom = 14;

    this.markers = [];
    this.createMarker(this.lat, this.lng, "", true);
  }

  getSelectedAddress(address: AddressInfo) {
    if (address) {
      // console.log(address);
      this.addressForm.get("Location").setValue(address.text);
      this.addressForm.get("Latitude").setValue(address.latlng.lat);
      this.addressForm.get("Longitude").setValue(address.latlng.lng);
      const selectedCountry = this.countryLists.find(
        (item) => item.countryName === address.properties.CntryName
      );
      if (selectedCountry) {
        this.addressForm.get("CountryName").setValue(selectedCountry.countryId);
        this.onCountryChange(selectedCountry.countryId);
      }
      setTimeout(() => {
        const selectedState = this.stateLists.find(
          (item) => item.stateName === address.properties.Region
        );
        if (selectedState) {
          this.addressForm.get("StateName").setValue(selectedState.stateId);
          this.onStateChange(selectedState.stateId);
        }
        setTimeout(() => {
          const selectedCity = this.cityLists.find(
            (item) => item.cityName === address.properties.City
          );
          if (selectedCity) {
            this.addressForm.get("CityName").setValue(selectedCity.cityId);
          }
        }, 500);
      }, 500);
      // this.addressForm.get("AddressLine").setValue(storeData.addressLine);
      const postalCode = address.properties.PostalExt
        ? address.properties.Postal + " " + address.properties.PostalExt
        : address.properties.Postal;
      this.addressForm.get("ZipCode").setValue(postalCode);
      const stAddress = address.properties.StAddr
        ? address.properties.StAddr
        : "";
      const stAddressLine = address.properties.Address
        ? address.properties.Address
        : "";
      const addressLine1 = stAddress ? stAddress : stAddressLine;
      this.addressForm.get("AddressLine1").setValue(addressLine1);
      const nbrhd = address.properties.Nbrhd ? address.properties.Nbrhd : "";
      const nbrhdLine = address.properties.Neighborhood
        ? address.properties.Neighborhood
        : "";
      const neighborhood = nbrhd ? nbrhd : nbrhdLine;
      this.addressForm.get("Landmark").setValue(neighborhood);
      this.updateAddressLine();
    }
  }

  updateAddressLine() {
    const addressLine1 = this.addressForm.get("AddressLine1").value;
    const addressLine2 = this.addressForm.get("AddressLine2").value;
    const landmark = this.addressForm.get("Landmark").value;
    const postcode = this.addressForm.get("ZipCode").value;

    const combinedAddress = [addressLine1, addressLine2, landmark, postcode]
      .filter(Boolean)
      .join(", ");

    this.addressForm.get("AddressLine").setValue(combinedAddress);
  }

  private getCountryList() {
    this.general.getAllCountry().subscribe(
      (data) => {
        if (data["status"] === true) {
          this.countryLists = data["data"] as countryList[];
        }
        this.ls.hideLoader();
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          this.alert.danger(err.error.message);
        }
        this.ls.hideLoader();
      }
    );
  }

  onCountryChange(countryId: number) {
    if (countryId > 0) {
      this.ls.showLoader();
      this.stateLists = [];
      this.cityLists = [];
      this.addressForm.get("StateName").setValue(null);
      this.addressForm.get("CityName").setValue(null);
      this.general.getstateByCountryId(countryId).subscribe(
        (data) => {
          if (data["status"] === true) {
            this.stateLists = data["data"];
          }
          this.ls.hideLoader();
        },
        (err) => {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          if (msg !== "") {
            this.alert.danger(err.error.message);
          }
          this.ls.hideLoader();
        }
      );
    } else {
      this.stateLists = [];
      this.cityLists = [];
    }
  }

  onStateChange(stateId: number) {
    if (stateId > 0) {
      this.ls.showLoader();
      this.cityLists = [];
      this.addressForm.get("CityName").setValue(null);
      this.general.getcityByStateId(stateId).subscribe(
        (data) => {
          if (data["status"] === true) {
            this.cityLists = data["data"];
          }
          this.ls.hideLoader();
        },
        (err) => {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          if (msg !== "") {
            this.alert.danger(err.error.message);
          }
          this.ls.hideLoader();
        }
      );
    } else {
      this.cityLists = [];
    }
  }
}

interface countryList {
  countryId: number;
  countryName?: string;
}

interface stateList {
  stateId: number;
  stateName?: string;
}

interface cityList {
  cityId: number;
  cityName?: string;
}
