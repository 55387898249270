import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  AfterViewInit,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";

import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";

import { constEnv } from "common/constants.env";
import { AlertService } from "ngx-alerts";
import { LoaderService } from "common/services/loader.service";
import { TitleService } from "common/services/title.service";
import { DeliveryCompanyService } from "common/services/delivery-company.service";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-dc-login",
  templateUrl: "./dc-login.component.html",
  styleUrls: ["./dc-login.component.css"],
})
export class DcLoginComponent implements OnInit, OnDestroy, AfterViewInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  constantConfig = constEnv;

  public showPassword: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private ls: LoaderService,
    private titleService: TitleService,
    // private dcService: DeliveryCompanyService,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Sign_In");
    this.document.body.classList.add("page-login");

    this.loginForm = this.formBuilder.group({
      email: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern(
            "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"
          ),
        ])
      ),
      password: ["", Validators.required],
      type: ["deliverycompany"],
    });
  }

  getErrorEmail() {
    return this.loginForm.get("email").hasError("required")
      ? ""
      : this.loginForm.get("email").hasError("pattern")
      ? this.titleService.getTranslatedString("EmailInValid")
      : "";
  }

  getErrorPassword() {
    return this.loginForm.get("password").hasError("required")
      ? ""
      : this.loginForm.get("password").hasError("minlength")
      ? this.titleService.getTranslatedString("PasswordFormate")
      : this.loginForm.get("password").hasError("maxlength")
      ? this.titleService.getTranslatedString("PasswordFormate")
      : "";
  }

  ngAfterViewInit(): void {
    const errorCode = this.route.snapshot.queryParams.code;
    if (errorCode >= 0) {
      const errMsg = this.getLoginErrorMsg(errorCode);
      if (errMsg !== "") {
        this.alertService.danger(this.translate.instant(errMsg));
      }
    }
    if (errorCode == 1) {
      const emailFromParams = this.route.snapshot.queryParams.email || "";
      this.loginForm.patchValue({
        email: emailFromParams,
      });
    }
  }

  ngOnDestroy(): void {
    this.document.body.classList.remove("login-body");
  }

  get f() {
    return this.loginForm.controls;
  }

  getLoginErrorMsg(errorCode) {
    const errors = [
      "UserLoginDisable",
      "UserLoginAuthorise",
      "password_incorrect",
      "email_not_verified",
      "user_not_found",
    ];

    return typeof errors[errorCode] !== "undefined" ? errors[errorCode] : "";
  }

  onSubmit(e) {
    this.submitted = true;

    // reset alerts on submit
    // this.alertService.clear();

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.ls.showLoader();

    e.target.submit();
    // return false;

    // this.dcService.login(this.f.email.value, this.f.password.value)
    //   .pipe(first())
    //   .subscribe(
    //     data => {
    //       console.log(data);
    //       // window.location.href = redirectURL;
    //     },
    //     err => {
    //       let errorMsg = '';
    //       if (typeof err.error.Message !== 'undefined') {
    //         errorMsg = err.error.Message;
    //       } else if (typeof err.error.error_description !== 'undefined') {
    //         errorMsg = err.error.error_description;
    //       }

    //       this.submitted = false;
    //       this.loginForm.reset();

    //       if (errorMsg !== '') {
    //         this.alertService.danger(errorMsg);
    //       }
    //       this.loading = false;
    //       this.ls.hideLoader();
    //     });
  }
}
