import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DcLoginComponent } from "./dc-login/dc-login.component";
// import { DcRegisterComponent } from './dc-register/dc-register.component';
import { ComponentModule } from "common/_components/components.module";
import { TranslateModule } from "@ngx-translate/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { RecaptchaModule, RecaptchaFormsModule } from "ng-recaptcha";
import { Ng2TelInputModule } from "ng2-tel-input";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
// import { DcEmailVerifyComponent } from './dc-email-verify/dc-email-verify.component';
import { DcForgotPasswordComponent } from "./dc-forgot-password/dc-forgot-password.component";
import { DcResetPasswordComponent } from "./dc-reset-password/dc-reset-password.component";

import { DeliveryCompanyRoutingModule } from "./delivery-company-routing.module";
import { MaterialModule } from "common/modules/material.module";

@NgModule({
  declarations: [
    DcLoginComponent,
    DcForgotPasswordComponent,
    DcResetPasswordComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentModule,
    TranslateModule,
    RouterModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    Ng2TelInputModule,
    DeliveryCompanyRoutingModule,
    NgxIntlTelInputModule,
    MaterialModule,
  ],
})
export class DeliveryCompanyModule {}
