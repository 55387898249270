import { Component, OnInit, TemplateRef, ChangeDetectorRef, ViewChild } from '@angular/core';

import { constEnv } from 'common/constants.env';
import { AlertService } from 'ngx-alerts';
import { first } from 'rxjs/operators';
import { DataTableDirective } from 'angular-datatables';
import { TitleService } from 'common/services/title.service';
import { MatDialog } from '@angular/material';
import { IsLoadingService } from '@service-work/is-loading';
import { SpecificationService } from 'common/services/store/specification.service';
import { ModalDirective } from 'ngx-bootstrap';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LanguageService } from 'common/services/language.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-values',
  templateUrl: './values.component.html',
  styleUrls: ['./values.component.css']
})
export class ValuesComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  config = constEnv;

  lists: SpecificationValueList[] = null;
  specid: number = 0;

  loading = false;

  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;

  @ViewChild('actionModal', { static: false }) actionModal: ModalDirective;
  isActionModalShown = false;

  actionForm: FormGroup;
  editActionForm: FormGroup;

  availableLangs;

  action = 'add';

  specModalTitle: string;

  constructor(
    private alert: AlertService,
    private isLoadingService: IsLoadingService,    
    private titleService: TitleService,
    private specification: SpecificationService,
    private lang: LanguageService,
    private fb: FormBuilder,
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    this.titleService.setTitle('SpecificationValues');
    if ( this.isLoadingService.isLoading$() ) {
      this.isLoadingService.remove();
    }

    const id = this.route.snapshot.params.specid;
    this.specid = (typeof id !== 'undefined') ? id : 0;
    if (this.specid <= 0) {
      this.router.navigate([`${this.config.STORE_URL}/product/specification`]);
    }

    this.availableLangs = this.lang.getAvailableLanguages();

    this.createDataTable();
  }

  createActionForm() {
    this.actionForm = this.fb.group({
      languagesArray: this.fb.array([]),
    });

    this.addAvailableLanguages();

    this.editActionForm = this.fb.group({
      specValueLangId: [0],
      value: ['', Validators.required],
      specValueList: this.fb.array([]),
      specNameLangIdList: this.fb.array([]),
    });
  }

  get a() { return this.actionForm.controls; }
  get l() { return this.a.languagesArray as FormArray; }

  trackByFn(index: any) {
    return index;
  }

  addAvailableLanguages() {
    this.availableLangs.forEach(singleLang => {
        this.l.push(this.fb.group({
          specValueLangId: [0],
          value: [''],
          specValueList: ['', Validators.required],
          specNameLangIdList: [''],
          langId: [singleLang.id]
        }));
    });
  }

  createDataTable() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      ordering: false,
      lengthChange: false,
      searching: false,
      serverSide: true,
      processing: true,
      // pageLength: 2,
      language: {
        paginate: {
          previous: '<',
          first: '<<',
          last: '>>',
          next: '>'
        }
      },
      ajax: (dataTablesParameters: any, callback) => {       
        this.specification.getAllSpecValue( this.specid, dataTablesParameters )
        .pipe(first())
        .subscribe(data => {
          const res = data as SpecificationValueListsResponse;
          let paging: Paging;
          if (res.status === true) {
            this.lists = res.data as SpecificationValueList[];
            
            paging = JSON.parse(res.paging) as Paging;            
          } else {
            this.alert.danger(data['message']);
            this.lists = [];
          }

          callback({
            recordsTotal: paging.totalCount,
            recordsFiltered: paging.totalRowsAfterFiltering,
            data: []
          });

          this.isLoadingService.remove();
        },
          err => {
            if (typeof err.error !== 'undefined') {
              const msg = (typeof err.error.Message !== 'undefined') ? err.error.Message : err.error.message;
              this.alert.danger(msg);              
              this.lists = [];
            }

            callback({
              recordsTotal: 0,
              recordsFiltered: 0,
              data: []
            });

            this.isLoadingService.remove();
          });
      },
      // columns: [{ data: 'id' }, { data: 'firstName' }, { data: 'lastName' }]
    };
  }

  showModal(action: string = 'add', specId: number = 0): void {
    if(action === 'add') {
      this.action = 'add';
      this.specModalTitle = 'Create Specification Value';
      this.createActionForm();
      
      this.isLoadingService.add();
      this.specification.getCreateSpecification( this.specid )
      .subscribe(
        data => {
          if(data['status'] === true) {
            const res = data['data'];
            if(typeof res['specLangData'] !== 'undefined') {
              let languageWiseData = [];
              res['specLangData'].forEach(specLang => {
                languageWiseData[specLang['langId']] = specLang['specNameLangId'];
              });

              this.l.controls.forEach((singleRow, i) => {
                const singleLang = (typeof languageWiseData[singleRow.value.langId] !== 'undefined') ? languageWiseData[singleRow.value.langId] : 0;
                if(singleLang > 0){
                  this.l.controls[i].get('specNameLangIdList').setValue(singleLang);
                }
              });
              this.isLoadingService.remove();
              this.isActionModalShown = true;
            }
          } else {
            this.alert.danger(data['message']);
            this.isLoadingService.remove();
          }
        },
        err => {
          if (typeof err.error !== 'undefined') {
            const msg = (typeof err.error.Message !== 'undefined') ? err.error.Message : err.error.message;
            this.alert.danger(msg);
          }
          this.isLoadingService.remove();
          this.loading = false;
        }
      );

  
    } else {
      this.action = 'edit';
      this.isLoadingService.add();
      this.specification.getSpecificationValue( specId )
      .subscribe(
        data => {
          const res = data as SpecificationValueResponse;

          if(res.status === true) {

            this.createActionForm();

            this.editActionForm.get('specValueLangId').setValue( res.data[0].specValueLangId );
            this.editActionForm.get('value').setValue( res.data[0].value );

            this.isActionModalShown = true;

          } else {
            this.alert.danger(res.message);
          }
          
          this.isLoadingService.remove();
        },
        err => {
          if (typeof err.error !== 'undefined') {
            const msg = (typeof err.error.Message !== 'undefined') ? err.error.Message : err.error.message;
            this.alert.danger(msg);
          }
          this.isLoadingService.remove();
          this.loading = false;
        }
      ); 

      this.specModalTitle = 'Update Specification Value';
    }
    
  }

  onHidden(): void {
    this.isActionModalShown = false;    
    this.actionForm.reset();
    this.editActionForm.reset();

    this.action = 'add';
  }

  hideModal(): void {
    this.actionModal.hide();
  }

  onActionSubmit() {
    if(this.actionForm.invalid) {
      return;
    }
    
    this.isLoadingService.add();
    
    const langValueLists = [];
    const specNameLangIdList = [];
    this.actionForm.value.languagesArray.forEach(element => {
      langValueLists.push(element.specValueList);
      specNameLangIdList.push(element.specNameLangIdList);
    });

    const formData = {
      specValueLangId: 0,
      value: "",
      specValueList: langValueLists,
      specNameLangIdList: specNameLangIdList
    }
   

    this.addEditSPecValue( formData );
  }

  onEditActionSubmit() {
    if(this.editActionForm.invalid) {
      return;
    }
    
    this.isLoadingService.add();
    
    const formData = {
      specValueLangId: this.editActionForm.value.specValueLangId,
      value: this.editActionForm.value.value,
      specValueList: [],
      specNameLangIdList: []
    }    

    this.addEditSPecValue( formData );
  }

  addEditSPecValue( formData ) {
    this.specification.addEditSpecificationValue( formData )
    .subscribe(
      data => {

        if(data['status'] === true) {
          this.hideModal();
          this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => dtInstance.draw());
          this.alert.success( data['message'] )
        } else {
          this.alert.danger( data['message'] );
        }
        this.isLoadingService.remove();
      },
      err => {
        if (typeof err.error !== 'undefined') {
          const msg = (typeof err.error.Message !== 'undefined') ? err.error.Message : err.error.message;
          this.alert.danger(msg);
        }
        this.isLoadingService.remove();
        this.loading = false;
      }
    );
  }

}

interface SpecificationValueListsResponse {
  status: boolean;
  message: string;
  data: any[];
  paging: string;
}

interface Paging {
  totalCount: number;
  totalRowsAfterFiltering: number;
  pageSize: number;
  currentPage: number;
  totalPages: number;
  previousPage: string;
  nextPage: string;
}

interface SpecificationValueList {
  langId: number;
  langName: string;
  name: string;
  specNameId: number;
  specNameLangId: number;
  specValueLangId: number;
  value: string;
}

interface SpecificationValueResponse {
  status: boolean;
  message: string;
  data: SpecificationValue;
}

interface SpecificationValue {
  langId: number;
  specNameLangId: number;
  specValueLangId: number;
  value: string;
}