export class Product {
  public productId: number;
  public productName: string;
  public serviceTypeId: number;
  public categoryName?: string;
  public categoryId?: number;
  public subCategoryId?: number;
  public productImage: string;
  public productDesc?: string;
  public status: boolean;
  public isPublish: boolean;
  public productQty: number;
  public productPrice: number;
  public productType: number;
  public brandId?: number;
  public itemTagId?: number;
  public itemTypeId?: number;
  public productTagIds?: number[];
  public productOfferAmt?: number;
  public videoURL?: string;

  public themeId?: number;
  public colorPaletteId?: number;
  public isFragile?: boolean;
  public isReturnable?: boolean;

  public isAssemblyRequired?: boolean;
  public assemblyCharges?: number;

  public primaryImage?: string;
  public isPrimaryImage?: boolean;
  public secondaryImage?: SecondaryImage[];

  topViewImage: string;
  topViewImageId: number;

  augmentedViewImage: string;
  augmentedViewImageId: number;

  modelJsonViewImage: string;
  modelJsonViewImageId: number;

  public productDimensions: ProductDimension;
  public inventory?: Inventory;
  public shipping?: Shipping;
  public linkedProducts?: LinkedProducts;
  public extra?: Extra;
  public productLanguages?: ProductLanguages[];
  public specification?: ProductSpecification[];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  getStatus() {
    return this.status === true ? "Active" : "Inactive";
  }
}

export interface Inventory {
  sku: string;
  qty: number;
  productStock: boolean;
  soldIndividually: boolean;
}

export interface ProductDimension {
  weight: number;
  length: number;
  width: number;
  height: number;
}

export interface Shipping {
  processingTime: number;
  weight: number;
  length: number;
  width: number;
  height: number;
}

export interface ProductLinked {
  key: number;
  value: string;
}

export interface LinkedProducts {
  recommendedProduct: ProductLinked[];
  crossSellProduct: ProductLinked[];
}

export interface Extra {
  manufacturingDate: string;
  warranty: number;
  isFreeReturnPolicy: boolean;
  isInstallmentAvailable: boolean;
  additionalPackingCharges: number;
  spaceTypeId: number;
}

export interface ProductLanguages {
  productId: number;
  languageId: number;
  productName: string;
  productDesc: string;
}

export interface SecondaryImage {
  key: number;
  value: string;
}

export interface ProductSpecification {
  id: number;
  specificationId: number;
  productId: number;
  productSpecification: string;
  productSpecificationValueIds: string;
  productSpecificationValues: string;
  specNameLangId: number;
}
