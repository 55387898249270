import { Component, OnInit, ViewChild } from "@angular/core";
import { DataTableDirective } from "angular-datatables";
import { Config } from "datatables.net";
import { constEnv } from "common/constants.env";
import { CustomerService } from "common/services/customers/customer.service";
import { LoaderService } from "common/services/loader.service";
import { TitleService } from "common/services/title.service";
import { AlertService } from "@full-fledged/alerts";
import { first } from "rxjs/operators";

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.css"],
})
export class ListComponent implements OnInit {
  config = constEnv;

  hasList = false;
  cancelRequestLists: CancelRequestItem[] = [];

  @ViewChild(DataTableDirective, { static: false })
  private dataTableElement: DataTableDirective;
  // dtOptions: DataTables.Settings = {};
  dtOptions: Config = {};

  constructor(
    private titleService: TitleService,
    private ls: LoaderService,
    private customer: CustomerService,
    private alert: AlertService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Cancel Request");

    this.createDataTable();
  }

  createDataTable() {
    this.dtOptions = {
      pagingType: "full_numbers",
      ordering: false,
      lengthChange: false,
      searching: false,
      serverSide: true,
      processing: true,
      // pageLength: -1,
      language: {
        paginate: {
          previous: "<",
          first: "<<",
          last: ">>",
          next: ">",
        },
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.hasList = true;
        this.customer
          .getCancelRequestLists(dataTablesParameters)
          .pipe(first())
          .subscribe(
            (resp) => {
              const data = resp as CancelRequestLsiResponse;
              let paging: any;

              if (data.status === true) {
                this.cancelRequestLists = data.data;
                paging = JSON.parse(resp["paging"]);
                // paging = { totalCount: this.lists.length, totalRowsAfterFiltering: this.lists.length };
              } else {
                paging = { totalCount: 0, totalRowsAfterFiltering: 0 };
                this.cancelRequestLists = [];
                this.hasList = false;
              }

              if (this.cancelRequestLists.length > 0) {
                this.hasList = true;
              }

              callback({
                recordsTotal: paging.totalCount,
                recordsFiltered: paging.totalRowsAfterFiltering,
                data: [],
              });
            },
            (err) => {
              const msg =
                typeof err.error.Message !== "undefined"
                  ? err.error.Message
                  : err.error.message;
              if (msg !== "") {
                // this.alert.danger(err.error.message);
              }
              callback({
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              });
            }
          );
      },
    };
  }
}

interface CancelRequestLsiResponse {
  status: boolean;
  message: string;
  data: CancelRequestItem[];
}

interface CancelRequestItem {
  id: number;
  ticketId: string;
  createdDate: string;
  customerName: string;
  customerPhone: string;
  statusName: string;
  storeId: number;
  statusColor: string;
  backgroundColor: string;
  itemReturnReason: string;
  orderTotal: string;
  productName: string;
  productPrice: string;
  productImage: string;
  totalItem: number;
  isAccepted: boolean;
  isClosed: boolean;
  isInProgress: boolean;
  storeName: string;
  customerId: number;
  customerLangId: number;
  storeLangId: number;
  customerEmail: string;
  storeEmail: string;
  description: string;
  attachment: string;
  orderId: number;
  orderBaseDetailId: number;
  orderNo: string;
  statusId: number;
  reason: string;
  currencyCode: string;
}
