import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AlertService } from "@full-fledged/alerts";
import { SettingsService } from "common/services/store/settings.service";
import { TitleService } from "common/services/title.service";
import { IsLoadingService } from "@service-work/is-loading";
import { Paging } from "src/app/e-commerce/category/category.component";
import { constEnv } from "common/constants.env";
import { ModalDirective } from "ngx-bootstrap/modal";
import { Observable, of, Subject } from "rxjs";
import {
  catchError,
  distinctUntilChanged,
  switchMap,
  tap,
} from "rxjs/operators";
import { DataTableDirective } from "angular-datatables";
import { Config } from "datatables.net";
import { ConfirmDialogComponent } from "common/_components/confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-pickup-address-list",
  templateUrl: "./pickup-address-list.component.html",
  styleUrls: ["./pickup-address-list.component.css"],
})
export class PickupAddressListComponent implements OnInit {
  pickupAddressLists: PickupAddress[] = null;

  // dtOptions: DataTables.Settings = {};
  dtOptions: Config = {};
  // dtOptionsCancelOrder: DataTables.Settings = {};
  dtOptionsCancelOrder: Config = {};

  config = constEnv;

  lists: PickupAddress[] = [];
  hasLists = false;

  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;

  @ViewChild("autoShownModal", { static: false })
  autoShownModal: ModalDirective;
  isModalShown = false;

  pickupaddressActionForm: FormGroup;
  loading = false;

  autocompleteLists$: Observable<AutocompleteAddress[]>;
  autocompleteLoading = false;
  autocompleteInput$ = new Subject<string>();

  constructor(
    private fb: FormBuilder,
    private alert: AlertService,
    private isLoadingService: IsLoadingService,
    private settings: SettingsService,
    private titleService: TitleService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.titleService.setTitle("PickupAddress");

    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }

    this.createDataTable();
  }

  createDataTable() {
    this.dtOptions = {
      pagingType: "full_numbers",
      ordering: false,
      lengthChange: false,
      searching: false,
      serverSide: true,
      processing: true,
      // pageLength: 2,
      language: {
        paginate: {
          previous: "<",
          first: "<<",
          last: ">>",
          next: ">",
        },
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.settings.getPickupaddressLists().subscribe(
          (data) => {
            const res = data as ListResponse;
            let paging = {
              totalCount: 0,
              totalRowsAfterFiltering: 0,
            } as Paging;
            if (res.status === true) {
              this.lists = res.data as PickupAddress[];
              // paging = JSON.parse(data.paging) as Paging;
              paging.totalCount = this.lists.length;
              paging.totalRowsAfterFiltering = this.lists.length;
            } else {
              this.lists = [];
              this.hasLists = false;
              paging.totalCount = 0;
              paging.totalRowsAfterFiltering = 0;
              this.alert.danger(res.message);
            }

            callback({
              recordsTotal: paging.totalCount,
              recordsFiltered: paging.totalRowsAfterFiltering,
              data: [],
            });
          },
          (err) => {
            if (typeof err.error !== "undefined") {
              const msg =
                typeof err.error.Message !== "undefined"
                  ? err.error.Message
                  : err.error.message;
              this.alert.danger(msg);
            }
            callback({
              recordsTotal: 0,
              recordsFiltered: 0,
              data: [],
            });
            this.isLoadingService.remove();
          }
        );
      },
    };
  }

  createActionForm() {
    this.pickupaddressActionForm = this.fb.group({
      id: [0],
      contactPerson: [null, Validators.required],
      location: [null, Validators.required],
      locationText: [null],
      pickupAddressLatitude: [null],
      pickupAddressLongitude: [null],
      addressLine: [null, Validators.required],
    });
  }

  trackByFn(item: AutocompleteAddress) {
    return item.text;
  }

  showModal(action: string, addressId: number = 0): void {
    if (action === "edit") {
      this.isLoadingService.add();
      this.settings.getPickupAddressDetail(addressId).subscribe(
        (data) => {
          if (data["status"] === true) {
            const addressData = data["data"] as PickupAddress;
            this.createActionForm();
            this.loadAutocompleteAddress();
            this.pickupaddressActionForm.patchValue({
              id: addressData.id,
              contactPerson: addressData.contactPerson,
              location: addressData.location,
              locationText: addressData.location,
              pickupAddressLatitude: addressData.latitude,
              pickupAddressLongitude: addressData.longitude,
              addressLine: addressData.addressLine,
            });
            this.isModalShown = true;
          } else {
            this.alert.danger(data["message"]);
          }
          this.isLoadingService.remove();
        },
        (err) => {
          if (typeof err.error !== "undefined") {
            const msg =
              typeof err.error.Message !== "undefined"
                ? err.error.Message
                : err.error.message;
            this.alert.danger(msg);
          }
          this.isLoadingService.remove();
        }
      );
    } else {
      this.createActionForm();
      this.loadAutocompleteAddress();
      this.isModalShown = true;
    }
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
    this.pickupaddressActionForm.reset();
  }

  loadAutocompleteAddress() {
    this.autocompleteLists$ = this.autocompleteInput$.pipe(
      distinctUntilChanged(),
      tap(() => (this.autocompleteLoading = true)),
      switchMap((term) =>
        this.settings.searchAddress(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => (this.autocompleteLoading = false))
        )
      )
    );
  }

  getAutocompeteAddresDetail($event: AutocompleteAddress) {
    if (typeof $event !== "undefined") {
      this.settings.getAddressDetail($event.magicKey, $event.text).subscribe(
        (data) => {
          this.pickupaddressActionForm
            .get("locationText")
            .setValue($event.text);
          this.pickupaddressActionForm
            .get("pickupAddressLatitude")
            .setValue(data.location.x);
          this.pickupaddressActionForm
            .get("pickupAddressLongitude")
            .setValue(data.location.y);
        },
        (err) => {
          if (typeof err.error !== "undefined") {
            const msg =
              typeof err.error.Message !== "undefined"
                ? err.error.Message
                : err.error.message;
            this.alert.danger(msg);
          }
        }
      );
    }
  }

  deletePickupAddress(id: number) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm",
        message: "DeleteConfirm",
      },
    });

    confirmDialog.afterClosed().subscribe((result) => {
      if (result === true) {
        this.isLoadingService.add();
        this.settings.deletePickupAddress(id).subscribe(
          (data) => {
            if (data["status"] === true) {
              this.alert.success(data["message"]);
              this.datatableElement.dtInstance.then(
                // (dtInstance: DataTables.Api) => dtInstance.draw()
                (dtInstance) => dtInstance.draw()
              );
            } else {
              this.alert.danger(data["message"]);
            }
            this.isLoadingService.remove();
          },
          (err) => {
            if (typeof err.error !== "undefined") {
              const msg =
                typeof err.error.Message !== "undefined"
                  ? err.error.Message
                  : err.error.message;
              this.alert.danger(msg);
            }

            this.isLoadingService.remove();
          }
        );
      }
    });
  }

  onSubmit() {
    if (this.pickupaddressActionForm.invalid) {
      return;
    }

    this.loading = true;

    const formData = {
      contactPerson: this.pickupaddressActionForm.value.contactPerson,
      addressLine: this.pickupaddressActionForm.value.addressLine,
      location: this.pickupaddressActionForm.value.locationText,
      latitude: this.pickupaddressActionForm.value.pickupAddressLatitude,
      longitude: this.pickupaddressActionForm.value.pickupAddressLongitude,
    };

    const action = this.pickupaddressActionForm.value.id > 0 ? "update" : "add";
    if (action === "update") {
      formData["id"] = this.pickupaddressActionForm.value.id;
    }

    this.isLoadingService.add();
    this.settings.pickupLocationAction(action, formData).subscribe(
      (data) => {
        if (data["status"] === true) {
          this.alert.success(data["message"]);
          // this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) =>
          this.datatableElement.dtInstance.then((dtInstance) =>
            dtInstance.draw()
          );
          this.hideModal();
        } else {
          this.alert.danger(data["message"]);
        }
        this.isLoadingService.remove();
        this.loading = false;
      },
      (err) => {
        if (typeof err.error !== "undefined") {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          this.alert.danger(msg);
        }
        this.loading = false;
        this.isLoadingService.remove();
      }
    );
  }
}

export interface AutocompleteAddress {
  text: string;
  magicKey: string;
  isCollection: boolean;
}

export interface StoreAddressDetail {
  address: string;
  location: AddressDetailLocation;
  attributes: any;
  extent: any;
  score: number;
}

interface AddressDetailLocation {
  x: number;
  y: number;
}

interface ListResponse {
  status: boolean;
  message: string;
  data: PickupAddress[];
}

interface PickupAddress {
  id: number;
  contactPerson: string;
  addressLine: string;
  location: string;
  latitude: number;
  longitude: number;
}
