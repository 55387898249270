import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TemplateModule } from '../template/template.module';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'common/modules/material.module';


import { PaymentRoutingModule } from './payment-routing.module';
import { SuccessComponent } from './success/success.component';
import { CancelComponent } from './cancel/cancel.component';
import { DeclineComponent } from './decline/decline.component';
import { PaymentActionComponent } from './payment-action/payment-action.component';


@NgModule({
  declarations: [SuccessComponent, CancelComponent, DeclineComponent, PaymentActionComponent],
  imports: [
    CommonModule,
    TranslateModule.forRoot(),
    MaterialModule,
    TemplateModule,
    PaymentRoutingModule,
  ]
})
export class PaymentModule { }
