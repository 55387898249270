import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { TitleService } from 'common/services/title.service';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from 'common/services/general.service';
import { IsLoadingService } from '@service-work/is-loading';
import { first } from 'rxjs/operators';
import { AlertService } from 'ngx-alerts';

@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.css']
})
export class CmsComponent implements OnInit, OnDestroy {

  pageName: string;
  pageTitle: string;
  pageContent;
  acceptPages = ['aboutus', 'errorpage', 'helpcenter', 'howitwork', 'privacypolicy', 'termsofuse', 'trustandsafety','paymentpolicy'];


  constructor(
    private route: ActivatedRoute,
    private titleService: TitleService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private gs: GeneralService,
    private alert: AlertService,
    private isLoadingService: IsLoadingService,
  ) { }

  ngOnInit() {
    // this.titleService.setTitle('Home');

    if ( this.isLoadingService.isLoading$() ) {
      this.isLoadingService.remove();
    }

    this.route.params.subscribe(
      params => {
        this.pageName = params['pagename'];
        if ( this.acceptPages.indexOf( this.pageName ) === -1 ) {
          this.router.navigate([`/home`]);
        }

        this.loadCmsPage();
      });
  }

  ngOnDestroy(): void {
    this.titleService.removeMetaTag('title');
    this.titleService.removeMetaTag('keywords');
    this.titleService.removeMetaTag('description');
    this.document.body.classList.remove('front-body');
  }

  loadCmsPage() {
    this.isLoadingService.add();
    this.pageTitle = '';
    this.pageContent = '';
    this.titleService.setTitle( this.pageName );
    this.gs.getCmsPageContent( this.pageName )
    .pipe(first())
    .subscribe(data => {
      if (data['status']) {
        if ( typeof data['data'] !== 'undefined' ) {
          this.pageTitle = data['data'].title;
          this.pageContent = data['data'].description;
          this.titleService.setTitle( this.pageTitle );

          this.titleService.setMetaTag( 'title', data['data'].metaTitle );
          this.titleService.setMetaTag( 'keywords', data['data'].metaKeyword );
          this.titleService.setMetaTag( 'description', data['data'].metaDescription );
        }
      }
      this.isLoadingService.remove();
    },
    err => {
      // this.alert.error(err.error.Message);
      const msg = (typeof err.error.Message !== 'undefined') ? err.error.Message : err.error.message;
      if (msg !== '') {
        this.alert.danger(err.error.message);          
      }
      this.isLoadingService.remove();
    });
  }

}
