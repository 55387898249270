import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { IsLoadingService } from "@service-work/is-loading";
import { constEnv } from "common/constants.env";
import { CustomerService } from "common/services/customers/customer.service";
import { TitleService } from "common/services/title.service";
import { AlertService } from "@full-fledged/alerts";

@Component({
  selector: "app-detail",
  templateUrl: "./detail.component.html",
  styleUrls: ["./detail.component.css"],
})
export class DetailComponent implements OnInit {
  config = constEnv;
  requestId: number = 0;

  requestDetail: any;

  constructor(
    private titleService: TitleService,
    private cs: CustomerService,
    private alert: AlertService,
    private ls: IsLoadingService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Cancel Request Detail");
    if (this.ls.isLoading$()) {
      this.ls.remove();
    }

    this.requestId =
      this.route.snapshot.params.id !== "undefined"
        ? this.route.snapshot.params.id
        : 0;

    if (this.requestId <= 0) {
      this.router.navigate([this.config.CUSTOMER_URL, "cancel-request"]);
    }

    this.getCancelRequestDetail();
  }

  getCancelRequestDetail() {
    this.ls.add();
    this.cs.getCancelRequestDetail(this.requestId).subscribe(
      (data) => {
        if (data["status"] === true) {
          this.requestDetail = data["data"];
        } else {
          this.alert.danger(data["message"]);
        }
        this.ls.remove();
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          this.alert.danger(err.error.message);
        }
        this.ls.remove();
      }
    );
  }
}
