export class Earnings {
    rowNum: number;
    totalRows: number;
    orderId: number;
    orderNo: string;
    userTypeId: number;
    orderDate: string;
    amount: number;
    isSettelled: boolean;
    orderStatus: string;
    statusColor: string;
    backgroundColor: string;
    totalItem: number;
    balance: number;
    type: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
