import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthPagesModule } from './auth-pages/auth-pages.module';
import { AlertModule } from 'ngx-alerts';

const appProviders = [];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AuthPagesModule,
    AlertModule.forRoot({maxMessages: 1, timeout: 2500, position: 'right'}),
  ],
  providers: appProviders,
  bootstrap: [AppComponent]
})
export class AppModule { }

@NgModule({})
export class DriverSharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AppModule,
      providers: appProviders
    };
  }
}
