import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  IterableDiffers,
  ViewChild,
} from "@angular/core";
import { constEnv } from "common/constants.env";

import { DOCUMENT } from "@angular/common";

import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  AbstractControl,
} from "@angular/forms";
import { first } from "rxjs/operators";
import { AlertService } from "@full-fledged/alerts";
import { CustomValidator } from "common/_helpers/custom.validator";
import { IsLoadingService } from "@service-work/is-loading";
import { Observable } from "rxjs";
import { TitleService } from "common/services/title.service";
import { CustomerService } from "common/services/customers/customer.service";
import { CustomerUser } from "common/_models/customers/customerUser";

import { LanguageService } from "common/services/language.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit, OnDestroy {
  env = constEnv;
  regiForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  countryCode: string;
  countryIsoCode: string;
  validMobileNo = true;

  isLoading: Observable<boolean>;

  iniObj: any;
  constantConfig = constEnv;
  profileForm: FormGroup;

  accountForm: FormGroup;
  accountSubmitted = false;

  socialCountryCode: string;
  socialCountryIsoCode: string;

  iniSocailObj: any;

  hasAccountEmail = false;

  public showPassword: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private titleService: TitleService,
    private isLoadingService: IsLoadingService,
    private customer: CustomerService,
    private langService: LanguageService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Sign_Up");
    this.isLoading = this.isLoadingService.isLoading$();

    this.document.body.classList.add("page-register");

    this.createSignUpFrom();

    this.returnUrl =
      this.route.snapshot.queryParams.returnUrl || `${this.env.CUSTOMER_URL}`;
  }
  getCustomerData() {
    this.isLoadingService.add();
    this.loading = true;
    this.customer.getProfile().subscribe(
      (data) => {
        /*if (data['status'] === true) {*/

        const customerData = new CustomerUser().deserialize(data);

        this.setFormValue(customerData);
        this.loading = false;
        /*} else {*/
        // this.alert.error(data['message']);
        //this.isLoadingService.remove();
        /*}*/
      },
      (err) => {
        // this.alert.error(err.error.message);
        this.loading = false;
      }
    );
  }
  ngOnDestroy(): void {
    this.document.body.classList.remove("page-register");
  }

  createSignUpFrom() {
    // tslint:disable-next-line: max-line-length
    const emailregex: RegExp =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    this.regiForm = this.formBuilder.group(
      {
        firstName: ["", Validators.required],
        lastName: ["", Validators.required],
        mobileNo: ["", Validators.required],
        countryCode: this.countryCode,
        countryIsoCode: this.countryIsoCode,
        email: new FormControl(
          "",
          Validators.compose([
            Validators.required,
            Validators.email,
            Validators.pattern(emailregex),
          ])
        ),
        password: new FormControl(
          "",
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(16),
          ])
        ),
        terms: [false, Validators.required],
        // recaptchaReactive: new FormControl(null, Validators.required)
      },
      { validator: CustomValidator.checkTerms }
    );
  }

  get formData() {
    return this.regiForm.controls;
  }

  hasError(obj) {
    if (!obj && this.countryIsoCode === "ae") {
      const selectedNumber = this.iniObj.getNumber();
      this.validMobileNo =
        /^(?:\+971|00971|0)?(?:50|52|53|54|55|56|57|58|59|2|3|4|6|7|9)\d{7}$/gm.test(
          selectedNumber
        );
    } else {
      this.validMobileNo = obj;
    }
  }

  onCountryChange(obj) {
    this.countryCode = obj.dialCode;
    this.countryIsoCode = obj.iso2;
  }

  telInputObject(obj) {
    this.iniObj = obj;
    const countryData = obj.getSelectedCountryData();
    this.countryCode = countryData.dialCode;
    this.countryIsoCode = countryData.iso2;
  }

  getErrorEmail() {
    return this.regiForm.get("email").hasError("required")
      ? ""
      : this.regiForm.get("email").hasError("pattern")
      ? "EmailInValid"
      : // ? this.titleService.getTranslatedString("EmailInValid")
        "";
  }

  getErrorPassword() {
    return this.regiForm.get("password").hasError("required")
      ? ""
      : this.regiForm.get("password").hasError("minlength")
      ? "PasswordFormate"
      : // ? this.titleService.getTranslatedString("PasswordFormate")
      this.regiForm.get("password").hasError("maxlength")
      ? "PasswordFormate"
      : // ? this.titleService.getTranslatedString("PasswordFormate")
        "";
  }
  setFormValue(customerData: CustomerUser) {
    if (customerData.phone_code !== null) {
      this.iniObj.setNumber(customerData.phone_code);
    }
    // this.isEmailAvailable = (customerData.email !== '') ? true : false;
    this.regiForm.get("firstName").setValue(customerData.first_name);
    this.regiForm.get("lastName").setValue(customerData.last_name);
    this.regiForm.get("mobileNo").setValue(customerData.phone);
    this.regiForm.get("email").setValue(customerData.email);
    this.regiForm
      .get("languageId")
      .setValue(customerData.languageId.toString());
    this.regiForm
      .get("CurrencyId")
      .setValue(customerData.currencyId.toString());
  }
  get f() {
    return this.profileForm.controls;
  }
  onSubmit() {
    this.submitted = true;

    if (this.regiForm.invalid) {
      return;
    }

    if (!this.validMobileNo) {
      return;
    }

    this.loading = true;
    this.isLoadingService.add();

    this.regiForm.value.countryCode = "+" + this.countryCode;
    this.regiForm.value.countryIsoCode = this.countryIsoCode;
    const formData = {
      firstName: this.regiForm.value.firstName,
      lastName: this.regiForm.value.lastName,
      email: this.regiForm.value.email,
      phoneCode: this.countryCode,
      phone: this.regiForm.value.mobileNo,
      password: this.regiForm.value.password,
      regionCode: this.countryIsoCode,
    };
    this.customer
      .register(formData)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data["status"]) {
            this.alertService.success(data["message"]);
            this.submitted = false;
            this.regiForm.reset();
            this.router.navigate([`${this.constantConfig.CUSTOMER_URL}`]);
          } else {
            this.alertService.danger(data["message"]);
          }
          this.loading = false;
        },
        (err) => {
          const msg =
            typeof err.error.message !== "undefined" ? err.error.message : "";
          if (msg) {
            this.alertService.danger(msg);
          }
          this.loading = false;
        }
      );

    setTimeout(() => {
      this.isLoadingService.remove();
      this.loading = false;
    }, 2000);
  }
}
