import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { IsLoadingService } from '@service-work/is-loading';
import { constEnv } from 'common/constants.env';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { SiteGeneralService } from 'common/services/site/siteGeneral.service';
import { SiteProductService } from 'common/services/site/siteProduct.service';
import { TitleService } from 'common/services/title.service';
import { ProductFilterModal } from 'common/_models/site/product-filter.modal';
import { ProductList } from 'common/_models/site/product-list.modal';
import { AlertService } from 'ngx-alerts';
import { filter } from 'rxjs/operators';
import { Paging, ProductFilerSortParam, SortByList } from '../category/category.component';
import { FilterBrandModal } from 'common/_models/site/filter/filter-brand.modal';
import { FilterValueModal } from 'common/_models/site/filter/filter-value.modal';
import { FilterAttributeModal } from 'common/_models/site/filter/filter-attribute.modal';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-hot-deal',
  templateUrl: './hot-deal.component.html',
  styleUrls: ['./hot-deal.component.css']
})
export class HotDealComponent implements OnInit, OnDestroy {

  config = constEnv;
  filters: ProductFilterModal = null;
  productLists: ProductList[] = null;
  sortBy: SortByList[] = null;

  navSubcription;
  pageTitle = '';
  productDetailUrl = `${this.config.BASE_URL}/product/`;

  pagination: Paging;
  itemPerPage = 12;

  brands: FilterBrandModal[] = null;
  subCategories: FilterValueModal[] = null;
  attributes: FilterAttributeModal[] = null;

  searchForm: FormGroup;

  selectedBrands = [];
  selectedSubCategoryIds = [];
  selectedAttributeValues = [];

  productParams = {
    SearchColumn: '',
    SearchValue: '',
    SortColumn: '',
    BrandIds: '',
    SubCategoryIds: '',
    AttributeValueIds: '',
    CategoryId: 0,
    PageNo: 0,
    PageSize: 12,
  } as ProductFilerSortParam;

  constructor(
    private isLoadingService: IsLoadingService,
    private titleService: TitleService,
    private alert: AlertService,
    private general: SiteGeneralService,
    private product: SiteProductService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.titleService.setTitle('HotDeals');

    if ( this.isLoadingService.isLoading$() ) {
      this.isLoadingService.remove();
    }

    this.pagination = {
      totalCount: 0,
      totalRowsAfterFiltering: 0,
      pageSize: this.itemPerPage,
      currentPage: 0,
      totalPages: 0,
      previousPage: '',
      nextPage: ''
    };

    this.createSearchForm();
    this.selectedBrands = [];
    this.selectedSubCategoryIds = [];

    this.navSubcription = this.router.events
      .pipe(
        filter(
          event =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError,
        ),
      )
      .subscribe(event => {
        // If it's the start of navigation, `add()` a loading indicator
        if (event instanceof NavigationStart) {
          this.isLoadingService.add();
          return;
        }

        if (event instanceof NavigationEnd) {
          this.getProductLists();
        }

        // Else navigation has ended, so `remove()` a loading indicator
        this.isLoadingService.remove();
      });

      this.route.queryParams.subscribe( param => {
        this.productParams.CategoryId = (typeof param.CategoryId !== 'undefined') ? param.CategoryId : '';
        this.productParams.SubCategoryIds = (typeof param.SubCategoryIds !== 'undefined') ? param.SubCategoryIds : '';
        this.productParams.SortColumn = (typeof param.SortColumn !== 'undefined') ? param.SortColumn : '';
        this.productParams.SortOrder = (typeof param.SortOrder !== 'undefined') ? param.SortOrder : '';
        this.productParams.SearchValue = (typeof param.SearchValue !== 'undefined') ? param.SearchValue : '';
        this.productParams.BrandIds = (typeof param.BrandIds !== 'undefined') ? param.BrandIds : '';
        this.productParams.AttributeValueIds = (typeof param.AttributeValueIds !== 'undefined') ? param.AttributeValueIds : '';
        this.productParams.PageNo = (typeof param.PageNo !== 'undefined') ? param.PageNo : '';
        this.productParams.PageSize = (typeof param.PageSize !== 'undefined') ? param.PageSize : this.itemPerPage;
      } );


      this.getProductLists();
  }

  ngOnDestroy() {
    this.navSubcription.unsubscribe();
  }

  getProductLists() 
  {
    this.isLoadingService.add();
   
    this.product.getHotDealList( this.productParams )
    .subscribe(
      data => {
        const res = data as HotDealListResponse;
        if (res.status === true) {          
          if(res.data.productList !== null) {
            this.productLists = res.data.productList.map(item => new ProductList().deserialize(item));
          } else {
            this.productLists = [];
          }
          this.sortBy = res.data.sortByList as SortByList[];

          this.pagination = JSON.parse(res.paging) as Paging;

          this.filters = new ProductFilterModal().deserialize(res.data.filters);

          this.brands = this.filters.brands;
          this.subCategories = this.filters.categories;
          this.attributes = this.filters.attributes;

        } else {
          this.alert.danger(res.message);
        }
        this.isLoadingService.remove();
      },
      err => {
        const msg = (typeof err.error.Message !== 'undefined') ? err.error.Message : err.error.message;
        if (msg !== '') {
          // this.alert.danger(err.error.message);          
        }
        this.productLists = [];
        this.isLoadingService.remove();
      }
    );
  }

  changeSortBy( $event: any ) {
    const selectedEle = $event.target;

    const sortOrder = selectedEle.options[selectedEle.selectedIndex].getAttribute('data-sort');
    const sortCol = $event.target.value;

    if(sortCol !== '') {      
      this.router.navigate( [],  {
        queryParams: {
          SortColumn: sortCol,
          SortOrder: sortOrder
        },
        queryParamsHandling: 'merge'
      } );
    }
  }

  pageChange( paging: PageChangedEvent )
  {
    paging.page = (paging.page > 0) ? (paging.page - 1) : 0;
    paging.itemsPerPage = (paging.itemsPerPage) ? paging.itemsPerPage : this.itemPerPage;

    this.router.navigate( [],  {
      queryParams: {
        PageNo: paging.page,
        PageSize: paging.itemsPerPage
      },
      queryParamsHandling: 'merge'
    } );
  }

  createSearchForm() {
    this.searchForm = this.fb.group({
      SearchValue: ['']
    });
  }

  submitSearchValue() {
    const SearchValue = this.searchForm.value.SearchValue;
    // if(SearchValue !== '') {
      this.router.navigate( [],  {
        queryParams: {
          SearchValue: SearchValue
        },
        queryParamsHandling: 'merge'
      } );
    // }
  }

  brandChecked($event) {
    if($event.target.checked) {
      this.selectedBrands.push($event.target.value);
    } else {
      let removeIndex = this.selectedBrands.findIndex(itm => itm===$event.target.value);
      if(removeIndex !== -1)
        this.selectedBrands.splice(removeIndex,1);
    }

    this.router.navigate( [],  {
      queryParams: {
        BrandIds: this.selectedBrands.join(',')
      },
      queryParamsHandling: 'merge'
    } );
  }

  subCatChecked( $event ) {
    if($event.target.checked) {
      let findIndex = this.selectedSubCategoryIds.findIndex(itm => itm===$event.target.value);
      if(findIndex === -1) {
        this.selectedSubCategoryIds.push($event.target.value);
      }
    } else {
      let removeIndex = this.selectedSubCategoryIds.findIndex(itm => itm===$event.target.value);
      if(removeIndex !== -1)
        this.selectedSubCategoryIds.splice(removeIndex,1);
    }

    this.router.navigate( [],  {
      queryParams: {
        SubCategoryIds: this.selectedSubCategoryIds.join(',')
      },
      queryParamsHandling: 'merge'
    } );
  }

  attributeChecked(attrId, $event) {
    if($event.target.checked) {
      this.selectedAttributeValues.push($event.target.value);
    } else {
      let removeIndex = this.selectedAttributeValues.findIndex(itm => itm===$event.target.value);
      if(removeIndex !== -1)
      this.selectedAttributeValues.splice(removeIndex,1);
    }

    this.router.navigate( [],  {
      queryParams: {
        AttributeValueIds: this.selectedAttributeValues.join(',')
      },
      queryParamsHandling: 'merge'
    } );
  }

}


interface HotDealListResponse {
  status: boolean;
  message: string;
  data: ResponseData;
  paging: string;
}

interface ResponseData {
  bannerList: any[];
  productList: ProductList[];
  totalProductCount: number;
  sortByList: any[];
  subCategoryIds: string;
  brandIds: string;
  attributeValueIds: string;
  themeIds: string;
  colorPaletteIds: string;
  categoryName: string;
  subCategoryDetails: any[];
  storeDetails: string;
  filters: ProductFilterModal;
  minPrice: number;
  maxPrice: number;
}