import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { StoreUser } from "common/_models/store/storeUser";
import { constEnv } from "common/constants.env";
import { map } from "rxjs/operators";

import { AuthenticationService } from "common/services/authentication.service";
import { Observable, throwError } from "rxjs";

@Injectable({ providedIn: "root" })
export class StoreUserService {
  currentUserProfile;

  configConstant = constEnv;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {
    this.currentUserProfile =
      this.authenticationService.currentUserProfileValue;
  }

  getProfile() {
    return this.http
      .get<StoreUser>(`${this.configConstant.apiStoreURL}/profile`)
      .pipe(
        map((profile) => {
          let data = [];
          if (profile["status"]) {
            data = profile["data"];
            localStorage.setItem("currentUserProfile", JSON.stringify(data));
          }
          return data;
        })
      );
  }

  getDashboard() {
    return this.http
      .get(`${this.configConstant.apiStoreURL}/WebStoreDashboard/0`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  register(user) {
    const requestHeader = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    const requestData = new HttpParams()
      .set("Email", user.email)
      .set("Password", user.password)
      .set("PhoneCode", user.countryCode)
      .set("Phone", user.mobileNo)
      .set("RegionCode", user.countryIsoCode)
      .set("Name", user.name);

    return this.http
      .post(`${this.configConstant.apiStoreURL}/profile`, requestData, {
        headers: requestHeader,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  forgotPassword(email) {
    // const requestHeader = {
    //     'Content-Type': 'application/x-www-form-urlencoded'
    // };

    const requestData = new HttpParams().set("storeEmail", email);

    return this.http
      .post(
        `${this.configConstant.apiStoreURL}/forgotpassword?storeEmail=${email}`,
        requestData,
        {
          // headers: requestHeader
        }
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  resetPassword(accessToken, newPassword) {
    // const requestData = new HttpParams()
    //     .set('AccessToken', accessToken)
    //     .set('NewPassword', newPassword);
    const requestData = {
      accessToken: accessToken,
      newPassword: newPassword,
    };

    return this.http
      .post(`${this.configConstant.apiStoreURL}/resetpassword`, requestData)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  changeProfilePhoto(formModel) {
    return this.http
      .post(`${this.configConstant.apiStoreURL}/updateprofileimage`, formModel)
      .pipe(
        map((data) => {
          if (data["status"] === true) {
            const responseData = data["data"];
            const imageURL =
              typeof responseData.image_url !== "undefined"
                ? responseData.image_url
                : "";
            if (imageURL !== "") {
              if (typeof this.currentUserProfile !== "undefined") {
                this.currentUserProfile.profile_image = imageURL;
              }
              localStorage.setItem(
                "currentUserProfile",
                JSON.stringify(this.currentUserProfile)
              );
            }
          }
          return data;
        })
      );
  }

  delete(id: number) {
    return this.http.delete(`${this.configConstant.apiStoreURL}/users/${id}`);
  }

  getHeaderCounters() {
    return this.http.get(`${this.configConstant.apiStoreURL}/GetCounts`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  verifyEmail(token: string) {
    return this.http
      .get(`${this.configConstant.apiStoreURL}/verify/${token}`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getStoreServiceTypeId() {
    const storeProfile = this.authenticationService.currentUserProfileValue;

    return typeof storeProfile.ServiceTypeId !== "undefined"
      ? storeProfile.ServiceTypeId
      : 0;
  }

  getStoreCurrency() {
    const currentUserValue = this.authenticationService.currentUserValue;
    const defaultCurrency =
      typeof currentUserValue["default_currency_code"] !== "undefined"
        ? currentUserValue["default_currency_code"]
        : "USD";
    const userCurrancy = currentUserValue["userCurrancy"];

    return typeof userCurrancy !== "undefined" ? userCurrancy : defaultCurrency;
  }

  checkSocialLogin(socialId, socialType, socialEmail) {
    const formData = {
      socialType: socialType,
      socialId: socialId,
      email: socialEmail,
    };

    return this.http
      .post(`${this.configConstant.apiStoreURL}/checklogin`, formData)
      .pipe(
        map((data) => {
          if (data["status"] === true) {
            localStorage.setItem("currentUser", JSON.stringify(data["data"]));
            this.authenticationService.currentUserSubject.next(data["data"]);
          }

          return data;
        })
      );
  }

  socialLogin(formData) {
    return this.http
      .post(`${this.configConstant.apiStoreURL}/externallogin`, formData)
      .pipe(
        map((data) => {
          localStorage.setItem("currentUser", JSON.stringify(data["data"]));
          this.authenticationService.currentUserSubject.next(data["data"]);
          return data;
        })
      );
  }

  getCodPaymentReceivedLists(dataTablesParameters) {
    const pageNo =
      (dataTablesParameters.start * 1) / (dataTablesParameters.length * 1);

    const searchParams = new HttpParams()
      .set("searchColumn", "")
      .set("searchValue", "")
      .set("pageNo", pageNo.toString())
      .set("pageSize", dataTablesParameters.length)
      .set("sortColumn", "")
      .set("sortOrder", "");

    return this.http
      .get(`${this.configConstant.apiStoreURL}/PastPayments`, {
        params: searchParams,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getPaymentReceivedDetailbyId(PayId) {
    return this.http
      .get(`${this.configConstant.apiStoreURL}/GetOrderDetailsByPayId/${PayId}`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getStoreDashboard() {
    return this.http
      .get(`${this.configConstant.apiStoreURL}/loaddashboard`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
