import { Component, Input, OnInit } from '@angular/core';
import { constEnv } from 'common/constants.env';

import { Payment } from 'common/_models/site/payment.modal';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {

  @Input() paymentInfo: Payment;
  config = constEnv;

  constructor() { }

  ngOnInit() {
  }

}
