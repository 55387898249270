export class VariationInventory {
    name: string;
    stockQuantity: number;
    productId: number;
    productVariationId: number;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
