import { Component, OnInit, ViewChild } from "@angular/core";
import { DataTableDirective } from "angular-datatables";
import { Config } from "datatables.net";
import { TitleService } from "common/services/title.service";
import { constEnv } from "common/constants.env";
import { CustomerService } from "common/services/customers/customer.service";
import { first } from "rxjs/operators";
import { AlertService } from "@full-fledged/alerts";
import { IsLoadingService } from "@service-work/is-loading";
import { ModalDirective } from "ngx-bootstrap/modal";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.css"],
})
export class ListComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;
  // dtOptions: DataTables.Settings = {};
  dtOptions: Config = {};

  @ViewChild("autoShownModal", { static: false })
  autoShownModal: ModalDirective;
  isModalShown = false;

  hasList = false;
  lists: JobList[] = [];
  config = constEnv;

  jobForm: FormGroup;
  loading = false;

  spaceTypes: SpaceType[] = [];

  constructor(
    private titleService: TitleService,
    private cs: CustomerService,
    private alert: AlertService,
    private fb: FormBuilder,
    private ls: IsLoadingService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Jobs");
    if (this.ls.isLoading$()) {
      this.ls.remove();
    }

    this.createDataTable();
  }

  createAttributeForm() {
    this.jobForm = this.fb.group({
      id: [0],
      name: ["", Validators.required],
      spaceTypeId: [null, Validators.required],
    });
  }

  createDataTable() {
    this.dtOptions = {
      pagingType: "full_numbers",
      ordering: false,
      lengthChange: false,
      searching: false,
      serverSide: true,
      processing: true,
      // pageLength: -1,
      language: {
        paginate: {
          previous: "<",
          first: "<<",
          last: ">>",
          next: ">",
        },
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.hasList = true;
        this.cs
          .getCustomerJobs(dataTablesParameters)
          .pipe(first())
          .subscribe(
            (resp) => {
              const data = resp as JobListResponse;
              let paging: any;

              if (data.status === true) {
                this.lists = data.data;
                paging = JSON.parse(resp["paging"]);
                // paging = { totalCount: this.lists.length, totalRowsAfterFiltering: this.lists.length };
              } else {
                paging = { totalCount: 0, totalRowsAfterFiltering: 0 };
                this.lists = [];
                this.hasList = false;
              }

              if (this.lists.length > 0) {
                this.hasList = true;
              }

              callback({
                recordsTotal: paging.totalCount,
                recordsFiltered: paging.totalRowsAfterFiltering,
                data: [],
              });
            },
            (err) => {
              const msg =
                typeof err.error.Message !== "undefined"
                  ? err.error.Message
                  : err.error.message;
              if (msg !== "") {
                // this.alert.danger(err.error.message);
              }
              callback({
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              });
            }
          );
      },
    };
  }

  showModal(): void {
    this.createAttributeForm();
    this.cs.getSpaceType().subscribe(
      (data) => {
        if (data["status"] === true) {
          this.spaceTypes = data["data"] as SpaceType[];
        } else {
          this.spaceTypes = [];
        }
        this.ls.remove();
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          this.alert.danger(err.error.message);
        }
        this.ls.remove();
      }
    );
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }

  onSubmit() {
    if (this.jobForm.invalid) {
      return;
    }

    this.loading = true;
    this.cs.createCustomerJob(this.jobForm.value).subscribe(
      (data) => {
        if (data["status"] === true) {
          this.alert.success(data["message"]);
          this.hideModal();
          // this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) =>
          this.datatableElement.dtInstance.then((dtInstance) =>
            dtInstance.draw()
          );
        } else {
          this.alert.danger(data["message"]);
        }
        this.loading = false;
        this.ls.remove();
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          this.alert.danger(err.error.message);
        }
        this.loading = false;
        this.ls.remove();
      }
    );
  }
}

interface JobListResponse {
  status: boolean;
  message: string;
  data: JobList[];
}

interface JobList {
  id: number;
  isProductInJob: boolean;
  name: string;
  productImage: string;
  spaceTypeId: number;
  spaceTypeName: string;
  productCount: number;
}

interface SpaceType {
  name: string;
  spacetypeId: number;
}
