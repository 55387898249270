import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentModule } from 'common/_components/components.module';
import { DataTablesModule } from 'angular-datatables';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MyDirectiveModule } from 'common/directives/my-directive.module';
import { MaterialModule } from 'common/modules/material.module';
import { TemplateDefaultModule } from '../../shared/template-default/template-default.module';
import { NgSelectModule } from '@ng-select/ng-select';

import { PromoCodeRoutingModule } from './promo-code-routing.module';
import { ListComponent } from './list/list.component';
import { ActionComponent } from './action/action.component';


@NgModule({
  declarations: [ListComponent, ActionComponent],
  imports: [
    CommonModule,
    PromoCodeRoutingModule,
    RouterModule,
    TranslateModule.forRoot(),
    ReactiveFormsModule,
    DataTablesModule,
    ComponentModule,
    MaterialModule,
    NgSelectModule,
    TemplateDefaultModule,
    BsDatepickerModule.forRoot(),
    MyDirectiveModule    
  ]
})
export class PromoCodeModule { }
