import { Deserializable } from "../product-filter.modal";
import { FilterValueModal } from "./filter-value.modal";

export class FilterAttributeModal extends FilterValueModal {
    attributeValues: FilterValueModal[];
    
    deserialize(input: any): this {
        Object.assign(this, input);
        this.attributeValues = input.attributeValues.map(item => new FilterValueModal().deserialize(item));
        return this;
    }
}
