import { Deserializable } from "../product-filter.modal";

export class FilterValueModal implements Deserializable {
    id: number;
    image: string;
    name: string;
    showImage: boolean;
    
    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
