import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { constEnv } from "common/constants.env";
import { map } from "rxjs/operators";
import { ProductFilerSortParam } from "src/app/e-commerce/category/category.component";
import { LanguageService } from "../language.service";

@Injectable({ providedIn: "root" })
export class SiteProductService {
  configConstant = constEnv;

  constructor(private http: HttpClient, private ls: LanguageService) {}

  getProductFilter(categoyId: number, subCategoryId: number = 0) {
    const langId = this.ls.getCurrentLanguageId();
    const searchParams = new HttpParams()
      .set("LanguageId", langId)
      .set("CategoryId", categoyId.toString())
      .set("SubCategoryIds", subCategoryId > 0 ? subCategoryId.toString() : "");

    return this.http
      .get(`${this.configConstant.apiSiteURL}/getfilterlist`, {
        params: searchParams,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getProductList(productParams: ProductFilerSortParam) {
    const searchParams = new HttpParams()
      .set("LanguageId", this.ls.getCurrentLanguageId())
      .set("CategoryId", productParams.CategoryId.toString())
      .set("SubCategoryIds", productParams.SubCategoryIds)
      .set("SearchColumn", productParams.SearchColumn)
      .set("SearchValue", productParams.SearchValue)
      .set("SortColumn", productParams.SortColumn)
      .set("SortOrder", productParams.SortOrder)
      .set("BrandIds", productParams.BrandIds)
      .set("AttributeValueIds", productParams.AttributeValueIds)
      .set(
        "PageNo",
        productParams.PageNo > 0 ? productParams.PageNo.toString() : "0"
      )
      .set("PageSize", productParams.PageSize.toString())
      .set("TagId", productParams.TagId);

    return this.http
      .get(`${this.configConstant.apiSiteURL}/productlist`, {
        params: searchParams,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getHotDealList(productParams: ProductFilerSortParam) {
    const searchParams = new HttpParams()
      .set("LanguageId", this.ls.getCurrentLanguageId())
      .set("CategoryId", productParams.CategoryId.toString())
      .set("SubCategoryIds", productParams.SubCategoryIds)
      .set("SearchColumn", productParams.SearchColumn)
      .set("SearchValue", productParams.SearchValue)
      .set("SortColumn", productParams.SortColumn)
      .set("SortOrder", productParams.SortOrder)
      .set("BrandIds", productParams.BrandIds)
      .set("AttributeValueIds", productParams.AttributeValueIds)
      .set(
        "PageNo",
        productParams.PageNo > 0 ? productParams.PageNo.toString() : "0"
      )
      .set("PageSize", productParams.PageSize.toString());

    return this.http
      .get(`${this.configConstant.apiCustomerURL}/hotdeals`, {
        params: searchParams,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  // getTagWiseProductList( productParams: ProductFilerSortParam ) {

  //     const searchParams = new HttpParams()
  //         .set('LanguageId', this.ls.getCurrentLanguageId())
  //         .set('CategoryId', productParams.CategoryId.toString())
  //         .set('SubCategoryIds', productParams.SubCategoryIds)
  //         .set('SearchColumn', productParams.SearchColumn)
  //         .set('SearchValue', productParams.SearchValue)
  //         .set('SortColumn', productParams.SortColumn)
  //         .set('SortOrder', productParams.SortOrder)
  //         .set('BrandIds', productParams.BrandIds)
  //         .set('AttributeValueIds', productParams.AttributeValueIds)
  //         .set('PageNo', (productParams.PageNo > 0) ? productParams.PageNo.toString() : '0')
  //         .set('PageSize', productParams.PageSize.toString())
  //         .set('TagId', productParams.toString());

  //     return this.http.get(`${this.configConstant.apiCustomerURL}/p`, {
  //         params: searchParams
  //     })
  //         .pipe(map(data => {
  //             return data;
  //         }));
  // }

  getLastMinuteDealList(productParams: ProductFilerSortParam) {
    const searchParams = new HttpParams()
      .set("LanguageId", this.ls.getCurrentLanguageId())
      .set("CategoryId", productParams.CategoryId.toString())
      .set("SubCategoryIds", productParams.SubCategoryIds)
      .set("SearchColumn", productParams.SearchColumn)
      .set("SearchValue", productParams.SearchValue)
      .set("SortColumn", productParams.SortColumn)
      .set("SortOrder", productParams.SortOrder)
      .set("BrandIds", productParams.BrandIds)
      .set("AttributeValueIds", productParams.AttributeValueIds)
      .set(
        "PageNo",
        productParams.PageNo > 0 ? productParams.PageNo.toString() : "0"
      )
      .set("PageSize", productParams.PageSize.toString());

    return this.http
      .get(`${this.configConstant.apiCustomerURL}/getlastminutedeals`, {
        params: searchParams,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getProductDetail(productId: number, variationId: number = 0) {
    const searchParams = new HttpParams()
      .set("LanguageId", this.ls.getCurrentLanguageId())
      .set("pid", productId.toString())
      .set("vid", variationId.toString());

    return this.http
      .get(`${this.configConstant.apiSiteURL}/productdetail`, {
        params: searchParams,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getProductVariationDetail(productId: number, variationIds: any) {
    return this.http
      .post(
        `${this.configConstant.apiCustomerURL}/getvariation?pid=${productId}`,
        variationIds
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getProductReviews(reviewParams: any) {
    const searchParams = new HttpParams()
      .set("ProductId", reviewParams.ProductId.toString())
      .set("PageNo", reviewParams.PageNo.toString())
      .set(
        "Rating",
        reviewParams.Rating > 0 ? reviewParams.Rating.toString() : ""
      )
      .set("PageSize", reviewParams.PageSize.toString());

    return this.http
      .get(`${this.configConstant.apiCustomerURL}/getproductreviewsbyId`, {
        params: searchParams,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  addToWishList(params) {
    return this.http
      .post(`${this.configConstant.apiCustomerURL}/addwishList`, params)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  deleteWishlist(wishlistId: number) {
    const searchParams = new HttpParams().set("id", wishlistId.toString());

    return this.http
      .get(`${this.configConstant.apiCustomerURL}/deletewishList/${wishlistId}`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  addToCart(formData) {
    return this.http
      .post(`${this.configConstant.apiShopingCartURL}/AddToCart`, formData)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getProductTypePageLists() {
    const productTypeLists = [
      { id: 1, title: "Best_Seller", slug: "best-sellers", type: "tag" },
      { id: 2, title: "NewArrivals", slug: "new-arrivals", type: "tag" },
    ];

    return productTypeLists;
  }
}
