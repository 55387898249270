import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { constEnv } from "common/constants.env";
import { LoaderService } from "common/services/loader.service";
import { TitleService } from "common/services/title.service";
import { AlertService } from "ngx-alerts";

import { DataTableDirective } from "angular-datatables";
import { ProductService } from "common/services/store/product.service";
import { first } from "rxjs/operators";

@Component({
  selector: "app-inventory-view",
  templateUrl: "./inventory-view.component.html",
  styleUrls: ["./inventory-view.component.css"],
})
export class InventoryViewComponent implements OnInit {
  config = constEnv;
  productListUrl: string;
  productId = 0;
  variationId = 0;

  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};

  inventoryLists: InventoryList[] = [];
  productInfo: ProductInfo = null;
  hasLists = false;

  constructor(
    private alert: AlertService,
    private loader: LoaderService,
    private titleService: TitleService,
    private route: ActivatedRoute,
    private router: Router,
    private product: ProductService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("View Inventory");

    this.productListUrl = `${this.config.STORE_URL}/product/lists`;

    const id = this.route.snapshot.params.id;
    this.productId = typeof id !== "undefined" ? id : 0;
    if (this.productId <= 0) {
      this.router.navigate([this.productListUrl]);
    }

    const vid = this.route.snapshot.params.variationId;
    this.variationId = typeof vid !== "undefined" ? vid : 0;
    if (this.variationId <= 0) {
      this.router.navigate([this.productListUrl]);
    }

    this.createDataTable();
  }

  createDataTable() {
    this.dtOptions = {
      pagingType: "full_numbers",
      ordering: false,
      lengthChange: false,
      searching: false,
      serverSide: true,
      processing: true,
      // pageLength: 3,
      language: {
        paginate: {
          previous: "<",
          first: "<<",
          last: ">>",
          next: ">",
        },
      },
      ajax: (dataTablesParameters: any, callback) => {
        const pageNo =
          (dataTablesParameters.start * 1) / (dataTablesParameters.length * 1);
        // const pageNo = dataTablesParameters.start;
        const pageSize = dataTablesParameters.length;

        let variationId = this.variationId;
        let isVariation = true;
        if (this.productId === this.variationId) {
          variationId = 0;
          isVariation = false;
        }

        this.product
          .getProductVariationInventoryDetail(
            this.productId,
            variationId,
            isVariation,
            pageNo,
            pageSize
          )
          .pipe(first())
          .subscribe(
            (resp) => {
              const data = resp as InventoryViewResponse;

              let paging: Pagination;
              if (data.status === true) {
                this.inventoryLists = data.data;
                this.productInfo = data.ProductInfo;
                paging = JSON.parse(data.paging) as Pagination;
                // paging = { totalCount: this.productVariationLists.length, totalRowsAfterFiltering: this.productVariationLists.length };
              } else {
                // paging = { totalCount: 0, totalRowsAfterFiltering: 0 };
                this.inventoryLists = [];
                this.hasLists = false;
              }

              if (this.inventoryLists.length > 0) {
                this.hasLists = true;
              }
              callback({
                recordsTotal: paging.totalCount,
                recordsFiltered: paging.totalRowsAfterFiltering,
                data: [],
              });
            },
            (err) => {
              if (typeof err.error !== "undefined") {
                const msg =
                  typeof err.error.Message !== "undefined"
                    ? err.error.Message
                    : err.error.message;
                this.alert.danger(msg);
                this.hasLists = false;
                this.inventoryLists = [];
              }

              callback({
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              });

              this.loader.hideLoader();
            }
          );
      },
    };
  }
}

interface InventoryViewResponse {
  status: boolean;
  status_code: number;
  message: string;
  paging: string;
  ProductInfo: ProductInfo;
  data: InventoryList[];
}

interface InventoryList {
  boughtQuantity: number;
  createdDate: string;
  id: number;
  name: string;
  orderId: number;
  orderNo: string;
  productId: number;
  productVariationId: number;
  reservedQuantity: number;
  soldQuantity: number;
  stockQuantity: number;
  totalStockQuantity: number;
}

interface ProductInfo {
  productId: number;
  productImage: string;
  productName: string;
  stockQuantity: number;
  variationName: string;
}

interface Pagination {
  totalCount: number;
  totalRowsAfterFiltering: number;
  pageSize: number;
  currentPage: number;
  totalPages: number;
  previousPage: string;
  nextPage: string;
}
