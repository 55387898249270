import { Component, OnDestroy, OnInit } from "@angular/core";
import { IsLoadingService } from "@service-work/is-loading";
import { TitleService } from "common/services/title.service";
import { constEnv } from "common/constants.env";
import {
  NgxGalleryOptions,
  NgxGalleryImage,
  NgxGalleryAnimation,
} from "ngx-gallery-9";
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from "@angular/router";
import { SiteProductService } from "common/services/site/siteProduct.service";
import { AlertService } from "@full-fledged/alerts";
import {
  ProductDetail,
  ProductExtra,
} from "common/_models/site/product-detail.modal";
import { AuthenticationService } from "common/services/authentication.service";
import { EventEmitterService } from "common/services/event-emitter.service";
import { CartService } from "common/services/site/cartservice";
import { filter, map, shareReplay } from "rxjs/operators";
import * as moment from "moment";
import { Observable, interval } from "rxjs";
import { PageChangedEvent } from "ngx-bootstrap/pagination";

@Component({
  selector: "app-product-detail",
  templateUrl: "./product-detail.component.html",
  styleUrls: ["./product-detail.component.css"],
})
export class ProductDetailComponent implements OnInit, OnDestroy {
  config = constEnv;

  productId: number;
  variationId: number;

  productGalleryOptions: NgxGalleryOptions[];
  productGalleryImages: NgxGalleryImage[] = [];

  productDetail: ProductDetail = null;

  selectedVariations = [];
  selectedAttributeValue = {};

  navSubcription;

  LABLE = 1;
  IMAGE = 2;
  COLOR = 3;
  console = console;
  isUserLoggedIn = false;
  itemQty: number = 1;

  productExtra: any = null;

  public timeLeft$: Observable<any>;

  reviewCounters: any = [
    { star: 5, reviews: "", reviewPer: 0, key: "fiveReviewsCount" },
    { star: 4, reviews: "", reviewPer: 0, key: "fourReviewsCount" },
    { star: 3, reviews: "", reviewPer: 0, key: "threeReviewsCount" },
    { star: 2, reviews: "", reviewPer: 0, key: "twoReviewsCount" },
    { star: 1, reviews: "", reviewPer: 0, key: "oneReviewsCount" },
  ];

  productReviewsParams = {
    ProductId: "",
    Rating: "",
    PageNo: 0,
    PageSize: 10,
  };

  productReviews: ProductReviewData;
  productReviewData: ProductReview[] = [];
  productCounters: ProductCounter;

  pagination: Paging = {
    totalCount: 0,
    totalRowsAfterFiltering: 0,
    pageSize: 10,
    currentPage: 0,
    totalPages: 0,
    previousPage: "",
    nextPage: "",
  };
  reviewImagesOptions: NgxGalleryOptions[];

  constructor(
    private isLoadingService: IsLoadingService,
    private titleService: TitleService,
    private route: ActivatedRoute,
    private router: Router,
    private product: SiteProductService,
    private alert: AlertService,
    private authenticationService: AuthenticationService,
    private eventEmitterService: EventEmitterService,
    private cart: CartService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Product Detail");

    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }

    this.variationId = this.route.snapshot.params.variationId;
    this.variationId = this.variationId > 0 ? this.variationId : 0;
    // if (this.variationId <= 0) {
    //   this.router.navigate([this.config.BASE_URL]);
    // }

    this.productId = this.route.snapshot.params.productId;
    if (this.productId <= 0) {
      this.router.navigate([this.config.BASE_URL]);
    }

    this.navSubcription = this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError
        )
      )
      .subscribe((event) => {
        // If it's the start of navigation, `add()` a loading indicator
        if (event instanceof NavigationStart) {
          this.isLoadingService.add();
          return;
        }

        if (event instanceof NavigationEnd) {
          this.variationId = this.route.snapshot.params.variationId;
          this.variationId = this.variationId > 0 ? this.variationId : 0;
          // if (this.variationId <= 0) {
          //   this.router.navigate([this.config.BASE_URL]);
          // }

          this.productId = this.route.snapshot.params.productId;
          if (this.productId <= 0) {
            this.router.navigate([this.config.BASE_URL]);
          }

          // if ( event.url.indexOf( 'category' ) > -1 ) {
          this.getProductDetail();
          // }
        }

        // Else navigation has ended, so `remove()` a loading indicator
        this.isLoadingService.remove();
      });

    this.productGalleryOptions = [
      {
        width: "100%",
        // height: 'auto',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide,
      },
      // max-width 800
      {
        breakpoint: 800,
        width: "100%",
        // height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false,
      },
    ];

    this.reviewImagesOptions = [
      {
        width: "100%",
        height: "70px",
        thumbnailsColumns: 10,
        imageAnimation: NgxGalleryAnimation.Slide,
        image: false,
        thumbnailsRemainingCount: true,
        imageSize: "content",
        previewCloseOnClick: true,
        previewCloseOnEsc: true,
      },
      // max-width 800
      {
        breakpoint: 800,
        width: "100%",
        // height: '600px',
        thumbnailsColumns: 4,
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false,
      },
    ];

    this.isUserLoggedIn = this.authenticationService.currentUserValue
      ? true
      : false;

    this.getProductDetail();
    this.getProductReviews();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.productDetail = null;
    this.navSubcription.unsubscribe();
  }

  getProductDetail() {
    this.isLoadingService.add();
    this.product.getProductDetail(this.productId, this.variationId).subscribe(
      (data) => {
        const res = data as ProductDetailResponse;
        if (res.status === true) {
          this.productDetail = new ProductDetail().deserialize(res.data);

          // this.titleService.setMultipleTitle([
          //   this.productDetail.headerText,
          //   "Product Detail",
          // ]);
          this.titleService.setMultipleTitle([this.productDetail.headerText]);

          // this.itemQty = (this.productDetail.inCartQty > 0) ? this.productDetail.inCartQty : 1;

          this.productGalleryImages = [];
          if (this.productDetail.imageUrl.length > 0) {
            for (var i = 0; i < this.productDetail.imageUrl.length; i++) {
              this.productGalleryImages.push({
                small: this.productDetail.imageUrl[i],
                medium: this.productDetail.imageUrl[i],
                big: this.productDetail.imageUrl[i],
              });
            }
          }

          if (this.productDetail.attribute) {
            this.productDetail.attribute.forEach((singleAttr) => {
              singleAttr.attributeValue.forEach((attrValue) => {
                if (attrValue.selected) {
                  // console.log(singleAttr, attrValue);
                  this.selectedAttributeValue[singleAttr.attributeId] =
                    attrValue.value;
                  this.selectedVariations.push({
                    attrId: singleAttr.attributeId,
                    attrValueId: attrValue.id,
                  });
                }
              });
            });
          }

          if (this.productDetail.extra.manufacturingDate) {
            if (
              moment(
                this.productDetail.extra.manufacturingDate,
                "DD-MM-YYYY"
              ).isValid()
            ) {
              this.productDetail.extra.manufacturingDate = moment(
                this.productDetail.extra.manufacturingDate,
                "DD-MM-YYYY"
              ).toDate();
            } else {
              this.productDetail.extra.manufacturingDate = moment(
                this.productDetail.extra.manufacturingDate
              ).toDate();
            }
          }
          // console.log(this.productDetail.offerEndsOnDate);
          let expiredDateTime: Date | String = "";
          if (this.productDetail.offerEndsOnDate) {
            const momentDate = moment(
              this.productDetail.offerEndsOnDate,
              "DD-MM-YYYY HH:mm"
            );
            const parseDate = `${
              momentDate.month() + 1
            }-${momentDate.date()}-${momentDate.year()} ${momentDate.hours()}:${momentDate.minutes()}`;
            // console.log(parseDate);
            expiredDateTime = new Date(parseDate);
          }
          // console.log(expiredDateTime);
          // const expiredDateTime = this.productDetail.offerEndsOnDate
          //   ? new Date(this.productDetail.offerEndsOnDate)
          //   : "";

          if (expiredDateTime) {
            this.timeLeft$ = interval(1000).pipe(
              map((x) => this.calcDateDiff(expiredDateTime)),
              shareReplay(1)
            );
          }

          this.setExtraMeta(this.productDetail.extra);

          window.scroll(0, 0);
          this.isLoadingService.remove();
        } else {
          this.alert.danger(res.message);
          this.isLoadingService.remove();
        }
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          // this.alert.danger(err.error.message);
        }
        this.isLoadingService.remove();
      }
    );
  }

  setExtraMeta(extraMeta: ProductExtra) {
    const brand = extraMeta.brandName.trim();
    const warranty = extraMeta.warranty.trim();
    const isReturnable = extraMeta.isReturnable === "Yes" ? true : false;
    const returnPolicy = extraMeta.returnableText.trim();
    const manufacturingDate = extraMeta.manufacturingDate;
    const fragile = extraMeta.isFragile === "Yes" ? true : false;

    this.productExtra = [];
    if (brand) {
      this.productExtra.push({
        text: "Brand Name: " + brand,
        icon: "assets/images/assets/ic_brand.svg",
        show: brand ? true : false,
        class: "ic_brand",
      });
    }
    if (warranty) {
      this.productExtra.push({
        text: warranty,
        icon: "assets/images/assets/ic_warranty.svg",
        show: warranty ? true : false,
        class: "ic_warranty",
      });
    }

    if (isReturnable && returnPolicy) {
      this.productExtra.push({
        text: returnPolicy,
        icon: "assets/images/assets/ic_return_policy.svg",
        show: isReturnable,
        class: "ic_return_policy",
      });
    }

    if (manufacturingDate) {
      const manufacturingDateFormat =
        moment(manufacturingDate).format("MM/DD/YYYY");
      this.productExtra.push({
        text: `Manufacturing Date: ${manufacturingDateFormat}`,
        icon: "assets/images/assets/ic_manufacture.svg",
        show: manufacturingDate ? true : false,
        class: "ic_manufacture",
      });
    }
    if (fragile) {
      this.productExtra.push({
        text: "Fragile_Product",
        icon: "assets/images/assets/ic_handle.svg",
        show: fragile,
        class: "ic_handle",
      });
    }
  }

  showExtraTab(): boolean {
    return this.productExtra.length > 0 ? true : false;
  }

  getProductVariation(attrId: number, attrValueId: number) {
    const variationAttrIds = [];
    this.selectedVariations.forEach((attr) => {
      if (attr.attrId === attrId) {
        attr.attrValueId = attrValueId;
      }

      if (attr.attrValueId > 0) {
        variationAttrIds.push(attr.attrValueId);
      }
    });
    this.selectedVariations = [];
    this.selectedVariations = variationAttrIds;

    this.isLoadingService.add();
    this.product
      .getProductVariationDetail(this.productId, variationAttrIds)
      .subscribe(
        (data) => {
          const res = data as ProductDetailResponse;

          if (res.status === true) {
            this.productDetail = new ProductDetail().deserialize(res.data);

            if (this.productDetail.message != "") {
              this.alert.danger(this.productDetail.message);
            }

            if (this.productDetail.imageUrl.length > 0) {
              this.productGalleryImages = [];
              for (var i = 0; i < this.productDetail.imageUrl.length; i++) {
                this.productGalleryImages.push({
                  small: this.productDetail.imageUrl[i],
                  medium: this.productDetail.imageUrl[i],
                  big: this.productDetail.imageUrl[i],
                });
              }
            }

            if (this.productDetail.attribute) {
              this.productDetail.attribute.forEach((singleAttr) => {
                singleAttr.attributeValue.forEach((attrValue) => {
                  if (attrValue.selected) {
                    this.selectedAttributeValue[singleAttr.attributeId] =
                      attrValue.value;
                    this.selectedVariations.push({
                      attrId: singleAttr.attributeId,
                      attrValueId: attrValue.id,
                    });
                  }
                });
              });
            }

            this.isLoadingService.remove();
          } else {
            this.alert.danger(res.message);
            this.isLoadingService.remove();
          }
        },
        (err) => {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          if (msg !== "") {
            this.alert.danger(err.error.message);
          }
          this.isLoadingService.remove();
        }
      );
  }

  addToWishlist() {
    if (this.isUserLoggedIn) {
      const wishListData = {
        wishListId: this.productDetail.wishListId,
        storeId: this.productDetail.storeId,
        customerId: 0,
        productId: this.productDetail.productId,
        productVariationId: this.productDetail.productVariationId,
      };

      this.isLoadingService.add();
      this.product.addToWishList(wishListData).subscribe(
        (data) => {
          const res = data as ProductDetailResponse;
          if (res.status) {
            this.productDetail.wishListId = res.data;
            this.cart.sendCartCountEvent();
            this.alert.success(res.message);
          } else {
            this.productDetail.wishListId = 0;
            this.alert.danger(res.message);
          }
          this.isLoadingService.remove();
        },
        (err) => {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          if (msg !== "") {
            this.alert.danger(err.error.message);
          }
          this.productDetail.wishListId = 0;
          this.isLoadingService.remove();
        }
      );
    } else {
      this.router.navigate([`/${this.config.BASE_URL}/login`], {
        queryParams: { returnUrl: this.router.url },
      });
    }
  }

  removeFromWishlist(wishListId: number) {
    this.isLoadingService.add();
    this.product.deleteWishlist(wishListId).subscribe(
      (data) => {
        const res = data as ProductDetailResponse;
        if (res.status) {
          this.productDetail.wishListId = res.data;
          this.cart.sendCartCountEvent();
          this.alert.success(res.message);
        } else {
          this.productDetail.wishListId = 0;
          this.alert.danger(res.message);
        }
        this.isLoadingService.remove();
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          this.alert.danger(err.error.message);
        }
        this.productDetail.wishListId = 0;
        this.isLoadingService.remove();
      }
    );
  }

  addToCart() {
    if (this.isUserLoggedIn) {
      const formData = new FormData();
      formData.append("StoreId", this.productDetail.storeId);
      formData.append("ProductId", this.productDetail.productId.toString());
      formData.append(
        "ProductVariationId",
        this.productDetail.productVariationId.toString()
      );
      formData.append("ItemQuantity", this.itemQty.toString());
      formData.append("ItemTypeId", this.productDetail.itemTypeId.toString());

      this.isLoadingService.add();
      this.product.addToCart(formData).subscribe(
        (data) => {
          const res = data as ProductDetailResponse;
          if (res.status) {
            this.productDetail.buttonText = res.data.ButtonText;
            this.productDetail.inCart = true;
            // this.eventEmitterService.getCartCount();
            this.cart.sendCartCountEvent();
            this.alert.success(res.message);
          } else {
            this.alert.danger(res.message);
          }
          this.isLoadingService.remove();
        },
        (err) => {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          if (msg !== "") {
            this.alert.danger(err.error.message);
          }
          this.isLoadingService.remove();
        }
      );
    } else {
      this.router.navigate([`/${this.config.BASE_URL}/login`], {
        queryParams: { returnUrl: this.router.url },
      });
    }
  }

  showActionButtons(): boolean {
    if (this.isUserLoggedIn) {
      const userDetails =
        this.authenticationService.currentUserValue.userdetails;
      if (
        userDetails.userType == "designer" ||
        userDetails.userType == "store"
      ) {
        return false;
      }
    }
    return true;
  }

  buyNow() {
    if (this.isUserLoggedIn) {
      this.isLoadingService.add();
      this.cart
        .getBuyNow(this.productDetail.productId, 0, this.itemQty)
        .subscribe(
          (data) => {
            const res = data as ProductDetailResponse;
            if (res.status === true) {
              this.router.navigate([`${this.config.BASE_URL}/buy-now`], {
                queryParams: { pid: this.productDetail.productId },
              });
            } else {
              this.alert.danger(res.message);
              this.isLoadingService.remove();
            }
          },
          (err) => {
            const msg =
              typeof err.error.Message !== "undefined"
                ? err.error.Message
                : err.error.message;
            if (msg !== "") {
              this.alert.danger(err.error.message);
            }
            this.isLoadingService.remove();
          }
        );
    } else {
      this.router.navigate([`/${this.config.BASE_URL}/login`], {
        queryParams: { returnUrl: this.router.url },
      });
    }
  }

  collapseSpaces(text: string): string {
    return text.replace(/[\r\n]+/g, "\n").trim();
  }

  calcDateDiff(endDay): any {
    const dDay = endDay.valueOf();
    // console.log(dDay);
    const milliSecondsInASecond = 1000;
    const hoursInADay = 24;
    const minutesInAnHour = 60;
    const secondsInAMinute = 60;

    const timeDifference = dDay - Date.now();

    let daysToDday = Math.floor(
      timeDifference /
        (milliSecondsInASecond *
          minutesInAnHour *
          secondsInAMinute *
          hoursInADay)
    );

    let hoursToDday = Math.floor(
      timeDifference /
        (milliSecondsInASecond * minutesInAnHour * secondsInAMinute)
    );

    let minutesToDday = Math.floor(
      (timeDifference / (milliSecondsInASecond * minutesInAnHour)) %
        secondsInAMinute
    );

    let secondsToDday =
      Math.floor(timeDifference / milliSecondsInASecond) % secondsInAMinute;

    const secondsToDdayWS =
      secondsToDday < 10 ? "0" + secondsToDday : secondsToDday.toString();
    const minutesToDdayWS =
      minutesToDday < 10 ? "0" + minutesToDday : minutesToDday.toString();
    const hoursToDdayWS =
      hoursToDday < 10 ? "0" + hoursToDday : hoursToDday.toString();
    const daysToDdayWS =
      daysToDday < 10 ? "0" + daysToDday : daysToDday.toString();

    return { secondsToDdayWS, minutesToDdayWS, hoursToDdayWS, daysToDdayWS };
  }

  getProductReviews() {
    this.isLoadingService.add();
    this.productReviewsParams.ProductId = this.productId.toString();
    this.product.getProductReviews(this.productReviewsParams).subscribe(
      (data) => {
        const res = data as ProductReviewResponse;
        if (res.status === true) {
          this.productReviews = res.data as ProductReviewData;

          this.productCounters = this.productReviews.Counter;
          this.productReviewData = this.productReviews.Reviews || [];

          if (this.productReviewData) {
            this.pagination = JSON.parse(res.paging) as Paging;

            this.productReviewData.map((item, index) => {
              if (item.images.length > 0) {
                item.images.map((image) => {
                  if (
                    typeof this.productReviews.Reviews[index].reviewImages ===
                    "undefined"
                  ) {
                    this.productReviews.Reviews[index]["reviewImages"] = [];
                  }
                  this.productReviews.Reviews[index].reviewImages.push({
                    small: image.images,
                    medium: image.images,
                    big: image.images,
                  });
                });
              }
            });

            const counters = this.productCounters;
            const totalReviewCounter = counters.totalReviewsCount;
            this.reviewCounters.map((counter, index) => {
              counter.reviews = counters[counter.key];
              counter.reviewPer = Math.round(
                (counters[counter.key] / totalReviewCounter) * 100
              );

              this.reviewCounters[index] = counter;
            });
          } else {
            this.productReviewData = [];
          }
          this.isLoadingService.remove();
        } else {
          // this.alert.danger(res.message);
          this.isLoadingService.remove();
          this.productReviewData = [];
        }
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          // this.alert.danger(err.error.message);
        }
        this.isLoadingService.remove();
      }
    );
  }

  onReviewFilterClick(filterStar) {
    this.productReviewsParams.Rating = filterStar;
    this.getProductReviews();
  }

  pageChange(paging: PageChangedEvent) {
    paging.page = paging.page > 0 ? paging.page - 1 : 0;
    paging.itemsPerPage = paging.itemsPerPage
      ? paging.itemsPerPage
      : this.productReviewsParams.PageSize;

    this.productReviewsParams.PageSize = paging.itemsPerPage;
    this.productReviewsParams.PageNo = paging.page;

    this.getProductReviews();
  }
}

interface ProductDetailResponse {
  status: boolean;
  message: string;
  data: any;
}

interface ProductReviewResponse {
  status: boolean;
  message: string;
  data: ProductReviewData;
  paging: any;
}

interface ProductReviewData {
  Counter: ProductCounter;
  Reviews: ProductReview[];
}

interface ProductCounter {
  avgReviewsCount: number;
  fiveReviewsCount: number;
  fourReviewsCount: number;
  oneReviewsCount: number;
  threeReviewsCount: number;
  totalReviewsCount: number;
  twoReviewsCount: number;
}

interface ProductReview {
  comment: string;
  customerName: string;
  customerProfile: string;
  date: Date;
  images: [
    {
      images: string;
    }
  ];
  rate: number;
  reviewId: number;
  rowNum: number;
  totalRows: number;
  reviewImages: NgxGalleryImage[];
}

interface timeComponents {
  secondsToDday: string;
  minutesToDday: string;
  hoursToDday: string;
  daysToDday: string;
}
export interface Paging {
  totalCount: number;
  totalRowsAfterFiltering: number;
  pageSize: number;
  currentPage: number;
  totalPages: number;
  previousPage: string;
  nextPage: string;
}
