import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "common/modules/material.module";
import { TemplateDefaultModule } from "../../shared/template-default/template-default.module";
import { ModalModule } from "ngx-bootstrap/modal";

import { Ng2TelInputModule } from "ng2-tel-input";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { NgSelectModule } from "@ng-select/ng-select";

// import { AgmCoreModule } from "@agm/core";

import { constEnv } from "common/constants.env";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { DataTablesModule } from "angular-datatables";

import { SettingsRoutingModule } from "./settings-routing.module";

import { StoreComponent } from "./store/store.component";
import { TimeComponent } from "./time/time.component";
import { OrderComponent } from "./order/order.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { DocumentsComponent } from "./documents/documents.component";
import { BankDetailComponent } from "./bank-detail/bank-detail.component";
import { SettingsMenuComponent } from "./settings-menu/settings-menu.component";
import { PickupAddressListComponent } from "./pickup-address-list/pickup-address-list.component";
import { MyDirectiveModule } from "common/directives/my-directive.module";
import { ComponentModule } from "common/_components/components.module";
import { INIT_COORDS } from "common/_components/maps/map-tokens";
import { PolicyComponent } from "./policy/policy.component";

@NgModule({
  declarations: [
    StoreComponent,
    TimeComponent,
    OrderComponent,
    ChangePasswordComponent,
    DocumentsComponent,
    BankDetailComponent,
    SettingsMenuComponent,
    PickupAddressListComponent,
    PolicyComponent,
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    // AgmCoreModule.forRoot({
    //   apiKey: constEnv.googleMapAPIKey,
    //   libraries: ["places"],
    // }),
    RouterModule,
    TranslateModule.forRoot(),
    ReactiveFormsModule,
    Ng2TelInputModule,
    NgxIntlTelInputModule,
    MaterialModule,
    TemplateDefaultModule,
    ModalModule.forRoot(),
    TimepickerModule.forRoot(),
    SettingsRoutingModule,
    NgSelectModule,
    DataTablesModule,
    MyDirectiveModule,
    ComponentModule,
  ],
  providers: [
    DatePipe,
    { provide: INIT_COORDS, useValue: { lat: 32.9756, long: -96.89 } },
  ],
})
export class SettingsModule {}
