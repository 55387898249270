import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AlertModule } from "ngx-alerts";
import { ComponentModule } from "common/_components/components.module";
import { BrowserModule } from "@angular/platform-browser";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "common/modules/material.module";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { TemplateModule } from "../template/template.module";
import { StarRatingModule } from "angular-star-rating";

import { DesignerCatalogRoutingModule } from "./designer-catalog-routing.module";
import { ListComponent } from "./list/list.component";
import { CatalogDetailComponent } from "./catalog-detail/catalog-detail.component";
import { SharedComponentsModule } from "../components/sharedComponents.module";
import { PipeModule } from "common/_pipe/pipe.module";

@NgModule({
  declarations: [ListComponent, CatalogDetailComponent],
  imports: [
    CommonModule,
    DesignerCatalogRoutingModule,
    AlertModule.forRoot({ maxMessages: 1, timeout: 2500, position: "right" }),
    ComponentModule,
    BrowserModule,
    RouterModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    MaterialModule,
    TemplateModule,
    StarRatingModule,
    PaginationModule.forRoot(),
    SharedComponentsModule,
    PipeModule,
  ],
})
export class DesignerCatalogModule {}
