import { Component, OnInit } from "@angular/core";

import { constEnv } from "common/constants.env";

@Component({
  selector: "app-settings-menu",
  templateUrl: "./settings-menu.component.html",
  styleUrls: ["./settings-menu.component.css"],
})
export class SettingsMenuComponent implements OnInit {
  config = constEnv;

  settingsUrl = `${this.config.STORE_URL}/settings`;

  navItems: SettingsMenu[] = [
    { displayName: "Store", path: `${this.settingsUrl}/store` },
    // { displayName: 'Time', path: `${this.settingsUrl}/time` },
    // { displayName: 'DRIVER_Order', path: `${this.settingsUrl}/order` },
    {
      displayName: "STORE_Change_Password",
      path: `${this.settingsUrl}/change-password`,
    },
    {
      displayName: "STORE_Manage_Documents",
      path: `${this.settingsUrl}/documents`,
    },
    {
      displayName: "StorePolicy",
      path: `${this.settingsUrl}/policy`,
    },
    {
      displayName: "BankDetails",
      path: `${this.settingsUrl}/bank-detail`,
    },
    {
      displayName: "PickupAddress",
      path: `${this.settingsUrl}/pickup-address`,
    },
  ];

  constructor() {}

  ngOnInit() {}
}

interface SettingsMenu {
  displayName: string;
  path: string;
}
