import { Component, OnInit, ViewChild } from '@angular/core';
import { IsLoadingService } from '@service-work/is-loading';

import { constEnv } from 'common/constants.env';

import { TitleService } from 'common/services/title.service';
import { first, filter } from 'rxjs/operators';
import { DriverService } from 'common/services/store/driver.service';
import { StoreDriver } from 'common/_models/store/storeDriver';
import { AlertService } from 'ngx-alerts';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from 'common/_components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-lists',
  templateUrl: './lists.component.html',
  styleUrls: ['./lists.component.css']
})
export class ListsComponent implements OnInit {

  config = constEnv;

  lists: StoreDriver[];
  hasLists = false;

  constructor(
    private titleService: TitleService,
    private alert: AlertService,
    private isLoadingService: IsLoadingService,
    private driver: DriverService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Drivers');

    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }

    this.listDrivers();
  }

  listDrivers() {
    this.isLoadingService.add();
    this.driver.getAll().pipe()
      .subscribe(
        data => {
          const res = data as DriverListResponse;
          if (res.status === true) {
            this.lists = res.data.map(list => new StoreDriver().deserialize(list));
            this.hasLists = (res.data.length > 0) ? true : false;
          } else {
            this.alert.danger(res.message);
            this.hasLists = false;
          }
          this.isLoadingService.remove();
        },
        err => {
          if (typeof err.error !== 'undefined') {
            const msg = (typeof err.error.Message !== 'undefined') ? err.error.Message : err.error.message;
            // this.alert.danger(msg);
          }
          this.isLoadingService.remove();
        }
      );
  }

  deleteDriver(driverId: number) {

    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm',
        message: 'DeleteConfirm',
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.isLoadingService.add();
        this.driver.delete(driverId).pipe()
          .subscribe(
            data => {
              const res = data as DriverListResponse;
              if (res.status === true) {
                this.alert.success(res.message);
                this.listDrivers();
              } else {
                this.alert.danger(res.message);
              }
              this.isLoadingService.remove();
            },
            err => {
              if (typeof err.error.Message !== 'undefined') {
                this.alert.danger(err.error.Message);
              } else if (typeof err.error !== 'undefined') {
                this.alert.danger(err.error.message);
              }
              this.isLoadingService.remove();
            }
          );
      }
    });
  }

  verifyDriverProfile(driverId) {
    this.isLoadingService.add();
        this.driver.verifyDriverProfile(driverId).pipe()
          .subscribe(
            data => {
              const res = data as DriverListResponse;
              if (res.status === true) {
                this.alert.success(res.message);
                this.listDrivers();
              } else {
                this.alert.danger(res.message);
              }
              this.isLoadingService.remove();
            },
            err => {
              if (typeof err.error.Message !== 'undefined') {
                this.alert.danger(err.error.Message);
              } else if (typeof err.error !== 'undefined') {
                this.alert.danger(err.error.message);
              }
              this.isLoadingService.remove();
            }
          );
  }

}

export interface DriverListResponse {
  status: boolean;
  message: string;
  data: StoreDriver[];
}
