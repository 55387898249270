import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { constEnv } from "common/constants.env";
import { map } from "rxjs/operators";

import { Observable, Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class CartService {
  configConstant = constEnv;

  private cartCountSubject = new Subject<any>();

  constructor(private http: HttpClient) {}

  sendCartCountEvent() {
    this.cartCountSubject.next();
  }

  getCartCountEvent(): Observable<any> {
    return this.cartCountSubject.asObservable();
  }

  getCartCount() {
    return this.http
      .get(`${this.configConstant.apiURL}/getShoppingCartCount`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getCart(
    addressId: number = 0,
    IsBuyNow: boolean = false,
    ProductId: number = 0
  ) {
    const params = new HttpParams()
      .set("CustomerAddressId", addressId.toString())
      .set("IsBuyNow", IsBuyNow.toString())
      .set("ProductId", ProductId.toString());

    return this.http
      .post(
        `${this.configConstant.apiURL}/ShoppingCart`,
        {},
        {
          params: params,
        }
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getBuyNow(productId: number, addressId: number = 0, itemQty: number = 1) {
    const params = new HttpParams()
      .set("StoreId", "0")
      .set("ProductId", productId.toString())
      .set("ItemQuantity", itemQty.toString())
      .set("IsBuyNow", "true")
      .set("CustomerAddressId", addressId.toString());

    return this.http
      .post(
        `${this.configConstant.apiURL}/ShoppingCart/BuyNow`,
        {},
        {
          params: params,
        }
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  removeFromCart(recordId: number, addressId: number = 0) {
    const params = new HttpParams().set(
      "CustomerAddressId",
      addressId.toString()
    );

    return this.http
      .post(
        `${this.configConstant.apiShopingCartURL}/RemoveFromCart/${recordId}`,
        {},
        {
          params: params,
        }
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  updateCart(formData, isBuyNow: boolean = false, addressId: number = 0) {
    const params = new HttpParams()
      .set("IsBuyNow", isBuyNow.toString())
      .set("customerAddressId", addressId.toString());

    return this.http
      .post(`${this.configConstant.apiShopingCartURL}/UpdateCart`, formData, {
        params,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  updateDeliveryDate(recordId: number, date: string) {
    return this.http
      .post(
        `${this.configConstant.apiShopingCartURL}/UpdateDeliveryPlanDate/${recordId}/${date}`,
        {}
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getCustomerCoupons() {
    return this.http.get(`${this.configConstant.apiCustomerURL}/coupons`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  applyPromoCode(
    promoCode: string,
    isBuyNow: boolean = false,
    productId: number = 0,
    addressId: number = 0
  ) {
    const params = new HttpParams()
      .set("promocode", promoCode)
      .set("isBuyNow", isBuyNow.toString())
      .set("productId", productId.toString())
      .set("addressId", addressId.toString());

    return this.http
      .post(
        `${this.configConstant.apiShopingCartURL}/AddCouponCode/`,
        {},
        {
          params: params,
        }
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  removePromoCode(
    isBuyNow: boolean = false,
    productId: number = 0,
    addressId: number = 0
  ) {
    const params = new HttpParams()
      .set("IsBuyNow", isBuyNow.toString())
      .set("ProductId", productId.toString())
      .set("CustomerAddressId", addressId.toString());

    return this.http
      .post(
        `${this.configConstant.apiShopingCartURL}/RemoveCouponCode/`,
        {},
        {
          params,
        }
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  makePayment(formData) {
    return this.http
      .post(
        `${this.configConstant.apiURL}/pay`,
        {},
        {
          params: formData,
        }
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  checkOrderQty(formData) {
    return this.http
      .post(
        `${this.configConstant.apiURL}/isOrderPlaced`,
        {},
        {
          params: formData,
        }
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  checkPaymentStatus(formData) {
    return this.http
      .post(
        `${this.configConstant.apiURL}/checkstatus`,
        {},
        {
          params: formData,
        }
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
