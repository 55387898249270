import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AlertModule } from "ngx-alerts";
import { ComponentModule } from "common/_components/components.module";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { Ng2TelInputModule } from "ng2-tel-input";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";

import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "common/modules/material.module";
import { PaginationModule } from "ngx-bootstrap/pagination";

import { ECommerceRoutingModule } from "./e-commerce-routing.module";

import { TemplateModule } from "./template/template.module";
import { HomeComponent } from "./home/home.component";
import { NgxGalleryModule } from "ngx-gallery";
import { StarRatingModule } from "angular-star-rating";
import { ModalModule } from "ngx-bootstrap/modal";

import { DesignerCatalogModule } from "./designer-catalog/designer-catalog.module";

import { CarouselModule } from "ngx-owl-carousel-o";
import { RouterModule } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CategoryComponent } from "./category/category.component";
import { ProductDetailComponent } from "./product-detail/product-detail.component";
import { PagesModule } from "./pages/pages.module";
import { PaymentModule } from "./payment/payment.module";
import { EventEmitterService } from "common/services/event-emitter.service";
import { CartComponent } from "./cart/cart.component";
import { HotDealComponent } from "./hot-deal/hot-deal.component";
import { LastMinuteDealComponent } from "./last-minute-deal/last-minute-deal.component";
import { SharedComponentsModule } from "./components/sharedComponents.module";
import { PipeModule } from "common/_pipe/pipe.module";
import { ProductsComponent } from './products/products.component';

@NgModule({
  declarations: [
    HomeComponent,
    CategoryComponent,
    ProductDetailComponent,
    CartComponent,
    HotDealComponent,
    LastMinuteDealComponent,
    ProductsComponent,
  ],
  imports: [
    CommonModule,
    PagesModule,
    PaymentModule,
    ECommerceRoutingModule,
    AlertModule.forRoot({ maxMessages: 1, timeout: 2500, position: "right" }),
    ComponentModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2TelInputModule,
    NgxIntlTelInputModule,
    RouterModule,
    BrowserAnimationsModule,
    CarouselModule,
    NgxGalleryModule,
    TranslateModule.forRoot(),
    ModalModule.forRoot(),
    StarRatingModule.forRoot(),
    MaterialModule,
    TemplateModule,
    DesignerCatalogModule,
    PaginationModule.forRoot(),
    SharedComponentsModule,
    PipeModule,
  ],
  providers: [EventEmitterService],
})
export class ECommerceModule {}
