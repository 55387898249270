import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { SiderbarComponent } from "./siderbar/siderbar.component";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "common/modules/material.module";
import { RouterModule } from "@angular/router";
import { ComponentModule } from "common/_components/components.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ModalModule } from "ngx-bootstrap/modal";

@NgModule({
  declarations: [HeaderComponent, FooterComponent, SiderbarComponent],
  imports: [
    CommonModule,
    TranslateModule.forRoot(),
    MaterialModule,
    RouterModule,
    ComponentModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
  ],
  exports: [HeaderComponent, FooterComponent, SiderbarComponent],
})
export class TemplateDefaultModule {}
