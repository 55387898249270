import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";

import { constEnv } from "../constants.env";
import { LanguageService } from "./language.service";

@Injectable({
  providedIn: "root",
})
export class GeneralService {
  constantConfig;

  constructor(private http: HttpClient, private ls: LanguageService) {
    this.constantConfig = constEnv;
  }

  getAllCountry() {
    return this.http.get(`${this.constantConfig.apiURL}/country/get`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getstateByCountryId(countryId: number) {
    const param = new HttpParams().set("countryid", countryId.toString());

    return this.http
      .get(`${this.constantConfig.apiURL}/state/getbyid`, {
        params: param,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getcityByStateId(stateId: number) {
    const param = new HttpParams().set("stateid", stateId.toString());

    return this.http
      .get(`${this.constantConfig.apiURL}/city/getbyid`, {
        params: param,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getDefaultSettingsValue() {
    return this.http
      .get(`${this.constantConfig.apiURL}/getdefaultvalue`)
      .pipe(
        map((data) => {
          return data;
        })
      )
      .subscribe((response) => {
        if (response["status"] === true) {
          this.setDefaultSettings(response["data"]);
        }
      });
  }

  setDefaultSettings(settingsValue) {
    localStorage.setItem("defaultSettings", JSON.stringify(settingsValue));
  }

  getDefaultSettings() {
    let defaultSettings = localStorage.getItem("defaultSettings");
    defaultSettings = JSON.parse(defaultSettings);
    return defaultSettings ? defaultSettings : null;
  }
  getGoogleMapKey() {
    let defaultSettings = this.getDefaultSettings();
    if (!defaultSettings) {
      this.getDefaultSettingsValue();
      setTimeout(function () {
        defaultSettings = this.getDefaultSettings();
      }, 100);
    }
    return defaultSettings["googleMapAPIKey"]
      ? defaultSettings["googleMapAPIKey"]
      : null;
  }

  getCmsPageContent(pagename) {
    const lang = localStorage.getItem("locale") || "en-US";
    const param = new HttpParams().set("u_lang", lang);

    return this.http
      .get(`${this.constantConfig.apiURL}/page/${pagename}`, {
        params: param,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getAllCurrencies() {
    return this.http.get(`${this.constantConfig.apiURL}/currency`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getSettings(settingKey) {
    const settings = this.getDefaultSettings();
    if (settings !== null) {
      return typeof settings[settingKey] !== "undefined"
        ? settings[settingKey]
        : "";
    } else {
      return "";
    }
  }

  getDefaultCurrency() {
    return this.getSettings("currancy_code") || constEnv.defaultCurrency;
  }

  getFaq() {
    return this.http.get(`${this.constantConfig.apiURL}/getfaq`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  sendContactInquiry(formData) {
    let params = new HttpParams();
    const formKeys = Object.keys(formData);

    formKeys.map((itemKey) => {
      params = params.set(itemKey.toString(), formData[itemKey].toString());
    });

    return this.http
      .post(
        `${this.constantConfig.apiURL}/contactus`,
        {},
        {
          params: params,
        }
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getServiceTypes() {
    return this.http.get(`${this.constantConfig.apiURL}/servicetype`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getSiteLogo(svg = false) {
    return svg
      ? this.constantConfig.LOGO_SVG_PATH
      : this.constantConfig.LOGO_PATH;
  }

  getSiteCategory() {
    const langId = this.ls.getCurrentLanguageId();
    const param = new HttpParams().set("LanguageId", langId);

    return this.http
      .get(`${this.constantConfig.apiURL}/store/categories`, {
        params: param,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  // Delivery Zone Dt. 26-07-2021 By. PCG
  getDeliveryZoneLists(dataTablesParameters) {
    const langId = this.ls.getCurrentLanguageId();
    const param = new HttpParams().set("LanguageId", langId);

    let searchValue = "";
    let searchBy = "";
    if (dataTablesParameters.search.value !== "") {
      searchValue = dataTablesParameters.search.value;
      searchBy = dataTablesParameters.search.column;
    }

    const pageNo =
      (dataTablesParameters.start * 1) / (dataTablesParameters.length * 1);

    const searchParams = new HttpParams()
      // .set('searchColumn', searchBy)
      // .set('searchValue', searchValue)
      .set("PageNo", pageNo.toString())
      .set("PageSize", dataTablesParameters.length);
    // .set('sortColumn', '')
    // .set('sortOrder', '');

    return this.http
      .get(`${this.constantConfig.apiStoreURL}/getzonelist`, {
        params: searchParams,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getDeliveryZoneById(id: number) {
    return this.http
      .get(`${this.constantConfig.apiStoreURL}/getzonebyid/${id}`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  addEditDeliveryZone(formData) {
    return this.http
      .post(`${this.constantConfig.apiStoreURL}/addeditzone`, formData)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  deleteDeliveryZone(id: number) {
    return this.http.get(
      `${this.constantConfig.apiStoreURL}/deletezone/${id}`,
      {}
    );
  }

  deliveryZoneChangeStatus(id: number, status: number) {
    return this.http.post(
      `${this.constantConfig.apiStoreURL}/updatezonestatus/${id}/${status}`,
      {}
    );
  }

  getDeliveryZoneForStore() {
    return this.http
      .get(`${this.constantConfig.apiStoreURL}/getzoneforstore`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  // Shipping Rate
  addEditShippingRate(formData) {
    return this.http
      .post(`${this.constantConfig.apiStoreURL}/addeditshippingrate`, formData)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getShippingRateById(id: number) {
    return this.http
      .get(`${this.constantConfig.apiStoreURL}/getshippingratebyid/${id}`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getShippingRateLists(dataTablesParameters) {
    let searchValue = "";
    let searchBy = "";
    if (dataTablesParameters.search.value !== "") {
      searchValue = dataTablesParameters.search.value;
      searchBy = dataTablesParameters.search.column;
    }

    const pageNo =
      (dataTablesParameters.start * 1) / (dataTablesParameters.length * 1);

    const searchParams = new HttpParams()
      // .set('searchColumn', searchBy)
      // .set('searchValue', searchValue)
      .set("PageNo", pageNo.toString())
      .set("PageSize", dataTablesParameters.length);
    // .set('sortColumn', '')
    // .set('sortOrder', '');

    return this.http
      .get(`${this.constantConfig.apiStoreURL}/getshippingratelist`, {
        params: searchParams,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  deleteShippingRate(id: number) {
    return this.http.get(
      `${this.constantConfig.apiStoreURL}/deleteshippingrate/${id}`,
      {}
    );
  }

  shippingRateChangeStatus(id: number, status: number) {
    return this.http.post(
      `${this.constantConfig.apiStoreURL}/updateshippingratestatus/${id}/${status}`,
      {}
    );
  }

  getReturnReasonOptions() {
    return this.http.get(`${this.constantConfig.apiURL}/getreturnoption`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getCancelReasonOptions() {
    return this.http.get(`${this.constantConfig.apiURL}/getcanceloption`).pipe(
      map((data) => {
        return data;
      })
    );
  }
}
