export class Order {
  customerAddress: string;
  customerId: number;
  customerImageUrl: string;
  customerName: string;
  deliveryType: string;
  discount: number;
  isSelfPickUp: boolean;
  orderDate: string;
  orderId: number;
  orderNo: string;
  paymentType: string;
  storeEarning: number;
  totalProduct: number;
  totalProductPrice: string;

  customerPhone?: string;
  notes?: string;
  statusId?: number;
  statusName?: string;
  finalTotal?: string;
  finalTotalWOCC?: number;
  subTotal?: string;
  deliveryCharge?: string;
  adminEarning?: number;
  paymentGatewayCharges?: number;
  patchDtos: PatchInfo[];
  myCartCalulationDto: OrderCalculation;
  calculationDetails_New: CalculationDetail[];

  offerDiscount: number;
  storeDeliveryCharge: number;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

interface PatchInfo {
  patchName: string;
  date: string;
  itemList: PatchItem[];
}

interface PatchItem {
  orderId: number;
  productName: string;
  qty: number;
  price: string;
  finalPrice: number;
  productId: number;
  productImageUrl: string;
  totalPrice: string;
  orderDetailId: number;
  orderNo: string;
  storeName: string;
  storeAddress: string;
  customerAddress: string;
  storeId: number;
  langId: number;
  statusId: number;
  backgroundColor: string;
  statusColor: string;
  statusName: string;
  deliverDate: string;
  deliveryPlanDate: string;
}

interface OrderCalculation {
  currencyCode: string;
  deliveryCharges: number;
  offerDiscount: number;
  packingCharges: number;
  assemblyCharge: number;
  paymentGatewayCharges: number;
  promotionalDiscount: number;
  subTotal: number;
  tax: number;
  totalAmount: number;
  differDeliveryCharge: number;
  siteEarning: number;
}

export interface CalculationDetail {
  key: string;
  value: string;
  keyColor: string;
  valueColor: string;
}
