import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { first } from "rxjs/operators";

import { constEnv } from "common/constants.env";
import { TitleService } from "common/services/title.service";
import { AlertService } from "@full-fledged/alerts";
import { TranslateService } from "@ngx-translate/core";
import { IsLoadingService } from "@service-work/is-loading";
import { ProductService } from "common/services/store/product.service";
import { ProductBrand } from "common/_models/store/productBrand.model";
import { ProductCategory } from "common/_models/store/productCategory.model";
import { ProductLabel } from "common/_models/store/productLabel.model";
import { ProductTag } from "common/_models/store/productTag.model";
import { ProductType } from "common/_models/store/productType.model";
import { HttpParams } from "@angular/common/http";
import { ProductTheme } from "common/_models/store/productTheme.model";
import { ProductColorPalette } from "common/_models/store/productColorPalette.model";
import { ProductServiceType } from "common/_models/store/productServiceType.model";
import { CustomValidator } from "common/_helpers/custom.validator";

@Component({
  selector: "app-add-product",
  templateUrl: "./add-product.component.html",
  styleUrls: ["./add-product.component.css"],
})
export class AddProductComponent implements OnInit {
  config = constEnv;
  productListUrl: string;

  dropDownValues: DropDownValues;
  subCategories: ProductCategory[] = [];

  addProductForm: FormGroup;
  loading = false;
  submitted = false;

  defaultLanguage: any;
  allLanguages: any;
  availableLangs = [];

  dropdownSettings = {};

  @ViewChild("primaryFileInput", { static: true }) primaryFileInput: ElementRef;
  primaryFileData: File = null;
  primaryPreviewUrl: any = null;

  @ViewChild("secondaryFileInput", { static: true })
  secondaryFileInput: ElementRef;
  secondaryFileData: File[] = [];
  secondaryPreviewUrl: any = [];

  sellingPrice: Number = 0;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private alert: AlertService,
    private titleService: TitleService,
    private isLoadingService: IsLoadingService,
    private translate: TranslateService,
    private product: ProductService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Add Product");

    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }

    this.productListUrl = `${this.config.STORE_URL}/product/lists`;

    this.dropdownSettings = {
      singleSelection: false,
      text: "Product Tags",
      selectAllText: this.translate.get("SelectAll")["value"],
      unSelectAllText: this.translate.get("UnSelectAll")["value"],
      enableSearchFilter: true,
      classes: "myclass custom-class",
    };

    this.dropDownValues = {
      categoryLists: [],
      brands: [],
      productTags: [],
      productLabels: [],
    } as DropDownValues;

    this.makeForm();
    this.loadDropDownValues();
  }

  loadDropDownValues() {
    this.isLoadingService.add();
    this.product.getDropDownValues().subscribe((data) => {
      const dataValues = data["data"];

      this.dropDownValues = this.product.getProductDropDownModels(
        dataValues
      ) as DropDownValues;

      this.isLoadingService.remove();
    });
  }

  getSubcaregoryLists() {
    const catId = this.addProductForm.value.categoryName;

    if (catId !== null) {
      this.isLoadingService.add();
      this.addProductForm.get("subCategory").setValue(null);
      this.product.getSubCategortLists(catId).subscribe((data) => {
        if (data["data"] !== null) {
          const dataValues = data["data"];

          this.subCategories = dataValues;

          this.addProductForm
            .get("subCategory")
            .setValidators(Validators.required);
        } else {
          this.subCategories = [];
        }
        this.isLoadingService.remove();
      });
    } else {
      this.subCategories = [];
    }
  }

  makeForm() {
    this.addProductForm = this.fb.group(
      {
        productType: [null, Validators.required],
        serviceTypeId: [null, Validators.required],
        categoryName: [null, Validators.required],
        subCategory: [null, Validators.required],
        productName: ["", Validators.required],
        descriptipn: [""],
        brand: [null, Validators.required],
        productTags: [null],
        productLabel: [null],
        price: ["", Validators.required],
        offerAmount: [""],
        primaryImage: [null, Validators.required],
        secondaryImage: null,
        videoUrl: [""],
        theme: [null, Validators.required],
        colorPalette: [null, Validators.required],
        isFragile: [null],
        IsAssemblyRequired: [null],
        AssemblyCharges: [0],
        isReturnable: [null],
        productDimensions: this.fb.group({
          weight: [null, Validators.required],
          length: [null, Validators.required],
          width: [null, Validators.required],
          height: [null, Validators.required],
        }),
        shipping: this.fb.group({
          processingTime: [null, Validators.required],
          weight: [null, Validators.required],
          length: [null, Validators.required],
          width: [null, Validators.required],
          height: [null, Validators.required],
        }),
      },
      {
        validators: [
          CustomValidator.OnlyZeroNotAllowed("productDimensions.weight"),
          CustomValidator.OnlyZeroNotAllowed("productDimensions.length"),
          CustomValidator.OnlyZeroNotAllowed("productDimensions.width"),
          CustomValidator.OnlyZeroNotAllowed("productDimensions.height"),
          CustomValidator.OnlyZeroNotAllowed("shipping.weight"),
          CustomValidator.OnlyZeroNotAllowed("shipping.length"),
          CustomValidator.OnlyZeroNotAllowed("shipping.width"),
          CustomValidator.OnlyZeroNotAllowed("shipping.height"),
          // CustomValidator.OnlyZeroNotAllowed("AssemblyCharges"),
        ],
      }
    );
  }

  primaryFileProgress(primaryFileInput: any) {
    const allowedImageTypes = ["image/jpeg", "image/jpg", "image/png"];
    const selectedFile = primaryFileInput.target.files[0] as File;

    if (selectedFile) {
      if (allowedImageTypes.includes(selectedFile.type)) {
        this.primaryFileData = selectedFile;
        this.primaryImagePreview();
      } else {
        this.alert.danger("Please upload only JPG, JPEG, or PNG image.");
        primaryFileInput.target.value = null;
        this.primaryPreviewUrl = null;
        this.addProductForm.get("primaryImage").setValue(null);
      }
    }
  }
  primaryImagePreview() {
    const mimeType = this.primaryFileData.type;
    const reader = new FileReader();
    reader.readAsDataURL(this.primaryFileData);
    reader.onload = (_event) => {
      this.primaryPreviewUrl = reader.result;
      if (this.primaryPreviewUrl.length > 2 ** 21) {
        this.alert.danger("File exceeds the maximum size: 2mb.");
        this.removePrimaryImage();
      } else {
        this.addProductForm.get("primaryImage").setValue({
          filename: this.primaryFileData.name,
          filetype: this.primaryFileData.type,
          value: this.primaryPreviewUrl.split(",")[1],
        });
      }
    };
  }

  removePrimaryImage() {
    this.primaryPreviewUrl = null;
    this.primaryFileData = null;
    this.addProductForm.get("primaryImage").setValue(null);
  }

  secondaryFileProgress(secondaryFileInput: any) {
    this.secondaryFileData.push(secondaryFileInput.target.files[0] as File);
    this.secondaryImagePreview();
  }

  secondaryImagePreview() {
    if (this.secondaryFileData.length > 0) {
      this.secondaryPreviewUrl = [];
      for (let i = 0; i < this.secondaryFileData.length; i++) {
        const reader = new FileReader();
        reader.readAsDataURL(this.secondaryFileData[i]);
        reader.onload = (_event) => {
          this.secondaryPreviewUrl[i] = reader.result;
          if (this.secondaryPreviewUrl[i].length > 2 ** 21) {
            this.alert.danger("File exceeds the maximum size: 2mb.");
            this.removeSecondaryImage(i);
          }
        };
      }
    }
  }

  onAssemblyChange($event) {
    if ($event.checked === false) {
      this.addProductForm.get("AssemblyCharges").setValue(0);
      this.addProductForm.get("AssemblyCharges").setValidators(null);
    } else {
      this.addProductForm.get("AssemblyCharges").setValue(null);
      this.addProductForm
        .get("AssemblyCharges")
        .setValidators(Validators.required);
    }
  }

  removeSecondaryImage(i) {
    this.secondaryFileData.splice(i, 1);
    this.secondaryPreviewUrl.splice(i, 1);
  }

  onOfferChange() {
    const price =
      this.addProductForm.value.price > 0 ? this.addProductForm.value.price : 0;
    const offerAmount =
      this.addProductForm.value.offerAmount > 0
        ? this.addProductForm.value.offerAmount
        : 0;

    if (offerAmount > 0) {
      this.sellingPrice = price - (price * offerAmount) / 100;
    } else {
      this.sellingPrice = price;
    }
  }

  onProductTypeChange($event) {
    const itemType = $event;
    if (itemType === 2) {
      this.addProductForm.get("price").setValidators(null);
      this.addProductForm.get("offerAmount").setValidators(null);
      // this.editProductForm.get("sellingPrice").setValidators(null);
      this.addProductForm.get("price").updateValueAndValidity();
      this.addProductForm.get("offerAmount").updateValueAndValidity();
      // this.editProductForm.get("sellingPrice").updateValueAndValidity();
      this.addProductForm.get("price").setValue(0);
      this.addProductForm.get("offerAmount").setValue(0);
    } else {
      this.addProductForm.get("price").setValidators([Validators.required]);
      this.addProductForm.get("offerAmount").setValidators(null);
      this.addProductForm.get("price").updateValueAndValidity();
      this.addProductForm.get("offerAmount").updateValueAndValidity();
      this.addProductForm.get("price").setValue("");
      this.addProductForm.get("offerAmount").setValue("");
    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.addProductForm.invalid) {
      return;
    }

    this.loading = true;
    this.isLoadingService.add();

    const formData = new FormData();
    formData.append("ServiceTypeId", this.addProductForm.value.serviceTypeId);
    formData.append("CategoryId", this.addProductForm.value.categoryName);
    formData.append("SubCategoryId", this.addProductForm.value.subCategory);
    formData.append("ProductName", this.addProductForm.value.productName);
    formData.append("ProductDesc", this.addProductForm.value.descriptipn);
    formData.append("ProductPrice", this.addProductForm.value.price);
    formData.append("ProductOfferAmt", this.addProductForm.value.offerAmount);
    formData.append(
      "PrimaryImage",
      this.primaryFileData !== null ? this.primaryFileData : ""
    );
    formData.append("ItemTypeId", this.addProductForm.value.productType);
    formData.append(
      "ItemTagId",
      this.addProductForm.value.productLabel !== null
        ? this.addProductForm.value.productLabel
        : 0
    );
    formData.append("BrandId", this.addProductForm.value.brand);
    // formData.append('ProductTagIds', productTags.join(','));
    formData.append("VideoURL", this.addProductForm.value.videoUrl);
    formData.append("ThemeId", this.addProductForm.value.theme);
    formData.append("ColorPaletteId", this.addProductForm.value.colorPalette);
    formData.append(
      "IsFragile",
      this.addProductForm.value.isFragile !== null
        ? this.addProductForm.value.isFragile
        : false
    );

    formData.append(
      "IsReturnable",
      this.addProductForm.value.isReturnable !== null
        ? this.addProductForm.value.isReturnable
        : false
    );

    const pWeight =
      typeof this.addProductForm.value.productDimensions.weight !== "undefined"
        ? this.addProductForm.value.productDimensions.weight
        : 0;
    const pLength =
      typeof this.addProductForm.value.productDimensions.length !== "undefined"
        ? this.addProductForm.value.productDimensions.length
        : 0;
    const pWidth =
      typeof this.addProductForm.value.productDimensions.width !== "undefined"
        ? this.addProductForm.value.productDimensions.width
        : 0;
    const pHeight =
      typeof this.addProductForm.value.productDimensions.height !== "undefined"
        ? this.addProductForm.value.productDimensions.height
        : 0;

    formData.append("ProductDimensions.Weight", pWeight);
    formData.append("ProductDimensions.Length", pLength);
    formData.append("ProductDimensions.Width", pWidth);
    formData.append("ProductDimensions.Height", pHeight);

    // Shipping

    const sWeight =
      typeof this.addProductForm.value.shipping.weight !== "undefined"
        ? this.addProductForm.value.shipping.weight
        : 0;
    const sLength =
      typeof this.addProductForm.value.shipping.length !== "undefined"
        ? this.addProductForm.value.shipping.length
        : 0;
    const sWidth =
      typeof this.addProductForm.value.shipping.width !== "undefined"
        ? this.addProductForm.value.shipping.width
        : 0;
    const sHeight =
      typeof this.addProductForm.value.shipping.height !== "undefined"
        ? this.addProductForm.value.shipping.height
        : 0;

    const sprocessingTime =
      typeof this.addProductForm.value.shipping.processingTime !== "undefined"
        ? this.addProductForm.value.shipping.processingTime
        : 0;

    formData.append("Shipping.ProcessingTime", sprocessingTime);
    formData.append("Shipping.Weight", sWeight);
    formData.append("Shipping.Length", sLength);
    formData.append("Shipping.Width", sWidth);
    formData.append("Shipping.Height", sHeight);

    const isAssemblyRequired =
      this.addProductForm.value.IsAssemblyRequired !== null
        ? this.addProductForm.value.IsAssemblyRequired
        : false;
    formData.append("IsAssemblyRequired", isAssemblyRequired);

    const AssemblyCharges =
      this.addProductForm.value.AssemblyCharges !== null
        ? this.addProductForm.value.AssemblyCharges
        : 0;
    formData.append(
      "AssemblyCharges",
      isAssemblyRequired === true ? AssemblyCharges : null
    );

    if (this.secondaryFileData.length > 0) {
      for (let i = 0; i < this.secondaryFileData.length; i++) {
        formData.append("SecondaryImage", this.secondaryFileData[i]);
      }
    }

    if (this.addProductForm.value.productTags !== null) {
      this.addProductForm.value.productTags.forEach((singleTag) => {
        formData.append("ProductTagIds", singleTag.id);
      });
    }

    this.product
      .add(formData)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data["status"] === true) {
            this.alert.success(data["message"]);
            const editUrl = `${this.config.STORE_URL}/product/edit/${data["data"]}`;
            this.router.navigate([`${editUrl}`]);
          } else {
            this.alert.danger(data["message"]);
            this.isLoadingService.remove();
          }
        },
        (err) => {
          if (typeof err.error !== "undefined") {
            const msg =
              typeof err.error.Message !== "undefined"
                ? err.error.Message
                : err.error.message;
            this.alert.danger(msg);
          }
          this.isLoadingService.remove();
          this.loading = false;
        }
      );
  }
}

export interface DropDownValues {
  brands: ProductBrand[];
  categoryLists: ProductCategory[];
  productLabels: ProductLabel[];
  productTags: ProductTag[];
  productTypes: ProductType[];
  themes: ProductTheme[];
  colorPalettes: ProductColorPalette[];
  productServiceType: ProductServiceType[];
}
