import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { constEnv } from "common/constants.env";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService } from "ngx-alerts";
import { DeliveryCompanyService } from "common/services/delivery-company.service";
import { CustomValidator } from "common/_helpers/custom.validator";
import { first } from "rxjs/operators";
import { TitleService } from "common/services/title.service";
import { IsLoadingService } from "@service-work/is-loading";

@Component({
  selector: "app-dc-reset-password",
  templateUrl: "./dc-reset-password.component.html",
  styleUrls: ["./dc-reset-password.component.css"],
})
export class DcResetPasswordComponent implements OnInit {
  RPForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  accessToken: string;
  constantConfig = constEnv;

  public showNewPassword: boolean = false;
  public showConfirmPassword: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    // public constantConfig: ConstansService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private dcService: DeliveryCompanyService,
    private titleService: TitleService,
    private isLoadingService: IsLoadingService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("ResetPassword");
    this.document.body.classList.add("reset-password-body");

    this.accessToken = this.route.snapshot.params.token;

    this.RPForm = this.formBuilder.group(
      {
        newPassword: new FormControl(
          "",
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(16),
          ])
        ),
        confirmPassword: new FormControl(
          "",
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(16),
          ])
        ),
      },
      { validator: CustomValidator.MatchPassword }
    );
  }

  ngOnDestroy(): void {
    this.document.body.classList.remove("reset-password-body");
  }

  get f() {
    return this.RPForm.controls;
  }

  getErrorNewPassword() {
    return this.RPForm.get("newPassword").hasError("required")
      ? ""
      : this.RPForm.get("newPassword").hasError("minlength")
      ? this.titleService.getTranslatedString("PasswordFormate")
      : this.RPForm.get("newPassword").hasError("maxlength")
      ? this.titleService.getTranslatedString("PasswordFormate")
      : "";
  }

  getErrorConfirmPassword() {
    return this.RPForm.get("confirmPassword").hasError("required")
      ? ""
      : this.RPForm.get("confirmPassword").hasError("minlength")
      ? this.titleService.getTranslatedString("PasswordFormate")
      : this.RPForm.get("confirmPassword").hasError("maxlength")
      ? this.titleService.getTranslatedString("PasswordFormate")
      : this.RPForm.get("confirmPassword").hasError("confirmPassword")
      ? this.titleService.getTranslatedString(
          "NewPasswordAndConfirmPasswordMustBeSame"
        )
      : "";
  }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    // this.alertService.clear();

    // stop here if form is invalid
    if (this.RPForm.invalid) {
      return;
    }

    this.loading = true;
    this.isLoadingService.add();

    this.dcService
      .resetPassword(this.accessToken, this.RPForm.value.newPassword)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data["status"]) {
            this.alertService.success(data["message"]);
            this.submitted = false;
            this.RPForm.reset();
          } else {
            this.alertService.danger(data["message"]);
          }
          this.loading = false;
          this.isLoadingService.remove();
          this.router.navigate([
            `${this.constantConfig.DELIVERY_COMPANY_URL}/login`,
          ]);
        },
        (err) => {
          const msg =
            typeof err.error.message !== "undefined" ? err.error.message : "";
          if (msg) {
            this.alertService.danger(msg);
          }
          this.isLoadingService.remove();
          this.loading = false;
        }
      );
  }
}
