import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LoaderService } from "common/services/loader.service";
import { SettingsService } from "common/services/store/settings.service";
import { TitleService } from "common/services/title.service";
import { AlertService } from "@full-fledged/alerts";

@Component({
  selector: "app-policy",
  templateUrl: "./policy.component.html",
  styleUrls: ["./policy.component.css"],
})
export class PolicyComponent implements OnInit {
  policyForm: FormGroup;
  submitted = false;
  loading = false;

  constructor(
    private fb: FormBuilder,
    private ls: LoaderService,
    private alert: AlertService,
    private settings: SettingsService,
    private titleService: TitleService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("StorePolicy");
    this.makeForm();

    this.getPolicyData();
  }

  makeForm() {
    this.policyForm = this.fb.group({
      storeReturnPolicy: [""],
      customerDropoffPolicy: [""],
    });
  }

  get f() {
    return this.policyForm.controls;
  }

  getPolicyData() {
    this.ls.showLoader();
    this.settings.getPolicyDetail().subscribe(
      (data) => {
        const res = data as any;
        if (res.status === true) {
          if (res.data !== null) {
            this.setFormValue(res.data);
          }
          this.ls.hideLoader();
        } else {
          // this.alert.error(data['message']);
          this.ls.hideLoader();
        }
      },
      (err) => {
        // this.alert.error(err.error.message);
        this.ls.hideLoader();
      }
    );
  }

  setFormValue(data: any) {
    for (const key in data) {
      if (
        data.hasOwnProperty(key) &&
        key !== "store_id" &&
        key !== "driver_id"
      ) {
        if (typeof data[key] !== null) {
          const val = data[key] > 0 ? data[key] : "";
          this.policyForm.get(key).setValue(val);
        }
      }
    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.policyForm.invalid) {
      return;
    }

    this.ls.showLoader();
    this.loading = true;

    this.settings.updatePolicyDetail(this.policyForm.value).subscribe(
      (data) => {
        if (data["status"] === true) {
          this.alert.success(data["message"]);
          this.ls.hideLoader();
        } else {
          this.alert.danger(data["message"]);
          this.ls.hideLoader();
        }
        this.loading = false;
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        this.alert.danger(msg);
        this.ls.hideLoader();
        this.loading = false;
      }
    );
  }
}
