import { Component, OnInit, ViewChild } from "@angular/core";

import { constEnv } from "common/constants.env";
import { LoaderService } from "common/services/loader.service";

import { TitleService } from "common/services/title.service";
import { first } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService } from "@full-fledged/alerts";
import { OrderService } from "common/services/store/order.service";
import { CalculationDetail, Order } from "common/_models/store/order";
import { MatSelectionList } from "@angular/material/list";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalDirective } from "ngx-bootstrap/modal";

@Component({
  selector: "app-detail",
  templateUrl: "./detail.component.html",
  styleUrls: ["./detail.component.css"],
})
export class DetailComponent implements OnInit {
  orderId = 0;
  listUrl: string;
  orderInfo: Order = null;

  config = constEnv;

  currencyCode;

  @ViewChild("cancelFormModal", { static: false })
  cancelFormModal: ModalDirective;
  @ViewChild("allCancelItemSelected", { static: false })
  public allCancelItemSelected: MatSelectionList;
  allCancelItemComplete: boolean = false;
  cancelOrderCurrencyCode: string = "";
  cancelRequestForm: FormGroup;
  isOpenCancelRequestModal: boolean = false;
  cancelReasonOptions: any = [];

  orderCalculationDetail: CalculationDetail[] = [];

  constructor(
    private titleService: TitleService,
    private route: ActivatedRoute,
    private router: Router,
    private alert: AlertService,
    private ls: LoaderService,
    private os: OrderService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.titleService.setTitle("OrderDetails");

    this.orderId =
      this.route.snapshot.params.orderId > 0
        ? this.route.snapshot.params.orderId
        : 0;

    this.listUrl = `${this.config.STORE_URL}/orders`;

    if (this.orderId <= 0) {
      this.router.navigate([this.listUrl]);
      return false;
    }

    this.getOrderDetail();
  }

  getOrderDetail() {
    this.ls.showLoader();
    this.os
      .getDetail(this.orderId)
      .pipe(first())
      .subscribe(
        (data) => {
          const res = data as OrderDetailResponse;
          if (res.status === true) {
            this.orderInfo = new Order().deserialize(res.data);
            // console.log(this.orderInfo);
            this.currencyCode =
              this.orderInfo.myCartCalulationDto.currencyCode || "";

            this.orderCalculationDetail = this.orderInfo.calculationDetails_New;

            this.ls.hideLoader();
          } else {
            this.alert.danger(res.message);
            this.ls.hideLoader();
          }
        },
        (err) => {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          if (msg !== "") {
            this.alert.danger(err.error.message);
          }
          this.ls.hideLoader();
        }
      );
  }

  get getCancelableItems(): any {
    const validCancelStatus = [4, 5, 9, 11];
    const cancelableItems = [];
    if (this.orderInfo) {
      this.orderInfo.patchDtos.map((patch) => {
        patch.itemList.map((item) => {
          if (validCancelStatus.indexOf(item.statusId) !== -1) {
            cancelableItems.push(item);
          }
        });
      });
    }
    return cancelableItems;
  }

  getCancelReasonOption() {
    this.cancelReasonOptions = [];
    this.os
      .getCancelReasonOptions()
      .pipe(first())
      .subscribe(
        (data) => {
          if (data["status"] === true) {
            this.cancelReasonOptions = data["data"];
          } else {
            this.alert.danger(data["message"]);
          }
        },
        (err) => {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          if (msg !== "") {
            this.alert.danger(err.error.message);
          }
        }
      );
  }

  private makeCancelRequestForm() {
    this.cancelRequestForm = this.fb.group({
      orderDetailIds: [""],
      reason: [null, Validators.required],
    });
  }

  openCancelOrderPopup() {
    this.cancelOrderCurrencyCode =
      this.getCancelableItems[0].currencyCode || "";
    this.isOpenCancelRequestModal = true;

    this.getCancelReasonOption();
    this.makeCancelRequestForm();

    setTimeout(() => {
      this.cancelFormModal.show();
    }, 100);
  }
  onCancelOrderModalHidden() {
    this.cancelFormModal.hide();
    this.allCancelItemSelected.deselectAll();
    this.cancelRequestForm.reset();
    this.cancelRequestForm.get("reason").updateValueAndValidity();
  }

  toggleCancelSelection(completed: boolean) {
    this.allCancelItemComplete = completed;
    if (completed) {
      this.allCancelItemSelected.selectAll();
    } else {
      this.allCancelItemSelected.deselectAll();
    }
  }

  isAllCancelSelected() {
    return (
      this.allCancelItemSelected.selectedOptions.selected.length ===
      this.allCancelItemSelected.options.length
    );
  }

  onCancelSelectionListChange(event) {
    this.allCancelItemComplete = this.isAllCancelSelected();
  }

  someCancelComplete() {
    return (
      this.allCancelItemSelected &&
      this.allCancelItemSelected.selectedOptions.selected.length > 0 &&
      !this.allCancelItemComplete
    );
  }

  isCancelFormValid(): boolean {
    if (this.cancelRequestForm.invalid) {
      return false;
    }

    if (
      this.allCancelItemSelected &&
      this.allCancelItemSelected.selectedOptions.selected.length === 0
    ) {
      return false;
    }

    return true;
  }

  onCancelRequestSubmit() {
    if (!this.isCancelFormValid()) {
      return;
    }

    const selectedItems =
      this.allCancelItemSelected.selectedOptions.selected.map(
        (item) => item.value
      );

    const orderDetailIdSet = [];
    selectedItems.forEach((item) => {
      orderDetailIdSet.push(item.orderDetailId);
    });

    const formData = {
      orderId: this.orderInfo.orderId,
      // orderDetailIds: this.allCancelItemComplete
      //   ? ""
      //   : orderDetailIdSet.join(","),
      orderDetailIds: orderDetailIdSet.join(","),
      reason: this.cancelRequestForm.value.reason,
    };

    this.ls.showLoader();
    this.os
      .createCancelOrderByStore(formData)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data["status"] === true) {
            this.alert.success(data["message"]);
            this.cancelFormModal.hide();
            this.ls.hideLoader();
            this.getOrderDetail();
          } else {
            this.alert.danger(data["message"]);
            this.ls.hideLoader();
          }
        },
        (err) => {
          if (typeof err.error !== "undefined") {
            const msg =
              typeof err.error.Message !== "undefined"
                ? err.error.Message
                : err.error.message;
            this.alert.danger(msg);
          }
          this.ls.hideLoader();
        }
      );
  }
}

interface OrderDetailResponse {
  status: boolean;
  message: string;
  data: Order;
}
