import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { constEnv } from 'common/constants.env';
import { AuthGuard } from 'common/_helpers/auth.guard';

import { OrdersComponent } from './orders/orders.component';
import { DetailComponent } from './detail/detail.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { OrdersModule } from './orders.module';

const ORDER_ENDPOINT = `${constEnv.storeEndPoint}/order`;

const routes: Routes = [
   {
      path: `${ORDER_ENDPOINT}`,
      children: [
         { path: 'lists', component: OrdersComponent },
         {
            path: 'detail',
            children: [
               { path: ':orderId', component: DetailComponent },
               { path: '', redirectTo: `${ORDER_ENDPOINT}`, pathMatch: 'full'}
            ]
         },
         { path: ':orderId/schedule', component: ScheduleComponent },
         { path: '', component: OrdersComponent }
      ],
      canActivate: [AuthGuard]
   },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class OrdersRoutingModule { }
