import { Component, OnInit, Inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { constEnv } from "common/constants.env";
import { AlertService } from "ngx-alerts";
import { FormGroup, Validators, FormBuilder, FormArray } from "@angular/forms";
import { TitleService } from "common/services/title.service";
import { IsLoadingService } from "@service-work/is-loading";
import { Observable, of, Subject } from "rxjs";
import {
  catchError,
  distinctUntilChanged,
  switchMap,
  tap,
} from "rxjs/operators";
import { LastminuteDealService } from "common/services/store/lastminuteDeal.service";
import { ProductList } from "common/_models/site/product-list.modal";
import * as moment from "moment";

@Component({
  selector: "app-action",
  templateUrl: "./action.component.html",
  styleUrls: ["./action.component.css"],
})
export class ActionComponent implements OnInit {
  action: string;
  id: number;

  cs = constEnv;

  actionForm: FormGroup;
  loading = false;
  submitted = false;
  btnLable = "AddLastMinuteDeal";
  pageTitle = "AddLastMinuteDeal";

  productsLists$: Observable<ProductList[]>;
  productsLoading = false;
  productsInput$ = new Subject<string>();
  selectedProducts: any[] = [];

  variationLists: ProductVariation[] = null;

  offerTypes: OfferType[] = null;
  canCreateOffer: boolean = false;
  productQty: number;
  productPrice: number;

  discountType: number = 0;
  minDate: Date;
  maxDate = new Date();

  listUrl = `${this.cs.STORE_URL}/product/last-minute-deal`;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alert: AlertService,
    private titleService: TitleService,
    private isLoadingService: IsLoadingService,
    private lastMinuteDealService: LastminuteDealService
  ) {
    this.action = route.snapshot.params.action;
  }

  ngOnInit() {
    this.titleService.setTitle(this.pageTitle);
    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }

    switch (this.action) {
      case "edit":
        // const id = this.route.snapshot.params.id;
        // this.id = (typeof id !== 'undefined') ? id : 0;
        // if (this.id <= 0) {
        //   this.router.navigate([categoryURL]);
        // }

        // this.btnLable = 'EditProductSubCategory';
        // this.pageTitle = 'EditProductSubCategory';

        // if (this.id > 0) {
        // this.ls.showLoader();
        // this.categoryService.subCategoryGet(this.id)
        //   .pipe(first())
        //   .subscribe(
        //     data => {
        //       if (data['status'] === true) {
        //         this.categoryData = data['data'];

        //         for (let i = 1; i <= this.categoryData.DisplayOrderNextValue; i++) {
        //           this.displayOrderCounts.push(i);
        //         }

        //         this.actionForm.get('catID').setValue(this.categoryData.subCatId);
        //         this.actionForm.get('mainCateId').setValue(this.categoryData.catId);
        //         // this.actionForm.get('displayOrder').setValue(this.categoryData.DisplayOrder);

        //         const catNamesControls = this.actionForm.get('catNames');

        //         this.languages.forEach((singleRow, index) => {
        //           const l_index = index;
        //           this.categoryData.languages.forEach(row => {
        //             if (singleRow.id === row.langKey) {
        //               catNamesControls['controls'][l_index].get('catName').setValue(row.langValue);
        //             }
        //           });
        //         });

        //         this.ls.hideLoader();
        //       } else {
        //         this.alert.danger(data['message']);
        //         this.ls.hideLoader();
        //       }
        //     },
        //     err => {
        //       this.alert.danger(err.error.message);
        //       this.ls.hideLoader();
        //     });
        //   }
        break;

      case "add":
        this.createActionForm();
        this.loadAutocompleteProducts();
        break;

      default:
        this.router.navigate([this.listUrl]);
        break;
    }

    this.minDate = new Date();
    // this.maxDate.setDate( this.minDate.getDate() + 4 );
    this.maxDate.setHours(this.minDate.getHours() + 72);
  }

  createActionForm() {
    this.actionForm = this.formBuilder.group({
      productId: [null, Validators.required],
      productVariationId: [null],
      quantity: [null],
      offerEndsOn: [null],
      offerType: [null],
      discountPercent: [null],
      discountAmount: [null],
    });
  }

  trackByFnAttributes(item: ProductList) {
    return item.productId;
  }

  getProductInfo($event: ProductList) {
    this.variationLists = [];
    this.canCreateOffer = false;
    this.productQty = null;
    this.actionForm.get("productVariationId").clearValidators();
    this.actionForm.get("productVariationId").setValue(null);
    this.actionForm.get("productVariationId").updateValueAndValidity();

    this.clearFieldValidation();

    if (typeof $event !== "undefined") {
      this.lastMinuteDealService.getProductInfo($event.productId).subscribe(
        (data) => {
          const res = data as ProductInfoResponse;

          if (res.status === true) {
            this.offerTypes = res.data.offerTypeData as OfferType[];

            const productDetail = res.data.productDetails;
            if (productDetail.hasVariations === true) {
              this.actionForm
                .get("productVariationId")
                .setValidators([Validators.required]);
              this.actionForm
                .get("productVariationId")
                .updateValueAndValidity();
              this.variationLists = productDetail.variations;
            } else {
              if (productDetail.currentStockQuantity > 0) {
                this.canCreateOffer = true;
                this.productQty = productDetail.currentStockQuantity;
                this.productPrice = productDetail.productPrice;
                this.makeFieldRequired();
              }
              this.productQty = productDetail.currentStockQuantity;
            }
          } else {
            this.alert.danger(res.message);
          }
          this.isLoadingService.remove();
        },
        (err) => {
          if (typeof err.error !== "undefined") {
            const msg =
              typeof err.error.Message !== "undefined"
                ? err.error.Message
                : err.error.message;
            // this.alert.danger(msg);
          }
          this.variationLists = [];
          this.canCreateOffer = false;
          this.actionForm.get("productVariationId").clearValidators();
          this.actionForm.get("productVariationId").updateValueAndValidity();
          this.isLoadingService.remove();
        }
      );
    }
  }

  changeVariation($event: ProductVariation) {
    this.canCreateOffer = false;
    this.productQty = 0;
    this.clearFieldValidation();

    if (typeof $event !== "undefined") {
      if ($event.currentStockQuantity > 0) {
        this.canCreateOffer = true;
        this.productQty = $event.currentStockQuantity;
        this.productPrice = $event.productPrice;
        this.makeFieldRequired();
      }
    }
  }

  makeFieldRequired() {
    this.actionForm
      .get("quantity")
      .setValidators([
        Validators.required,
        Validators.min(1),
        Validators.max(this.productQty),
      ]);
    this.actionForm.get("quantity").updateValueAndValidity();

    this.actionForm.get("offerEndsOn").setValidators([Validators.required]);
    this.actionForm.get("offerEndsOn").updateValueAndValidity();

    this.actionForm.get("offerType").setValidators([Validators.required]);
    this.actionForm.get("offerType").updateValueAndValidity();
  }

  clearFieldValidation() {
    this.actionForm.get("quantity").clearValidators();
    this.actionForm.get("quantity").setValue(null);
    this.actionForm.get("quantity").updateValueAndValidity();

    this.actionForm.get("offerEndsOn").clearValidators();
    this.actionForm.get("offerEndsOn").setValue(null);
    this.actionForm.get("offerEndsOn").updateValueAndValidity();

    this.actionForm.get("offerType").clearValidators();
    this.actionForm.get("offerType").setValue(null);
    this.actionForm.get("offerType").updateValueAndValidity();

    this.actionForm.get("discountPercent").clearValidators();
    this.actionForm.get("discountPercent").setValue(null);
    this.actionForm.get("discountPercent").updateValueAndValidity();

    this.actionForm.get("discountAmount").clearValidators();
    this.actionForm.get("discountAmount").setValue(null);
    this.actionForm.get("discountAmount").updateValueAndValidity();
  }

  loadAutocompleteProducts() {
    this.productsLists$ = this.productsInput$.pipe(
      distinctUntilChanged(),
      tap(() => (this.productsLoading = true)),
      switchMap((term) =>
        this.lastMinuteDealService.searchProductList(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => (this.productsLoading = false))
        )
      )
    );
  }

  changeOfferType($event: OfferType) {
    this.actionForm.get("discountPercent").clearValidators();
    this.actionForm.get("discountPercent").setValue(null);
    this.actionForm.get("discountPercent").updateValueAndValidity();

    this.actionForm.get("discountAmount").clearValidators();
    this.actionForm.get("discountAmount").setValue(null);
    this.actionForm.get("discountAmount").updateValueAndValidity();

    this.discountType = 0;

    if (typeof $event !== "undefined") {
      if ($event.offerTypeId === 2) {
        this.actionForm
          .get("discountPercent")
          .setValidators([
            Validators.required,
            Validators.min(1),
            Validators.max(99),
          ]);
        this.actionForm.get("discountPercent").updateValueAndValidity();
      } else if ($event.offerTypeId === 3) {
        this.actionForm
          .get("discountAmount")
          .setValidators([
            Validators.required,
            Validators.min(1),
            Validators.max(this.productPrice - 1),
          ]);
        this.actionForm.get("discountAmount").updateValueAndValidity();
      }
      this.discountType = $event.offerTypeId;
    }
  }

  getErrorQty() {
    return this.actionForm.get("quantity").hasError("required")
      ? ""
      : this.actionForm.get("quantity").hasError("min")
      ? this.titleService.getTranslatedString("QuantityValidation")
      : this.actionForm.get("quantity").hasError("max")
      ? this.titleService.getTranslatedString("Quantitymorethenstock")
      : "";
  }

  getErrorDiscountAmount() {
    return this.actionForm.get("discountAmount").hasError("required")
      ? ""
      : this.actionForm.get("discountAmount").hasError("min")
      ? this.titleService.getTranslatedString("ProductPriceValidation")
      : this.actionForm.get("discountAmount").hasError("max")
      ? this.titleService.getTranslatedString("PriceGreateThenProductPrice")
      : "";
  }

  getErrorDiscountPer() {
    return this.actionForm.get("discountPercent").hasError("required")
      ? ""
      : this.actionForm.get("discountPercent").hasError("min")
      ? this.titleService.getTranslatedString("ProductPriceValidation")
      : this.actionForm.get("discountPercent").hasError("max")
      ? this.titleService.getTranslatedString("DiscountPercentValidation")
      : "";
  }

  onSubmit() {
    this.submitted = true;
    if (this.actionForm.invalid) {
      return;
    }
    // const offerEndDate = new Date(this.actionForm.value.offerEndsOn);
    const offerEndDate = moment(this.actionForm.value.offerEndsOn).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    // const offerEndsOn = new Date(this.actionForm.value.offerEndsOn);
    // const m = ((offerEndsOn.getMonth() * 1) + 1);
    // const month = (m >= 1 && m <= 9) ? '0'+m : m;
    // const day = (offerEndsOn.getDate() >= 1 && offerEndsOn.getDate() <= 9) ? '0'+offerEndsOn.getDate() : offerEndsOn.getDate();
    // const offerEndDate = new Date(offerEndsOn.getFullYear()+'-'+month+'-'+day);

    this.actionForm.value.offerEndsOn = offerEndDate;
    this.actionForm.value.productVariationId =
      this.actionForm.value.productVariationId === null
        ? 0
        : this.actionForm.value.productVariationId;
    this.actionForm.value.discountPercent =
      this.actionForm.value.discountPercent === null
        ? 0
        : this.actionForm.value.discountPercent;
    this.actionForm.value.discountAmount =
      this.actionForm.value.discountAmount === null
        ? 0
        : this.actionForm.value.discountAmount;
    console.log(this.actionForm.value);
    this.isLoadingService.add();
    this.lastMinuteDealService
      .createLastMinuteDeal(this.actionForm.value)
      .subscribe(
        (data) => {
          if (data["status"] === true) {
            this.alert.success(data["message"]);
            this.router.navigate([this.listUrl]);
          } else {
            this.alert.danger(data["message"]);
          }
          this.isLoadingService.remove();
        },
        (err) => {
          if (typeof err.error !== "undefined") {
            const msg =
              typeof err.error.Message !== "undefined"
                ? err.error.Message
                : err.error.message;
            this.alert.danger(msg);
          }
          this.isLoadingService.remove();
        }
      );
  }
}

interface ProductInfoResponse {
  status: boolean;
  message: string;
  data: ProductData;
}

interface ProductData {
  productDetails: ProductDetail;
  offerTypeData: OfferType[];
}

interface ProductDetail {
  productId: number;
  productName: string;
  hasVariations: boolean;
  currentStockQuantity: number;
  productPrice: number;
  variations: ProductVariation[];
}

interface ProductVariation {
  variationId: number;
  variationName: string;
  selected: boolean;
  productPrice: number;
  currentStockQuantity: number;
}

interface OfferType {
  offerTypeId: number;
  offerTypeName: string;
}
