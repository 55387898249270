import {
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
} from "@angular/core";
import { constEnv } from "common/constants.env";
import { CustomerService } from "common/services/customers/customer.service";
import { LoaderService } from "common/services/loader.service";
import { combineLatest, Subscription } from "rxjs";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { first } from "rxjs/operators";
import { AlertService } from "@full-fledged/alerts";

@Component({
  selector: "app-address",
  templateUrl: "./address.component.html",
  styleUrls: ["./address.component.css"],
})
export class AddressComponent implements OnInit {
  addLists: any;
  noDataFoundMsg: any;
  config = constEnv;
  subscriptions: Subscription[] = [];
  selectedAddressId = 0;
  modalRef: BsModalRef;

  constructor(
    private ls: LoaderService,
    private customer: CustomerService,
    private modalService: BsModalService,
    private changeDetection: ChangeDetectorRef,
    private alert: AlertService
  ) {}

  ngOnInit() {
    this.getCustomerAddresses();
  }
  getCustomerAddresses() {
    this.ls.showLoader();
    this.customer.getCustomerAddressese().subscribe(
      (data) => {
        if (data["status"] === true) {
          this.addLists = data["data"];
        } else {
          this.addLists = [];
          this.noDataFoundMsg = data["message"];
        }
        this.ls.hideLoader();
      },
      (err) => {
        this.addLists = [];
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          this.noDataFoundMsg = msg;
        }
        this.ls.hideLoader();
      }
    );
  }
  openModal(template: TemplateRef<any>, addressId) {
    const _combine = combineLatest(
      this.modalService.onShow,
      this.modalService.onShown,
      this.modalService.onHide,
      this.modalService.onHidden
    ).subscribe(() => this.changeDetection.markForCheck());

    this.subscriptions.push(
      this.modalService.onShow.subscribe((reason: string) => {
        this.selectedAddressId = addressId;
      })
    );

    this.subscriptions.push(
      this.modalService.onHidden.subscribe((reason: string) => {
        this.selectedAddressId = 0;
        this.unsubscribe();
      })
    );

    this.subscriptions.push(_combine);

    this.modalRef = this.modalService.show(template, { class: "modal-sm" });
  }
  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }
  deleteAddress(addressId) {
    this.customer
      .deleteCustomerAddress(addressId)
      .pipe(first())
      .subscribe(
        (res) => {
          if (res["status"] === true) {
            this.alert.success(res["message"]);
            this.modalRef.hide();
            this.getCustomerAddresses();
          } else {
            this.alert.danger(res["message"]);
          }
          this.ls.hideLoader();
        },
        (err) => {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          if (msg !== "") {
            this.alert.danger(msg);
          }
          this.ls.hideLoader();
        }
      );
  }
  // loadAddresses() {
  //   this.ls.showLoader();
  //   this.cs.getCustomerAddressese()
  //     .pipe(first())
  //     .subscribe(res => {
  //       if (res['status'] === true) {
  //         this.addLists = res['data'];
  //       } else {
  //         this.addLists = [];
  //         // this.alert.danger( res['message'] );
  //         this.noDataFoundMsg = res['message'];
  //       }
  //       this.ls.hideLoader();
  //     },
  //     err => {
  //       this.addLists = [];
  //       const msg = (typeof err.error.Message !== 'undefined') ? err.error.Message : err.error.message;
  //       if (msg !== '') {
  //         this.noDataFoundMsg = msg;
  //         // this.alert.danger(msg);
  //       }
  //       this.ls.hideLoader();
  //     });
  // }

  setDefaultAddress(addressId: number) {
    this.ls.showLoader();

    this.customer.setCustomerDefaultAddress(addressId).subscribe(
      (data) => {
        if (data["status"] === true) {
          this.ls.hideLoader();
          this.getCustomerAddresses();
        } else {
          this.alert.danger(data["message"]);
          this.ls.hideLoader();
        }
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          this.alert.danger(msg);
        }
        this.ls.hideLoader();
      }
    );
  }
}
