import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { constEnv } from 'common/constants.env';
import { map } from 'rxjs/operators';
import { ProductCategory } from 'common/_models/store/productCategory.model';
import { ProductType } from 'common/_models/store/productType.model';
import { ProductBrand } from 'common/_models/store/productBrand.model';
import { ProductTag } from 'common/_models/store/productTag.model';
import { ProductLabel } from 'common/_models/store/productLabel.model';
import { Observable, of } from 'rxjs';
import { ProductLinked } from 'common/_models/store/product.model';
import { ProductTheme } from 'common/_models/store/productTheme.model';
import { ProductAttribute } from 'common/_models/store/productAttribute.model';
import { ProductServiceType } from 'common/_models/store/productServiceType.model';

@Injectable({ providedIn: 'root' })
export class ProductService {
    configConstant = constEnv;

    constructor(private http: HttpClient) {
    }

    getAll( paging, search: any = [] ) {
        const searchBy = (typeof search.searchBy !== 'undefined') ? search.searchBy : '';
        const searchValue = (typeof search.searchValue !== 'undefined') ? search.searchValue : '';
        const searchParams = new HttpParams()
            .set('searchColumn', searchBy)
            .set('searchValue', searchValue)
            .set('pageNo', (paging.page).toString())
            .set('pageSize', paging.itemsPerPage)
            .set('sortColumn', '')
            .set('sortOrder', '');

        return this.http.get(`${this.configConstant.apiStoreURL}/productlist`, {
            params: searchParams
        })
            .pipe(map(data => {
                return data;
            }));
    }

    get( id ) {
        return this.http.get(`${this.configConstant.apiStoreURL}/getproduct/${id}`)
            .pipe(map(data => {
                return data;
            }));
    }

    add( formData ) {

        return this.http.post(`${this.configConstant.apiStoreURL}/product/add`, formData )
            .pipe(map(data => {
                return data;
            }));
    }

    edit( formData ) {
        return this.http.post(`${this.configConstant.apiStoreURL}/product/edit`, formData)
            .pipe(map(data => {
                return data;
            }));
    }

    delete(id: number) {
        return this.http.get(`${this.configConstant.apiStoreURL}/product/delete/${id}`, {});
    }

    changeStatus(id: any, status: any) {
    const updatedStatus= status == true ? 0 : 1;
        return this.http.get(`${this.configConstant.apiStoreURL}/product/status/${id}/${updatedStatus}`, {});
    }

    publishProduct(id: any, status: any) {
        const updatedStatus = status == true ? true : true;
        return this.http.patch(`${this.configConstant.apiStoreURL}/product/publish/${id},${updatedStatus}`, {});
    }

    getDropDownValues() {
        return this.http.get(`${this.configConstant.apiStoreURL}/AddNewProduct`);
    }

    getSubCategortLists( catId: number ) {
        return this.http.get(`${this.configConstant.apiStoreURL}/GetSubCategoryByCategoryId/${catId}`);
    }

    getProductDropDownModels( dropDownValues ) {
        const dpValues = {
            categoryLists: dropDownValues.categories.map( list => new ProductCategory().deserialize(list) ),
            productTypes: dropDownValues.productType.map( list => new ProductType().deserialize(list) ),
            brands: dropDownValues.brand.map( list => new ProductBrand().deserialize(list) ),
            productTags: dropDownValues.productTags.map( list => new ProductTag().deserialize(list) ),
            productLabels: dropDownValues.productLabel.map( list => new ProductLabel().deserialize(list) ),
            themes: dropDownValues.themes.map( list => new ProductTheme().deserialize(list) ),
            colorPalettes: dropDownValues.colorPalette.map( list => new ProductTheme().deserialize(list) ),
            productServiceType: dropDownValues.productServiceType.map( list => new ProductServiceType().deserialize(list) ),
            spaceType: dropDownValues.spaceType.map( list => new ProductServiceType().deserialize(list) ),
        };

        return dpValues;
    }

    getLinkedProduct(term: string = null, pCat: number = 0, pType: number = 0): Observable<ProductLinked[]> {
        if (term) {
            return this.http.get<ProductLinked[]>(`${this.configConstant.apiStoreURL}/searchproduct/${pCat}/${pType}/${term}`)
            .pipe(map(data => {
                return data['data'];
            }));
        } else {
            return of([]).pipe();
        }
    }

    getSpecificationList( productId: number, specId: number = 0 ) {
        let specUrl = `${this.configConstant.apiStoreURL}/specification/?pid=${productId}`;
        if (specId > 0) {
            specUrl = `${this.configConstant.apiStoreURL}/specification/?id=${specId}&pid=${productId}`;
        }

        return this.http.get<Specification[]>( specUrl )
        .pipe(map(data => {
            return data['data'];
        }));
    }

    addSpecification( formData ) {
        return this.http.post(`${this.configConstant.apiStoreURL}/specification/add`, formData )
            .pipe(map(data => {
                return data;
            }));
    }

    deleteSpecification( id: number ) {
        return this.http.delete(`${this.configConstant.apiStoreURL}/specification/delete/?id=${id}` );
    }

    getProductAttributes( productId: number ) {
        return this.http.get( `${this.configConstant.apiStoreURL}/attributes/get/?pid=${productId}` )
        .pipe(map(data => {
            return data;
        }));
    }

    getEditAttributesAndValues( productId: number, attrId: number ) {
        return this.http.get( `${this.configConstant.apiStoreURL}/getattributesbyid?id=${attrId}&pid=${productId}` )
        .pipe(map(data => {
            return data;
        }));
    }

    getAttributeList( productId: number, attrId: number = 0, sercheTerm: string = '' ): Observable<AttributeArray[]> {
        let Url = `${this.configConstant.apiStoreURL}/attributes/?pid=${productId}`;
        if ( sercheTerm ) {
            Url = Url + `&searchValue=${sercheTerm}`;

            return this.http.get<AttributeArray[]>( Url )
            .pipe(map(data => {
                return data['data'];
            }));
        } else if (attrId > 0) {
            Url = `${this.configConstant.apiStoreURL}/attributes/?id=${attrId}&pid=${productId}`;

            return this.http.get<AttributeArray[]>( Url )
            .pipe(map(data => {
                return data['data'];
            }));
        } else {
            return of([]).pipe();
        }
    }

    addProductAttribute( formData ) {
        return this.http.post(`${this.configConstant.apiStoreURL}/attributes/add`, formData )
            .pipe(map(data => {
                return data;
            }));
    }

    editProductAttribute( formData ) {
        return this.http.post(`${this.configConstant.apiStoreURL}/attributes/edit`, formData )
            .pipe(map(data => {
                return data;
            }));
    }

    deleteAttribute( id: number ) {
        return this.http.delete(`${this.configConstant.apiStoreURL}/attributes/delete/?id=${id}` );
    }

    deleteAttributeValue( id: number ) {
        return this.http.delete(`${this.configConstant.apiStoreURL}/deleteattributevaluebyid/?id=${id}` );
    }

    getProductVariationLists( productId: number, pageNo = 1, pageSize = 10 ) {
        const searchParams = new HttpParams()
            .set('pageNo', pageNo.toString())
            .set('pageSize', pageSize.toString());

        return this.http.get( `${this.configConstant.apiStoreURL}/GetManageInventorylist/${productId}`, {
            params: searchParams
        } )
        .pipe(map(data => {
            return data;
        }));

    }

    getProductVariationInventoryDetail(
        productId: number,
        variationId: number = 0,
        isVariation: boolean = false,
        pageNo = 1,
        pageSize = 10
    ) {
        const searchParams = new HttpParams()
            .set('pageNo', pageNo.toString())
            .set('pageSize', pageSize.toString());

        return this.http.get( `${this.configConstant.apiStoreURL}/ViewManageInventorylist/${variationId}/${productId}/${isVariation}`, {
            params: searchParams
        } )
        .pipe(map(data => {
            return data;
        }));

    }


    // Product Variation Tab
    getProductVariations( productId: number ) {
        return this.http.get( `${this.configConstant.apiStoreURL}/getvariationbyproductid/${productId}`)
        .pipe(map(data => {
            return data;
        }));
    }
    createProductVariations( productId: number, type: string ) {
        return this.http.get( `${this.configConstant.apiStoreURL}/createvariation/${productId}/${type}`)
        .pipe(map(data => {
            return data;
        }));
    }
    insertUpdateVariation( formData ) {
        return this.http.post(`${this.configConstant.apiStoreURL}/insertupdatevariation`, formData)
            .pipe(map(data => {
                return data;
            }));
    }
    deleteVariation( id: number ) {
        return this.http.delete(`${this.configConstant.apiStoreURL}/variation/delete/?id=${id}` );
    }
    // END

    removeImage( itemId, typeId ) {
        return this.http.get(`${this.configConstant.apiStoreURL}/deleteimage/${itemId}/${typeId}` );
    }
}


export interface Specification {
    id: number;
    value: string;
    specNameLangId: number;
}

export interface AttributeArray {
    id: number;
    value: string;
    swatcheTypeId?: number;
    swatcheType?: string; // 1 -> Label, 2 -> Image, 3 -> Color
}

export interface EditAttributeArray {
    productAttribute: string;
    productAttributeId: number;
    productId: number;
    swatcheTypeId: number;
    swatcheTypeLabel: string;
    productAttValueList: ProductAttributeValueList[];
}

export interface ProductAttributeValueList {
    id: number;
    swatcheTypeValue: string;
    productAttributeValue: string;
}