import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  IterableDiffers,
  ViewChild,
} from "@angular/core";
import { constEnv } from "common/constants.env";
// import { TitleService } from 'common/services/title.service';

import { DOCUMENT } from "@angular/common";

import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  AbstractControl,
} from "@angular/forms";
import { first } from "rxjs/operators";
import { AlertService } from "@full-fledged/alerts";
import { CustomValidator } from "common/_helpers/custom.validator";
import { IsLoadingService } from "@service-work/is-loading";
import { Observable } from "rxjs";
import { TitleService } from "common/services/title.service";

import { BsModalRef, ModalDirective } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";

import { StoreUserService } from "common/services/store/storeUser.service";
import { AuthenticationService } from "common/services/authentication.service";
import { LanguageService } from "common/services/language.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit, OnDestroy {
  env = constEnv;
  regiForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  countryCode: string;
  countryIsoCode: string;
  validMobileNo = true;

  accountForm: FormGroup;
  accountSubmitted = false;

  socialSubcription: Subscription;

  hasAccountEmail = false;

  socialValidMobileNo = true;
  iniSocailObj: any;
  socialCountryCode: string;
  socialCountryIsoCode: string;

  isLoading: Observable<boolean>;

  iniObj: any;

  public showPassword: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private titleService: TitleService,
    private isLoadingService: IsLoadingService,
    private authenticationService: AuthenticationService,
    private storeUserService: StoreUserService,
    private langService: LanguageService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Sign_Up");

    this.isLoading = this.isLoadingService.isLoading$();
    if (this.authenticationService.currentUserValue) {
      this.router.navigate([this.returnUrl]);
    }

    this.document.body.classList.add("page-register");

    this.createSignUpFrom();

    this.returnUrl =
      this.route.snapshot.queryParams.returnUrl || `${this.env.STORE_URL}`;
  }

  ngOnDestroy(): void {
    this.document.body.classList.remove("page-register");
  }

  createSignUpFrom() {
    const emailregex: RegExp =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.regiForm = this.formBuilder.group(
      {
        name: ["", Validators.required],
        mobileNo: ["", Validators.required],
        countryCode: this.countryCode,
        countryIsoCode: this.countryIsoCode,
        email: new FormControl(
          "",
          Validators.compose([
            Validators.required,
            Validators.email,
            Validators.pattern(emailregex),
          ])
        ),
        password: new FormControl(
          "",
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(16),
          ])
        ),
        terms: [false, Validators.required],
        // recaptchaReactive: new FormControl(null, Validators.required)
      },
      { validator: CustomValidator.checkTerms }
    );
  }

  get formData() {
    return this.regiForm.controls;
  }

  hasError(obj) {
    if (!obj && this.countryIsoCode === "ae") {
      const selectedNumber = this.iniObj.getNumber();
      this.validMobileNo =
        /^(?:\+971|00971|0)?(?:50|52|53|54|55|56|57|58|59|2|3|4|6|7|9)\d{7}$/gm.test(
          selectedNumber
        );
    } else {
      this.validMobileNo = obj;
    }
  }

  getErrorEmail() {
    return this.regiForm.get("email").hasError("required")
      ? ""
      : this.regiForm.get("email").hasError("pattern")
      ? "EmailInValid"
      : // ? this.titleService.getTranslatedString("EmailInValid")
        "";
  }

  getErrorPassword() {
    return this.regiForm.get("password").hasError("required")
      ? ""
      : this.regiForm.get("password").hasError("minlength")
      ? "PasswordFormate"
      : // ? this.titleService.getTranslatedString("PasswordFormate")
      this.regiForm.get("password").hasError("maxlength")
      ? "PasswordFormate"
      : // ? this.titleService.getTranslatedString("PasswordFormate")
        "";
  }

  onCountryChange(obj) {
    this.countryCode = obj.dialCode;
    this.countryIsoCode = obj.iso2;
  }

  telInputObject(obj) {
    this.iniObj = obj;
    const countryData = obj.getSelectedCountryData();
    this.countryCode = countryData.dialCode;
    this.countryIsoCode = countryData.iso2;
  }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    // this.alertService.clear();

    // stop here if form is invalid
    if (this.regiForm.invalid) {
      return;
    }

    if (!this.validMobileNo) {
      return;
    }

    this.loading = true;
    this.isLoadingService.add();

    this.regiForm.value.countryCode = "+" + this.countryCode;
    this.regiForm.value.countryIsoCode = this.countryIsoCode.toUpperCase();

    this.storeUserService
      .register(this.regiForm.value)
      .pipe(first())
      .subscribe(
        (data) => {
          this.alertService.success(data["message"]);
          this.submitted = false;
          this.regiForm.reset();
          this.loading = false;
          this.router.navigate([`${constEnv.STORE_URL}/login`]);
        },
        (err) => {
          const msg =
            typeof err.error.message !== "undefined" ? err.error.message : "";
          if (msg) {
            this.alertService.danger(msg);
          }
          this.loading = false;
          this.isLoadingService.remove();
        }
      );
  }
}
