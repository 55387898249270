import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { constEnv } from 'common/constants.env';
import { map } from 'rxjs/operators';
import { ProductList } from 'common/_models/site/product-list.modal';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LastminuteDealService {
    
    configConstant = constEnv;
    constructor(private http: HttpClient) {    
    }

    getAll( dataTablesParameters ) {        
        const pageNo = (dataTablesParameters.start * 1) / (dataTablesParameters.length * 1);

        const searchParams = new HttpParams()
            .set('pageNo', pageNo.toString())
            .set('pageSize', dataTablesParameters.length);

        return this.http.get(`${this.configConstant.apiStoreURL}/getlastminutedeals`, {
            params: searchParams
        })
            .pipe(map(data => {
                return data;
            }));
    }

    getLastMinuteDeal( Id ) {
        return this.http.get(`${this.configConstant.apiStoreURL}/getlastminutedeal/${Id}`)
            .pipe(map(data => {
                return data;
            }));
    }

    changeLMDealStatus( id: number, status: boolean ) {
        return this.http.get(`${this.configConstant.apiStoreURL}/updatelastminutedealstatus/${id}/${status}`)
            .pipe(map(data => {
                return data;
            }));
    }

    searchProductList( searchText: string ) {

        if ( searchText ) {
            const searchParams = new HttpParams()            
            .set('searchString', searchText);

            return this.http.get<ProductList[]>(`${this.configConstant.apiStoreURL}/getproductlist/`, {
                params: searchParams
            })
            .pipe(map(data => {
                return data['data'];
            }));
        } else {
            return of([]).pipe();
        }
    }

    getProductInfo( productId: number ) {
        return this.http.get(`${this.configConstant.apiStoreURL}/createlastminutedeal/${productId}`)
        .pipe(map(data => {
            return data;
        }));
    }

    createLastMinuteDeal( formData ) {
        return this.http.post(`${this.configConstant.apiStoreURL}/createlastminutedeal`, formData)
            .pipe(map(data => {
                return data;
            }));
    }

    deleteLastMinuteDeal( id: number ) {
        return this.http.delete(`${this.configConstant.apiStoreURL}/deletelastminutedeal/${id}` );
    }
}
