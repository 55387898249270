import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { CancelRequestRoutingModule } from "./cancel-request-routing.module";
import { TemplateDefaultModule } from "src/app/shared/template-default/template-default.module";
import { TranslateModule } from "@ngx-translate/core";
import { DataTablesModule } from "angular-datatables";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "common/modules/material.module";
import { PaginationModule } from "ngx-bootstrap";
import { ListComponent } from "./list/list.component";
import { PipeModule } from "common/_pipe/pipe.module";
import { DetailComponent } from './detail/detail.component';

@NgModule({
  declarations: [ListComponent, DetailComponent],
  imports: [
    CommonModule,
    CancelRequestRoutingModule,
    CommonModule,
    TemplateDefaultModule,
    TranslateModule,
    DataTablesModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MaterialModule,
    PaginationModule.forRoot(),
    PipeModule,
  ],
})
export class CancelRequestModule {}
