import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AuthPagesRoutingModule } from "./auth-pages-routing.module";
import { LoginComponent } from "./login/login.component";
import { AlertModule } from "ngx-alerts";
import { ComponentModule } from "common/_components/components.module";
import { RegisterComponent } from "./register/register.component";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { Ng2TelInputModule } from "ng2-tel-input";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";

import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "common/modules/material.module";

import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
} from "angularx-social-login";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ModalModule } from "ngx-bootstrap/modal";

import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { EmailVerifyComponent } from "./email-verify/email-verify.component";
import { constEnv } from "common/constants.env";
import { MyDirectiveModule } from "common/directives/my-directive.module";

const socialConfig = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(constEnv.googleAuthClientId),
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider(constEnv.facebookAppId),
  },
]);

export function provideConfig() {
  return socialConfig;
}

const appProviders = [
  BsModalRef,
  { provide: AuthServiceConfig, useClass: provideConfig },
];

@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    EmailVerifyComponent,
  ],
  imports: [
    CommonModule,
    AuthPagesRoutingModule,
    AlertModule.forRoot({ maxMessages: 1, timeout: 2500, position: "right" }),
    ComponentModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2TelInputModule,
    NgxIntlTelInputModule,
    TranslateModule.forRoot(),
    ModalModule.forRoot(),
    MaterialModule,
    SocialLoginModule,
    MyDirectiveModule,
  ],
  providers: appProviders,
})
export class AuthPagesModule {}
