import { Component, OnInit } from "@angular/core";
import { constEnv } from "common/constants.env";
import { TitleService } from "common/services/title.service";
import { AlertService } from "ngx-alerts";
import { ProductService } from "common/services/store/product.service";

import { Product } from "common/_models/store/product.model";
import { first } from "rxjs/operators";
import { IsLoadingService } from "@service-work/is-loading";
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { ConfirmDialogComponent } from "common/_components/confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { FormBuilder, FormGroup } from "@angular/forms";

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.css"],
})
export class ListComponent implements OnInit {
  config = constEnv;

  public productLists: Product[];
  hasProducts = false;

  pagination: Paging;
  itemPerPage = 12;

  searchForm: FormGroup;

  orderFiltersOptions = [
    { optionKey: "ProductName", optionValue: "ProductName" },
    { optionKey: "CategoryName", optionValue: "SubCategoryName" },
    { optionKey: "Status", optionValue: "Status" },
  ];

  searchParams: any = [];

  constructor(
    private alert: AlertService,
    private isLoadingService: IsLoadingService,
    private titleService: TitleService,
    private product: ProductService,
    private dialog: MatDialog,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.titleService.setTitle("STORE_Products");

    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }

    this.pagination = {
      totalCount: 0,
      totalRowsAfterFiltering: 0,
      pageSize: this.itemPerPage,
      currentPage: 0,
      totalPages: 0,
      previousPage: "",
      nextPage: "",
    };
    const paging = { page: 0, itemsPerPage: this.itemPerPage };

    this.loadProductLists(paging);
    this.createSearchForm();
  }

  loadProductLists(paging: PageChangedEvent) {
    this.isLoadingService.add();

    paging.page = paging.page > 0 ? paging.page - 1 : 0;
    paging.itemsPerPage = paging.itemsPerPage
      ? paging.itemsPerPage
      : this.itemPerPage;

    this.product
      .getAll(paging, this.searchParams)
      .pipe(first())
      .subscribe(
        (resp) => {
          if (resp["status"] === true) {
            this.productLists = resp["data"].map((list) =>
              new Product().deserialize(list)
            );
            this.pagination = JSON.parse(resp["Paging"]);
          } else {
            this.pagination.totalCount = 0;
            this.pagination.totalRowsAfterFiltering = 0;
            this.productLists = [];
            this.hasProducts = false;
          }

          if (this.productLists.length > 0) {
            this.hasProducts = true;
          }
          this.isLoadingService.remove();
        },
        (err) => {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          if (msg !== "") {
            // this.alert.danger(err.error.message);
            this.productLists = [];
            this.pagination.totalCount = 0;
            this.pagination.totalRowsAfterFiltering = 0;
            this.hasProducts = false;
          }
          this.isLoadingService.remove();
        }
      );
  }

  createSearchForm() {
    this.searchForm = this.fb.group({
      filterBy: [this.orderFiltersOptions[0].optionKey],
      filterText: [""],
    });
  }

  filterCHange() {
    this.searchForm.get("filterText").setValue("");
    this.searchParams = [];
  }

  resetSearchForm() {
    this.searchForm.reset();
    this.searchForm
      .get("filterBy")
      .setValue(this.orderFiltersOptions[0].optionKey);
    this.searchParams = [];

    const paging = { page: 0, itemsPerPage: this.itemPerPage };

    this.loadProductLists(paging);
  }

  delete(productId) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm",
        message: "DeleteProductConfirm",
      },
    });

    confirmDialog.afterClosed().subscribe((result) => {
      if (result === true) {
        if (productId > 0) {
          this.isLoadingService.add();
          this.product.delete(productId).subscribe(
            (data) => {
              if (data["status"] === true) {
                this.alert.success(data["message"]);
                const paging = { page: 0, itemsPerPage: this.itemPerPage };
                this.isLoadingService.remove();
                this.loadProductLists(paging);
              } else {
                this.alert.danger(data["message"]);
                this.isLoadingService.remove();
              }
            },
            (err) => {
              this.alert.danger(err.error.message);
              // this.modalRef.hide();
              this.isLoadingService.remove();
            }
          );
        }
      }
    });
  }

  searchSubmit() {
    if (this.searchForm.invalid) {
      return;
    }

    this.searchParams = {
      searchBy: this.searchForm.value.filterBy,
      searchValue: this.searchForm.value.filterText,
    };
    const paging = { page: 0, itemsPerPage: this.itemPerPage };

    this.loadProductLists(paging);
  }

  updateStatus(productId, status) {
    if (productId > 0) {
      this.isLoadingService.add();
      this.product.changeStatus(productId, status).subscribe(
        (data) => {
          if (data["status"] === true) {
            this.alert.success(data["message"]);
            const paging = { page: 0, itemsPerPage: this.itemPerPage };
            this.isLoadingService.remove();
            this.loadProductLists(paging);
          } else {
            this.alert.danger(data["message"]);
            this.isLoadingService.remove();
          }
        },
        (err) => {
          this.alert.danger(err.error.message);
          this.isLoadingService.remove();
        }
      );
      this.isLoadingService.remove();
    }
  }

  publishProduct(productId, status, isPublish) {
    if (productId > 0) {
      if (!isPublish) {
        this.isLoadingService.add();
        this.product.publishProduct(productId, status).subscribe(
          (data) => {
            if (data["status"] === true) {
              this.alert.success(data["message"]);
              const paging = { page: 0, itemsPerPage: this.itemPerPage };
              this.isLoadingService.remove();
              this.loadProductLists(paging);
            } else {
              this.alert.danger(data["message"]);
              this.isLoadingService.remove();
            }
          },
          (err) => {
            this.alert.danger(err.error.message);
            this.isLoadingService.remove();
          }
        );
        this.isLoadingService.remove();
      }
    }
  }
  showPublishButton(productList) {
    if (productList.isPublish) {
      return false;
    }
    if (
      productList.productType === 2 &&
      productList.variationProductCount === 0
    ) {
      return false;
    }
    return true;
  }
}

export interface Paging {
  totalCount: number;
  totalRowsAfterFiltering: number;
  pageSize: number;
  currentPage: number;
  totalPages: number;
  previousPage: string;
  nextPage: string;
}
