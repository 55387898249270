import { Injectable, EventEmitter } from '@angular/core';    
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({ providedIn: 'root' })
export class EventEmitterService {

    invokeSiteHeaderFunction = new EventEmitter();    
    subsVar: Subscription; 

    constructor() { }

    // getCartCount() {
    //     this.invokeSiteHeaderFunction.emit();
    // }

}
