export class Wishlist {

    productId: number;
    productName: string;
    productPrice: number;
    productImage: string;
    currencyCode: string;
    hasOffer: boolean;
    whishlistId: number;
    productVaritionId: number;
    jobName: string;
    customerName: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
