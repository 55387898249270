import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { OrdersComponent } from "./orders/orders.component";
import { OrdersRoutingModule } from "./orders-routing.module";
import { TranslateModule } from "@ngx-translate/core";
import { DataTablesModule } from "angular-datatables";
import { FormsModule } from "@angular/forms";
import { MaterialModule } from "common/modules/material.module";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TemplateDefaultModule } from "../../../shared/template-default/template-default.module";
import { DetailComponent } from "./detail/detail.component";
import { PipeModule } from "common/_pipe/pipe.module";
import { ModalModule, PopoverModule } from "ngx-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
@NgModule({
  declarations: [OrdersComponent, DetailComponent],
  imports: [
    CommonModule,
    OrdersRoutingModule,
    TranslateModule,
    DataTablesModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RouterModule,
    BsDatepickerModule,
    TemplateDefaultModule,
    PipeModule,
    ModalModule.forRoot(),
    NgSelectModule,
    PopoverModule.forRoot(),
  ],
})
export class OrdersModule {}
