import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { constEnv } from 'common/constants.env';
import { AuthGuard } from 'common/_helpers/auth.guard';

import { ListsComponent } from './lists/lists.component';
// import { AddComponent } from './add/add.component';
// import { EditComponent } from './edit/edit.component';
import { ActionsComponent } from './actions/actions.component';
import { DocumentsComponent } from './documents/documents.component';

const STORE_ENDPOINT = `${constEnv.storeEndPoint}/driver`;

const routes: Routes = [

  {
    path: `${STORE_ENDPOINT}`,
    children: [
       { path: 'lists' , component: ListsComponent },
       {
         path: ':id/documents', pathMatch: 'full', component: DocumentsComponent
       },
       {
          path: ':action',
          children: [
             {path: ':id', component: ActionsComponent},
             {path: '', component: ActionsComponent}
          ]
       },
       {path: '', redirectTo: `lists`, pathMatch: 'full' },
    ],
    canActivate: [AuthGuard]
 },

//   {
//     path: `${STORE_ENDPOINT}` ,
//     children: [``
//       { path: 'lists' , component: ListsComponent },
//       { path: 'add' , component: ActionsComponent },
//       {
//         path: 'edit',
//         children: [
//           { path: ':id' , component: ActionsComponent },
//           { path: '' , component: ActionsComponent },
//         ]
//       },
//       {
//         path: ':id',
//         children: [
//           { path: 'documents' , component: DocumentsComponent },
//           { path: '' , component: DocumentsComponent },
//         ]
//       },
//       { path: '' , redirectTo: `dashboard`, pathMatch: 'full' },
//     ],
//     canActivate: [AuthGuard]
//  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DriversRoutingModule { }
