export class Vehicle {

    driverId: number;
    driverName: string;
    status: boolean;
    storeName: string;
    vehicleColor: string;
    vehicleId: number;
    vehicleNumber: string;
    vehicleTypeId: number;
    vehicleTypeName: string;
    year: number;
    makeId: number;
    modelId: number;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}

export interface VehicleType {
    vehicleType: string;
    vehicleTypeId: number;
}

export interface VehicleMake {
    makeId: number;
    makeName: string;
}

export interface vehicleModel {
    modelId: number;
    modelName: string;
}
