import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  AbstractControl,
} from "@angular/forms";
import { constEnv } from "common/constants.env";
import { AlertService } from "@full-fledged/alerts";
import { LoaderService } from "common/services/loader.service";
import { SettingsService } from "common/services/store/settings.service";
import { TitleService } from "common/services/title.service";

@Component({
  selector: "app-time",
  templateUrl: "./time.component.html",
  styleUrls: ["./time.component.css"],
})
export class TimeComponent implements OnInit {
  timeSettingForm: FormGroup;
  submitted = false;
  valid = true;

  config = constEnv;

  loading = false;

  constructor(
    private alert: AlertService,
    private ls: LoaderService,
    private fb: FormBuilder,
    private settings: SettingsService,
    private titleService: TitleService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Time Setting");
    this.makeForm();

    this.getTimeSlot();
  }

  private makeForm() {
    this.timeSettingForm = this.fb.group({
      weekDaySlot1StartTime: ["", Validators.required],
      weekDaySlot1EndTime: ["", Validators.required],
      weekDaySlot2StartTime: [""],
      weekDaySlot2EndTime: [""],
      weekEndSlot1StartTime: ["", Validators.required],
      weekEndSlot1EndTime: ["", Validators.required],
      weekEndSlot2StartTime: [""],
      weekEndSlot2EndTime: [""],
    });
  }

  setFormValues(fieldValues) {
    const myTimeValues = [];
    fieldValues.forEach((fieldValue) => {
      const weekType = fieldValue.weekId === 1 ? "weekDay" : "weekEnd";
      const slots = fieldValue.weekSlot;
      slots.forEach((slot) => {
        const startTimeKey = `${weekType}Slot${slot.slot}StartTime`;
        const endTimeKey = `${weekType}Slot${slot.slot}EndTime`;

        const startTime = slot.startTime;
        const startParts = startTime.split(":");
        const endTime = slot.endTime;
        const endParts = endTime.split(":");

        if (startParts[0] !== "" && startParts[1] !== "") {
          const sTime = new Date();
          sTime.setHours(parseFloat(startParts[0]));
          sTime.setMinutes(parseFloat(startParts[1]));
          myTimeValues[startTimeKey] = sTime;
        } else {
          myTimeValues[startTimeKey] = "";
        }

        if (endParts[0] !== "" && endParts[1] !== "") {
          const eTime = new Date();
          eTime.setHours(parseFloat(endParts[0]));
          eTime.setMinutes(parseFloat(endParts[1]));
          myTimeValues[endTimeKey] = eTime;
        } else {
          myTimeValues[endTimeKey] = "";
        }
      });
    });

    this.timeSettingForm
      .get("weekDaySlot1StartTime")
      .setValue(myTimeValues["weekDaySlot1StartTime"]);
    this.timeSettingForm
      .get("weekDaySlot1EndTime")
      .setValue(myTimeValues["weekDaySlot1EndTime"]);
    this.timeSettingForm
      .get("weekDaySlot2StartTime")
      .setValue(myTimeValues["weekDaySlot2StartTime"]);
    this.timeSettingForm
      .get("weekDaySlot2EndTime")
      .setValue(myTimeValues["weekDaySlot2EndTime"]);

    this.timeSettingForm
      .get("weekEndSlot1StartTime")
      .setValue(myTimeValues["weekEndSlot1StartTime"]);
    this.timeSettingForm
      .get("weekEndSlot1EndTime")
      .setValue(myTimeValues["weekEndSlot1EndTime"]);
    this.timeSettingForm
      .get("weekEndSlot2StartTime")
      .setValue(myTimeValues["weekEndSlot2StartTime"]);
    this.timeSettingForm
      .get("weekEndSlot2EndTime")
      .setValue(myTimeValues["weekEndSlot2EndTime"]);
  }

  getTimeSlot() {
    this.ls.showLoader();
    this.settings.getTimeData().subscribe(
      (data) => {
        if (data["status"] === true) {
          this.setFormValues(data["data"]);
        }
        this.ls.hideLoader();
      },
      (err) => {
        // console.log(err);
        this.ls.hideLoader();
      }
    );
  }

  isValid(event: boolean) {
    this.valid = event;
  }

  onSubmit() {
    this.submitted = true;
    // this.alert.clear();

    if (this.timeSettingForm.invalid) {
      this.alert.danger("Please fill required fields.");
      return;
    }

    const fieldValues = this.timeSettingForm.value;

    const fValues = [];
    for (const fieldValue in fieldValues) {
      if (fieldValue !== "") {
        const fDate = fieldValues[fieldValue];

        if (typeof fDate !== "undefined" && fDate !== null && fDate !== "") {
          const h = (fDate.getHours() < 10 ? "0" : "") + fDate.getHours();
          const m = (fDate.getMinutes() < 10 ? "0" : "") + fDate.getMinutes();
          fValues[fieldValue] = `${h}:${m}`;
        } else {
          fValues[fieldValue] = "";
        }
      }
    }

    const formData = [
      {
        weekId: 1,
        weekSlot: [
          {
            startTime: fValues["weekDaySlot1StartTime"],
            endTime: fValues["weekDaySlot1EndTime"],
            slot: 1,
          },
          {
            startTime: fValues["weekDaySlot2StartTime"],
            endTime: fValues["weekDaySlot2EndTime"],
            slot: 2,
          },
        ],
      },
      {
        weekId: 2,
        weekSlot: [
          {
            startTime: fValues["weekEndSlot1StartTime"],
            endTime: fValues["weekEndSlot1EndTime"],
            slot: 1,
          },
          {
            startTime: fValues["weekEndSlot2StartTime"],
            endTime: fValues["weekEndSlot2EndTime"],
            slot: 2,
          },
        ],
      },
    ];

    this.ls.showLoader();
    this.loading = true;
    this.settings.updateTimeData(formData).subscribe(
      (data) => {
        if (data["status"] === true) {
          this.alert.success(data["message"]);
          this.ls.hideLoader();
        } else {
          this.alert.danger(data["message"]);
          this.ls.hideLoader();
        }
        this.loading = false;
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        this.alert.danger(msg);
        this.loading = false;
        this.ls.hideLoader();
      }
    );
  }
}
