import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { PagesRoutingModule } from "./pages-routing.module";
import { AlertModule } from "@full-fledged/alerts";
import { ComponentModule } from "common/_components/components.module";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { Ng2TelInputModule } from "ng2-tel-input";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { ChartsModule } from "ng2-charts";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "common/modules/material.module";
import { NgSelectModule } from "@ng-select/ng-select";

import { DashboardComponent } from "./dashboard/dashboard.component";
import { TemplateDefaultModule } from "../shared/template-default/template-default.module";
import { StarRatingModule } from "angular-star-rating";

import { ProductsModule } from "./products/products.module";
import { OrdersModule } from "./orders/orders.module";
import { DriversModule } from "./drivers/drivers.module";
import { ModalModule } from "ngx-bootstrap/modal";
import { EarningsModule } from "./earnings/earnings.module";
import { SettingsModule } from "./settings/settings.module";
import { WishlistModule } from "./wishlist/wishlist.module";
import { PromoCodeModule } from "./promo-code/promo-code.module";
import { ShippingRateModule } from "./shipping-rate/shipping-rate.module";
import { OffersModule } from "./offers/offers.module";
import { NotificationModule } from "./notification/notification.module";
import { HelpSupportModule } from "./help-support/help-support.module";
import { DataTablesModule } from "angular-datatables";

import { ReturnRequestModule } from "./return-request/return-request.module";
import { AerialViewComponent } from "./aerial-view/aerial-view.component";
import { FeedbackComponent } from "./feedback/feedback.component";
import { PrefrredDeliveryCompanyComponent } from "./prefrred-delivery-company/prefrred-delivery-company.component";
import { StoreVehicleModule } from "./store-vehicle/store-vehicle.module";
import { PotentialListModule } from "./potential-list/potential-list.module";
import { DeliveryZoneComponent } from "./delivery-zone/delivery-zone.component";
import { CancelRequestModule } from "./cancel-request/cancel-request.module";
import { NgxGalleryModule } from "ngx-gallery-9";
import { PaginationModule } from "ngx-bootstrap/pagination";

@NgModule({
  declarations: [
    DashboardComponent,
    AerialViewComponent,
    FeedbackComponent,
    PrefrredDeliveryCompanyComponent,
    DeliveryZoneComponent,
  ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    AlertModule.forRoot({
      maxMessages: 1,
      timeout: 2500,
      positionX: "right",
      positionY: "top",
    }),
    ComponentModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2TelInputModule,
    NgxIntlTelInputModule,
    TranslateModule.forRoot(),
    MaterialModule,
    TemplateDefaultModule,
    ProductsModule,
    OrdersModule,
    PotentialListModule,
    DriversModule,
    EarningsModule,
    SettingsModule,
    WishlistModule,
    PromoCodeModule,
    ShippingRateModule,
    StoreVehicleModule,
    OffersModule,
    ChartsModule,
    ReturnRequestModule,
    NotificationModule,
    DataTablesModule,
    HelpSupportModule,
    StarRatingModule.forRoot(),
    NgSelectModule,
    ModalModule.forRoot(),
    CancelRequestModule,
    NgxGalleryModule,
    PaginationModule.forRoot(),
  ],
})
export class PagesModule {}
