export class ReturnRequest {
  id: number;
  ticketId: string;
  attachment: string;
  createdDate: string;
  customerName: string;
  customerEmail: string;
  customerPhone: string;
  statusName: string;
  statusColor: string;
  backgroundColor: string;
  itemReturnReason: string;
  description: string;
  orderTotal: string;
  productName: string;
  productPrice: string;
  productImage: string;
  totalItem: number;
  isAccepted: boolean;
  isClosed: boolean;
  storeName: string;
  orderNo: string;
  orderId: number;
  orderBaseDetailId: number;
  returnAmount: number;
  currencyCode: string;
  reason: string;
  pickupDate: string;
  customerFeedback: string;
  isReplaced: boolean;
  driverName: string;
  driverEmail: string;
  driverPhone: string;
  driverDetails: any[];
  isDriverAssigned: boolean;
  deliveryCompnyName: string;
  storeId: number;
  productId: number;
  productVariationId: number;
  isInProgress: boolean;
  customerId: number;
  customerLangId: number;
  storeLangId: number;
  storeEmail: string;
  statusId: number;
  returnStatusId: number;
  returnStatusName: string;
  deliveryCompnyId: number;
  driverId: number;
  deliveryHandleId: number;
  isPickupByStore: boolean;
  pickupCharge: number;
  returnItem: number;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
