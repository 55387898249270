export class ProductList {
    brandName: string;
    currencyCode: string;
    finalPrice: number;
    hasIndividualOffer: boolean;
    hasOffer: boolean;
    hasRating: boolean;
    img_h: number;
    img_w: number;
    offerDiscount: number;
    offerType: string;
    productId: number;
    productImage: string;
    productName: string;
    productPrice: number;
    productRating: number;
    storeName: string;
    totalRatings: number;
    buyNow: boolean;
    isVariableProduct: boolean;
    offerEndsOn: string;
    offerEndsOnDt: Date;
    variationId: number;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
