import { AbstractControl, ValidatorFn } from "@angular/forms";
export class CustomValidator {
  static MatchPassword(control: AbstractControl) {
    const password = control.get("newPassword").value;
    const confirmPassword = control.get("confirmPassword").value;
    if (password !== confirmPassword) {
      control.get("confirmPassword").setErrors({ confirmPassword: true });
    } else {
      return null;
    }
  }

  static CheckTargetAmount(control: AbstractControl) {
    const offerDiscount = parseFloat(control.get("discount").value);
    const targetAmount = parseFloat(control.get("targetAmount").value);
    const offerType = control.get("discountUnit").value;

    if (offerType === "Flat") {
      if (targetAmount <= offerDiscount) {
        control.get("targetAmount").setErrors({ targetAmountLess: true });
      } else {
        return null;
      }
    } else {
      if (targetAmount >= 0) {
        control.get("targetAmount").setErrors(null);
      }
      return null;
    }
  }

  static checkTerms(control: AbstractControl) {
    const checkterms = control.get("terms").value;

    if (!checkterms) {
      control.get("terms").setErrors({ checkTerms: true });
    } else {
      return null;
    }
  }

  static checkDiscount(control: AbstractControl) {
    const discount = control.get("discount").value;
    const discountUnit = control.get("discountUnit").value;

    if (discountUnit === "Per") {
      if (discount <= 0 || discount >= 99) {
        control.get("discount").setErrors({ discountPer: true });
      }
    } else if (discountUnit === "Flat") {
      if (discount <= 0) {
        control.get("discount").setErrors({ discountFlat: true });
      }
    } else {
      return null;
    }
  }

  static checkPotentialListDiscount(control: AbstractControl) {
    const discount = control.get("discount").value;
    const discountUnit = control.get("discountUnit").value;
    const productPrice = control.get("productPrice").value;

    if (discountUnit === "Per") {
      if (discount <= 0 || discount >= 99) {
        control.get("discount").setErrors({ discountPer: true });
      } else {
        return null;
      }
    } else if (discountUnit === "Flat") {
      if (discount <= 0) {
        control.get("discount").setErrors({ discountFlat: true });
      } else if (discount > productPrice - 1) {
        control.get("discount").setErrors({ discountFlatMax: true });
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  static OnlyZeroNotAllowed(fieldName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const fieldValue = control.get(fieldName).value;
      if (fieldValue !== "") {
        if (fieldValue > 0) {
          return null;
        } else {
          control.get(fieldName).setErrors({ zeroError: true });
          return { zeroError: { value: control.value } };
        }
      }
      return null;
    };
  }

  static checkMaxValueFromMinValue(
    minfieldName: string,
    maxFiledName: string
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let minFieldValue = control.get(minfieldName).value;
      let maxFieldValue = control.get(maxFiledName).value;

      if (minFieldValue !== "" && maxFieldValue !== "") {
        minFieldValue = parseFloat(minFieldValue);
        maxFieldValue = parseFloat(maxFieldValue);
        if (minFieldValue > 0 && maxFieldValue > 0) {
          if (minFieldValue > maxFieldValue) {
            control.get(maxFiledName).setErrors({ maxValueError: true });
            return { maxValueError: { value: control.value } };
          } else {
            control.get(maxFiledName).setErrors(null);
            return null;
          }
        } else {
          // control.get(maxFiledName).setErrors(null);
          return null;
        }
      }

      return null;
    };
  }
}
