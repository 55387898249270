import { Injectable, Renderer2, RendererFactory2, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { constEnv } from '../constants.env';
import { pipe } from 'rxjs';
import { async } from '@angular/core/testing';
import { LoaderService } from './loader.service';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  languages = [];
  defaultLanguage = 'en-US';
  defaultLanguageInfo = [];
  public langLoaded = false;
  constantConfig;

  private renderer: Renderer2;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    public translate: TranslateService,
    rendererFactory: RendererFactory2,
    private ls: LoaderService
  ) {
    this.constantConfig = constEnv;
    this.renderer = rendererFactory.createRenderer(null, null);
    if (this.getCurrentLanguageCode()) {
      this.changeLanguageByLangCode( this.getCurrentLanguageCode() );
    } else {
      this.setDefaultLanguage();
    }
  }

  _setLanguage(lang) {
    localStorage.setItem('locale', lang);
    this.translate.use(lang);
  }

  getCurrentLanguageCode() {
    const lang = localStorage.getItem('locale');

    return (lang) ? lang : '';
  }

  getCurrentLanguage() {
    const lang = localStorage.getItem('locale');

    return (lang) ? lang : this.defaultLanguage;
  }

  getCurrentLanguageId() {
    const lang = this.getDefaultLanguage();

    return (typeof lang !== 'undefined' && lang !== null) ? lang.id || 1 : 1;
  }

  getCurrentLanguageDrirection() {
    const lang = this.getDefaultLanguage();

    return (typeof lang !== 'undefined' && lang !== null) ? lang.lang_style || 'ltl' : 'ltl';
  }

  getDefaultLanguage() {
    const language = localStorage.getItem('defaultLang');

    return (typeof language !== 'undefined') ? JSON.parse(language) : [];
  }

  setLanguageTranslation(translations) {
    localStorage.setItem('translations', JSON.stringify(translations));
  }

  getLanguageTranslations() {
    const translations = localStorage.getItem('translations');

    return JSON.parse(translations);
  }

  setAvailableLanguages(languages) {
    localStorage.setItem('available_languages', JSON.stringify(languages));
  }

  getAvailableLanguages() {
    const languages = localStorage.getItem('available_languages');

    return JSON.parse(languages);
  }

  getLanguageDetailById(langId) {
    let returnVal = null;
    this.getAvailableLanguages().forEach(singleLanguage => {
      if (langId === singleLanguage['id']) {
        returnVal = singleLanguage;
      }
    });

    return returnVal;
  }

  changeLanguage(changeLangCode = '') {
    this.langLoaded = true;
    this.getAllLanguages().pipe(first())
      .subscribe(
        data => {
          const apiLangs = (typeof data['data'] !== 'undefined') ? data['data'] : [];
          this.setAvailableLanguages(apiLangs);
          apiLangs.map(lang => {
            this.languages.push(lang.lang_code);
            if (changeLangCode !== '') {
              if (changeLangCode === lang.lang_code) {
                this._setLanguage(lang.lang_code);
                localStorage.setItem('defaultLang', JSON.stringify(lang));

                this.getLanguageTranslation(this.getCurrentLanguage()).pipe(first())
                  .subscribe(
                    translationsJSON => {
                      this.setLanguageTranslation(translationsJSON);
                      this.langLoaded = false;
                    }
                  );

              }
            } else {
              if (lang.default_lang === '1') {
                this.defaultLanguage = lang.lang_code;
                this._setLanguage(lang.lang_code);
                localStorage.setItem('defaultLang', JSON.stringify(lang));


                this.getLanguageTranslation(this.getCurrentLanguage()).pipe(first())
                  .subscribe(
                    translationsJSON => {
                      this.setLanguageTranslation(translationsJSON);
                      this.langLoaded = false;
                    }
                  );

              }
            }
          }
          );
        }
      );
  }

  setDefaultLanguage() {
    this.langLoaded = true;
    if (!this.getDefaultLanguage()) {
      this.ls.showLoader();
      this.getAllLanguages().pipe(first())
        .subscribe(
          data => {
            const apiLangs = (typeof data['data'] !== 'undefined') ? data['data'] : [];
            this.setAvailableLanguages(apiLangs);
            apiLangs.map(lang => {
              this.languages.push(lang.lang_code);
              if (lang.default_lang === '1') {
                this.defaultLanguage = lang.lang_code;
                this._setLanguage(lang.lang_code);
                localStorage.setItem('defaultLang', JSON.stringify(lang));

                this.getLanguageTranslation(this.getCurrentLanguage()).pipe(first())
                  .subscribe(
                    translationsJSON => {
                      this.setLanguageTranslation(translationsJSON);

                      this.translate.setTranslation(this.getCurrentLanguage(), this.getLanguageTranslations());

                      const currentLanguage = this.getDefaultLanguage();

                      const langStyle = (currentLanguage !== null) ? currentLanguage.lang_style : 'ltl';

                      this.renderer.setAttribute(document.querySelector('html'), 'lang', this.translate.currentLang);
                      this.renderer.setAttribute(document.querySelector('html'), 'dir', langStyle === 'rtl' ? 'rtl' : 'ltl');

                      if (langStyle === 'rtl') {
                        this.loadStyle('rtl.css', 'rtl-style');
                      }

                      this.langLoaded = false;
                      this.ls.hideLoader();
                      // location.reload(true);
                    }
                  );

              }
            }
            );
          }
        );
    }
  }

  getLanguageTranslation(language = 'en-US') {
    return this.http.get(`${this.constantConfig.apiURL}/getResource`, { params: { langcode : language } } )
      .pipe(map(translations => {
        const dataString = (typeof translations['data'] !== 'undefined' ) ? translations['data'] : [];
        const translationsStrings = (typeof dataString !== 'undefined') ? dataString : [];

        return translationsStrings;
      }));

  }

  getAllLanguages() {
    return this.http.get(`${this.constantConfig.apiURL}/getAllLanguages`)
      .pipe(map(languages => {
        return languages;
      }));

  }


  changeLanguageByLangCode(langCode) {
    this.getLanguageTranslation(langCode).pipe(first())
      .subscribe(
        translationsJSON => {
          this.setLanguageTranslation(translationsJSON);
        }
      );
  }

  loadStyle(styleName: string, styleId: string) {
    const head = this.document.getElementsByTagName('head')[0];

    const themeLink = this.document.getElementById(
      styleId
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement('link');
      style.id = styleId;
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }

}
