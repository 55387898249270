import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AddressRoutingModule } from "./address-routing.module";
import { AddressComponent } from "./address/address.component";
import { MaterialModule } from "common/modules/material.module";
import { AddAddressComponent } from "./add-address/add-address.component";
import { TemplateDefaultModule } from "../../../shared/template-default/template-default.module";
import { TranslateModule } from "@ngx-translate/core";
import { ReactiveFormsModule } from "@angular/forms";
import { AgmCoreModule } from "@agm/core";
import { constEnv } from "common/constants.env";
import { ComponentModule } from "common/_components/components.module";
import { NgSelectModule } from "@ng-select/ng-select";

@NgModule({
  declarations: [AddressComponent, AddAddressComponent],
  imports: [
    CommonModule,
    AddressRoutingModule,
    MaterialModule,
    TemplateDefaultModule,
    TranslateModule,
    ReactiveFormsModule,
    // AgmCoreModule.forRoot({
    //   apiKey: constEnv.googleMapAPIKey,
    //   libraries: ["places"],
    // }),
    ComponentModule,
    NgSelectModule,
  ],
})
export class AddressModule {}
