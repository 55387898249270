export class ProductVariation {
    id: number;
    name: string;
    sku: string;
    productId: number;
    stockQuantity: number;
    overriddenPrice: number;
    overriddenOffer: number;
    filename: string;
    allowOutOfStockOrders: boolean;
    attributeList: AttributeList[];
    attValueList: AttributeValueList[];

    topViewImage: string;
    topViewImageId: number;

    augmentedViewImage: string;
    augmentedViewImageId: number;
    
    modelJsonViewImage: string;
    modelJsonViewImageId: number;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}

export interface AttributeList {
    id: number;
    attributeName: string;
    selectedId: number;
    attributeValueList: AttributeValueList[];
}

export interface AttributeValueList {
    attributeValueId: number;
    attributeValueName: string;
}
