import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { LanguageService } from "common/services/language.service";
import { LoaderService } from "common/services/loader.service";
import { GeneralService } from "common/services/general.service";
import { CustomerService } from "common/services/customers/customer.service";
import { AlertService } from "ngx-alerts";
import { CustomerUser } from "common/_models/customers/customerUser";
import { IsLoadingService } from "@service-work/is-loading";
import { TitleService } from "common/services/title.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {
  profileForm: FormGroup;
  iniObj: any;
  countryCode: string;
  countryIsoCode: string;
  validMobileNo = true;
  isEmailAvailable = false;
  languages: any;
  currencies: any;
  submitted: boolean;
  loading = false;

  constructor(
    private fb: FormBuilder,
    private lang: LanguageService,
    private ls: LoaderService,
    private gs: GeneralService,
    private customer: CustomerService,
    private alert: AlertService,
    private isLoadingService: IsLoadingService,
    private titleService: TitleService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Profile");
    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }
    this.makeForm();
    this.getAllLanguage();
    this.getAllCurrencies();
    this.getCustomerData();
  }
  getCustomerData() {
    this.ls.showLoader();
    this.customer.getProfile().subscribe(
      (data) => {
        /*if (data['status'] === true) {*/

        const customerData = new CustomerUser().deserialize(data);

        this.setFormValue(customerData);
        this.ls.hideLoader();
        /*} else {*/
        // this.alert.error(data['message']);
        //this.ls.hideLoader();
        /*}*/
      },
      (err) => {
        // this.alert.error(err.error.message);
        this.ls.hideLoader();
      }
    );
  }
  private makeForm() {
    this.profileForm = this.fb.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      mobileNo: ["", Validators.required],
      email: ["", Validators.required],
      languageId: [null, Validators.required],
      CurrencyId: [null, Validators.required],
    });
  }
  onPhoneCodeChange(obj) {
    this.countryCode = obj.dialCode;
    this.countryIsoCode = obj.iso2;
  }
  telInputObject(obj) {
    this.iniObj = obj;
    const countryData = obj.getSelectedCountryData();
    this.countryCode = countryData.dialCode;
    this.countryIsoCode = countryData.iso2;
  }
  private getAllLanguage() {
    this.ls.showLoader();
    this.lang.getAllLanguages().subscribe((data) => {
      if (data["status"] === true) {
        this.languages = data["data"];
      }
      this.ls.hideLoader();
    });
  }
  private getAllCurrencies() {
    this.ls.showLoader();
    this.gs.getAllCurrencies().subscribe((data) => {
      if (data["status"] === true) {
        this.currencies = data["data"];
        /*const formData = this.customerService.currentUserProfile;
          this.frmProfile.get('CurrencyId').setValue( formData.CurrencyId );*/
        this.ls.hideLoader();
      }
    });
  }
  setFormValue(customerData: CustomerUser) {
    if (customerData.phone_code !== null) {
      this.iniObj.setNumber(customerData.phone_code);
    }
    this.isEmailAvailable = customerData.email !== "" ? true : false;
    this.profileForm.get("firstName").setValue(customerData.first_name);
    this.profileForm.get("lastName").setValue(customerData.last_name);
    this.profileForm.get("mobileNo").setValue(customerData.phone);
    this.profileForm.get("email").setValue(customerData.email);
    this.profileForm
      .get("languageId")
      .setValue(customerData.languageId.toString());
    this.profileForm
      .get("CurrencyId")
      .setValue(customerData.currencyId.toString());
  }
  get f() {
    return this.profileForm.controls;
  }
  hasError(obj) {
    if (!obj && this.countryIsoCode === "ae") {
      const selectedNumber = this.iniObj.getNumber();
      // if (regex.test(selectedNumber)) {
      //   this.validMobileNo = true;
      // } else {
      //   this.validMobileNo = false;
      // }
      this.validMobileNo =
        /^(?:\+971|00971|0)?(?:50|52|53|54|55|56|57|58|59|2|3|4|6|7|9)\d{7}$/gm.test(
          selectedNumber
        );
    } else {
      this.validMobileNo = obj;
    }
  }
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.profileForm.invalid) {
      return;
    }
    const LanguageId = this.profileForm.value.languageId;
    const formData = {
      firstName: this.profileForm.value.firstName,
      lastName: this.profileForm.value.lastName,
      phone: this.profileForm.value.mobileNo,
      phoneCode: this.countryCode,
      languageId: LanguageId,
      currencyId: this.profileForm.value.CurrencyId,
      regionCode: this.countryIsoCode,
    };

    this.ls.showLoader();
    this.loading = true;
    this.customer.updateProfile(formData).subscribe(
      (data) => {
        if (data["status"] === true) {
          this.alert.success(data["message"]);

          const defaultLang = this.lang.getDefaultLanguage();
          if (defaultLang.id !== LanguageId.toString()) {
            const langDetail = this.lang.getLanguageDetailById(LanguageId);
            this.lang.changeLanguage(langDetail.lang_code);
          }

          this.customer.getProfile().subscribe(
            (data) => {
              setTimeout(() => {
                window.location.href = window.location.href;
              }, 2000);
            },
            (err) => {
              this.ls.hideLoader();
            }
          );
        } else {
          this.alert.danger(data["message"]);
          this.ls.hideLoader();
        }
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        this.alert.danger(msg);
        this.loading = false;
        this.ls.hideLoader();
      }
    );
  }

  validForm(): boolean {
    if (!this.validMobileNo) {
      return false;
    }
    if (this.profileForm.invalid) {
      return false;
    }
    return true;
  }
}
