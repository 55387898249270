import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { constEnv } from "common/constants.env";
import { map } from "rxjs/operators";

import { AuthenticationService } from "common/services/authentication.service";

@Injectable({ providedIn: "root" })
export class DesignerService {
  currentUserProfile;
  configConstant = constEnv;
  apiURL = this.configConstant.apiDesignerURL;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {
    this.currentUserProfile =
      this.authenticationService.currentUserProfileValue;
  }

  forgotPassword(email) {
    const requestData = new HttpParams().set("email", email);

    return this.http
      .post(`${this.apiURL}/forgotpassword`, requestData, {
        params: requestData,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  resetPassword(accessToken, newPassword) {
    const requestHeader = {
      "Content-Type": "application/json",
    };

    // const requestData = new HttpParams()
    //   .set("AccessToken", accessToken)
    //   .set("NewPassword", newPassword);
    const requestData = {
      accessToken: accessToken,
      newPassword: newPassword,
    };

    return this.http
      .post(`${this.apiURL}/resetpassword`, requestData, {
        headers: requestHeader,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
