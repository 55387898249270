import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { ComponentModule } from 'common/_components/components.module';
import { MaterialModule } from 'common/modules/material.module';
import { TemplateDefaultModule } from '../../shared/template-default/template-default.module';
import { DataTablesModule } from 'angular-datatables';

import { WishlistRoutingModule } from './wishlist-routing.module';
import { WishlistComponent } from './wishlist/wishlist.component';


@NgModule({
  declarations: [WishlistComponent],
  imports: [
    CommonModule,
    WishlistRoutingModule,
    TranslateModule,
    ReactiveFormsModule,
    ComponentModule,
    MaterialModule,
    DataTablesModule,
    TemplateDefaultModule
  ]
})
export class WishlistModule { }
