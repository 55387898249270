import { Component, OnInit, TemplateRef, ChangeDetectorRef, ViewChild } from '@angular/core';

import { constEnv } from 'common/constants.env';
import { AlertService } from 'ngx-alerts';
import { first } from 'rxjs/operators';
import { DataTableDirective } from 'angular-datatables';
import { TitleService } from 'common/services/title.service';
import { IsLoadingService } from '@service-work/is-loading';
import { SpecificationService } from 'common/services/store/specification.service';
import { ModalDirective } from 'ngx-bootstrap';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LanguageService } from 'common/services/language.service';



@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  config = constEnv;

  lists: SpecificationList[] = null;

  loading = false;

  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;

  @ViewChild('actionModal', { static: false }) actionModal: ModalDirective;
  isActionModalShown = false;

  actionForm: FormGroup;

  availableLangs;

  specModalTitle: string;

  constructor(
    private alert: AlertService,
    private isLoadingService: IsLoadingService,    
    private titleService: TitleService,
    private specification: SpecificationService,
    private lang: LanguageService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Specification');
    if ( this.isLoadingService.isLoading$() ) {
      this.isLoadingService.remove();
    }

    this.availableLangs = this.lang.getAvailableLanguages();

    this.createDataTable();
  }

  createActionForm() {
    this.actionForm = this.fb.group({
      languagesArray: this.fb.array([]),
    });

    this.addAvailableLanguages();
  }

  get a() { return this.actionForm.controls; }
  get l() { return this.a.languagesArray as FormArray; }

  trackByFn(index: any) {
    return index;
  }

  addAvailableLanguages() {
    this.availableLangs.forEach(singleLang => {
        this.l.push(this.fb.group({
          specNameId: [0],
          specNameLangId: [0],
          langId: singleLang.id,
          name: ['', [Validators.required, this.noWhitespaceValidator]]
        }));
    });
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}

  createDataTable() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      ordering: false,
      lengthChange: false,
      searching: false,
      serverSide: true,
      processing: true,
      // pageLength: 2,
      language: {
        paginate: {
          previous: '<',
          first: '<<',
          last: '>>',
          next: '>'
        }
      },
      ajax: (dataTablesParameters: any, callback) => {       
        this.specification.getAll( dataTablesParameters )
        .pipe(first())
        .subscribe(data => {
          const res = data as SpecificationListsResponse;
          let paging: Paging;
          if (res.status === true) {            
            this.lists = res.data as SpecificationList[];
            
            paging = JSON.parse(res.paging) as Paging;
            // paging = { totalCount: this.categoryLists.length, totalRowsAfterFiltering: this.categoryLists.length };
          } else {
            this.alert.danger(data['message']);
            this.lists = [];
          }

          callback({
            recordsTotal: paging.totalCount,
            recordsFiltered: paging.totalRowsAfterFiltering,
            data: []
          });

          this.isLoadingService.remove();
        },
          err => {
            if (typeof err.error !== 'undefined') {
              const msg = (typeof err.error.Message !== 'undefined') ? err.error.Message : err.error.message;
              // this.alert.danger(msg);              
              this.lists = [];
            }

            callback({
              recordsTotal: 0,
              recordsFiltered: 0,
              data: []
            });

            this.isLoadingService.remove();
          });
      },
      // columns: [{ data: 'id' }, { data: 'firstName' }, { data: 'lastName' }]
    };
  }

  showModal(action: string = 'add', specId: number = 0): void {
    if(action === 'add') {
      this.specModalTitle = 'CreateSpecification';
      this.createActionForm();
      this.isActionModalShown = true;
    } else {

      this.isLoadingService.add();
      this.specification.getSpecification( specId )
      .subscribe(
        data => {
          const res = data as SpecificationListsResponse;

          if(res.status === true) {

            this.createActionForm();

            let languageWiseData = [];
            res.data.forEach( single => {
              languageWiseData[single.langId] = single;
            });

            this.l.controls.forEach((singleRow, i) => {
              const singleLang = (typeof languageWiseData[singleRow.value.langId] !== 'undefined') ? languageWiseData[singleRow.value.langId] : [];
              if(singleLang){
                this.l.controls[i].get('name').setValue(singleLang.name);
                this.l.controls[i].get('specNameId').setValue(singleLang.specNameId);
                this.l.controls[i].get('specNameLangId').setValue(singleLang.specNameLangId);
              }
            });

            this.isActionModalShown = true;

          } else {
            this.alert.danger(res.message);
          }
          
          this.isLoadingService.remove();
        },
        err => {
          if (typeof err.error !== 'undefined') {
            const msg = (typeof err.error.Message !== 'undefined') ? err.error.Message : err.error.message;
            this.alert.danger(msg);
          }
          this.isLoadingService.remove();
          this.loading = false;
        }
      ); 

      this.specModalTitle = 'Update Specification';
    }
    
  }

  onHidden(): void {
    this.isActionModalShown = false;    
    this.actionForm.reset();    
  }

  hideModal(): void {
    this.actionModal.hide();
  }

  onActionSubmit() {
    if(this.actionForm.invalid) {
      return;
    }
    
    this.isLoadingService.add();
    
    this.specification.addEditSpecification( this.actionForm.value.languagesArray )
    .subscribe(
      data => {
        const res = data as SpecActionResponse;
        if(res.status === true) {
          this.hideModal();
          this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => dtInstance.draw());
          this.alert.success( res.message )
        } else {
          this.alert.danger( res.message );
        }
        this.isLoadingService.remove();
      },
      err => {
        if (typeof err.error !== 'undefined') {
          const msg = (typeof err.error.Message !== 'undefined') ? err.error.Message : err.error.message;
          this.alert.danger(msg);
        }
        this.isLoadingService.remove();
        this.loading = false;
      }
    );

  }


}

interface SpecificationListsResponse {
  status: boolean;
  message: string;
  data: SpecificationList[];
  paging: string;
}

interface Paging {
  totalCount: number;
  totalRowsAfterFiltering: number;
  pageSize: number;
  currentPage: number;
  totalPages: number;
  previousPage: string;
  nextPage: string;
}

interface SpecificationList {
  langId: number;
  langName: string;
  name: string;
  specNameId: number;
  specNameLangId: number;
}

interface SpecActionResponse {
  status: boolean;
  message: string;
  data: any[];
}
