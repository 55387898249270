import {
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";

import { constEnv } from "common/constants.env";
import { DataTableDirective } from "angular-datatables";
import { TitleService } from "common/services/title.service";
import { first } from "rxjs/operators";
import { Subscription, combineLatest } from "rxjs";
import { AlertService } from "ngx-alerts";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { ModalDirective } from "ngx-bootstrap";
import { IsLoadingService } from "@service-work/is-loading";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "common/_components/confirm-dialog/confirm-dialog.component";
import { PotentialListService } from "common/services/store/potentialList.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PromoCodeService } from "common/services/store/promo-code.service";
import { GeneralService } from "common/services/general.service";
import { CustomValidator } from "common/_helpers/custom.validator";

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.css"],
})
export class ListComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};

  hasLists = false;
  lists: PotentialList[] = [];

  modalRef: BsModalRef;
  subscriptions: Subscription[] = [];

  selectedId = 0;

  config = constEnv;

  @ViewChild("actionModal", { static: false }) actionModal: ModalDirective;
  isActionModalShown = false;

  @ViewChild("viewModal", { static: false }) viewModal: ModalDirective;
  isViewModalShown = false;

  actionForm: FormGroup;
  loading = false;

  currentDate = new Date();
  defaultCurrencyCode = " ";

  dealData = null;

  filters = [
    { key: 0, label: "All" },
    { key: 1, label: "Wishlist" },
    { key: 2, label: "Job" },
  ];

  selectedFilter = 0;

  constructor(
    private titleService: TitleService,
    private alert: AlertService,
    private ls: IsLoadingService,
    private pl: PotentialListService,
    private promocode: PromoCodeService,
    private fb: FormBuilder,
    private general: GeneralService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Potential List");

    if (this.ls.isLoading$()) {
      this.ls.remove();
    }

    const defaultSetting = this.general.getDefaultSettings();

    this.defaultCurrencyCode = defaultSetting["currancy_code"];

    this.currentDate.setDate(this.currentDate.getDate() + 1);

    this.createTable();
  }

  createTable() {
    this.dtOptions = {
      pagingType: "full_numbers",
      ordering: false,
      lengthChange: false,
      searching: false,
      serverSide: true,
      processing: true,
      // pageLength: -1,
      language: {
        paginate: {
          previous: "<",
          first: "<<",
          last: ">>",
          next: ">",
        },
      },
      ajax: (dataTablesParameters: any, callback) => {
        // this.hasLists = false;

        // const searchparams = this.searchForm.value;
        // if (searchparams.filterText !== '') {
        //   dataTablesParameters.search.column = searchparams.filterBy;
        //   dataTablesParameters.search.value = searchparams.filterText;
        // }

        dataTablesParameters.filterListType = this.selectedFilter;

        this.pl
          .getAllPotentialList(dataTablesParameters)
          .pipe(first())
          .subscribe(
            (resp) => {
              const data = resp as PotentialListResponse;

              let paging: Paginate;
              this.lists = [];
              if (data.status === true) {
                this.lists = data.data as PotentialList[];

                paging = JSON.parse(data.paging) as Paginate;
              } else {
                paging = {
                  totalCount: 0,
                  totalRowsAfterFiltering: 0,
                } as Paginate;
                this.lists = [];
                this.hasLists = false;
              }

              if (this.lists.length > 0) {
                this.hasLists = true;
              }

              callback({
                recordsTotal: paging.totalCount,
                recordsFiltered: paging.totalRowsAfterFiltering,
                data: [],
              });
            },
            (err) => {
              const msg =
                typeof err.error.Message !== "undefined"
                  ? err.error.Message
                  : err.error.message;
              if (msg !== "") {
                // this.alert.danger(msg);
              }
              this.lists = [];
              this.hasLists = false;
              callback({
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              });
            }
          );
      },
    };
  }

  get a() {
    return this.actionForm.controls;
  }

  onChangePotentialListType($event) {
    this.selectedFilter = $event.target.value;
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) =>
      dtInstance.draw()
    );
  }

  createDeal(item: PotentialList): void {
    this.createActionForm(item);
    this.isActionModalShown = true;
  }

  viewDeal() {
    this.isViewModalShown = true;
  }

  createActionForm(item: PotentialList) {
    this.actionForm = this.fb.group(
      {
        customerId: [item.customerId],
        productId: [item.productId],
        variationId: [item.productVariationId],
        productPrice: [item.productPrice],
        discount: ["", Validators.required],
        discountUnit: [null, Validators.required],
        activationdate: [this.currentDate],
        expirydate: ["", Validators.required],
        status: [null, Validators.required],
      },
      {
        validators: [CustomValidator.checkPotentialListDiscount],
      }
    );
  }

  getErrorDiscount() {
    return this.actionForm.get("discount").hasError("required")
      ? ""
      : this.actionForm.get("discount").hasError("discountPer")
      ? this.titleService.getTranslatedString("DiscountPercentValidation")
      : this.actionForm.get("discount").hasError("discountFlatMax")
      ? this.titleService.getTranslatedString("PriceGreateThenProductPrice")
      : this.actionForm.get("discount").hasError("discountFlat")
      ? this.titleService.getTranslatedString("MaximumDiscount")
      : "";
  }

  changeDiscountUnit($event) {
    if (this.a["discount"].value !== "") {
      this.a["discount"].markAsTouched({ onlySelf: true });
      this.a["discount"].updateValueAndValidity();
    }
  }

  onHidden(): void {
    this.isActionModalShown = false;
    this.actionForm.reset();
  }

  hideModal(): void {
    this.actionModal.hide();
  }

  hideViewModel() {
    this.viewModal.hide();
  }

  hiddenViewModel() {
    this.isViewModalShown = false;
  }

  showDeal(offerId) {
    this.ls.add();
    this.promocode.get(offerId).subscribe(
      (data) => {
        if (data["status"] === true) {
          this.dealData = data["data"];

          this.isViewModalShown = true;
        } else {
          this.alert.danger(data["message"]);
        }
        this.ls.remove();
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          this.alert.danger(msg);
        }
        this.ls.remove();
      }
    );
  }

  onActionSubmit() {
    if (this.actionForm.invalid) {
      return;
    }

    const ActivationDateUtc = this.actionForm.value.activationdate;
    let activationDate = "";
    if (typeof ActivationDateUtc !== "undefined" && ActivationDateUtc !== "") {
      const month = ActivationDateUtc.getMonth() * 1 + 1;
      const monthWithZero = month >= 0 && month <= 9 ? "0" + month : month;
      const day =
        ActivationDateUtc.getDate() >= 1 && ActivationDateUtc.getDate() <= 9
          ? "0" + ActivationDateUtc.getDate()
          : ActivationDateUtc.getDate();
      activationDate =
        ActivationDateUtc.getFullYear() + "-" + monthWithZero + "-" + day;
    }

    const expiryDateUtc = this.actionForm.value.expirydate;
    let expiryDate = "";
    if (typeof expiryDateUtc !== "undefined" && expiryDateUtc !== "") {
      const month = expiryDateUtc.getMonth() * 1 + 1;
      const monthWithZero = month >= 0 && month <= 9 ? "0" + month : month;
      const day =
        expiryDateUtc.getDate() >= 1 && expiryDateUtc.getDate() <= 9
          ? "0" + expiryDateUtc.getDate()
          : expiryDateUtc.getDate();
      expiryDate =
        expiryDateUtc.getFullYear() + "-" + monthWithZero + "-" + day;
    }

    const formData = {
      discount: this.actionForm.value.discount,
      activationdate: activationDate,
      expirydate: expiryDate,
      status: this.actionForm.value.status,
      type1: this.actionForm.value.discountUnit,
      customerId:
        this.actionForm.value.customerId > 0
          ? this.actionForm.value.customerId
          : 0,
      productId:
        this.actionForm.value.productId > 0
          ? this.actionForm.value.productId
          : 0,
      variationId:
        this.actionForm.value.variationId > 0
          ? this.actionForm.value.variationId
          : 0,
      isDeal: true,
    };

    this.ls.add();
    this.promocode.add(formData).subscribe(
      (data) => {
        if (data["status"] === true) {
          this.alert.success(data["message"]);
          this.hideModal();
          this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) =>
            dtInstance.draw()
          );
        } else {
          this.alert.danger(data["message"]);
        }
        this.ls.remove();
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          this.alert.danger(msg);
        }
        this.ls.remove();
      }
    );
  }
}

interface PotentialListResponse {
  status: boolean;
  message: string;
  data: PotentialList[];
  paging: string;
}

interface Paginate {
  totalCount: number;
  totalRowsAfterFiltering: number;
  pageSize: number;
  currentPage: number;
  totalPages: number;
  previousPage: string;
  nextPage: string;
}

interface PotentialList {
  customerId: number;
  customerName: string;
  finalPrice: number;
  hasIndividualOffer: boolean;
  hasOffer: boolean;
  jobName: string;
  productId: number;
  productImage: string;
  productName: string;
  productPrice: number;
  productVariationId: number;
  wishlistId: number;
  offerId: number;
  currencyCode: string;
}
