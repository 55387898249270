import { FilterAttributeModal } from "./filter/filter-attribute.modal";
import { FilterBrandModal } from "./filter/filter-brand.modal";
import { FilterValueModal } from "./filter/filter-value.modal";


export class ProductFilterModal{
    attributes: FilterAttributeModal[];
    brands: FilterBrandModal[];
    categories: FilterValueModal[];
    maxPrice: number;
    minPrice: number;
    
    deserialize(input: any): this {
        Object.assign(this, input);
        this.attributes = input.attributes.map(item =>  new FilterAttributeModal().deserialize(item) );
        this.brands = input.brands.map(item =>  new FilterBrandModal().deserialize(item) );
        this.categories = input.categories.map(item =>  new FilterValueModal().deserialize(item) );
        return this;
    }
}

export interface Deserializable {
    deserialize(input: any): this;
  }