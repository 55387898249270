import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  AfterViewInit,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";

import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";

import { constEnv } from "common/constants.env";
import { AlertService } from "@full-fledged/alerts";
import { TitleService } from "common/services/title.service";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { IsLoadingService } from "@service-work/is-loading";
import { DesignerService } from "common/services/store/designer.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"],
})
export class ForgotPasswordComponent implements OnInit {
  FPForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  constantConfig = constEnv;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private ls: IsLoadingService,
    private titleService: TitleService,
    private router: Router,
    private designerService: DesignerService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Forgot_Password");
    this.document.body.classList.add("page-forgot-password");

    if (this.ls.isLoading$()) {
      this.ls.remove();
    }

    this.FPForm = this.formBuilder.group({
      email: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern(
            "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"
          ),
        ])
      ),
    });
  }

  ngOnDestroy(): void {
    this.document.body.classList.remove("page-forgot-password");
  }

  getErrorEmail() {
    return this.FPForm.get("email").hasError("required")
      ? ""
      : this.FPForm.get("email").hasError("pattern")
      ? "EmailInValid"
      : // ? this.titleService.getTranslatedString("EmailInValid")
        "";
  }

  get f() {
    return this.FPForm.controls;
  }

  onSubmit(e) {
    this.submitted = true;

    // reset alerts on submit
    // this.alertService.clear();

    // stop here if form is invalid
    if (this.FPForm.invalid) {
      return;
    }

    this.loading = true;
    this.ls.add();

    this.designerService.forgotPassword(this.f.email.value).subscribe(
      (data) => {
        const res = data as FPResponse;
        if (res.status) {
          this.alertService.success(res.message);
          this.submitted = false;
          this.FPForm.reset();
          this.loading = false;
          this.router.navigate([`${this.constantConfig.DESIGNER_URL}/login`]);
        } else {
          this.alertService.danger(res.message);
          this.ls.remove();
        }
      },
      (err) => {
        let errorMsg = "";
        if (typeof err.error.Message !== "undefined") {
          errorMsg = err.error.Message;
        } else if (typeof err.error.message !== "undefined") {
          errorMsg = err.error.message;
        }

        this.submitted = false;
        this.FPForm.reset();

        if (errorMsg !== "") {
          this.alertService.danger(errorMsg);
        }
        this.loading = false;
        this.ls.remove();
      }
    );
  }
}

interface FPResponse {
  status: boolean;
  status_code: number;
  message: string;
}
