/**
 * Copyright 2019 Jim Armstrong (www.algorithmist.net)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * Skeleton for an Angular map control component such as custom zoom
 */
import {
  Component
} from '@angular/core';

@Component({
  selector: 'map-control',

  templateUrl: './map-control.component.html',

  styleUrls: ['./map-control.component.scss'],
})
export class MapControlComponent
{
  constructor()
  {
    // empty
  }
}
