import { Component, OnInit, OnDestroy, Inject, ViewChild } from "@angular/core";
import { AlertService } from "ngx-alerts";
import { constEnv } from "../../../../common/constants.env";

import { DOCUMENT } from "@angular/common";

import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { first } from "rxjs/operators";

import { AuthenticationService } from "common/services/authentication.service";
import { LanguageService } from "common/services/language.service";
import { LoaderService } from "common/services/loader.service";
import { TitleService } from "common/services/title.service";
import { IsLoadingService } from "@service-work/is-loading";
import { Observable } from "rxjs";
import { CustomerService } from "common/services/customers/customer.service";

import { AuthService } from "angularx-social-login";
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialUser,
} from "angularx-social-login";
import { BsModalRef, ModalDirective } from "ngx-bootstrap";
import { Subscription } from "rxjs";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  accountForm: FormGroup;
  accountSubmitted = false;
  socialuser: SocialUser;
  validMobileNo = true;

  @ViewChild("autoShownModal", { static: false })
  autoShownModal: ModalDirective;

  modalRef: BsModalRef;
  socialSubcription: Subscription;

  countryCode: string;
  countryIsoCode: string;

  hasAccountEmail = false;

  constantConfig = constEnv;
  isLoading: Observable<boolean>;

  public showPassword: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private alert: AlertService,
    private titleService: TitleService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private langService: LanguageService,
    private isLoadingService: IsLoadingService,
    private storeUser: CustomerService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Sign_In");

    // this.returnUrl = this.route.snapshot.queryParams.returnUrl || `${this.constantConfig.CUSTOMER_URL}`;
    this.returnUrl =
      this.route.snapshot.queryParams.returnUrl ||
      `${this.constantConfig.BASE_URL}home`;

    if (this.authenticationService.currentUserValue) {
      this.router.navigate([this.returnUrl]);
    }
    this.isLoading = this.isLoadingService.isLoading$();
    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }
    //this.document.body.classList.add('login-body');

    this.makeLoginForm();

    this.socialSubcription = this.authService.authState.subscribe((user) => {
      this.socialuser = user;
      if (user !== null) {
        this.checkSocailLogin(user);
      }
    });

    this.createAccountForm();
  }

  makeLoginForm() {
    this.loginForm = this.formBuilder.group({
      email: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern(
            "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"
          ),
        ])
      ),
      password: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(16),
        ])
      ),
    });
  }

  ngOnDestroy(): void {
    this.document.body.classList.remove("login-body");

    this.socialSubcription.unsubscribe();
  }

  get f() {
    return this.loginForm.controls;
  }

  getErrorEmail() {
    return this.loginForm.get("email").hasError("required")
      ? ""
      : this.loginForm.get("email").hasError("pattern")
      ? this.titleService.getTranslatedString("EmailInValid")
      : "";
  }

  getErrorPassword() {
    return this.loginForm.get("password").hasError("required")
      ? ""
      : this.loginForm.get("password").hasError("minlength")
      ? this.titleService.getTranslatedString("PasswordFormate")
      : this.loginForm.get("password").hasError("maxlength")
      ? this.titleService.getTranslatedString("PasswordFormate")
      : "";
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.isLoadingService.add();

    this.authenticationService
      .login(this.f.email.value, this.f.password.value, "Customer")
      .pipe(first())
      .subscribe(
        (data) => {
          const userdetail =
            typeof data.userdetails !== "undefined" ? data.userdetails : [];

          this.langService.changeLanguage(userdetail.languageCode);

          this.storeUser
            .getProfile()
            .pipe(first())
            .subscribe(
              (profile) => {
                let redirectURL = this.returnUrl;
                //redirectURL = `${constEnv.apiCustomerURL}/settings/store`;

                if (userdetail.isFirstimeLogin) {
                  redirectURL = `${constEnv.CUSTOMER_URL}/change-password`;
                }

                setTimeout(() => {
                  window.location.href = redirectURL;
                }, 2500);
              },
              (err) => {
                this.alert.danger(err.error.message);
                this.loading = false;
                this.isLoadingService.remove();
              }
            );
        },
        (err) => {
          let errorMsg = "";
          if (typeof err.error.Message !== "undefined") {
            errorMsg = err.error.Message;
          } else if (typeof err.error.error_description !== "undefined") {
            errorMsg = err.error.error_description;
          } else if (typeof err.error.message !== "undefined") {
            errorMsg = err.error.message;
          }
          this.submitted = false;
          if (errorMsg == "Password not match") {
            this.loginForm.get("password").reset();
          } else {
            this.loginForm.reset();
          }
          if (errorMsg !== "") {
            // this.alertService.error(errorMsg);
            this.alert.danger(errorMsg);
          }
          this.loading = false;
          this.isLoadingService.remove();
        }
      );
    /*setTimeout( () => {
      this.isLoadingService.remove();
      this.loading = false;
    }, 1500);*/
  }

  checkSocailLogin(socialUser) {
    const socialType = socialUser.provider.toLowerCase();
    const socialId = socialUser.id;
    const socialEmail =
      typeof socialUser.email !== "undefined" ? socialUser.email : "";

    this.isLoadingService.add();
    this.authService.signOut();
    this.storeUser
      .checkSocialLogin(socialId, socialType, socialEmail)
      .subscribe(
        (response) => {
          if (response["status"] !== true) {
            this.setAccountRegister(socialUser);
            this.isLoadingService.remove();
            this.autoShownModal.show();
          } else {
            const userData = response["data"];
            this.isLoadingService.add();
            if (
              this.langService.getCurrentLanguage() !== userData.userLanguage
            ) {
              this.langService.changeLanguage(userData.userLanguage);
            }

            this.storeUser
              .getProfile()
              .pipe(first())
              .subscribe(
                (profile) => {
                  // this.authService.signOut();
                  // this.router.navigate([this.returnUrl]);
                  const redirectURL = this.returnUrl;
                  setTimeout(() => {
                    // this.authService.signOut();
                    window.location.href = redirectURL;
                  }, 1000);
                },
                (err) => {
                  // this.alertService.error(err.error.message);
                  this.loading = false;
                  this.isLoadingService.remove();
                }
              );
          }
          this.isLoadingService.remove();
        },
        (err) => {
          this.setAccountRegister(socialUser);
          this.isLoadingService.remove();
        }
      );
  }

  setAccountRegister(socialUser) {
    this.accountForm.get("firstName").setValue(socialUser.firstName);
    const lastName =
      typeof socialUser.lastName !== "undefined" ? socialUser.lastName : "";
    this.accountForm.get("lastName").setValue(lastName);
    const photoUrl =
      typeof socialUser.photoUrl !== "undefined" ? socialUser.photoUrl : "";
    this.accountForm.get("image").setValue(photoUrl);
    const email =
      typeof socialUser.email !== "undefined" ? socialUser.email : "";
    this.hasAccountEmail = email !== "" ? true : false;
    this.accountForm.get("email").setValue(email);
    this.accountForm.get("socialId").setValue(socialUser.id);
    this.accountForm.get("socialType").setValue(socialUser.provider);
    this.autoShownModal.show();
  }

  hasError(obj) {
    this.validMobileNo = obj;
  }

  onCountryChange(obj) {
    this.countryCode = obj.dialCode;
    this.countryIsoCode = obj.iso2;
  }

  telInputObject(obj) {
    const countryData = obj.getSelectedCountryData();
    this.countryCode = countryData.dialCode;
    this.countryIsoCode = countryData.iso2;
  }

  getAccountErrorEmail() {
    return this.accountForm.get("email").hasError("required")
      ? ""
      : this.accountForm.get("email").hasError("pattern")
      ? this.titleService.getTranslatedString("EmailInValid")
      : "";
  }

  get a() {
    return this.accountForm.controls;
  }

  createAccountForm() {
    this.accountForm = this.formBuilder.group({
      phone: ["", Validators.required],
      phoneCode: this.countryCode,
      email: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern(
            "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"
          ),
        ])
      ),
      firstName: new FormControl(""),
      lastName: new FormControl(""),
      image: new FormControl(""),
      socialId: new FormControl(""),
      socialType: new FormControl(""),
      terms: ["", Validators.required],
    });
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  onAcountSubmit() {
    this.accountSubmitted = true;

    if (this.accountForm.invalid) {
      return;
    }

    if (!this.validMobileNo) {
      return;
    }

    this.accountForm.value.phoneCode = "+" + this.countryCode;
    this.isLoadingService.add();
    this.storeUser
      .socialLogin(this.accountForm.value)
      .pipe(first())
      .subscribe(
        (data) => {
          // console.log(data);
          // this.alertService.success(data['message']);
          const userData = data["data"];

          if (this.langService.getCurrentLanguage() !== userData.userLanguage) {
            this.langService.changeLanguage(userData.userLanguage);
          }

          this.storeUser
            .getProfile()
            .pipe(first())
            .subscribe(
              (profile) => {
                // this.router.navigate([this.returnUrl]);
                const redirectURL = this.returnUrl;
                setTimeout(() => {
                  window.location.href = redirectURL;
                  this.accountSubmitted = false;
                  this.accountForm.reset();
                }, 1000);
              },
              (err) => {
                this.alert.danger(err.error.message);
                this.loading = false;
                this.isLoadingService.remove();
              }
            );

          // this.router.navigate([`${this.constantConfig.STORE_URL}/login`]);
        },
        (err) => {
          // console.log(err);
          const msg = typeof err.error !== "undefined" ? err.error.message : "";
          if (msg) {
            this.alert.danger(msg);
            // this.alert.error(msg);
          }
          this.loading = false;
          this.isLoadingService.remove();
        }
      );
  }

  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }
}
