import {
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";

import { constEnv } from "common/constants.env";
import { DataTableDirective } from "angular-datatables";
import { TitleService } from "common/services/title.service";
import { first } from "rxjs/operators";
import { Subscription, combineLatest } from "rxjs";
import { AlertService } from "ngx-alerts";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { IsLoadingService } from "@service-work/is-loading";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "common/_components/confirm-dialog/confirm-dialog.component";
import { GeneralService } from "common/services/general.service";

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.css"],
})
export class ListComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};

  hasLists = false;
  lists: ShippingRate[] = [];

  modalRef: BsModalRef;
  subscriptions: Subscription[] = [];

  selectedId = 0;

  config = constEnv;

  constructor(
    private titleService: TitleService,
    private alert: AlertService,
    private ls: IsLoadingService,
    private dialog: MatDialog,
    private gs: GeneralService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("PromoCode");

    if (this.ls.isLoading$()) {
      this.ls.remove();
    }

    this.createTable();
  }

  createTable() {
    this.dtOptions = {
      pagingType: "full_numbers",
      ordering: false,
      lengthChange: false,
      searching: false,
      serverSide: true,
      processing: true,
      // pageLength: -1,
      language: {
        paginate: {
          previous: "<",
          first: "<<",
          last: ">>",
          next: ">",
        },
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.hasLists = false;

        this.gs
          .getShippingRateLists(dataTablesParameters)
          .pipe(first())
          .subscribe(
            (resp) => {
              let paging: any;
              if (resp["status"] === true) {
                this.lists = resp["data"] as ShippingRate[];

                paging = JSON.parse(resp["paging"]);
              } else {
                paging = { totalCount: 0, totalRowsAfterFiltering: 0 };
                this.lists = [];
                this.hasLists = false;
              }

              if (this.lists.length > 0) {
                this.hasLists = true;
              }

              callback({
                recordsTotal: paging.totalCount,
                recordsFiltered: paging.totalRowsAfterFiltering,
                data: [],
              });
            },
            (err) => {
              const msg =
                typeof err.error.Message !== "undefined"
                  ? err.error.Message
                  : err.error.message;
              if (msg !== "") {
                // this.alert.danger(msg);
              }
              this.lists = [];
              this.hasLists = false;
              callback({
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              });
            }
          );
      },
    };
  }

  changeStatus(Id, status) {
    if (Id > 0) {
      this.ls.add();
      this.gs.shippingRateChangeStatus(Id, status ? 1 : 0).subscribe(
        (data) => {
          if (data["status"] === true && data["message"] !== "") {
            this.alert.success(data["message"]);
          } else {
            if (data["message"] !== "") {
              this.alert.danger(data["message"]);
            }
          }

          this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) =>
            dtInstance.draw()
          );
          this.ls.remove();
          // this.loadProductLists();
        },
        (err) => {
          this.alert.danger(err.error.message);
          this.ls.remove();
        }
      );
    }
  }

  delete(id) {
    if (id > 0) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: "Confirm",
          message: "DeleteConfirm",
        },
      });
      confirmDialog.afterClosed().subscribe((result) => {
        if (result === true) {
          this.ls.add();
          this.gs.deleteShippingRate(id).subscribe(
            (data) => {
              if (data["status"] === true) {
                this.alert.success(data["message"]);
              } else {
                this.alert.danger(data["message"]);
              }
              this.datatableElement.dtInstance.then(
                (dtInstance: DataTables.Api) => dtInstance.draw()
              );
              this.ls.remove();
              // this.loadProductLists();
            },
            (err) => {
              if (typeof err.error !== "undefined") {
                const msg =
                  typeof err.error.Message !== "undefined"
                    ? err.error.Message
                    : err.error.message;
                this.alert.danger(msg);
              }
              this.ls.remove();
            }
          );
        }
      });
    }
  }
}

interface ShippingRate {
  id: number;
  zoneName: string;
  freeDeliveryCharges: number;
  status: boolean;
  perWeightPrice: number;
}
