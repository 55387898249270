import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from 'common/_helpers/auth.guard';
import { constEnv } from 'common/constants.env';
import { WishlistComponent } from './wishlist/wishlist.component';

const STORE_ENDPOINT = `${constEnv.storeEndPoint}`;

const routes: Routes = [
  {
    path: `${STORE_ENDPOINT}/wishlists`, component: WishlistComponent, canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WishlistRoutingModule { }
