import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from 'common/services/authentication.service';

import { constEnv } from 'common/constants.env';

@Injectable({ providedIn: 'root' })

export class RoleGuard implements CanActivate {
    config = constEnv;
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
            
            const currentUserDetail = currentUser.userdetails;

            const userType = currentUserDetail.userType;

            let redirectUrl = '';
            if ( state.url.indexOf( userType ) === -1 ) {
                switch (userType) {
                    case 'driver' : redirectUrl = this.config.DRIVER_URL + '/dashboard'; break;
                    case 'store' : 
                        redirectUrl = this.config.STORE_URL + '/dashboard'; 
                    case 'designer' : 
                        // this.router.navigate([this.config.deliveryCompanyActionUrl + '/home']);
                        // redirectUrl = this.config.deliveryCompanyActionUrl + '/home'; 
                    break;
                    // default : redirectUrl = this.config.BASE_URL; break;
                }
            }
            return true;
        }

        return true;
    }
}
