import { Component, OnInit, OnDestroy, Inject, ViewChild } from "@angular/core";
import { DOCUMENT } from "@angular/common";

import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { first } from "rxjs/operators";
import { AlertService } from "@full-fledged/alerts";
import { constEnv } from "common/constants.env";
import { IsLoadingService } from "@service-work/is-loading";
// import { TitleService } from 'common/services/title.service';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit, OnDestroy {
  env = constEnv;

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    // private titleService: TitleService,
    private isLoadingService: IsLoadingService
  ) {}

  ngOnInit() {
    // this.titleService.setTitle('Sign In');

    this.document.body.classList.add("page-login");

    this.returnUrl =
      this.route.snapshot.queryParams.returnUrl ||
      `${this.env.DRIVER_URL}/dashboard`;

    this.createLoginForm();
  }

  ngOnDestroy(): void {
    this.document.body.classList.remove("page-login");
  }

  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      email: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern(
            "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"
          ),
        ])
      ),
      password: ["", Validators.required],
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    // this.alertService.clear();

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.isLoadingService.add();

    setTimeout(() => {
      this.isLoadingService.remove();
      this.loading = false;
    }, 1500);
  }
}
