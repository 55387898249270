import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { IsLoadingService } from "@service-work/is-loading";
import { constEnv } from "common/constants.env";
import { SiteDesignerService } from "common/services/site/siteDesigner.service";
import { TitleService } from "common/services/title.service";
import { ProductList } from "common/_models/site/product-list.modal";
import { AlertService } from "@full-fledged/alerts";
import { PageChangedEvent } from "ngx-bootstrap/pagination";

@Component({
  selector: "app-catalog-detail",
  templateUrl: "./catalog-detail.component.html",
  styleUrls: ["./catalog-detail.component.css"],
})
export class CatalogDetailComponent implements OnInit {
  config = constEnv;
  pageTitle = "";

  catalogData: DesignerPick;
  productLists: ProductList[] = null;
  productCount: number = 0;
  catalogId: number;

  showFiller = false;

  productDetailUrl = `${this.config.BASE_URL}/product/`;

  toggleReview: boolean = false;

  designerReviewsParams = {
    DesignerId: "",
    Rating: "",
    PageNo: 0,
    PageSize: 10,
  };
  designerReviews: any[] = [];

  pagination: Paging = {
    totalCount: 0,
    totalRowsAfterFiltering: 0,
    pageSize: 10,
    currentPage: 0,
    totalPages: 0,
    previousPage: "",
    nextPage: "",
  };

  constructor(
    private isLoadingService: IsLoadingService,
    private titleService: TitleService,
    private alert: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private designerService: SiteDesignerService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("DesignerCatalog");

    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }

    this.catalogId = this.route.snapshot.params.catalogId;
    this.catalogId = this.catalogId > 0 ? this.catalogId : 0;
    if (this.catalogId <= 0) {
      this.router.navigate([this.config.BASE_URL, "designer-catalog"]);
    }

    this.getCatalogDetail();
  }

  getCatalogDetail() {
    this.isLoadingService.add();
    this.designerService.getDesignerCatalogDetail(this.catalogId).subscribe(
      (data) => {
        const res = data as CatalogDetailResponse;
        if (res.status === true) {
          this.catalogData = res.data.catalogDetails as DesignerPick;
          this.productCount = res.data.productCount;
          this.productLists = res.data.productList.map((item) =>
            new ProductList().deserialize(item)
          );

          this.getDesignerReviews();
          window.scroll(0, 0);
          this.isLoadingService.remove();
        } else {
          this.alert.danger(res.message);
          this.isLoadingService.remove();
        }
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          this.alert.danger(err.error.message);
        }
        this.isLoadingService.remove();
      }
    );
  }

  toggleReviewSection() {
    this.toggleReview = !this.toggleReview;
  }

  getDesignerReviews() {
    this.isLoadingService.add();
    this.designerReviewsParams.DesignerId =
      this.catalogData.designerId.toString();
    this.designerService
      .getDesignerReviews(this.designerReviewsParams)
      .subscribe(
        (data) => {
          const res = data as any;
          if (res.status === true) {
            this.designerReviews = res.data || [];

            if (this.designerReviews) {
              this.pagination = JSON.parse(res.paging) as Paging;
            } else {
              this.designerReviews = [];
            }
            this.isLoadingService.remove();
          } else {
            // this.alert.danger(res.message);
            this.isLoadingService.remove();
            this.designerReviews = [];
          }
        },
        (err) => {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          if (msg !== "") {
            // this.alert.danger(err.error.message);
          }
          this.isLoadingService.remove();
        }
      );
  }

  pageChange(paging: PageChangedEvent) {
    paging.page = paging.page > 0 ? paging.page - 1 : 0;
    paging.itemsPerPage = paging.itemsPerPage
      ? paging.itemsPerPage
      : this.designerReviewsParams.PageSize;

    this.designerReviewsParams.PageSize = paging.itemsPerPage;
    this.designerReviewsParams.PageNo = paging.page;

    this.getDesignerReviews();
  }
}

interface CatalogDetailResponse {
  status: boolean;
  message: string;
  data: CatalogData;
}

interface CatalogData {
  catalogDetails: any;
  productCount: number;
  productList: ProductList[];
}

interface DesignerPick {
  catalogId: number;
  catalogName: string;
  catalogDescription: string;
  spaceType: string;
  catalogCoverImage: string;
  designerId: number;
  designerName: string;
  designerProfileImage: string;
  likeCount: number;
  isLiked: boolean;
  experianceLevel: number;
  serviceFees: number;
  currencyCode: string;
  rate: number;
  total_rate_count: number;
}

export interface Paging {
  totalCount: number;
  totalRowsAfterFiltering: number;
  pageSize: number;
  currentPage: number;
  totalPages: number;
  previousPage: string;
  nextPage: string;
}
