import { Component, OnInit } from '@angular/core';
import { constEnv } from 'common/constants.env';
import { GeneralService } from 'common/services/general.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  currentYear = (new Date()).getFullYear();
  siteTitle = constEnv.projectTitle;

  config = constEnv;

  constructor(
    public gs: GeneralService
  ) { }

  ngOnInit() {
  }

}
