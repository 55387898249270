import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { ComponentModule } from 'common/_components/components.module';
import { CategoryRoutingModule } from './category-routing.module';

import { ListComponent } from './list/list.component';
import { ActionComponent } from './action/action.component';
import { NgSelectModule } from '@ng-select/ng-select';

import { DataTablesModule } from 'angular-datatables';
import { TemplateDefaultModule } from '../../../shared/template-default/template-default.module';
import { MaterialModule } from 'common/modules/material.module';

@NgModule({
  declarations: [ListComponent, ActionComponent],
  imports: [
    CommonModule,
    CategoryRoutingModule,
    RouterModule,
    TranslateModule.forRoot(),
    ReactiveFormsModule,
    ComponentModule,
    DataTablesModule,
    TemplateDefaultModule,
    MaterialModule,
    NgSelectModule
  ],
  exports: [
    ListComponent
  ]
})
export class CategoryModule { }
