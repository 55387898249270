import { ProductList } from "./product-list.modal";

export class ProductDetail {
  productId: number;
  productName: string;
  productPrice: number;
  productPriceAfterDiscount: number;

  isAssemblyRequired: boolean;
  assemblyCharges: number;
  assemblyChargesString: string;

  productRating: number;
  afterDiscountYouSave: string;
  attribute: ProductAttribute[];
  boughtTogether: string;
  buttonText: string;
  buyNow: boolean;
  canMoveToCart: boolean;
  crossCellProduct: ProductList[];
  currencyCode: string;
  customerReview: number;
  delivery: ProductDelivery;
  discount: string;
  discountPer: string;
  extra: ProductExtra;
  freeReturnPolicy: string;
  headerText: string;
  imageUrl: string[];
  inCart: boolean;
  inCartQty: number;
  isCustomerAddressExists: boolean;
  isInstallmentAvailable: boolean;
  isSoldIndividual: boolean;
  itemStock: string;
  itemStockQty: number;
  itemTypeId: number;
  linkedProduct: ProductList[];
  message: string;
  offerEndsOn: string;
  offerEndsOnDate: Date;
  productDesc: string;
  productDimensions: Dimension;

  productTagIds: any[];
  productVariationId: number;
  returnPolicyUrl: string;
  similarProducts: string;
  sku: string;

  hasLastMinuteDeal: Boolean;
  hasIndividualOffer: Boolean;

  specifications: ProductSpecification[];
  status: boolean;
  storeName: string;
  storeId: string;
  totalRatings: number;
  wishListId: number;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

interface ProductAttribute {
  attributeId: number;
  attributeName: string;
  swatcheType: number;
  attributeValue: ProductAttributeValue[];
}

interface ProductAttributeValue {
  id: number;
  name: string;
  value: string;
  disabled: boolean;
  selected: boolean;
}

interface ProductDelivery {
  date: Date;
  daysFromNowText: any;
  freeShipping: string;
  preShipmentText: string;
  shippingText: string;
}

export interface ProductExtra {
  isFragile: string;
  isFreeReturnPolicy: string;
  isInstallmentAvailable: string;
  manufacturingDate: Date | string;
  videoURL: string;
  warranty: string;

  isReturnable: boolean | string;
  returnableText: string;
  brandName: string;
}

interface Dimension {
  height: number;
  length: number;
  weight: number;
  width: number;
  processingTime: number;
}

interface ProductSpecification {
  displayName: string;
  displayValue: string;
}
