import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  IterableDiffers,
  ViewChild,
} from "@angular/core";
import { constEnv } from "common/constants.env";

import { DOCUMENT } from "@angular/common";

import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  AbstractControl,
} from "@angular/forms";
import { first } from "rxjs/operators";
import { AlertService } from "ngx-alerts";
import { CustomValidator } from "common/_helpers/custom.validator";
import { IsLoadingService } from "@service-work/is-loading";
import { Observable } from "rxjs";
import { TitleService } from "common/services/title.service";
import { CustomerService } from "common/services/customers/customer.service";
import { CustomerUser } from "common/_models/customers/customerUser";
import { AuthService } from "angularx-social-login";
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialUser,
} from "angularx-social-login";
import { BsModalRef, ModalDirective } from "ngx-bootstrap";
import { Subscription } from "rxjs/internal/Subscription";
import { LanguageService } from "common/services/language.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit, OnDestroy {
  env = constEnv;
  regiForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  countryCode: string;
  countryIsoCode: string;
  validMobileNo = true;

  isLoading: Observable<boolean>;

  iniObj: any;
  constantConfig = constEnv;
  profileForm: FormGroup;

  accountForm: FormGroup;
  accountSubmitted = false;

  socialCountryCode: string;
  socialCountryIsoCode: string;

  iniSocailObj: any;
  socialuser: SocialUser;
  socialValidMobileNo = true;

  @ViewChild("autoShownModal", { static: false })
  autoShownModal: ModalDirective;

  modalRef: BsModalRef;
  socialSubcription: Subscription;

  hasAccountEmail = false;

  public showPassword: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private titleService: TitleService,
    private isLoadingService: IsLoadingService,
    private customer: CustomerService,
    private authService: AuthService,
    private langService: LanguageService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Sign_Up");
    this.isLoading = this.isLoadingService.isLoading$();

    this.document.body.classList.add("page-register");

    this.createSignUpFrom();

    this.returnUrl =
      this.route.snapshot.queryParams.returnUrl || `${this.env.CUSTOMER_URL}`;

    this.socialSubcription = this.authService.authState.subscribe((user) => {
      this.socialuser = user;
      if (user !== null) {
        this.checkSocailLogin(user);
      }
    });

    this.createAccountForm();
  }
  getCustomerData() {
    this.isLoadingService.add();
    this.loading = true;
    this.customer.getProfile().subscribe(
      (data) => {
        /*if (data['status'] === true) {*/

        const customerData = new CustomerUser().deserialize(data);

        this.setFormValue(customerData);
        this.loading = false;
        /*} else {*/
        // this.alert.error(data['message']);
        //this.isLoadingService.remove();
        /*}*/
      },
      (err) => {
        // this.alert.error(err.error.message);
        this.loading = false;
      }
    );
  }
  ngOnDestroy(): void {
    this.document.body.classList.remove("page-register");

    this.socialSubcription.unsubscribe();
  }

  createSignUpFrom() {
    // tslint:disable-next-line: max-line-length
    const emailregex: RegExp =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    this.regiForm = this.formBuilder.group(
      {
        firstName: ["", Validators.required],
        lastName: ["", Validators.required],
        mobileNo: ["", Validators.required],
        countryCode: this.countryCode,
        countryIsoCode: this.countryIsoCode,
        email: new FormControl(
          "",
          Validators.compose([
            Validators.required,
            Validators.email,
            Validators.pattern(emailregex),
          ])
        ),
        password: new FormControl(
          "",
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(16),
          ])
        ),
        terms: [false, Validators.required],
        // recaptchaReactive: new FormControl(null, Validators.required)
      },
      { validator: CustomValidator.checkTerms }
    );
  }

  get formData() {
    return this.regiForm.controls;
  }

  hasError(obj) {
    if (!obj && this.countryIsoCode === "ae") {
      const selectedNumber = this.iniObj.getNumber();
      this.validMobileNo =
        /^(?:\+971|00971|0)?(?:50|52|53|54|55|56|57|58|59|2|3|4|6|7|9)\d{7}$/gm.test(
          selectedNumber
        );
    } else {
      this.validMobileNo = obj;
    }
  }

  onCountryChange(obj) {
    this.countryCode = obj.dialCode;
    this.countryIsoCode = obj.iso2;
  }

  telInputObject(obj) {
    this.iniObj = obj;
    const countryData = obj.getSelectedCountryData();
    this.countryCode = countryData.dialCode;
    this.countryIsoCode = countryData.iso2;
  }

  getErrorEmail() {
    return this.regiForm.get("email").hasError("required")
      ? ""
      : this.regiForm.get("email").hasError("pattern")
      ? this.titleService.getTranslatedString("EmailInValid")
      : "";
  }

  getErrorPassword() {
    return this.regiForm.get("password").hasError("required")
      ? ""
      : this.regiForm.get("password").hasError("minlength")
      ? this.titleService.getTranslatedString("PasswordFormate")
      : this.regiForm.get("password").hasError("maxlength")
      ? this.titleService.getTranslatedString("PasswordFormate")
      : "";
  }
  setFormValue(customerData: CustomerUser) {
    if (customerData.phone_code !== null) {
      this.iniObj.setNumber(customerData.phone_code);
    }
    // this.isEmailAvailable = (customerData.email !== '') ? true : false;
    this.regiForm.get("firstName").setValue(customerData.first_name);
    this.regiForm.get("lastName").setValue(customerData.last_name);
    this.regiForm.get("mobileNo").setValue(customerData.phone);
    this.regiForm.get("email").setValue(customerData.email);
    this.regiForm
      .get("languageId")
      .setValue(customerData.languageId.toString());
    this.regiForm
      .get("CurrencyId")
      .setValue(customerData.currencyId.toString());
  }
  get f() {
    return this.profileForm.controls;
  }
  onSubmit() {
    this.submitted = true;

    if (this.regiForm.invalid) {
      return;
    }

    if (!this.validMobileNo) {
      return;
    }

    this.loading = true;
    this.isLoadingService.add();

    this.regiForm.value.countryCode = "+" + this.countryCode;
    this.regiForm.value.countryIsoCode = this.countryIsoCode;
    const formData = {
      firstName: this.regiForm.value.firstName,
      lastName: this.regiForm.value.lastName,
      email: this.regiForm.value.email,
      phoneCode: this.countryCode,
      phone: this.regiForm.value.mobileNo,
      password: this.regiForm.value.password,
      regionCode: this.countryIsoCode,
    };
    this.customer
      .register(formData)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data["status"]) {
            this.alertService.success(data["message"]);
            this.submitted = false;
            this.regiForm.reset();
            this.router.navigate([`${this.constantConfig.CUSTOMER_URL}`]);
          } else {
            this.alertService.danger(data["message"]);
          }
          this.loading = false;
        },
        (err) => {
          const msg =
            typeof err.error.message !== "undefined" ? err.error.message : "";
          if (msg) {
            this.alertService.danger(msg);
          }
          this.loading = false;
        }
      );

    setTimeout(() => {
      this.isLoadingService.remove();
      this.loading = false;
    }, 2000);
  }

  checkSocailLogin(socialUser) {
    const socialType = socialUser.provider.toLowerCase();
    const socialId = socialUser.id;
    const socialEmail =
      typeof socialUser.email !== "undefined" ? socialUser.email : "";

    this.isLoadingService.add();
    this.authService.signOut();
    this.customer.checkSocialLogin(socialId, socialType, socialEmail).subscribe(
      (response) => {
        if (response["status"] !== true) {
          this.setAccountRegister(socialUser);
          this.isLoadingService.remove();
          this.autoShownModal.show();
        } else {
          const userData = response["data"];
          this.isLoadingService.add();
          if (this.langService.getCurrentLanguage() !== userData.userLanguage) {
            this.langService.changeLanguage(userData.userLanguage);
          }

          this.customer
            .getProfile()
            .pipe(first())
            .subscribe(
              (profile) => {
                // this.authService.signOut();
                // this.router.navigate([this.returnUrl]);
                const redirectURL = this.returnUrl;
                setTimeout(() => {
                  // this.authService.signOut();
                  window.location.href = redirectURL;
                }, 1000);
              },
              (err) => {
                // this.alertService.error(err.error.message);
                this.loading = false;
                this.isLoadingService.remove();
              }
            );
        }
        this.isLoadingService.remove();
      },
      (err) => {
        this.setAccountRegister(socialUser);
        this.isLoadingService.remove();
      }
    );
  }

  setAccountRegister(socialUser) {
    this.accountForm.get("firstName").setValue(socialUser.firstName);
    const lastName =
      typeof socialUser.lastName !== "undefined" ? socialUser.lastName : "";
    this.accountForm.get("lastName").setValue(lastName);
    const photoUrl =
      typeof socialUser.photoUrl !== "undefined" ? socialUser.photoUrl : "";
    this.accountForm.get("image").setValue(photoUrl);
    const email =
      typeof socialUser.email !== "undefined" ? socialUser.email : "";
    this.hasAccountEmail = email !== "" ? true : false;
    this.accountForm.get("email").setValue(email);
    this.accountForm.get("socialId").setValue(socialUser.id);
    this.accountForm.get("socialType").setValue(socialUser.provider);
    this.autoShownModal.show();
  }

  hasErrorSocial(obj) {
    // this.socialValidMobileNo = obj;
    if (!obj && this.socialCountryIsoCode === "ae") {
      const selectedNumber = this.iniSocailObj.getNumber();
      // console.log(regex.test(selectedNumber), "Reg Ex");
      // if (regex.test(selectedNumber)) {
      //   this.validMobileNo = true;
      // } else {
      //   this.validMobileNo = false;
      // }
      this.socialValidMobileNo =
        /^(?:\+971|00971|0)?(?:50|52|53|54|55|56|57|58|59|2|3|4|6|7|9)\d{7}$/gm.test(
          selectedNumber
        );
    } else {
      this.socialValidMobileNo = obj;
    }
  }

  onCountryChangeSocial(obj) {
    this.socialCountryCode = obj.dialCode;
    this.socialCountryIsoCode = obj.iso2;
  }

  telInputObjectSocial(obj) {
    this.iniSocailObj = obj;
    const countryData = obj.getSelectedCountryData();
    this.socialCountryCode = countryData.dialCode;
    this.socialCountryIsoCode = countryData.iso2;
  }

  getAccountErrorEmail() {
    return this.accountForm.get("email").hasError("required")
      ? ""
      : this.accountForm.get("email").hasError("pattern")
      ? this.titleService.getTranslatedString("EmailInValid")
      : "";
  }

  get a() {
    return this.accountForm.controls;
  }

  createAccountForm() {
    this.accountForm = this.formBuilder.group({
      phone: ["", Validators.required],
      phoneCode: this.countryCode,
      email: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern(
            "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"
          ),
        ])
      ),
      firstName: new FormControl(""),
      lastName: new FormControl(""),
      image: new FormControl(""),
      socialId: new FormControl(""),
      socialType: new FormControl(""),
      terms: ["", Validators.required],
    });
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  onAcountSubmit() {
    this.accountSubmitted = true;

    if (this.accountForm.invalid) {
      return;
    }

    if (!this.socialValidMobileNo) {
      return;
    }

    this.accountForm.value.phoneCode = "+" + this.socialCountryCode;

    this.isLoadingService.add();
    this.customer
      .socialLogin(this.accountForm.value)
      .pipe(first())
      .subscribe(
        (data) => {
          // console.log(data);
          // this.alertService.success(data['message'])
          const userData = data["data"];

          if (this.langService.getCurrentLanguage() !== userData.userLanguage) {
            this.langService.changeLanguage(userData.userLanguage);
          }

          this.customer
            .getProfile()
            .pipe(first())
            .subscribe(
              (profile) => {
                // this.router.navigate([this.returnUrl]);
                const redirectURL = this.returnUrl;
                setTimeout(() => {
                  window.location.href = redirectURL;
                  this.accountSubmitted = false;
                  this.accountForm.reset();
                }, 1000);
              },
              (err) => {
                this.alertService.danger(err.error.message);
                this.loading = false;
                this.isLoadingService.remove();
              }
            );

          // this.router.navigate([`${this.constantConfig.STORE_URL}/login`]);
        },
        (err) => {
          // console.log(err);
          const msg = typeof err.error !== "undefined" ? err.error.message : "";
          if (msg) {
            this.alertService.danger(msg);
            // this.alertService.error(msg);
          }
          this.loading = false;
          this.isLoadingService.remove();
        }
      );
  }
}
