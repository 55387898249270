import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone,
} from "@angular/core";

import { constEnv } from "common/constants.env";
import { AlertService } from "@full-fledged/alerts";
import { LoaderService } from "common/services/loader.service";
import { LanguageService } from "common/services/language.service";
import { GeneralService } from "common/services/general.service";
import { SettingsService } from "common/services/store/settings.service";
import { StoreUserService } from "common/services/store/storeUser.service";

import {
  FormGroup,
  FormBuilder,
  Validators,
  ValidatorFn,
  AbstractControl,
} from "@angular/forms";
import { MapsAPILoader } from "@agm/core";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "common/services/title.service";
import { StoreUser } from "common/_models/store/storeUser";

import { AddressInfo } from "common/_components/maps/interfaces/address-info";

@Component({
  selector: "app-store",
  templateUrl: "./store.component.html",
  styleUrls: ["./store.component.css"],
})
export class StoreComponent implements OnInit {
  storeSettingForm: FormGroup;
  submitted = false;

  config = constEnv;

  countryCode: string;
  countryIsoCode: string;
  validMobileNo = true;

  zoom: number;
  // initial center position for the map
  lat: number;
  lng: number;

  private geoCoder;

  // @ViewChild("search", { static: false })
  // public searchElementRef: ElementRef;

  markers: marker[] = [];

  isEmailAvailable = false;
  iniObj: any;
  storeId = 0;

  languages: any;
  countryLists: countryList[] = [];
  stateLists: stateList[] = [];
  cityLists: cityList[] = [];

  cuisineLists = [];
  selectedCuisine = [];
  dropdownSettings = {};

  deliveryHandleDp = [
    { key: 2, value: "Both" },
    { key: 1, value: "Store" },
    { key: 0, value: "Admin" },
  ];

  loading = false;

  constructor(
    private alert: AlertService,
    private ls: LoaderService,
    private fb: FormBuilder,
    private lang: LanguageService,
    private general: GeneralService,
    private ngZone: NgZone,
    private settings: SettingsService,
    private su: StoreUserService,
    private translate: TranslateService,
    private titleService: TitleService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("STORE_Settings");
    this.zoom = this.config.defaultZoom;
    this.lat = this.config.defaultLatitude;
    this.lng = this.config.defaultLongitude;

    //this.placeAutoCompleteField();

    this.makeForm();

    this.getAllLanguage();
    this.getCountryList();

    this.getStoreData();
  }

  getStoreData() {
    this.ls.showLoader();
    this.settings.getStoreData().subscribe(
      (data) => {
        if (data["status"] === true) {
          const storeData = data["data"] as StoreUser;
          this.setFormValue(storeData);
          this.ls.hideLoader();
        } else {
          // this.alert.error(data['message']);
          this.ls.hideLoader();
        }
      },
      (err) => {
        // this.alert.error(err.error.message);
        this.ls.hideLoader();
      }
    );
  }

  private makeForm() {
    const zipCodeRegex: RegExp =
      /^(?!.*[\s-]{2,})[A-Za-z0-9][A-Za-z\d\- ]{0,22}[A-Za-z\d]$/;

    this.storeSettingForm = this.fb.group({
      storeName: ["", Validators.required],
      email: ["", Validators.required],
      contactPersonName: ["", Validators.required],
      mobileNo: ["", Validators.required],
      location: ["", Validators.required],
      locationLatitude: [""],
      locationLongitude: [""],
      storeAddress: ["", Validators.required],
      addressLine1: ["", Validators.required],
      addressLine2: [""],
      landmark: [""],
      storeZipCode: ["", [Validators.required, this.zipCodeValidator()]],
      countryId: [null, Validators.required],
      stateId: [null, Validators.required],
      cityId: [null, Validators.required],
      languageId: [null, Validators.required],
      vatNumber: [""],
      deliveryHandleId: [null, Validators.required],
    });
  }

  get f() {
    return this.storeSettingForm.controls;
  }

  zipCodeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (!value) return null;
      if (
        value.startsWith(" ") ||
        value.endsWith(" ") ||
        value.startsWith("-") ||
        value.endsWith("-")
      ) {
        return { invalidZipCode: { value: control.value } };
      }
      const spacesCount = (value.match(/ /g) || []).length;
      const dashesCount = (value.match(/-/g) || []).length;

      if (spacesCount > 1 || dashesCount > 1) {
        return { invalidZipCode: { value: control.value } };
      }

      const regex = /^[a-zA-Z0-9\- ]+$/;
      if (!regex.test(value)) {
        return { invalidZipCode: { value: control.value } };
      }

      return null;
    };
  }

  hasError(obj) {
    if (!obj && this.countryIsoCode === "ae") {
      const selectedNumber = this.iniObj.getNumber();
      // if (regex.test(selectedNumber)) {
      //   this.validMobileNo = true;
      // } else {
      //   this.validMobileNo = false;
      // }
      this.validMobileNo =
        /^(?:\+971|00971|0)?(?:50|52|53|54|55|56|57|58|59|2|3|4|6|7|9)\d{7}$/gm.test(
          selectedNumber
        );
    } else {
      this.validMobileNo = obj;
    }
  }

  setFormValue(storeData: StoreUser) {
    this.onCountryChange(storeData.countryId);
    this.onStateChange(storeData.stateId);
    if (storeData.phoneCode !== null) {
      this.iniObj.setNumber(storeData.phoneCode);
    }
    // this.storeId = storeData.StoreId;
    this.isEmailAvailable = storeData.email !== "" ? true : false;

    this.storeSettingForm.get("storeName").setValue(storeData.storeName);
    this.storeSettingForm.get("email").setValue(storeData.email);
    this.storeSettingForm
      .get("contactPersonName")
      .setValue(storeData.contactPerson);
    this.storeSettingForm.get("mobileNo").setValue(storeData.phone);
    this.storeSettingForm.get("location").setValue(storeData.location);
    this.storeSettingForm.get("locationLatitude").setValue(storeData.latitude);
    this.storeSettingForm
      .get("locationLongitude")
      .setValue(storeData.longitude);
    this.storeSettingForm.get("storeAddress").setValue(storeData.addressLine);
    this.storeSettingForm.get("addressLine1").setValue(storeData.addressLine1);
    this.storeSettingForm.get("addressLine2").setValue(storeData.addressLine2);
    this.storeSettingForm.get("landmark").setValue(storeData.landmark);
    this.storeSettingForm.get("storeZipCode").setValue(storeData.zipCode);
    this.storeSettingForm
      .get("countryId")
      .setValue(storeData.countryId > 0 ? storeData.countryId : null);
    this.storeSettingForm
      .get("stateId")
      .setValue(storeData.stateId > 0 ? storeData.stateId : null);
    this.storeSettingForm
      .get("cityId")
      .setValue(storeData.cityId > 0 ? storeData.cityId : null);
    this.storeSettingForm
      .get("languageId")
      .setValue(storeData.langId.toString());
    this.storeSettingForm
      .get("deliveryHandleId")
      .setValue(storeData.deliveryHandleId);
    this.storeSettingForm.get("vatNumber").setValue(storeData.vat);

    this.lat = parseFloat(storeData.latitude);
    this.lng = parseFloat(storeData.longitude);

    this.createMarker(this.lat, this.lng, storeData.location, true);
  }

  onDeSelectAll(items: any) {
    this.selectedCuisine = [];
  }

  private placeAutoCompleteField() {
    // this.mapsAPILoader.load().then(() => {
    //   // this.setCurrentLocation();
    //   this.geoCoder = new google.maps.Geocoder();
    //   const autocomplete = new google.maps.places.Autocomplete(
    //     this.searchElementRef.nativeElement,
    //     {
    //       types: ["address"],
    //     }
    //   );
    //   autocomplete.addListener("place_changed", () => {
    //     this.ngZone.run(() => {
    //       // get the place result
    //       const place: google.maps.places.PlaceResult = autocomplete.getPlace();
    //       // verify result
    //       if (place.geometry === undefined || place.geometry === null) {
    //         return;
    //       }
    //       // set latitude, longitude and zoom
    //       this.lat = place.geometry.location.lat();
    //       this.lng = place.geometry.location.lng();
    //       this.zoom = 12;
    //       this.storeSettingForm
    //         .get("location")
    //         .setValue(place.formatted_address);
    //       this.storeSettingForm.get("locationLatitude").setValue(this.lat);
    //       this.storeSettingForm.get("locationLongitude").setValue(this.lng);
    //       this.createMarker(this.lat, this.lng);
    //     });
    //   });
    // });
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.zoom = 8;

        this.storeSettingForm.get("locationLatitude").setValue(this.lat);
        this.storeSettingForm.get("locationLongitude").setValue(this.lng);

        this.createMarker(this.lat, this.lng);
        //this.getAddress(this.lat, this.lng);
      });
    }
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            this.zoom = 12;
            this.storeSettingForm
              .get("location")
              .setValue(results[0].formatted_address);
          } else {
            // window.alert('No results found');
            this.alert.danger("No results found");
          }
        } else {
          // window.alert('Geocoder failed due to: ' + status);
          this.alert.danger("Geocoder failed due to: " + status);
        }
      }
    );
  }

  getSelectedAddress(address: AddressInfo) {
    if (address) {
      this.storeSettingForm.get("location").setValue(address.text);

      this.storeSettingForm
        .get("locationLatitude")
        .setValue(address.latlng.lat);
      this.storeSettingForm
        .get("locationLongitude")
        .setValue(address.latlng.lng);

      const selectedCountry = this.countryLists.find(
        (item) => item.countryName === address.properties.CntryName
      );
      if (selectedCountry) {
        this.storeSettingForm
          .get("countryId")
          .setValue(selectedCountry.countryId);
        this.onCountryChange(selectedCountry.countryId);
      }

      setTimeout(() => {
        const selectedState = this.stateLists.find(
          (item) => item.stateName === address.properties.Region
        );
        if (selectedState) {
          this.storeSettingForm.get("stateId").setValue(selectedState.stateId);
          this.onStateChange(selectedState.stateId);
        }

        setTimeout(() => {
          const selectedCity = this.cityLists.find(
            (item) => item.cityName === address.properties.City
          );
          if (selectedCity) {
            this.storeSettingForm.get("cityId").setValue(selectedCity.cityId);
          }
        }, 500);
      }, 500);

      // this.storeSettingForm.get("storeAddress").setValue(storeData.addressLine);
      const postalCode = address.properties.PostalExt
        ? address.properties.Postal + " " + address.properties.PostalExt
        : address.properties.Postal;
      this.storeSettingForm.get("storeZipCode").setValue(postalCode);

      const stAddress = address.properties.StAddr
        ? address.properties.StAddr
        : "";
      const stAddressLine = address.properties.Address
        ? address.properties.Address
        : "";

      const addressLine1 = stAddress ? stAddress : stAddressLine;

      this.storeSettingForm.get("addressLine1").setValue(addressLine1);

      const nbrhd = address.properties.Nbrhd ? address.properties.Nbrhd : "";
      const nbrhdLine = address.properties.Neighborhood
        ? address.properties.Neighborhood
        : "";

      const neighborhood = nbrhd ? nbrhd : nbrhdLine;

      this.storeSettingForm.get("landmark").setValue(neighborhood);

      this.updateAddressLine();
    }
  }

  updateAddressLine() {
    const addressLine1 = this.storeSettingForm.get("addressLine1").value;
    const addressLine2 = this.storeSettingForm.get("addressLine2").value;
    const landmark = this.storeSettingForm.get("landmark").value;
    const postcode = this.storeSettingForm.get("storeZipCode").value;

    const combinedAddress = [addressLine1, addressLine2, landmark, postcode]
      .filter(Boolean)
      .join(", ");

    this.storeSettingForm.get("storeAddress").setValue(combinedAddress);
  }

  onPhoneCodeChange(obj) {
    this.countryCode = obj.dialCode;
    this.countryIsoCode = obj.iso2;
  }

  telInputObject(obj) {
    this.iniObj = obj;
    const countryData = obj.getSelectedCountryData();
    this.countryCode = countryData.dialCode;
    this.countryIsoCode = countryData.iso2;
  }

  private getAllLanguage() {
    this.ls.showLoader();
    this.lang.getAllLanguages().subscribe((data) => {
      if (data["status"] === true) {
        this.languages = data["data"];
      }
    });
  }

  private getCountryList() {
    this.general.getAllCountry().subscribe(
      (data) => {
        if (data["status"] === true) {
          this.countryLists = data["data"];
        }
        this.ls.hideLoader();
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          this.alert.danger(err.error.message);
        }
        this.ls.hideLoader();
      }
    );
  }

  createMarker(lat, lng, label = "", draggable = false) {
    this.markers.push({
      lat,
      lng,
      label,
      draggable,
    });
  }

  onCountryChange(countryId: number) {
    if (countryId > 0) {
      this.ls.showLoader();
      this.stateLists = [];
      this.cityLists = [];
      this.storeSettingForm.get("stateId").setValue(null);
      this.storeSettingForm.get("cityId").setValue(null);
      this.general.getstateByCountryId(countryId).subscribe(
        (data) => {
          if (data["status"] === true) {
            this.stateLists = data["data"];
          }
          this.ls.hideLoader();
        },
        (err) => {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          if (msg !== "") {
            this.alert.danger(err.error.message);
          }
          this.ls.hideLoader();
        }
      );
    } else {
      this.stateLists = [];
      this.cityLists = [];
    }
  }

  onStateChange(stateId: number) {
    if (stateId > 0) {
      this.ls.showLoader();
      this.cityLists = [];
      this.storeSettingForm.get("cityId").setValue(null);
      this.general.getcityByStateId(stateId).subscribe(
        (data) => {
          if (data["status"] === true) {
            this.cityLists = data["data"];
          }
          this.ls.hideLoader();
        },
        (err) => {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          if (msg !== "") {
            this.alert.danger(err.error.message);
          }
          this.ls.hideLoader();
        }
      );
    } else {
      this.cityLists = [];
    }
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.storeSettingForm.invalid) {
      return;
    }

    const LanguageId = this.storeSettingForm.value.languageId;

    const formData = {
      cityId: this.storeSettingForm.value.cityId,
      contactPerson: this.storeSettingForm.value.contactPersonName,
      countryId: this.storeSettingForm.value.countryId,
      Email: this.storeSettingForm.value.email,
      location: this.storeSettingForm.value.location,
      longitude: this.storeSettingForm.value.locationLongitude,
      latitude: this.storeSettingForm.value.locationLatitude,
      phone: this.storeSettingForm.value.mobileNo,
      phoneCode: this.countryCode,
      regionCode: this.countryIsoCode,
      stateId: this.storeSettingForm.value.stateId,
      addressLine: this.storeSettingForm.value.storeAddress,
      addressLine1: this.storeSettingForm.value.addressLine1,
      addressLine2: this.storeSettingForm.value.addressLine2,
      landmark: this.storeSettingForm.value.landmark,
      storeName: this.storeSettingForm.value.storeName,
      zipCode: this.storeSettingForm.value.storeZipCode,
      vat: this.storeSettingForm.value.vatNumber,
      langId: LanguageId,
      deliveryHandleId: this.storeSettingForm.value.deliveryHandleId,
    };

    this.ls.showLoader();
    this.loading = true;
    this.settings.updateStoreData(formData).subscribe(
      async (data) => {
        if (data["status"] === true) {
          this.alert.success(data["message"]);

          const defaultLang = this.lang.getDefaultLanguage();
          if (defaultLang.id !== LanguageId.toString()) {
            const langDetail = this.lang.getLanguageDetailById(LanguageId);
            this.lang.changeLanguage(langDetail.lang_code);
          }

          await this.general.getDefaultSettingsValue();

          this.su.getProfile().subscribe(
            (data) => {
              setTimeout(() => {
                window.location.href = window.location.href;
              }, 2000);
            },
            (err) => {
              this.ls.hideLoader();
            }
          );
        } else {
          this.alert.danger(data["message"]);
          this.ls.hideLoader();
        }
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        this.alert.danger(msg);
        this.loading = false;
        this.ls.hideLoader();
      }
    );
  }
}

interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}

interface countryList {
  countryId: number;
  countryName?: string;
}

interface stateList {
  stateId: number;
  stateName?: string;
}

interface cityList {
  cityId: number;
  cityName?: string;
}
