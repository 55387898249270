import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "common/_helpers/auth.guard";
import { constEnv } from "common/constants.env";
import { ListsComponent } from "./lists/lists.component";
import { DetailComponent } from "./detail/detail.component";

const STORE_ENDPOINT = constEnv.storeEndPoint;

const routes: Routes = [
  {
    path: `${STORE_ENDPOINT}/cancel-request`,
    children: [
      { path: "", component: ListsComponent },
      { path: ":id", component: DetailComponent },
    ],
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CancelRequestRoutingModule {}
