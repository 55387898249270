import { Component, OnInit, ViewChild } from "@angular/core";

import { constEnv } from "common/constants.env";
import { DataTableDirective } from "angular-datatables";
import { Config } from "datatables.net";
import { TitleService } from "common/services/title.service";
import { AlertService } from "@full-fledged/alerts";
import { IsLoadingService } from "@service-work/is-loading";
import { FeedbackService } from "common/services/store/feedback.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxGalleryAnimation, NgxGalleryOptions } from "ngx-gallery-9";
import { PageChangedEvent } from "ngx-bootstrap/pagination";

@Component({
  selector: "app-feedback",
  templateUrl: "./feedback.component.html",
  styleUrls: ["./feedback.component.css"],
})
export class FeedbackComponent implements OnInit {
  config = constEnv;

  hasLists = false;
  lists: FeedbackList[] = [];
  counters: FeedbackCounter = null;
  feedbackProgress: FeedbackProgress[] = null;

  feedbackUrl = `${this.config.STORE_URL}/feedback`;
  feedbackType: number;

  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;

  // dtOptions: DataTables.Settings = {};
  dtOptions: Config = {};

  reviews: any[] = [];
  pagination: any;

  reviewImagesOptions: NgxGalleryOptions[];

  constructor(
    private titleService: TitleService,
    private alert: AlertService,
    private isLoadingService: IsLoadingService,
    private feedback: FeedbackService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.titleService.setTitle("STORE_Feedbacks");

    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }

    this.reviewImagesOptions = [
      {
        width: "100%",
        height: "70px",
        thumbnailsColumns: 10,
        imageAnimation: NgxGalleryAnimation.Slide,
        image: false,
        thumbnailsRemainingCount: true,
        imageSize: "content",
        previewCloseOnClick: true,
        previewCloseOnEsc: true,
      },
      // max-width 800
      {
        breakpoint: 800,
        width: "100%",
        // height: '600px',
        thumbnailsColumns: 4,
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false,
      },
    ];

    const type = this.route.snapshot.params.type;
    // this.setFeedbackType( (typeof type !== 'undefined') ? type : 3 );
    this.feedbackType = typeof type !== "undefined" ? type : 3;

    // if (this.feedbackType == 3) {
    //   this.getCustomerFeedback();
    //   this.getReviews();
    // } else {
    //   this.createDataTable();
    // }

    this.route.params.subscribe(() => {
      if (this.feedbackType == 3) {
        this.getCustomerFeedback();
        this.getReviews();
      } else {
        this.createDataTable();
      }
    });
  }

  setFeedbackType(type: number) {
    this.feedbackType = type;
    // if (this.feedbackType != 3) {
    //   // this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) =>
    //   this.datatableElement.dtInstance.then((dtInstance) => dtInstance.draw());
    // }

    this.router.navigateByUrl(`${this.feedbackUrl}/${this.feedbackType}`);
  }

  createDataTable() {
    this.dtOptions = {
      pagingType: "full_numbers",
      ordering: false,
      lengthChange: false,
      searching: false,
      serverSide: true,
      processing: true,
      // pageLength: -1,
      language: {
        paginate: {
          previous: "<",
          first: "<<",
          last: ">>",
          next: ">",
        },
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.hasLists = true;

        // const searchparams = this.searchForm.value;
        // if (searchparams.filterText !== '') {
        //   dataTablesParameters.search.column = searchparams.filterBy;
        //   dataTablesParameters.search.value = searchparams.filterText;
        // }

        // dataTablesParameters.search.dateTimeForm = '';
        // dataTablesParameters.search.dateTimeTo = '';
        // if (searchparams.orderFilterDates !== '') {
        //   const oDates = searchparams.orderFilterDates;
        //   dataTablesParameters.search.dateTimeForm = this.datePipe.transform( oDates[0], this.dateFormat );
        //   dataTablesParameters.search.dateTimeTo = this.datePipe.transform( oDates[1], this.dateFormat );
        // }

        let paging: Paginate = null;

        this.feedback
          .getFeedbacks(this.feedbackType, dataTablesParameters)
          .subscribe(
            (data) => {
              const res = data as FeedbackListResponse;

              if (res.status === true) {
                const feedbackData = res.data as FeedbackData;

                this.lists = feedbackData.Reviews;
                this.counters = feedbackData.Counter;

                this.feedbackProgress = [
                  {
                    label: "5",
                    bg: "success",
                    review: Math.round(
                      (this.counters.fiveReviewsCount /
                        this.counters.totalReviewsCount) *
                        100
                    ),
                  },
                  {
                    label: "4",
                    bg: "info",
                    review: Math.round(
                      (this.counters.fourReviewsCount /
                        this.counters.totalReviewsCount) *
                        100
                    ),
                  },
                  {
                    label: "3",
                    bg: "warning",
                    review: Math.round(
                      (this.counters.threeReviewsCount /
                        this.counters.totalReviewsCount) *
                        100
                    ),
                  },
                  {
                    label: "2",
                    bg: "teal",
                    review: Math.round(
                      (this.counters.twoReviewsCount /
                        this.counters.totalReviewsCount) *
                        100
                    ),
                  },
                  {
                    label: "1",
                    bg: "danger",
                    review: Math.round(
                      (this.counters.oneReviewsCount /
                        this.counters.totalReviewsCount) *
                        100
                    ),
                  },
                ] as FeedbackProgress[];

                paging = JSON.parse(res.paging) as Paginate;
              } else {
                paging = {
                  totalCount: 0,
                  totalRowsAfterFiltering: 0,
                } as Paginate;

                this.alert.danger(data["message"]);
                this.lists = [];
              }

              callback({
                recordsTotal: paging.totalCount,
                recordsFiltered: paging.totalRowsAfterFiltering,
                data: [],
              });

              // this.loader.hideLoader();
            },
            (err) => {
              const msg =
                typeof err.error.Message !== "undefined"
                  ? err.error.Message
                  : err.error.message;
              if (msg !== "") {
                // this.alert.danger(msg);
                callback({
                  recordsTotal: 0,
                  recordsFiltered: 0,
                  data: [],
                });
                this.lists = [];
              }
              // this.loader.hideLoader();
            }
          );
      },
    };
  }

  getCustomerFeedback() {
    const dataTablesParameters = {
      start: 0,
      length: 10,
    };
    this.feedback
      .getFeedbacks(this.feedbackType, dataTablesParameters)
      .subscribe(
        (data) => {
          const res = data as FeedbackListResponse;

          if (res.status === true) {
            const feedbackData = res.data as FeedbackData;

            //this.lists = feedbackData.Reviews;
            this.counters = feedbackData.Counter;

            this.feedbackProgress = [
              {
                label: "5",
                bg: "success",
                review: Math.round(
                  (this.counters.fiveReviewsCount /
                    this.counters.totalReviewsCount) *
                    100
                ),
              },
              {
                label: "4",
                bg: "info",
                review: Math.round(
                  (this.counters.fourReviewsCount /
                    this.counters.totalReviewsCount) *
                    100
                ),
              },
              {
                label: "3",
                bg: "warning",
                review: Math.round(
                  (this.counters.threeReviewsCount /
                    this.counters.totalReviewsCount) *
                    100
                ),
              },
              {
                label: "2",
                bg: "teal",
                review: Math.round(
                  (this.counters.twoReviewsCount /
                    this.counters.totalReviewsCount) *
                    100
                ),
              },
              {
                label: "1",
                bg: "danger",
                review: Math.round(
                  (this.counters.oneReviewsCount /
                    this.counters.totalReviewsCount) *
                    100
                ),
              },
            ] as FeedbackProgress[];
          } else {
            this.alert.danger(data["message"]);
            this.lists = [];
          }
          // this.loader.hideLoader();
        },
        (err) => {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          if (msg !== "") {
            // this.alert.danger(msg);
            this.lists = [];
          }
          // this.loader.hideLoader();
        }
      );
  }

  getReviews(pageNo: number = 0, pageSize: number = 10) {
    this.isLoadingService.add();
    this.feedback.getStoreReviews(pageNo, pageSize).subscribe(
      (data) => {
        const res = data;

        if (res["status"] === true) {
          this.reviews = res["data"];

          if (this.reviews.length > 0) {
            this.reviews.map((item, index) => {
              if (item.reviewImages.length > 0) {
                item.reviewImages.map((image) => {
                  if (
                    typeof this.reviews[index].review_images === "undefined"
                  ) {
                    this.reviews[index]["review_images"] = [];
                  }
                  this.reviews[index]["review_images"].push({
                    small: image.images,
                    medium: image.images,
                    big: image.images,
                  });
                });
              } else {
                this.reviews[index]["review_images"] = [];
              }
            });
            this.pagination = JSON.parse(res["paging"]);
          } else {
            this.reviews = [];
          }
          this.isLoadingService.remove();
        } else {
          // this.alert.danger(res.message);
          this.isLoadingService.remove();
          this.reviews = [];
        }
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          // this.alert.danger(err.error.message);
        }
        this.reviews = [];
        this.isLoadingService.remove();
      }
    );
  }

  pageChange(paging: PageChangedEvent) {
    paging.page = paging.page > 0 ? paging.page - 1 : 0;
    paging.itemsPerPage = paging.itemsPerPage ? paging.itemsPerPage : 10;

    this.getReviews(paging.page, paging.itemsPerPage);
  }
}

interface FeedbackListResponse {
  status: boolean;
  message: string;
  paging: string;
  data: FeedbackData;
}

interface Paginate {
  totalCount: number;
  totalRowsAfterFiltering: number;
  pageSize: number;
  currentPage: number;
  totalPages: number;
  previousPage: string;
  nextPage: string;
}

interface FeedbackData {
  Counter: FeedbackCounter;
  Reviews: FeedbackList[];
}

interface FeedbackCounter {
  fiveReviewsCount: number;
  fourReviewsCount: number;
  oneReviewsCount: number;
  threeReviewsCount: number;
  twoReviewsCount: number;
  totalReviewsCount: number;
  avgReviewsCount: number;
}

interface FeedbackList {
  date: string;
  feedbackId: number;
  id: number;
  imageUrl: string;
  name: number;
  rate: number;
  remarks: string;
  type: string;
  orderBaseDetailId: number;
  productName: string;
}

interface FeedbackProgress {
  label: string;
  bg: string;
  review: number;
}
