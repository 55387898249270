import { Component, OnInit, ViewChild } from "@angular/core";

import { constEnv } from "common/constants.env";
import { DataTableDirective } from "angular-datatables";
import { TitleService } from "common/services/title.service";
import { first, filter } from "rxjs/operators";
import { AlertService } from "ngx-alerts";

import { HelpSupportService } from "common/services/store/helpSupport.service";
import { HelpSupport } from "common/_models/store/helpSupport";
import { IsLoadingService } from "@service-work/is-loading";

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.css"],
})
export class ListComponent implements OnInit {
  config = constEnv;

  hasLists = false;
  lists: HelpSupport[] = [];

  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};

  constructor(
    private titleService: TitleService,
    private alert: AlertService,
    private hs: HelpSupportService,
    private isLoadingService: IsLoadingService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("STORE_Help_Support");

    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }

    this.createDataTable();
  }

  createDataTable() {
    this.dtOptions = {
      pagingType: "full_numbers",
      ordering: false,
      lengthChange: false,
      searching: false,
      serverSide: true,
      processing: true,
      // pageLength: -1,
      language: {
        emptyTable: "No data available",
        paginate: {
          previous: "<",
          first: "<<",
          last: ">>",
          next: ">",
        },
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.hasLists = true;

        // const searchparams = this.searchForm.value;
        // if (searchparams.filterText !== '') {
        //   dataTablesParameters.search.column = searchparams.filterBy;
        //   dataTablesParameters.search.value = searchparams.filterText;
        // }

        // dataTablesParameters.search.dateTimeForm = '';
        // dataTablesParameters.search.dateTimeTo = '';
        // if (searchparams.orderFilterDates !== '') {
        //   const oDates = searchparams.orderFilterDates;
        //   dataTablesParameters.search.dateTimeForm = this.datePipe.transform( oDates[0], this.dateFormat );
        //   dataTablesParameters.search.dateTimeTo = this.datePipe.transform( oDates[1], this.dateFormat );
        // }

        let paging: Paginate = null;

        this.hs
          .getAll(dataTablesParameters)
          .pipe(first())
          .subscribe(
            (data) => {
              const res = data as SupportListResponse;

              if (res.status === true) {
                this.lists = res.data.map((item) =>
                  new HelpSupport().deserialize(item)
                );

                paging = JSON.parse(res.paging) as Paginate;
              } else {
                paging = {
                  totalCount: 0,
                  totalRowsAfterFiltering: 0,
                } as Paginate;

                this.alert.danger(data["message"]);
                this.lists = [];
              }

              callback({
                recordsTotal: paging.totalCount,
                recordsFiltered: paging.totalRowsAfterFiltering,
                data: [],
              });

              // this.loader.hideLoader();
            },
            (err) => {
              const msg =
                typeof err.error.Message !== "undefined"
                  ? err.error.Message
                  : err.error.message;
              if (msg !== "") {
                // this.alert.danger(msg);
                callback({
                  recordsTotal: 0,
                  recordsFiltered: 0,
                  data: [],
                });
                this.lists = [];
              }
              // this.loader.hideLoader();
            }
          );
      },
    };
  }
}

interface SupportListResponse {
  status: boolean;
  message: string;
  paging: string;
  data: HelpSupport[];
}

interface Paginate {
  totalCount: number;
  totalRowsAfterFiltering: number;
  pageSize: number;
  currentPage: number;
  totalPages: number;
  previousPage: string;
  nextPage: string;
}
