import { Component, Input, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { AlertService } from "@full-fledged/alerts";
import { IsLoadingService } from "@service-work/is-loading";
import { SocialUser } from "angularx-social-login";
import { constEnv } from "common/constants.env";
import { CustomerService } from "common/services/customers/customer.service";
import { FirebaseAuthService } from "common/services/firebase-auth.service";
import { LanguageService } from "common/services/language.service";
import { StoreUserService } from "common/services/store/storeUser.service";
import { TitleService } from "common/services/title.service";
import { BsModalRef, ModalDirective } from "ngx-bootstrap/modal";
import { first } from "rxjs/operators";

@Component({
  selector: "app-social-login",
  templateUrl: "./social-login.component.html",
  styleUrls: ["./social-login.component.css"],
})
export class SocialLoginComponent {
  @Input() userType: string;

  loading = false;
  submitted = false;
  @Input() returnUrl: string;

  accountForm: FormGroup;
  accountSubmitted = false;
  socialUser: SocialUser;
  validMobileNo = true;

  @ViewChild("autoShownModal", { static: false })
  autoShownModal: ModalDirective;

  modalRef: BsModalRef;

  countryCode: string;
  countryIsoCode: string;

  hasAccountEmail = false;
  hasSocialData = {
    firstName: false,
    lastName: false,
  };

  public phoneInputPadding: string = "0px";

  constantConfig = constEnv;

  constructor(
    private alert: AlertService,
    private formBuilder: FormBuilder,
    private langService: LanguageService,
    private isLoadingService: IsLoadingService,
    private customerService: CustomerService,
    private storeService: StoreUserService,
    private firebaseAuthService: FirebaseAuthService,
    private titleService: TitleService
  ) {}

  ngOnInit() {
    this.createAccountForm();
  }

  userTypeObject() {
    if (this.userType === "store") {
      return this.storeService;
    } else if (this.userType === "customer") {
      return this.customerService;
    }
    return;
  }

  createAccountForm() {
    this.accountForm = this.formBuilder.group({
      phone: ["", Validators.required],
      phoneCode: this.countryCode,
      email: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern(
            "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"
          ),
        ])
      ),
      firstName: new FormControl("", Validators.compose([Validators.required])),
      lastName: new FormControl("", Validators.compose([Validators.required])),
      image: new FormControl(""),
      socialId: new FormControl(""),
      socialType: new FormControl(""),
      terms: ["", Validators.required],
    });
  }

  get a() {
    return this.accountForm.controls;
  }

  checkSocialLogin(socialUser) {
    const socialType = socialUser.provider.toLowerCase();
    const socialId = socialUser.id;
    const socialEmail =
      typeof socialUser.email !== "undefined" ? socialUser.email : "";

    this.isLoadingService.add();
    // this.authService.signOut();
    this.userTypeObject()
      .checkSocialLogin(socialId, socialType, socialEmail)
      .subscribe(
        (response) => {
          if (response["status"] !== true) {
            this.setAccountRegister(socialUser);
            this.isLoadingService.remove();
            this.autoShownModal.show();
          } else {
            const userData = response["data"];
            this.isLoadingService.add();
            if (
              this.langService.getCurrentLanguage() !== userData.userLanguage
            ) {
              this.langService.changeLanguage(userData.userLanguage);
            }

            this.userTypeObject()
              .getProfile()
              .pipe(first())
              .subscribe(
                (profile) => {
                  // this.authService.signOut();
                  // this.router.navigate([this.returnUrl]);
                  const redirectURL = this.returnUrl;
                  setTimeout(() => {
                    // this.authService.signOut();
                    window.location.href = redirectURL;
                  }, 1000);
                },
                (err) => {
                  // this.alertService.error(err.error.message);
                  this.loading = false;
                  this.isLoadingService.remove();
                }
              );
          }
          this.isLoadingService.remove();
        },
        (err) => {
          this.setAccountRegister(socialUser);
          this.isLoadingService.remove();
        }
      );
  }

  setAccountRegister(socialUser) {
    const firstName =
      typeof socialUser.firstName !== "undefined"
        ? socialUser.firstName.trim()
        : "";
    this.accountForm.get("firstName").setValue(firstName.trim());
    const lastName =
      typeof socialUser.lastName !== "undefined"
        ? socialUser.lastName.trim()
        : "";
    this.accountForm.get("lastName").setValue(lastName.trim());
    const photoUrl =
      typeof socialUser.photoUrl !== "undefined" ? socialUser.photoUrl : "";
    this.accountForm.get("image").setValue(photoUrl.trim());
    const email =
      typeof socialUser.email !== "undefined" ? socialUser.email : "";
    this.hasAccountEmail = email !== "" ? true : false;
    this.hasSocialData.firstName = firstName !== "" ? true : false;
    this.hasSocialData.lastName = lastName !== "" ? true : false;
    this.accountForm.get("email").setValue(email.trim());
    this.accountForm.get("socialId").setValue(socialUser.id);
    this.accountForm.get("socialType").setValue(socialUser.provider);
    this.phoneInputPadding = "95px";
    this.autoShownModal.show();
  }

  getAccountErrorEmail() {
    return this.accountForm.get("email").hasError("required")
      ? ""
      : this.accountForm.get("email").hasError("pattern")
      ? // ? this.titleService.getTranslatedString("EmailInValid")
        "EmailInValid"
      : "";
  }

  hasError(obj) {
    this.validMobileNo = obj;
  }

  onCountryChange(obj) {
    this.countryCode = obj.dialCode;
    this.countryIsoCode = obj.iso2;
  }

  telInputObject(obj) {
    const countryData = obj.getSelectedCountryData();
    this.countryCode = countryData.dialCode;
    this.countryIsoCode = countryData.iso2;
  }

  signInWithGoogle() {
    const providerId = this.firebaseAuthService.providerType["GOOGLE"];
    this.isLoadingService.add();
    this.firebaseAuthService.GoogleAuth().subscribe({
      next: (res) => {
        // console.log("Login successful:", res.user.toJSON());
        this.setSocialUser(res, providerId);
        this.isLoadingService.remove();
        this.checkSocialLogin(this.socialUser);
      },
      error: (err) => {
        console.error("Login failed:", err.message);
        this.isLoadingService.remove();
      },
      complete() {
        if (this.isLoading$) {
          this.isLoadingService.remove();
        }
      },
    });
  }

  signInWithFB(): void {
    this.isLoadingService.add();
    const providerId = this.firebaseAuthService.providerType["FB"];
    this.firebaseAuthService.FacebookAuth().subscribe({
      next: (res) => {
        console.log("Login successful:", res);
        this.setSocialUser(res, providerId);
        this.isLoadingService.remove();
        this.checkSocialLogin(this.socialUser);
      },
      error: (err) => {
        console.error("Login failed:", err.message);
        this.isLoadingService.remove();
      },
      complete() {
        if (this.isLoading$) {
          this.isLoadingService.remove();
        }
      },
    });
  }

  signInWithApple(): void {
    this.isLoadingService.add();
    const providerId = this.firebaseAuthService.providerType["APPLE"];
    this.firebaseAuthService.AppleAuth().subscribe({
      next: (res) => {
        console.log("Login successful:", res);
        this.setSocialUser(res, providerId);
        this.isLoadingService.remove();
        this.checkSocialLogin(this.socialUser);
      },
      error: (err) => {
        console.error("Login failed:", err.message);
        this.isLoadingService.remove();
      },
      complete() {
        if (this.isLoading$) {
          this.isLoadingService.remove();
        }
      },
    });
  }

  setSocialUser(user: any, providerId: string) {
    const additionalUserInfo = user.additionalUserInfo;
    const userProfile = additionalUserInfo.profile;
    const credentials = additionalUserInfo.credential ?? user.credential;

    let photoUrl = userProfile?.picture ?? "";
    if (providerId === this.firebaseAuthService.providerType["FB"]) {
      if (typeof photoUrl === "object") {
        photoUrl = photoUrl?.data?.url;
      }
    }

    this.socialUser = {
      provider: providerId,
      id: userProfile.id ?? "",
      email: userProfile?.email,
      name: userProfile?.name ?? "",
      photoUrl: photoUrl,
      firstName: userProfile?.given_name
        ? userProfile?.given_name
        : userProfile?.first_name
        ? userProfile?.first_name
        : "",
      lastName: userProfile?.family_name
        ? userProfile?.family_name
        : userProfile?.last_name
        ? userProfile?.last_name
        : "",
      authToken: credentials?.accessToken ?? "",
      idToken: credentials?.idToken ?? "",
      authorizationCode: "",
    };

    // console.log("social User:", this.socialUser);
  }

  onAccountSubmit() {
    this.accountSubmitted = true;

    if (this.accountForm.invalid) {
      return;
    }

    if (!this.validMobileNo) {
      return;
    }

    this.accountForm.value.phoneCode = "+" + this.countryCode;
    this.isLoadingService.add();
    this.userTypeObject()
      .socialLogin(this.accountForm.value)
      .pipe(first())
      .subscribe(
        (data) => {
          // console.log(data);
          // this.alertService.success(data['message']);
          const userData = data["data"];

          if (this.langService.getCurrentLanguage() !== userData.userLanguage) {
            this.langService.changeLanguage(userData.userLanguage);
          }

          this.userTypeObject()
            .getProfile()
            .pipe(first())
            .subscribe(
              (profile) => {
                // this.router.navigate([this.returnUrl]);
                const redirectURL = this.returnUrl;
                setTimeout(() => {
                  window.location.href = redirectURL;
                  this.accountSubmitted = false;
                  this.accountForm.reset();
                }, 1000);
              },
              (err) => {
                this.alert.danger(err.error.message);
                this.loading = false;
                this.isLoadingService.remove();
              }
            );

          // this.router.navigate([`${this.constantConfig.STORE_URL}/login`]);
        },
        (err) => {
          // console.log(err);
          const msg = typeof err.error !== "undefined" ? err.error.message : "";
          if (msg) {
            this.alert.danger(msg);
            // this.alert.error(msg);
          }
          this.loading = false;
          this.isLoadingService.remove();
        }
      );
  }
}
