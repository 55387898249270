import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { constEnv } from 'common/constants.env';
import { AuthGuard } from 'common/_helpers/auth.guard';

import { ListComponent } from './list/list.component';
const _ENDPOINT = `${constEnv.storeEndPoint}/notifications`;

const routes: Routes = [
  {
    path: `${_ENDPOINT}`,
    children: [
       { path: '', component: ListComponent }
    ],
    canActivate: [AuthGuard]
 },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NotificationRoutingModule { }
