import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddressComponent } from './address/address.component';
import { constEnv } from 'common/constants.env';
import { AuthGuard } from 'common/_helpers/auth.guard';
import { AddAddressComponent } from './add-address/add-address.component';

const ADDRESS_ENDPOINT = `${constEnv.customerEndPoint}/address`;

const routes: Routes = [
  {
  path: `${ADDRESS_ENDPOINT}`,
    children: [
       { path: ':action',
          children: [
                { path: ':id', component: AddAddressComponent },
                { path: '', component: AddAddressComponent },
          ]
        },
        { path: '', component: AddAddressComponent },
    ],
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AddressRoutingModule { }
