import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { constEnv } from 'common/constants.env';
import { map } from 'rxjs/operators';
import { formatDate } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class PromoCodeService {
    configConstant = constEnv;
    apiUrl = '';
    
    constructor(private http: HttpClient) {
        this.apiUrl = `${this.configConstant.apiStoreURL}`;
    }

    getAll( dataTablesParameters ) {
        let searchValue = ''; let searchBy = '';
        if ( dataTablesParameters.search.value !== '' ) {
            searchValue = dataTablesParameters.search.value;
            searchBy = dataTablesParameters.search.column;
        }

        let dateTimeFrom  = ''; let dateTimeTo = '';
        if ( dataTablesParameters.search.dateTimeForm !== '' && dataTablesParameters.search.dateTimeTo !== '') {
            dateTimeFrom = dataTablesParameters.search.dateTimeForm;
            dateTimeTo = dataTablesParameters.search.dateTimeTo;
        }

        const pageNo = (dataTablesParameters.start * 1) / (dataTablesParameters.length * 1);
        const searchParams = new HttpParams()
            .set('searchColumn', searchBy)
            .set('searchValue', searchValue)
            .set('pageNo', pageNo.toString())
            .set('pageSize', dataTablesParameters.length)
            .set('sortColumn', '')
            .set('sortOrder', '')
            .set('dateTimeForm', dateTimeFrom)
            .set('dateTimeTo', dateTimeTo);

        return this.http.get(`${this.apiUrl}/promocode/getallpromocode`, {
            params: searchParams
        })
            .pipe(map(data => {
                return data;
            }));
    }

    get( id ) {
        return this.http.get(`${this.apiUrl}/promocode/getpromocodebyid/${id}`)
            .pipe(map(data => {
                return data;
            }));
    }

    getCustomerList() {
        return this.http.get(`${this.apiUrl}/promocode/getstorecustomerlist`)
            .pipe(map(data => {
                return data;
            }));
    }

    add( formData ) {
        return this.http.post(`${this.apiUrl}/storepromocode/add`, formData)
            .pipe(map(data => {
                return data;
            }));
    }

    update( formData ) {
        return this.http.post(`${this.apiUrl}/storepromocode/edit`, formData)
            .pipe(map(data => {
                return data;
            }));
    }

    action( action, formData ) {
        if (action === 'add') {
            return this.add(formData);
        } else {
            return this.update(formData);
        }
    }

    delete(id: number) {
        return this.http.post(`${this.apiUrl}/storepromocode/delete/${id}`, {});
    }

    changeStatus(id: number, status: number) {
        return this.http.post(`${this.apiUrl}/storepromocode/updatestatus/${id}/${status}`, {});
    }

   
}
