import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "common/_helpers/auth.guard";
import { constEnv } from "common/constants.env";
import { ProfileComponent } from "./profile/profile/profile.component";
import { OrdersComponent } from "./orders/orders/orders.component";
import { AddressComponent } from "./address/address/address.component";
import { ReturnRequestComponent } from "./return-request/return-request.component";
import { SupportCancelOrderComponent } from "./support-cancel-order/support-cancel-order.component";

const routeUrl = constEnv.customerEndPoint;
const routes: Routes = [
  {
    path: `${routeUrl}`,
    children: [
      { path: "profile", component: ProfileComponent },
      // { path: 'orders' , component: OrdersComponent },
      { path: "address", component: AddressComponent },
      { path: "return-request-list", component: ReturnRequestComponent },
      {
        path: "cancel-order-request/:token",
        component: SupportCancelOrderComponent,
      },
      { path: "", redirectTo: `/${routeUrl}/profile`, pathMatch: "full" },
    ],
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomerRoutingModule {}
