export const constEnv = {
  projectTitle: "Vogue Veneer",
  storeEndPoint: "store",
  driverEndPoint: "driver",
  customerEndPoint: "customer",
  deliveryCompanyEndPoint: "delivery-company",
  designerEndPoint: "designer",
  defaultLang: "en-US",
  defaultCurrency: "USD",

  // googleMapAPIKey: "AIzaSyCLuiYBcHcOAFeskeqoWgwqJd5MxDuG4WU",
  googleMapAPIKey: "AIzaSyDfuPhBtkeJMctHCVpSisk67EOjsCyX_7U",
  googleSiteKey: "6LeOHv4ZAAAAAPPQJCuqkmPcjuPJn_kyK7XNNivw",
  googleAuthClientId:
    "289261855819-hvc5shnsnr6ntgmeqrqll68btrnggc76.apps.googleusercontent.com",
  facebookAppId: "931366767692724",
  // facebookAppId: "201400718198768",

  defaultLatitude: 51.673858,
  defaultLongitude: 7.815982,
  defaultZoom: 8,

  calanderTheme: "theme-default",

  BASE_URL: "/",
  STORE_URL: `/store`,
  DRIVER_URL: `/driver`,
  CUSTOMER_URL: `/customer`,
  DELIVERY_COMPANY_URL: `/delivery-company`,
  DESIGNER_URL: `/designer`,

  ASSETS_PATH: "./assets/",
  IMAGE_PATH: `./assets/images/`,
  LOGO_PATH: `./assets/images/logo/logo.png`,
  LOGO_SVG_PATH: `./assets/images/logo/vogue-logo.svg`,

  // apiBaseURL: "http://192.168.29.11:1931/",
  // apiBaseURL: "http://115.246.21.142:1931/",
  apiBaseURL: "http://service.vogueveneer.com/",
  // apiBaseURL: "http://115.246.21.142:3018/",
  apiLoginURL: `http://service.vogueveneer.com/token`,
  apiURL: `http://service.vogueveneer.com/api`,
  apiStoreURL: `http://service.vogueveneer.com/api/store`,
  apiDriverURL: `http://service.vogueveneer.com/api/driver`,
  apiCustomerURL: `http://service.vogueveneer.com/api/customer`,
  apiDeliveryCompanyURL: `http://service.vogueveneer.com/api/deliverycompany`,
  apiDesignerURL: `http://service.vogueveneer.com/api/Designer`,
  apiSiteURL: `http://service.vogueveneer.com/api/customerweb`,
  apiShopingCartURL: `http://service.vogueveneer.com/api/ShoppingCart`,

  deliveryCompanyActionUrl: "http://vogueveneer.com/admin",
  threeViewUrl: "http://vogueveneer.com/floor_plan",
};
