import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { IsLoadingService } from "@service-work/is-loading";

import { constEnv } from "common/constants.env";
import { DriverService } from "common/services/store/driver.service";

import { TitleService } from "common/services/title.service";
import {
  StoreDriver,
  StoreDriverDocument,
} from "common/_models/store/storeDriver";
import { AlertService } from "@full-fledged/alerts";
import { ModalDirective } from "ngx-bootstrap/modal";
import { first } from "rxjs/operators";

@Component({
  selector: "app-documents",
  templateUrl: "./documents.component.html",
  styleUrls: ["./documents.component.css"],
})
export class DocumentsComponent implements OnInit {
  config = constEnv;

  driverId: number;

  driverInfo: StoreDriver;

  @ViewChild("autoShownModal", { static: false })
  autoShownModal: ModalDirective;
  isModalShown = false;

  docForm: FormGroup;
  loading = false;
  submitted = false;

  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  fileUplaodName: string = null;
  dateFormat = "dd-MMM-yyyy";

  selectedDoc: StoreDriverDocument;

  listUrl = `${this.config.STORE_URL}/driver/lists`;

  constructor(
    private titleService: TitleService,
    private isLoadingService: IsLoadingService,
    private route: ActivatedRoute,
    private router: Router,
    private driver: DriverService,
    private alert: AlertService,
    private datePipe: DatePipe,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.titleService.setTitle("DriverDocuments");

    const id = this.route.snapshot.params.id;
    this.driverId = typeof id !== "undefined" ? id : 0;
    if (this.driverId <= 0) {
      this.router.navigate([this.listUrl]);
    }

    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }

    this.getDiverInfo();

    this.driverInfo = new StoreDriver();

    this.createDocumentForm();
  }

  getDiverInfo() {
    this.isLoadingService.add();
    this.driver
      .get(this.driverId)
      .pipe(first())
      .subscribe(
        (data) => {
          const res = data as DriverResponse;
          if (res.status === true) {
            this.driverInfo = new StoreDriver().deserialize(res.data);

            this.isLoadingService.remove();
          } else {
            this.alert.danger(res.message);
            this.isLoadingService.remove();
          }
        },
        (err) => {
          this.alert.danger(err.error.message);
          this.isLoadingService.remove();
        }
      );
  }

  createDocumentForm() {
    this.docForm = this.fb.group({
      fileUpload: ["", Validators.required],
      expiryDate: [""],
      docMgmtId: [""],
      docId: [0],
      driverId: [0],
    });
  }

  fileProgress(fileInput: any) {
    this.fileData = fileInput.target.files[0] as File;
    this.preview();
  }

  preview() {
    // Show preview
    const mimeType = this.fileData.type;
    const reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
      this.fileUplaodName = this.fileData.name;
    };
  }

  get f() {
    return this.docForm.controls;
  }

  showModal(driverDocument: StoreDriverDocument): void {
    this.isModalShown = true;
    this.selectedDoc = driverDocument;
    this.docForm.get("docMgmtId").setValue(this.selectedDoc.docMgmtId);
    this.docForm.get("docId").setValue(this.selectedDoc.id);
    this.docForm.get("driverId").setValue(this.selectedDoc.driverId);
    if (this.selectedDoc.isExpiryDateRequired) {
      this.docForm.get("expiryDate").setValidators([Validators.required]);
    }
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
    this.previewUrl = null;
    this.fileUplaodName = null;
    this.selectedDoc = null;
    this.docForm.get("expiryDate").setValidators(null);
    this.docForm.reset();
  }

  onSubmit() {
    this.submitted = true;

    if (this.docForm.invalid) {
      return;
    }

    const expiredDate = this.datePipe.transform(
      this.docForm.value.expiryDate,
      this.dateFormat
    );

    const formData = new FormData();
    // formData.append('Id', docId);
    formData.append("DocumentTypeId", this.docForm.value.docMgmtId);
    formData.append("DocumentExpiryDate", expiredDate);
    formData.append("Document", this.fileData);

    this.isLoadingService.add();
    this.driver.updateDocument(this.docForm.value.driverId, formData).subscribe(
      (data) => {
        if (data["status"] === true) {
          this.alert.success(data["message"]);
          this.isLoadingService.remove();
          this.hideModal();
          this.getDiverInfo();
        } else {
          this.alert.danger(data["message"]);
          this.isLoadingService.remove();
        }
      },
      (err) => {
        if (typeof err.error.Message !== "undefined") {
          this.alert.danger(err.error.Message);
        } else if (typeof err.error !== "undefined") {
          this.alert.danger(err.error.message);
        }
        this.isLoadingService.remove();
      }
    );
  }
}

interface DriverResponse {
  status: boolean;
  message: string;
  data: StoreDriver;
}
