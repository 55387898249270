import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentModule } from 'common/_components/components.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { MaterialModule } from 'common/modules/material.module';

import { DesignerRoutingModule } from './designer-routing.module';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';


@NgModule({
  declarations: [LoginComponent, ForgotPasswordComponent, ResetPasswordComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentModule,
    TranslateModule,
    DesignerRoutingModule,
    RouterModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    Ng2TelInputModule,
    NgxIntlTelInputModule,
    MaterialModule
  ]
})
export class DesignerModule { }
