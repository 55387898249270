import { Component, OnInit, ViewChild } from "@angular/core";

import { constEnv } from "common/constants.env";

import { DataTableDirective } from "angular-datatables";
import { Config } from "datatables.net";
import { TitleService } from "common/services/title.service";
import { first, filter } from "rxjs/operators";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { OrderService } from "common/services/store/order.service";
import { AlertService } from "@full-fledged/alerts";
import { Order } from "common/_models/store/order";
import { GeneralService } from "common/services/general.service";

@Component({
  selector: "app-orders",
  templateUrl: "./orders.component.html",
  styleUrls: ["./orders.component.css"],
})
export class OrdersComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;

  // dtOptions: DataTables.Settings = {};
  dtOptions: Config = {};

  orderFiltersOptions = [
    { optionKey: "OrderId", optionValue: "OrderNo" },
    // { optionKey: 'Customer', optionValue: 'CustomerName' },
  ];

  hasOrders = false;
  lists: Order[] = [];

  searchForm: FormGroup;
  submitted = false;
  maxDate: Date;

  dateFormat = "yyyy-MM-dd";

  config = constEnv;

  public defaultCurrency = "";

  constructor(
    private titleService: TitleService,
    private fb: FormBuilder,
    private order: OrderService,
    private alert: AlertService,
    private datePipe: DatePipe,
    private gs: GeneralService
  ) {
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate());
  }

  ngOnInit() {
    this.titleService.setTitle("Orders");
    this.defaultCurrency = this.gs.getDefaultCurrency();
    this.makeSearchFrom();
    this.createDataTable();
  }

  makeSearchFrom() {
    this.searchForm = this.fb.group({
      filterBy: [this.orderFiltersOptions[0].optionKey],
      filterText: [""],
      orderFilterDates: [""],
    });
  }

  createDataTable() {
    this.dtOptions = {
      pagingType: "full_numbers",
      ordering: false,
      lengthChange: false,
      searching: false,
      serverSide: true,
      processing: true,
      // pageLength: -1,
      language: {
        paginate: {
          previous: "<",
          first: "<<",
          last: ">>",
          next: ">",
        },
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.hasOrders = true;

        const searchparams = this.searchForm.value;
        if (searchparams.filterText !== "") {
          dataTablesParameters.search.column = searchparams.filterBy;
          dataTablesParameters.search.value = searchparams.filterText;
        }

        dataTablesParameters.search.dateTimeForm = "";
        dataTablesParameters.search.dateTimeTo = "";
        if (searchparams.orderFilterDates !== "") {
          const oDates = searchparams.orderFilterDates;
          dataTablesParameters.search.dateTimeForm = this.datePipe.transform(
            oDates[0],
            this.dateFormat
          );
          dataTablesParameters.search.dateTimeTo = this.datePipe.transform(
            oDates[1],
            this.dateFormat
          );
        }

        let paging: Paginate = null;

        this.order
          .getAllOrders(dataTablesParameters)
          .pipe(first())
          .subscribe(
            (data) => {
              const res = data as OrderListResponse;

              if (res.status === true) {
                this.lists = res.data.map((item) =>
                  new Order().deserialize(item)
                );

                paging = JSON.parse(res.paging) as Paginate;
              } else {
                paging = {
                  totalCount: 0,
                  totalRowsAfterFiltering: 0,
                } as Paginate;

                this.alert.danger(data["message"]);
                this.lists = [];
              }

              // if (data['status'] === true) {
              //   this.categoryLists = data['data'];
              //   if (this.categoryLists.length > 0) {
              //     this.hasCategory = true;
              //   } else {
              //     this.hasCategory = false;
              //   }
              //   paging = JSON.parse(data['paging']);
              //   // paging = { totalCount: this.categoryLists.length, totalRowsAfterFiltering: this.categoryLists.length };
              // } else {
              //   this.alert.danger(data['message']);
              //   this.categoryLists = [];
              //   this.hasCategory = false;
              // }

              // if (this.categoryLists.length > 0) {
              //   this.hasCategory = true;
              // }

              callback({
                recordsTotal: paging.totalCount,
                recordsFiltered: paging.totalRowsAfterFiltering,
                data: [],
              });

              // this.loader.hideLoader();
            },
            (err) => {
              // this.alert.danger(err.error.Message);
              // this.loader.hideLoader();
            }
          );

        callback({
          recordsTotal: 0,
          recordsFiltered: 0,
          data: [],
        });
      },
    };
  }

  onSubmit() {
    this.submitted = true;

    if (this.searchForm.invalid) {
      return;
    }

    // this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) =>
    this.datatableElement.dtInstance.then((dtInstance) => dtInstance.draw());
  }

  filterChange(selectedVal) {
    this.searchForm.get("filterText").setValue("");
  }

  resetFrom() {
    this.submitted = false;
    // this.searchForm.reset();

    // this.searchForm.get('filterBy').setValue( this.productFiltersOptions[0].optionKey );
    this.searchForm.get("filterText").setValue("");
    this.searchForm.get("orderFilterDates").setValue("");

    // this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) =>
    this.datatableElement.dtInstance.then((dtInstance) => dtInstance.draw());
  }
}

interface OrderListResponse {
  status: boolean;
  message: string;
  paging: string;
  data: Order[];
}

interface Paginate {
  totalCount: number;
  totalRowsAfterFiltering: number;
  pageSize: number;
  currentPage: number;
  totalPages: number;
  previousPage: string;
  nextPage: string;
}
