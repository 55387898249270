import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { DataTablesModule } from "angular-datatables";
import { FormsModule } from "@angular/forms";
import { MaterialModule } from "common/modules/material.module";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { ModalModule } from "ngx-bootstrap/modal";
import { TemplateDefaultModule } from "../../../shared/template-default/template-default.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { StarRatingModule } from "angular-star-rating";

import { JobRoutingModule } from "./job-routing.module";
import { ListComponent } from "./list/list.component";
import { JobDetailComponent } from "./job-detail/job-detail.component";
import { PipeModule } from "common/_pipe/pipe.module";

@NgModule({
  declarations: [ListComponent, JobDetailComponent],
  imports: [
    CommonModule,
    JobRoutingModule,
    CommonModule,
    TranslateModule,
    DataTablesModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    NgSelectModule,
    ModalModule.forRoot(),
    StarRatingModule.forRoot(),
    BrowserAnimationsModule,
    RouterModule,
    BsDatepickerModule,
    TemplateDefaultModule,
    PipeModule,
  ],
})
export class JobModule {}
