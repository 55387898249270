import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { constEnv } from 'common/constants.env';
import { LoaderService } from 'common/services/loader.service';
import { TitleService } from 'common/services/title.service';
import { AlertService } from 'ngx-alerts';

import { DataTableDirective } from 'angular-datatables';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ProductService } from 'common/services/store/product.service';
import { first } from 'rxjs/operators';
import { VariationInventory } from 'common/_models/store/variationInventory.model';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class InventoryComponent implements OnInit {

  config = constEnv;
  productListUrl: string;
  productId = 0;

  productVariationLists: VariationInventory[] = [];
  hasVariations = false;
  productInfo: ProductInfo = null;

  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};

  @ViewChild('autoShownModal', { static: false }) autoShownModal: ModalDirective;
  isModalShown = false;

  constructor(
    private alert: AlertService,
    private loader: LoaderService,
    private titleService: TitleService,
    private route: ActivatedRoute,
    private router: Router,
    private product: ProductService
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Product Inventory');

    this.productListUrl = `${this.config.STORE_URL}/product/lists`;

    const id = this.route.snapshot.params.id;
    this.productId = (typeof id !== 'undefined') ? id : 0;
    if (this.productId <= 0) {
      this.router.navigate([this.productListUrl]);
    }

    this.createDataTable();
  }

  createDataTable() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      ordering: false,
      lengthChange: false,
      searching: false,
      serverSide: true,
      processing: true,
      language: {
        paginate: {
          previous: '<',
          first: '<<',
          last: '>>',
          next: '>'
        }
      },
      ajax: (dataTablesParameters: any, callback) => {
        const pageNo = dataTablesParameters.start;
        const pageSize = dataTablesParameters.length;
        this.product.getProductVariationLists( this.productId, pageNo, pageSize )
          .pipe(first())
          .subscribe(resp => {
            console.log(resp);
            const data = resp as VariationListsResponse;
            let paging: any;
            if (data.status === true) {
              this.productVariationLists = data.data.map( list => new VariationInventory().deserialize(list) );
              this.productInfo = data.ProductInfo;
              paging = { totalCount: this.productVariationLists.length, totalRowsAfterFiltering: this.productVariationLists.length };
            } else {
              paging = { totalCount: 0, totalRowsAfterFiltering: 0 };
              this.productVariationLists = [];
              this.hasVariations = false;
            }

            if (this.productVariationLists.length > 0) {
              this.hasVariations = true;
            }
            callback({
              recordsTotal: paging.totalCount,
              recordsFiltered: paging.totalRowsAfterFiltering,
              data: []
            });
          },
          err => {
            if (typeof err.error !== 'undefined') {
              const msg = (typeof err.error.Message !== 'undefined') ? err.error.Message : err.error.message;
              this.alert.danger(msg);
              this.hasVariations = false;
              this.productVariationLists = [];
            }

            callback({
              recordsTotal: 0,
              recordsFiltered: 0,
              data: []
            });

            this.loader.hideLoader();
          });
      },
    };
  }

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }
}

interface VariationListsResponse {
  status: boolean;
  message: string;
  ProductInfo: ProductInfo;
  data: VariationInventory[];
  paging: string;
}

interface ProductInfo {
  productId: number;
  productName: string;
  productImage: string;
  variationName: string;
  stockQuantity: number;
}
