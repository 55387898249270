import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { IsLoadingService } from "@service-work/is-loading";
import { constEnv } from "common/constants.env";
import { TitleService } from "common/services/title.service";

import { ChartOptions, ChartType, ChartDataSets } from "chart.js";
import * as pluginDataLabels from "chartjs-plugin-datalabels";
import {
  Label,
  monkeyPatchChartJsLegend,
  monkeyPatchChartJsTooltip,
  SingleDataSet,
} from "ng2-charts";
import { StoreUserService } from "common/services/store/storeUser.service";
import { AlertService } from "ngx-alerts";
import { Dashboard } from "common/_models/store/dashboard";
import { GeneralService } from "common/services/general.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  configConstants = constEnv;

  dashboardData: Dashboard = null;

  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 20,
        bottom: 0,
      },
    },
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      xAxes: [{}],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        anchor: "end",
        align: "end",
      },
    },
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = "bar";
  public barChartLegend = false;
  public barChartPlugins = [pluginDataLabels];

  public barChartData: ChartDataSets[] = [
    {
      data: [],
      label: "Months",
      backgroundColor: "#337cfe",
      borderColor: "#337cfe",
      hoverBackgroundColor: "#337cfe",
    },
  ];

  // line chart
  public lineChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 30,
        bottom: 0,
      },
    },
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      xAxes: [{}],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        anchor: "end",
        align: "end",
      },
    },
  };

  public lineChartPlugins = [pluginDataLabels];
  public lineChartLegend = false;
  public lineChartType: ChartType = "line";
  public lineChartLabels: Label[] = [];
  public lineChartData: ChartDataSets[] = [
    {
      data: [],
      label: "Months",
      backgroundColor: "rgba(255,255,255,0)",
      borderColor: "#2196f3",
      // hoverBackgroundColor: '#337cfe'
    },
  ];

  //Pic Chart
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: "left",
      align: "center",
    },
  };
  public pieChartType: ChartType = "doughnut";
  public pieChartLegend = true;
  public pieChartPlugins = [];

  public orderPieChartLabels: Label[] = [];
  public productPieChartLabels: Label[] = [];
  public orderPieChartData: SingleDataSet = [];
  public productPieChartData: SingleDataSet = [];

  public repeatCustomerLabel: Label[] = [];
  public repeatCustomerData: SingleDataSet[] = [];

  public defaultCurrency = "";

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private isLoadingService: IsLoadingService,
    private titleService: TitleService,
    private storeUser: StoreUserService,
    private alert: AlertService,
    private gs: GeneralService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Dashboard");
    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }
    this.defaultCurrency = this.gs.getDefaultCurrency();

    this.loadDashboard();
  }

  loadDashboard() {
    this.isLoadingService.add();
    this.storeUser.getStoreDashboard().subscribe(
      (data) => {
        const res = data as DashboardResponse;
        if (res.status === true) {
          this.dashboardData = new Dashboard().deserialize(res.data);

          this.barChartLabels = this.dashboardData.orderStatusticsData.month;
          this.barChartData[0].data =
            this.dashboardData.orderStatusticsData.value;

          const lineLables = [];
          const lineChartData = [];
          this.dashboardData.orderSalesData.forEach((single) => {
            lineLables.push(single.month);
            lineChartData.push(single.orderCount);
          });

          this.lineChartLabels = lineLables;
          this.lineChartData[0].data = lineChartData;

          const orderPieChartData = [];
          const orderPieChartLabels = [];
          for (var key in this.dashboardData.orderStatusCounter) {
            if (
              this.dashboardData.orderStatusCounter.hasOwnProperty(key) &&
              key !== "total"
            ) {
              orderPieChartLabels.push(key.toUpperCase());
              orderPieChartData.push(
                this.dashboardData.orderStatusCounter[key]
              );
            }
          }
          this.orderPieChartLabels = orderPieChartLabels;
          this.orderPieChartData = orderPieChartData;

          const productChartLabels = [];
          const productChartData = [];
          for (var key in this.dashboardData.productCounter) {
            if (
              this.dashboardData.productCounter.hasOwnProperty(key) &&
              key !== "total"
            ) {
              productChartLabels.push(key.toUpperCase());
              productChartData.push(this.dashboardData.productCounter[key]);
            }
          }

          this.productPieChartData = productChartData;
          this.productPieChartLabels = productChartLabels;

          const repeatCustLabels = [];
          const reapeatCustData = [];
          for (var key in this.dashboardData.repeatCustomerCounter) {
            if (
              this.dashboardData.repeatCustomerCounter.hasOwnProperty(key) &&
              key !== "total"
            ) {
              repeatCustLabels.push(key.toUpperCase());
              reapeatCustData.push(
                this.dashboardData.repeatCustomerCounter[key]
              );
            }
          }
          this.repeatCustomerData = reapeatCustData;
          this.repeatCustomerLabel = repeatCustLabels;
        } else {
          this.alert.danger(res.message);
        }

        this.isLoadingService.remove();
      },
      (err) => {
        if (typeof err.error !== "undefined") {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          // this.alert.danger(msg);
        }
        this.isLoadingService.remove();
      }
    );
  }
}

interface DashboardResponse {
  status: boolean;
  message: string;
  data: Dashboard;
}
