import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from 'common/_helpers/auth.guard';
import { constEnv } from 'common/constants.env';
import { EarningsComponent } from './earnings/earnings.component';

const STORE_ENDPOINT = `${constEnv.storeEndPoint}`;

const routes: Routes = [
   {
      path: `${STORE_ENDPOINT}/earnings`, component: EarningsComponent, canActivate: [AuthGuard]
   },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class EarningsRoutingModule { }
