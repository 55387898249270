import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'common/modules/material.module';
import { ComponentModule } from 'common/_components/components.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TemplateDefaultModule } from '../../../shared/template-default/template-default.module';

import { EditComponent } from './edit/edit.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DataTablesModule } from 'angular-datatables';

import { AttributesComponent } from './attributes/attributes.component';
import { VariationsComponent } from './variations/variations.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ColorPickerModule } from 'ngx-color-picker';
import { MyDirectiveModule } from 'common/directives/my-directive.module';



@NgModule({
  declarations: [EditComponent, AttributesComponent, VariationsComponent],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    TranslateModule.forRoot(),
    ReactiveFormsModule,
    TemplateDefaultModule,
    MaterialModule,
    AngularMultiSelectModule,
    ModalModule.forRoot(),
    DataTablesModule,
    NgSelectModule,
    ComponentModule,
    ColorPickerModule,
    MyDirectiveModule
  ]
})
export class EditProductModule { }
