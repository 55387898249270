import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { constEnv } from "common/constants.env";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class FeedbackService {
  configConstant = constEnv;
  constructor(private http: HttpClient) {}

  getFeedbacks(type: number, dataTablesParameters) {
    // let searchValue = "";
    // let searchBy = "";
    // if (dataTablesParameters.search.value !== "") {
    //   searchValue = dataTablesParameters.search.value;
    //   searchBy = dataTablesParameters.search.column;
    // }

    const pageNo =
      (dataTablesParameters.start * 1) / (dataTablesParameters.length * 1);

    const searchParams = new HttpParams()
      // .set('searchColumn', searchBy)
      // .set('searchValue', searchValue)
      .set("PageNo", pageNo.toString())
      .set("PageSize", dataTablesParameters.length);
    // .set('sortColumn', '')
    // .set('sortOrder', '');

    return this.http
      .get(`${this.configConstant.apiStoreURL}/getfeedback?Id=${type}`, {
        params: searchParams,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getStoreReviews(pageNo, pageSize) {
    const searchParams = new HttpParams()
      .set("PageNo", pageNo.toString())
      .set("PageSize", pageSize.toString());

    return this.http
      .get(`${this.configConstant.apiStoreURL}/getreview`, {
        params: searchParams,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
