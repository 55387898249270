/**
 * Copyright 2019 Jim Armstrong (www.algorithmist.net)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  SimpleChanges,
  Output,
  EventEmitter,
} from "@angular/core";

import { getCurrentOffset } from "./libs/map-libs";

import { MapIconOptions } from "./data/map-icon-options";

import { EventHandler } from "./interfaces/event-handler";
import { INIT_COORDS } from "./map-tokens";

import * as esri from "esri-leaflet";
import * as L from "leaflet";
import * as ELG from "esri-leaflet-geocoder";
import { AddressInfo } from "./interfaces/address-info";

/**
 * Leaflet Map Component
 *
 * @author Jim Armstrong (www.algorithmist.net)
 *
 * @version 1.0
 */
@Component({
  selector: "app-map",

  templateUrl: "./map.component.html",

  styleUrls: ["./map.component.scss"],
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {
  public mcText: string; // mouse coords text (innerHTML)

  @Input()
  public markers: {
    lat: number;
    lng: number;
    label: string;
    draggable: boolean;
  }[]; // Markers to overlay on Map

  @Input()
  public currentWidth: string = ""; // current map width based on window width
  @Input()
  public currentHeight: string = ""; // current map height based on window height

  @Input() public latitude: number;
  @Input() public longitude: number;

  @Input() public currentBrowserLocation: boolean = false;
  currentLocation: boolean = false;

  protected baseLayer: any; // Map Base layer
  protected map: any; // Map reference (currently leaflet)
  protected mapLoaded = false; // True if the map has been loaded

  markerLatLongs = [];

  @Output() setAutoCompleteResult = new EventEmitter<any>();

  // The primary Map
  @ViewChild("primaryMap", { static: true }) protected mapDivRef: ElementRef;
  protected mapDiv: HTMLDivElement;

  // Leaflet Map Event Handlers (used for removal on destroy)
  protected onClickHandler: EventHandler;
  protected onMouseMoveHandler: EventHandler;

  mapLayerGroup;

  constructor(
    @Inject(INIT_COORDS) protected _initCoords: { lat: number; long: number }
  ) {
    this.baseLayer = null;

    // Leaflet Map Event Handlers
    this.onClickHandler = (evt: any) => this.__onMapClick(evt);
    this.onMouseMoveHandler = (evt: any) => this.__onMapMouseMove(evt);

    // Initial mouse-coords text
    this.mcText = "";

    // some simple default values
    // this.currentWidth = 600;
    // this.currentHeight = 200;
  }

  public ngOnInit(): void {
    // Reference to DIV containing map is used in Leaflet initialization
    this.mapDiv = this.mapDivRef.nativeElement;

    this.__setInitCoords();
    this.__initializeMap();
    this.__renderMap();
    // this.__showMarkers();
    if (this.currentBrowserLocation) {
      this.__getCurrentLocation();
    }
  }

  public ngAfterViewInit(): void {
    this.map.invalidateSize();

    this.__initMapHandlers();
    this.__showMarkers();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.__showMarkers();
  }

  public ngOnDestroy(): void {
    this.map.off("click", this.onClickHandler);
    this.map.off("mousemove", this.onMouseMoveHandler);
  }

  /**
   * Basic map initialization
   */
  protected __initializeMap(): void {
    if (this.mapLoaded) {
      return;
    }

    this.mapLoaded = true;

    this.__updateMapSize();
  }

  protected __setInitCoords() {
    this._initCoords.lat = this.latitude ? this.latitude : this._initCoords.lat;
    this._initCoords.long = this.longitude
      ? this.longitude
      : this._initCoords.long;
  }

  /**
   * Render the map (establish center and base layer)
   */
  protected __renderMap(): void {
    // Create Leaflet Map in fixed DIV - zoom level is hardcoded for simplicity
    this.map = L.map(this.mapDiv, {
      zoomControl: false,
      zoomAnimation: false,
      trackResize: true,
      boxZoom: true,
      zoomSnap: 0.25,
    }).setView([this._initCoords.lat, this._initCoords.long], 8);

    // this.baseLayer = esri.basemapLayer("Gray");
    // this.map.addLayer(this.baseLayer);
    // const lGroup = L.layerGroup().addTo(this.map);
    const tiles = L.tileLayer(
      "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      // "https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png",
      // "https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png",
      {
        maxZoom: 19,
        attribution: "&copy;",
      }
    ).addTo(this.map);

    this.mapLayerGroup = new L.LayerGroup().addTo(this.map);

    // console.log(ELG.WorldGeocodingServiceUrl);

    this.searchAddressControl();
  }

  /**
   * Show markers if they are defined
   */
  protected __showMarkers(): void {
    if (
      this.markers !== undefined &&
      this.markers != null &&
      this.markers.length > 0
    ) {
      // Add markers
      // const icon = L.icon({
      //   iconUrl: MapIconOptions.mapIcon,
      //   iconSize: MapIconOptions.iconSize as L.PointExpression,
      //   iconAnchor: MapIconOptions.iconAnchor as L.PointExpression,
      //   shadowUrl: MapIconOptions.mapShadowIcon,
      //   shadowSize: MapIconOptions.shadowSize as L.PointExpression,
      //   shadowAnchor: MapIconOptions.shadowAnchor as L.PointExpression,
      // });

      const n: number = this.markers.length;
      let i: number;
      // let m: L.marker;

      let x: number;
      let y: number;
      let l: string;
      let d: boolean;

      this.clearLayers();
      for (i = 0; i < n; ++i) {
        x = this.markers[i].lat;
        y = this.markers[i].lng;
        l = this.markers[i].label;
        d = this.markers[i].draggable;

        if (x !== undefined && !isNaN(x) && y !== undefined && !isNaN(y)) {
          // okay to add the icon
          // m = L.marker([x, y], { icon: icon }).addTo(this.map);
          this.createMarker(x, y, l, d);
        } else {
          // implement your own error handling
          console.log(
            "MARKER ERROR, Marker number: ",
            i + 1,
            "x: ",
            x,
            " y: ",
            y
          );
        }
      }
      this.setCenterMarkerMap(16);
    }
  }

  createMarker(
    lat: number,
    long: number,
    popupContent: string = "",
    draggable: boolean = false
  ): void {
    const icon = this.getMarkerIcon();

    let m: L.marker;

    m = L.marker([lat, long], { icon: icon, draggable: draggable }).addTo(
      this.map
    );
    if (popupContent) {
      m.bindPopup(popupContent);
    }
    this.mapLayerGroup.addLayer(m);

    const that = this;
    m.on("dragend", function (data) {
      that.getAddressFromLatLng(m);
    });

    if (this.currentLocation) {
      this.getAddressFromLatLng(m);
    }

    this.markerLatLongs.push(m.getLatLng());
  }

  setCenterMarkerMap(zoomLevel: number = 8) {
    var markerBounds = L.latLngBounds(this.markerLatLongs);
    //console.log(markerBounds);
    this.map.fitBounds(markerBounds, { padding: [50, 50] });
    if (this.markerLatLongs.length === 1) this.map.setZoom(zoomLevel);
  }

  clearLayers() {
    this.mapLayerGroup.clearLayers();
  }

  getMarkerIcon() {
    const icon = L.icon({
      iconUrl: MapIconOptions.mapIcon,
      iconSize: MapIconOptions.iconSize, // as L.PointExpression,
      // iconAnchor: MapIconOptions.iconAnchor as L.PointExpression,
      // shadowUrl: MapIconOptions.mapShadowIcon,
      // shadowSize: MapIconOptions.shadowSize,
      // shadowAnchor: MapIconOptions.shadowAnchor as L.PointExpression,
    });

    return icon;
  }

  protected __getCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;

        this.currentLocation = true;
        this.createMarker(lat, lng, "", true);
      });
    }
  }

  @HostListener("window:resize", ["$event"])
  __onResize(event: any): void {
    this.__updateMapSize();

    this.map.invalidateSize();
  }

  /**
   * Update the current width/height occupied by the map
   */
  protected __updateMapSize(): void {
    // update width/height settings as you see fit
    this.currentWidth =
      this.currentWidth === ""
        ? (
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth
          ).toString()
        : this.currentWidth;
    this.currentHeight =
      this.currentHeight === ""
        ? (
            (window.innerHeight ||
              document.documentElement.clientHeight ||
              document.body.clientHeight) - 200
          ).toString()
        : this.currentHeight;
  }

  /**
   * Initialize Leaflet Map handlers
   */
  protected __initMapHandlers(): void {
    this.map.on("mousemove", this.onMouseMoveHandler);
    this.map.on("click", this.onClickHandler);
  }

  /**
   * Execute on Leaflet Map click
   */
  protected __onMapClick(evt: any): void {
    const target: any = evt.originalEvent.target;

    //console.log("Map click on: ", target);
  }

  /**
   * Execute on mouse move over Leaflet map
   *
   * @param evt Leaflet-supplied information regarding current mouse point, mainly geo coords.
   */
  protected __onMapMouseMove(evt: any): void {
    const offset: { x: number; y: number } = getCurrentOffset(this.map);

    // uncomment to study offset
    // console.log('offset computation:', offset);

    // Lat and Long are embedded in the event object
    const lat: string = evt.latlng.lat.toFixed(3);
    const long: string = evt.latlng.lng.toFixed(3);
    this.mcText = `Latitude: ${lat} &nbsp; &nbsp; Longitude: ${long}`;
  }

  searchAddressControl() {
    // const searchControl = ELG.geosearch().addTo(this.map);
    const searchControl = ELG.geosearch({
      //here we call the input id.
      useMapBounds: false,
      providers: [
        ELG.arcgisOnlineProvider({
          maxResults: 10,
        }),
      ],
      placeholder: "Search for an address",
      title: "Address Search",
      // searchBounds: searchBounds, // limit search results within these coordinates
      zoomToResult: true,
      notFoundMessage: "Not found",
      position: "topright",
    });

    const that = this;
    searchControl
      .on("results", function (data) {
        that.mapLayerGroup.clearLayers();
        for (let i = data.results.length - 1; i >= 0; i--) {
          that.createMarker(
            data.results[i].latlng.lat,
            data.results[i].latlng.lng,
            data.results[i].text,
            true
          );
          // that.mapLayerGroup.addLayer(
          //   L.marker(data.results[i].latlng, {
          //     icon: that.getMarkerIcon(),
          //     draggable: true,
          //   })
          //     .addTo(that.map)
          //     .bindPopup(data.results[i].text)
          // );
          that.setAutoCompleteResult.emit(data.results[i] as AddressInfo);
        }
      })
      .addTo(this.map);
  }

  getAddressFromLatLng(marker: any) {
    let that = this;
    ELG.reverseGeocode()
      .latlng(marker.getLatLng())
      .language("eng")
      .run(function (error, result) {
        if (!error) {
          const addressInfo: AddressInfo = {
            bounds: [],
            properties: result.address,
            latlng: result.latlng,
            score: 0,
            text: result.address.LongLabel,
          };

          marker.bindPopup(addressInfo.text);
          that.markerLatLongs = [];
          that.markerLatLongs.push(marker.getLatLng());
          that.setCenterMarkerMap(16);
          that.setAutoCompleteResult.emit(addressInfo);
        }
      });
  }
}
