import { Injectable, NgZone } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Router } from "@angular/router";
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  UserCredential,
} from "firebase/auth";
import { Observable, from } from "rxjs";
import { tap, catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class FirebaseAuthService {
  userData: any;

  public providerType = {
    GOOGLE: "google",
    FB: "facebook",
    APPLE: "apple",
  };

  constructor(
    private afAuth: AngularFireAuth,
    private ngZone: NgZone,
    private router: Router
  ) {
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.userData = user;
        localStorage.setItem("user", JSON.stringify(this.userData));
      } else {
        localStorage.setItem("user", "null");
      }
    });
  }

  /**
   * Perform authentication using a given provider
   */
  AuthLogin(provider: any): Observable<UserCredential | any> {
    // this.isLoadingService.add();

    return from(this.afAuth.signInWithPopup(provider)).pipe(
      tap((result) => {
        // console.log("Auth login", result.additionalUserInfo.profile);
        return result.user.toJSON();
        // this.setUserData(result.user);
      }),
      catchError((error) => {
        // console.error("Authentication error:", error.message);
        throw error;
      })
      //   finalize(() => this.isLoadingService.remove())
    );
  }

  /**
   * Sign in with Google
   */
  GoogleAuth(): Observable<any> {
    const provider = new GoogleAuthProvider();
    return this.AuthLogin(provider).pipe(
      tap((res) => {}),
      catchError((error) => {
        console.error("Google login failed:", error.message);
        throw error;
      })
    );
  }

  /**
   * Sign in with Facebook
   */
  FacebookAuth(): Observable<any> {
    const provider = new FacebookAuthProvider();
    return this.AuthLogin(provider).pipe(
      tap((res) => {}),
      catchError((error) => {
        console.error("Facebook login failed:", error.message);
        throw error;
      })
    );
  }

  /**
   * Sign in with Apple
   */
  AppleAuth(): Observable<any> {
    const provider = new OAuthProvider("apple.com");
    provider.addScope("name email");
    // provider.addScope("name");
    return this.AuthLogin(provider).pipe(
      tap((res) => {}),
      catchError((error) => {
        console.error("Apple login failed:", error.message);
        throw error;
      })
    );
  }

  /**
   * Sign out the current user
   */
  SignOut(): Observable<void> {
    return from(this.afAuth.signOut()).pipe(
      tap(() => {
        // localStorage.removeItem("user");
        this.ngZone.run(() => this.router.navigate(["sign-in"]));
      }),
      catchError((error) => {
        console.error("Sign out failed:", error.message);
        throw error;
      })
    );
  }
}
