import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import {
  catchError,
  distinctUntilChanged,
  first,
  switchMap,
  tap,
} from "rxjs/operators";
import {
  Dimensions,
  ImageCroppedEvent,
  ImageTransform,
} from "ngx-image-cropper";
import { base64ToFile } from "ngx-image-cropper";
import { Observable, of, Subject, Subscription } from "rxjs";

import { constEnv } from "common/constants.env";
import { LoaderService } from "common/services/loader.service";
import { TitleService } from "common/services/title.service";
import { AlertService } from "ngx-alerts";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "common/services/language.service";
import { ModalDirective } from "ngx-bootstrap/modal";
import { IsLoadingService } from "@service-work/is-loading";
import {
  ProductService,
  Specification,
} from "common/services/store/product.service";
import { ProductBrand } from "common/_models/store/productBrand.model";
import { ProductCategory } from "common/_models/store/productCategory.model";
import { ProductLabel } from "common/_models/store/productLabel.model";
import { ProductTag } from "common/_models/store/productTag.model";
import { ProductType } from "common/_models/store/productType.model";
import {
  LinkedProducts,
  Product,
  ProductLinked,
  ProductSpecification,
} from "common/_models/store/product.model";
import { NgSelectComponent } from "@ng-select/ng-select";
import { ConfirmDialogComponent } from "common/_components/confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { ProductTheme } from "common/_models/store/productTheme.model";
import { ProductColorPalette } from "common/_models/store/productColorPalette.model";
import { ProductServiceType } from "common/_models/store/productServiceType.model";
import { CustomValidator } from "common/_helpers/custom.validator";
import { SpecificationService } from "common/services/store/specification.service";

@Component({
  selector: "app-edit",
  templateUrl: "./edit.component.html",
  styleUrls: ["./edit.component.css"],
})
export class EditComponent implements OnInit {
  config = constEnv;
  productEditUrl: string;
  productListUrl: string;

  specificationForm: FormGroup;
  editProductForm: FormGroup;
  loading = false;
  sloading = false;
  submitted = false;

  @ViewChild("primaryFileInput", { static: true }) primaryFileInput: ElementRef;
  primaryFileData: File = null;
  primaryPreviewUrl: any = null;

  @ViewChild("secondaryFileInput", { static: true })
  secondaryFileInput: ElementRef;
  secondaryFileData: File[] = [];
  secondaryPreviewUrl: any = [];
  secondaryImageUrl: any = [];
  removeSecondaryImageIds: number[] = [];

  removeProductTagIds: number[] = [];

  @ViewChild("topViewInput", { static: true }) topViewInput: ElementRef;
  topViewInputData: File = null;
  topViewPreviewUrl: any = null;
  topViewError = "";
  topViewUploadedFile: string = "";
  topViewUploadedFileId: number = 0;

  @ViewChild("augmentedViewInput", { static: true })
  augmentedViewInput: ElementRef;
  augmentedViewInputData: File = null;
  augmentedViewPreviewUrl: any = null;
  augmentedViewError = "";
  augmentedViewUploadedFile: string = "";
  augmentedViewUploadedFileId: number = 0;

  @ViewChild("threeDViewInput", { static: true }) threeDViewInput: ElementRef;
  threeDViewInputData: File = null;
  threeDViewPreviewUrl: any = null;
  threeDViewError = "";
  threeDViewUploadedFile: string = "";
  threeDViewUploadedFileId: number = 0;

  defaultLanguage: any;
  allLanguages: any;
  availableLangs = [];

  productId = 0;
  productData: Product;
  productCatId = 0;

  productSpecifications: ProductSpecification[] = [];
  hideSpecificationFieldOnPopup = false;
  specPopupTitle = "";

  isHideInvenrory = false;

  dropDownValues: DropDownValues;
  subCategories: ProductCategory[] = [];

  recommandedProducts$: Observable<ProductLinked[]>;
  recommandedProductsLoading = false;
  recommandedProductsInput$ = new Subject<string>();
  selectedRecommandedProducts: ProductLinked[] = [];

  crossSellProducts$: Observable<ProductLinked[]>;
  crossSellProductsLoading = false;
  crossSellProductsInput$ = new Subject<string>();
  selectedCrossSellProducts: ProductLinked[] = [];

  removeLinkedProduct: LinkedProducts;

  @ViewChild("autoShownModal", { static: false })
  autoShownModal: ModalDirective;
  isModalShown = false;

  specificationLists: Specification[];
  specificationListValues: Specification[];

  @ViewChild("ngSelectComponent", { static: true })
  ngSelectComponent: NgSelectComponent;
  @ViewChild("ngCrossProductComponent", { static: true })
  ngCrossProductComponent: NgSelectComponent;

  sellingPrice: Number = 0;

  PRIMARY_IMAGE = 1;
  SECONDARY_IMAGE = 2;
  TOPVIEW_IMAGE = 4;
  AUGMENTED_VIEW = 5;
  THREE_VIEW = 6;

  linkedProductMaxLimit = 10;
  specificationLength: string;
  // @ViewChild(NgSelectComponent,{static:false}) ngSelectComponent: NgSelectComponent;

  currentDate = new Date();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private alert: AlertService,
    private titleService: TitleService,
    private translate: TranslateService,
    private isLoadingService: IsLoadingService,
    private ls: LanguageService,
    private product: ProductService,
    private dialog: MatDialog,
    private specification: SpecificationService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Edit Product");

    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }

    this.productEditUrl = `${this.config.STORE_URL}/product/edit`;
    this.productListUrl = `${this.config.STORE_URL}/product/lists`;

    const id = this.route.snapshot.params.id;
    this.productId = typeof id !== "undefined" ? id : 0;
    if (this.productId <= 0) {
      this.router.navigate([this.productListUrl]);
    }

    this.dropDownValues = {
      categoryLists: [],
      brands: [],
      productTags: [],
      productLabels: [],
    } as DropDownValues;

    this.removeLinkedProduct = {
      recommendedProduct: [],
      crossSellProduct: [],
    } as LinkedProducts;

    this.secondaryImageUrl = [];

    this.defaultLanguage = this.ls.getDefaultLanguage();
    this.allLanguages = this.ls.getAvailableLanguages();
    this.makeForm();
    this.loadDropDownValues();
    this.getProductDetail();
  }

  loadDropDownValues() {
    this.isLoadingService.add();
    this.product.getDropDownValues().subscribe((data) => {
      const dataValues = data["data"];

      this.dropDownValues = this.product.getProductDropDownModels(
        dataValues
      ) as DropDownValues;

      this.isLoadingService.remove();
    });
  }

  getProductDetail() {
    this.isLoadingService.add();
    this.product.get(this.productId).subscribe(
      (data) => {
        if (data["status"] === true) {
          this.productData = data["data"] as Product;
          this.setFormValue(this.productData);
          this.isLoadingService.remove();
        } else {
          this.alert.danger(data["message"]);
          this.router.navigate([this.productListUrl]);
        }
      },
      (err) => {
        if (typeof err.error !== "undefined") {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          this.alert.danger(msg);
        }
        this.router.navigate([this.productListUrl]);
        this.isLoadingService.remove();
      }
    );
  }

  makeForm() {
    this.editProductForm = this.fb.group(
      {
        productId: [null],
        productType: [null, Validators.required],
        serviceTypeId: [null, Validators.required],
        categoryId: [null, Validators.required],
        subCategory: [null],
        productName: ["", Validators.required],
        description: [""],
        defaultLanguageId: [""],
        brandId: [null, Validators.required],
        productTags: [null],
        itemTagId: [null],
        price: ["", Validators.required],
        offerAmount: [""],
        primaryImage: null,
        secondaryImage: null,
        videoUrl: [""],
        theme: [null, Validators.required],
        colorPalette: [null, Validators.required],
        isFragile: [null],
        isReturnable: [null],
        IsAssemblyRequired: [null],
        AssemblyCharges: [0],

        languagesArray: this.fb.array([]),
        productDimensions: this.fb.group({
          weight: [null, Validators.required],
          length: [null, Validators.required],
          width: [null, Validators.required],
          height: [null, Validators.required],
        }),
        inventory: this.fb.group({
          sku: null,
          qty: null,
          productStock: null,
          soldIndividually: null,
        }),
        shipping: this.fb.group({
          processingTime: null,
          weight: null,
          length: null,
          width: null,
          height: null,
        }),
        extra: this.fb.group({
          manufacturingDate: null,
          warranty: null,
          isFreeReturnPolicy: null,
          AdditionalPackingCharges: null,
          SpaceTypeId: null,
          // isInstallmentAvailable: null,
        }),
        linkedProducts: this.fb.group({
          recommendedProduct: null,
          crossSellProduct: null,
        }),
      },
      {
        validators: [
          // CustomValidator.OnlyZeroNotAllowed("AssemblyCharges"),
          CustomValidator.OnlyZeroNotAllowed("productDimensions.weight"),
          CustomValidator.OnlyZeroNotAllowed("productDimensions.length"),
          CustomValidator.OnlyZeroNotAllowed("productDimensions.width"),
          CustomValidator.OnlyZeroNotAllowed("productDimensions.height"),
          CustomValidator.OnlyZeroNotAllowed("shipping.weight"),
          CustomValidator.OnlyZeroNotAllowed("shipping.length"),
          CustomValidator.OnlyZeroNotAllowed("shipping.width"),
          CustomValidator.OnlyZeroNotAllowed("shipping.height"),
        ],
      }
    );

    this.addAvailableLanguages();

    this.specificationForm = this.fb.group({
      productId: [this.productId],
      specification: [null, Validators.required],
      specificationValue: [null, Validators.required],
      deletedSpecificationValue: [null],
    });
  }

  setFormValue(productData: Product) {
    this.editProductForm
      .get("serviceTypeId")
      .setValue(productData.serviceTypeId);
    this.editProductForm.get("productId").setValue(productData.productId);
    this.editProductForm.get("productType").setValue(productData.itemTypeId);
    this.editProductForm.get("categoryId").setValue(productData.categoryId);
    this.editProductForm.get("brandId").setValue(productData.brandId);
    this.editProductForm
      .get("itemTagId")
      .setValue(productData.itemTagId !== 0 ? productData.itemTagId : null);
    this.editProductForm.get("productTags").setValue(productData.productTagIds);
    this.editProductForm.get("price").setValue(productData.productPrice);
    this.editProductForm
      .get("offerAmount")
      .setValue(productData.productOfferAmt);
    this.editProductForm.get("videoUrl").setValue(productData.videoURL);
    this.editProductForm.get("theme").setValue(productData.themeId);
    this.editProductForm
      .get("colorPalette")
      .setValue(productData.colorPaletteId);
    this.editProductForm.get("isFragile").setValue(productData.isFragile);
    this.editProductForm.get("isReturnable").setValue(productData.isReturnable);

    this.editProductForm
      .get("IsAssemblyRequired")
      .setValue(productData.isAssemblyRequired);
    this.editProductForm
      .get("AssemblyCharges")
      .setValue(productData.assemblyCharges);

    const itemType = productData.itemTypeId;
    if (itemType === 2) {
      this.editProductForm.get("price").setValidators(null);
      this.editProductForm.get("offerAmount").setValidators(null);
      // this.editProductForm.get("sellingPrice").setValidators(null);
      this.editProductForm.get("price").updateValueAndValidity();
      this.editProductForm.get("offerAmount").updateValueAndValidity();
      // this.editProductForm.get("sellingPrice").updateValueAndValidity();
    }

    this.sellingPrice = 0;
    const price = productData.productPrice;
    const per =
      productData.productOfferAmt > 0 ? productData.productOfferAmt : 0;
    if (per > 0) {
      this.sellingPrice = price - (price * per) / 100;
    } else {
      this.sellingPrice = productData.productPrice;
    }

    this.product
      .getSubCategortLists(productData.categoryId)
      .subscribe((data) => {
        if (data["data"] !== null) {
          const dataValues = data["data"];

          this.subCategories = dataValues;

          this.editProductForm
            .get("subCategory")
            .setValidators(Validators.required);

          if (this.subCategories.length > 0) {
            this.editProductForm
              .get("subCategory")
              .setValue(productData.subCategoryId);
          }
        } else {
          this.subCategories = [];
        }
        this.isLoadingService.remove();
      });

    // this.editProductForm.controls.productType.disable();
    this.editProductForm.controls.categoryId.disable();

    this.isHideInvenrory = productData.itemTypeId === 2 ? true : false;

    this.editProductForm.patchValue({
      inventory: {
        sku: productData.inventory !== null ? productData.inventory.sku : null,
        qty: productData.inventory !== null ? productData.inventory.qty : null,
        productStock:
          productData.inventory !== null
            ? productData.inventory.productStock
            : null,
        soldIndividually:
          productData.inventory !== null
            ? productData.inventory.soldIndividually
            : null,
      },
      productDimensions: {
        weight:
          productData.productDimensions !== null
            ? productData.productDimensions.weight
            : "",
        length:
          productData.productDimensions !== null
            ? productData.productDimensions.length
            : "",
        width:
          productData.productDimensions !== null
            ? productData.productDimensions.width
            : "",
        height:
          productData.productDimensions !== null
            ? productData.productDimensions.height
            : "",
      },
      shipping: {
        processingTime:
          productData.shipping !== null
            ? productData.shipping.processingTime
            : "",
        weight:
          productData.shipping !== null ? productData.shipping.weight : "",
        length:
          productData.shipping !== null ? productData.shipping.length : "",
        width: productData.shipping !== null ? productData.shipping.width : "",
        height:
          productData.shipping !== null ? productData.shipping.height : "",
      },
      extra: {
        manufacturingDate: productData.extra.manufacturingDate,
        warranty: productData.extra.warranty,
        isFreeReturnPolicy: productData.extra.isFreeReturnPolicy,
        AdditionalPackingCharges:
          productData.extra.additionalPackingCharges > 0
            ? productData.extra.additionalPackingCharges
            : null,
        SpaceTypeId:
          productData.extra.spaceTypeId > 0
            ? productData.extra.spaceTypeId
            : null,
        // isInstallmentAvailable: productData.extra.isInstallmentAvailable,
      },
    });
    if (productData.productLanguages.length > 0) {
      productData.productLanguages.forEach((singleLang, index) => {
        if (singleLang.languageId == this.defaultLanguage.id) {
          const productName =
            singleLang.productName !== ""
              ? singleLang.productName
              : productData.productName;
          const productDesc =
            singleLang.productDesc !== ""
              ? singleLang.productDesc
              : productData.productDesc;

          this.editProductForm.get("productName").setValue(productName);
          this.editProductForm.get("description").setValue(productDesc);

          this.editProductForm
            .get("defaultLanguageId")
            .setValue(this.defaultLanguage.id);
        } else {
          this.l.controls.forEach((singleRow, i) => {
            if (singleRow.value.lang_id == singleLang.languageId) {
              this.l.controls[i]
                .get("languageProductName")
                .setValue(singleLang.productName);
              this.l.controls[i]
                .get("languagedescription")
                .setValue(singleLang.productDesc);
            }
          });
        }
      });
    } else {
      this.editProductForm.get("productName").setValue(productData.productName);
      this.editProductForm.get("description").setValue(productData.productDesc);
    }

    this.loadRecommandedProducts();
    this.loadCrossSellProducts();

    this.productSpecifications = productData.specification;

    this.primaryPreviewUrl =
      productData.isPrimaryImage === true ? productData.primaryImage : null;
    this.secondaryImageUrl =
      productData.secondaryImage !== null ? productData.secondaryImage : [];

    this.selectedRecommandedProducts =
      productData.linkedProducts.recommendedProduct;
    this.selectedCrossSellProducts =
      productData.linkedProducts.crossSellProduct;

    this.topViewUploadedFile = productData.topViewImage;
    this.topViewUploadedFileId = productData.topViewImageId;
    this.augmentedViewUploadedFile = productData.augmentedViewImage;
    this.augmentedViewUploadedFileId = productData.augmentedViewImageId;
    this.threeDViewUploadedFile = productData.modelJsonViewImage;
    this.threeDViewUploadedFileId = productData.modelJsonViewImageId;

    this.productCatId = productData.categoryId;
  }

  primaryFileProgress(primaryFileInput: any) {
    this.primaryFileData = primaryFileInput.target.files[0] as File;
    this.primaryImagePreview();
  }

  primaryImagePreview() {
    const mimeType = this.primaryFileData.type;
    const reader = new FileReader();
    reader.readAsDataURL(this.primaryFileData);
    reader.onload = (_event) => {
      this.primaryPreviewUrl = reader.result;
      if (this.primaryPreviewUrl.length > 2 ** 21) {
        this.alert.danger("File exceeds the maximum size: 2mb.");
        this.removePrimaryImage();
      } else {
        this.editProductForm.get("primaryImage").setValue({
          filename: this.primaryFileData.name,
          filetype: this.primaryFileData.type,
          value: this.primaryPreviewUrl.split(",")[1],
        });
      }
    };
  }

  removePrimaryImage() {
    this.primaryPreviewUrl = null;
    this.primaryFileData = null;
    this.editProductForm.get("primaryImage").setValue({
      filename: "",
      filetype: "",
      value: null,
    });
  }

  secondaryFileProgress(secondaryFileInput: any) {
    this.secondaryFileData.push(secondaryFileInput.target.files[0] as File);
    this.secondaryImagePreview();
  }

  secondaryImagePreview() {
    if (this.secondaryFileData.length > 0) {
      this.secondaryPreviewUrl = [];
      for (let i = 0; i < this.secondaryFileData.length; i++) {
        const reader = new FileReader();
        reader.readAsDataURL(this.secondaryFileData[i]);
        reader.onload = (_event) => {
          this.secondaryPreviewUrl[i] = reader.result;
          if (this.secondaryPreviewUrl[i].length > 2 ** 21) {
            this.alert.danger("File exceeds the maximum size: 2mb.");
            this.removeSecondaryImage(i);
          }
        };
      }
    }
  }

  removeSecondaryImage(i) {
    this.secondaryFileData.splice(i, 1);
    this.secondaryPreviewUrl.splice(i, 1);
  }

  removeUploadedSecondaryImage(i, secondaryId: number) {
    this.secondaryImageUrl.splice(i, 1);
    this.removeSecondaryImageIds.push(secondaryId);
  }

  onRemoveProductTage($event) {
    const itemId = typeof $event.value.id !== undefined ? $event.value.id : 0;
    if (itemId > 0) {
      this.removeProductTagIds.push(itemId);
    }
  }

  topViewFileProgress(topViewFileInput: any) {
    const fileData = topViewFileInput.target.files[0] as File;
    const fileName = fileData.name;
    const fileType = fileName.substring(fileName.lastIndexOf(".") + 1);
    this.topViewError = "";
    this.topViewInputData = null;
    if (fileType === "png") {
      this.topViewInputData = fileData;
      this.topViewPreviewUrl = fileName;
    } else {
      this.topViewPreviewUrl = "";
      this.topViewError = "Please upload only PNG file.";
    }
  }

  augmnetedViewFileProgress(augmnetedViewFileInput: any) {
    const fileData = augmnetedViewFileInput.target.files[0] as File;
    const fileName = fileData.name;
    const fileType = fileName.substring(fileName.lastIndexOf(".") + 1);
    this.augmentedViewError = "";
    this.augmentedViewInputData = null;
    if (fileType === "zip") {
      this.augmentedViewInputData = fileData;
      this.augmentedViewPreviewUrl = fileName;
    } else {
      this.augmentedViewPreviewUrl = "";
      this.augmentedViewError = "Please upload only ZIP file.";
    }
  }

  threeDViewFileProgress(threeDViewFileInput: any) {
    const fileData = threeDViewFileInput.target.files[0] as File;
    const fileName = fileData.name;
    const fileType = fileName.substring(fileName.lastIndexOf(".") + 1);
    this.threeDViewError = "";
    this.threeDViewInputData = null;
    if (fileType === "json") {
      this.threeDViewInputData = fileData;
      this.threeDViewPreviewUrl = fileName;
    } else {
      this.threeDViewPreviewUrl = "";
      this.threeDViewError = "Please upload only 3D JOSN file.";
    }
  }

  get e() {
    return this.editProductForm.controls;
  }
  get l() {
    return this.e.languagesArray as FormArray;
  }

  addAvailableLanguages() {
    this.allLanguages.forEach((singleLang) => {
      if (singleLang.id !== this.defaultLanguage.id) {
        this.availableLangs.push(singleLang);

        this.l.push(
          this.fb.group({
            languageProductName: [""],
            languagedescription: [""],
            lang_id: singleLang.id,
            defaultLang: singleLang.default_lang,
          })
        );
      }
    });
  }

  loadRecommandedProducts() {
    this.recommandedProducts$ = this.recommandedProductsInput$.pipe(
      distinctUntilChanged(),
      tap(() => (this.recommandedProductsLoading = true)),
      switchMap((term) =>
        this.product.getLinkedProduct(term, this.productCatId, 1).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => (this.recommandedProductsLoading = false))
        )
      )
    );
  }

  addNewRecommandedProduct($event) {
    if (
      !this.selectedRecommandedProducts.some((item) => item.key === $event.key)
    ) {
      $event.fromTag = true;
      this.selectedRecommandedProducts.push($event);
      if (
        this.selectedRecommandedProducts.length >= this.linkedProductMaxLimit
      ) {
        this.ngSelectComponent.setDisabledState(true);
      }
    }
    this.ngSelectComponent.handleClearClick();
  }

  removeRecommandedProduct(removeProduct) {
    if (
      typeof removeProduct.fromTag === "undefined" &&
      !removeProduct.fromTag
    ) {
      this.removeLinkedProduct.recommendedProduct.push(removeProduct);
    }

    this.selectedRecommandedProducts = this.selectedRecommandedProducts.filter(
      (item) => item.key !== removeProduct.key
    );
    if (this.selectedRecommandedProducts.length <= this.linkedProductMaxLimit) {
      this.ngSelectComponent.setDisabledState(false);
    }
  }

  loadCrossSellProducts() {
    this.crossSellProducts$ = this.crossSellProductsInput$.pipe(
      distinctUntilChanged(),
      tap(() => (this.crossSellProductsLoading = true)),
      switchMap((term) =>
        this.product.getLinkedProduct(term, this.productCatId, 0).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => (this.crossSellProductsLoading = false))
        )
      )
    );
  }

  addNewCrossSellProduct($event) {
    if (
      !this.selectedCrossSellProducts.some((item) => item.key === $event.key)
    ) {
      $event.fromTag = true;
      this.selectedCrossSellProducts.push($event);
      if (this.selectedCrossSellProducts.length >= this.linkedProductMaxLimit) {
        this.ngCrossProductComponent.setDisabledState(true);
      }
    }
    this.ngCrossProductComponent.handleClearClick();
  }

  removeCrossSellProduct(removeProduct) {
    if (
      typeof removeProduct.fromTag === "undefined" &&
      !removeProduct.fromTag
    ) {
      this.removeLinkedProduct.crossSellProduct.push(removeProduct);
    }

    this.selectedCrossSellProducts = this.selectedCrossSellProducts.filter(
      (item) => item.key !== removeProduct.key
    );
    if (this.selectedCrossSellProducts.length <= this.linkedProductMaxLimit) {
      this.ngCrossProductComponent.setDisabledState(false);
    }
  }

  trackByFnLinkedProduct(item: ProductLinked) {
    return item.key;
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  onOfferChange() {
    const price =
      this.editProductForm.value.price > 0
        ? this.editProductForm.value.price
        : 0;
    const offerAmount =
      this.editProductForm.value.offerAmount > 0
        ? this.editProductForm.value.offerAmount
        : 0;

    if (offerAmount > 0) {
      this.sellingPrice = price - (price * offerAmount) / 100;
    } else {
      this.sellingPrice = price;
    }
  }

  showModal(): void {
    this.product.getSpecificationList(this.productId).subscribe((data) => {
      this.specificationLists = data;
      this.specPopupTitle = "Add Specification";
      this.isModalShown = true;
    });
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
    this.specificationForm.get("specificationValue").patchValue([]);
    this.specificationForm.get("specification").patchValue([]);
    this.specificationListValues = [];
    this.hideSpecificationFieldOnPopup = false;
    this.specificationForm.reset();
  }

  getSpecificationValues($event: Specification) {
    this.specificationListValues = [];
    this.specificationForm.get("specificationValue").patchValue([]);

    if (typeof $event !== "undefined") {
      this.product
        .getSpecificationList(this.productId, $event.specNameLangId)
        .subscribe((data) => {
          this.specificationListValues = data;
        });
    }
  }

  updateSPecification(singleSpecification: ProductSpecification) {
    this.hideSpecificationFieldOnPopup = true;
    this.specificationForm.get("specificationValue").patchValue([]);
    this.specificationForm
      .get("specification")
      .setValue(singleSpecification.specificationId);

    this.specPopupTitle = "Edit Specification";

    this.product
      .getSpecificationList(this.productId, singleSpecification.specNameLangId)
      .subscribe((data) => {
        this.specificationListValues = data;
        this.specificationForm
          .get("specificationValue")
          .setValue(
            singleSpecification.productSpecificationValueIds
              .split(",")
              .map((x) => +x)
          );

        this.isModalShown = true;
      });
  }

  // It will handel remove specification from popup. Now not in use
  removeSpecicationValue($event) {
    let deletedSpec =
      this.specificationForm.controls["deletedSpecificationValue"].value;
    deletedSpec = deletedSpec !== null ? deletedSpec : [];
    const eventValue = typeof $event.value !== "undefined" ? $event.value : [];
    if (eventValue) {
      const id = typeof eventValue.id !== "undefined" ? eventValue.id : 0;
      if (id > 0) {
        deletedSpec.push(id);
        this.specificationForm
          .get("deletedSpecificationValue")
          .setValue(deletedSpec);
      }
    }
  }

  removeSpecification(id: number) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm Remove Specification",
        message: "Are you sure, you want to remove Specification?",
      },
    });
    confirmDialog.afterClosed().subscribe((result) => {
      if (result === true) {
        this.isLoadingService.add();
        this.product.deleteSpecification(id).subscribe(
          (data) => {
            if (data["status"] === true) {
              this.alert.success(data["message"]);
              this.productSpecifications = this.productSpecifications.filter(
                (item) => item.id !== id
              );
            } else {
              this.alert.danger(data["message"]);
            }
            this.isLoadingService.remove();
          },
          (err) => {
            this.alert.danger(err.error.message);
            this.isLoadingService.remove();
          }
        );
      }
    });
  }

  onSpecificationSubmit() {
    if (this.specificationForm.invalid) {
      return;
    }

    this.sloading = true;
    this.isLoadingService.add();

    const formData = {
      specNameId: this.specificationForm.value.specification,
      specNameLangIds:
        this.specificationForm.value.specificationValue.join(","),
      productId:
        this.specificationForm.value.productId !== null
          ? this.specificationForm.value.productId
          : this.productId,
    };

    this.product
      .addSpecification(formData)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data["status"] === true) {
            this.productSpecifications = data["data"];
            this.alert.success(data["message"]);
            this.autoShownModal.hide();
            this.isLoadingService.remove();
          } else {
            this.alert.danger(data["message"]);
            this.isLoadingService.remove();
          }
          this.sloading = false;
        },
        (err) => {
          if (typeof err.error !== "undefined") {
            const msg =
              typeof err.error.Message !== "undefined"
                ? err.error.Message
                : err.error.message;
            this.alert.danger(msg);
          }
          this.isLoadingService.remove();
          this.sloading = false;
        }
      );
  }

  onSubmit() {
    this.submitted = true;

    if (this.editProductForm.invalid) {
      return;
    }

    const formData = new FormData();
    // formData.append('CategoryId', this.editProductForm.value.categoryName);
    formData.append("ServiceTypeId", this.editProductForm.value.serviceTypeId);
    formData.append("ProductId", this.editProductForm.value.productId);
    formData.append("SubCategoryId", this.editProductForm.value.subCategory);
    formData.append("ProductName", this.editProductForm.value.productName);
    formData.append("ProductDesc", this.editProductForm.value.description);
    formData.append("ProductPrice", this.editProductForm.value.price);
    formData.append(
      "ProductOfferAmt",
      this.editProductForm.value.offerAmount !== null
        ? this.editProductForm.value.offerAmount
        : ""
    );
    formData.append(
      "PrimaryImage",
      this.primaryFileData !== null ? this.primaryFileData : ""
    );
    formData.append("ItemTypeId", this.editProductForm.value.productType);
    formData.append(
      "ItemTagId",
      this.editProductForm.value.itemTagId !== null
        ? this.editProductForm.value.itemTagId
        : 0
    );
    formData.append("BrandId", this.editProductForm.value.brandId);
    // formData.append('ProductTagIds', productTags.join(','));
    formData.append(
      "VideoURL",
      this.editProductForm.value.videoUrl !== null
        ? this.editProductForm.value.videoUrl
        : ""
    );

    formData.append("ThemeId", this.editProductForm.value.theme);
    formData.append("ColorPaletteId", this.editProductForm.value.colorPalette);
    formData.append(
      "IsFragile",
      this.editProductForm.value.isFragile !== null
        ? this.editProductForm.value.isFragile
        : 0
    );

    formData.append(
      "IsReturnable",
      this.editProductForm.value.isReturnable !== null
        ? this.editProductForm.value.isReturnable
        : 0
    );

    if (this.topViewInputData !== null) {
      formData.append("TopViewImage", this.topViewInputData);
    } else {
      formData.append("TopViewImage", "");
    }

    if (this.augmentedViewInputData !== null) {
      formData.append("AugmentedViewImage", this.augmentedViewInputData);
    } else {
      formData.append("AugmentedViewImage", "");
    }

    if (this.threeDViewInputData !== null) {
      formData.append("ModelJsonViewImage", this.threeDViewInputData);
    } else {
      formData.append("ModelJsonViewImage", "");
    }

    if (this.secondaryFileData.length > 0) {
      for (let i = 0; i < this.secondaryFileData.length; i++) {
        formData.append("SecondaryImage", this.secondaryFileData[i]);
      }
    }

    if (this.editProductForm.value.productTags.length > 0) {
      this.editProductForm.value.productTags.forEach((singleTag) => {
        formData.append("ProductTagIds", singleTag);
      });
    }

    if (this.removeProductTagIds.length > 0) {
      this.removeProductTagIds.forEach((item) => {
        formData.append("ProductTagRemovedIds", item.toString());
      });
    }

    // inventory
    formData.append("Inventory.SKU", this.editProductForm.value.inventory.sku);
    formData.append("Inventory.Qty", this.editProductForm.value.inventory.qty);
    formData.append(
      "Inventory.ProductStock",
      this.editProductForm.value.inventory.productStock
    );
    formData.append(
      "Inventory.SoldIndividually",
      this.editProductForm.value.inventory.soldIndividually
    );

    const pWeight =
      typeof this.editProductForm.value.productDimensions.weight !== "undefined"
        ? this.editProductForm.value.productDimensions.weight
        : 0;
    const pLength =
      typeof this.editProductForm.value.productDimensions.length !== "undefined"
        ? this.editProductForm.value.productDimensions.length
        : 0;
    const pWidth =
      typeof this.editProductForm.value.productDimensions.width !== "undefined"
        ? this.editProductForm.value.productDimensions.width
        : 0;
    const pHeight =
      typeof this.editProductForm.value.productDimensions.height !== "undefined"
        ? this.editProductForm.value.productDimensions.height
        : 0;

    formData.append("ProductDimensions.Weight", pWeight);
    formData.append("ProductDimensions.Length", pLength);
    formData.append("ProductDimensions.Width", pWidth);
    formData.append("ProductDimensions.Height", pHeight);

    // Shipping
    formData.append(
      "Shipping.ProcessingTime",
      this.editProductForm.value.shipping.processingTime !== null
        ? this.editProductForm.value.shipping.processingTime
        : ""
    );
    formData.append(
      "Shipping.Weight",
      this.editProductForm.value.shipping.weight !== null
        ? this.editProductForm.value.shipping.weight
        : ""
    );
    formData.append(
      "Shipping.Length",
      this.editProductForm.value.shipping.length !== null
        ? this.editProductForm.value.shipping.length
        : ""
    );
    formData.append(
      "Shipping.Width",
      this.editProductForm.value.shipping.width !== null
        ? this.editProductForm.value.shipping.width
        : ""
    );
    formData.append(
      "Shipping.Height",
      this.editProductForm.value.shipping.height !== null
        ? this.editProductForm.value.shipping.height
        : ""
    );

    const isAssemblyRequired =
      this.editProductForm.value.IsAssemblyRequired !== null
        ? this.editProductForm.value.IsAssemblyRequired
        : false;
    formData.append("IsAssemblyRequired", isAssemblyRequired);

    const AssemblyCharges =
      this.editProductForm.value.AssemblyCharges !== null
        ? this.editProductForm.value.AssemblyCharges
        : 0;
    formData.append(
      "AssemblyCharges",
      isAssemblyRequired === true ? AssemblyCharges : ""
    );

    // Extra
    let mfDate = "";
    if (this.editProductForm.value.extra.manufacturingDate !== null) {
      const mDate = new Date(
        this.editProductForm.value.extra.manufacturingDate
      );
      const m =
        mDate.getMonth() > 0 && mDate.getMonth() <= 9
          ? "0" + (mDate.getMonth() + 1)
          : mDate.getMonth() + 1;
      const d =
        mDate.getDate() > 0 && mDate.getDate() <= 9
          ? "0" + mDate.getDate()
          : mDate.getDate();
      mfDate = mDate.getFullYear() + "-" + m + "-" + d;
    }

    formData.append("Extra.ManufacturingDate", mfDate);
    formData.append(
      "Extra.Warranty",
      this.editProductForm.value.extra.warranty !== null
        ? this.editProductForm.value.extra.warranty
        : ""
    );
    formData.append(
      "Extra.IsFreeReturnPolicy",
      this.editProductForm.value.extra.isFreeReturnPolicy !== null
        ? this.editProductForm.value.extra.isFreeReturnPolicy
        : ""
    );
    formData.append(
      "Extra.AdditionalPackingCharges",
      this.editProductForm.value.extra.AdditionalPackingCharges !== null
        ? this.editProductForm.value.extra.AdditionalPackingCharges
        : ""
    );
    formData.append(
      "Extra.SpaceTypeId",
      this.editProductForm.value.extra.SpaceTypeId !== null
        ? this.editProductForm.value.extra.SpaceTypeId
        : ""
    );
    // formData.append('Extra.IsInstallmentAvailable', (this.editProductForm.value.extra.isInstallmentAvailable !== null) ? this.editProductForm.value.extra.isInstallmentAvailable : '');

    // Linked Products
    if (this.selectedRecommandedProducts.length > 0) {
      let recommandedProductsIds = [];
      this.selectedRecommandedProducts.forEach((item) => {
        recommandedProductsIds.push(item.key.toString());
      });

      formData.append(
        "LinkedProducts.RecommendedProductSetIds",
        recommandedProductsIds.length > 0
          ? recommandedProductsIds.join(",")
          : ""
      );
    } else {
      formData.append("LinkedProducts.RecommendedProductSetIds", "");
    }

    if (this.removeLinkedProduct.recommendedProduct.length > 0) {
      let removeRecommandedProductIds = [];
      this.removeLinkedProduct.recommendedProduct.forEach((item) => {
        removeRecommandedProductIds.push(item.key.toString());
      });
      formData.append(
        "LinkedProducts.RecommendedProductRemovedIds",
        removeRecommandedProductIds.length > 0
          ? removeRecommandedProductIds.join(",")
          : ""
      );
    } else {
      formData.append("LinkedProducts.RecommendedProductRemovedIds", "");
    }

    if (this.selectedCrossSellProducts.length > 0) {
      let crossSellProductIds = [];
      this.selectedCrossSellProducts.forEach((item) => {
        crossSellProductIds.push(item.key.toString());
      });
      formData.append(
        "LinkedProducts.CrossSellProductSetIds",
        crossSellProductIds.length > 0 ? crossSellProductIds.join(",") : ""
      );
    } else {
      formData.append("LinkedProducts.CrossSellProductSetIds", "");
    }

    if (this.removeLinkedProduct.crossSellProduct.length > 0) {
      let removeCrossSellProductIds = [];
      this.removeLinkedProduct.crossSellProduct.forEach((item) => {
        removeCrossSellProductIds.push(item.key.toString());
      });
      formData.append(
        "LinkedProducts.CrossSellProductRemovedIds",
        removeCrossSellProductIds.length > 0
          ? removeCrossSellProductIds.join(",")
          : ""
      );
    } else {
      formData.append("LinkedProducts.CrossSellProductRemovedIds", "");
    }

    // Product Language
    const productLanguages = [];
    this.editProductForm.value.languagesArray.forEach((item) => {
      productLanguages.push({
        ProductId: this.productId,
        LanguageId: item.lang_id,
        ProductName: item.languageProductName,
        ProductDesc: item.languagedescription,
      });
    });

    formData.append(
      "ProductLanguagesJSONString",
      JSON.stringify(productLanguages).toString()
    );

    this.isLoadingService.add();
    this.product
      .edit(formData)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data["status"] === true) {
            this.alert.success(data["message"]);
            this.router.navigate([`${this.productListUrl}`]);
          } else {
            this.alert.danger(data["message"]);
            this.isLoadingService.remove();
          }
        },
        (err) => {
          if (typeof err.error !== "undefined") {
            const msg =
              typeof err.error.Message !== "undefined"
                ? err.error.Message
                : err.error.message;
            this.alert.danger(msg);
          }
          this.isLoadingService.remove();
          this.loading = false;
        }
      );
  }

  onAssemblyChange($event) {
    if ($event.checked === false) {
      this.editProductForm.get("AssemblyCharges").setValue(null);
      this.editProductForm.get("AssemblyCharges").setValidators(null);
      this.editProductForm.get("AssemblyCharges").clearValidators();
      this.editProductForm.get("AssemblyCharges").updateValueAndValidity();
    } else {
      this.editProductForm.get("AssemblyCharges").setValue(null);
      this.editProductForm
        .get("AssemblyCharges")
        .setValidators([Validators.required]);
      this.editProductForm.get("AssemblyCharges").updateValueAndValidity();
    }
  }

  removeUploadedFile(refId, imageType, index: number = 0) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm",
        message: "Are you sure you wish to delete this file?",
      },
    });

    confirmDialog.afterClosed().subscribe((result) => {
      if (result === true) {
        this.isLoadingService.add();
        this.product
          .removeImage(refId, imageType)
          .pipe()
          .subscribe(
            (data) => {
              if (data["status"] === true) {
                this.alert.success(data["message"]);

                switch (imageType) {
                  case this.SECONDARY_IMAGE:
                    this.secondaryImageUrl.splice(index, 1);
                    break;
                  case this.TOPVIEW_IMAGE:
                    this.topViewUploadedFile = "";
                    this.topViewUploadedFileId = 0;
                    this.topViewPreviewUrl = "";
                    break;
                  case this.AUGMENTED_VIEW:
                    this.augmentedViewUploadedFile = "";
                    this.augmentedViewUploadedFileId = 0;
                    this.augmentedViewPreviewUrl = "";
                    break;
                  case this.THREE_VIEW:
                    this.threeDViewUploadedFile = "";
                    this.threeDViewUploadedFileId = 0;
                    this.threeDViewPreviewUrl = "";
                    break;
                }
              } else {
                this.alert.danger(data["message"]);
              }
              this.isLoadingService.remove();
            },
            (err) => {
              if (typeof err.error.Message !== "undefined") {
                this.alert.danger(err.error.Message);
              } else if (
                typeof err.error !== "undefined" &&
                err.error !== null
              ) {
                this.alert.danger(err.error.message);
              }
              this.isLoadingService.remove();
            }
          );
      }
    });
  }

  addTagFn(name) {
    if (name.length >= 40) {
      this.specificationLength = "Maximum 40 characters allowed.";
      return;
    } else {
      this.specificationLength = "";
    }
    const selectedSpec = this.specificationLists.find(
      (spec) => spec.id === this.specificationForm.get("specification").value
    );
    const specNameLangId = selectedSpec ? selectedSpec.specNameLangId : null;

    const CueenrLangId = JSON.parse(localStorage.getItem("defaultLang"));

    const langValueLists = [];
    const specNameLangIdList = [];

    specNameLangIdList.push(specNameLangId);
    langValueLists.push(name);
    const formData = {
      specValueLangId: 0,
      value: "",
      specValueList: langValueLists,
      specNameLangIdList: specNameLangIdList,
    };

    this.isLoadingService.add();

    this.specification.addEditSpecificationValue(formData).subscribe(
      (data) => {
        if (data["status"] === true) {
          this.product
            .getSpecificationList(this.productId, specNameLangId)
            .subscribe((data) => {
              this.specificationListValues = data;
              const addedTag = this.specificationListValues.find(
                (item) => item.value === name
              );
              // const currentSelected =
              //   this.specificationForm.get("specificationValue").value;
              // this.specificationForm.get("specificationValue").patchValue([]);
              // this.ngSelectComponent.handleClearClick();
              // const updatedSelected = currentSelected.concat(addedTag.id);
              // this.specificationForm
              //   .get("specificationValue")
              //   .setValue(updatedSelected);
            });
          this.alert.success(data["message"]);
        } else {
          this.alert.danger(data["message"]);
        }
        this.isLoadingService.remove();
      },
      (err) => {
        if (typeof err.error !== "undefined") {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          this.alert.danger(msg);
        }
        this.isLoadingService.remove();
        this.loading = false;
      }
    );
  }
}

export interface DropDownValues {
  brands: ProductBrand[];
  categoryLists: ProductCategory[];
  productLabels: ProductLabel[];
  productTags: ProductTag[];
  productTypes: ProductType[];
  themes: ProductTheme[];
  colorPalettes: ProductColorPalette[];
  productServiceType: ProductServiceType[];
  spaceType: ProductServiceType[];
}
