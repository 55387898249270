import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { constEnv } from 'common/constants.env';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PotentialListService {
    configConstant = constEnv;
    apiUrl = '';

    constructor(private http: HttpClient) {
        this.apiUrl = `${this.configConstant.apiStoreURL}`;
    }

    getAllPotentialList( dataTablesParameters ) {
        let searchValue = ''; let searchBy = '';
        if ( dataTablesParameters.search.value !== '' ) {
            searchValue = dataTablesParameters.search.value;
            searchBy = dataTablesParameters.search.column;
        }

        let filterListType = '';
        if(dataTablesParameters.filterListType !== '') {
            filterListType = dataTablesParameters.filterListType;
        }

        const pageNo = (dataTablesParameters.start * 1) / (dataTablesParameters.length * 1);
        const searchParams = new HttpParams()
            // .set('SearchColumn', searchBy)
            // .set('SearchValue', searchValue)
            .set('PageNo', pageNo.toString())
            .set('PageSize', dataTablesParameters.length)
            .set('ListType', filterListType)
            // .set('ApplyOfferFilter', '')
            // .set('GetWithOffer', '');

        return this.http.get(`${this.apiUrl}/getpotentiallist`, {
            params: searchParams
        })
            .pipe(map(data => {
                return data;
            }));
    }
}