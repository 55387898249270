import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  NgZone,
  ElementRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl,
  ValidatorFn,
  AbstractControl,
} from "@angular/forms";
import { first } from "rxjs/operators";

import { constEnv } from "common/constants.env";
import { TitleService } from "common/services/title.service";
import { AlertService } from "ngx-alerts";
import { IsLoadingService } from "@service-work/is-loading";
import { LanguageService } from "common/services/language.service";
import { MapsAPILoader } from "@agm/core";
import { GeneralService } from "common/services/general.service";
import { DriverService } from "common/services/store/driver.service";
import { StoreDriver } from "common/_models/store/storeDriver";
import { AddressInfo } from "common/_components/maps/interfaces/address-info";

@Component({
  selector: "app-actions",
  templateUrl: "./actions.component.html",
  styleUrls: ["./actions.component.css"],
})
export class ActionsComponent implements OnInit {
  config = constEnv;

  actionForm: FormGroup;
  loading = false;
  submitted = false;

  languages = [];

  pageTitle = "AddDriver";

  action: string;
  id: number;

  iniObj: any;
  countryCode: string;
  countryIsoCode: string;
  validMobileNo = true;

  zoom: number = this.config.defaultZoom;
  // initial center position for the map
  lat: number = this.config.defaultLatitude;
  lng: number = this.config.defaultLongitude;

  // private geoCoder;
  // @ViewChild("search", { static: false })
  // public searchElementRef: ElementRef;
  markers: Marker[] = [];

  @ViewChild("profileFileInput", { static: true }) profileFileInput: ElementRef;
  profileFileData: File = null;
  profilePreviewUrl: any = null;

  countryLists: CountryList[] = [];
  stateLists: StateList[] = [];
  cityLists: CityList[] = [];

  isEmailAvailable = false;

  listUrl = `${this.config.STORE_URL}/driver/lists`;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private alert: AlertService,
    private titleService: TitleService,
    private isLoadingService: IsLoadingService,
    // private mapsAPILoader: MapsAPILoader,
    // private ngZone: NgZone,
    private general: GeneralService,
    private ls: LanguageService,
    private driver: DriverService
  ) {
    this.action = route.snapshot.params.action;
  }

  ngOnInit() {
    this.document.body.classList.add("driver-action");

    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }

    switch (this.action) {
      case "edit":
        const id = this.route.snapshot.params.id;
        this.id = typeof id !== "undefined" ? id : 0;
        if (this.id <= 0) {
          this.router.navigate([this.listUrl]);
        }

        this.pageTitle = "EditDriver";

        if (this.id > 0) {
          this.isLoadingService.add();
          this.driver
            .get(this.id)
            .pipe(first())
            .subscribe(
              (data) => {
                const res = data as DriverResponse;
                if (res.status === true) {
                  this.setFormValue(res.data);

                  this.isLoadingService.remove();
                } else {
                  this.alert.danger(res.message);
                  this.isLoadingService.remove();
                }
              },
              (err) => {
                this.alert.danger(err.error.message);
                this.isLoadingService.remove();
              }
            );
        }
        break;

      case "add":
        break;

      default:
        this.router.navigate([this.listUrl]);
        break;
    }

    this.titleService.setTitle(this.pageTitle);

    this.languages = this.ls.getAvailableLanguages();

    this.makeForm();

    if (this.action === "add") {
      this.actionForm
        .get("password")
        .setValidators(
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(16),
          ])
        );
    }

    // this.placeAutoCompleteField();
    this.getCountryList();
  }

  makeForm() {
    const emailregex: RegExp =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const zipCodeRegex = /^[A-Za-z0-9][A-Za-z0-9\s-]*(?!\s-)$/;

    this.actionForm = this.fb.group({
      driverId: [0],
      profilePicture: [],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      email: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern(emailregex),
        ])
      ),
      password: new FormControl(
        "",
        Validators.compose([Validators.minLength(8), Validators.maxLength(16)])
      ),
      phone: ["", Validators.required],
      language: ["", Validators.required],
      location: ["", Validators.required],
      locationLatitude: [""],
      locationLongitude: [""],
      address: [""],
      addressLine1: ["", Validators.required],
      addressLine2: [""],
      landmark: [""],
      zipcode: ["", [Validators.required, this.zipCodeValidator()]],
      country: [null, Validators.required],
      state: [null, Validators.required],
      city: [null, Validators.required],
    });
  }

  private setFormValue(formData: StoreDriver) {
    this.actionForm.get("driverId").setValue(formData.id);
    this.actionForm.get("firstName").setValue(formData.firstName);
    this.actionForm.get("lastName").setValue(formData.lastName);
    this.actionForm.get("email").setValue(formData.email);
    this.actionForm.get("phone").setValue(formData.phone);
    this.actionForm.get("language").setValue(formData.langId.toString());

    this.actionForm.get("location").setValue(formData.address.location);
    this.actionForm.get("locationLatitude").setValue(formData.address.latitude);
    this.actionForm
      .get("locationLongitude")
      .setValue(formData.address.longitude);
    this.actionForm.get("address").setValue(formData.address.addressLine);
    this.actionForm.get("addressLine1").setValue(formData.address.addressLine1);
    this.actionForm.get("addressLine2").setValue(formData.address.addressLine2);
    this.actionForm.get("landmark").setValue(formData.address.landmark);
    this.actionForm.get("zipcode").setValue(formData.address.zipCode);
    this.actionForm.get("country").setValue(formData.address.countryId);
    this.actionForm.get("state").setValue(formData.address.stateId);
    this.actionForm.get("state").setValue(formData.address.cityId);

    this.profilePreviewUrl = formData.profileImage;

    this.onCountryChange(formData.address.countryId, formData.address.stateId);
    this.onStateChange(formData.address.stateId, formData.address.cityId);
    if (formData.phoneCode !== null) {
      this.iniObj.setNumber(formData.phoneCode);
    }

    this.isEmailAvailable = formData.email !== "" ? true : false;

    this.lat = formData.address.latitude;
    this.lng = formData.address.longitude;

    this.createMarker(this.lat, this.lng, formData.address.addressLine, true);
  }

  zipCodeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (!value) return null;       
      if (value.startsWith(' ') || value.endsWith(' ') || value.startsWith('-') || value.endsWith('-')) {
        return { 'invalidZipCode': { value: control.value } };
      }
      const spacesCount = (value.match(/ /g) || []).length;
      const dashesCount = (value.match(/-/g) || []).length;
      
      if (spacesCount > 1 || dashesCount > 1) {
        return { 'invalidZipCode': { value: control.value } };
      }
      
      const regex = /^[a-zA-Z0-9\- ]+$/;
      if (!regex.test(value)) {
        return { 'invalidZipCode': { value: control.value } };
      }

      return null;
    };
  }

  getErrorEmail() {
    return this.actionForm.get("email").hasError("required")
      ? ""
      : this.actionForm.get("email").hasError("pattern")
      ? this.titleService.getTranslatedString("EmailInValid")
      : "";
  }
  getErrorPassword() {
    return this.actionForm.get("password").hasError("required")
      ? ""
      : this.actionForm.get("password").hasError("minlength")
      ? this.titleService.getTranslatedString("PasswordFormate")
      : this.actionForm.get("password").hasError("maxlength")
      ? this.titleService.getTranslatedString("PasswordFormate")
      : "";
  }

  hasError(obj) {
    if (!obj && this.countryIsoCode === "ae") {
      const selectedNumber = this.iniObj.getNumber();
      this.validMobileNo =
        /^(?:\+971|00971|0)?(?:50|52|53|54|55|56|57|58|59|2|3|4|6|7|9)\d{7}$/gm.test(
          selectedNumber
        );
    } else {
      this.validMobileNo = obj;
    }
  }

  onPhoneCodeChange(obj) {
    this.countryCode = obj.dialCode;
    this.countryIsoCode = obj.iso2;
  }

  telInputObject(obj) {
    this.iniObj = obj;
    const countryData = obj.getSelectedCountryData();
    this.countryCode = countryData.dialCode;
    this.countryIsoCode = countryData.iso2;
  }

  private placeAutoCompleteField() {
    // this.mapsAPILoader.load().then(() => {
    //   // this.setCurrentLocation();
    //   this.geoCoder = new google.maps.Geocoder();
    //   const autocomplete = new google.maps.places.Autocomplete(
    //     this.searchElementRef.nativeElement,
    //     {
    //       types: ["address"],
    //     }
    //   );
    //   autocomplete.addListener("place_changed", () => {
    //     this.ngZone.run(() => {
    //       // get the place result
    //       const place: google.maps.places.PlaceResult = autocomplete.getPlace();
    //       // verify result
    //       if (place.geometry === undefined || place.geometry === null) {
    //         return;
    //       }
    //       // set latitude, longitude and zoom
    //       this.lat = place.geometry.location.lat();
    //       this.lng = place.geometry.location.lng();
    //       this.zoom = 12;
    //       this.actionForm.get("location").setValue(place.formatted_address);
    //       this.actionForm.get("locationLatitude").setValue(this.lat);
    //       this.actionForm.get("locationLongitude").setValue(this.lng);
    //       this.createMarker(this.lat, this.lng);
    //     });
    //   });
    // });
  }

  createMarker(lat, lng, label = "", draggable = false) {
    this.markers.push({
      lat,
      lng,
      label,
      draggable,
    });
  }

  profileFileProgress(profileFileInput: any) {
    this.profileFileData = profileFileInput.target.files[0] as File;
    this.profileImagePreview();
  }

  profileImagePreview() {
    const reader = new FileReader();
    reader.readAsDataURL(this.profileFileData);
    reader.onload = (_event) => {
      this.profilePreviewUrl = reader.result;
      // this.addProductForm.get('primaryImage').setValue({
      //   filename: this.primaryFileData.name,
      //   filetype: this.primaryFileData.type,
      //   value: this.primaryPreviewUrl.split(',')[1]
      // });
    };
  }

  private getCountryList() {
    this.general.getAllCountry().subscribe((data) => {
      if (data["status"] === true) {
        this.countryLists = data["data"];
      }
      this.isLoadingService.remove();
    });
  }

  onCountryChange(countryId: number, selectedStateId: number = 0) {
    if (countryId > 0) {
      this.isLoadingService.add();
      this.stateLists = [];
      this.cityLists = [];
      this.actionForm.get("state").setValue(null);
      this.actionForm.get("city").setValue(null);
      this.general.getstateByCountryId(countryId).subscribe(
        (data) => {
          if (data["status"] === true) {
            this.stateLists = data["data"];

            if (selectedStateId > 0) {
              this.actionForm.get("state").setValue(selectedStateId);
            }
          }
          this.isLoadingService.remove();
        },
        (err) => {
          this.isLoadingService.remove();
        }
      );
    } else {
      this.stateLists = [];
      this.cityLists = [];
    }
  }

  onStateChange(stateId: number, selectedCityId: number = 0) {
    if (stateId > 0) {
      this.isLoadingService.add();
      this.cityLists = [];
      this.actionForm.get("city").setValue(null);
      this.general.getcityByStateId(stateId).subscribe(
        (data) => {
          if (data["status"] === true) {
            this.cityLists = data["data"];

            if (selectedCityId > 0) {
              this.actionForm.get("city").setValue(selectedCityId);
            }
          }
          this.isLoadingService.remove();
        },
        (err) => {
          this.isLoadingService.remove();
        }
      );
    } else {
      this.cityLists = [];
    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.actionForm.invalid) {
      return;
    }

    if (!this.validMobileNo) {
      return;
    }

    const formData = new FormData();
    formData.append("FirstName", this.actionForm.value.firstName);
    formData.append("LastName", this.actionForm.value.lastName);
    formData.append("Email", this.actionForm.value.email);
    formData.append("Phone", this.actionForm.value.phone);
    formData.append("PhoneCode", this.countryCode);
    formData.append("Password", this.actionForm.value.password);
    formData.append("RegionCode", this.countryIsoCode);
    formData.append("address.Location", this.actionForm.value.location);
    formData.append("address.AddressLine", this.actionForm.value.address);
    formData.append("address.AddressLine1", this.actionForm.value.addressLine1);
    formData.append("address.AddressLine2", this.actionForm.value.addressLine2);
    formData.append("address.Landmark", this.actionForm.value.landmark);
    formData.append("address.ZipCode", this.actionForm.value.zipcode);
    formData.append(
      "address.Longitude",
      this.actionForm.value.locationLongitude
    );
    formData.append("address.Latitude", this.actionForm.value.locationLatitude);
    formData.append("address.CityId", this.actionForm.value.city);
    formData.append("address.CountryId", this.actionForm.value.country);
    formData.append("address.StateId", this.actionForm.value.state);

    if (this.profileFileData) {
      formData.append("ProfilePicture", this.profileFileData);
    }

    let action = "add";
    if (this.actionForm.value.driverId > 0) {
      action = "edit";
      formData.append("Id", this.actionForm.value.driverId);
    }

    this.loading = true;
    this.isLoadingService.add();

    this.driver
      .action(action, formData)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data["status"] === true) {
            this.alert.success(data["message"]);
            this.router.navigate([`${this.listUrl}`]);
          } else {
            this.alert.danger(data["message"]);
            this.isLoadingService.remove();
          }
        },
        (err) => {
          if (typeof err.error !== "undefined") {
            const msg =
              typeof err.error.Message !== "undefined"
                ? err.error.Message
                : err.error.message;
            this.alert.danger(msg);
          }
          this.isLoadingService.remove();
          this.loading = false;
        }
      );
  }

  getSelectedAddress(address: AddressInfo) {
    if (address) {
      this.actionForm.get("location").setValue(address.text);
      this.actionForm.get("locationLatitude").setValue(address.latlng.lat);
      this.actionForm.get("locationLongitude").setValue(address.latlng.lng);
      const selectedCountry = this.countryLists.find(
        (item) => item.countryName === address.properties.CntryName
      );
      if (selectedCountry) {
        this.actionForm.get("country").setValue(selectedCountry.countryId);
        this.onCountryChange(selectedCountry.countryId);
      }
      setTimeout(() => {
        const selectedState = this.stateLists.find(
          (item) => item.stateName === address.properties.Region
        );
        if (selectedState) {
          this.actionForm.get("state").setValue(selectedState.stateId);
          this.onStateChange(selectedState.stateId);
        }
        setTimeout(() => {
          const selectedCity = this.cityLists.find(
            (item) => item.cityName === address.properties.City
          );
          if (selectedCity) {
            this.actionForm.get("city").setValue(selectedCity.cityId);
          }
        }, 500);
      }, 500);
      // this.actionForm.get("storeAddress").setValue(storeData.addressLine);
      const postalCode = address.properties.PostalExt
        ? address.properties.Postal + " " + address.properties.PostalExt
        : address.properties.Postal;
      this.actionForm.get("zipcode").setValue(postalCode);
      const stAddress = address.properties.StAddr
        ? address.properties.StAddr
        : "";
      const stAddressLine = address.properties.Address
        ? address.properties.Address
        : "";
      const addressLine1 = stAddress ? stAddress : stAddressLine;
      this.actionForm.get("addressLine1").setValue(addressLine1);
      const nbrhd = address.properties.Nbrhd ? address.properties.Nbrhd : "";
      const nbrhdLine = address.properties.Neighborhood
        ? address.properties.Neighborhood
        : "";
      const neighborhood = nbrhd ? nbrhd : nbrhdLine;
      this.actionForm.get("landmark").setValue(neighborhood);
      this.updateAddressLine();
    }
  }

  updateAddressLine() {
    const addressLine1 = this.actionForm.get("addressLine1").value;
    const addressLine2 = this.actionForm.get("addressLine2").value;
    const landmark = this.actionForm.get("landmark").value;
    const postcode = this.actionForm.get("zipcode").value;

    const combinedAddress = [addressLine1, addressLine2, landmark, postcode]
      .filter(Boolean)
      .join(", ");

    this.actionForm.get("address").setValue(combinedAddress);
  }
}

interface Marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}

interface CountryList {
  countryId: number;
  countryName?: string;
}

interface StateList {
  stateId: number;
  stateName?: string;
}

interface CityList {
  cityId: number;
  cityName?: string;
}

interface DriverResponse {
  status: boolean;
  message: string;
  data: StoreDriver;
}
