import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { IsLoadingService } from "@service-work/is-loading";
import { constEnv } from "common/constants.env";
import { AuthenticationService } from "common/services/authentication.service";
import { EventEmitterService } from "common/services/event-emitter.service";
import { GeneralService } from "common/services/general.service";
import { LanguageService } from "common/services/language.service";
import { CartService } from "common/services/site/cartservice";
import { Subscription } from "rxjs";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  config = constEnv;
  logoUrl;
  siteTitle;

  cartCount = 0;
  wishListCount = 0;

  cartCountEventsubscription: Subscription;

  @Input() isFirstTimeLogin: boolean = false;

  constructor(
    private gs: GeneralService,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private isLoadingService: IsLoadingService,
    private langService: LanguageService,
    private eventEmitterService: EventEmitterService,
    private cart: CartService
  ) {}

  ngOnInit() {
    this.logoUrl = this.gs.getSiteLogo(true);
    this.siteTitle = this.config.projectTitle;

    this.cartCountEventsubscription = this.cart
      .getCartCountEvent()
      .subscribe(() => {
        this.getCartCount();
      });

    this.getCartCount();
  }

  getCartCount() {
    this.cart.getCartCount().subscribe(
      (data) => {
        this.cartCount =
          typeof data["data"] !== "undefined" && data["data"] > 0
            ? data["data"]
            : 0;
        this.wishListCount =
          typeof data["wishlistCount"] !== "undefined" &&
          data["wishlistCount"] > 0
            ? data["wishlistCount"]
            : 0;
      },
      (err) => {
        this.cartCount = 0;
        this.wishListCount = 0;
      }
    );
  }

  logout() {
    this.isLoadingService.add();
    // this.langService.changeLanguage(this.langService.defaultLanguage);
    this.authenticationService.logout();
    const loginURL = `${this.config.BASE_URL}login`;

    const settingLang = this.gs.getSettings("default_language");

    const changeLangCode =
      settingLang !== "" ? settingLang : this.langService.defaultLanguage;

    this.langService.getAvailableLanguages().map((lang) => {
      if (changeLangCode === lang.lang_code) {
        this.langService._setLanguage(lang.lang_code);
        localStorage.setItem("defaultLang", JSON.stringify(lang));
      }
    });

    this.langService
      .getLanguageTranslation(this.langService.getCurrentLanguage())
      .subscribe((translationsJSON) => {
        this.langService.setLanguageTranslation(translationsJSON);
        window.location.href = loginURL;
      });
  }
}
