import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { constEnv } from "common/constants.env";
import { map } from "rxjs/operators";
import { of } from "rxjs";
import {
  AutocompleteAddress,
  StoreAddressDetail,
} from "projects/store/src/app/pages/settings/pickup-address-list/pickup-address-list.component";

@Injectable({ providedIn: "root" })
export class SettingsService {
  configConstant = constEnv;

  constructor(private http: HttpClient) {}

  // Store settings
  getStoreData() {
    return this.http.get(`${this.configConstant.apiStoreURL}/getstore`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  updateStoreData(formData) {
    return this.http
      .post(`${this.configConstant.apiStoreURL}/updatestore`, formData)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  // Store Settings end

  // Time settings
  getTimeData() {
    return this.http
      .get(`${this.configConstant.apiStoreURL}/gettimeslotdata`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  updateTimeData(formData) {
    return this.http
      .post(`${this.configConstant.apiStoreURL}/updatetimeslotdata`, formData)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  // Time Settings end

  // Change Passowrd
  changePassword(formData) {
    return this.http
      .post(`${this.configConstant.apiStoreURL}/changepassword`, formData)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  // END

  // Stote Order Settings
  getOrderSettings() {
    return this.http
      .get(`${this.configConstant.apiStoreURL}/getstoreordersetting`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  updateOrderSettings(formData) {
    return this.http
      .post(
        `${this.configConstant.apiStoreURL}/updatestoreordersetting`,
        formData
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  // End Order Settings

  // Store Bank Settings
  getBankDetail() {
    return this.http
      .get(`${this.configConstant.apiStoreURL}/getbankdetails`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  updateBankDetail(formData) {
    return this.http
      .post(`${this.configConstant.apiStoreURL}/addeditbankdetails`, formData)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  // End Bank Settings

  // Store Policy Settings
  getPolicyDetail() {
    return this.http
      .get(`${this.configConstant.apiStoreURL}/getstorepolicy`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  updatePolicyDetail(formData) {
    return this.http
      .post(`${this.configConstant.apiStoreURL}/updatestorepolicy`, formData)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  // End Bank Settings

  // Document Management
  getDocumentLists() {
    return this.http
      .get(`${this.configConstant.apiStoreURL}/getrequireddocuments`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getUploadedDouments(docMgmtId) {
    return this.http
      .get(`${this.configConstant.apiStoreURL}/document/${docMgmtId}/view`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  addDocument(formData) {
    return this.http
      .post(`${this.configConstant.apiStoreURL}/document/add`, formData)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  updateDocument(formData) {
    return this.http
      .post(`${this.configConstant.apiStoreURL}/updatedocument`, formData)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  actionDocumentUpload(formData) {
    // if ( action === 'add' ) {
    //     return this.addDocument(formData);
    // } else {
    return this.updateDocument(formData);
    // }
  }
  // END

  getCuisineLists() {
    return this.http.get(`${this.configConstant.apiStoreURL}/getcuisine`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getStoreReviews(paging) {
    const pageNo = typeof paging.page !== "undefined" ? paging.page : 0;
    const perPage =
      typeof paging.itemsPerPage !== "undefined" ? paging.itemsPerPage : 12;

    const searchParams = new HttpParams()
      .set("searchColumn", "")
      .set("searchValue", "")
      .set("pageNo", pageNo)
      .set("pageSize", perPage)
      .set("sortColumn", "")
      .set("sortOrder", "");

    return this.http
      .get(`${this.configConstant.apiStoreURL}/getreview`, {
        params: searchParams,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  // Preffered Driver Section

  getPreferredDriverLists() {
    return this.http
      .get(`${this.configConstant.apiStoreURL}/GetPreferableDriverList`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  searchDriverData(formData) {
    return this.http
      .get(`${this.configConstant.apiStoreURL}/SearchPreferableDriver`, {
        params: formData,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  addPreferrableDriver(driverID) {
    return this.http
      .post(
        `${this.configConstant.apiStoreURL}/AddPreferableDriver`,
        {},
        {
          params: { driverId: driverID },
        }
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  addPreferrableDeliveryCompany(deliveryCompanyID) {
    return this.http
      .post(
        `${this.configConstant.apiStoreURL}/AddPreferableDeliveryCompany`,
        {},
        {
          params: { deliveryCompanyId: deliveryCompanyID },
        }
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  removePreferrableDriver(preferableId) {
    return this.http
      .post(
        `${this.configConstant.apiStoreURL}/RemovePreferableDriver`,
        {},
        {
          params: { PreferableId: preferableId },
        }
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  // End Preffered Driver Section

  // Store Pickup location
  getPickupaddressLists() {
    return this.http
      .get(`${this.configConstant.apiStoreURL}/getpickupaddresses`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  searchAddress(searchText: string) {
    if (searchText) {
      const searchParams = new HttpParams()
        .set("text", searchText)
        .set("maxSuggestions", "20")
        .set("f", "json");

      return this.http
        .get<AutocompleteAddress[]>(
          "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest",
          {
            params: searchParams,
          }
        )
        .pipe(
          map((data) => {
            return data["suggestions"];
          })
        );
    } else {
      return of([]).pipe();
    }
  }

  getAddressDetail(addressId, addressLine) {
    const params = new HttpParams()
      .set("forStorage", "false")
      .set("outFields", "*")
      .set("magicKey", addressId)
      .set("SingleLine", addressLine)
      .set("f", "json");

    return this.http
      .get<StoreAddressDetail>(
        "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates",
        {
          params: params,
        }
      )
      .pipe(
        map((data) => {
          let addressDetail: StoreAddressDetail = null;
          if (data["candidates"] && data["candidates"].length > 0) {
            addressDetail = data["candidates"][0] as StoreAddressDetail;
          }
          return addressDetail;
        })
      );
  }

  addPickupLocation(formData) {
    return this.http
      .post(`${this.configConstant.apiStoreURL}/addpickupaddress`, formData)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  updatePickupLocation(formData) {
    return this.http
      .post(`${this.configConstant.apiStoreURL}/updatepickupaddress`, formData)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  pickupLocationAction(action, formData) {
    if (action === "update") {
      return this.updatePickupLocation(formData);
    } else {
      return this.addPickupLocation(formData);
    }
  }

  getPickupAddressDetail(addressId: number) {
    return this.http
      .get(`${this.configConstant.apiStoreURL}/getpickupaddress/${addressId}`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  deletePickupAddress(id: number) {
    return this.http.delete(
      `${this.configConstant.apiStoreURL}/deletepickupaddress/${id}`
    );
  }

  // END
}
