import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ReactiveFormsModule } from "@angular/forms";

import { TranslateModule } from "@ngx-translate/core";
import { ComponentModule } from "common/_components/components.module";
import { MaterialModule } from "common/modules/material.module";
import { TemplateDefaultModule } from "../../shared/template-default/template-default.module";
import { DataTablesModule } from "angular-datatables";

import { ReturnRequestRoutingModule } from "./return-request-routing.module";
import { ListComponent } from "./list/list.component";
import { DetailComponent } from "./detail/detail.component";
import { PipeModule } from "common/_pipe/pipe.module";
import { PopoverModule } from "ngx-bootstrap";

@NgModule({
  declarations: [ListComponent, DetailComponent],
  imports: [
    CommonModule,
    ReturnRequestRoutingModule,
    ReactiveFormsModule,
    TranslateModule,
    ComponentModule,
    MaterialModule,
    TemplateDefaultModule,
    DataTablesModule,
    PipeModule,
    PopoverModule.forRoot(),
  ],
})
export class ReturnRequestModule {}
