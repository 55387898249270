import { Component, OnInit } from "@angular/core";

import { constEnv } from "common/constants.env";
import { LoaderService } from "common/services/loader.service";

import { TitleService } from "common/services/title.service";
import { first } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService } from "@full-fledged/alerts";

@Component({
  selector: "app-schedule",
  templateUrl: "./schedule.component.html",
  styleUrls: ["./schedule.component.css"],
})
export class ScheduleComponent implements OnInit {
  orderId = 0;
  listUrl: string;

  deliverySchedules = [];

  config = constEnv;

  selectedPatch: string;

  constructor(
    private titleService: TitleService,
    private route: ActivatedRoute,
    private router: Router,
    private alert: AlertService,
    private ls: LoaderService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Schedule Delivery");

    this.orderId =
      this.route.snapshot.params.orderId > 0
        ? this.route.snapshot.params.orderId
        : 0;

    this.listUrl = `${this.config.STORE_URL}/orders`;

    if (this.orderId <= 0) {
      this.router.navigate([this.listUrl]);
      return false;
    }

    this.getDeliverySchedule();
  }

  getDeliverySchedule() {
    this.deliverySchedules = [
      {
        schedule: 100,
        name: "Patch 1",
        date: "28 October, 2020",
        items: Array(10)
          .fill(10)
          .map((x, i) => i),
      },
      {
        schedule: 101,
        name: "Patch 2",
        date: "29 October, 2020",
        items: Array(5)
          .fill(5)
          .map((x, i) => i),
      },
    ];

    console.log(this.deliverySchedules);
  }
}
