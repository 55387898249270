import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSelectionList } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { constEnv } from "common/constants.env";
import { AuthenticationService } from "common/services/authentication.service";
import { CustomerService } from "common/services/customers/customer.service";
import { GeneralService } from "common/services/general.service";
import { LoaderService } from "common/services/loader.service";
import { TitleService } from "common/services/title.service";
import { AlertService } from "ngx-alerts";
import { first } from "rxjs/operators";

@Component({
  selector: "app-support-cancel-order",
  templateUrl: "./support-cancel-order.component.html",
  styleUrls: ["./support-cancel-order.component.css"],
})
export class SupportCancelOrderComponent implements OnInit {
  currentYear = new Date().getFullYear();
  config = constEnv;
  logoUrl;
  siteTitle;
  token: string = "";

  orderId: number = 0;

  orderInfo = [];
  patches = [];

  isOrderFound: boolean = false;
  currentUserProfile;

  @ViewChild("allCancelItemSelected", { static: false })
  public allCancelItemSelected: MatSelectionList;

  allCancelItemComplete: boolean = false;
  cancelOrderItems: any[] = [];
  cancelOrderCurrencyCode: string = "";
  cancelRequestForm: FormGroup;
  isOpenCancelRequestModal: boolean = false;
  cancelReasonOptions: any = [];
  homeUrl = `${this.config.BASE_URL}home`;

  constructor(
    private titleService: TitleService,
    private gs: GeneralService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private ls: LoaderService,
    private authenticationService: AuthenticationService,
    private alert: AlertService,
    private customer: CustomerService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Cancel Order");
    this.token =
      this.route.snapshot.params.token !== ""
        ? this.route.snapshot.params.token
        : "";

    if (this.token === "") {
      this.router.navigate([this.homeUrl]);
      return false;
    }

    const decodedToken = atob(this.token).split("_");
    this.orderId = parseInt(decodedToken[0]) || 0;
    if (this.orderId <= 0) {
      this.router.navigate([this.homeUrl]);
      return false;
    }
    const tokenDateTime = new Date(decodedToken[1]);
    this.currentUserProfile =
      this.authenticationService.currentUserProfileValue;
    // console.log(orderId, tokenDateTime);

    this.logoUrl = this.gs.getSiteLogo(true);
    this.siteTitle = this.config.projectTitle;
    this.getOrderDetail();
  }

  getOrderDetail() {
    this.ls.showLoader();
    this.customer
      .getCustomerOrderDetail(this.orderId)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data["status"] === true) {
            this.orderInfo = data["data"];
            this.patches = this.orderInfo["patchDtos"];
            // console.log(data["data"]);
            this.isOrderFound = true;
            if (
              this.currentUserProfile.cust_id !== this.orderInfo["customerId"]
            ) {
              this.alert.danger("You are not authorized to view this page.");
              this.router.navigate([this.homeUrl]);
            }
            if (this.getCancelableItems.length <= 0) {
              this.router.navigate([this.homeUrl]);
            }
            this.openCancelOrderPopup();
            this.ls.hideLoader();
          } else {
            this.alert.danger(data["message"]);
            this.ls.hideLoader();
            this.isOrderFound = false;
            this.router.navigate([this.homeUrl]);
          }
        },
        (err) => {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          if (msg !== "") {
            this.alert.danger(err.error.message);
          }
          this.isOrderFound = false;
          this.router.navigate([this.homeUrl]);
          this.ls.hideLoader();
        }
      );
  }

  get getCancelableItems(): any {
    const validCancelStatus = [4, 5, 9, 11];
    const cancelableItems = [];
    if (this.orderInfo) {
      this.orderInfo["patchDtos"].map((patch) => {
        patch.itemList.map((item) => {
          if (validCancelStatus.indexOf(item.statusId) !== -1) {
            cancelableItems.push(item);
          }
        });
      });
    }
    return cancelableItems;
  }

  private makeCancelRequestForm() {
    this.cancelRequestForm = this.fb.group({
      Phone: [null, Validators.required],
      ItemOptionId: [null, Validators.required],
      Description: [""],
      Attachment: [null],
    });
  }

  getCancelReasonOption() {
    this.cancelReasonOptions = [];
    this.gs
      .getCancelReasonOptions()
      .pipe(first())
      .subscribe(
        (data) => {
          if (data["status"] === true) {
            this.cancelReasonOptions = data["data"];
          } else {
            this.alert.danger(data["message"]);
          }
        },
        (err) => {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          if (msg !== "") {
            this.alert.danger(err.error.message);
          }
        }
      );
  }

  openCancelOrderPopup() {
    this.cancelOrderItems = this.getCancelableItems;
    this.cancelOrderCurrencyCode =
      (this.cancelOrderItems.length > 0 &&
        this.cancelOrderItems[0].currencyCode) ||
      "";
    this.isOpenCancelRequestModal = true;
    this.getCancelReasonOption();
    this.makeCancelRequestForm();

    setTimeout(() => {
      this.cancelRequestForm
        .get("Phone")
        .setValue(this.orderInfo["customerPhone"]);
    }, 100);
  }

  toggleCancelSelection(completed: boolean) {
    this.allCancelItemComplete = completed;
    if (completed) {
      this.allCancelItemSelected.selectAll();
    } else {
      this.allCancelItemSelected.deselectAll();
    }
  }

  isAllCancelSelected() {
    return (
      this.allCancelItemSelected.selectedOptions.selected.length ===
      this.allCancelItemSelected.options.length
    );
  }

  onCancelSelectionListChange(event) {
    this.allCancelItemComplete = this.isAllCancelSelected();
  }

  someCancelComplete() {
    return (
      this.allCancelItemSelected &&
      this.allCancelItemSelected.selectedOptions.selected.length > 0 &&
      !this.allCancelItemComplete
    );
  }

  isCancelFormValid(): boolean {
    if (this.cancelRequestForm.invalid) {
      return false;
    }

    if (this.cancelOrderItems.length === 0) {
      return false;
    }

    if (
      this.allCancelItemSelected &&
      this.allCancelItemSelected.selectedOptions.selected.length === 0
    ) {
      return false;
    }

    return true;
  }

  onCancelRequestSubmit() {
    if (!this.isCancelFormValid()) {
      return;
    }

    const selectedItems =
      this.allCancelItemSelected.selectedOptions.selected.map(
        (item) => item.value
      );

    const orderDetailIdSet = [];
    selectedItems.forEach((item) => {
      orderDetailIdSet.push(item.orderDetailId);
    });

    const formData = new FormData();
    formData.append("ItemOptionId", this.cancelRequestForm.value.ItemOptionId);
    formData.append(
      "OrderDetailId",
      this.allCancelItemComplete ? "" : orderDetailIdSet.join(",")
    );
    formData.append("StoreId", "0");
    formData.append("OrderBaseDetailId", this.orderInfo["orderId"]);
    formData.append("Description", this.cancelRequestForm.value.Description);
    formData.append("Attachment", this.cancelRequestForm.value.Attachment);
    formData.append("Phone", this.cancelRequestForm.value.Phone);

    this.ls.showLoader();
    this.customer
      .cancelRequestTickets(formData)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data["status"] === true) {
            this.alert.success(data["message"]);
            this.ls.hideLoader();
          } else {
            this.alert.danger(data["message"]);
            this.ls.hideLoader();
          }
          this.router.navigate([this.homeUrl]);
        },
        (err) => {
          if (typeof err.error !== "undefined") {
            const msg =
              typeof err.error.Message !== "undefined"
                ? err.error.Message
                : err.error.message;
            this.alert.danger(msg);
          }
          this.router.navigate([this.homeUrl]);
          this.ls.hideLoader();
        }
      );
  }
}
