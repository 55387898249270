import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  IterableDiffers,
  ViewChild,
} from "@angular/core";
import { constEnv } from "common/constants.env";
// import { TitleService } from 'common/services/title.service';

import { DOCUMENT } from "@angular/common";

import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  AbstractControl,
} from "@angular/forms";
import { first } from "rxjs/operators";
import { AlertService } from "@full-fledged/alerts";
import { CustomValidator } from "common/_helpers/custom.validator";
import { IsLoadingService } from "@service-work/is-loading";
import { Observable } from "rxjs";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit, OnDestroy {
  env = constEnv;
  regiForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  countryCode: string;
  countryIsoCode: string;
  validMobileNo = true;

  isLoading: Observable<boolean>;

  iniObj: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    // private titleService: TitleService,
    private isLoadingService: IsLoadingService
  ) {}

  ngOnInit() {
    // this.titleService.setTitle('Sign Up');
    this.isLoading = this.isLoadingService.isLoading$();

    this.document.body.classList.add("page-register");

    this.createSignUpFrom();

    this.returnUrl =
      this.route.snapshot.queryParams.returnUrl || `${this.env.STORE_URL}`;
  }

  ngOnDestroy(): void {
    this.document.body.classList.remove("page-register");

    // this.socialSubcription.unsubscribe();
  }

  createSignUpFrom() {
    this.regiForm = this.formBuilder.group(
      {
        firstName: ["", Validators.required],
        lastName: ["", Validators.required],
        mobileNo: ["", Validators.required],
        countryCode: this.countryCode,
        countryIsoCode: this.countryIsoCode,
        email: new FormControl(
          "",
          Validators.compose([
            Validators.required,
            Validators.email,
            Validators.pattern(
              "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"
            ),
          ])
        ),
        password: ["", Validators.required],
        terms: ["", Validators.required],
        // recaptchaReactive: new FormControl(null, Validators.required)
      },
      { validator: CustomValidator.checkTerms }
    );
  }

  get formData() {
    return this.regiForm.controls;
  }

  hasError(obj) {
    if (!obj && this.countryIsoCode === "ae") {
      const selectedNumber = this.iniObj.getNumber();
      this.validMobileNo =
        /^(?:\+971|00971|0)?(?:50|52|53|54|55|56|57|58|59|2|3|4|6|7|9)\d{7}$/gm.test(
          selectedNumber
        );
    } else {
      this.validMobileNo = obj;
    }
  }

  onCountryChange(obj) {
    this.countryCode = obj.dialCode;
    this.countryIsoCode = obj.iso2;
  }

  telInputObject(obj) {
    this.iniObj = obj;
    const countryData = obj.getSelectedCountryData();
    this.countryCode = countryData.dialCode;
    this.countryIsoCode = countryData.iso2;
  }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    // this.alertService.clear();

    // stop here if form is invalid
    if (this.regiForm.invalid) {
      return;
    }

    if (!this.validMobileNo) {
      return;
    }

    this.loading = true;
    this.isLoadingService.add();

    this.regiForm.value.countryCode = "+" + this.countryCode;
    this.regiForm.value.countryIsoCode = this.countryIsoCode;
    console.log("submitted..");
    setTimeout(() => {
      this.isLoadingService.remove();
      this.loading = false;
    }, 2000);
  }
}
