import { KeyValue } from "@angular/common";

export class CartItem {
    additionalPackingCharges: string;
    afterDiscountYouSave: string;
    allowOutOfStockOrders: boolean;
    beforeDiscount: string;
    currencyCode: string;
    expectedDeliveryDate: Date;
    deliveryExpected: string;
    deliveryExpectedDate: string;
    deliveryExpectedDays: string;
    deliveryPlan: DeliveryPlan;
    deliveryPlanDate: string;
    discount: string;
    hasOffer: boolean;
    hasRating: boolean;
    imageUrl: string;
    isActive: boolean;
    isDeleted: boolean;
    isVariable: boolean;
    itemQty: number;
    itemStock: string;
    itemTypeId: number;
    message: string;
    offerDiscount: number;
    offerType: string;
    productId: number;
    productName: string;
    productOfferAmt: number;
    productPrice: number;
    productPriceAfterDiscount: number;
    productRating: number;
    productVariationId: number;
    productVariationIsDeleted: boolean;
    promocodeDiscount: number;
    recordId: number;
    status: boolean;
    stockQuantity: number;
    storeAddress: string;
    storeId: number;
    storeName: string;
    totalRatings: number;
    variationId: number;
    
    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}

export interface DeliveryPlan {
    date: string;
    days: string;
    deliveredOn: string;
    minDays: number;
    orderPreparationPeriod: KeyValuePair;
}

export interface KeyValuePair {
    key: string;
    value: string;
}