export class SubCategoryModal {
    categoryId: number;
    image: string;
    isDelivarbleType: boolean;
    langCode: string;
    langId: number;
    status: boolean;
    subCategoryId: number;
    subCategoryName: string;
    
    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
