import { Component, OnInit } from "@angular/core";
import { GeneralService } from "common/services/general.service";
import { constEnv } from "common/constants.env";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit {
  currentYear: number;
  siteTitle: string;
  config = constEnv;
  logoUrl;

  constructor(public gs: GeneralService) {}

  ngOnInit() {
    this.currentYear = new Date().getFullYear();

    this.siteTitle = this.gs.getSettings("title");
    this.logoUrl = this.gs.getSiteLogo(true);
  }
}
