import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { constEnv } from 'common/constants.env';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class HelpSupportService {
    configConstant = constEnv;
    constructor(private http: HttpClient) {
    }

    getAll( dataTablesParameters ) {

        let searchValue = ''; let searchBy = '';
        if ( dataTablesParameters.search.value !== '' ) {
            searchValue = dataTablesParameters.search.value;
            searchBy = dataTablesParameters.search.column;
        }

        const pageNo = (dataTablesParameters.start * 1) / (dataTablesParameters.length * 1);

        const searchParams = new HttpParams()
            .set('searchColumn', searchBy)
            .set('searchValue', searchValue)
            .set('pageNo', pageNo.toString())
            .set('pageSize', dataTablesParameters.length)
            .set('sortColumn', '')
            .set('sortOrder', '');

        return this.http.get(`${this.configConstant.apiStoreURL}/gesupportlist`, {
            params: searchParams
        })
            .pipe(map(data => {
                return data;
            }));
    }

    get( Id ) {
        return this.http.get(`${this.configConstant.apiStoreURL}/getticketbyid/${Id}`)
            .pipe(map(data => {
                return data;
            }));
    }

    add( formData ) {
        return this.http.post(`${this.configConstant.apiStoreURL}/appsupport`, formData)
            .pipe(map(data => {
                return data;
            }));
    }

    // update( formData ) {
    //     return this.http.post(`${this.configConstant.apiStoreURL}/category/update`, formData)
    //         .pipe(map(data => {
    //             return data;
    //         }));
    // }

    delete(id: number) {
        return this.http.post(`${this.configConstant.apiStoreURL}/category/delete/${id}`, {});
    }

    changeStatus(cateID: number, status: number) {
        return this.http.post(`${this.configConstant.apiStoreURL}/category/status/${cateID}/${status}`, {});
    }

}
