import { Component, OnInit } from "@angular/core";
import { IsLoadingService } from "@service-work/is-loading";
import { constEnv } from "common/constants.env";
import { LanguageService } from "common/services/language.service";
import { SiteGeneralService } from "common/services/site/siteGeneral.service";
import { TitleService } from "common/services/title.service";
import { HomeBanner } from "common/_models/site/home-banner.modal";
import { Home } from "common/_models/site/Home.modal";
import { ProductList } from "common/_models/site/product-list.modal";
import { SubCategoryModal } from "common/_models/site/sub-category.modal";
import { AlertService } from "@full-fledged/alerts";
import { OwlOptions } from "ngx-owl-carousel-o";
import { SiteProductService } from "common/services/site/siteProduct.service";
import { ProductFilerSortParam } from "../category/category.component";
import { ProductFilterModal } from "common/_models/site/product-filter.modal";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  config = constEnv;

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    margin: 15,
    autoplay: false,
    rtl: false,
    navSpeed: 700,
    navText: [
      "<i class='la la-long-arrow-alt-left'></i>",
      "<i class='la la-long-arrow-alt-right'></i>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      500: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
    nav: true,
  };

  productSlide: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    margin: 15,
    rtl: false,
    autoplay: true,
    navText: [
      "<i class='la la-long-arrow-alt-left'></i>",
      "<i class='la la-long-arrow-alt-right'></i>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      500: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
    nav: true,
  };

  hotDealSlider: OwlOptions = this.productSlide;
  newArrivalSlider: OwlOptions = this.productSlide;
  bestSellerSlider: OwlOptions = this.productSlide;

  bannerSliders: HomeBanner[] = null;
  popularCategories: SubCategoryModal[] = null;
  newArrivals: ProductList[] = null;
  bestSellers: ProductList[] = null;
  hotDeals: ProductList[] = null;

  hotDealsProductParams = {
    SearchColumn: "",
    SearchValue: "",
    SortColumn: "",
    BrandIds: "",
    SubCategoryIds: "",
    AttributeValueIds: "",
    CategoryId: 0,
    PageNo: 0,
    PageSize: 10,
  } as ProductFilerSortParam;

  constructor(
    private isLoadingService: IsLoadingService,
    private titleService: TitleService,
    private alert: AlertService,
    private general: SiteGeneralService,
    private product: SiteProductService,
    private ls: LanguageService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Home");

    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }

    this.customOptions.rtl =
      this.ls.getCurrentLanguageDrirection() === "rtl" ? true : false;
    this.productSlide.rtl =
      this.ls.getCurrentLanguageDrirection() === "rtl" ? true : false;

    this.getHomeSections();
    this.getHotDeals();
  }

  getHomeSections() {
    this.isLoadingService.add();
    this.general.getHome().subscribe(
      (data) => {
        const res = data as HomeSectionResponse;
        if (res.status === true) {
          this.bannerSliders = res.data.banners.map((item) =>
            new HomeBanner().deserialize(item)
          );
          this.popularCategories = res.data.subCategories.map((cat) =>
            new SubCategoryModal().deserialize(cat)
          );
          this.newArrivals = res.data.productList.map((list) =>
            new ProductList().deserialize(list)
          );
          this.bestSellers = res.data.bestSellerProductList.map((list) =>
            new ProductList().deserialize(list)
          );

          if (
            this.newArrivalSlider &&
            this.newArrivals.length < 4 &&
            !this.isMobileDevice()
          ) {
            this.newArrivalSlider.loop = false;
            this.newArrivalSlider.autoplay = false;
          }

          if (
            this.bestSellerSlider &&
            this.bestSellers.length < 4 &&
            !this.isMobileDevice()
          ) {
            this.bestSellerSlider.loop = false;
            this.bestSellerSlider.autoplay = false;
          }
        } else {
          this.alert.danger(res.message);
        }
        this.isLoadingService.remove();
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          // this.alert.danger(err.error.message);
        }
        this.isLoadingService.remove();
      }
    );
  }

  getHotDeals() {
    this.product.getHotDealList(this.hotDealsProductParams).subscribe(
      (data) => {
        const res = data as HotDealListResponse;
        if (res.status === true) {
          if (res.data.productList !== null) {
            this.hotDeals = res.data.productList.map((item) =>
              new ProductList().deserialize(item)
            );

            if (
              this.hotDealSlider &&
              this.hotDeals.length < 4 &&
              !this.isMobileDevice()
            ) {
              this.hotDealSlider.loop = false;
              this.hotDealSlider.autoplay = false;
            }
          } else {
            this.hotDeals = [];
          }
        } else {
          this.alert.danger(res.message);
        }
        this.isLoadingService.remove();
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          // this.alert.danger(err.error.message);
        }
        this.hotDeals = [];
        this.isLoadingService.remove();
      }
    );
  }

  isMobileDevice() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true;
    } else {
      // false for not mobile device
      return false;
    }
  }
}

interface HomeSectionResponse {
  status: boolean;
  message: string;
  data: Home;
  paging: string;
}

interface HotDealListResponse {
  status: boolean;
  message: string;
  data: ResponseData;
  paging: string;
}

interface ResponseData {
  bannerList: any[];
  productList: ProductList[];
  totalProductCount: number;
  sortByList: any[];
  subCategoryIds: string;
  brandIds: string;
  attributeValueIds: string;
  themeIds: string;
  colorPaletteIds: string;
  categoryName: string;
  subCategoryDetails: any[];
  storeDetails: string;
  filters: ProductFilterModal;
  minPrice: number;
  maxPrice: number;
}
