import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { constEnv } from "common/constants.env";
// import { DcEmailVerifyComponent } from './dc-email-verify/dc-email-verify.component';
import { DcForgotPasswordComponent } from "./dc-forgot-password/dc-forgot-password.component";
import { DcLoginComponent } from "./dc-login/dc-login.component";
import { DcResetPasswordComponent } from "./dc-reset-password/dc-reset-password.component";
// import { DcRegisterComponent } from './dc-register/dc-register.component';

const DeliveryCompanyPrefix = constEnv.deliveryCompanyEndPoint;

const routes: Routes = [
  { path: `${DeliveryCompanyPrefix}/login`, component: DcLoginComponent },
  // { path: `${DeliveryCompanyPrefix}/register` , component: DcRegisterComponent },
  {
    path: `${DeliveryCompanyPrefix}/forgot-password`,
    component: DcForgotPasswordComponent,
  },
  {
    path: `${DeliveryCompanyPrefix}/reset-password/:token`,
    component: DcResetPasswordComponent,
  },
  // { path: `${DeliveryCompanyPrefix}/email-verify/:token` , component: DcEmailVerifyComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DeliveryCompanyRoutingModule {}
