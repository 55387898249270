import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { constEnv } from 'common/constants.env';
import { LanguageService } from './language.service';

import { StoreUser } from 'common/_models/store/storeUser';
// import { AuthService } from 'angularx-social-login';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { AuthUser } from 'common/_models/AuthUser.model';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public currentUserSubject: BehaviorSubject<AuthUser>;
  private currentUserProfileSubject: BehaviorSubject<StoreUser>;
  public currentUser: Observable<AuthUser>;
  public currentUserProfile: Observable<StoreUser>;

  constantConfig;

  constructor(
    private http: HttpClient,
    private langService: LanguageService,
   //  private authService: AuthService,
  ) {
    this.constantConfig = constEnv;
    this.currentUserSubject = new BehaviorSubject<AuthUser>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): AuthUser {
    return this.currentUserSubject.value;
  }

  public get currentUserProfileValue() {
    return JSON.parse(localStorage.getItem('currentUserProfile'));
  }

  login(email, password, type, language = 'en-US') {
    const grantType = 'password';

    const params = new HttpParams()
      .set('Email', email)
      .set('Password', password)
      .set('UserType', type);
      // .set('grant_type', grantType)
      // .set('language', language);

    const requestHeader = {
      'Content-Type': 'application/x-www-form-urlencoded'
    };

    return this.http.post<AuthUser>(`${this.constantConfig.apiLoginURL}`, params, {headers: requestHeader} )
            .pipe(map(user => {
                if (user['status'] === true) {
                  localStorage.setItem('currentUser', JSON.stringify(user['data']));
                  this.currentUserSubject.next(user['data']);
                }

                return user['data'];
            }));

  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentUserProfile');
    this.currentUserSubject.next(null);
    // this.authService.signOut();
    // this.langService.changeLanguage(this.langService.defaultLanguage);
  }

  userLanguagePreference( currentLangId ) {    
    const params1 = new HttpParams()
      .set('UserType', this.currentUserValue.userdetails.userType)
      .set('LangId', currentLangId);

    return this.http.post(`${this.constantConfig.apiURL}/userlangpref`, {}, {
      params: params1
    } )
            .pipe(map(data => {
                return data;
            }));
  }

}
