import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  FormControl,
} from "@angular/forms";
import { CustomValidator } from "common/_helpers/custom.validator";
import { LoaderService } from "common/services/loader.service";
import { AlertService } from "@full-fledged/alerts";
import { SettingsService } from "common/services/store/settings.service";
import { TitleService } from "common/services/title.service";
import { AuthenticationService } from "common/services/authentication.service";
import { AuthUser, UserData } from "common/_models/AuthUser.model";
import { constEnv } from "common/constants.env";
import { GeneralService } from "common/services/general.service";
import { LanguageService } from "common/services/language.service";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.css"],
})
export class ChangePasswordComponent implements OnInit {
  changePasswordFrom: FormGroup;
  submitted = false;
  loading = false;

  config = constEnv;

  public showOldPassword: boolean = false;
  public showNewPassword: boolean = false;
  public showConfirmPassword: boolean = false;

  currentUserDetail: UserData;
  isFirstTimeLogin: boolean = false;

  constructor(
    private fb: FormBuilder,
    private ls: LoaderService,
    private alert: AlertService,
    private settings: SettingsService,
    private titleService: TitleService,
    private authenticationService: AuthenticationService,
    private gs: GeneralService,
    private langService: LanguageService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("STORE_Change_Password");
    this.changePasswordFrom = this.fb.group(
      {
        oldPassword: ["", Validators.required],
        newPassword: new FormControl(
          "",
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(16),
            this.noWhitespaceValidator,
          ])
        ),
        confirmPassword: new FormControl(
          "",
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(16),
            this.noWhitespaceValidator,
          ])
        ),
      },
      { validator: CustomValidator.MatchPassword }
    );

    const currentUser = this.authenticationService.currentUserValue;
    this.currentUserDetail = currentUser.userdetails as UserData;
    this.isFirstTimeLogin = this.currentUserDetail.isFirstimeLogin;
  }

  get f() {
    return this.changePasswordFrom.controls;
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  getErrorNewPassword() {
    return this.changePasswordFrom.get("newPassword").hasError("required")
      ? ""
      : this.changePasswordFrom.get("newPassword").hasError("minlength")
      ? "PasswordFormate"
      : // ? this.titleService.getTranslatedString("PasswordFormate")
      this.changePasswordFrom.get("newPassword").hasError("maxlength")
      ? "PasswordFormate"
      : // ? this.titleService.getTranslatedString("PasswordFormate")
        "";
  }

  getErrorConfirmPassword() {
    return this.changePasswordFrom.get("confirmPassword").hasError("required")
      ? ""
      : this.changePasswordFrom.get("confirmPassword").hasError("minlength")
      ? "PasswordFormate"
      : // ? this.titleService.getTranslatedString("PasswordFormate")
      this.changePasswordFrom.get("confirmPassword").hasError("maxlength")
      ? "PasswordFormate"
      : // ? this.titleService.getTranslatedString("PasswordFormate")
      this.changePasswordFrom.get("confirmPassword").hasError("confirmPassword")
      ? "NewPasswordAndConfirmPasswordMustBeSame"
      : // ? this.titleService.getTranslatedString("NewPasswordAndConfirmPasswordMustBeSame")
        "";
  }

  onSubmit() {
    this.submitted = true;

    if (this.changePasswordFrom.invalid) {
      return;
    }

    this.ls.showLoader();
    this.loading = true;

    this.settings.changePassword(this.changePasswordFrom.value).subscribe(
      (data) => {
        if (data["status"] === true) {
          this.alert.success(data["message"]);
          this.submitted = false;
          this.changePasswordFrom.reset();
          this.ls.hideLoader();

          if (this.isFirstTimeLogin) {
            this.logout();
          }
        } else {
          this.alert.danger(data["message"]);
          this.ls.hideLoader();
        }
        this.loading = false;
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        this.alert.danger(msg);
        this.loading = false;
        this.ls.hideLoader();
      }
    );
  }

  logout() {
    this.ls.showLoader();
    // this.langService.changeLanguage(this.langService.defaultLanguage);
    this.authenticationService.logout();
    const loginURL = `${this.config.STORE_URL}/login`;

    const settingLang = this.gs.getSettings("default_language");

    const changeLangCode =
      settingLang !== "" ? settingLang : this.langService.defaultLanguage;

    this.langService.getAvailableLanguages().map((lang) => {
      if (changeLangCode === lang.lang_code) {
        this.langService._setLanguage(lang.lang_code);
        localStorage.setItem("defaultLang", JSON.stringify(lang));
      }
    });

    this.langService
      .getLanguageTranslation(this.langService.getCurrentLanguage())
      .subscribe((translationsJSON) => {
        this.langService.setLanguageTranslation(translationsJSON);
        window.location.href = loginURL;
      });
  }
}
