import { Component, OnInit } from "@angular/core";
import { constEnv } from "common/constants.env";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit {
  currentYear = new Date().getFullYear();
  siteTitle = constEnv.projectTitle;

  constructor() {}

  ngOnInit() {}
}
