import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { constEnv } from 'common/constants.env';
import { AuthGuard } from 'common/_helpers/auth.guard';

import { ListComponent } from './list/list.component';
import { AddComponent } from './add/add.component';
const _ENDPOINT = `${constEnv.storeEndPoint}/support`;

const routes: Routes = [
  {
    path: `${_ENDPOINT}`,
    children: [
      { path: 'add', component: AddComponent },
      //  {
      //     path: 'detail',
      //     children: [
      //        { path: ':orderId', component: DetailComponent },
      //        { path: '', redirectTo: `${ORDER_ENDPOINT}`, pathMatch: 'full'}
      //     ]
      //  },
       { path: '', component: ListComponent }
    ],
    canActivate: [AuthGuard]
 },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HelpSupportRoutingModule { }
