import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { constEnv } from 'common/constants.env';
import { AuthGuard } from 'common/_helpers/auth.guard';
import { ProfileComponent } from './profile/profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
const routeUrl = constEnv.customerEndPoint;

const routes: Routes = [
  {
    path: `${routeUrl}` ,
    children: [
      { path: 'profile' , component: ProfileComponent },
      { path: 'change-password' , component: ChangePasswordComponent },
      
      { path: '' , redirectTo: `/${routeUrl}/profile`, pathMatch: 'full' },
    ],
    canActivate: [AuthGuard]
 },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfileRoutingModule { }
