import { Component, OnInit, OnDestroy, Inject, ViewChild } from "@angular/core";
import { DOCUMENT } from "@angular/common";

import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { first } from "rxjs/operators";
import { AlertService } from "@full-fledged/alerts";
import { constEnv } from "common/constants.env";
import { IsLoadingService } from "@service-work/is-loading";
import { TitleService } from "common/services/title.service";
import { AuthenticationService } from "common/services/authentication.service";
import { StoreUserService } from "common/services/store/storeUser.service";
import { LanguageService } from "common/services/language.service";

import { BsModalRef, ModalDirective } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
import { GeneralService } from "common/services/general.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit, OnDestroy {
  env = constEnv;

  accountForm: FormGroup;
  accountSubmitted = false;
  validMobileNo = true;

  @ViewChild("autoShownModal", { static: false })
  autoShownModal: ModalDirective;

  modalRef: BsModalRef;
  socialSubcription: Subscription;

  countryCode: string;
  countryIsoCode: string;

  hasAccountEmail = false;

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  public showPassword: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private titleService: TitleService,
    private isLoadingService: IsLoadingService,
    private authenticationService: AuthenticationService,
    private langService: LanguageService,
    private general: GeneralService,
    private storeUser: StoreUserService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Sign_In");

    this.returnUrl =
      this.route.snapshot.queryParams.returnUrl ||
      `${this.env.STORE_URL}/dashboard`;

    if (this.authenticationService.currentUserValue) {
      this.router.navigate([this.returnUrl]);
    }

    this.document.body.classList.add("page-login");
    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }

    this.createLoginForm();
  }

  ngOnDestroy(): void {
    this.document.body.classList.remove("page-login");
  }

  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      email: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern(
            "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"
          ),
        ])
      ),
      password: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(16),
        ])
      ),
    });
  }

  getErrorEmail() {
    return this.loginForm.get("email").hasError("required")
      ? ""
      : this.loginForm.get("email").hasError("pattern")
      ? "EmailInValid"
      : // ? this.titleService.getTranslatedString("EmailInValid")
        "";
  }

  getErrorPassword() {
    return this.loginForm.get("password").hasError("required")
      ? ""
      : this.loginForm.get("password").hasError("minlength")
      ? "PasswordFormate"
      : // ? this.titleService.getTranslatedString("PasswordFormate")
      this.loginForm.get("password").hasError("maxlength")
      ? "PasswordFormate"
      : // ? this.titleService.getTranslatedString("PasswordFormate")
        "";
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.isLoadingService.add();

    this.authenticationService
      .login(this.f.email.value, this.f.password.value, "store")
      .pipe(first())
      .subscribe(
        async (data) => {
          const userdetail =
            typeof data.userdetails !== "undefined" ? data.userdetails : [];

          this.langService.changeLanguage(userdetail.languageCode);

          await this.general.getDefaultSettingsValue();

          this.storeUser
            .getProfile()
            .pipe(first())
            .subscribe(
              (profile) => {
                let redirectURL = this.returnUrl;
                if (data.addressId === "0") {
                  redirectURL = `${this.env.STORE_URL}/settings/store`;
                }

                if (userdetail.isFirstimeLogin) {
                  redirectURL = `${this.env.STORE_URL}/settings/change-password`;
                }

                setTimeout(() => {
                  window.location.href = redirectURL;
                }, 2500);
              },
              (err) => {
                this.alertService.danger(err.error.message);
                this.loading = false;
                this.isLoadingService.remove();
              }
            );
        },
        (err) => {
          let errorMsg = "";
          if (typeof err.error.Message !== "undefined") {
            errorMsg = err.error.Message;
          } else if (typeof err.error.error_description !== "undefined") {
            errorMsg = err.error.error_description;
          } else if (typeof err.error.message !== "undefined") {
            errorMsg = err.error.message;
          }
          this.submitted = false;
          if (errorMsg == "Password not match") {
            this.loginForm.get("password").reset();
          } else {
            this.loginForm.reset();
          }
          if (errorMsg !== "") {
            // this.alertService.error(errorMsg);
            this.alertService.danger(errorMsg);
          }
          this.loading = false;
          this.isLoadingService.remove();
        }
      );
  }
}
