import { Component, OnInit, ViewChild } from "@angular/core";

import { constEnv } from "common/constants.env";
import { TitleService } from "common/services/title.service";
import { PromoCodeService } from "common/services/store/promo-code.service";
import { first } from "rxjs/operators";
import { AlertService } from "ngx-alerts";
import { ActivatedRoute, Router } from "@angular/router";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { LoaderService } from "common/services/loader.service";
import { LanguageService } from "common/services/language.service";
import { IsLoadingService } from "@service-work/is-loading";
import { CustomValidator } from "common/_helpers/custom.validator";
import { GeneralService } from "common/services/general.service";

@Component({
  selector: "app-action",
  templateUrl: "./action.component.html",
  styleUrls: ["./action.component.css"],
})
export class ActionComponent implements OnInit {
  config = constEnv;

  action: string;
  id: number;
  listUrl: string;
  pageTitle: string;

  actionForm: FormGroup;
  loading = false;
  submitted = false;
  customerLists: any[] = [];

  isCustomValidity = false;
  isDiscountPer = false;

  defaultLamguage: any;
  allLanguages: any[] = [];
  availableLangs = [];

  currentDate = new Date();
  expiredDate = new Date();

  showUsageLimit = true;
  makeCustomerReadOnly = false;

  public defaultCurrency = "";

  constructor(
    private titleService: TitleService,
    private pc: PromoCodeService,
    private alert: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private ls: IsLoadingService,
    private lang: LanguageService,
    private gs: GeneralService
  ) {}

  ngOnInit() {
    this.action = this.route.snapshot.params.action;
    this.listUrl = `${this.config.STORE_URL}/promo-code`;

    this.defaultCurrency = this.gs.getDefaultCurrency();

    this.defaultLamguage = this.lang.getDefaultLanguage();
    this.allLanguages = this.lang.getAvailableLanguages();

    this.checkValidAction();
  }

  private checkValidAction() {
    if (this.action !== "add" && this.action !== "edit") {
      this.router.navigate([this.listUrl]);
    }

    this.makeForm();
    if (this.action === "edit") {
      this.id =
        typeof this.route.snapshot.params.id !== "undefined" &&
        this.route.snapshot.params.id > 0
          ? this.route.snapshot.params.id
          : 0;
      if (this.id <= 0) {
        this.router.navigate([this.listUrl]);
      }

      this.pageTitle = "EditPromoCode";

      this.getPromoCode();
    } else {
      this.pageTitle = "AddPromoCode";
    }

    this.titleService.setTitle(this.pageTitle);
    this.getCustomerLists();
  }

  getCustomerLists() {
    this.pc.getCustomerList().subscribe(
      (data) => {
        if (data["status"]) {
          this.customerLists = data["data"];
          this.ls.remove();
        } else {
          // this.alert.danger(data['message']);
          this.customerLists = [];
          this.ls.remove();
        }
      },
      (err) => {
        if (typeof err.error !== "undefined") {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          // this.alert.danger(msg);
        }
        this.customerLists = [];
        this.ls.remove();
      }
    );
  }

  private makeForm() {
    this.actionForm = this.fb.group(
      {
        id: [0],
        couponCode: ["", Validators.required],
        customer: [null],
        description: ["", Validators.required],
        discount: ["", Validators.required],
        discountUnit: [null, Validators.required],
        UsageLimit: ["", Validators.required],
        Validity: ["Permanent", Validators.required],
        ActivationDate: [""],
        ExpiryDate: [""],
        Status: [null, Validators.required],
        maxDiscount: [""],
        targetAmount: new FormControl("", [Validators.required]),
        languagesArray: this.fb.array([]),
        defaultLanguageId: [""],
      },
      {
        validators: [
          CustomValidator.CheckTargetAmount,
          CustomValidator.checkDiscount,
          CustomValidator.OnlyZeroNotAllowed("maxDiscount"),
          CustomValidator.OnlyZeroNotAllowed("targetAmount"),
          CustomValidator.OnlyZeroNotAllowed("UsageLimit"),
        ],
      }
    );

    this.addAvailableLanguages();

    this.actionForm.get("defaultLanguageId").setValue(this.defaultLamguage.id);
  }

  addAvailableLanguages() {
    this.allLanguages.forEach((singleLang) => {
      if (singleLang.id !== this.defaultLamguage.id) {
        this.availableLangs.push(singleLang);

        this.l.push(
          this.fb.group({
            languagedescription: [""],
            lang_id: singleLang.id,
            defaultLang: singleLang.default_lang,
          })
        );
      }
    });
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  get f() {
    return this.actionForm.controls;
  }
  get l() {
    return this.f.languagesArray as FormArray;
  }

  changeActivationDate($event) {
    this.expiredDate = $event.value;
  }

  getPromoCode() {
    this.ls.add();
    this.pc.get(this.id).subscribe(
      (data) => {
        if (data["status"]) {
          this.setFormData(data["data"]);

          this.ls.remove();
        } else {
          this.alert.danger(data["message"]);
          this.ls.remove();
        }
      },
      (err) => {
        if (typeof err.error !== "undefined") {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          this.alert.danger(msg);
        }
        this.ls.remove();
      }
    );
  }

  getErrorTargetAmount() {
    return this.actionForm.get("targetAmount").hasError("required")
      ? ""
      : this.actionForm.get("targetAmount").hasError("targetAmountLess")
      ? this.titleService.getTranslatedString("TargetAmountGreaterValidation")
      : this.actionForm.get("targetAmount").hasError("zeroError")
      ? this.titleService.getTranslatedString("Targetamountvalidation")
      : "";
  }

  getErrorDiscount() {
    return this.actionForm.get("discount").hasError("required")
      ? ""
      : this.actionForm.get("discount").hasError("discountPer")
      ? this.titleService.getTranslatedString("DiscountPercentValidation")
      : this.actionForm.get("discount").hasError("discountFlat")
      ? this.titleService.getTranslatedString("MaximumDiscount")
      : "";
  }

  getErrorMaxDiscount() {
    return this.actionForm.get("maxDiscount").hasError("required")
      ? ""
      : this.actionForm.get("maxDiscount").hasError("zeroError")
      ? this.titleService.getTranslatedString("MaximumDiscount")
      : "";
  }

  getErrorUsageLimit() {
    return this.actionForm.get("UsageLimit").hasError("required")
      ? ""
      : this.actionForm.get("UsageLimit").hasError("zeroError")
      ? this.titleService.getTranslatedString("UsageLimitValue")
      : "";
  }

  setFormData(formData) {
    let activationDate;
    if (formData.activationdate !== "") {
      // const myDate = formData.activationdate.split('-');
      // const a_date = new Date(myDate[2]+'-'+myDate[1]+'-'+myDate[0]);
      // const m = ((a_date.getMonth() * 1) + 1);
      // const month = (m >= 1 && m <= 9) ? '0'+m : m;
      // const day = (a_date.getDate() >= 1 && a_date.getDate() <= 9) ? '0'+a_date.getDate() : a_date.getDate();
      // activationDate = new Date(a_date.getFullYear()+'-'+month+'-'+day);
      activationDate = new Date(formData.activationdate);
    }

    let expiryDate;
    if (formData.expirydate !== "") {
      // const myeDate = formData.expirydate.split('-');
      // const e_date = new Date(myeDate[2]+'-'+myeDate[1]+'-'+myeDate[0]);
      // const em = ((e_date.getMonth() * 1) + 1);
      // const emonth = (em >= 1 && em <= 9) ? '0'+em : em;
      // const day = (e_date.getDate() >= 1 && e_date.getDate() <= 9) ? '0'+e_date.getDate() : e_date.getDate();
      // expiryDate = new Date(e_date.getFullYear()+'-'+emonth+'-'+day);
      expiryDate = new Date(formData.expirydate);
    }

    const fieldData = {
      id: formData.pid,
      couponCode: formData.giftcode,
      customer: formData.customerId > 0 ? formData.customerId : null,
      // description: formData.description,
      discount: formData.discount,
      discountUnit: formData.type1,
      maxDiscount: formData.maxDiscount > 0 ? formData.maxDiscount : "",
      targetAmount: formData.targetAmount,
      UsageLimit: formData.usagelimit,
      Validity: formData.validity,
      ActivationDate: activationDate,
      ExpiryDate: expiryDate,
      Status: formData.status,
    };

    if (formData.customerId > 0) {
      this.onCustomerChange(formData.customerId);
      // this.actionForm.get('customer').disable();
      this.makeCustomerReadOnly = true;
    }

    this.changeDiscountUnit(formData.type1);

    if (formData.validity === "Custom") {
      this.isCustomValidity = true;

      this.expiredDate = activationDate != "" ? activationDate : "";
    }

    this.actionForm.patchValue(fieldData);

    if (formData.languages.length > 0) {
      formData.languages.forEach((singleLang, index) => {
        if (singleLang.langKey == this.defaultLamguage.id) {
          const productDesc =
            singleLang.langValue != ""
              ? singleLang.langValue
              : formData.description;
          this.actionForm.get("description").setValue(productDesc);
          this.actionForm
            .get("defaultLanguageId")
            .setValue(this.defaultLamguage.id);
        } else {
          this.l.controls.forEach((singleRow, i) => {
            if (singleRow.value.lang_id == singleLang.langKey) {
              this.l.controls[i]
                .get("languagedescription")
                .setValue(singleLang.langValue);
            }
          });
        }
      });
    } else {
      this.actionForm.get("description").setValue(formData.description);
    }
  }

  changeDiscountUnit($event) {
    if ($event == "Per") {
      this.isDiscountPer = true;
      this.actionForm.get("maxDiscount").setValidators([Validators.required]);
      if (this.actionForm.get("maxDiscount").value !== "") {
        this.actionForm.get("maxDiscount").markAsTouched({ onlySelf: true });
      }
      this.actionForm.get("maxDiscount").updateValueAndValidity();
    } else {
      this.isDiscountPer = false;
      this.actionForm.get("maxDiscount").setValue("");
      this.actionForm.get("maxDiscount").clearValidators();
      this.actionForm.get("maxDiscount").updateValueAndValidity();
    }

    if (this.f["targetAmount"].value !== "") {
      this.f["targetAmount"].markAsTouched({ onlySelf: true });
      this.f["targetAmount"].updateValueAndValidity();
    }
  }

  onCustomerChange($event) {
    if (typeof $event !== "undefined" && $event > 0) {
      this.showUsageLimit = false;
      this.actionForm.get("UsageLimit").setValue(1);
    } else {
      this.showUsageLimit = true;
      this.actionForm.get("UsageLimit").setValue(null);
    }
  }

  handleValidityChange($event) {
    var target = $event;
    if (target.value === "Custom") {
      this.isCustomValidity = true;
      this.actionForm
        .get("ActivationDate")
        .setValidators([Validators.required]);
      this.actionForm.get("ActivationDate").updateValueAndValidity();
      this.actionForm.get("ExpiryDate").setValidators([Validators.required]);
      this.actionForm.get("ExpiryDate").updateValueAndValidity();
    } else {
      this.isCustomValidity = false;
      this.actionForm.get("ActivationDate").clearValidators();
      this.actionForm.get("ActivationDate").updateValueAndValidity();
      this.actionForm.get("ExpiryDate").clearValidators();
      this.actionForm.get("ExpiryDate").updateValueAndValidity();
    }
  }

  generateCode() {
    this.actionForm.get("couponCode").setValue(this.makeRandom());
  }

  private makeRandom() {
    var randomChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var length = 10;
    var result = "";
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    return result;
  }

  submit() {
    this.submitted = true;

    if (this.actionForm.invalid) {
      return;
    }

    const LanguageArray = [];
    LanguageArray.push({
      langKey: this.actionForm.value.defaultLanguageId,
      langValue: this.actionForm.value.description,
    });
    if (this.actionForm.value.languagesArray.length > 0) {
      this.actionForm.value.languagesArray.forEach((singleLang) => {
        LanguageArray.push({
          langKey: singleLang.lang_id,
          langValue: singleLang.languagedescription,
        });
      });
    }

    const ActivationDateUtc = this.actionForm.value.ActivationDate;
    let activationDate = "";
    if (typeof ActivationDateUtc !== "undefined" && ActivationDateUtc !== "") {
      const month = ActivationDateUtc.getMonth() * 1 + 1;
      const monthWithZero = month >= 0 && month <= 9 ? "0" + month : month;
      const day =
        ActivationDateUtc.getDate() >= 1 && ActivationDateUtc.getDate() <= 9
          ? "0" + ActivationDateUtc.getDate()
          : ActivationDateUtc.getDate();
      activationDate =
        ActivationDateUtc.getFullYear() + "-" + monthWithZero + "-" + day;
    }

    const expiryDateUtc = this.actionForm.value.ExpiryDate;
    let expiryDate = "";
    if (typeof expiryDateUtc !== "undefined" && expiryDateUtc !== "") {
      const month = expiryDateUtc.getMonth() * 1 + 1;
      const monthWithZero = month >= 0 && month <= 9 ? "0" + month : month;
      const day =
        expiryDateUtc.getDate() >= 1 && expiryDateUtc.getDate() <= 9
          ? "0" + expiryDateUtc.getDate()
          : expiryDateUtc.getDate();
      expiryDate =
        expiryDateUtc.getFullYear() + "-" + monthWithZero + "-" + day;
    }

    const maxDiscount =
      this.actionForm.value.maxDiscount !== ""
        ? parseFloat(this.actionForm.value.maxDiscount)
        : 0;

    const formData = {
      pid: this.actionForm.value.id,
      giftcode: this.actionForm.value.couponCode,
      discount: this.actionForm.value.discount,
      validity: this.actionForm.value.Validity,
      activationdate: activationDate,
      expirydate: expiryDate,
      usagelimit: this.actionForm.value.UsageLimit,
      used: 0,
      status: this.actionForm.value.Status,
      description: this.actionForm.value.description,
      type1: this.actionForm.value.discountUnit,
      maxDiscount: maxDiscount !== null ? maxDiscount : 0,
      targetAmount: parseFloat(this.actionForm.value.targetAmount),
      customerId:
        this.actionForm.value.customer > 0 ? this.actionForm.value.customer : 0,
      storeId: 0,
      promocodeType: true,
      totalRows: 0,
      languages: LanguageArray,
    };

    this.loading = true;
    this.ls.add();

    this.pc.action(this.action, formData).subscribe(
      (data) => {
        if (data["status"] === true) {
          this.alert.success(data["message"]);
          this.ls.remove();
          this.router.navigate([`${this.listUrl}`]);
        } else {
          this.alert.danger(data["message"]);
          this.ls.remove();
        }
      },
      (err) => {
        if (typeof err.error !== "undefined") {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          this.alert.danger(msg);
        }
        this.ls.remove();
        this.loading = false;
      }
    );
  }
}
