import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { constEnv } from "common/constants.env";
import { AuthGuard } from "common/_helpers/auth.guard";
import { JobDetailComponent } from "./job-detail/job-detail.component";

import { ListComponent } from "./list/list.component";

const JOB_ENDPOINT = `${constEnv.customerEndPoint}/job`;

const routes: Routes = [
  {
    path: `${JOB_ENDPOINT}`,
    children: [
      {
        path: "detail",
        children: [
          { path: ":id", component: JobDetailComponent },
          { path: "", redirectTo: `${JOB_ENDPOINT}`, pathMatch: "full" },
        ],
      },
      { path: "", component: ListComponent },
    ],
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class JobRoutingModule {}
