import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { constEnv } from 'common/constants.env';

const DRIVER_ENDPOINT = constEnv.driverEndPoint;

const routes: Routes = [
  { path: `${DRIVER_ENDPOINT}`, redirectTo: `${DRIVER_ENDPOINT}/login`, pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
