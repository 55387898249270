import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { WishlistRoutingModule } from "./wishlist-routing.module";
import { WishlistComponent } from "./wishlist/wishlist.component";
import { RouterModule } from "@angular/router";
import { TemplateDefaultModule } from "../../../shared/template-default/template-default.module";
import { TranslateModule } from "@ngx-translate/core";
import { DetailComponent } from "./detail/detail.component";
import { NgxGalleryModule } from "ngx-gallery-9";
import { PipeModule } from "common/_pipe/pipe.module";
@NgModule({
  declarations: [WishlistComponent, DetailComponent],
  imports: [
    CommonModule,
    WishlistRoutingModule,
    RouterModule,
    TemplateDefaultModule,
    TranslateModule,
    NgxGalleryModule,
    PipeModule,
  ],
})
export class WishlistModule {}
