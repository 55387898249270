import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { constEnv } from "common/constants.env";
import { map } from "rxjs/operators";
import { LanguageService } from "../language.service";

@Injectable({ providedIn: "root" })
export class SiteDesignerService {
  configConstant = constEnv;

  constructor(private http: HttpClient, private ls: LanguageService) {}

  getDesignerCatalogList(productParams) {
    const searchParams = new HttpParams()
      .set(
        "PageNo",
        productParams.PageNo > 0 ? productParams.PageNo.toString() : "0"
      )
      .set("PageSize", productParams.PageSize.toString());

    return this.http
      .get(`${this.configConstant.apiCustomerURL}/getdesignerpicks`, {
        params: searchParams,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getDesignerCatalogDetail(catalogId: number) {
    return this.http
      .get(`${this.configConstant.apiCustomerURL}/getdesignerpick/${catalogId}`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
