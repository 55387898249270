export class Offers {

    storeDiscountId: number;
    customerId: number;
    name: string;
    discountType: number;
    usePercentage: boolean;
    discountPercentage: string;
    discountAmount: string;
    requireCouponCode: boolean;
    couponCode: string;
    startDate: string;
    endDate: string;
    discountLimitation: number;
    discountLimitationValue: number;
    maxDiscountQuantity: number;
    used: number;
    status: boolean;
    isForNewUser: boolean;
    minOrderAmount: number;
    maxAmount: number;
    currencyCode: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
