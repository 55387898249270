import { CustomerAddress } from "../customers/customerAddress";
import { CartItem, KeyValuePair } from "./cartItem.modal";

export class Cart {
  appliedCouponCode: string;
  cartMessage: string;
  customerAddress: CustomerAddress;
  isCheckOut: boolean;
  messageToCustomer: string;
  myCart: CartItem[];
  myCartCalculation: CartCalculation;
  offerMessage: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    this.customerAddress = new CustomerAddress().deserialize(
      input.customerAddress
    );
    this.myCart = input.myCart.map((item) => new CartItem().deserialize(item));
    return this;
  }
}

export interface CartCalculation {
  calculationDetails: KeyValuePair[];
  calculationDetails_New: CalculationDetail[];
  currencyCode: string;
  customerAddress: CustomerAddress;
  deliveryCharges: number;
  itemsCount: KeyValuePair;
  myCartData: CartItem[];
  offerDiscount: number;
  packingCharges: number;
  assemblyCharges: number;
  paymentGatewayCharges: number;
  paymentGatewayDetails: PaymentGatewayDetail;
  promocodeDiscount: number;
  promotionalDiscount: number;
  subTotal: number;
  tax: number;
  taxPer: string;
  totalAmount: number;
  totalAmountText: string;
  totalProductAmount: string;
  totalShippment: string;
  promocodeText: string;
  promocodeId: number;
  isPromocodeShow: boolean;
}

export interface PaymentGatewayDetail {
  authenticationKey: string;
  storeID: string;
}

export interface CalculationDetail {
  key: string;
  value: string;
  keyColor: string;
  valueColor: string;
}
