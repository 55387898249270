import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { constEnv } from "common/constants.env";
import { map } from "rxjs/operators";
import { formatDate } from "@angular/common";

@Injectable({ providedIn: "root" })
export class OrderService {
  apiUrl = "";
  configConstant = constEnv;
  constructor(private http: HttpClient) {
    this.apiUrl = this.configConstant.apiStoreURL;
  }

  getAllOrders(dataTablesParameters) {
    let searchValue = "";
    let searchBy = "";
    let orderNo = "";
    if (dataTablesParameters.search.value !== "") {
      searchValue = dataTablesParameters.search.value;
      searchBy = dataTablesParameters.search.column;

      if (searchBy === "OrderId") {
        searchBy = "";
        orderNo = searchValue;
        searchValue = "";
      }
    }

    let dateTimeFrom = "";
    let dateTimeTo = "";
    if (
      dataTablesParameters.search.dateTimeForm !== "" &&
      dataTablesParameters.search.dateTimeTo !== ""
    ) {
      dateTimeFrom =
        typeof dataTablesParameters.search.dateTimeForm !== "undefined"
          ? dataTablesParameters.search.dateTimeForm
          : "";
      dateTimeTo =
        typeof dataTablesParameters.search.dateTimeTo !== "undefined"
          ? dataTablesParameters.search.dateTimeTo
          : "";
    }

    const pageNo =
      (dataTablesParameters.start * 1) / (dataTablesParameters.length * 1);
    const searchParams = new HttpParams()
      .set("searchColumn", searchBy)
      .set("searchValue", searchValue)
      .set("pageNo", pageNo.toString())
      .set("pageSize", dataTablesParameters.length)
      .set("sortColumn", "")
      .set("sortOrder", "")
      .set("OrderId", orderNo)
      .set("dateTimeForm", dateTimeFrom)
      .set("dateTimeTo", dateTimeTo);

    return this.http
      .get(`${this.apiUrl}/getorder`, {
        params: searchParams,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getProcessingOrders(dataTablesParameters) {
    let searchValue = "";
    let searchBy = "";
    if (dataTablesParameters.search.value !== "") {
      searchValue = dataTablesParameters.search.value;
      searchBy = dataTablesParameters.search.column;
    }

    const pageNo =
      (dataTablesParameters.start * 1) / (dataTablesParameters.length * 1);

    const searchParams = new HttpParams()
      .set("searchColumn", searchBy)
      .set("searchValue", searchValue)
      .set("pageNo", pageNo.toString())
      .set("pageSize", dataTablesParameters.length)
      .set("sortColumn", "")
      .set("sortOrder", "");

    return this.http
      .get(`${this.apiUrl}/getprocessorder`, {
        params: searchParams,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getDetail(id) {
    return this.http.get(`${this.apiUrl}/getorderbyid/${id}`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getOrdersEarnings(dataTablesParameters) {
    let fromDate = "";
    let toDate = "";
    if (
      dataTablesParameters.search.dateTimeForm !== "" &&
      dataTablesParameters.search.dateTimeTo !== ""
    ) {
      fromDate =
        typeof dataTablesParameters.search.dateTimeForm !== "undefined"
          ? dataTablesParameters.search.dateTimeForm
          : "";
      toDate =
        typeof dataTablesParameters.search.dateTimeTo !== "undefined"
          ? dataTablesParameters.search.dateTimeTo
          : "";
    }
    const pageNo =
      typeof dataTablesParameters.start !== "undefined"
        ? dataTablesParameters.start
        : 0;
    const perPage =
      typeof dataTablesParameters.length !== "undefined"
        ? dataTablesParameters.length
        : 10;

    const CurpageNo = (pageNo * 1) / (perPage * 1);

    const searchParams = new HttpParams()
      // .set('searchColumn', '')
      // .set('searchValue', '')
      .set("PageNo", CurpageNo.toString())
      .set("PageSize", perPage)
      .set(
        "FromDate",
        fromDate !== "" ? formatDate(fromDate, "yyyy-MM-dd", "en-US") : ""
      )
      .set(
        "ToDate",
        toDate !== "" ? formatDate(toDate, "yyyy-MM-dd", "en-US") : ""
      );
    // .set('sortColumn', '')
    // .set('sortOrder', '');

    return this.http
      .get(`${this.apiUrl}/getearning`, {
        params: searchParams,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getCompletedOrdersEarnings(fromDate, toDate, paging) {
    const formData = {
      FromDate: formatDate(fromDate, "yyyy-MM-dd", "en-US"),
      ToDate: formatDate(toDate, "yyyy-MM-dd", "en-US"),
    };

    const pageNo = typeof paging.page !== "undefined" ? paging.page : 0;
    const perPage =
      typeof paging.itemsPerPage !== "undefined" ? paging.itemsPerPage : 10;

    const searchParams = new HttpParams()
      .set("searchColumn", "")
      .set("searchValue", "")
      .set("pageNo", pageNo)
      .set("pageSize", perPage)
      .set("sortColumn", "")
      .set("sortOrder", "");

    return this.http
      .post(`${this.apiUrl}/getearningcompleteorder`, formData, {
        params: searchParams,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getCancelOrdersEarnings(fromDate, toDate, paging) {
    const formData = {
      FromDate: formatDate(fromDate, "yyyy-MM-dd", "en-US"),
      ToDate: formatDate(toDate, "yyyy-MM-dd", "en-US"),
    };

    const pageNo = typeof paging.page !== "undefined" ? paging.page : 0;
    const perPage =
      typeof paging.itemsPerPage !== "undefined" ? paging.itemsPerPage : 10;

    const searchParams = new HttpParams()
      .set("searchColumn", "")
      .set("searchValue", "")
      .set("pageNo", pageNo)
      .set("pageSize", perPage)
      .set("sortColumn", "")
      .set("sortOrder", "");

    return this.http
      .post(`${this.apiUrl}/getearningcancelorder`, formData, {
        params: searchParams,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getWallet(dataTablesParameters) {
    let fromDate = "";
    let toDate = "";
    if (
      dataTablesParameters.search.dateFrom !== "" &&
      dataTablesParameters.search.dateTo !== ""
    ) {
      fromDate = dataTablesParameters.search.dateFrom;
      toDate = dataTablesParameters.search.dateTo;
    }

    const formData = {
      FromDate: fromDate,
      ToDate: toDate,
    };

    const pageNo =
      (dataTablesParameters.start * 1) / (dataTablesParameters.length * 1);

    const searchParams = new HttpParams()
      .set("searchColumn", "")
      .set("searchValue", "")
      .set("pageNo", pageNo.toString())
      .set("pageSize", dataTablesParameters.length)
      .set("sortColumn", "")
      .set("sortOrder", "");

    return this.http
      .post(`${this.apiUrl}/GetWallet`, formData, {
        params: searchParams,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  sendWithdrawRequest(formData) {
    return this.http.post(`${this.apiUrl}/addwallet`, formData).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getOrderStatistics(year) {
    return this.http.get(`${this.apiUrl}/getorderstatistics/${year}`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  // Cancel Requests
  getCancelRequestLists(dataTablesParameters) {
    let searchValue = "";
    let searchBy = "";
    // if ( dataTablesParameters.search.value !== '' ) {
    //     searchValue = dataTablesParameters.search.value;
    //     searchBy = dataTablesParameters.search.column;
    // }

    const pageNo =
      (dataTablesParameters.start * 1) / (dataTablesParameters.length * 1);
    const searchParams = new HttpParams()
      .set("SearchColumn", searchBy)
      .set("SearchValue", searchValue)
      .set("PageNo", pageNo.toString())
      .set("PageSize", dataTablesParameters.length)
      .set("SortColumn", "")
      .set("SortOrder", "");

    return this.http
      .get(`${this.apiUrl}/getcancelticketlist`, {
        params: searchParams,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getCancelRequestDetail(requestId: number) {
    return this.http.get(`${this.apiUrl}/getcancelicketbyid/${requestId}`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  createCancelOrderByStore(formData) {
    return this.http.post(`${this.apiUrl}/cancelorder`, formData).pipe(
      map((data) => {
        return data;
      })
    );
  }
  getCancelReasonOptions(deliveryType: number = 1) {
    return this.http.get(`${this.apiUrl}/cancelreason/${deliveryType}`).pipe(
      map((data) => {
        return data;
      })
    );
  }
}
