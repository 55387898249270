import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

@Component({
  selector: "app-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.css"],
})
export class ConfirmDialogComponent implements OnInit {
  title: string;
  message: string;
  confirmButton = "Confirm";
  cancelButton = "Cancel";
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.data.confirmButton =
      typeof this.data.confirmButton !== "undefined"
        ? this.data.confirmButton
        : this.confirmButton;
    this.data.cancelButton =
      typeof this.data.cancelButton !== "undefined"
        ? this.data.cancelButton
        : this.cancelButton;
  }
}
