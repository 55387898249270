import { Injectable } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";
import { constEnv } from "../constants.env";
import { GeneralService } from "./general.service";
import { AuthenticationService } from "./authentication.service";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { AuthUser } from "common/_models/AuthUser.model";

@Injectable({ providedIn: "root" })
export class TitleService {
  siteTitle: string;
  userType: string;

  delim = " - ";

  pageTitle: PageTitle;
  config = constEnv;

  constructor(
    private title: Title,
    private gs: GeneralService,
    private auth: AuthenticationService,
    private trans: TranslateService,
    private router: Router,
    private meta: Meta
  ) {
    const defaultSettings = this.gs.getDefaultSettings();
    if (defaultSettings !== null) {
      this.siteTitle = defaultSettings["title"];
    } else {
      this.siteTitle = this.config.projectTitle;
    }

    if (this.auth.currentUserValue) {
      const userData = this.auth.currentUserValue.userdetails;
      this.userType = userData.userType;
    }
  }

  getTitleSufix() {
    this.userType = this.getCurrentUserType();

    let uType = "";
    let titleSuffix = [];
    if (this.userType !== "") {
      if (this.userType === this.config.driverEndPoint) {
        uType = this.getTranslatedString("Driver");
      } else if (this.userType === this.config.storeEndPoint) {
        uType = this.getTranslatedString("Store");
      } else if (this.userType === this.config.customerEndPoint) {
        uType = this.getTranslatedString("Users");
      }

      titleSuffix = [uType, this.siteTitle];
    } else {
      titleSuffix = [this.siteTitle];
    }

    return titleSuffix;
  }

  setTitle(title: string = "") {
    const pageTitleParts = this.getTitleSufix();
    let newTitleParts = [];
    if (title !== "") {
      newTitleParts = [this.getTranslatedString(title)].concat(pageTitleParts);
      // pageTitle = this.getTranslatedString(title) + this.getTitleSufix().join(this.delim);
    } else {
      // pageTitle = this.getTitleSufix();
      newTitleParts = pageTitleParts;
    }

    const pageTitle = newTitleParts.join(this.delim);

    this.title.setTitle(pageTitle);
  }

  setMultipleTitle(titles: any = []) {
    const pageTitleParts = this.getTitleSufix();
    let newTitleParts = [];
    if (titles.length > 0) {
      titles.forEach((title) => {
        if (title !== "") {
          newTitleParts.push(this.getTranslatedString(title));
        }
      });
      newTitleParts = newTitleParts.concat(pageTitleParts);
      // pageTitle = this.getTranslatedString(title) + this.getTitleSufix().join(this.delim);
    } else {
      // pageTitle = this.getTitleSufix();
      newTitleParts = pageTitleParts;
    }

    const pageTitle = newTitleParts.join(this.delim);

    this.title.setTitle(pageTitle);
  }

  getTitle() {
    return this.title.getTitle();
  }

  titleCaseWord(word: string) {
    if (!word) {
      return word;
    }
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  getTranslatedString(key: string) {
    const translatedText = this.trans.get(key);

    return typeof translatedText["value"] !== "undefined"
      ? translatedText["value"]
      : key;
  }

  getCurrentUserType() {
    const currentUser = this.auth.currentUserValue as AuthUser;
    let loggedInUser = "";
    if (currentUser) {
      const userData = currentUser.userdetails;
      const userType =
        typeof userData.userType !== "undefined" ? userData.userType : "";
      if (this.router.url.indexOf(userType) > -1) {
        loggedInUser = userType;
      }
    }
    return loggedInUser;
  }

  setMetaTag(name, content) {
    if (this.getMetaTag(name) === null) {
      this.addMetaTag(name, content);
    } else {
      this.updateMetaTag(name, content);
    }
  }

  getMetaTag(name) {
    return this.meta.getTag(`name=${name}`);
  }

  addMetaTag(name, content) {
    this.meta.addTag({ name, content });
  }

  updateMetaTag(name, content) {
    this.meta.updateTag({ name, content });
  }

  removeMetaTag(name) {
    this.meta.removeTag(`name=${name}`);
  }
}

interface PageTitle {
  page: string;
  user: string;
  site: string;
}
