import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from "@angular/router";
import { SiteProductService } from "common/services/site/siteProduct.service";
import { FilterAttributeModal } from "common/_models/site/filter/filter-attribute.modal";
import { FilterBrandModal } from "common/_models/site/filter/filter-brand.modal";
import { FilterValueModal } from "common/_models/site/filter/filter-value.modal";
import { ProductFilterModal } from "common/_models/site/product-filter.modal";
import { filter } from "rxjs/operators";
import { ProductFilerSortParam } from "../../category/category.component";

@Component({
  selector: "app-filter-sidebar",
  templateUrl: "./filter-sidebar.component.html",
  styleUrls: ["./filter-sidebar.component.css"],
})
export class FilterSidebarComponent implements OnInit, OnChanges {
  filters: ProductFilterModal = null;
  @Input() subCategoryId: number;
  @Input() categoryId: number;
  @Input() set setFilters(value: ProductFilterModal) {
    this.filters = value;
  }

  brands: FilterBrandModal[] = null;
  subCategories: FilterValueModal[] = null;
  attributes: FilterAttributeModal[] = null;

  searchForm: FormGroup;

  selectedBrands = [];
  selectedSubCategoryIds = [];
  selectedAttributeValues = [];

  navSubcription;

  productParams = {
    SearchColumn: "",
    SearchValue: "",
    SortColumn: "",
    BrandIds: {},
    SubCategoryIds: {},
    AttributeValueIds: {},
    CategoryId: 0,
    PageNo: 0,
    PageSize: 10,
  } as ProductFilerSortParam;

  constructor(
    private product: SiteProductService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.subCategoryId = this.subCategoryId > 0 ? this.subCategoryId : 0;
    this.categoryId = this.categoryId > 0 ? this.categoryId : 0;

    this.createSearchForm();
    this.selectedBrands = [];
    this.selectedSubCategoryIds = [];

    this.route.queryParams.subscribe((param) => {
      this.productParams.SearchValue =
        typeof param.SearchValue !== "undefined" ? param.SearchValue : "";
      if (this.productParams.SearchValue !== "") {
        this.searchForm
          .get("SearchValue")
          .setValue(this.productParams.SearchValue);
      }

      this.productParams.BrandIds =
        typeof param.BrandIds !== "undefined" ? param.BrandIds.split(",") : [];

      for (let i = 0; i < this.productParams.BrandIds.length; i++) {
        if (this.selectedBrands.indexOf(this.productParams.BrandIds[i]) == -1) {
          this.selectedBrands.push(this.productParams.BrandIds[i]);
        }
      }

      this.productParams.SubCategoryIds =
        typeof param.SubCategoryIds !== "undefined"
          ? param.SubCategoryIds.split(",")
          : [];

      for (let i = 0; i < this.productParams.SubCategoryIds.length; i++) {
        if (
          this.selectedSubCategoryIds.indexOf(
            this.productParams.SubCategoryIds[i]
          ) == -1
        ) {
          this.selectedSubCategoryIds.push(
            this.productParams.SubCategoryIds[i]
          );
        }
      }

      this.productParams.AttributeValueIds =
        typeof param.AttributeValueIds !== "undefined"
          ? param.AttributeValueIds.split(",")
          : [];

      for (let i = 0; i < this.productParams.AttributeValueIds.length; i++) {
        if (
          this.selectedAttributeValues.indexOf(
            this.productParams.AttributeValueIds[i]
          ) == -1
        ) {
          this.selectedAttributeValues.push(
            this.productParams.AttributeValueIds[i]
          );
        }
      }
    });

    this.showFilters();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.showFilters();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.selectedBrands = [];
    this.selectedSubCategoryIds = [];
  }

  showFilters() {
    // console.log(this.productFiletrs);
    if (this.filters !== null) {
      // this.filters = new ProductFilterModal().deserialize(this.productFiletrs);
      this.brands = this.filters.brands;
      this.subCategories = this.filters.categories;
      this.attributes = this.filters.attributes;
    }
    // if(this.productFiletrs !== null) {
    //   this.filters = new ProductFilterModal().deserialize(this.productFiletrs);
    //   this.brands = this.filters.brands;
    //   this.subCategories = this.filters.categories;
    //   this.attributes = this.filters.attributes;
    // } else {
    //this.getFilters();
    // }
  }

  getFilters() {
    this.product
      .getProductFilter(this.categoryId, this.subCategoryId)
      .subscribe(
        (data) => {
          const res = data as FilterResponse;
          if (res.status === true) {
            this.filters = new ProductFilterModal().deserialize(
              res.data.filters
            );

            this.brands = this.filters.brands;
            this.subCategories = this.filters.categories;
            this.attributes = this.filters.attributes;
          }
        },
        (err) => {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          if (msg !== "") {
            // this.alert.danger(err.error.message);
          }
        }
      );
  }

  createSearchForm() {
    this.searchForm = this.fb.group({
      SearchValue: [""],
    });
  }

  submitSearchValue() {
    const SearchValue = this.searchForm.value.SearchValue;
    // if(SearchValue !== '') {
    this.router.navigate([], {
      queryParams: {
        SearchValue: SearchValue,
      },
      queryParamsHandling: "merge",
    });
    // }
  }

  brandChecked($event) {
    if ($event.target.checked) {
      this.selectedBrands.push($event.target.value);
    } else {
      let removeIndex = this.selectedBrands.findIndex(
        (itm) => itm === $event.target.value
      );
      if (removeIndex !== -1) this.selectedBrands.splice(removeIndex, 1);
    }

    this.router.navigate([], {
      queryParams: {
        BrandIds: this.selectedBrands.join(","),
      },
      queryParamsHandling: "merge",
    });
  }

  subCatChecked($event) {
    if ($event.target.checked) {
      let findIndex = this.selectedSubCategoryIds.findIndex(
        (itm) => itm === $event.target.value
      );
      if (findIndex === -1) {
        this.selectedSubCategoryIds.push($event.target.value);
      }
    } else {
      let removeIndex = this.selectedSubCategoryIds.findIndex(
        (itm) => itm === $event.target.value
      );
      if (removeIndex !== -1)
        this.selectedSubCategoryIds.splice(removeIndex, 1);
    }

    this.router.navigate([], {
      queryParams: {
        SubCategoryIds: this.selectedSubCategoryIds
          .filter((item) => item != "")
          .join(","),
      },
      queryParamsHandling: "merge",
    });
  }

  attributeChecked(attrId, $event) {
    if ($event.target.checked) {
      this.selectedAttributeValues.push($event.target.value);
    } else {
      let removeIndex = this.selectedAttributeValues.findIndex(
        (itm) => itm === $event.target.value
      );
      if (removeIndex !== -1)
        this.selectedAttributeValues.splice(removeIndex, 1);
    }

    this.router.navigate([], {
      queryParams: {
        AttributeValueIds: this.selectedAttributeValues.join(","),
      },
      queryParamsHandling: "merge",
    });
  }

  showAttributesSectionIfSelected(attributesValues: any[]): boolean {
    const isFound = attributesValues.some((obj) =>
      this.productParams.AttributeValueIds.includes(obj.id.toString())
    );

    return isFound;
  }
}

interface FilterResponse {
  status: boolean;
  message: string;
  data: ResponseData;
}

interface ResponseData {
  filters: ProductFilterModal;
}
