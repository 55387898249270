import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from "@angular/router";
import { IsLoadingService } from "@service-work/is-loading";
import { constEnv } from "common/constants.env";
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { SiteGeneralService } from "common/services/site/siteGeneral.service";
import { SiteProductService } from "common/services/site/siteProduct.service";
import { TitleService } from "common/services/title.service";
import { ProductFilterModal } from "common/_models/site/product-filter.modal";
import { ProductList } from "common/_models/site/product-list.modal";
import { AlertService } from "@full-fledged/alerts";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-category",
  templateUrl: "./category.component.html",
  styleUrls: ["./category.component.css"],
})
export class CategoryComponent implements OnInit, OnDestroy {
  config = constEnv;

  categoryId = 0;
  subCategoryId: 0;

  filters: ProductFilterModal = null;
  productLists: ProductList[] = null;
  sortBy: SortByList[] = null;

  navSubcription;
  pageTitle = "";
  productDetailUrl = `${this.config.BASE_URL}/product/`;

  productParams = {
    SearchColumn: "",
    SearchValue: "",
    SortColumn: "",
    BrandIds: "",
    SubCategoryIds: "",
    AttributeValueIds: "",
    CategoryId: 0,
    PageNo: 0,
    PageSize: 12,
  } as ProductFilerSortParam;

  pagination: Paging;
  itemPerPage = 12;

  constructor(
    private isLoadingService: IsLoadingService,
    private titleService: TitleService,
    private alert: AlertService,
    private general: SiteGeneralService,
    private product: SiteProductService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Category");

    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }

    this.pagination = {
      totalCount: 0,
      totalRowsAfterFiltering: 0,
      pageSize: this.itemPerPage,
      currentPage: 0,
      totalPages: 0,
      previousPage: "",
      nextPage: "",
    };

    this.setCategoryAndSubCategory();

    this.navSubcription = this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError
        )
      )
      .subscribe((event) => {
        // If it's the start of navigation, `add()` a loading indicator
        if (event instanceof NavigationStart) {
          this.isLoadingService.add();
          return;
        }

        if (event instanceof NavigationEnd) {
          this.setCategoryAndSubCategory();

          // if ( event.url.indexOf( 'category' ) > -1 ) {
          this.getProductLists();
          // }
        }

        // Else navigation has ended, so `remove()` a loading indicator
        this.isLoadingService.remove();
      });

    this.route.queryParams.subscribe((param) => {
      this.productParams.SortColumn =
        typeof param.SortColumn !== "undefined" ? param.SortColumn : "";
      this.productParams.SortOrder =
        typeof param.SortOrder !== "undefined" ? param.SortOrder : "";
      this.productParams.SearchValue =
        typeof param.SearchValue !== "undefined" ? param.SearchValue : "";
      this.productParams.BrandIds =
        typeof param.BrandIds !== "undefined" ? param.BrandIds : "";
      // this.subCategoryId =
      //   typeof param.SubCategoryIds !== "undefined"
      //     ? param.SubCategoryIds
      //     : this.subCategoryId;
      this.productParams.AttributeValueIds =
        typeof param.AttributeValueIds !== "undefined"
          ? param.AttributeValueIds
          : "";
      this.productParams.PageNo =
        typeof param.PageNo !== "undefined" ? param.PageNo : "";
      this.productParams.PageSize =
        typeof param.PageSize !== "undefined"
          ? param.PageSize
          : this.itemPerPage;
    });

    // this.setCategoryAndSubCategory();
    this.getProductLists();
  }

  ngOnDestroy() {
    this.navSubcription.unsubscribe();
  }

  setCategoryAndSubCategory() {
    this.categoryId = this.route.snapshot.params.categoryId;
    const subCategoryId = this.route.snapshot.params.subCategoryId;
    this.subCategoryId =
      typeof subCategoryId !== "undefined" ? subCategoryId : "";

    if (this.categoryId <= 0) {
      this.router.navigate([this.config.BASE_URL]);
    }
  }

  getProductLists() {
    this.isLoadingService.add();

    this.productParams.CategoryId = this.categoryId;
    if (this.subCategoryId) {
      this.productParams.SubCategoryIds = this.subCategoryId;
    } else {
      this.route.queryParams.subscribe((param) => {
        const subCategoryIds =
          typeof param.SubCategoryIds !== "undefined"
            ? param.SubCategoryIds
            : "";

        const subCatIdsArray = subCategoryIds
          .split(",")
          .filter((item) => item != "");

        this.productParams.SubCategoryIds = subCatIdsArray.join(",");
      });
    }
    // this.productParams.SubCategoryIds = this.subCategoryId.toString();

    this.product.getProductList(this.productParams).subscribe(
      (data) => {
        const res = data as ProductListResponse;
        if (res.status === true) {
          this.productLists = res.data.productList.map((item) =>
            new ProductList().deserialize(item)
          );

          this.filters = res.data.filters as ProductFilterModal;

          this.sortBy = res.data.sortByList as SortByList[];

          let CategoryName = "";
          if (res.data.categoryName !== "") {
            CategoryName = res.data.categoryName;
          }
          const subCatName =
            typeof res.data.subCategoryDetails[0] !== "undefined"
              ? res.data.subCategoryDetails[0].name
              : "";
          if (subCatName !== "") {
            this.pageTitle = subCatName;
            this.titleService.setMultipleTitle([
              subCatName,
              // CategoryName,
              // "Category",
            ]);
          } else if (CategoryName !== "") {
            this.pageTitle = CategoryName;
            this.titleService.setMultipleTitle([CategoryName]);
          }

          this.pagination = JSON.parse(res.paging) as Paging;
        } else {
          // this.alert.danger(res.message);
        }
        this.isLoadingService.remove();
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          this.alert.danger(err.error.message);
        }
        this.isLoadingService.remove();
      }
    );
  }

  changeSortBy($event: any) {
    const selectedEle = $event.target;

    const sortOrder =
      selectedEle.options[selectedEle.selectedIndex].getAttribute("data-sort");
    const sortCol = $event.target.value;

    if (sortCol !== "") {
      this.router.navigate([], {
        queryParams: {
          SortColumn: sortCol,
          SortOrder: sortOrder,
        },
        queryParamsHandling: "merge",
      });
    }
  }

  pageChange(paging: PageChangedEvent) {
    paging.page = paging.page > 0 ? paging.page - 1 : 0;
    paging.itemsPerPage = paging.itemsPerPage
      ? paging.itemsPerPage
      : this.itemPerPage;

    this.router.navigate([], {
      queryParams: {
        PageNo: paging.page,
        PageSize: paging.itemsPerPage,
      },
      queryParamsHandling: "merge",
    });
  }
}

interface ProductListResponse {
  status: boolean;
  message: string;
  data: ResponseData;
  paging: string;
}

interface ResponseData {
  attributeValueIds: any;
  brandIds: any;
  subCategoryIds: any;
  productList: ProductList[];
  sortByList: SortByList[];
  categoryName: string;
  subCategoryDetails: any;
  filters: ProductFilterModal;
}

export interface SortByList {
  sortColumn: string;
  sortOrder: string;
  sortOrderName: string;
}

export interface ProductFilerSortParam {
  SearchColumn: string;
  SearchValue: string;
  SortColumn: string;
  SortOrder: string;
  BrandIds: string;
  SubCategoryIds: string;
  AttributeValueIds: string;
  CategoryId: number;

  PageNo: number;
  PageSize: number;
  TagId: string;
}

export interface Paging {
  totalCount: number;
  totalRowsAfterFiltering: number;
  pageSize: number;
  currentPage: number;
  totalPages: number;
  previousPage: string;
  nextPage: string;
}
