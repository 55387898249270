import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { constEnv } from "common/constants.env";
import { ListComponent } from "./list/list.component";
import { AuthGuard } from "common/_helpers/auth.guard";
import { DetailComponent } from "./detail/detail.component";

const routeUrl = constEnv.customerEndPoint;
const CANCEL_REQUEST_ENDPOINT = `${routeUrl}/cancel-request`;

const routes: Routes = [
  {
    path: `${CANCEL_REQUEST_ENDPOINT}`,
    children: [
      {
        path: "detail",
        children: [
          { path: ":id", component: DetailComponent },
          {
            path: "",
            redirectTo: `${CANCEL_REQUEST_ENDPOINT}`,
            pathMatch: "full",
          },
        ],
      },
      { path: "", component: ListComponent },
    ],
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CancelRequestRoutingModule {}
