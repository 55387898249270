import { Component, OnInit, ViewChild } from "@angular/core";

import { constEnv } from "common/constants.env";
import { AlertService } from "ngx-alerts";
import { IsLoadingService } from "@service-work/is-loading";

import { DataTableDirective } from "angular-datatables";
import { TitleService } from "common/services/title.service";
import { LastminuteDealService } from "common/services/store/lastminuteDeal.service";
import { ConfirmDialogComponent } from "common/_components/confirm-dialog/confirm-dialog.component";
import { first } from "rxjs/operators";
import { MatDialog } from "@angular/material";
import { ModalDirective } from "ngx-bootstrap";

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.css"],
})
export class ListComponent implements OnInit {
  config = constEnv;

  dtOptions: DataTables.Settings = {};

  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;

  lists: LMDList[] = [];

  @ViewChild("autoShownModal", { static: false })
  autoShownModal: ModalDirective;
  isModalShown = false;

  itemDetail: LMDInfo = null;

  pageTitle = "Last Minute Deal";

  constructor(
    private alert: AlertService,
    private isLoadingService: IsLoadingService,
    private titleService: TitleService,
    private dialog: MatDialog,
    private LastminuteDealService: LastminuteDealService
  ) {}

  ngOnInit() {
    this.titleService.setTitle(this.pageTitle);
    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }

    this.createDataTable();
  }

  createDataTable() {
    this.dtOptions = {
      pagingType: "full_numbers",
      ordering: false,
      lengthChange: false,
      searching: false,
      serverSide: true,
      processing: true,
      // pageLength: 2,
      language: {
        emptyTable: "Last Minute Deal is empty!",
        paginate: {
          previous: "<",
          first: "<<",
          last: ">>",
          next: ">",
        },
      },
      ajax: (dataTablesParameters: any, callback) => {
        let paging: Paging = null;
        this.LastminuteDealService.getAll(dataTablesParameters).subscribe(
          (data) => {
            const res = data as LMDResponse;
            if (res.status === true) {
              this.lists = res.data as LMDList[];

              paging = JSON.parse(res.paging) as Paging;
              // paging = { totalCount: this.categoryLists.length, totalRowsAfterFiltering: this.categoryLists.length };
            } else {
              paging = {
                totalCount: 0,
                totalRowsAfterFiltering: 0,
              } as Paging;
              this.alert.danger(res.message);
              this.lists = [];
            }

            callback({
              recordsTotal: paging.totalCount,
              recordsFiltered: paging.totalRowsAfterFiltering,
              data: [],
            });

            this.isLoadingService.remove();
          },
          (err) => {
            if (typeof err.error !== "undefined") {
              const msg =
                typeof err.error.Message !== "undefined"
                  ? err.error.Message
                  : err.error.message;
              this.alert.danger(msg);
              this.lists = [];
            }

            callback({
              recordsTotal: 0,
              recordsFiltered: 0,
              data: [],
            });

            this.isLoadingService.remove();
          }
        );

        callback({
          recordsTotal: 0,
          recordsFiltered: 0,
          data: [],
        });
      },
      // columns: [{ data: 'id' }, { data: 'firstName' }, { data: 'lastName' }]
    };
  }

  viewLastMinuteDeal(id: number): void {
    this.LastminuteDealService.getLastMinuteDeal(id).subscribe(
      (data) => {
        const res = data as SingleResponse;
        if (res.status === true) {
          this.itemDetail = res.data;
          this.isModalShown = true;
        } else {
          this.alert.danger(res.message);
        }
        this.isLoadingService.remove();
      },
      (err) => {
        if (typeof err.error !== "undefined") {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          this.alert.danger(msg);
        }
        this.isLoadingService.remove();
      }
    );
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.itemDetail = null;
    this.isModalShown = false;
  }

  changeStatus(id, status) {
    this.isLoadingService.add();
    this.LastminuteDealService.changeLMDealStatus(id, status).subscribe(
      (data) => {
        const res = data as LMDResponse;
        if (res.status === true) {
          this.alert.success(res.message);
          this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) =>
            dtInstance.draw()
          );
        } else {
          this.alert.danger(res.message);
        }

        this.isLoadingService.remove();
      },
      (err) => {
        if (typeof err.error !== "undefined") {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          this.alert.danger(msg);
        }

        this.isLoadingService.remove();
      }
    );
  }

  deleteLMD(id: number) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm",
        message: "DeleteConfirm",
      },
    });

    confirmDialog.afterClosed().subscribe((result) => {
      if (result === true) {
        this.isLoadingService.add();
        this.LastminuteDealService.deleteLastMinuteDeal(id).subscribe(
          (data) => {
            if (data["status"] === true) {
              this.alert.success(data["message"]);
              this.datatableElement.dtInstance.then(
                (dtInstance: DataTables.Api) => dtInstance.draw()
              );
            } else {
              this.alert.danger(data["message"]);
            }
            this.isLoadingService.remove();
          },
          (err) => {
            if (typeof err.error !== "undefined") {
              const msg =
                typeof err.error.Message !== "undefined"
                  ? err.error.Message
                  : err.error.message;
              this.alert.danger(msg);
            }

            this.isLoadingService.remove();
          }
        );
      }
    });
  }
}

interface LMDResponse {
  status: boolean;
  message: string;
  data: LMDList[];
  paging: string;
}

interface LMDList {
  id: number;
  offerEndsOn: string;
  offerEndsOnDt: string;
  productId: number;
  productName: string;
  status: boolean;
  variationName: string;
}

interface Paging {
  totalCount: number;
  totalRowsAfterFiltering: number;
  pageSize: number;
  currentPage: number;
  totalPages: number;
  previousPage: string;
  nextPage: string;
}

interface SingleResponse {
  status: boolean;
  message: string;
  data: LMDInfo;
}

interface LMDInfo {
  id: number;
  productName: string;
  variationName: string;
  quantity: number;
  offerEndsOn: string;
  offerEndsOnDt: Date;
  offerType: number;
  offerTypeName: string;
  discountAmount: number;
  discountPercent: number;
}
