import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { constEnv } from 'common/constants.env';
import { map } from 'rxjs/operators';

import { AuthenticationService } from 'common/services/authentication.service';

@Injectable({ providedIn: 'root' })
export class ReturnRequestService {
    currentUserProfile;
    configConstant = constEnv;
    constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
        this.currentUserProfile = this.authenticationService.currentUserProfileValue;
    }

    getAllRequests( dtParameters ) {
        const pageNo = (dtParameters.start * 1) / (dtParameters.length * 1);

        const params = new HttpParams()
            // .set('searchColumn', searchBy)
            // .set('searchValue', searchValue)
            .set('PageNo', pageNo.toString())
            .set('PageSize', dtParameters.length);
            // .set('sortColumn', '')
            // .set('sortOrder', '');

        return this.http.get(`${this.configConstant.apiStoreURL}/getreturnticketlist`, {
            params
        })
            .pipe(map(data => {
                return data;
            }));
    }

    getRequestById( requestId: number ) {
        return this.http.get(`${this.configConstant.apiStoreURL}/getreturnticketbyid/${requestId}`)
            .pipe(map(data => {
                return data;
            }));
    }
}
