import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { constEnv } from 'common/constants.env';

import { EmailVerifyComponent } from './email-verify/email-verify.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const URL_ENDPOINT = constEnv.driverEndPoint;

const routes: Routes = [
  { path: `${URL_ENDPOINT}/login`, component: LoginComponent },
  { path: `${URL_ENDPOINT}/register`, component: RegisterComponent },
  { path: `${URL_ENDPOINT}/reset-password/:token` , component: ResetPasswordComponent },
  { path: `${URL_ENDPOINT}/email-verify/:token` , component: EmailVerifyComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthPagesRoutingModule { }

