import { Component, OnInit, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { LoaderService } from 'common/services/loader.service';
import { AlertService } from 'ngx-alerts';
import { SettingsService } from 'common/services/store/settings.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription, combineLatest } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { TitleService } from 'common/services/title.service';
import { constEnv } from 'common/constants.env';
import { RequiredDocuments } from 'common/_models/store/requiredDocuments';
import { UplodedDocuments } from 'common/_models/store/uplodedDocuments';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {

  documentLists = [];

  modalRef: BsModalRef;
  subscriptions: Subscription[] = [];

  dateFormat = 'dd-MMM-yyyy';

  selectedDocMgmtId = 0;
  selExpDateReq = true;
  submitted = false;

  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  fileUplaodName: string = null;

  uploadForm: FormGroup;

  config = constEnv;

  constructor(
    private ls: LoaderService,
    private alert: AlertService,
    private settings: SettingsService,
    private modalService: BsModalService,
    private changeDetection: ChangeDetectorRef,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private titleService: TitleService,
  ) { }

  ngOnInit() {
    this.titleService.setTitle('STORE_Manage_Documents');
    this.getRequiredDocumentLists();

    this.makeUploadForm();
  }

  makeUploadForm() {
    this.uploadForm = this.fb.group({
      fileUpload: ['', Validators.required],
      expiryDate: [''],
      docMgmtId: [''],
      docId: [0],
    });
  }

  get f() { return this.uploadForm.controls; }

  getRequiredDocumentLists() {
    this.ls.showLoader();
    this.documentLists = [];
    this.settings.getDocumentLists()
    .subscribe(
      data => {
        const res = data as RequiredDocResponse;
        if (res.status === true) {
          if (res.data !== null) {
            res.data.forEach(doc => {
              if (doc.docMgmtId > 0) {

                this.settings.getUploadedDouments( doc.docMgmtId )
                  .subscribe(
                    response => {
                      const resp = response as UploadedDocResponse;
                      if (resp.status === true) {
                        const resData = (typeof resp.data !== 'undefined') ? resp.data : null;
                        this.documentLists.push({
                          DocMgmtId: doc.docMgmtId,
                          DocumentName: doc.documentName,
                          IsExpiryDateRequired: doc.isExpiryDateRequired,
                          docId: (typeof resData.id !== 'undefined') ? resData.id : 0,
                          DocExpiryDate: (typeof resData.docExpiryDate !== 'undefined') ? resData.docExpiryDate : null,
                          DocUrl: (typeof resData.docUrl !== 'undefined') ? resData.docUrl : null,
                          IsVerify: (typeof resData.isVerify !== 'undefined') ? resData.isVerify : 0,
                          isDocUrl: (typeof resData.docUrl !== 'undefined') ? true : false,
                          btnLabel: 'UpdateDocument',
                        });
                      } else {
                        this.documentLists.push({
                          DocMgmtId: doc.docMgmtId,
                          DocumentName: doc.documentName,
                          IsExpiryDateRequired: doc.isExpiryDateRequired,
                          docId: 0,
                          DocExpiryDate: null,
                          DocUrl: null,
                          isDocUrl: false,
                          IsVerify: true,
                          btnLabel: 'Upload',
                        });
                      }

                      this.ls.hideLoader();
                    },
                    err => {
                      this.documentLists.push({
                        DocMgmtId: doc.docMgmtId,
                        DocumentName: doc.documentName,
                        IsExpiryDateRequired: doc.isExpiryDateRequired,
                        docId: 0,
                        DocExpiryDate: null,
                        DocUrl: null,
                        isDocUrl: false,
                        IsVerify: true,
                        btnLabel: 'Upload',
                      });
                      this.ls.hideLoader();
                    }
                  );

              }
            });
          }
          // this.documentLists = data['data'];

          this.ls.hideLoader();
        } else {
          this.alert.danger(res.message);
          this.ls.hideLoader();
        }
      },
      err => {
        const msg = (typeof err.error.Message !== 'undefined') ? err.error.Message : err.error.message;
        this.alert.danger(msg);
        this.ls.hideLoader();
      }
    );
  }

  openModal(template: TemplateRef<any>, docMgmtId, docId, isexpDateReq) {
    const _combine = combineLatest(
      this.modalService.onShow,
      this.modalService.onShown,
      this.modalService.onHide,
      this.modalService.onHidden
    ).subscribe(() => this.changeDetection.markForCheck());

    this.subscriptions.push(
      this.modalService.onShow.subscribe((reason: string) => {
        this.selectedDocMgmtId = docMgmtId;
        this.uploadForm.get('docMgmtId').setValue(docMgmtId);
        this.uploadForm.get('docId').setValue(docId);
        this.selExpDateReq = isexpDateReq;
        if (isexpDateReq === true) {
          this.uploadForm.get('expiryDate').setValidators([Validators.required]);
        }
      })
    );

    this.subscriptions.push(
      this.modalService.onHidden.subscribe((reason: string) => {
        this.selectedDocMgmtId = 0;
        this.submitted = false;
        this.uploadForm.get('fileUpload').setValue('');
        this.uploadForm.get('docMgmtId').setValue('');
        this.uploadForm.get('docId').setValue(0);
        this.uploadForm.get('expiryDate').setValue('');
        this.uploadForm.get('expiryDate').setValidators([]);
        this.selExpDateReq = true;
        this.fileUplaodName = null;
        this.previewUrl = '';
        this.unsubscribe();
      })
    );

    this.subscriptions.push(_combine);

    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  fileProgress(fileInput: any) {
    this.fileData = fileInput.target.files[0] as File;
    this.preview();
  }

  preview() {
    // Show preview
    const mimeType = this.fileData.type;
    const reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
      this.fileUplaodName = this.fileData.name;
    };
  }

  onSubmit() {
    this.submitted = true;

    if (this.uploadForm.invalid) {
      return;
    }

    // const docId = (this.uploadForm.value.docId > 0) ? this.uploadForm.value.docId : 0;
    // const action = (docId > 0) ? 'edit' : 'add';

    const expiredDate = this.datePipe.transform( this.uploadForm.value.expiryDate, this.dateFormat );

    const formData = new FormData();
    // formData.append('Id', docId);
    formData.append('DocumentTypeId', this.uploadForm.value.docMgmtId);
    formData.append('DocumentExpiryDate', (expiredDate !== null) ? expiredDate : '');
    formData.append('Document', this.fileData);

    this.ls.showLoader();
    this.settings.actionDocumentUpload( formData )
        .subscribe(
          data => {
            if (data['status'] === true) {
              this.alert.success(data['message']);
              // this.ls.hideLoader();
              this.modalRef.hide();
              this.getRequiredDocumentLists();
            } else {
              this.alert.danger(data['message']);
              this.ls.hideLoader();
            }
          },
          err => {
            if (typeof err.error.Message !== 'undefined') {
              this.alert.danger(err.error.Message);
            } else if (typeof err.error !== 'undefined') {
              this.alert.danger(err.error.message);
            }
            this.ls.hideLoader();
          });
  }
}

export interface RequiredDocResponse {
  status: boolean;
  message: string;
  data: RequiredDocuments[];
}

export interface UploadedDocResponse {
  status: boolean;
  message: string;
  data: UplodedDocuments;
}
