import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { StoreSharedModule } from "projects/store/src/app/app.module";
import { DriverSharedModule } from "projects/driver/src/app/app.module";
import { AuthPagesModule } from "./auth-pages/auth-pages.module";
import { DeliveryCompanyModule } from "./delivery-company/delivery-company.module";
import { DesignerModule } from "./designer/designer.module";

import { CustomerModule } from "./pages/customer/customer.module";
import { ECommerceModule } from "./e-commerce/e-commerce.module";

import { TranslateModule } from "@ngx-translate/core";

import { AlertModule } from "@full-fledged/alerts";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatFormFieldModule } from "@angular/material/form-field";
import { JwtInterceptor } from "common/_helpers/jwt.interceptor";
import { ErrorInterceptor } from "common/_helpers/error.interceptor";

const appProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AuthPagesModule,
    StoreSharedModule.forRoot(),
    DriverSharedModule.forRoot(),
    AlertModule.forRoot({
      maxMessages: 1,
      timeout: 5000,
      positionX: "right",
      positionY: "top",
    }),
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    DesignerModule,
    DeliveryCompanyModule,
    ECommerceModule,
    CustomerModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
