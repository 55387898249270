import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { IsLoadingService } from "@service-work/is-loading";
import { DataTableDirective } from "angular-datatables";
import { Config } from "datatables.net";
import { ReturnRequestService } from "common/services/store/returnRequest.service";
import { TitleService } from "common/services/title.service";
import { ReturnRequest } from "common/_models/store/returnRequest";
import { AlertService } from "@full-fledged/alerts";
import { first } from "rxjs/operators";
import { Paging } from "../../products/list/list.component";
import { constEnv } from "common/constants.env";

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.css"],
})
export class ListComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;

  // dtOptions: DataTables.Settings = {};
  dtOptions: Config = {};

  config = constEnv;

  lists: ReturnRequest[] = [];
  hasLists = false;

  constructor(
    private titleService: TitleService,
    private fb: FormBuilder,
    private alert: AlertService,
    private isLoadingService: IsLoadingService,
    private rr: ReturnRequestService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Return Request");

    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }

    this.createDataTable();
  }

  createDataTable() {
    this.dtOptions = {
      pagingType: "full_numbers",
      ordering: false,
      lengthChange: false,
      searching: false,
      serverSide: true,
      processing: true,
      // pageLength: 1,
      language: {
        paginate: {
          previous: "<",
          first: "<<",
          last: ">>",
          next: ">",
        },
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.hasLists = true;

        let paging: Paging = null;

        this.rr
          .getAllRequests(dataTablesParameters)
          .pipe(first())
          .subscribe(
            (data) => {
              const res = data as ReturnRequestList;

              if (res.status === true) {
                this.lists = res.data.map((item) =>
                  new ReturnRequest().deserialize(item)
                );

                paging = JSON.parse(res.paging) as Paging;
              } else {
                paging = {
                  totalCount: 0,
                  totalRowsAfterFiltering: 0,
                } as Paging;

                this.alert.danger(data["message"]);
                this.lists = [];
              }

              callback({
                recordsTotal: paging.totalCount,
                recordsFiltered: paging.totalRowsAfterFiltering,
                data: [],
              });

              // this.loader.hideLoader();
            },
            (err) => {
              if (typeof err.error !== "undefined") {
                const msg =
                  typeof err.error.Message !== "undefined"
                    ? err.error.Message
                    : err.error.message;
                // this.alert.danger(msg);
                this.lists = [];
              }

              callback({
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              });

              // this.loader.hideLoader();
            }
          );
      },
    };
  }
}

interface ReturnRequestList {
  status: boolean;
  message: string;
  data: ReturnRequest[];
  paging: string;
}
