import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { IsLoadingService } from "@service-work/is-loading";
import { LoaderService } from "common/services/loader.service";
import { WishlistService } from "common/services/store/wishlist.service";
import { TitleService } from "common/services/title.service";
import { Wishlist } from "common/_models/store/wishlist";
import { AlertService } from "@full-fledged/alerts";
import { first } from "rxjs/operators";
import { Paging } from "../../products/list/list.component";
import { Config } from "datatables.net";

@Component({
  selector: "app-wishlist",
  templateUrl: "./wishlist.component.html",
  styleUrls: ["./wishlist.component.css"],
})
export class WishlistComponent implements OnInit {
  // dtOptions: DataTables.Settings = {};
  dtOptions: Config = {};
  // dtOptionsCancelOrder: DataTables.Settings = {};
  dtOptionsCancelOrder: Config = {};

  lists: Wishlist[] = [];
  hasLists = false;

  constructor(
    private titleService: TitleService,
    private fb: FormBuilder,
    private alert: AlertService,
    private isLoadingService: IsLoadingService,
    private wishlist: WishlistService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Wishlist");

    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }

    this.createDataTable();
  }

  createDataTable() {
    this.dtOptions = {
      pagingType: "full_numbers",
      ordering: false,
      lengthChange: false,
      searching: false,
      serverSide: true,
      processing: true,
      // pageLength: 2,
      language: {
        paginate: {
          previous: "<",
          first: "<<",
          last: ">>",
          next: ">",
        },
      },
      ajax: (dataTablesParameters: any, callback) => {
        // const searchparams = this.searchForm.value;
        // if (searchparams.filterText !== '') {
        //   dataTablesParameters.search.column = searchparams.filterBy;
        //   dataTablesParameters.search.value = searchparams.filterText;
        // }

        // dataTablesParameters.search.dateTimeForm = '';
        // dataTablesParameters.search.dateTimeTo = '';
        // if (searchparams.orderFilterDates !== null) {
        //   const oDates = searchparams.orderFilterDates;
        //   dataTablesParameters.search.dateTimeForm = this.datePipe.transform( oDates[0], this.dateFormat );
        //   dataTablesParameters.search.dateTimeTo = this.datePipe.transform( oDates[1], this.dateFormat );
        // }
        this.wishlist
          .getWishlist(dataTablesParameters)
          .pipe(first())
          .subscribe(
            (resp) => {
              const data = resp as WishlistResponse;
              let paging = {
                totalCount: 0,
                totalRowsAfterFiltering: 0,
              } as Paging;
              if (data.status === true) {
                this.lists = data.data.map((item) =>
                  new Wishlist().deserialize(item)
                );
                paging = JSON.parse(data.paging) as Paging;
                this.hasLists = true;
              } else {
                this.lists = [];
                this.hasLists = false;
                this.alert.danger(data.message);
              }

              callback({
                recordsTotal: paging.totalCount,
                recordsFiltered: paging.totalRowsAfterFiltering,
                data: [],
              });

              // this.loader.hideLoader();
            },
            (err) => {
              if (typeof err.error !== "undefined") {
                const msg =
                  typeof err.error.Message !== "undefined"
                    ? err.error.Message
                    : err.error.message;
                // this.alert.danger(msg);
                this.lists = [];
              }

              callback({
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              });
              // this.loader.hideLoader();
            }
          );
      },
    };
  }
}

interface WishlistResponse {
  status: boolean;
  message: string;
  data: Wishlist[];
  paging: string;
}
