import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { constEnv } from 'common/constants.env';
import { LanguageService } from 'common/services/language.service';
import { CategoryService } from 'common/services/store/category.service';
import { LoaderService } from 'common/services/loader.service';
import { AlertService } from 'ngx-alerts';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { first } from 'rxjs/operators';
import { defaultLongDateFormat } from 'ngx-bootstrap/chronos/locale/locale.class';
import { TitleService } from 'common/services/title.service';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.css']
})
export class ActionComponent implements OnInit {
  action: string;
  id: number;
  languages: any;
  categoryData: any;

  actionForm: FormGroup;
  loading = false;
  submitted = false;
  btnLable = 'AddProductSubCategory';
  pageTitle = 'AddProductSubCategory';
  displayOrderCounts = [];

  allMainCategories: MainCategoryList[] = [];

  cs = constEnv;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private lang: LanguageService,
    private ls: LoaderService,
    private categoryService: CategoryService,
    private alert: AlertService,
    private titleService: TitleService
  ) {
    this.action = route.snapshot.params.action;

    this.languages = this.lang.getAvailableLanguages();
  }

  ngOnInit() {
    this.document.body.classList.add('category-action-body');

    const categoryURL = `${this.cs.STORE_URL}/sub-category`;

    switch (this.action) {
      case 'edit':
        const id = this.route.snapshot.params.id;
        this.id = (typeof id !== 'undefined') ? id : 0;
        if (this.id <= 0) {
          this.router.navigate([categoryURL]);
        }

        this.btnLable = 'EditProductSubCategory';
        this.pageTitle = 'EditProductSubCategory';

        if (this.id > 0) {
        this.ls.showLoader();
        this.categoryService.subCategoryGet(this.id)
          .pipe(first())
          .subscribe(
            data => {
              if (data['status'] === true) {
                this.categoryData = data['data'];

                for (let i = 1; i <= this.categoryData.DisplayOrderNextValue; i++) {
                  this.displayOrderCounts.push(i);
                }

                this.actionForm.get('catID').setValue(this.categoryData.subCatId);
                this.actionForm.get('mainCateId').setValue(this.categoryData.catId);
                // this.actionForm.get('displayOrder').setValue(this.categoryData.DisplayOrder);

                const catNamesControls = this.actionForm.get('catNames');

                this.languages.forEach((singleRow, index) => {
                  const l_index = index;
                  this.categoryData.languages.forEach(row => {
                    if (singleRow.id === row.langKey) {
                      catNamesControls['controls'][l_index].get('catName').setValue(row.langValue);
                    }
                  });
                });

                this.ls.hideLoader();
              } else {
                this.alert.danger(data['message']);
                this.ls.hideLoader();
              }
            },
            err => {
              this.alert.danger(err.error.message);
              this.ls.hideLoader();
            });
          }
        break;

      case 'add':
        break;

      default:
        this.router.navigate([categoryURL]);
        break;
    }


    this.actionForm = this.formBuilder.group({
      catID: 0,
      mainCateId: [null, Validators.required],
      catNames: this.formBuilder.array([]),
      displayOrder: ['']
    });

    // if (this.action === 'edit') {
    //   this.actionForm.get('displayOrder').setValidators([Validators.required]);
    // }

    this.titleService.setTitle( this.pageTitle );

    const langNames = this.actionForm.controls.catNames as FormArray;

    for (let i = 0; i < this.languages.length; i++) {
      if (this.languages[i].default_lang === '1') {
        langNames.push(this.formBuilder.group({
          catName: ['', Validators.required],
          lang_id: this.languages[i].id,
          defaultLang: this.languages[i].default_lang
        }));
      } else {
        langNames.push(this.formBuilder.group({
          catName: [''],
          lang_id: this.languages[i].id,
          defaultLang: this.languages[i].default_lang
        }));
      }
    }

    this.getAllMainCategory();
  }

  getAllMainCategory() {
    this.ls.showLoader();
    this.categoryService.getAllMainCategories()
        .pipe(first())
        .subscribe(data => {
          const response  = data as MainCategoryResponse;
          if ( response.status) {
            this.allMainCategories = response.data;
          } else {
            this.alert.danger(response.message);
          }
          this.ls.hideLoader();
        },
          err => {
            const msg = (typeof err.error.Message !== 'undefined') ? err.error.Message : err.error.message;
            this.alert.danger(msg);
            this.ls.hideLoader();
          });
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  getValidity(i) {
    return (this.actionForm.get('catNames') as FormArray).controls[i].invalid;
  }

  get f() { return this.actionForm.controls; }
  get c() { return this.f.catNames as FormArray; }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.actionForm.invalid) {
      return;
    }

    this.loading = true;
    this.ls.showLoader();

    let categoryName = '';
    const languages = [];
    this.actionForm.value.catNames.forEach(singleEle => {
      if (singleEle.defaultLang === '1') {
        categoryName = singleEle.catName;
      }

      const lang = {
        LangKey: `${singleEle.lang_id}`,
        LangValue: `${singleEle.catName}`,
      };
      languages.push(lang);
    });

    // let formData = {};
    // if (this.actionForm.value.catID > 0) {
    //   formData = {
    //     catId: this.actionForm.value.mainCateId,
    //     subCatId: this.actionForm.value.catID,
    //     subCatName: categoryName,
    //     languages,
    //     // DisplayOrder: this.actionForm.value.displayOrder
    //   };
    // } else {
    //   formData = {
    //     CatName: categoryName,
    //     Languages: languages
    //   };
    // }

    const formData = {
      catId: this.actionForm.value.mainCateId,
      subCatId: this.actionForm.value.catID,
      subCatName: categoryName,
      languages,
    };

    this.categoryService.subCategoeryAction(this.action, formData)
      .pipe(first())
      .subscribe(
        data => {
          if (data['status'] === true) {
            this.alert.success(data['message']);
            this.ls.hideLoader();
            // setTimeout(() => {
            this.router.navigate([`${this.cs.STORE_URL}/product/sub-category`]);
            // }, 1000);

            // this.ls.hideLoader();
          } else {
            this.alert.danger(data['message']);
            this.ls.hideLoader();
          }
        },
        err => {
          this.alert.danger(err.error.message);
          this.ls.hideLoader();
          this.loading = false;
        });
  }
}

interface MainCategoryResponse {
  status: boolean;
  message: string;
  data: MainCategoryList[];
}

interface MainCategoryList {
  categoryId: number;
  categoryName: string;
}
