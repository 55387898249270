import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { constEnv } from "common/constants.env";
import { RoleGuard } from "common/_helpers/role.guard";

import { HomeComponent } from "./home/home.component";
import { CategoryComponent } from "./category/category.component";
import { ProductDetailComponent } from "./product-detail/product-detail.component";
import { CartComponent } from "./cart/cart.component";
import { HotDealComponent } from "./hot-deal/hot-deal.component";
import { LastMinuteDealComponent } from "./last-minute-deal/last-minute-deal.component";
import { ProductsComponent } from "./products/products.component";

const baseUrl = constEnv.BASE_URL;

const routes: Routes = [
  { path: "home", component: HomeComponent, canActivate: [RoleGuard] },
  {
    path: "category",
    children: [
      {
        path: ":categoryId",
        children: [
          { path: ":subCategoryId", component: CategoryComponent },
          { path: "", component: CategoryComponent },
        ],
      },
      { path: "", redirectTo: "/home", pathMatch: "full" },
    ],
    canActivate: [RoleGuard],
  },
  {
    path: "product",
    children: [
      {
        path: ":productId",
        children: [
          { path: ":variationId", component: ProductDetailComponent },
          { path: "", redirectTo: "/home", pathMatch: "full" },
        ],
      },
      { path: "", redirectTo: "/home", pathMatch: "full" },
    ],
    canActivate: [RoleGuard],
  },
  { path: "hot-deals", component: HotDealComponent, canActivate: [RoleGuard] },
  {
    path: "last-minute-deals",
    component: LastMinuteDealComponent,
    canActivate: [RoleGuard],
  },
  {
    path: "cart",
    component: CartComponent,
    canActivate: [RoleGuard],
    data: { for: "cart" },
  },
  {
    path: "buy-now",
    component: CartComponent,
    canActivate: [RoleGuard],
    data: { for: "buy-now" },
  },
  {
    path: "products",
    children: [
      {
        path: ":type",
        component: ProductsComponent,
      },
      { path: "", component: ProductsComponent },
    ],
    canActivate: [RoleGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ECommerceRoutingModule {}
