import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";
import { TemplateDefaultModule } from "../../shared/template-default/template-default.module";
import { CustomerRoutingModule } from "./customer-routing.module";
import { AuthGuard } from "common/_helpers/auth.guard";
import { ProfileModule } from "./profile/profile.module";

import { TranslateModule } from "@ngx-translate/core";
import { DataTablesModule } from "angular-datatables";
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ReactiveFormsModule } from "@angular/forms";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { MaterialModule } from "common/modules/material.module";
//import { AddressComponent } from './address/address/address.component';
import { AddressModule } from "./address/address.module";
import { OrdersModule } from "./orders/orders.module";
import { WishlistModule } from "./wishlist/wishlist.module";
import { JobModule } from "./job/job.module";
import { ReturnRequestComponent } from "./return-request/return-request.component";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { ModalModule } from "ngx-bootstrap/modal";
import { PopoverModule } from "ngx-bootstrap/popover";
import { PipeModule } from "common/_pipe/pipe.module";
import { CancelRequestModule } from "./cancel-request/cancel-request.module";
import { SupportCancelOrderComponent } from "./support-cancel-order/support-cancel-order.component";
import { NgSelectModule } from "@ng-select/ng-select";

@NgModule({
  declarations: [ReturnRequestComponent, SupportCancelOrderComponent],
  imports: [
    CommonModule,
    CustomerRoutingModule,
    ProfileModule,
    TemplateDefaultModule,
    TranslateModule,
    DataTablesModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    BsDatepickerModule,
    MaterialModule,
    AddressModule,
    OrdersModule,
    WishlistModule,
    JobModule,
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    PipeModule,
    CancelRequestModule,
    NgSelectModule,
    PopoverModule.forRoot(),
  ],
})
export class CustomerModule {}
