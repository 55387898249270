import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { CancelRequestRoutingModule } from "./cancel-request-routing.module";
import { ListsComponent } from "./lists/lists.component";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { ComponentModule } from "common/_components/components.module";
import { MaterialModule } from "common/modules/material.module";
import { TemplateDefaultModule } from "../../shared/template-default/template-default.module";
import { DataTablesModule } from "angular-datatables";
import { PipeModule } from "common/_pipe/pipe.module";
import { DetailComponent } from './detail/detail.component';

@NgModule({
  declarations: [ListsComponent, DetailComponent],
  imports: [
    CommonModule,
    CancelRequestRoutingModule,
    ReactiveFormsModule,
    TranslateModule,
    ComponentModule,
    MaterialModule,
    TemplateDefaultModule,
    DataTablesModule,
    PipeModule,
  ],
})
export class CancelRequestModule {}
