import { ThrowStmt } from "@angular/compiler";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { IsLoadingService } from "@service-work/is-loading";
import { constEnv } from "common/constants.env";
import { ReturnRequestService } from "common/services/store/returnRequest.service";
import { TitleService } from "common/services/title.service";
import { ReturnRequest } from "common/_models/store/returnRequest";
import { AlertService } from "ngx-alerts";

@Component({
  selector: "app-detail",
  templateUrl: "./detail.component.html",
  styleUrls: ["./detail.component.css"],
})
export class DetailComponent implements OnInit {
  requestId: number;

  config = constEnv;
  listUrl = "";

  requestDetail: ReturnRequest = null;

  constructor(
    private titleService: TitleService,
    private alert: AlertService,
    private isLoadingService: IsLoadingService,
    private rr: ReturnRequestService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Return Request Detail");

    this.requestId =
      this.route.snapshot.params.id > 0 ? this.route.snapshot.params.id : 0;

    this.listUrl = `${this.config.STORE_URL}/return-request`;

    if (this.requestId <= 0) {
      this.router.navigate([this.listUrl]);
      return false;
    }

    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }

    this.getRturnRequestDetail();
  }

  getRturnRequestDetail() {
    this.isLoadingService.add();

    this.rr.getRequestById(this.requestId).subscribe(
      (res) => {
        const data = res as RequestDetailResponse;

        if (data.status === true) {
          this.requestDetail = new ReturnRequest().deserialize(data.data);
          this.isLoadingService.remove();
        } else {
          this.alert.danger(data.message);
          this.isLoadingService.remove();
        }
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          this.alert.danger(err.error.message);
        }
        this.isLoadingService.remove();
      }
    );
  }

  showPickupDateByStatus(item: any): boolean {
    const returnStatusIds = [15, 16];
    if (item.pickupDate == "" && item.pickupDate == null) {
      return false;
    }
    // if (returnStatusIds.indexOf(item.returnStatusId) !== -1) {
    //   return true;
    // }
    return true;
  }
}

interface RequestDetailResponse {
  status: boolean;
  message: string;
  data: ReturnRequest;
}
