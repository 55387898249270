import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { constEnv } from 'common/constants.env';

const STORE_URL = constEnv.storeEndPoint;

const routes: Routes = [
  { path: `${STORE_URL}`, redirectTo: `${STORE_URL}/login`, pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
