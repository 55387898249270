export class HomeBanner {
    bannerId: number;
    bannerImageUrl: string;
    id: number;
    redirectionType: string;
    status: boolean;
    title: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
