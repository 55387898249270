import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { LoaderService } from "common/services/loader.service";
import { AlertService } from "@full-fledged/alerts";
import { SettingsService } from "common/services/store/settings.service";
import { TitleService } from "common/services/title.service";

@Component({
  selector: "app-bank-detail",
  templateUrl: "./bank-detail.component.html",
  styleUrls: ["./bank-detail.component.css"],
})
export class BankDetailComponent implements OnInit {
  bankDetailForm: FormGroup;
  submitted = false;
  loading = false;

  constructor(
    private fb: FormBuilder,
    private ls: LoaderService,
    private alert: AlertService,
    private settings: SettingsService,
    private titleService: TitleService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("STORE_Bank_Detail");
    this.makeForm();

    this.getBankData();
  }

  makeForm() {
    this.bankDetailForm = this.fb.group({
      type: [""],
      name: ["", Validators.required],
      email: ["", Validators.required],
      accountNumber: ["", Validators.required],
      bankName: ["", Validators.required],
      location: ["", Validators.required],
      bankCode: ["", Validators.required],
    });
  }

  get f() {
    return this.bankDetailForm.controls;
  }

  getBankData() {
    this.ls.showLoader();
    this.settings.getBankDetail().subscribe(
      (data) => {
        const res = data as BankDetailResponse;
        if (res.status === true) {
          if (res.data !== null) {
            this.setFormValue(res.data);
            this.bankDetailForm.get("type").setValue("edit");
          }
          this.ls.hideLoader();
        } else {
          // this.alert.error(data['message']);
          this.ls.hideLoader();
        }
      },
      (err) => {
        // this.alert.error(err.error.message);
        this.ls.hideLoader();
      }
    );
  }

  setFormValue(data: BankDetail) {
    for (const key in data) {
      if (
        data.hasOwnProperty(key) &&
        key !== "store_id" &&
        key !== "driver_id"
      ) {
        if (typeof data[key] !== null) {
          this.bankDetailForm.get(key).setValue(data[key]);
        }
      }
    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.bankDetailForm.invalid) {
      return;
    }

    this.ls.showLoader();
    this.loading = true;

    this.bankDetailForm.value.type =
      this.bankDetailForm.value.type === "edit" ? "edit" : "add";

    this.settings.updateBankDetail(this.bankDetailForm.value).subscribe(
      (data) => {
        if (data["status"] === true) {
          this.alert.success(data["message"]);
          this.ls.hideLoader();
        } else {
          this.alert.danger(data["message"]);
          this.ls.hideLoader();
        }
        this.loading = false;
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        this.alert.danger(msg);
        this.ls.hideLoader();
        this.loading = false;
      }
    );
  }
}

export interface BankDetailResponse {
  status: boolean;
  message: string;
  data: BankDetail;
}

export interface BankDetail {
  bankName: string;
  accountNumber: string;
  bankCode: string;
  location: string;
  name: string;
  email: string;
}
