import { Component, OnInit, Inject, ViewChild, NgZone, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { base64ToFile } from 'ngx-image-cropper';
import { Subscription } from 'rxjs';

import { constEnv } from 'common/constants.env';
import { TitleService } from 'common/services/title.service';
import { AlertService } from 'ngx-alerts';
import { IsLoadingService } from '@service-work/is-loading';
import { VehicleService } from 'common/services/store/vehicle.service';
import { Vehicle, VehicleMake, vehicleModel, VehicleType } from 'common/_models/store/vehicle.model';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.css']
})
export class ActionComponent implements OnInit {

  config = constEnv;

  actionForm: FormGroup;
  loading = false;
  submitted = false;

  languages = [];

  pageTitle = 'DRIVER_Add_Vehicle';

  action: string;
  id: number;

  isEmailAvailable = false;

  listUrl = `${this.config.STORE_URL}/vehicle`;

  currentYear = new Date().getFullYear();
  vehicleYear = [];

  vehicleType: VehicleType[] = [];
  vehicleMake: VehicleMake[] = [];
  vehicleModel: vehicleModel[] = [];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private alert: AlertService,
    private titleService: TitleService,
    private isLoadingService: IsLoadingService,
    private vehicle: VehicleService    
  ) { 
    this.action = route.snapshot.params.action;
  }

  ngOnInit() {
    this.document.body.classList.add('driver-action');

    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }

    for(let i = this.currentYear; i >= 1970; i--) {
      this.vehicleYear.push(i);
    }

    switch (this.action) {
      case 'edit':
        const id = this.route.snapshot.params.id;
        this.id = (typeof id !== 'undefined') ? id : 0;
        if (this.id <= 0) {
          this.router.navigate([this.listUrl]);
        }

        this.pageTitle = 'Edit_Vehicle';

        if (this.id > 0) {
          this.makeForm();
          this.getAutoloadData();

          this.isLoadingService.add();
          this.vehicle.getVehiclebyId(this.id)
            .pipe(first())
            .subscribe(
              data => {    
                if (data['status'] === true) {
                  const formData = data['data'] as Vehicle;
                  this.setFormValue( formData );
                } else {
                  this.alert.danger(data['message']);                
                }
                this.isLoadingService.remove();
              },
              err => {
                if (typeof err.error !== 'undefined') {
                  const msg = (typeof err.error.Message !== 'undefined') ? err.error.Message : err.error.message;
                  this.alert.danger(msg);
                }
                this.router.navigate([`${this.listUrl}`]);
                this.isLoadingService.remove();
              });
        } else {
          this.router.navigate([`${this.listUrl}`]);
        }
        break;

      case 'add':
        this.makeForm();
        this.getAutoloadData();
        break;

      default:
        this.router.navigate([this.listUrl]);
        break;
    }

    this.titleService.setTitle(this.pageTitle);
  }

  getAutoloadData() {
    this.isLoadingService.add();
    this.vehicle.getVehicleType()
    .subscribe(
      response => {
        if(response['status'] === true) {
          this.vehicleType = response['data'] as VehicleType[];
        }
        this.isLoadingService.remove();
      }, 
      err => {
        this.vehicleType = [];
        this.isLoadingService.remove();
      }
    );

    this.vehicle.getVehicleMake()
    .subscribe(
      response => {
        if(response['status'] === true) {
          this.vehicleMake = response['data'] as VehicleMake[];
        }
        this.isLoadingService.remove();
      }, 
      err => {
        this.vehicleMake = []
        this.isLoadingService.remove();
      }
    );
  }

  makeForm() {
    const emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.actionForm = this.fb.group({
      vehicleId: [0],
      vehicleTypeId: [null, Validators.required],
      vehicleMakeId: [null, Validators.required],
      vehicleModelId: [null, Validators.required],
      year: [null, Validators.required],
      vehicleNumber: ['', Validators.required],
      vehicleColor: [''],
    });
  }

  setFormValue(formData: Vehicle) {

    this.getVehicleModel(formData.makeId);

    const myFromData = {
      vehicleId: formData.vehicleId,
      vehicleTypeId: formData.vehicleTypeId,
      vehicleMakeId: formData.makeId,
      vehicleModelId: formData.modelId,
      year: formData.year,
      vehicleNumber: formData.vehicleNumber,
      vehicleColor: formData.vehicleColor
    }

    this.actionForm.patchValue(myFromData);
  }

  getVehicleModel( $event ) {
    this.isLoadingService.add();
    this.vehicle.getVehicleModel($event)
    .subscribe(
      response => {
        if(response['status'] === true) {
          this.vehicleModel = response['data'] as vehicleModel[];
        }
        this.isLoadingService.remove();
      }, 
      err => {
        this.vehicleModel = [];
        this.isLoadingService.remove();
      }
    );
  }

  onSubmit() {
    this.submitted = true;

    if (this.actionForm.invalid) {
      return;
    }

    this.loading = true;
    this.isLoadingService.add();

    this.vehicle.manageVehicle( this.actionForm.value )
      .pipe(first())
      .subscribe(
        data => {
          if (data['status'] === true) {
            this.alert.success(data['message']);
            this.router.navigate([`${this.listUrl}`]);
          } else {
            this.alert.danger(data['message']);
            this.isLoadingService.remove();
          }
        },
        err => {
          if (typeof err.error !== 'undefined') {
            const msg = (typeof err.error.Message !== 'undefined') ? err.error.Message : err.error.message;
            this.alert.danger(msg);
          }
          this.isLoadingService.remove();
          this.loading = false;
        });
  }

}
