import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { constEnv } from "common/constants.env";

// import { DcEmailVerifyComponent } from './dc-email-verify/dc-email-verify.component';
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { LoginComponent } from "./login/login.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
// import { DcRegisterComponent } from './dc-register/dc-register.component';

const DesignerPrefix = constEnv.designerEndPoint;

const routes: Routes = [
  { path: `${DesignerPrefix}/login`, component: LoginComponent },
  // { path: `${DeliveryCompanyPrefix}/register` , component: DcRegisterComponent },
  {
    path: `${DesignerPrefix}/forgot-password`,
    component: ForgotPasswordComponent,
  },
  {
    path: `${DesignerPrefix}/reset-password/:token`,
    component: ResetPasswordComponent,
  },
  // { path: `${DeliveryCompanyPrefix}/email-verify/:token` , component: DcEmailVerifyComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DesignerRoutingModule {}
