import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { IsLoadingService } from "@service-work/is-loading";
import { DataTableDirective } from "angular-datatables";
import { Config } from "datatables.net";
import { GeneralService } from "common/services/general.service";
import { TitleService } from "common/services/title.service";
import { ConfirmDialogComponent } from "common/_components/confirm-dialog/confirm-dialog.component";
import { AlertService } from "@full-fledged/alerts";
import { BsModalRef, ModalDirective } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";

@Component({
  selector: "app-delivery-zone",
  templateUrl: "./delivery-zone.component.html",
  styleUrls: ["./delivery-zone.component.css"],
})
export class DeliveryZoneComponent implements OnInit {
  modalRef: BsModalRef;
  subscriptions: Subscription[] = [];

  deliveryZoneActionForm: FormGroup;
  submitted = false;

  lists: DeliveryZoneList[] = [];

  @ViewChild("DZActionModel", { static: false })
  DZActionModel: ModalDirective;

  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;

  // dtOptions: DataTables.Settings = {};
  dtOptions: Config = {};

  countryLists: countryList[] = [];
  stateLists: stateList[] = [];
  cityLists: cityList[] = [];

  modelTitle: string;

  constructor(
    private alert: AlertService,
    private ls: IsLoadingService,
    private fb: FormBuilder,
    private titleService: TitleService,
    private dialog: MatDialog,
    private gs: GeneralService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Zone");
    if (this.ls.isLoading$()) {
      this.ls.remove();
    }
    this.modelTitle = "AddZone";
    this.createDataTable();
    this.getCountryList();
    this.makeForm();
  }

  createDataTable() {
    this.dtOptions = {
      pagingType: "full_numbers",
      ordering: false,
      lengthChange: false,
      searching: false,
      serverSide: true,
      processing: true,
      // pageLength: -1,
      language: {
        paginate: {
          previous: "<",
          first: "<<",
          last: ">>",
          next: ">",
        },
      },
      ajax: (dataTablesParameters: any, callback) => {
        // const searchparams = this.searchForm.value;
        // if (searchparams.filterText !== '') {
        //   dataTablesParameters.search.column = searchparams.filterBy;
        //   dataTablesParameters.search.value = searchparams.filterText;
        // }

        // dataTablesParameters.search.dateTimeForm = '';
        // dataTablesParameters.search.dateTimeTo = '';
        // if (searchparams.orderFilterDates !== '') {
        //   const oDates = searchparams.orderFilterDates;
        //   dataTablesParameters.search.dateTimeForm = this.datePipe.transform( oDates[0], this.dateFormat );
        //   dataTablesParameters.search.dateTimeTo = this.datePipe.transform( oDates[1], this.dateFormat );
        // }

        let paging: Paginate = null;

        this.gs.getDeliveryZoneLists(dataTablesParameters).subscribe(
          (data) => {
            const res = data as DeliveryZoneListResponse;

            if (res.status === true) {
              this.lists = res.data as DeliveryZoneList[];

              paging = JSON.parse(res.paging) as Paginate;
            } else {
              paging = {
                totalCount: 0,
                totalRowsAfterFiltering: 0,
              } as Paginate;

              this.alert.danger(data["message"]);
              this.lists = [];
            }

            callback({
              recordsTotal: paging.totalCount,
              recordsFiltered: paging.totalRowsAfterFiltering,
              data: [],
            });

            // this.loader.hideLoader();
          },
          (err) => {
            const msg =
              typeof err.error.Message !== "undefined"
                ? err.error.Message
                : err.error.message;
            if (msg !== "") {
              // this.alert.danger(msg);
              callback({
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              });
              this.lists = [];
            }
            // this.loader.hideLoader();
          }
        );
      },
    };
  }

  openModal() {
    this.DZActionModel.show();
  }

  hideModal() {
    this.submitted = false;
    this.deliveryZoneActionForm.reset();
  }

  makeForm() {
    this.deliveryZoneActionForm = this.fb.group({
      id: [0],
      zoneName: ["", Validators.required],
      roleIdentifier: [0],
      roleIdentifierId: [0],
      countryId: [null],
      stateId: [null],
      cityId: [null],
    });
  }

  private getCountryList() {
    this.gs.getAllCountry().subscribe(
      (data) => {
        if (data["status"] === true) {
          this.countryLists = data["data"];
        }
        this.ls.remove();
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          this.alert.danger(err.error.message);
        }
        this.ls.remove();
      }
    );
  }

  onCountryChange(countryId: number) {
    if (countryId > 0) {
      this.ls.add();
      this.stateLists = [];
      this.cityLists = [];
      this.deliveryZoneActionForm.get("stateId").setValue(null);
      this.deliveryZoneActionForm.get("cityId").setValue(null);
      this.gs.getstateByCountryId(countryId).subscribe(
        (data) => {
          if (data["status"] === true) {
            this.stateLists = data["data"];
          }
          this.ls.remove();
        },
        (err) => {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          if (msg !== "") {
            this.alert.danger(err.error.message);
          }
          this.ls.remove();
        }
      );
    } else {
      this.stateLists = [];
      this.cityLists = [];
    }
  }

  onStateChange(stateId: number) {
    if (stateId > 0) {
      this.ls.add();
      this.cityLists = [];
      this.deliveryZoneActionForm.get("cityId").setValue(null);
      this.gs.getcityByStateId(stateId).subscribe(
        (data) => {
          if (data["status"] === true) {
            this.cityLists = data["data"];
          }
          this.ls.remove();
        },
        (err) => {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          if (msg !== "") {
            this.alert.danger(err.error.message);
          }
          this.ls.remove();
        }
      );
    } else {
      this.cityLists = [];
    }
  }

  changeStatus(id: number, status: boolean) {
    if (id > 0) {
      this.ls.add();
      this.gs.deliveryZoneChangeStatus(id, status ? 1 : 0).subscribe(
        (data) => {
          if (data["status"] === true && data["message"] !== "") {
            this.alert.success(data["message"]);
          } else {
            if (data["message"] !== "") {
              this.alert.danger(data["message"]);
            }
          }

          // this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) =>
          this.datatableElement.dtInstance.then((dtInstance) =>
            dtInstance.draw()
          );
          this.ls.remove();
          // this.loadProductLists();
        },
        (err) => {
          this.alert.danger(err.error.message);
          this.ls.remove();
        }
      );
    }
  }

  onClear(type: string) {
    if (type == "country") {
      this.deliveryZoneActionForm.get("stateId").setValue(null);
      this.stateLists = [];
    }
    this.deliveryZoneActionForm.get("cityId").setValue(null);
    this.cityLists = [];
  }

  delete(id) {
    if (id > 0) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: "Confirm",
          message: "DeleteConfirm",
        },
      });
      confirmDialog.afterClosed().subscribe((result) => {
        if (result === true) {
          this.ls.add();
          this.gs.deleteDeliveryZone(id).subscribe(
            (data) => {
              if (data["status"] === true) {
                this.alert.success(data["message"]);
              } else {
                this.alert.danger(data["message"]);
              }
              this.datatableElement.dtInstance.then(
                // (dtInstance: DataTables.Api) => dtInstance.draw()
                (dtInstance) => dtInstance.draw()
              );
              this.ls.remove();
              // this.loadProductLists();
            },
            (err) => {
              if (typeof err.error !== "undefined") {
                const msg =
                  typeof err.error.Message !== "undefined"
                    ? err.error.Message
                    : err.error.message;
                this.alert.danger(msg);
              }
              this.ls.remove();
            }
          );
        }
      });
    }
  }

  editDeliveryZone(id: number) {
    this.ls.add();
    this.gs.getDeliveryZoneById(id).subscribe(
      (data) => {
        const res = data as DeliveryZoneResponse;

        if (res.status === true) {
          this.modelTitle = "EditZone";
          const formEditData = res.data as DeliveryZoneList;

          this.deliveryZoneActionForm.get("id").setValue(formEditData.id);
          this.deliveryZoneActionForm
            .get("zoneName")
            .setValue(formEditData.zoneName);
          this.deliveryZoneActionForm
            .get("countryId")
            .setValue(
              formEditData.countryId > 0 ? formEditData.countryId : null
            );

          this.onCountryChange(formEditData.countryId);

          this.deliveryZoneActionForm
            .get("stateId")
            .setValue(formEditData.stateId > 0 ? formEditData.stateId : null);

          this.onStateChange(formEditData.stateId);

          this.deliveryZoneActionForm
            .get("cityId")
            .setValue(formEditData.cityId > 0 ? formEditData.cityId : null);
          this.deliveryZoneActionForm
            .get("roleIdentifier")
            .setValue(formEditData.roleIdentifier);
          this.deliveryZoneActionForm
            .get("roleIdentifierId")
            .setValue(formEditData.roleIdentifierId);

          this.ls.remove();
          this.DZActionModel.show();
        } else {
          this.alert.danger(data["message"]);
        }
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          this.alert.danger(msg);
        }
        this.ls.remove();
      }
    );
  }

  get f() {
    return this.deliveryZoneActionForm.controls;
  }

  submitForm() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.deliveryZoneActionForm.invalid) {
      return;
    }

    this.deliveryZoneActionForm.value.id =
      this.deliveryZoneActionForm.value.id !== null
        ? this.deliveryZoneActionForm.value.id
        : 0;
    this.deliveryZoneActionForm.value.roleIdentifier =
      this.deliveryZoneActionForm.value.roleIdentifier !== null
        ? this.deliveryZoneActionForm.value.roleIdentifier
        : 0;
    this.deliveryZoneActionForm.value.roleIdentifierId =
      this.deliveryZoneActionForm.value.roleIdentifierId !== null
        ? this.deliveryZoneActionForm.value.roleIdentifierId
        : 0;

    this.deliveryZoneActionForm.value.countryId =
      this.deliveryZoneActionForm.value.countryId !== null
        ? this.deliveryZoneActionForm.value.countryId
        : 0;

    this.deliveryZoneActionForm.value.stateId =
      this.deliveryZoneActionForm.value.stateId !== null
        ? this.deliveryZoneActionForm.value.stateId
        : 0;

    this.deliveryZoneActionForm.value.cityId =
      this.deliveryZoneActionForm.value.cityId !== null
        ? this.deliveryZoneActionForm.value.cityId
        : 0;

    this.ls.add();

    this.gs.addEditDeliveryZone(this.deliveryZoneActionForm.value).subscribe(
      (data) => {
        if (data["status"] === true) {
          this.alert.success(data["message"]);
          this.DZActionModel.hide();
          // this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) =>
          this.datatableElement.dtInstance.then((dtInstance) =>
            dtInstance.draw()
          );
          this.ls.remove();
        } else {
          this.alert.danger(data["message"]);
          this.ls.remove();
        }
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        this.alert.danger(msg);
        this.ls.remove();
      }
    );
  }
}

interface DeliveryZoneListResponse {
  status: boolean;
  message: string;
  paging: string;
  data: any[];
}

interface DeliveryZoneResponse {
  status: boolean;
  message: string;
  data: DeliveryZoneList;
}

interface Paginate {
  totalCount: number;
  totalRowsAfterFiltering: number;
  pageSize: number;
  currentPage: number;
  totalPages: number;
  previousPage: string;
  nextPage: string;
}

interface DeliveryZoneList {
  id: number;
  zoneName: string;
  roleIdentifier: number;
  roleIdentifierId: number;
  countryId: number;
  stateId: number;
  cityId: number;
  status: boolean;
}

interface countryList {
  countryId: number;
  countryName?: string;
}

interface stateList {
  stateId: number;
  stateName?: string;
}

interface cityList {
  cityId: number;
  cityName?: string;
}
