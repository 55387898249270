import { Injectable } from '@angular/core';
import { IsLoadingService } from '@service-work/is-loading';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoaderService {

    constructor(
        private isLoadingService: IsLoadingService,
    ) { }

    isLoading() {
        return this.isLoadingService.isLoading$();
    }

    showLoader() {
        this.isLoadingService.add();
        this.isLoading();
    }

    hideLoader() {
        this.isLoadingService.remove();
    }
}
