import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

//import { ConstansService } from 'common/services/constans.service';
import { constEnv } from "common/constants.env";
import { map } from "rxjs/operators";
import { AuthenticationService } from "common/services/authentication.service";

@Injectable({ providedIn: "root" })
export class CustomerService {
  currentUserProfile;
  configConstant = constEnv;
  apiURL = this.configConstant.apiCustomerURL;
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {
    this.currentUserProfile =
      this.authenticationService.currentUserProfileValue;
  }
  getProfile() {
    return this.http.get(`${this.apiURL}/getprofile`).pipe(
      map((profile) => {
        let data = [];
        if (profile["status"]) {
          data = profile["data"];

          localStorage.setItem("currentUserProfile", JSON.stringify(data));
        }
        return data;
      })
    );
  }

  updateProfile(formData) {
    return this.http.post(`${this.apiURL}/updatecustprofile`, formData).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getDashboard() {
    return this.http.get(`${this.apiURL}/dashboard`).pipe(
      map((data) => {
        let dashboardData = {};
        if (data["status"]) {
          dashboardData = data["data"];
        }
        return dashboardData;
      })
    );
  }

  register(formData) {
    const requestHeader = {
      "Content-Type": "application/json",
    };

    // const requestData = new HttpParams()
    //     .set('email', user.email)
    //     .set('password', user.password)
    //     .set('phoneCode', user.countryCode)
    //     .set('phone', user.mobileNo)
    //     .set('first_name', user.firstName)
    //     .set('last_name', user.lastName);

    return this.http.post(`${this.apiURL}/profile`, formData).pipe(
      map((data) => {
        return data;
      })
    );
  }

  forgotPassword(email) {
    const requestHeader = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    const requestData = new HttpParams().set("customerEmail", email);

    return this.http
      .post(`${this.apiURL}/forgotpassword`, requestData, {
        headers: requestHeader,
        params: requestData,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  resetPassword(accessToken, newPassword) {
    // const requestHeader = {
    //     'Content-Type': 'application/x-www-form-urlencoded'
    // };

    const requestData = new HttpParams()
      .set("accessToken", accessToken)
      .set("newPassword", newPassword);

    const formData = {
      accessToken: accessToken,
      newPassword: newPassword,
    };

    return this.http.post(`${this.apiURL}/resetpassword`, formData).pipe(
      map((data) => {
        return data;
      })
    );
  }

  changeProfilePhoto(formModel) {
    return this.http.post(`${this.apiURL}/editprofileimage`, formModel).pipe(
      map((data) => {
        if (data["status"] === true) {
          const responseData = data["data"];
          const imageURL =
            typeof responseData.image_url !== "undefined"
              ? responseData.image_url
              : "";
          if (imageURL !== "") {
            if (typeof this.currentUserProfile !== "undefined") {
              this.currentUserProfile.profile_image = imageURL;
            }
            localStorage.setItem(
              "currentUserProfile",
              JSON.stringify(this.currentUserProfile)
            );
          }
        }
        return data;
      })
    );
  }

  delete(id: number) {
    return this.http.delete(`${this.apiURL}/users/${id}`);
  }

  verifyEmail(token: string) {
    return this.http.get(`${this.apiURL}/verify/${token}`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getHeaderCounters() {
    return this.http.get(`${this.apiURL}/GetCounts`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  checkSocialLogin(socialId, socialType, socialEmail) {
    const formData = {
      SocialType: socialType,
      SocialId: socialId,
      Email: socialEmail,
    };

    return this.http.post(`${this.apiURL}/checklogin`, formData).pipe(
      map((data) => {
        if (data["status"] === true) {
          localStorage.setItem("currentUser", JSON.stringify(data["data"]));
          this.authenticationService.currentUserSubject.next(data["data"]);
        }

        return data;
      })
    );
  }

  socialLogin(formData) {
    return this.http.post(`${this.apiURL}/externallogin`, formData).pipe(
      map((data) => {
        localStorage.setItem("currentUser", JSON.stringify(data["data"]));
        this.authenticationService.currentUserSubject.next(data["data"]);
        return data;
      })
    );
  }

  changePassword(formData) {
    return this.http.post(`${this.apiURL}/changepassword`, formData).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getCustomerOrders(dataTablesParameters) {
    let searchValue = "";
    let searchBy = "";
    // if ( dataTablesParameters.search.value !== '' ) {
    //     searchValue = dataTablesParameters.search.value;
    //     searchBy = dataTablesParameters.search.column;
    // }
    let orderId = "";
    if (typeof dataTablesParameters.orderId !== "undefined") {
      orderId = dataTablesParameters.orderId;
    }

    let dateFrom = "";
    if (typeof dataTablesParameters.dateFrom !== "undefined") {
      dateFrom = dataTablesParameters.dateFrom;
    }

    let dateTo = "";
    if (typeof dataTablesParameters.dateTo !== "undefined") {
      dateTo = dataTablesParameters.dateTo;
    }
    const pageNo =
      (dataTablesParameters.start * 1) / (dataTablesParameters.length * 1);
    const searchParams = new HttpParams()
      .set("searchColumn", searchBy)
      .set("searchValue", searchValue)
      .set("pageNo", pageNo.toString())
      .set("pageSize", dataTablesParameters.length)
      .set("sortColumn", "")
      .set("sortOrder", "")
      .set("orderId", orderId)
      .set("DateTimeForm", dateFrom)
      .set("DateTimeTo", dateTo);

    // if(orderId !== ''){

    //     searchParams.set('orderId', orderId);
    // }
    // console.log(searchParams);
    return this.http
      .get(`${this.apiURL}/getorder`, {
        params: searchParams,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getCustomerAddressese(addressId = 0) {
    const coallbackUrl =
      addressId > 0
        ? `${this.apiURL}/getaddress/${addressId}`
        : `${this.apiURL}/getaddress`;

    return this.http.get(coallbackUrl).pipe(
      map((data) => {
        return data;
      })
    );
  }
  updateCustomerAddress(formData) {
    return this.http.post(`${this.apiURL}/updateaddress`, formData).pipe(
      map((data) => {
        return data;
      })
    );
  }
  deleteCustomerAddress(addressId: number) {
    return this.http.get(`${this.apiURL}/deleteaddress/${addressId}`, {}).pipe(
      map((data) => {
        return data;
      })
    );
  }

  setCustomerDefaultAddress(addressId: number) {
    return this.http.get(`${this.apiURL}/setdefaultaddress/${addressId}`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getCustomerOrderDetail(orderId) {
    return this.http.get(`${this.apiURL}/getorderbyid/${orderId}`).pipe(
      map((data) => {
        return data;
      })
    );
  }
  getCustomerWishList(paging) {
    const pageNo = typeof paging.page !== "undefined" ? paging.page : 0;
    const perPage =
      typeof paging.itemsPerPage !== "undefined" ? paging.itemsPerPage : 12;

    // const searchParams = new HttpParams()
    //     .set('searchColumn', '')
    //     .set('searchValue', '')
    //     .set('pageNo', pageNo)
    //     .set('pageSize', perPage)
    //     .set('sortColumn', '')
    //     .set('sortOrder', '');

    const searchParams = {
      searchColumn: "",
      searchValue: "",
      pageNo,
      pageSize: perPage,
      sortColumn: "",
      sortOrder: "",
    };

    // return this.http.get( `${this.apiUrl}/getwhishlist`, {
    //     params: searchParams
    // } )
    //     .pipe(map(data => {
    //         return data;
    //     }));

    return this.http.get(`${this.apiURL}/getwishList`).pipe(
      map((data) => {
        return data;
      })
    );
  }
  getProductDetail(productId) {
    const searchParams = {
      pid: productId,
    };
    return this.http
      .get(`${this.apiURL}/productdetail`, {
        params: searchParams,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  removeCustomerWishlist(wishlistId: number) {
    return this.http.get(`${this.apiURL}/deletewishList/${wishlistId}`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  // Jobs
  getCustomerJobs(dataTablesParameters) {
    let searchValue = "";
    let searchBy = "";
    // if ( dataTablesParameters.search.value !== '' ) {
    //     searchValue = dataTablesParameters.search.value;
    //     searchBy = dataTablesParameters.search.column;
    // }

    const pageNo =
      (dataTablesParameters.start * 1) / (dataTablesParameters.length * 1);
    const searchParams = new HttpParams()
      .set("searchColumn", searchBy)
      .set("searchValue", searchValue)
      .set("pageNo", pageNo.toString())
      .set("pageSize", dataTablesParameters.length)
      .set("sortColumn", "")
      .set("sortOrder", "");

    return this.http
      .get(`${this.apiURL}/getjoblist`, {
        params: searchParams,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  createCustomerJob(formData) {
    return this.http.post(`${this.apiURL}/createjob`, formData).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getJobItems(jobId: number) {
    return this.http.get(`${this.apiURL}/getproductlistforjob/${jobId}`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getSpaceType() {
    return this.http.get(`${this.apiURL}/getspacetype`).pipe(
      map((data) => {
        return data;
      })
    );
  }
  // END

  getReturnRequestPickupCharge(params: any) {
    const queryParams = new HttpParams()
      .set("Quantity", params.Quantity)
      .set("ProductId", params.ProductId)
      .set("StoreId", params.StoreId)
      .set("OrderDetailId", params.OrderDetailId)
      .set("IsReplaced", params.IsReplaced);

    return this.http
      .get(`${this.apiURL}/getpickupcharges`, {
        params: queryParams,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  returnRequestTickets(formData) {
    return this.http.post(`${this.apiURL}/generateticket`, formData).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getReturnRequestLists(paging, search: any = []) {
    const searchBy =
      typeof search.searchBy !== "undefined" ? search.searchBy : "";
    const searchValue =
      typeof search.searchValue !== "undefined" ? search.searchValue : "";
    const searchParams = new HttpParams()
      .set("SearchColumn", searchBy)
      .set("SearchValue", searchValue)
      .set("PageNo", paging.page.toString())
      .set("PageSize", paging.itemsPerPage)
      .set("SortColumn", "")
      .set("SortOrder", "");

    return this.http
      .get(`${this.apiURL}/getreturnticketlist`, {
        params: searchParams,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  returnRequestPayment(ticketId: number) {
    return this.http
      .post(`${this.configConstant.apiURL}/returnpay/${ticketId}`, {})
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  updatePickupDate(ticketId: number, pickupDate: string) {
    const formDate = new FormData();
    formDate.append("TicketId", ticketId.toString());
    formDate.append("PickupDate", pickupDate);
    return this.http.patch(`${this.apiURL}/changepickupdate`, formDate).pipe(
      map((data) => {
        return data;
      })
    );
  }

  returnRequestPaymentSuccess(ticketId: number) {
    return this.http
      .post(
        `${this.configConstant.apiURL}/returnpaymentsuccess?TicketId=${ticketId}`,
        {}
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  cancelRequestTickets(formData) {
    return this.http.post(`${this.apiURL}/generatecancelticket`, formData).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getCancelRequestLists(dataTablesParameters) {
    let searchValue = "";
    let searchBy = "";
    // if ( dataTablesParameters.search.value !== '' ) {
    //     searchValue = dataTablesParameters.search.value;
    //     searchBy = dataTablesParameters.search.column;
    // }

    const pageNo =
      (dataTablesParameters.start * 1) / (dataTablesParameters.length * 1);
    const searchParams = new HttpParams()
      .set("SearchColumn", searchBy)
      .set("SearchValue", searchValue)
      .set("PageNo", pageNo.toString())
      .set("PageSize", dataTablesParameters.length)
      .set("SortColumn", "")
      .set("SortOrder", "");

    return this.http
      .get(`${this.apiURL}/getcancelticketlist`, {
        params: searchParams,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getCancelRequestDetail(requestId: number) {
    return this.http.get(`${this.apiURL}/getcancelicketbyid/${requestId}`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  addFeedBack(formData) {
    return this.http.patch(`${this.apiURL}/customerfeedback`, formData).pipe(
      map((data) => {
        return data;
      })
    );
  }

  rateReviewToStoreOrderProduct(formData) {
    return this.http.post(`${this.apiURL}/addstorereview`, formData).pipe(
      map((data) => {
        return data;
      })
    );
  }
}
