import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";

import { constEnv } from "common/constants.env";
import { TitleService } from "common/services/title.service";
import { AlertService } from "ngx-alerts";
import { TranslateService } from "@ngx-translate/core";

import { IsLoadingService } from "@service-work/is-loading";
import { ProductService } from "common/services/store/product.service";
import {
  AttributeValueList,
  ProductVariation,
} from "common/_models/store/productVariation.model";
import { ConfirmDialogComponent } from "common/_components/confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material";
import { first } from "rxjs/operators";

@Component({
  selector: "app-variations",
  templateUrl: "./variations.component.html",
  styleUrls: ["./variations.component.css"],
})
export class VariationsComponent implements OnInit {
  config = constEnv;
  productEditUrl: string;
  productListUrl: string;

  loading = false;
  submitted = false;

  productId = 0;

  productVariations: ProductVariation[] = [];
  isSingleVariation = false;

  variationForm: FormGroup;

  @ViewChild("variationImageInput", { static: true })
  variationImageInput: ElementRef;
  variationImageFileData: File[] = [];
  imageSelected: boolean[] = [];
  formSubmitted = false;

  @ViewChild("topViewInput", { static: true }) topViewInput: ElementRef;
  topViewInputData: File[] = [];
  topViewPreviewUrl: string[] = [];
  topViewError: string[] = [];
  topViewUploadedFile: string[] = [];
  topViewUploadedFileId: number[] = [];

  @ViewChild("augmentedViewInput", { static: true })
  augmentedViewInput: ElementRef;
  augmentedViewInputData: File[] = [];
  augmentedViewPreviewUrl: string[] = [];
  augmentedViewError: string[] = [];
  augmentedViewUploadedFile: string[] = [];
  augmentedViewUploadedFileId: number[] = [];

  @ViewChild("threeDViewInput", { static: true }) threeDViewInput: ElementRef;
  threeDViewInputData: File[] = [];
  threeDViewPreviewUrl: string[] = [];
  threeDViewError: string[] = [];
  threeDViewUploadedFile: string[] = [];
  threeDViewUploadedFileId: number[] = [];

  availableQty = [];
  attributeValueLists = [];

  isAllVariationCreated: boolean = false;

  PRIMARY_IMAGE = 4;
  TOPVIEW_IMAGE = 4;
  AUGMENTED_VIEW = 5;
  THREE_VIEW = 6;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private alert: AlertService,
    private isLoadingService: IsLoadingService,
    private titleService: TitleService,
    private translate: TranslateService,
    private product: ProductService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Product Variations");

    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }

    this.productListUrl = `${this.config.STORE_URL}/product/lists`;

    const id = this.route.snapshot.params.id;
    this.productId = typeof id !== "undefined" ? id : 0;
    if (this.productId <= 0) {
      this.router.navigate([this.productListUrl]);
    }

    this.productEditUrl = `${this.config.STORE_URL}/product/edit/${this.productId}`;

    this.loadProductVariations();
    this.createForm();
  }

  loadProductVariations() {
    this.isLoadingService.add();
    this.product.getProductVariations(this.productId).subscribe(
      (data) => {
        const res = data as ProductVariationListResponse;
        if (res.status === true) {
          this.clearFormArray(this.v);
          this.productVariations = [];
          this.isAllVariationCreated = res.data.isAllVariationCreated;
          if (res.data.data.length > 0) {
            this.productVariations = res.data.data.map((item) =>
              new ProductVariation().deserialize(item)
            );

            this.productVariations.forEach((single, index) => {
              this.createVariations(single);
              if (typeof single.attributeList !== "undefined") {
                single.attributeList.forEach((attr, i) => {
                  const attrId = attr.id;
                  const selectedAttrValueId = attr.selectedId.toString();
                  this.addNewVariationAttribute(this.v.length - 1);
                  this.setNewVariationAttributeValues(
                    this.v.length - 1,
                    i,
                    attrId,
                    selectedAttrValueId
                  );
                });
              }
              this.availableQty[index] = single.stockQuantity;

              this.topViewUploadedFile[index] = single.topViewImage;
              this.topViewUploadedFileId[index] = single.topViewImageId;
              this.augmentedViewUploadedFile[index] = single.augmentedViewImage;
              this.augmentedViewUploadedFileId[index] =
                single.augmentedViewImageId;
              this.threeDViewUploadedFile[index] = single.modelJsonViewImage;
              this.threeDViewUploadedFileId[index] =
                single.modelJsonViewImageId;
            });
          }
          this.isLoadingService.remove();
        } else {
          this.alert.danger(res.message);
          this.isLoadingService.remove();
        }
      },
      (err) => {
        if (typeof err.error.Message !== "undefined") {
          this.alert.danger(err.error.Message);
        } else if (typeof err.error !== "undefined") {
          this.alert.danger(err.error.message);
        }
        this.isLoadingService.remove();
      }
    );
  }

  showCreateButtonContainer(): boolean {
    if (this.isAllVariationCreated) {
      return false;
    }
    return true;
  }

  createForm() {
    this.variationForm = this.fb.group({
      productId: [this.productId],
      variations: this.fb.array([]),
    });
  }

  get f() {
    return this.variationForm.controls;
  }
  get v() {
    return this.f.variations as FormArray;
  }

  createVariations(variationData: ProductVariation) {
    this.v.push(
      this.fb.group({
        variationId: [variationData.id],
        variationSku: [variationData.sku, Validators.required],
        variationName: [variationData.name],
        variationFile: [variationData.filename],
        variationQty: [0],
        variationAvailable: [variationData.stockQuantity],
        variationPrice: [variationData.overriddenPrice],
        variationOfferAmount: [variationData.overriddenOffer],
        variationOutOfStock: [variationData.allowOutOfStockOrders],
        variationAtrributeList: this.fb.array([]),
      })
    );

    const hasImage =
      variationData.filename !== null && variationData.filename !== "";
    this.imageSelected.push(hasImage);
  }

  createVariationAttribute() {
    return this.fb.group({
      attributeId: [0],
      attributeValueId: ["", Validators.required],
    });
  }

  addNewVariationAttribute(mainIndex) {
    const control = (this.variationForm.controls["variations"] as FormArray)
      .at(mainIndex)
      .get("variationAtrributeList") as FormArray;

    control.push(this.createVariationAttribute());
  }

  setNewVariationAttributeValues(
    mainIndex,
    subindex,
    arrtId = 0,
    attrValueId = ""
  ) {
    const control = (this.variationForm.controls["variations"] as FormArray)
      .at(mainIndex)
      .get("variationAtrributeList") as FormArray;
    if (typeof control.at(subindex) !== "undefined") {
      control.at(subindex).get("attributeId").setValue(arrtId);
      control.at(subindex).get("attributeValueId").setValue(attrValueId);
    }
  }

  variationQtyChange(value, i, originalQty) {
    const aQty = originalQty > 0 ? originalQty : 0;
    const qty = value > 0 ? value : 0;

    this.availableQty[i] = qty * 1 + aQty * 1;
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };

  createProductVariations(variationType: string = "all") {
    this.isLoadingService.add();
    this.product
      .createProductVariations(this.productId, variationType)
      .subscribe(
        (data) => {
          const res = data as any;
          if (res.status === true) {
            if (variationType === "all") {
              const pVatiation = res.data.map((item) =>
                new ProductVariation().deserialize(item)
              );
              pVatiation.forEach((single, index) => {
                this.createVariations(single);

                single.attributeList.forEach((singleAttr, i) => {
                  this.addNewVariationAttribute(this.v.length - 1);
                  const attrId = singleAttr.id;
                  const attrValue =
                    typeof single.attValueList[i] !== "undefined"
                      ? (single.attValueList[i] as AttributeValueList)
                      : "";
                  let selectedAttrValueId = "";
                  if (attrValue !== "") {
                    selectedAttrValueId = attrValue.attributeValueId.toString();
                  }
                  this.setNewVariationAttributeValues(
                    this.v.length - 1,
                    i,
                    attrId,
                    selectedAttrValueId
                  );
                });
                this.attributeValueLists[this.v.length - 1] =
                  single.attributeList;
              });
            } else {
              const pVatiation = new ProductVariation().deserialize(res.data);
              pVatiation.name = "";
              this.createVariations(pVatiation);
              pVatiation.attributeList.forEach((singleAttr, index) => {
                this.addNewVariationAttribute(this.v.length - 1);
                const attrId = singleAttr.id;
                const selectedAttrValueId = "";
                this.setNewVariationAttributeValues(
                  this.v.length - 1,
                  index,
                  attrId,
                  selectedAttrValueId
                );
              });

              this.attributeValueLists[this.v.length - 1] =
                pVatiation.attributeList;
            }

            this.isLoadingService.remove();
          } else {
            this.alert.danger(res.message);
            this.isLoadingService.remove();
          }
        },
        (err) => {
          if (typeof err.error.Message !== "undefined") {
            this.alert.danger(err.error.Message);
          } else if (typeof err.error !== "undefined") {
            this.alert.danger(err.error.message);
          }
          this.isLoadingService.remove();
        }
      );
  }

  removeVariation(removeIndex: number, varitionId: number = 0) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm",
        message: "DeleteConfirm",
      },
    });

    confirmDialog.afterClosed().subscribe((result) => {
      if (result === true) {
        if (varitionId > 0) {
          this.isLoadingService.add();
          this.product
            .deleteVariation(varitionId)
            .pipe()
            .subscribe(
              (data) => {
                if (data["status"] === true) {
                  this.alert.success(data["message"]);
                  (
                    this.variationForm.controls["variations"] as FormArray
                  ).removeAt(removeIndex);
                  this.loadProductVariations();
                } else {
                  this.alert.danger(data["message"]);
                }
                this.isLoadingService.remove();
              },
              (err) => {
                if (typeof err.error.Message !== "undefined") {
                  this.alert.danger(err.error.Message);
                } else if (typeof err.error !== "undefined") {
                  this.alert.danger(err.error.message);
                }
                this.isLoadingService.remove();
              }
            );
        } else {
          (this.variationForm.controls["variations"] as FormArray).removeAt(
            removeIndex
          );
        }
      }
    });
  }

  variationImageFileProgress(fileInput: any, i) {
    const fileInfo = fileInput.target.files[0] as File;
    const fileSize = Math.round(fileInfo.size / 1024);
    if (fileSize > 2048) {
      this.alert.danger("File exceeds the maximum size: 2mb.");
      fileInput.target.value = null;
    } else {
      this.variationImageFileData[i] = fileInfo;
      this.imageSelected[i] = true;
    }
  }

  topViewImageFileProgress(fileInput: any, i) {
    const fileInfo = fileInput.target.files[0] as File;

    const fileName = fileInfo.name;
    const fileType = fileName.substring(fileName.lastIndexOf(".") + 1);
    this.topViewError[i] = "";
    this.topViewInputData[i] = null;
    if (fileType === "png") {
      this.topViewInputData[i] = fileInfo;
      this.topViewPreviewUrl[i] = fileName;
    } else {
      this.topViewPreviewUrl[i] = "";
      this.topViewError[i] = "Please upload only PNG file.";
    }
  }

  augmentedViewImageFileProgress(fileInput: any, i) {
    const fileInfo = fileInput.target.files[0] as File;

    const fileName = fileInfo.name;
    const fileType = fileName.substring(fileName.lastIndexOf(".") + 1);
    this.augmentedViewError[i] = "";
    this.augmentedViewInputData[i] = null;
    if (fileType === "zip") {
      this.augmentedViewInputData[i] = fileInfo;
      this.augmentedViewPreviewUrl[i] = fileName;
    } else {
      this.augmentedViewPreviewUrl[i] = "";
      this.augmentedViewError[i] = "Please upload only ZIP file.";
    }
  }

  threeDImageFileProgress(fileInput: any, i) {
    const fileInfo = fileInput.target.files[0] as File;

    const fileName = fileInfo.name;
    const fileType = fileName.substring(fileName.lastIndexOf(".") + 1);
    this.threeDViewError[i] = "";
    this.threeDViewInputData[i] = null;
    if (fileType === "json") {
      this.threeDViewInputData[i] = fileInfo;
      this.threeDViewPreviewUrl[i] = fileName;
    } else {
      this.threeDViewPreviewUrl[i] = "";
      this.threeDViewError[i] = "Please upload only 3D JSON file.";
    }
  }

  onSubmit() {
    this.submitted = true;
    this.formSubmitted = true;

    if (this.variationForm.invalid) {
      return;
    }
    const totalVariations =
      this.variationForm["controls"].variations["controls"].length;
    const variationsWithImages = this.imageSelected.filter(
      (selected) => selected
    ).length;

    if (variationsWithImages !== totalVariations) {
      return;
    }

    const formData = new FormData();
    formData.append(
      "ProductId",
      this.variationForm.value.productId !== null
        ? this.variationForm.value.productId
        : this.productId
    );

    if (typeof this.variationForm.value.variations !== "undefined") {
      this.variationForm.value.variations.forEach((variation, index) => {
        formData.append("Id", variation.variationId);
        formData.append("Sku", variation.variationSku);
        formData.append("Name", variation.variationName);
        formData.append("StockQuantity", variation.variationQty);
        formData.append("OverriddenPrice", variation.variationPrice);
        formData.append("OverriddenOffer", variation.variationOfferAmount);
        formData.append("AllowOutOfStockOrders", variation.variationOutOfStock);

        const imageData =
          typeof this.variationImageFileData[index] !== "undefined" &&
          this.variationImageFileData[index] !== null
            ? this.variationImageFileData[index]
            : "";
        if (imageData === "") {
          formData.append("Filename", variation.variationFile);
          formData.append("Image", "");
        } else {
          formData.append("Filename", "");
          formData.append(
            "Image",
            typeof this.variationImageFileData[index] !== "undefined" &&
              this.variationImageFileData[index] !== null
              ? this.variationImageFileData[index]
              : ""
          );
        }

        const topViewimageData =
          typeof this.topViewInputData[index] !== "undefined" &&
          this.topViewInputData[index] !== null
            ? this.topViewInputData[index]
            : "";
        if (topViewimageData === "") {
          formData.append("TopViewFilename", variation.variationFile);
          formData.append("TopViewImage", "");
        } else {
          formData.append("TopViewFilename", "");
          formData.append("TopViewImage", topViewimageData);
        }

        const augumentedimageData =
          typeof this.augmentedViewInputData[index] !== "undefined" &&
          this.augmentedViewInputData[index] !== null
            ? this.augmentedViewInputData[index]
            : "";
        if (augumentedimageData === "") {
          formData.append("AugmentedViewFilename", variation.variationFile);
          formData.append("AugmentedViewImage", "");
        } else {
          formData.append("AugmentedViewFilename", "");
          formData.append("AugmentedViewImage", augumentedimageData);
        }

        const threeDimageData =
          typeof this.threeDViewInputData[index] !== "undefined" &&
          this.threeDViewInputData[index] !== null
            ? this.threeDViewInputData[index]
            : "";
        if (threeDimageData === "") {
          formData.append("ModelJsonViewFilename", variation.variationFile);
          formData.append("ModelJsonViewImage", "");
        } else {
          formData.append("ModelJsonViewFilename", "");
          formData.append("ModelJsonViewImage", threeDimageData);
        }

        const attrIds = [];
        const attrValueIds = [];
        if (typeof variation.variationAtrributeList !== "undefined") {
          variation.variationAtrributeList.forEach((attr, i) => {
            attrIds.push(attr.attributeId);
            attrValueIds.push(attr.attributeValueId);
          });
        }

        formData.append("productAttributeIds", attrIds.join(","));
        formData.append("productAttributeValueIds", attrValueIds.join(","));
      });
    }

    this.isLoadingService.add();
    this.product
      .insertUpdateVariation(formData)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data["status"] === true) {
            this.alert.success(data["message"]);
            this.isLoadingService.remove();
            this.formSubmitted = false;
            this.imageSelected = [];
            this.loadProductVariations();
          } else {
            this.alert.danger(data["message"]);
            this.isLoadingService.remove();
          }
        },
        (err) => {
          if (typeof err.error !== "undefined") {
            const msg =
              typeof err.error.Message !== "undefined"
                ? err.error.Message
                : err.error.message;
            this.alert.danger(msg);
          }
          this.isLoadingService.remove();
          this.loading = false;
        }
      );
  }

  removeUploadedFile(removeIndex: number, varitionId, imageType) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm",
        message: "Are you sure you wish to delete this file?",
      },
    });

    confirmDialog.afterClosed().subscribe((result) => {
      if (result === true) {
        this.isLoadingService.add();
        this.product
          .removeImage(varitionId, imageType)
          .pipe()
          .subscribe(
            (data) => {
              if (data["status"] === true) {
                this.alert.success(data["message"]);

                switch (imageType) {
                  case 4:
                    this.topViewUploadedFile[removeIndex] = "";
                    this.topViewUploadedFileId[removeIndex] = 0;
                    this.topViewPreviewUrl[removeIndex] = "";
                    break;
                  case 5:
                    this.augmentedViewUploadedFile[removeIndex] = "";
                    this.augmentedViewUploadedFileId[removeIndex] = 0;
                    this.augmentedViewPreviewUrl[removeIndex] = "";
                    break;
                  case 6:
                    this.threeDViewUploadedFile[removeIndex] = "";
                    this.threeDViewUploadedFileId[removeIndex] = 0;
                    this.threeDViewPreviewUrl[removeIndex] = "";
                    break;
                }
              } else {
                this.alert.danger(data["message"]);
              }
              this.isLoadingService.remove();
            },
            (err) => {
              if (typeof err.error.Message !== "undefined") {
                this.alert.danger(err.error.Message);
              } else if (
                typeof err.error !== "undefined" &&
                err.error !== null
              ) {
                this.alert.danger(err.error.message);
              }
              this.isLoadingService.remove();
            }
          );
      }
    });
  }
}

interface ProductVariationListResponse {
  status: boolean;
  message: string;
  data: ProductVariationListData;
}

interface ProductVariationListData {
  data: ProductVariation[];
  isAllVariationCreated: boolean;
}
