import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { CategoryModule } from './category/category.module';
import { ProductsRoutingModule } from './products-routing.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'common/modules/material.module';
import { DataTablesModule } from 'angular-datatables';
import { TemplateDefaultModule } from '../../shared/template-default/template-default.module';
import { ListComponent } from './list/list.component';
import { AddProductComponent } from './add-product/add-product.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { EditProductModule } from './edit-product/edit-product.module';
import { InventoryComponent } from './inventory/inventory.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { InventoryViewComponent } from './inventory-view/inventory-view.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { MyDirectiveModule } from 'common/directives/my-directive.module';
import { SpecificationModule } from './specification/specification.module';
import { LastMinuteDealModule } from './last-minute-deal/last-minute-deal.module';



@NgModule({
  declarations: [ListComponent, AddProductComponent, InventoryComponent, InventoryViewComponent],
  imports: [
    CommonModule,
    CategoryModule,
    LastMinuteDealModule,
    SpecificationModule,
    ProductsRoutingModule,
    RouterModule,
    TranslateModule.forRoot(),
    ReactiveFormsModule,
    DataTablesModule,
    TemplateDefaultModule,
    MaterialModule,
    AngularMultiSelectModule,
    EditProductModule,
    ModalModule.forRoot(),
    NgSelectModule,
    MyDirectiveModule,
    PaginationModule.forRoot()
  ]
})
export class ProductsModule { }
