import { Component, OnInit, ViewChild } from "@angular/core";
import { IsLoadingService } from "@service-work/is-loading";
import { TitleService } from "common/services/title.service";
import { constEnv } from "common/constants.env";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService } from "@full-fledged/alerts";
import { AuthenticationService } from "common/services/authentication.service";
import { CartService } from "common/services/site/cartservice";
import { Cart, CartCalculation } from "common/_models/site/cart.modal";
import { EventEmitterService } from "common/services/event-emitter.service";
import { DeliveryPlan } from "common/_models/site/cartItem.modal";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalDirective } from "ngx-bootstrap/modal";
import { CustomerService } from "common/services/customers/customer.service";
// import { escapeRegExp } from "@angular/compiler/src/util";
import { HttpParams } from "@angular/common/http";

@Component({
  selector: "app-cart",
  templateUrl: "./cart.component.html",
  styleUrls: ["./cart.component.css"],
})
export class CartComponent implements OnInit {
  config = constEnv;

  isUserLoggedIn = false;
  cartInfo: Cart = null;

  cartForm: FormGroup;

  selectedPromoCode: string = "";

  pageTitle = "USER_My_Cart";
  pageFor: string;
  productId: number = 0;

  @ViewChild("autoShownModal", { static: false })
  autoShownModal: ModalDirective;
  isModalShown = false;

  @ViewChild("couponModal", { static: false })
  couponModal: ModalDirective;
  isCouponModalShown = false;

  customerAddresses: CustAddress[] = [];
  selectedAddress = 0;

  cartItemQty: UpdateItemQtyData[] = [];

  qtyError: string[] = [];

  couponLists: Coupon[] = null;
  termsAgreed: boolean = false;
  currencyCode: any;
  constructor(
    private isLoadingService: IsLoadingService,
    private titleService: TitleService,
    private route: ActivatedRoute,
    private router: Router,
    private alert: AlertService,
    private cart: CartService,
    private authenticationService: AuthenticationService,
    private fb: FormBuilder,
    private customer: CustomerService
  ) {}

  ngOnInit() {
    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.currencyCode = currentUser.userdetails.currencyCode;

    this.isUserLoggedIn = this.authenticationService.currentUserValue
      ? true
      : false;

    if (this.isUserLoggedIn) {
      const userDetails =
        this.authenticationService.currentUserValue.userdetails;
      if (
        userDetails.userType == "designer" ||
        userDetails.userType == "store"
      ) {
        this.router.navigate([this.config.BASE_URL]);
        return false;
      }
      this.route.queryParams.subscribe((param) => {
        this.selectedAddress =
          typeof param.addId !== "undefined" ? param.addId : 0;
      });

      this.route.data.subscribe((data) => {
        switch (data.for) {
          case "buy-now":
            this.pageTitle = "BuyNow";
            this.route.queryParams.subscribe((params) => {
              this.productId = params["pid"];
            });
            if (this.productId <= 0) {
              this.router.navigate([this.config.BASE_URL]);
            }
            this.getBuyNowData();
            break;

          case "cart":
            this.pageTitle = "USER_My_Cart";
            this.getCart();
            break;

          default:
            this.router.navigate([this.config.BASE_URL]);
            break;
        }
        this.pageFor = data.for;
      });

      this.createCartForm();
    }
    this.titleService.setTitle(this.pageTitle);

    if (localStorage.getItem("_o") !== null) {
      const orderInfo = JSON.parse(
        decodeURIComponent(localStorage.getItem("_o"))
      );
      this.checkPaymentStatus(orderInfo);
    }
  }

  checkPaymentStatus(orderInfo) {
    const formData = new FormData();
    formData.append("CustomerNotes", orderInfo._n);
    // formData.append('promoCodeId', '');
    // formData.append('promoCodeText', this.orderInfo._p);
    formData.append("DeliveryAddressId", orderInfo._da);
    formData.append("order_ref", orderInfo._ref);
    formData.append("OrderBaseDetailId", orderInfo._order);
    formData.append("IsBuyNow", orderInfo._isBuyNow);
    formData.append("ProductId", orderInfo._isBuyNow ? orderInfo._pid : 0);

    this.isLoadingService.add();
    this.cart.checkPaymentStatus(formData).subscribe(
      (data) => {
        if (data["status"] === true) {
          localStorage.removeItem("_o");
        } else {
          // this.alert.danger(res.message);
        }
        this.isLoadingService.remove();
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          // this.alert.danger(err.error.message);
        }
        this.isLoadingService.remove();
      }
    );
  }

  createCartForm() {
    this.cartForm = this.fb.group({
      addressId: [this.selectedAddress],
      promoCode: [""],
      note: ["", [Validators.maxLength(500)]],
      ProductId: [this.productId],
      termsAgreed: [false, Validators.requiredTrue],
    });
  }

  getCart() {
    this.isLoadingService.add();

    this.cart.getCart(this.selectedAddress).subscribe(
      (data) => {
        const res = data as CartResponse;
        if (res.status === true) {
          this.cartInfo = new Cart().deserialize(res.data);

          this.cartInfo.myCart.forEach((item) => {
            const index = this.cartItemQty.findIndex(
              (e) => e.recordId === item.recordId
            );
            if (index === -1) {
              const currentDate = new Date();
              currentDate.setDate(
                currentDate.getDate() + item.deliveryPlan.minDays
              );
              item.expectedDeliveryDate = currentDate;
              this.cartItemQty.push({
                recordId: item.recordId,
                itemQuantity: item.itemQty,
                status: false,
                message: "",
              });
            }
          });

          this.cartForm
            .get("addressId")
            .setValue(this.cartInfo.customerAddress.addressId);

          if (this.cartInfo.cartMessage !== "") {
            this.alert.danger(this.cartInfo.cartMessage);
          }
          this.isLoadingService.remove();
        } else {
          if (res.message !== "") {
            this.alert.danger(res.message);
          }
        }

        this.isLoadingService.remove();
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          this.alert.danger(err.error.message);
        }
        this.isLoadingService.remove();
      }
    );
  }

  getBuyNowData() {
    this.isLoadingService.add();
    this.cart.getCart(this.selectedAddress, true, this.productId).subscribe(
      (data) => {
        const res = data as CartResponse;
        if (res.status === true) {
          this.cartInfo = new Cart().deserialize(res.data);

          this.cartInfo.myCart.forEach((item) => {
            const currentDate = new Date();
            currentDate.setDate(
              currentDate.getDate() + item.deliveryPlan.minDays
            );
            item.expectedDeliveryDate = currentDate;
            this.cartItemQty.push({
              recordId: item.recordId,
              itemQuantity: item.itemQty,
              status: false,
              message: "",
            });
          });

          this.cartForm
            .get("addressId")
            .setValue(this.cartInfo.customerAddress.addressId);

          if (this.cartInfo.cartMessage !== "") {
            this.alert.danger(this.cartInfo.cartMessage);
          }
          this.isLoadingService.remove();
        } else {
          this.alert.danger(res.message);
        }

        this.isLoadingService.remove();
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          this.alert.danger(err.error.message);
        }
        this.isLoadingService.remove();
      }
    );
  }

  getBuyNowData_OLD() {
    this.isLoadingService.add();
    this.cart.getBuyNow(this.productId, this.selectedAddress).subscribe(
      (data) => {
        const res = data as CartResponse;
        if (res.status === true) {
          this.cartInfo = new Cart().deserialize(res.data);

          this.cartInfo.myCart.forEach((item) => {
            const currentDate = new Date();
            currentDate.setDate(
              currentDate.getDate() + item.deliveryPlan.minDays
            );
            item.expectedDeliveryDate = currentDate;
          });

          this.cartForm
            .get("addressId")
            .setValue(this.cartInfo.customerAddress.addressId);
        } else {
          this.alert.danger(res.message);
        }

        this.isLoadingService.remove();
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          this.alert.danger(err.error.message);
        }
        this.isLoadingService.remove();
      }
    );
  }

  removeFromCart(recordId: number) {
    this.isLoadingService.add();
    this.cart.removeFromCart(recordId).subscribe(
      (data) => {
        const res = data as CartResponse;

        if (res.status === true) {
          if (res.data.myCart !== null) {
            this.cartInfo = new Cart().deserialize(res.data);
            // this.eventEmitterService.getCartCount();
            this.cartItemQty.forEach((single, i) => {
              if (single.recordId === recordId) {
                this.cartItemQty.splice(i, 1);
              }
            });
            this.alert.success(res.message);
          } else {
            this.cartInfo = null;
          }
        } else {
          this.cartInfo = null;
          this.alert.danger(res.message);
        }
        this.cart.sendCartCountEvent();
        this.isLoadingService.remove();
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          this.alert.danger(err.error.message);
        }
        this.isLoadingService.remove();
      }
    );
  }

  updateCart() {
    // this.isLoadingService.add();
    const formData = new FormData();
    formData.append("items", JSON.stringify(this.cartItemQty));

    const isBuyNow = this.pageFor === "buy-now" ? true : false;

    this.cart.updateCart(formData, isBuyNow, this.selectedAddress).subscribe(
      (data) => {
        const res = data as UpdateCartResponse;

        if (res.status === true) {
          if (res.data.myCart !== null) {
            this.cartInfo = new Cart().deserialize(res.data);
            this.cartItemQty = res.updateData as UpdateItemQtyData[];
            // this.eventEmitterService.getCartCount();
            this.cart.sendCartCountEvent();
            this.alert.success(res.message);
          } else {
            this.cartInfo = null;
          }
        } else {
          this.cartInfo = null;
          this.alert.danger(res.message);
        }

        this.isLoadingService.remove();
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          this.alert.danger(err.error.message);
        }
        this.isLoadingService.remove();
      }
    );
  }

  changeDate(recordId, $event, index = 0) {
    const pDate = new Date($event.value);
    const day =
      pDate.getDate() >= 1 && pDate.getDate() <= 9
        ? "0" + pDate.getDate()
        : pDate.getDate();
    const month = pDate.getMonth() + 1;
    const selectedMonth = month >= 1 && month <= 9 ? "0" + month : month;
    const selectedDate = pDate.getFullYear() + "-" + selectedMonth + "-" + day;
    this.isLoadingService.add();
    this.cart.updateDeliveryDate(recordId, selectedDate).subscribe(
      (data) => {
        if (data["status"] === true) {
          if (typeof this.cartInfo.myCart[index] !== "undefined") {
            this.cartInfo.myCart[index].deliveryPlan = data[
              "data"
            ] as DeliveryPlan;
          }
          this.getCart();
        } else {
          this.alert.danger(data["message"]);
        }

        this.isLoadingService.remove();
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          this.alert.danger(err.error.message);
        }
        this.isLoadingService.remove();
      }
    );
  }

  applyPromoCode(couponCode: string = null) {
    const promoCode =
      couponCode !== null ? couponCode : this.cartForm.value.promoCode;

    if (promoCode !== "") {
      this.isLoadingService.add();

      const isBuyNow = this.pageFor === "buy-now" ? true : false;

      this.cart
        .applyPromoCode(
          promoCode,
          isBuyNow,
          this.productId,
          this.selectedAddress
        )
        .subscribe(
          (data) => {
            if (data["status"] === true) {
              this.alert.success(data["message"]);
              this.cartInfo.myCartCalculation = data["data"] as CartCalculation;
              this.selectedPromoCode = promoCode;
              this.hideCouponModal();
            } else {
              this.alert.danger(data["message"]);
              this.cartForm.get("promoCode").setValue("");
              this.selectedPromoCode = "";
            }
            this.isLoadingService.remove();
          },
          (err) => {
            const msg =
              typeof err.error.Message !== "undefined"
                ? err.error.Message
                : err.error.message;
            if (msg !== "") {
              this.alert.danger(err.error.message);
            }
            this.cartForm.get("promoCode").setValue("");
            this.selectedPromoCode = "";
            this.isLoadingService.remove();
          }
        );
    } else {
      this.cartForm.get("promoCode").setValue("");
      this.selectedPromoCode = "";
    }
  }

  listPromoCode() {
    this.isLoadingService.add();
    this.cart.getCustomerCoupons().subscribe(
      (data) => {
        if (data["status"] === true) {
          this.couponLists = data["data"] as Coupon[];
          this.couponLists.forEach((item, i) => {
            // this.couponLists[i].discountAmount = item.discountAmount.replace(" ", "");
            this.couponLists[i].discountPercentage =
              item.discountPercentage.replace(" ", "");
            this.couponLists[i].isPercentageApplied =
              item.discountPercentage == "0%" ? true : false;
          });
          this.isCouponModalShown = true;
        } else {
          this.alert.danger(data["message"]);
        }
        this.isLoadingService.remove();
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          this.alert.danger(err.error.message);
        }
        this.isLoadingService.remove();
      }
    );
  }

  removePromoCode() {
    this.isLoadingService.add();
    const isBuyNow = this.pageFor === "buy-now" ? true : false;
    this.cart
      .removePromoCode(isBuyNow, this.productId, this.selectedAddress)
      .subscribe(
        (data) => {
          if (data["status"] === true) {
            this.alert.success(data["message"]);
            this.cartInfo.myCartCalculation = data["data"] as CartCalculation;
            this.cartForm.get("promoCode").setValue("");
            this.selectedPromoCode = "";
          } else {
            this.alert.danger(data["message"]);
            this.cartForm.get("promoCode").setValue("");
            this.selectedPromoCode = "";
          }
          this.isLoadingService.remove();
        },
        (err) => {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          if (msg !== "") {
            this.alert.danger(err.error.message);
          }
          this.cartForm.get("promoCode").setValue("");
          this.selectedPromoCode = "";
          this.isLoadingService.remove();
        }
      );
  }

  cartFormSubmit() {
    if (this.cartForm.get("note").invalid) {
      return;
    }

    const authUrl = `${location.origin}/payment/success`;
    const declineUrl = `${location.origin}/payment/decline`;
    const cancelUrl = `${location.origin}/payment/cancel`;

    const isBuyNow = this.pageFor === "cart" ? false : true;

    const params = new HttpParams()
      .set("CustomerNotes", this.cartForm.value.note)
      .set("DeliveryAddressId", this.cartForm.value.addressId)
      .set("ProductId", this.productId.toString())
      .set("IsBuyNow", isBuyNow.toString());

    // const formData = new FormData();
    // formData.append('CustomerNotes', this.cartForm.value.note);
    // // formData.append('promoCodeText', this.cartForm.value.promoCode);
    // formData.append('DeliveryAddressId', this.cartForm.value.addressId);
    // formData.append('ProductId', this.productId.toString());
    // formData.append('IsBuyNow', isBuyNow.toString());
    // formData.append('return_auth', authUrl);
    // formData.append('return_decl', declineUrl);
    // formData.append('return_can', cancelUrl);

    if (this.cartForm.get("note").invalid) {
      return;
    }
    this.isLoadingService.add();

    this.cart.checkOrderQty(params).subscribe(
      (data) => {
        const res = data as CheckOrderQtyResponse;
        if (res.status === true) {
          const OrderBaseId = data["data"];

          const params1 = new HttpParams()
            .set("CustomerNotes", this.cartForm.value.note)
            .set("DeliveryAddressId", this.cartForm.value.addressId)
            .set("ProductId", this.productId.toString())
            .set("OrderBaseDetailId", OrderBaseId)
            .set("order_ref", "")
            .set("IsBuyNow", isBuyNow.toString());

          //formData.append('OrderBaseDetailId', OrderBaseId);

          this.cart.makePayment(params1).subscribe(
            (data) => {
              const res = data as PaymentResponse;
              if (res.status === true) {
                // const msg =
                //   res.data?.error?.message !== null
                //     ? res.data?.error?.message
                //     : res.data?.error?.note;
                // if (msg !== null) {
                //   this.alert.danger(msg);
                //   this.isLoadingService.remove();
                // } else {
                // if (res.data.order.url) {
                if (res.data.url) {
                  const od = {
                    // _ref: res.data.order.ref,
                    _ref: res.data.id,
                    _n: this.cartForm.value.note,
                    _order: OrderBaseId,
                    _da: this.cartForm.value.addressId,
                    _isBuyNow: isBuyNow,
                    _pid: this.productId,
                  };
                  localStorage.setItem(
                    "_o",
                    encodeURIComponent(JSON.stringify(od))
                  );
                  // this.router.navigate([res.data.order.url]);
                  window.location.href = res.data.url;
                } else {
                  this.alert.danger("Something went wrong.");
                  this.isLoadingService.remove();
                }
                // }
              } else {
                this.alert.danger(res.message);
                this.isLoadingService.remove();
              }
            },
            (err) => {
              const msg =
                typeof err.error.Message !== "undefined"
                  ? err.error.Message
                  : err.error.message;
              if (msg !== "") {
                this.alert.danger(err.error.message);
              }
              this.isLoadingService.remove();
            }
          );
        } else {
          console.log(res.data);
          res.data.forEach((err) => {
            this.qtyError[err["key"]] = err["value"];
          });
          console.log(this.qtyError);
        }
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          this.alert.danger(err.error.message);
        }
        if (typeof err.error.data !== "undefined") {
          err.error.data.forEach((err) => {
            this.qtyError[err["key"]] = err["value"];
          });
        }
        this.isLoadingService.remove();
      }
    );
    return false;
  }

  showModal(): void {
    this.customer.getCustomerAddressese().subscribe(
      (data) => {
        if (data["status"] === true) {
          this.customerAddresses = data["data"] as CustAddress[];
          this.customerAddresses.sort((a, b) =>
            a.isDefault === b.isDefault ? 0 : a.isDefault ? -1 : 1
          );
          console.log(this.customerAddresses);
          this.isModalShown = true;
        } else {
          this.alert.danger(data["message"]);
        }
        this.isLoadingService.remove();
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          this.alert.danger(err.error.message);
        }
        this.isLoadingService.remove();
      }
    );
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }

  hideCouponModal(): void {
    this.couponModal.hide();
  }

  onCouponHidden(): void {
    this.isCouponModalShown = false;
  }

  changeAddress(addressId: number) {
    if (addressId > 0) {
      this.router.navigate([], {
        queryParams: {
          addId: addressId,
        },
        queryParamsHandling: "merge",
      });
      this.hideModal();
      this.selectedAddress = addressId;
      if (this.pageFor === "cart") {
        this.getCart();
      } else {
        this.getBuyNowData();
      }
    }
  }
}

interface CartResponse {
  status_code: number;
  status: boolean;
  message: string;
  data: Cart;
}

interface UpdateCartResponse {
  status_code: number;
  status: boolean;
  message: string;
  data: Cart;
  updateData: UpdateItemQtyData[];
}

interface UpdateItemQtyData {
  itemQuantity: number;
  message: string;
  recordId: number;
  status: boolean;
}

interface PaymentResponse {
  status_code: number;
  status: boolean;
  message: string;
  data: PaymentOrder;
}

interface PaymentOrder {
  method: string;
  order: PaymentOrderObject;
  error: PaymentError;
  url: string;
  id: string;
}

interface PaymentOrderObject {
  ref: string;
  url: string;
}

interface PaymentError {
  details: string;
  message: string;
  note: string;
}

interface CustAddress {
  addressId: number;
  addressLine: string;
  cityName: string;
  countryName: string;
  isDefault: boolean;
  latitude: number;
  longitude: number;
  location: string;
  stateName: string;
  type: string;
  zipCode: string;
}

interface CheckOrderQtyResponse {
  status: boolean;
  status_code: number;
  message: string;
  data: any[];
}

interface Coupon {
  categoryId: string;
  couponCode: string;
  couponId: number;
  couponText: string;
  discountAmount: string;
  discountPercentage: string;
  discountType: number;
  endDate: string;
  maxDiscountQuantity: number;
  minOrderAmount: number;
  startDate: string;
  storeName: string;
  targetAmount: number;
  isPercentageApplied: boolean;
}
