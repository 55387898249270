import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';

import { constEnv } from 'common/constants.env';
import { TitleService } from 'common/services/title.service';
import { AlertService } from 'ngx-alerts';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'common/services/language.service';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { DataTableDirective } from 'angular-datatables';
import { IsLoadingService } from '@service-work/is-loading';
import { AttributeArray, EditAttributeArray, ProductAttributeValueList, ProductService } from 'common/services/store/product.service';
import { ProductAttribute } from 'common/_models/store/productAttribute.model';
import { Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, first, switchMap, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from 'common/_components/confirm-dialog/confirm-dialog.component';


@Component({
  selector: 'app-attributes',
  templateUrl: './attributes.component.html',
  styleUrls: ['./attributes.component.css']
})
export class AttributesComponent implements OnInit {

  config = constEnv;
  productEditUrl: string;
  productListUrl: string;

  loading = false;
  submitted = false;

  productId = 0;

  attributes: ProductAttribute[] = [];

  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;

  @ViewChild('autoShownModal', { static: false }) autoShownModal: ModalDirective;
  isModalShown = false;

  @ViewChild('editAttributeModal', { static: false }) editAttributeModal: ModalDirective;
  isEditAttributeModalShown = false;

  @ViewChild('imageFileInput', { static: true }) primaryFileInput: ElementRef;
  imageFileData: File = null;
  imageFileName = '';
  defaultImageFileName = 'Choose File';
  imagePreviewUrl: any = null;

  editAttributeImageData: File[] = [];
  editAttrImageNames = [];

  modalTitle = '';

  attributesLists: AttributeArray[];
  attributeListValues: AttributeArray[];

  attributesLists$: Observable<AttributeArray[]>;
  attributesLoading = false;
  attributesInput$ = new Subject<string>();
  selectedAttributes: AttributeArray[] = [];

  selectedSingleAttribute: AttributeArray;
  attrColor = '#ffffff';

  editAttributeValueLists: EditAttributeArray = null;

  attributeForm: FormGroup;
  editAttributeForm: FormGroup;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private alert: AlertService,
    private titleService: TitleService,
    private translate: TranslateService,
    private ls: LanguageService,
    private product: ProductService,
    private isLoadingService: IsLoadingService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Product Attributes');

    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }

    this.productListUrl = `${this.config.STORE_URL}/product/lists`;

    const id = this.route.snapshot.params.id;
    this.productId = (typeof id !== 'undefined') ? id : 0;
    if (this.productId <= 0) {
      this.router.navigate([this.productListUrl]);
    }

    this.productEditUrl = `${this.config.STORE_URL}/product/edit/${this.productId}`;

    this.selectedSingleAttribute = {
      id: 0,
      value: '',
      swatcheType: '',
      swatcheTypeId: 0,
    } as AttributeArray;

    this.loadAttributes();
    this.createAttributeForm();
  }

  createAttributeForm() {
    this.attributeForm = this.fb.group({
      attributeId: [null, Validators.required],
      attributeValues: [null, Validators.required],
      attributeColor: [null],
      attributeImage: [null],
      swatcheTypeId: [0],
    });

    this.editAttributeForm = this.fb.group({
      attributeId: [null],
      swatcheTypeId: [null],
      attributeValueLists: this.fb.array([]),
    });
  }

  get f() { return this.editAttributeForm.controls; }
  get a() { return this.f.attributeValueLists as FormArray; }

  attributeEditFromValue( attributeValue: ProductAttributeValueList ) {
    this.a.push(this.fb.group({
      id: [attributeValue.id],
      productAttributeValue: [attributeValue.productAttributeValue],
      swatcheTypeValue: [attributeValue.swatcheTypeValue],
      attributrColor: [null],
      attributeImage: [null],
    }));
  }

  loadAttributes() {
    this.isLoadingService.add();
    this.product.getProductAttributes( this.productId )
      .subscribe(
        data => {
          const response = data as Response;

          if (response.status === true) {
            this.attributes = response.data as ProductAttribute[];
            this.isLoadingService.remove();
          } else {
            this.alert.danger( response.message );
            this.router.navigate([this.productListUrl]);
          }
        }
      );
  }

  showModal( action: string, i: number = 0 ): void {
    let attrId = 0;
    if (action === 'edit') {
      this.modalTitle = 'Edit Attribute';
      attrId = this.attributes[i].productAttributeId;
      this.getEditAttributesValues(attrId);
    } else {
      this.loadAutocompleteAttributes();
      this.modalTitle = 'Add Attribute';
      // this.product.getAttributeList( this.productId, attrId )
      //   .subscribe(
      //     data => {
      //       this.attributesLists = data;
      //       this.isModalShown = true;
      //     },
      //     err => {
      //       if (typeof err.error !== 'undefined') {
      //         const msg = (typeof err.error.Message !== 'undefined') ? err.error.Message : err.error.message;
      //         this.alert.danger(msg);
      //       }
      //     }
      //   );

      this.isModalShown = true;
    }
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  edithideModal(): void {
    this.editAttributeModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
    this.isEditAttributeModalShown = false;
    this.attributeForm.reset();
    this.attributesLists = [];
    this.attributeListValues = [];
    this.selectedSingleAttribute = {
      id: 0,
      value: '',
      swatcheType: '',
      swatcheTypeId: 0,
    } as AttributeArray;

    this.attributesLoading = false;
    this.attributesInput$ = new Subject<string>();
    this.selectedAttributes = [];
    this.attributeForm.get('attributeColor').clearValidators();
    this.attributeForm.get('attributeImage').clearValidators();
    this.imageFileData = null;
    this.imageFileName = this.defaultImageFileName;
    this.editAttributeValueLists = null;

    this.editAttributeForm.reset();
    this.clearFormArray( this.a );
    this.editAttributeImageData = [];
    this.editAttrImageNames = [];
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  deleteAttribute( attributeId: number ) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm',
        message: 'DeleteConfirm',
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.isLoadingService.add();
        this.product.deleteAttribute(attributeId).pipe()
          .subscribe(
            data => {
              if (data['status'] === true) {
                this.alert.success(data['message']);                
                this.loadAttributes();                
              } else {
                this.alert.danger(data['message']);
              }
              this.isLoadingService.remove();
            },
            err => {
              if (typeof err.error.Message !== 'undefined') {
                this.alert.danger(err.error.Message);
              } else if (typeof err.error !== 'undefined') {
                this.alert.danger(err.error.message);
              }
              this.isLoadingService.remove();
            }
          );
      }
    });
  }

  trackByFnAttributes(item: ProductAttribute) {
    return item.id;
  }

  getAttributeValues($event: AttributeArray) {
    this.attributeListValues = [];
    this.attributeForm.get('attributeValues').patchValue([]);
    this.attributeForm.get('swatcheTypeId').setValue(0);
    this.attributeForm.get('attributeColor').setValue('');
    this.attributeForm.get('attributeColor').clearValidators();
    this.attributeForm.get('attributeImage').setValue('');
    this.attributeForm.get('attributeImage').clearValidators();
    this.attrColor = '#ffffff';
    this.imageFileData = null;
    this.imageFileName = this.defaultImageFileName;
    this.selectedSingleAttribute = {
      id: 0,
      value: '',
      swatcheType: '',
      swatcheTypeId: 0,
    } as AttributeArray;

    if (typeof $event !== 'undefined') {
      this.selectedSingleAttribute = $event;
      if ($event.swatcheTypeId === 3) {
        this.attributeForm.get('attributeColor').setValidators([Validators.required]);
      }

      if ($event.swatcheTypeId === 2) {
        this.attributeForm.get('attributeImage').setValidators([Validators.required]);
      }

      this.attributeForm.get('swatcheTypeId').setValue($event.swatcheTypeId);

      this.product.getAttributeList(this.productId, $event.id)
        .subscribe(
          data => {
            this.attributeListValues = data;
          }
        );
    }
  }

  imageFileProgress(fileInput: any) {
    this.imageFileData = fileInput.target.files[0] as File;

    const mimeType = this.imageFileData.type;
    this.imageFileName = (typeof this.imageFileData !== 'undefined') ? this.imageFileData.name : this.defaultImageFileName;
    // const reader = new FileReader();
    // reader.readAsDataURL(this.imageFileData);
    // reader.onload = (_event) => {
    //   this.imagePreviewUrl = reader.result;
    //   this.editProductForm.get('primaryImage').setValue({
    //     filename: this.primaryFileData.name,
    //     filetype: this.primaryFileData.type,
    //     value: this.primaryPreviewUrl.split(',')[1]
    //   });
    // };
  }

  loadAutocompleteAttributes() {
    this.attributesLists$ = this.attributesInput$.pipe(
      distinctUntilChanged(),
      tap(() => (this.attributesLoading = true)),
      switchMap(term =>
        this.product.getAttributeList(this.productId, 0, term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => (this.attributesLoading = false))
        )
      )
    );
  }

  getEditAttributesValues( attrId ) {
    this.editAttributeForm.reset();
    this.clearFormArray( this.a );
    this.editAttributeImageData = [];
    this.editAttrImageNames = [];

    this.product.getEditAttributesAndValues( this.productId, attrId )
    .subscribe(
      data => {
        const res = data as EditAttributeResponse;

        if (res.status === true) {
          this.editAttributeValueLists = res.data;

          this.editAttributeForm.get('attributeId').setValue( this.editAttributeValueLists.productAttributeId );
          this.editAttributeForm.get('swatcheTypeId').setValue( this.editAttributeValueLists.swatcheTypeId );

          this.editAttributeValueLists.productAttValueList.forEach(single => {
            this.attributeEditFromValue( single );
          });

          this.isEditAttributeModalShown = true;
        } else {
          this.alert.danger(res.message);
        }
      },
      err => {
        if (typeof err.error !== 'undefined') {
          const msg = (typeof err.error.Message !== 'undefined') ? err.error.Message : err.error.message;
          this.alert.danger(msg);
        }
      }
    );
  }

  colorPickerChange( $event ) {
    this.attributeForm.get('attributeColor').setValue($event);
    this.attrColor = $event;
  }

  editColorPickerChange( $event, i ) {
    ((this.editAttributeForm.get('attributeValueLists') as FormArray).at(i) as FormGroup).get('attributrColor').setValue($event);
  }

  editImageFileProgress(fileInput: any, i) {
    const fileInfo = fileInput.target.files[0] as File;
    this.editAttributeImageData[i] = fileInfo;

    this.editAttrImageNames[i] = (typeof fileInfo !== 'undefined') ? fileInfo.name : this.defaultImageFileName;
  }

  onSubmit() {
    this.submitted = true;

    if (this.attributeForm.invalid) {
      return;
    }

    const formData = new FormData();
    formData.append('ProductAttributeId', this.attributeForm.value.attributeId);
    formData.append('ProductAttributeValueIds', this.attributeForm.value.attributeValues);
    formData.append('ProductId', this.productId.toString());
    formData.append('SwatcheTypeId', this.attributeForm.value.swatcheTypeId);
    formData.append('SwatcheTypeValue', this.attributeForm.value.attributeColor);
    formData.append('Image', this.imageFileData);

    this.isLoadingService.add();
    this.product.addProductAttribute( formData )
      .pipe(first())
      .subscribe(
        data => {
          if (data['status'] === true) {
            this.alert.success(data['message']);
            this.isLoadingService.remove();
            this.hideModal();
            this.loadAttributes();
          } else {
            this.alert.danger(data['message']);
            this.isLoadingService.remove();
          }
        },
        err => {
          if (typeof err.error !== 'undefined') {
            const msg = (typeof err.error.Message !== 'undefined') ? err.error.Message : err.error.message;
            this.alert.danger(msg);
          }
          this.isLoadingService.remove();
          this.loading = false;
        });

  }

  deleteAttributeValue( deleteId: number, attributeId: number ) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm',
        message: 'DeleteConfirm',
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.isLoadingService.add();
        this.product.deleteAttributeValue(deleteId).pipe()
          .subscribe(
            data => {
              if (data['status'] === true) {
                this.alert.success(data['message']);
                this.getEditAttributesValues(attributeId);
                this.loadAttributes();
                this.edithideModal();
              } else {
                this.alert.danger(data['message']);
              }
              this.isLoadingService.remove();
            },
            err => {
              if (typeof err.error.Message !== 'undefined') {
                this.alert.danger(err.error.Message);
              } else if (typeof err.error !== 'undefined') {
                this.alert.danger(err.error.message);
              }
              this.isLoadingService.remove();
            }
          );
      }
    });
  }

  onEditAttributeSubmit() {
    if (this.editAttributeForm.invalid) {
      return;
    }

    const formData = new FormData();
    formData.append('ProductAttributeId', this.editAttributeForm.value.attributeId);
    formData.append('ProductId', this.productId.toString());
    formData.append('SwatcheTypeId', this.editAttributeForm.value.swatcheTypeId);

    this.editAttributeForm.value.attributeValueLists.forEach( (singleEle, index) => {
      formData.append('Id', singleEle.id);

      const imageData = (typeof this.editAttributeImageData[index] !== 'undefined') ? this.editAttributeImageData[index] : '';

      if (this.editAttributeForm.value.swatcheTypeId === 3 ) {
        formData.append('SwatcheTypeValue', (singleEle.attributrColor !== null) ? singleEle.attributrColor : singleEle.swatcheTypeValue);
      } else if (this.editAttributeForm.value.swatcheTypeId === 2 ) {
        if (imageData === '') {
          formData.append('SwatcheTypeValue', singleEle.swatcheTypeValue);
          formData.append('Image', '');
        } else {
          formData.append('SwatcheTypeValue', '');
          formData.append('Image', (typeof this.editAttributeImageData[index] !== 'undefined') ? this.editAttributeImageData[index] : '');
        }
      }
    });

    this.isLoadingService.add();
    this.product.editProductAttribute( formData )
      .pipe(first())
      .subscribe(
        data => {
          if (data['status'] === true) {
            this.alert.success(data['message']);
            this.isLoadingService.remove();
            this.edithideModal();
            this.loadAttributes();
          } else {
            this.alert.danger(data['message']);
            this.isLoadingService.remove();
          }
        },
        err => {
          if (typeof err.error !== 'undefined') {
            const msg = (typeof err.error.Message !== 'undefined') ? err.error.Message : err.error.message;
            this.alert.danger(msg);
          }
          this.isLoadingService.remove();
          this.loading = false;
        });
  }

}

export interface Response {
  status: boolean;
  status_code: number;
  message: string;
  data: ProductAttribute[];
}

export interface EditAttributeResponse {
  status: boolean;
  status_code: number;
  message: string;
  data: EditAttributeArray;
}
