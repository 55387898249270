import { Component, Input, OnInit } from '@angular/core';
import { constEnv } from 'common/constants.env';

import { Payment } from 'common/_models/site/payment.modal';

@Component({
  selector: 'app-decline',
  templateUrl: './decline.component.html',
  styleUrls: ['./decline.component.css']
})
export class DeclineComponent implements OnInit {

  @Input() paymentInfo: Payment;
  config = constEnv;

  constructor() { }

  ngOnInit() {
  }

}
