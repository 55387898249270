import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from 'common/_helpers/auth.guard';
import { ListComponent } from './list/list.component';
import { ActionComponent } from './action/action.component';
import { constEnv } from 'common/constants.env';

const routeBase = `${constEnv.storeEndPoint}/product`;

const routes: Routes = [
   {
      path: `${routeBase}/sub-category`,
      children: [
         {
            path: ':action',
            children: [
               {path: ':id', component: ActionComponent},
               {path: '', component: ActionComponent}
            ]
         },
         {path: '', component: ListComponent}
      ],
      // canActivate: [AuthGuard]
   },
   // {path: `${routeStore}/category`, component: ListComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class CategoryRoutingModule { }
