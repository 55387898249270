export class DashboardProduct {
    productId: number;
    productName: string;
    stockQuantity: number;
    productCount: number;
    
    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
