import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { constEnv } from "common/constants.env";
// import { AlertService } from 'common/services/alert.service';
import { AlertService } from "@full-fledged/alerts";
import { StoreUserService } from "common/services/store/storeUser.service";
import { TitleService } from "common/services/title.service";
import { DOCUMENT } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { LoaderService } from "common/services/loader.service";
import { CustomerService } from "common/services/customers/customer.service";

@Component({
  selector: "app-email-verify",
  templateUrl: "./email-verify.component.html",
  styleUrls: ["./email-verify.component.css"],
})
export class EmailVerifyComponent implements OnInit, OnDestroy {
  accessToken: string;
  isSuccess = false;
  isError = false;
  errorMessage = "";

  constantConfig = constEnv;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private alert: AlertService,
    private userService: CustomerService,
    private titleService: TitleService,
    private route: ActivatedRoute,
    private ls: LoaderService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("VerifyEmail");
    this.document.body.classList.add("verify-email-body");

    this.accessToken = this.route.snapshot.params.token;
    this.verifyEmail();
  }

  ngOnDestroy(): void {
    this.document.body.classList.remove("verify-email-body");
  }

  verifyEmail() {
    this.ls.showLoader();
    // this.alert.clear();
    this.isSuccess = false;
    this.isError = false;
    this.userService.verifyEmail(this.accessToken).subscribe(
      (response) => {
        if (response["status"] === true) {
          this.isSuccess = true;
        } else {
          this.isError = true;
          this.errorMessage = response["message"];
        }
        // console.log(response);
        this.ls.hideLoader();
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          // this.alert.error(msg);
          this.isError = true;
          this.errorMessage = msg;
        }
        this.ls.hideLoader();
      }
    );
  }
}
