import { Component, OnInit, ViewChild } from '@angular/core';
import { IsLoadingService } from '@service-work/is-loading';

import { constEnv } from 'common/constants.env';

import { TitleService } from 'common/services/title.service';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-aerial-view',
  templateUrl: './aerial-view.component.html',
  styleUrls: ['./aerial-view.component.css']
})
export class AerialViewComponent implements OnInit {
  config = constEnv;

  @ViewChild('autoShownModal', { static: false }) autoShownModal: ModalDirective;
  isModalShown = false;

  driverLists = Array(5).fill(5);
  reachedPickUp = Array(3).fill(3);
  journyStarted = Array(4).fill(4);
  available = Array(1).fill(1);
  all = Array(10).fill(10);

  constructor(
    private titleService: TitleService,
    private isLoadingService: IsLoadingService,
  ) { }

  ngOnInit() {
    this.titleService.setTitle('GodsView');

    if ( this.isLoadingService.isLoading$() ) {
      this.isLoadingService.remove();
    }
  }

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }

}
