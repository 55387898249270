import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { IsLoadingService } from "@service-work/is-loading";
import { constEnv } from "common/constants.env";
import { CustomerService } from "common/services/customers/customer.service";
import { TitleService } from "common/services/title.service";
import { ProductList } from "common/_models/site/product-list.modal";
import { AlertService } from "@full-fledged/alerts";

@Component({
  selector: "app-job-detail",
  templateUrl: "./job-detail.component.html",
  styleUrls: ["./job-detail.component.css"],
})
export class JobDetailComponent implements OnInit {
  config = constEnv;
  jobId: number = 0;

  categoryItemLists: any[] = null;

  constructor(
    private titleService: TitleService,
    private cs: CustomerService,
    private alert: AlertService,
    private ls: IsLoadingService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.titleService.setTitle("ViewJob");
    if (this.ls.isLoading$()) {
      this.ls.remove();
    }

    this.jobId =
      this.route.snapshot.params.id !== "undefined"
        ? this.route.snapshot.params.id
        : 0;

    if (this.jobId <= 0) {
      this.router.navigate([this.config.CUSTOMER_URL, "job"]);
    }
    this.getJobProducts();
  }

  getJobProducts() {
    this.ls.add();
    this.cs.getJobItems(this.jobId).subscribe(
      (data) => {
        if (data["status"] === true) {
          let _data = data["data"] as ResponseData;
          this.categoryItemLists = _data.serviceCategories as ServiceCategory[];
        } else {
          if (Object.keys(data["data"]).length == 0) {
            this.router.navigate(["/customer/job"]);
          }
          this.alert.danger(data["message"]);
        }
        this.ls.remove();
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        if (msg !== "") {
          this.alert.danger(err.error.message);
        }
        this.ls.remove();
      }
    );
  }
}

interface ResponseData {
  arvrFPId: string;
  arvrImgUrl: string;
  floorPlanImage: string;
  spaceArea: number;
  serviceCategories: ServiceCategory[];
}

interface ServiceCategory {
  id: number;
  name: string;
  serviceTypeImage: string;
  product: ProductList[];
}
