import { DatePipe } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { IsLoadingService } from "@service-work/is-loading";

import { constEnv } from "common/constants.env";
import { VehicleService } from "common/services/store/vehicle.service";

import { TitleService } from "common/services/title.service";
import { AlertService } from "@full-fledged/alerts";
import {
  BsModalRef,
  BsModalService,
  ModalDirective,
} from "ngx-bootstrap/modal";
import { Subscription, combineLatest } from "rxjs";
import {
  RequiredDocResponse,
  UploadedDocResponse,
} from "../../settings/documents/documents.component";

@Component({
  selector: "app-document",
  templateUrl: "./document.component.html",
  styleUrls: ["./document.component.css"],
})
export class DocumentComponent implements OnInit {
  config = constEnv;

  vehicleId: number;

  @ViewChild("autoShownModal", { static: false })
  autoShownModal: ModalDirective;
  isModalShown = false;

  docForm: FormGroup;
  loading = false;
  submitted = false;

  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  fileUplaodName: string = null;
  dateFormat = "dd-MMM-yyyy";

  documentLists = [];

  modalRef: BsModalRef;
  subscriptions: Subscription[] = [];

  selectedDocMgmtId = 0;
  selExpDateReq = true;

  listUrl = `${this.config.STORE_URL}/vehicle`;

  constructor(
    private titleService: TitleService,
    private isLoadingService: IsLoadingService,
    private route: ActivatedRoute,
    private router: Router,
    private alert: AlertService,
    private modalService: BsModalService,
    private changeDetection: ChangeDetectorRef,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private vehicle: VehicleService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("VehicleDocuments");

    const id = this.route.snapshot.params.id;
    this.vehicleId = typeof id !== "undefined" ? id : 0;
    if (this.vehicleId <= 0) {
      this.router.navigate([this.listUrl]);
    }

    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }

    this.createDocumentForm();
    this.getRequiredDoument();
  }

  createDocumentForm() {
    this.docForm = this.fb.group({
      fileUpload: ["", Validators.required],
      expiryDate: [""],
      docMgmtId: [""],
      docId: [0],
      driverId: [0],
    });
  }

  get f() {
    return this.docForm.controls;
  }

  showModal(template: TemplateRef<any>, docMgmtId, docId, isexpDateReq): void {
    const _combine = combineLatest(
      this.modalService.onShow,
      this.modalService.onShown,
      this.modalService.onHide,
      this.modalService.onHidden
    ).subscribe(() => this.changeDetection.markForCheck());

    this.subscriptions.push(
      this.modalService.onShow.subscribe((reason: string) => {
        this.selectedDocMgmtId = docMgmtId;
        this.docForm.get("docMgmtId").setValue(docMgmtId);
        this.docForm.get("docId").setValue(docId);
        this.selExpDateReq = isexpDateReq;
        if (isexpDateReq === true) {
          this.docForm.get("expiryDate").setValidators([Validators.required]);
        }
      })
    );

    this.subscriptions.push(
      this.modalService.onHidden.subscribe((reason: string) => {
        this.selectedDocMgmtId = 0;
        this.submitted = false;
        this.docForm.get("fileUpload").setValue("");
        this.docForm.get("docMgmtId").setValue("");
        this.docForm.get("docId").setValue(0);
        this.docForm.get("expiryDate").setValue("");
        this.docForm.get("expiryDate").setValidators([]);
        this.selExpDateReq = true;
        this.fileUplaodName = null;
        this.previewUrl = "";
        this.unsubscribe();
      })
    );

    this.subscriptions.push(_combine);

    this.modalRef = this.modalService.show(template, { class: "modal-sm" });
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
    this.previewUrl = null;
    this.fileUplaodName = null;
    // this.selectedDoc = null;
    this.docForm.get("expiryDate").setValidators(null);
    this.docForm.reset();
  }

  getRequiredDoument() {
    this.isLoadingService.add();
    this.documentLists = [];
    this.vehicle.getRequiredDocuments().subscribe(
      (data) => {
        const res = data as RequiredDocResponse;
        if (res.status === true) {
          if (res.data !== null) {
            res.data.forEach((doc) => {
              if (doc.docMgmtId > 0) {
                this.vehicle
                  .getUploadedDouments(doc.docMgmtId, this.vehicleId)
                  .subscribe(
                    (response) => {
                      const resp = response as UploadedDocResponse;
                      if (resp.status === true) {
                        const resData =
                          typeof resp.data !== "undefined" ? resp.data : null;
                        this.documentLists.push({
                          DocMgmtId: doc.docMgmtId,
                          DocumentName: doc.documentName,
                          IsExpiryDateRequired: doc.isExpiryDateRequired,
                          docId:
                            typeof resData.id !== "undefined" ? resData.id : 0,
                          DocExpiryDate:
                            typeof resData.docExpiryDate !== "undefined"
                              ? resData.docExpiryDate
                              : null,
                          DocUrl:
                            typeof resData.docUrl !== "undefined"
                              ? resData.docUrl
                              : null,
                          IsVerify:
                            typeof resData.isVerify !== "undefined"
                              ? resData.isVerify
                              : 0,
                          isDocUrl:
                            typeof resData.docUrl !== "undefined"
                              ? true
                              : false,
                          btnLabel: "UpdateDocument",
                        });
                      } else {
                        this.documentLists.push({
                          DocMgmtId: doc.docMgmtId,
                          DocumentName: doc.documentName,
                          IsExpiryDateRequired: doc.isExpiryDateRequired,
                          docId: 0,
                          DocExpiryDate: null,
                          DocUrl: null,
                          isDocUrl: false,
                          IsVerify: true,
                          btnLabel: "Upload",
                        });
                      }

                      this.isLoadingService.remove();
                    },
                    (err) => {
                      this.documentLists.push({
                        DocMgmtId: doc.docMgmtId,
                        DocumentName: doc.documentName,
                        IsExpiryDateRequired: doc.isExpiryDateRequired,
                        docId: 0,
                        DocExpiryDate: null,
                        DocUrl: null,
                        isDocUrl: false,
                        IsVerify: true,
                        btnLabel: "Upload",
                      });
                      this.isLoadingService.remove();
                    }
                  );
              }
            });
          }
        } else {
          this.alert.danger(res.message);
        }
        this.isLoadingService.remove();
      },
      (err) => {
        if (typeof err.error.Message !== "undefined") {
          this.alert.danger(err.error.Message);
        } else if (typeof err.error !== "undefined") {
          this.alert.danger(err.error.message);
        }
        this.isLoadingService.remove();
      }
    );
  }

  fileProgress(fileInput: any) {
    this.fileData = fileInput.target.files[0] as File;
    this.preview();
  }

  preview() {
    // Show preview
    const mimeType = this.fileData.type;
    const reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
      this.fileUplaodName = this.fileData.name;
    };
  }

  onSubmit() {
    this.submitted = true;

    if (this.docForm.invalid) {
      return;
    }

    // const docId = (this.uploadForm.value.docId > 0) ? this.uploadForm.value.docId : 0;
    // const action = (docId > 0) ? 'edit' : 'add';

    const expiredDate = this.datePipe.transform(
      this.docForm.value.expiryDate,
      this.dateFormat
    );

    const formData = new FormData();
    formData.append("VehicleId", this.vehicleId.toString());
    formData.append("DocumentTypeId", this.docForm.value.docMgmtId);
    formData.append("DocumentExpiryDate", expiredDate);
    formData.append("Document", this.fileData);

    this.isLoadingService.add();
    this.vehicle.uploadDocument(formData).subscribe(
      (data) => {
        if (data["status"] === true) {
          this.alert.success(data["message"]);
          // this.ls.hideLoader();
          this.modalRef.hide();
          this.getRequiredDoument();
        } else {
          this.alert.danger(data["message"]);
          this.isLoadingService.remove();
        }
      },
      (err) => {
        if (typeof err.error.Message !== "undefined") {
          this.alert.danger(err.error.Message);
        } else if (typeof err.error !== "undefined") {
          this.alert.danger(err.error.message);
        }
        this.isLoadingService.remove();
      }
    );
  }
}
