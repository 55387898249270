import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AlertService } from "ngx-alerts";
import { LoaderService } from "common/services/loader.service";
import { SettingsService } from "common/services/store/settings.service";
import { CustomValidator } from "common/_helpers/custom.validator";
import { TitleService } from "common/services/title.service";
import { StoreUserService } from "common/services/store/storeUser.service";
import { OrderSettings } from "common/_models/store/orderSettings";

@Component({
  selector: "app-order",
  templateUrl: "./order.component.html",
  styleUrls: ["./order.component.css"],
})
export class OrderComponent implements OnInit {
  offerApplyon = [
    { key: "None", vlaue: "None" },
    { key: "First", vlaue: "FirstOrder" },
    { key: "All", vlaue: "AllOrdes" },
  ];
  offerTypes = [
    { key: "Flat", vlaue: "FlatOffer" },
    { key: "Per", vlaue: "PercentageOffer" },
  ];

  offerApply = "None";
  offerType = "Flat";

  orderSettingForm: FormGroup;

  submitted = false;
  loading = false;
  estimatedTimeUnits = [];

  storeTypeId = 0;
  storeCurrency = "";

  constructor(
    private fb: FormBuilder,
    private alert: AlertService,
    private ls: LoaderService,
    private settings: SettingsService,
    private titleService: TitleService,
    private us: StoreUserService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Order Setting");
    this.makeForm();

    this.storeTypeId = this.us.getStoreServiceTypeId();
    this.storeCurrency = this.us.getStoreCurrency();

    this.getOrderSettings();
  }

  private makeForm() {
    this.orderSettingForm = this.fb.group(
      {
        // MinOrderPrice: ['', Validators.required],
        // AdditionalCharges: ['0'],
        // MaxItemInCart: ['', Validators.required],
        // EstimateTime: ['', Validators.required],
        // EstimateTimeUnit: [null, Validators.required],
        // CostPerPerson: ['', Validators.required],
        OfferApplyOn: ["", Validators.required],
        OfferType: [""],
        OfferDiscount: [""],
        TargetAmount: [""],
        MaxAmount: ["0"],
        TakeAway: [""],
        DeliverToVehicleFees: [""],
      },
      { validators: CustomValidator.CheckTargetAmount }
    );

    if (this.storeTypeId !== 1) {
      this.orderSettingForm.get("CostPerPerson").setValidators(null);
    }
  }

  get f() {
    return this.orderSettingForm.controls;
  }

  getOrderSettings() {
    this.ls.showLoader();
    this.settings.getOrderSettings().subscribe(
      (data) => {
        const resp = data as OrderSettingResponse;
        if (resp.status === true) {
          this.setFormValue(resp.data);
          this.ls.hideLoader();
        } else {
          this.alert.danger(resp.message);
          this.ls.hideLoader();
        }
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        this.alert.danger(msg);
        this.ls.hideLoader();
      }
    );
  }

  setFormValue(orderData: OrderSettings) {
    // this.orderSettingForm.get('MinOrderPrice').setValue( orderData.minOrderPrice );
    // this.orderSettingForm.get('MaxItemInCart').setValue( orderData.maxItemsInCart );
    // this.orderSettingForm.get('AdditionalCharges').setValue( orderData.additionalCharges );
    // this.orderSettingForm.get('EstimateTime').setValue( orderData.estimateTime );

    if (orderData.offerApplyOn !== "" && orderData.offerApplyOn !== null) {
      this.orderSettingForm
        .get("OfferApplyOn")
        .setValue(orderData.offerApplyOn);
      this.offerApplyOnChange(orderData.offerApplyOn);
    }
    if (orderData.offerType !== "" && orderData.offerType !== null) {
      this.orderSettingForm.get("OfferType").setValue(orderData.offerType);
      this.onChangeOfferType(orderData.offerType);
    }
    this.orderSettingForm
      .get("OfferDiscount")
      .setValue(orderData.offerDiscount);
    this.orderSettingForm.get("TargetAmount").setValue(orderData.targetAmount);
    this.orderSettingForm.get("MaxAmount").setValue(orderData.maxAmount);
    this.orderSettingForm.get("TakeAway").setValue(orderData.takeAway);
    this.orderSettingForm
      .get("DeliverToVehicleFees")
      .setValue(orderData.deliverToVehicleFees);

    this.estimatedTimeUnits =
      typeof orderData.unitList !== "undefined" ? orderData.unitList : [];
    // this.orderSettingForm.get('EstimateTimeUnit').setValue( (typeof orderData.unitId !== 'undefined') ? orderData.unitId.toString() : null );
  }

  offerApplyOnChange(offerApplyValue: string) {
    this.offerApply = offerApplyValue;
    if (
      this.offerApply !== "None" &&
      this.offerApply !== "" &&
      this.offerApply !== null
    ) {
      this.orderSettingForm
        .get("OfferType")
        .setValidators([Validators.required]);
      this.orderSettingForm.get("OfferType").updateValueAndValidity();
      this.orderSettingForm
        .get("OfferDiscount")
        .setValidators([Validators.required]);
      this.orderSettingForm.get("OfferDiscount").updateValueAndValidity();
      this.orderSettingForm
        .get("TargetAmount")
        .setValidators([Validators.required]);
      this.orderSettingForm.get("TargetAmount").updateValueAndValidity();
    } else {
      this.orderSettingForm.get("OfferType").clearValidators();
      this.orderSettingForm.get("OfferType").updateValueAndValidity();
      this.orderSettingForm.get("OfferDiscount").clearValidators();
      this.orderSettingForm.get("OfferDiscount").updateValueAndValidity();
      this.orderSettingForm.get("TargetAmount").clearValidators();
      this.orderSettingForm.get("TargetAmount").updateValueAndValidity();
    }
  }

  onChangeOfferType(offerTypeValue: string) {
    this.offerType = offerTypeValue;
  }

  getTargetAmountError() {
    return this.orderSettingForm.get("TargetAmount").hasError("required")
      ? ""
      : this.orderSettingForm.get("TargetAmount").hasError("targetAmountLess")
      ? this.titleService.getTranslatedString("TargetAmountGreaterValidation")
      : "";
  }

  onSubmit() {
    this.submitted = true;

    if (this.orderSettingForm.invalid) {
      return;
    }

    this.ls.showLoader();
    this.loading = true;
    this.orderSettingForm.value.TakeAway = this.orderSettingForm.value.TakeAway
      ? "true"
      : "false";
    this.orderSettingForm.value.UnitId =
      this.orderSettingForm.value.EstimateTimeUnit;

    const deliverToVehicleFees =
      this.orderSettingForm.value.DeliverToVehicleFees;
    const _deliverToVehicleFees: number = +deliverToVehicleFees;

    const formData = {
      additionalCharges: this.orderSettingForm.value.AdditionalCharges,
      deliverToVehicleFees: _deliverToVehicleFees,
      estimateTime: this.orderSettingForm.value.EstimateTime,
      // estimateTimeUnit: this.orderSettingForm.value.EstimateTimeUnit,
      maxAmount: this.orderSettingForm.value.MaxAmount,
      maxItemsInCart: this.orderSettingForm.value.MaxItemInCart,
      minOrderPrice: this.orderSettingForm.value.MinOrderPrice,
      offerApplyOn: this.orderSettingForm.value.OfferApplyOn,
      offerDiscount: this.orderSettingForm.value.OfferDiscount,
      offerType: this.orderSettingForm.value.OfferType,
      takeAway: this.orderSettingForm.value.TakeAway,
      targetAmount: this.orderSettingForm.value.TargetAmount,
      unitId: +this.orderSettingForm.value.UnitId,
    };

    this.settings.updateOrderSettings(formData).subscribe(
      (data) => {
        if (data["status"] === true) {
          this.alert.success(data["message"]);
          this.ls.hideLoader();
        } else {
          this.alert.danger(data["message"]);
          this.ls.hideLoader();
        }
        this.loading = false;
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        this.alert.danger(msg);
        this.loading = false;
        this.ls.hideLoader();
      }
    );
  }
}

export interface OrderSettingResponse {
  status: boolean;
  message: string;
  data: OrderSettings;
}
