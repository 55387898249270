import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AuthPagesRoutingModule } from "./auth-pages-routing.module";
import { LoginComponent } from "./login/login.component";
import { AlertModule } from "@full-fledged/alerts";
import { ComponentModule } from "common/_components/components.module";
import { RegisterComponent } from "./register/register.component";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { Ng2TelInputModule } from "ng2-tel-input";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "common/modules/material.module";

import { EmailVerifyComponent } from "./email-verify/email-verify.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";

@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    EmailVerifyComponent,
    ResetPasswordComponent,
  ],
  imports: [
    CommonModule,
    AuthPagesRoutingModule,
    AlertModule.forRoot({
      maxMessages: 1,
      timeout: 2500,
      positionX: "right",
      positionY: "top",
    }),
    ComponentModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2TelInputModule,
    NgxIntlTelInputModule,
    MaterialModule,
    TranslateModule.forRoot(),
  ],
})
export class AuthPagesModule {}
