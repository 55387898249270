import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { TranslateModule } from "@ngx-translate/core";
import { LoginTemplateComponent } from "common/_components/login-template/login-template.component";
import { MenuListItemComponent } from "common/_components/menu-list-item/menu-list-item.component";
import { ConfirmDialogComponent } from "common/_components/confirm-dialog/confirm-dialog.component";
import { MaterialModule } from "common/modules/material.module";
import { NavService } from "common/services/nav.service";
import { MapComponent } from "./maps/map.component";
import { MapControlComponent } from "./maps/map-control/map-control.component";
import { SocialLoginComponent } from "./social-login/social-login.component";
import { ModalModule } from "ngx-bootstrap/modal";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Ng2TelInputModule } from "ng2-tel-input";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { constEnv } from "common/constants.env";

@NgModule({
  declarations: [
    LoginTemplateComponent,
    MenuListItemComponent,
    ConfirmDialogComponent,
    MapComponent,
    MapControlComponent,
    SocialLoginComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    Ng2TelInputModule,
    NgxIntlTelInputModule,
    TranslateModule.forRoot(),
    MaterialModule,
    ModalModule.forRoot(),
    AngularFireModule.initializeApp(constEnv.firebaseConfig),
    AngularFireAuthModule,
  ],
  exports: [
    LoginTemplateComponent,
    MenuListItemComponent,
    MapComponent,
    MapControlComponent,
    SocialLoginComponent,
  ],
  entryComponents: [ConfirmDialogComponent],
  providers: [NavService],
})
export class ComponentModule {}
