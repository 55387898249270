import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { constEnv } from 'common/constants.env';
import { AuthGuard } from 'common/_helpers/auth.guard';

import { ListComponent } from './list/list.component';
import { ActionComponent } from './action/action.component';
import { DocumentComponent } from './document/document.component';

const ORDER_ENDPOINT = `${constEnv.storeEndPoint}/vehicle`;

const routes: Routes = [
  {
    path: `${ORDER_ENDPOINT}`,
    children: [      
      { 
         path: 'document',
         children: [
            { path: ':id', component: DocumentComponent },
            { path: '', redirectTo: `/${ORDER_ENDPOINT}`, pathMatch: 'full' }
         ]
      },
       {
          path: ':action',
          children: [
             { path: ':id', component: ActionComponent, data: {for: 'edit'} },
             { path: '', component: ActionComponent, data: {for: 'add'} }
          ]
       },
       { path: '', component: ListComponent }
    ],
    canActivate: [AuthGuard]
 },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StoreVehicleRoutingModule { }
