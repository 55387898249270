import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { constEnv } from 'common/constants.env';
import { map } from 'rxjs/operators';

import { AuthenticationService } from 'common/services/authentication.service';

@Injectable({ providedIn: 'root' })
export class VehicleService {
    currentUserProfile;
    configConstant = constEnv;
    constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
        this.currentUserProfile = this.authenticationService.currentUserProfileValue;
    }

    getAll( dataTablesParameters ) {

        // let searchValue = ''; let searchBy = '';
        // if ( dataTablesParameters.search.value !== '' ) {
        //     searchValue = dataTablesParameters.search.value;
        //     searchBy = dataTablesParameters.search.column;
        // }
        const searchParams = new HttpParams()
            // .set('searchColumn', searchBy)
            // .set('searchValue', searchValue)
            .set('PageNo', dataTablesParameters.start)
            .set('PageSize', dataTablesParameters.length);
            // .set('sortColumn', '')
            // .set('sortOrder', '');

        return this.http.get(`${this.configConstant.apiStoreURL}/vehiclelist`, {
            params: searchParams
        })
            .pipe(map(data => {
                return data;
            }));
    }

    get( driverId: number ) {
        return this.http.get(`${this.configConstant.apiStoreURL}/getdriverbyid/${driverId}`)
            .pipe(map(data => {
                return data;
            }));
    }

    add( formData ) {
        return this.http.post(`${this.configConstant.apiStoreURL}/adddriver`, formData)
            .pipe(map(data => {
                return data;
            }));
    }

    update( formData ) {
        return this.http.post(`${this.configConstant.apiStoreURL}/updatedriver`, formData)
            .pipe(map(data => {
                return data;
            }));
    }

    action( action, formData ) {
        if (action === 'add') {
            return this.add(formData);
        } else {
            return this.update(formData);
        }
    }

    delete(id: number) {
        return this.http.get(`${this.configConstant.apiStoreURL}/deletedriver/${id}`, {});
    }

    updateDocument( driverId: number, formData ) {
        return this.http.post(`${this.configConstant.apiDriverURL}/document/update?DriverId=${driverId}`, formData)
        .pipe(map(data => {
            return data;
        }));
    }


    // Vehicle Data
    getVehicleType() {
        return this.http.get(`${this.configConstant.apiDriverURL}/getvehicletypedata`, {
            // params: searchParams
        })
            .pipe(map(data => {
                return data;
            }));
    }

    getVehicleMake() {
        return this.http.get(`${this.configConstant.apiDriverURL}/getvehiclemake`, {
            // params: searchParams
        })
            .pipe(map(data => {
                return data;
            }));
    }

    getVehicleModel( makeId ) {
        return this.http.get(`${this.configConstant.apiDriverURL}/getvehiclemodelbyid/${makeId}`, {
            // params: searchParams
        })
            .pipe(map(data => {
                return data;
            }));
    }

    manageVehicle(formData) {
        return this.http.post(`${this.configConstant.apiStoreURL}/managevehicle`, formData)
            .pipe(map(data => {
                return data;
            }));
    }

    getVehiclebyId( vehicleId: number ) {
        return this.http.get(`${this.configConstant.apiStoreURL}/vehiclebyId/${vehicleId}`)
            .pipe(map(data => {
                return data;
            }));
    }

    vehicleChangeStatus( vehicleId: number, status: number ) {
        return this.http.get(`${this.configConstant.apiStoreURL}/vehiclestatus/${vehicleId}/${status}`)
            .pipe(map(data => {
                return data;
            }));
    }
    deleteVehicle( vehicleId: number ) {
        return this.http.get(`${this.configConstant.apiStoreURL}/vehicledelete/${vehicleId}`)
        .pipe(map(data => {
            return data;
        }));
    }

    retractVehicle( vehicleId: number ) {
        return this.http.get(`${this.configConstant.apiStoreURL}/retractvehicle/${vehicleId}`)
        .pipe(map(data => {
            return data;
        }));
    }

    getDriverListForVehicle() {
        return this.http.get(`${this.configConstant.apiStoreURL}/driverlistforvehicle`)
            .pipe(map(data => {
                return data;
            }));
    }

    assignDriver(vehicleId, driverId) {
        return this.http.get(`${this.configConstant.apiStoreURL}/assignvehicle/${driverId}/${vehicleId}`)
            .pipe(map(data => {
                return data;
            }));
    }

    getRequiredDocuments() {
        return this.http.get(`${this.configConstant.apiDriverURL}/getvehiclerequireddocuments`)
            .pipe(map(data => {
                return data;
            }));
    }

    getUploadedDouments( docMgmtId, vehicleId ) {
        return this.http.get(`${this.configConstant.apiDriverURL}/vehicledocument/${docMgmtId}/view`, {
            params: {
                VehicleId: vehicleId
            }
        })
        .pipe(map(data => {
            return data;
        }));
    }

    uploadDocument( formData ) {
        return this.http.post(`${this.configConstant.apiDriverURL}/vehicledocument/update`, formData)
        .pipe(map(data => {
            return data;
        }));
    }

}
