import { Component, OnInit } from '@angular/core';
import { constEnv } from 'common/constants.env';

@Component({
  selector: 'app-inner-nav',
  templateUrl: './inner-nav.component.html',
  styleUrls: ['./inner-nav.component.css']
})
export class InnerNavComponent implements OnInit {
  config = constEnv;
  profileUrl = `${this.config.CUSTOMER_URL}/profile`;
  changePasswordUrl = `${this.config.CUSTOMER_URL}/change-password`;
  navItems: InnerNav[] = [
    { displayName: 'Profile', path: `${this.profileUrl}` },
    { displayName: 'STORE_Change_Password', path: `${this.changePasswordUrl}` },
  ];
  constructor() { }

  ngOnInit() {
  }

}
interface InnerNav {
  displayName: string;
  path: string;
}
