import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { constEnv } from 'common/constants.env';
import { map } from 'rxjs/operators';

import { AuthenticationService } from 'common/services/authentication.service';
import { throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService {
    currentUserProfile;
    currentUserValue;

    apiURL: string;

    configConstant = constEnv;

    constructor(
        private http: HttpClient,
        private authenticationService: AuthenticationService,
    ) {
        this.currentUserProfile = this.authenticationService.currentUserProfileValue;
        this.currentUserValue = this.authenticationService.currentUserValue;

        this.apiURL = this.configConstant.apiDriverURL;
    }

    getProfile() {
        return this.http.get(`${this.apiURL}/profile`)
            .pipe(map(profile => {
                let data = [];
                if (profile['status']) {
                    data = profile['data'];

                    localStorage.setItem('currentUserProfile', JSON.stringify(data));
                }
                return data;
            }));
    }

    getDashboard() {
        return this.http.get(`${this.apiURL}/dashboard`)
            .pipe(map(data => {
                let dashboardData = {};
                if (data['status']) {
                    dashboardData = data['data'];
                }
                return dashboardData;
            }));
    }

    register(user) {
        const requestHeader = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };

        const requestData = new HttpParams()
            .set('email', user.email)
            .set('password', user.password)
            .set('phone_code', user.countryCode)
            .set('phone', user.mobileNo)
            .set('d_fname', user.firstName)
            .set('d_lname', user.lastName);

        return this.http.post(`${this.apiURL}/profile`, requestData, {
            headers: requestHeader
        })
            .pipe(map(data => {
                return data;
            }));
    }

    forgotPassword(email) {
        const requestHeader = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };

        const requestData = new HttpParams()
            .set('email', email);

        return this.http.post(`${this.apiURL}/forgotpassword`, requestData, {
            headers: requestHeader,
            params: requestData
        })
            .pipe(map(data => {
                return data;
            }));
    }

    resetPassword(accessToken, newPassword) {
        
        const requestData = {
            accessToken: accessToken,
            newPassword: newPassword
        }
            
        return this.http.post(`${this.apiURL}/resetpassword`, requestData)
            .pipe(map(data => {
                return data;
            }));
    }

    changeProfilePhoto(formModel) {
        return this.http.post(`${this.apiURL}/editprofileimage`, formModel)
            .pipe(map(data => {
                if (data['status'] === true) {
                    const responseData = data['data'];
                    const imageURL = (typeof responseData.image_url !== 'undefined') ? responseData.image_url : '';
                    if (imageURL !== '') {
                        if (typeof this.currentUserProfile !== 'undefined') {
                            this.currentUserProfile.profile_image = imageURL;
                        }
                        localStorage.setItem('currentUserProfile', JSON.stringify(this.currentUserProfile));
                    }
                }
                return data;
            }));
    }

    delete(id: number) {
        return this.http.delete(`${this.apiURL}/users/${id}`);
    }

    getHeaderCounters() {
        return this.http.get(`${this.apiURL}/GetCounts`)
            .pipe(map(data => {
                return data;
            }));
    }

    checkSocialLogin(socialId, socialType, socialEmail) {
        const formData = {
            SocialType: socialType,
            SocialId: socialId,
            Email: socialEmail,
        };

        return this.http.post(`${this.apiURL}/checklogin`, formData)
            .pipe(map(data => {
                if (data['status'] === true) {
                    localStorage.setItem('currentUser', JSON.stringify(data['data']));
                    this.authenticationService.currentUserSubject.next(data['data']);
                }

                return data;
            }));
    }

    socialLogin(formData) {
        return this.http.post(`${this.apiURL}/externallogin`, formData)
            .pipe(map(data => {
                localStorage.setItem('currentUser', JSON.stringify(data['data']));
                this.authenticationService.currentUserSubject.next(data['data']);
                return data;
            }));
    }

    verifyEmail(token: string) {
        return this.http.get(`${this.apiURL}/verify/${token}`)
            .pipe(map(data => {
                return data;
            }));
    }

    getCodDetails() {
        return this.http.get(`${this.apiURL}/CODDetails`)
            .pipe(map(data => {
                return data;
            }));
    }

    getTransactionHistoryLists() {
        return this.http.get(`${this.apiURL}/GetCODTransferList`)
            .pipe(map(data => {
                return data;
            }));
    }

    createOfflinePayment( formData ) {
        const params = new HttpParams()
            .set('transferredDate', formData.transferredDate)
            .set('transferredAmount', formData.transferredAmount)
            .set('transactionDetails', formData.transactionDetails)
            .set('comments', formData.comments)
            .set('attachment', formData.attachment)
            .set('paymentMode', formData.paymentMode);

        const requestHeader = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };

        return this.http.post(`${this.apiURL}/InsertTransferDetails`, params, { headers: requestHeader })
            .pipe(map(data => {
                return data;
            }));
    }

    getCodPayments( dataTablesParameters ) {
        const pageNo = (dataTablesParameters.start * 1) / (dataTablesParameters.length * 1);

        const searchParams = new HttpParams()
            .set('searchColumn', '')
            .set('searchValue', '')
            .set('pageNo', pageNo.toString())
            .set('pageSize', dataTablesParameters.length)
            .set('sortColumn', '')
            .set('sortOrder', '');

        return this.http.get(`${this.apiURL}/PaidCODOrderList`, {
            params: searchParams
        })
            .pipe(map(data => {
                return data;
            }));
    }

    getCodPaymentDetail(PayId) {
        return this.http.get(`${this.apiURL}/GetOrderDetailsByPayId/${PayId}`)
            .pipe(map(data => {
                return data;
            }));
    }

    get isDeliveryCompany() {
        const isDeliveryCompany = (typeof this.currentUserValue.IsDeliveryCompanyDriver !== 'undefined' && this.currentUserValue.IsDeliveryCompanyDriver === 'true') ? true : false;
        return isDeliveryCompany;
    }
}
