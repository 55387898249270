import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { constEnv } from 'common/constants.env';
import { CustomerService } from 'common/services/customers/customer.service';
import { LoaderService } from 'common/services/loader.service';
import { AlertService } from 'ngx-alerts';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[] = [];
  productInfo = [];
  productId = 0;
  config = constEnv;
  listUrl: string;
  fetchImages = [];
  product_attribute = '';
  
  constructor(
    private ls: LoaderService,
    private customer: CustomerService,
    private alert: AlertService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    this.productId = (this.route.snapshot.params.id > 0) ? this.route.snapshot.params.id : 0;
    
    this.listUrl = `${this.config.CUSTOMER_URL}/wishlist`;

    if (this.productId <= 0) {
      this.router.navigate([this.listUrl]);
      return false;
    }
    this.getProductDetail();
    this.galleryOptions = [
      {
          width: '100%',
          height: '400px',
          thumbnailsColumns: 4,
          imageAnimation: NgxGalleryAnimation.Slide
      },
      // max-width 800
      {
          breakpoint: 800,
          width: '100%',
          height: '600px',
          imagePercent: 80,
          thumbnailsPercent: 20,
          thumbnailsMargin: 20,
          thumbnailMargin: 20
      },
      // max-width 400
      {
          breakpoint: 400,
          preview: false
      }
  ];

  }
  getProductDetail() {
    this.ls.showLoader();
    this.customer.getProductDetail(this.productId)
      .pipe(first())
      .subscribe(
        data => {
         if (data['status'] === true) {
            this.productInfo = data['data'];
            // console.log(this.productInfo);
            this.fetchImages = this.productInfo['imageUrl'];  
            this.product_attribute = this.productInfo['attribute'];
            // console.log(this.product_attribute);
            var images = [];
            for(var i = 0; i < this.fetchImages.length; i++){
              this.galleryImages.push({
                     small: this.fetchImages[i],
                     medium: this.fetchImages[i],
                     big: this.fetchImages[i]
               });
            }
            this.ls.hideLoader();
          } else {
            this.alert.danger(data['message']);
            this.ls.hideLoader();
          }
        },
        err => {
          const msg = (typeof err.error.Message !== 'undefined') ? err.error.Message : err.error.message;
          if (msg !== '') {
            this.alert.danger(err.error.message);
          }
          this.ls.hideLoader();
        });
  }

}

