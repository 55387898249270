export class Payment {
  method: string;
  trace: string;
  order: PaymentOrder;
  orderAmt: string;
  orderNo: string; // order id db id
  orderNumber: string; // order no
  paymentIntentId: string;
  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

interface PaymentOrder {
  amount: string;
  card: PaymentOrderCard;
  cartid: string;
  orderID: number;
  currency: string;
  customer: PaymentOrderCustomer;
  description: string;
  paymethod: string;
  ref: string;
  status: PaymentOrderStatus;
  test: number;
  transaction: PaymentOrderTransaction;
  url: string;
}

interface PaymentOrderCard {
  country: string;
  expiry: PaymentOrderCardExpiry;
  first6: string;
  last4: string;
  type: string;
}

interface PaymentOrderCardExpiry {
  month: number;
  year: number;
}

interface PaymentOrderCustomer {
  address: PaymentOrderCustomerAddress;
  email: string;
  name: PaymentOrderCustomerName;
}

interface PaymentOrderCustomerAddress {
  city: string;
  country: string;
  line1: string;
  mobile: string;
}

interface PaymentOrderCustomerName {
  forenames: string;
  surname: string;
}

interface PaymentOrderStatus {
  code: number;
  text: string;
}

interface PaymentOrderTransaction {
  class: string;
  code: string;
  date: string;
  message: string;
  ref: string;
  status: string;
  type: string;
}
