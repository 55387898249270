import { Component, OnInit, ViewChild } from "@angular/core";

import { constEnv } from "common/constants.env";
import { TitleService } from "common/services/title.service";
import { AlertService } from "ngx-alerts";
import { ActivatedRoute, Router } from "@angular/router";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { IsLoadingService } from "@service-work/is-loading";
import { CustomValidator } from "common/_helpers/custom.validator";
import { GeneralService } from "common/services/general.service";

@Component({
  selector: "app-action",
  templateUrl: "./action.component.html",
  styleUrls: ["./action.component.css"],
})
export class ActionComponent implements OnInit {
  config = constEnv;

  action: string;
  id: number;
  listUrl: string;
  pageTitle: string;

  actionForm: FormGroup;
  loading = false;
  submitted = false;
  deliveryZoneLists: DeliveryZone[] = null;

  isCustomValidity = false;
  isDiscountPer = false;

  defaultLamguage: any;
  allLanguages: any[] = [];
  availableLangs = [];

  currentDate = new Date();
  expiredDate = new Date();

  showUsageLimit = true;
  makeCustomerReadOnly = false;

  constructor(
    private titleService: TitleService,
    private alert: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private ls: IsLoadingService,
    private gs: GeneralService
  ) {}

  ngOnInit() {
    this.action = this.route.snapshot.params.action;
    this.listUrl = `${this.config.STORE_URL}/shipping-rate`;

    this.checkValidAction();
  }

  private checkValidAction() {
    if (this.action !== "add" && this.action !== "edit") {
      this.router.navigate([this.listUrl]);
    }

    this.makeForm();
    if (this.action === "edit") {
      this.id =
        typeof this.route.snapshot.params.id !== "undefined" &&
        this.route.snapshot.params.id > 0
          ? this.route.snapshot.params.id
          : 0;
      if (this.id <= 0) {
        this.router.navigate([this.listUrl]);
      }

      this.pageTitle = "EditShippingRate";

      this.getSingleShippingRate();
    } else {
      this.pageTitle = "AddShippingRate";
    }

    this.titleService.setTitle(this.pageTitle);
    this.getDeliveryZoneLists();
  }

  getDeliveryZoneLists() {
    this.gs.getDeliveryZoneForStore().subscribe(
      (data) => {
        if (data["status"]) {
          this.deliveryZoneLists = data["data"] as DeliveryZone[];
          this.ls.remove();
        } else {
          // this.alert.danger(data['message']);
          this.deliveryZoneLists = null;
          this.ls.remove();
        }
      },
      (err) => {
        if (typeof err.error !== "undefined") {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          // this.alert.danger(msg);
        }
        this.deliveryZoneLists = null;
        this.ls.remove();
      }
    );
  }

  private makeForm() {
    this.actionForm = this.fb.group(
      {
        id: [0],
        zoneId: [null, Validators.required],
        minWeight: ["", Validators.required],
        maxWeight: ["", Validators.required],
        perWeightPrice: ["", Validators.required],
        minLength: ["", Validators.required],
        maxLength: ["", Validators.required],
        minWidth: ["", Validators.required],
        maxWidth: ["", Validators.required],
        minHeight: ["", Validators.required],
        maxHeight: ["", Validators.required],
        freeDeliveryCharges: ["", Validators.required],
      },
      {
        validators: [
          CustomValidator.OnlyZeroNotAllowed("minWeight"),
          CustomValidator.OnlyZeroNotAllowed("maxWeight"),
          CustomValidator.checkMaxValueFromMinValue("minWeight", "maxWeight"),
          CustomValidator.OnlyZeroNotAllowed("perWeightPrice"),
          CustomValidator.OnlyZeroNotAllowed("minLength"),
          CustomValidator.OnlyZeroNotAllowed("maxLength"),
          CustomValidator.checkMaxValueFromMinValue("minLength", "maxLength"),
          CustomValidator.OnlyZeroNotAllowed("minWidth"),
          CustomValidator.OnlyZeroNotAllowed("maxWidth"),
          CustomValidator.checkMaxValueFromMinValue("minWidth", "maxWidth"),
          CustomValidator.OnlyZeroNotAllowed("minHeight"),
          CustomValidator.OnlyZeroNotAllowed("maxHeight"),
          CustomValidator.checkMaxValueFromMinValue("minHeight", "maxHeight"),
          CustomValidator.OnlyZeroNotAllowed("freeDeliveryCharges"),
        ],
      }
    );
  }

  get f() {
    return this.actionForm.controls;
  }

  getSingleShippingRate() {
    this.ls.add();
    this.gs.getShippingRateById(this.id).subscribe(
      (data) => {
        if (data["status"]) {
          const formData = data["data"] as ShippingRate;
          this.setFormData(formData);

          this.ls.remove();
        } else {
          this.alert.danger(data["message"]);
          this.ls.remove();
        }
      },
      (err) => {
        if (typeof err.error !== "undefined") {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          this.alert.danger(msg);
        }
        this.ls.remove();
      }
    );
  }

  setFormData(formData: ShippingRate) {
    const fieldData = {
      id: formData.id,
      zoneId: formData.zoneId,
      minWeight: formData.minWeight,
      maxWeight: formData.maxWeight,
      perWeightPrice: formData.perWeightPrice,
      minLength: formData.minLength,
      maxLength: formData.maxLength,
      minWidth: formData.minWidth,
      maxWidth: formData.maxWidth,
      minHeight: formData.minHeight,
      maxHeight: formData.maxHeight,
      freeDeliveryCharges: formData.freeDeliveryCharges,
    };
    this.actionForm.patchValue(fieldData);

    Object.keys(this.actionForm.controls).forEach((key) => {
      const ctrl = this.actionForm.get(key);
      ctrl.markAsTouched({ onlySelf: true });
    });
  }

  submit() {
    this.submitted = true;

    if (this.actionForm.invalid) {
      return;
    }

    this.loading = true;
    this.ls.add();

    this.gs.addEditShippingRate(this.actionForm.value).subscribe(
      (data) => {
        if (data["status"] === true) {
          this.alert.success(data["message"]);
          this.ls.remove();
          this.router.navigate([`${this.listUrl}`]);
        } else {
          this.alert.danger(data["message"]);
          this.ls.remove();
        }
      },
      (err) => {
        if (typeof err.error !== "undefined") {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          this.alert.danger(msg);
        }
        this.ls.remove();
        this.loading = false;
      }
    );
  }
}

interface DeliveryZone {
  id: number;
  name: string;
}

interface ShippingRate {
  id: number;
  status: boolean;
  zoneId: number;
  minWeight: number;
  maxWeight: number;
  perWeightPrice: number;
  minLength: number;
  maxLength: number;
  minWidth: number;
  maxWidth: number;
  minHeight: number;
  maxHeight: number;
  roleIdentifierId: number;
  roleIdentifier: number;
  freeDeliveryCharges: number;
}
