import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { constEnv } from 'common/constants.env';

import { CmsComponent } from "./cms/cms.component";
import { FaqComponent } from './faq/faq.component';
import { ContactComponent } from './contact/contact.component';

const baseUrl = constEnv.BASE_URL;

const routes: Routes = [
  {
    path: 'page',
    children: [
       { path: ':pagename', component: CmsComponent },
       { path: '', redirectTo: '/home', pathMatch: 'full' }
    ]
 },
 { path: 'faq', component: FaqComponent },
 { path: 'contact', component: ContactComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
