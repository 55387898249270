import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { constEnv } from 'common/constants.env';
import { map } from 'rxjs/operators';

import { AuthenticationService } from 'common/services/authentication.service';

@Injectable({ providedIn: 'root' })
export class DriverService {
    currentUserProfile;
    configConstant = constEnv;
    constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
        this.currentUserProfile = this.authenticationService.currentUserProfileValue;
    }

    getAll( dataTablesParameters = [] ) {

        // let searchValue = ''; let searchBy = '';
        // if ( dataTablesParameters.search.value !== '' ) {
        //     searchValue = dataTablesParameters.search.value;
        //     searchBy = dataTablesParameters.search.column;
        // }
        // const searchParams = new HttpParams()
        //     .set('searchColumn', searchBy)
        //     .set('searchValue', searchValue)
        //     .set('pageNo', dataTablesParameters.start)
        //     .set('pageSize', dataTablesParameters.length)
        //     .set('sortColumn', '')
        //     .set('sortOrder', '');

        return this.http.get(`${this.configConstant.apiStoreURL}/driverlist`, {
            // params: searchParams
        })
            .pipe(map(data => {
                return data;
            }));
    }

    get( driverId: number ) {
        return this.http.get(`${this.configConstant.apiStoreURL}/getdriverbyid/${driverId}`)
            .pipe(map(data => {
                return data;
            }));
    }

    add( formData ) {
        return this.http.post(`${this.configConstant.apiStoreURL}/adddriver`, formData)
            .pipe(map(data => {
                return data;
            }));
    }

    update( formData ) {
        return this.http.post(`${this.configConstant.apiStoreURL}/updatedriver`, formData)
            .pipe(map(data => {
                return data;
            }));
    }

    action( action, formData ) {
        if (action === 'add') {
            return this.add(formData);
        } else {
            return this.update(formData);
        }
    }

    delete(id: number) {
        return this.http.get(`${this.configConstant.apiStoreURL}/deletedriver/${id}`, {});
    }

    updateDocument( driverId: number, formData ) {
        return this.http.post(`${this.configConstant.apiDriverURL}/document/update?DriverId=${driverId}`, formData)
        .pipe(map(data => {
            return data;
        }));
    }

    verifyDriverProfile( driverId ) {
        return this.http.get(`${this.configConstant.apiStoreURL}/verifydriver/${driverId}`)
        .pipe(map(data => {
            return data;
        }));
    }

}
