import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDatepicker, MatDatepickerInputEvent } from "@angular/material";
import { constEnv } from "common/constants.env";
import { CustomerService } from "common/services/customers/customer.service";
import { LoaderService } from "common/services/loader.service";
import { TitleService } from "common/services/title.service";
import * as moment from "moment";
import { AlertService } from "ngx-alerts";
import { BsModalRef, BsModalService, ModalDirective } from "ngx-bootstrap";
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { first } from "rxjs/operators";

@Component({
  selector: "app-return-request",
  templateUrl: "./return-request.component.html",
  styleUrls: ["./return-request.component.css"],
})
export class ReturnRequestComponent implements OnInit {
  config = constEnv;

  returnRequestLists: ReturnRequest[] = [];

  pagination: Paging;
  itemPerPage = 12;

  @ViewChild("rejectReasonModal", { static: false })
  rejectReasonModal: ModalDirective;

  @ViewChild("pickupDateConfirmModel", { static: false })
  pickupDateConfirmModel: ModalDirective;

  @ViewChild("pickupDatePicker", { static: false })
  pickupDatePicker: MatDatepicker<null>;

  modalRef: BsModalRef;
  feedback: string = "";
  feedbackForm: FormGroup;
  submitted = false;
  ticketIdNumber: any;
  customerFeedBack: string;

  currentDate = new Date();
  minDate = new Date(this.currentDate.setDate(this.currentDate.getDate() + 1));
  maxDate = new Date(this.currentDate.setDate(this.currentDate.getDate() + 2));

  selectedPickupItem: any;
  selectedPickupDate: string;

  constructor(
    private titleService: TitleService,
    private ls: LoaderService,
    private customer: CustomerService,
    private alert: AlertService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Return/Replace");
    this.pagination = {
      totalCount: 0,
      totalRowsAfterFiltering: 0,
      pageSize: this.itemPerPage,
      currentPage: 0,
      totalPages: 0,
      previousPage: "",
      nextPage: "",
    };
    const paging = { page: 0, itemsPerPage: this.itemPerPage };

    this.getReturnRequestLists(paging);

    this.feedbackForm = new FormGroup({
      feedback: new FormControl("", [
        Validators.required,
        Validators.maxLength(500),
      ]),
    });
  }

  getReturnRequestLists(paging: PageChangedEvent) {
    this.ls.showLoader();

    paging.page = paging.page > 0 ? paging.page - 1 : 0;
    paging.itemsPerPage = paging.itemsPerPage
      ? paging.itemsPerPage
      : this.itemPerPage;

    this.customer
      .getReturnRequestLists(paging)
      .pipe(first())
      .subscribe(
        (resp) => {
          if (resp["status"] === true) {
            this.returnRequestLists = resp["data"] as ReturnRequest[];
            this.pagination = JSON.parse(resp["paging"]);
          } else {
            this.pagination.totalCount = 0;
            this.pagination.totalRowsAfterFiltering = 0;
            this.returnRequestLists = [];
          }
          this.ls.hideLoader();
        },
        (err) => {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          if (msg !== "") {
            // this.alert.danger(err.error.message);
            this.returnRequestLists = [];
            this.pagination.totalCount = 0;
            this.pagination.totalRowsAfterFiltering = 0;
          }
          this.ls.hideLoader();
        }
      );
  }

  pageChange(paging: PageChangedEvent) {
    console.log(paging);
    paging.page = paging.page > 0 ? paging.page : 0;
    paging.itemsPerPage = paging.itemsPerPage
      ? paging.itemsPerPage
      : this.itemPerPage;

    this.getReturnRequestLists(paging);
  }

  returnRequestPayment(item: ReturnRequest) {
    this.ls.showLoader();
    const ticketDBId = item.id;
    sessionStorage.removeItem(btoa("ticketId"));
    this.customer
      .returnRequestPayment(ticketDBId)
      .pipe(first())
      .subscribe(
        (resp) => {
          if (resp["status"] === true) {
            if (resp["data"].url) {
              sessionStorage.setItem(
                btoa("ticketId"),
                btoa(ticketDBId.toString())
              );
              window.location.href = resp["data"].url;
            }
          }
          this.ls.hideLoader();
        },
        (err) => {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          if (msg !== "") {
            this.alert.danger(err.error.message);
          }
          this.ls.hideLoader();
        }
      );
  }

  selectedItem: ReturnRequest = null;
  openRejectReasonModal(item: ReturnRequest, actionType: string) {
    if (actionType == "feedback") {
      this.customerFeedBack = item.customerFeedback;
    } else {
      this.customerFeedBack = "";
      this.selectedItem = item;
    }
    this.rejectReasonModal.show();
  }

  onRejectReasonModalHidden() {
    this.selectedItem = null;
  }
  openModal(template: TemplateRef<any>, ticketId) {
    this.ticketIdNumber = ticketId;
    this.modalRef = this.modalService.show(template);
  }

  get f() {
    return this.feedbackForm.controls;
  }

  submitFeedback() {
    this.submitted = true;
    if (this.feedbackForm.valid) {
      this.ls.showLoader();
      const feedbackValue = this.feedbackForm.get("feedback").value;

      const formData = new FormData();
      formData.append("TicketId", this.ticketIdNumber);
      formData.append("Feedback", feedbackValue);

      this.customer
        .addFeedBack(formData)
        .pipe(first())
        .subscribe(
          (resp: Feedback) => {
            console.log(resp);
            if (resp["status"] === true) {
              this.pagination = {
                totalCount: 0,
                totalRowsAfterFiltering: 0,
                pageSize: this.itemPerPage,
                currentPage: 0,
                totalPages: 0,
                previousPage: "",
                nextPage: "",
              };
              const paging = { page: 0, itemsPerPage: this.itemPerPage };

              this.getReturnRequestLists(paging);
              this.alert.success(resp.message);
              this.modalRef.hide();
            }
            this.ls.hideLoader();
          },
          (err) => {
            const msg =
              typeof err.error.Message !== "undefined"
                ? err.error.Message
                : err.error.message;
            if (msg !== "") {
              this.alert.danger(err.error.message);
            }
            this.ls.hideLoader();
          }
        );
    } else {
      this.feedbackForm.markAllAsTouched();
    }
  }

  isFeedBackVisible(item: any): boolean {
    if (
      item.returnStatusId == 22 &&
      item.isReplaced &&
      item.customerFeedback == ""
    ) {
      return true;
    }
    if (
      item.returnStatusId == 17 &&
      !item.isReplaced &&
      item.customerFeedback == ""
    ) {
      return true;
    }

    return false;
  }

  showPickupDateByStatus(item: ReturnRequest): boolean {
    const returnStatusIds = [15, 16];
    if (item.pickupDate === "" && item.pickupDate === null) {
      return false;
    }
    if (returnStatusIds.indexOf(item.returnStatusId) !== -1) {
      return true;
    }
    return false;
  }

  public myPickupDate: Date;
  openDatePicket() {
    // setTimeout(() => {
    this.pickupDatePicker.open();
    // }, 100);
  }
  pickupDateChange(event: MatDatepickerInputEvent<Date>, item: any) {
    // console.log(item);
    this.selectedPickupItem = item;
    const pickupDate = moment(event.value);
    this.selectedPickupDate = pickupDate.format("MM/DD/YYYY");
    // event.value = null;
    this.pickupDateConfirmModel.show();
  }

  onPickDateConfirmModalHidden() {
    this.selectedPickupItem = [];
    this.selectedPickupDate = "";
    this.myPickupDate = null;
  }

  onPickupChangeDateButtonClicked() {
    this.myPickupDate = null;
    this.pickupDateConfirmModel.hide();
    setTimeout(() => {
      this.pickupDatePicker.open();
    }, 100);
  }

  updatePickupDate() {
    if (this.selectedPickupDate === "") {
      return;
    }
    if (!this.selectedPickupItem) {
      return;
    }

    const ticketDBId = this.selectedPickupItem.id;

    this.customer
      .updatePickupDate(ticketDBId, this.selectedPickupDate)
      .pipe(first())
      .subscribe(
        (resp) => {
          if (resp["status"] === true) {
            this.ls.hideLoader();
            this.returnRequestPayment(this.selectedPickupItem);
          } else {
            this.alert.danger(resp["message"]);
            this.ls.hideLoader();
          }
        },
        (err) => {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          if (msg !== "") {
            this.alert.danger(err.error.message);
          }
          this.ls.hideLoader();
        }
      );
  }
}

export interface Paging {
  totalCount: number;
  totalRowsAfterFiltering: number;
  pageSize: number;
  currentPage: number;
  totalPages: number;
  previousPage: string;
  nextPage: string;
}

interface ReturnRequest {
  id: number;
  ticketId: string;
  createdDate: string;
  customerName: string;
  customerPhone: string;
  statusName: string;
  storeId: number;
  statusColor: string;
  backgroundColor: string;
  itemReturnReason: string;
  orderTotal: string;
  productName: string;
  productPrice: string;
  productImage: string;
  totalItem: number;
  isAccepted: boolean;
  isClosed: boolean;
  isInProgress: boolean;
  storeName: string;
  customerId: number;
  customerLangId: number;
  storeLangId: number;
  customerEmail: string;
  storeEmail: string;
  description: string;
  attachment: string;
  orderId: number;
  orderBaseDetailId: number;
  orderNo: string;
  statusId: number;
  returnStatusId: number;
  returnStatusName: string;
  deliveryHandleId: number;
  reason: string;
  isPickupByStore: boolean;
  customerFeedback: string;
  pickupDate: string;
  driverDetails: any[];
}

interface Feedback {
  status: boolean;
  message: string;
}
