export class CustomerUser {
    cust_id: number;
    first_name: string;
    last_name: string;
    email: string;
    phone_code: string;
    phone: string;
    languageId: number;
    language: string;
    isShowAboutUs: boolean;
    isShowHelpCenter: boolean;
    isShowPrivacyPolicy: boolean;
    isShowTermsOfUse: boolean;
    isShowTrustAndSafety: boolean;
    currency: string;
    currencyId: number;
    profile_image: string
    

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}