import {
  Component,
  OnInit,
  TemplateRef,
  ChangeDetectorRef,
  ViewChild,
} from "@angular/core";

import { constEnv } from "common/constants.env";
import { AlertService } from "@full-fledged/alerts";
import { TitleService } from "common/services/title.service";
import { SettingsService } from "common/services/store/settings.service";

import {
  BsModalService,
  BsModalRef,
  ModalDirective,
} from "ngx-bootstrap/modal";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { Subscription, combineLatest } from "rxjs";
import { IsLoadingService } from "@service-work/is-loading";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "common/_components/confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-prefrred-delivery-company",
  templateUrl: "./prefrred-delivery-company.component.html",
  styleUrls: ["./prefrred-delivery-company.component.css"],
})
export class PrefrredDeliveryCompanyComponent implements OnInit {
  modalRef: BsModalRef;
  subscriptions: Subscription[] = [];

  searchForm: FormGroup;
  submitted = false;

  @ViewChild("autoShownModal", { static: false })
  autoShownModal: ModalDirective;
  @ViewChild("searchFormModal", { static: false })
  searchFormModal: ModalDirective;
  @ViewChild("removeConfirm", { static: false }) removeConfirm: ModalDirective;

  driverData: any = [];
  noMessageDisplay = "";
  driverLists: any;
  removePreferableId = 0;

  config = constEnv;

  constructor(
    private alert: AlertService,
    private ls: IsLoadingService,
    private titleService: TitleService,
    private settings: SettingsService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private changeDetection: ChangeDetectorRef,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Preferred Delivery Company");
    if (this.ls.isLoading$()) {
      this.ls.remove();
    }

    this.getDriverLists();

    this.makeForm();
  }

  getDriverLists() {
    this.ls.add();
    this.noMessageDisplay = "";
    this.driverLists = [];
    this.settings.getPreferredDriverLists().subscribe(
      (response) => {
        if (response["status"] === true) {
          this.driverLists = response["data"];
        } else {
          this.noMessageDisplay = response["message"];
        }
        this.ls.remove();
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;
        this.noMessageDisplay = msg;
        this.ls.remove();
      }
    );
  }

  makeForm() {
    this.searchForm = this.fb.group({
      emailId: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern(
            "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"
          ),
        ])
      ),
    });
  }

  getErrorEmail() {
    return this.searchForm.get("emailId").hasError("required")
      ? ""
      : this.searchForm.get("emailId").hasError("pattern")
      ? "EmailInValid"
      : // ? this.titleService.getTranslatedString("EmailInValid")
        "";
  }

  openModal() {
    this.searchFormModal.show();
  }

  hideModal() {
    this.submitted = false;
    this.searchForm.reset();
  }

  backToSearch() {
    this.driverData = [];
    this.autoShownModal.hide();
    this.searchFormModal.show();
  }

  get f() {
    return this.searchForm.controls;
  }

  submitForm() {
    this.submitted = true;

    if (this.searchForm.invalid) {
      return;
    }

    this.ls.add();
    this.settings.searchDriverData(this.searchForm.value).subscribe(
      (response) => {
        if (response["status"] === true) {
          this.driverData = response["data"] !== null ? response["data"] : [];
          this.searchFormModal.hide();
          this.autoShownModal.show();
        } else {
          this.alert.danger(response["message"]);
        }
        this.ls.remove();
      },
      (err) => {
        const msg =
          typeof err.error.Message !== "undefined"
            ? err.error.Message
            : err.error.message;

        this.alert.danger(msg);
        this.ls.remove();
      }
    );
  }

  addToPrefrredDeliveryCompany(deliveryCompanyId = 0) {
    if (deliveryCompanyId > 0) {
      this.driverData = [];
      this.ls.add();
      this.settings.addPreferrableDeliveryCompany(deliveryCompanyId).subscribe(
        (response) => {
          if (response["status"] === true) {
            this.searchFormModal.hide();
            this.autoShownModal.hide();
            this.alert.success(response["message"]);
            this.getDriverLists();
          } else {
            this.alert.danger(response["message"]);
            this.backToSearch();
          }
          this.ls.remove();
        },
        (err) => {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;

          this.alert.danger(msg);
          this.ls.remove();
        }
      );
    } else {
      this.backToSearch();
    }
  }

  removeAction(preferenceId) {
    this.removePreferableId = preferenceId;
    this.removeConfirm.show();
  }

  removePreferredDeliveryCompany(preferenceId) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm",
        message: "DeleteConfirm",
      },
    });

    confirmDialog.afterClosed().subscribe((result) => {
      if (result === true) {
        this.ls.add();
        this.settings.removePreferrableDriver(preferenceId).subscribe(
          (response) => {
            if (response["status"] === true) {
              this.driverData = [];
              this.alert.success(response["message"]);
              this.getDriverLists();
            } else {
              this.alert.danger(response["message"]);
            }
            this.ls.remove();
          },
          (err) => {
            const msg =
              typeof err.error.Message !== "undefined"
                ? err.error.Message
                : err.error.message;

            this.alert.danger(msg);
            this.ls.remove();
          }
        );
      }
    });
  }

  hideRemoveModal() {
    this.removePreferableId = 0;
  }
}
