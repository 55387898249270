import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { constEnv } from 'common/constants.env';
import { AuthGuard } from 'common/_helpers/auth.guard';
import { WishlistComponent } from "./wishlist/wishlist.component";
import { DetailComponent } from "./detail/detail.component";
const WISHLIST_ENDPOINT = `${constEnv.customerEndPoint}/wishlist`;
const routes: Routes = [
  {
    path: `${WISHLIST_ENDPOINT}`,
    children: [
      { path: 'detail',
      children: [
        { path: ':id', component: DetailComponent },
        { path: '', redirectTo: `${WISHLIST_ENDPOINT}`, pathMatch: 'full'}
     ]
      },
      { path: '', component: WishlistComponent }
   ],
    
    canActivate: [AuthGuard]
 },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WishlistRoutingModule { }
