import { Component, OnInit, ViewChild } from "@angular/core";
import { DataTableDirective } from "angular-datatables";
import { TitleService } from "common/services/title.service";
import { constEnv } from "common/constants.env";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CustomerService } from "common/services/customers/customer.service";
import { first } from "rxjs/operators";
import { AlertService } from "ngx-alerts";
@Component({
  selector: "app-orders",
  templateUrl: "./orders.component.html",
  styleUrls: ["./orders.component.css"],
})
export class OrdersComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};

  searchForm: FormGroup;
  hasOrders = false;
  lists = [];
  config = constEnv;
  orderFiltersOptions = [{ optionKey: "orderId", optionValue: "OrderNo" }];

  maxDate = new Date();

  submitted = false;
  constructor(
    private titleService: TitleService,
    private fb: FormBuilder,
    private cs: CustomerService,
    private alert: AlertService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Orders");
    this.makeSearchFrom();
    this.createDataTable();
  }
  makeSearchFrom() {
    this.searchForm = this.fb.group({
      filterBy: [this.orderFiltersOptions[0].optionKey],
      filterText: [""],
      orderFilterDates: [""],
    });
  }

  createDataTable() {
    this.dtOptions = {
      pagingType: "full_numbers",
      ordering: false,
      lengthChange: false,
      searching: false,
      serverSide: true,
      processing: true,
      // pageLength: -1,
      language: {
        paginate: {
          previous: "<",
          first: "<<",
          last: ">>",
          next: ">",
        },
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.hasOrders = true;
        const searchparams = this.searchForm.value;

        if (searchparams.filterText !== "") {
          //dataTablesParameters.search.column = searchparams.filterBy;
          //dataTablesParameters.search.value = searchparams.filterText;
          const searchFilterText = searchparams.filterText.replace(/#/g, "");
          dataTablesParameters.orderId = searchFilterText;
        }
        if (searchparams.orderFilterDates !== "") {
          dataTablesParameters.dateFrom = searchparams.orderFilterDates[0]
            .toJSON()
            .split("T")[0];
          dataTablesParameters.dateTo = searchparams.orderFilterDates[1]
            .toJSON()
            .split("T")[0];
        }
        this.cs
          .getCustomerOrders(dataTablesParameters)
          .pipe(first())
          .subscribe(
            (resp) => {
              let paging: any;
              if (resp["status"] === true) {
                this.lists = resp["data"];
                paging = JSON.parse(resp["paging"]);
                // paging = { totalCount: this.lists.length, totalRowsAfterFiltering: this.lists.length };
              } else {
                paging = { totalCount: 0, totalRowsAfterFiltering: 0 };
                this.lists = [];
                this.hasOrders = false;
              }

              if (this.lists.length > 0) {
                this.hasOrders = true;
              }

              callback({
                recordsTotal: paging.totalCount,
                recordsFiltered: paging.totalRowsAfterFiltering,
                data: [],
              });
            },
            (err) => {
              const msg =
                typeof err.error.Message !== "undefined"
                  ? err.error.Message
                  : err.error.message;
              if (msg !== "") {
                // this.alert.danger(err.error.message);
              }
              callback({
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              });
            }
          );
      },
    };
  }

  get f() {
    return this.searchForm.controls;
  }
  onSubmit() {
    this.submitted = true;

    if (this.searchForm.invalid) {
      return;
    }

    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) =>
      dtInstance.draw()
    );
  }

  filterChange(selectedVal) {
    this.searchForm.get("filterText").setValue("");
  }

  resetFrom() {
    this.submitted = false;
    // this.searchForm.reset();

    // this.searchForm.get('filterBy').setValue( this.productFiltersOptions[0].optionKey );
    this.searchForm.get("filterText").setValue("");
    this.searchForm.get("orderFilterDates").setValue("");

    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) =>
      dtInstance.draw()
    );
  }
}
