import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { IsLoadingService } from "@service-work/is-loading";
import { constEnv } from "common/constants.env";
import { OrderService } from "common/services/store/order.service";
import { TitleService } from "common/services/title.service";
import { AlertService } from "@full-fledged/alerts";
import { first } from "rxjs/operators";
import { Paging } from "src/app/pages/customer/return-request/return-request.component";
import { Config } from "datatables.net";

@Component({
  selector: "app-lists",
  templateUrl: "./lists.component.html",
  styleUrls: ["./lists.component.css"],
})
export class ListsComponent implements OnInit {
  // dtOptions: DataTables.Settings = {};
  dtOptions: Config = {};

  config = constEnv;

  lists: any[] = [];
  hasLists = false;
  constructor(
    private titleService: TitleService,
    private fb: FormBuilder,
    private alert: AlertService,
    private isLoadingService: IsLoadingService,
    private orderService: OrderService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Cancel Requests");

    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }

    this.createDataTable();
  }

  createDataTable() {
    this.dtOptions = {
      pagingType: "full_numbers",
      ordering: false,
      lengthChange: false,
      searching: false,
      serverSide: true,
      processing: true,
      // pageLength: 1,
      language: {
        paginate: {
          previous: "<",
          first: "<<",
          last: ">>",
          next: ">",
        },
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.hasLists = true;

        let paging: Paging = null;

        this.orderService
          .getCancelRequestLists(dataTablesParameters)
          .pipe(first())
          .subscribe(
            (data) => {
              const res = data as any;

              if (res.status === true) {
                this.lists = res.data as any[];

                paging = JSON.parse(res.paging) as Paging;
              } else {
                paging = {
                  totalCount: 0,
                  totalRowsAfterFiltering: 0,
                } as Paging;

                this.alert.danger(data["message"]);
                this.lists = [];
              }

              callback({
                recordsTotal: paging.totalCount,
                recordsFiltered: paging.totalRowsAfterFiltering,
                data: [],
              });

              // this.loader.hideLoader();
            },
            (err) => {
              if (typeof err.error !== "undefined") {
                const msg =
                  typeof err.error.Message !== "undefined"
                    ? err.error.Message
                    : err.error.message;
                // this.alert.danger(msg);
                this.lists = [];
              }

              callback({
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              });

              // this.loader.hideLoader();
            }
          );
      },
    };
  }
}
