import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { ComponentModule } from 'common/_components/components.module';
import { MaterialModule } from 'common/modules/material.module';
import { TemplateDefaultModule } from '../../shared/template-default/template-default.module';
import { DataTablesModule } from 'angular-datatables';


import { OffersRoutingModule } from './offers-routing.module';
import { ListsComponent } from './lists/lists.component';


@NgModule({
  declarations: [ListsComponent],
  imports: [
    CommonModule,
    OffersRoutingModule,
    ReactiveFormsModule,
    TranslateModule,
    ComponentModule,
    MaterialModule,
    TemplateDefaultModule,
    DataTablesModule
  ]
})
export class OffersModule { }
