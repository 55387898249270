import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { TitleService } from "common/services/title.service";
import { constEnv } from "common/constants.env";
import { LoaderService } from "common/services/loader.service";
import { CustomerService } from "common/services/customers/customer.service";
import { first } from "rxjs/operators";
import { AlertService } from "@full-fledged/alerts";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalDirective } from "ngx-bootstrap/modal";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MAT_RADIO_DEFAULT_OPTIONS,
  MatRadioChange,
} from "@angular/material/radio";
import { GeneralService } from "common/services/general.service";
import { MatSelectionList } from "@angular/material/list";

@Component({
  selector: "app-detail",
  templateUrl: "./detail.component.html",
  styleUrls: ["./detail.component.css"],
  providers: [
    { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: "primary" } },
  ],
})
export class DetailComponent implements OnInit {
  orderId = 0;

  config = constEnv;
  listUrl: string;
  orderInfo = [];
  patches = [];

  orderCalculation: OrderCalculation = null;
  orderCalculationNew: any[] = [];

  @ViewChild("returnFormModal", { static: false })
  returnFormModal: ModalDirective;

  @ViewChild("cancelFormModal", { static: false })
  cancelFormModal: ModalDirective;

  @ViewChild("allCancelItemSelected", { static: false })
  public allCancelItemSelected: MatSelectionList;

  returnRequestForm: FormGroup;
  isOpenReturnRequestModal: boolean = false;
  returnItemData: any;

  returnReasonOptions: any = [];

  allCancelItemComplete: boolean = false;
  cancelOrderItems: any[] = [];
  cancelOrderCurrencyCode: string = "";
  cancelRequestForm: FormGroup;
  isOpenCancelRequestModal: boolean = false;
  cancelReasonOptions: any = [];

  @ViewChild("rateNowFormModal", { static: false })
  rateNowFormModal: ModalDirective;
  rateNowForm: FormGroup;
  isOpenRateNowModal: boolean = false;
  rateNowItemData: any;

  @ViewChild("rateNowFileInput", { static: true })
  rateNowFileInput: ElementRef;
  rateNowFileData: File[] = [];
  rateNowPreviewUrl: any = [];

  constructor(
    private titleService: TitleService,
    private ls: LoaderService,
    private customer: CustomerService,
    private general: GeneralService,
    private alert: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Order Detail");
    this.orderId =
      this.route.snapshot.params.id > 0 ? this.route.snapshot.params.id : 0;

    this.listUrl = `${this.config.CUSTOMER_URL}/orders`;

    if (this.orderId <= 0) {
      this.router.navigate([this.listUrl]);
      return false;
    }
    this.getOrderDetail();
    // this.makeReturnRequestForm();
  }

  private makeReturnRequestForm() {
    this.returnRequestForm = this.fb.group({
      IsPickupByStore: [null, Validators.required],
      IsReplaced: [null, Validators.required],
      PickupCharge: [null],
      Phone: [null, [Validators.required, this.validatePhoneNumber]],
      ItemOptionId: [null, Validators.required],
      Description: [""],
      Attachment: [null],
      Quantity: [null, Validators.required],
    });
  }

  get requestForm() {
    return this.returnRequestForm.controls;
  }

  validatePhoneNumber(control) {
    const phoneNumber = control.value;

    if (phoneNumber && /^\d{10}$/.test(phoneNumber)) {
      return null;
    } else {
      return { invalidPhoneNumber: true };
    }
  }

  getOrderDetail() {
    this.ls.showLoader();
    this.customer
      .getCustomerOrderDetail(this.orderId)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data["status"] === true) {
            this.orderInfo = data["data"];
            this.patches = this.orderInfo["patchDtos"];
            this.orderCalculation = this.orderInfo[
              "myCartCalulationDto"
            ] as OrderCalculation;

            this.orderCalculationNew = this.orderInfo[
              "calculationDetails_New"
            ] as CalculationDetail[];

            //console.log(this.patches);
            this.ls.hideLoader();
          } else {
            this.alert.danger(data["message"]);
            this.ls.hideLoader();
            if (data["status_code"] == 400) {
              this.router.navigate(["/customer/orders"]);
            }
          }
          this.ls.hideLoader();
        },
        (err) => {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          if (msg !== "") {
            this.alert.danger(err.error.message);
          }
          this.ls.hideLoader();
        }
      );
  }

  numSequence(n: number): Array<number> {
    return Array(n);
  }

  getReturnReasonOption() {
    this.returnReasonOptions = [];
    this.general
      .getReturnReasonOptions()
      .pipe(first())
      .subscribe(
        (data) => {
          if (data["status"] === true) {
            this.returnReasonOptions = data["data"];
          } else {
            this.alert.danger(data["message"]);
          }
        },
        (err) => {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          if (msg !== "") {
            this.alert.danger(err.error.message);
          }
        }
      );
  }

  openReturnForm(item: any) {
    this.returnItemData = item;
    // console.log(this.returnItemData);
    this.isOpenReturnRequestModal = true;
    this.makeReturnRequestForm();
    this.getReturnReasonOption();
    setTimeout(() => {
      this.returnRequestForm
        .get("Phone")
        .setValue(this.orderInfo["customerPhone"]);
      this.returnRequestForm.get("IsReplaced").setValue(false);
      if (this.returnItemData.qty == 1) {
        this.returnRequestForm
          .get("Quantity")
          .setValue(this.returnItemData.qty);
      }
      this.returnFormModal.show();
    }, 100);
  }

  onReturnRequestModalHidden() {
    this.returnItemData = null;
    this.isOpenReturnRequestModal = false;
    this.returnRequestForm.reset();
  }

  getReturnPickupCharge(event: MatRadioChange) {
    const qty = this.returnRequestForm.get("Quantity").value;
    // console.log(event.value);
    if (event.value == "true" && qty > 0) {
      const pickupChargeParams = {
        Quantity: qty,
        ProductId: this.returnItemData.productId,
        StoreId: this.returnItemData.storeId,
        OrderDetailId: this.returnItemData.orderDetailId,
        IsReplaced: this.returnRequestForm.get("IsReplaced").value,
      };

      this.customer
        .getReturnRequestPickupCharge(pickupChargeParams)
        .pipe(first())
        .subscribe(
          (data) => {
            // console.log(data);
            this.returnRequestForm.get("PickupCharge").setValue(data["data"]);
            // if (data["status"] === true) {
            //   // this.returnReasonOptions = data["data"];
            // } else {
            //   this.alert.danger(data["message"]);
            // }
          },
          (err) => {
            const msg =
              typeof err.error.Message !== "undefined"
                ? err.error.Message
                : err.error.message;
            if (msg !== "") {
              this.alert.danger(err.error.message);
            }
          }
        );
    } else {
      this.returnRequestForm.get("PickupCharge").setValue(null);
    }
  }

  onQuantityChange() {
    this.returnRequestForm.get("PickupCharge").setValue(null);
    this.returnRequestForm.get("IsPickupByStore").setValue(null);
  }

  onReturnRequestSubmit() {
    if (this.returnRequestForm.invalid) {
      return;
    }

    this.ls.showLoader();
    // console.log(this.returnRequestForm.value);
    const formData = new FormData();
    formData.append(
      "IsPickupByStore",
      this.returnRequestForm.value.IsPickupByStore
    );
    formData.append("IsReplaced", this.returnRequestForm.value.IsReplaced);
    formData.append("PickupCharge", this.returnRequestForm.value.PickupCharge);
    formData.append("ItemOptionId", this.returnRequestForm.value.ItemOptionId);
    formData.append("Phone", this.returnRequestForm.value.Phone);
    formData.append("Description", this.returnRequestForm.value.Description);
    formData.append("Attachment", this.returnRequestForm.value.Attachment);
    formData.append("Quantity", this.returnRequestForm.value.Quantity);
    formData.append("ProductId", this.returnItemData.productId);
    formData.append("StoreId", this.returnItemData.storeId);
    formData.append("OrderDetailId", this.returnItemData.orderDetailId);

    this.customer
      .returnRequestTickets(formData)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data["status"] === true) {
            this.alert.success(data["message"]);
            this.returnFormModal.hide();
            this.ls.hideLoader();
            this.getOrderDetail();
          } else {
            this.alert.danger(data["message"]);
            this.ls.hideLoader();
          }
        },
        (err) => {
          if (typeof err.error !== "undefined") {
            const msg =
              typeof err.error.Message !== "undefined"
                ? err.error.Message
                : err.error.message;
            this.alert.danger(msg);
          }
          this.ls.hideLoader();
        }
      );
  }

  getReturnStatusText(item: any) {
    let status = "";
    // if (item.isAccepted && item.isClosed) {
    if (item.isClosed) {
      status = "Close";
    } else if (item.isAccepted && !item.isClosed) {
      status = "Accepted";
    } else {
      status = "Open";
    }

    return status;
  }

  // cancel order flow
  get getCancelableItems(): any {
    const validCancelStatus = [9, 10];
    const cancelableItems = [];
    if (this.orderInfo && typeof this.orderInfo["patchDtos"] !== "undefined") {
      this.orderInfo["patchDtos"].map((patch) => {
        patch.itemList.map((item) => {
          if (validCancelStatus.indexOf(item.statusId) !== -1) {
            cancelableItems.push(item);
          }
        });
      });
    }
    return cancelableItems;
  }

  showCancelButton() {
    // const statusIds = [9, 10];
    // const isFound = statusIds.indexOf(this.orderInfo["statusId"]);

    // return isFound !== -1 ? true : false;
    return this.getCancelableItems.length > 0 ? true : false;
  }

  private makeCancelRequestForm() {
    this.cancelRequestForm = this.fb.group({
      Phone: [null, [Validators.required, this.validatePhoneNumber]],
      ItemOptionId: [null, Validators.required],
      Description: ["", [Validators.maxLength(500)]],
      Attachment: [null],
    });
  }

  getCancelReasonOption() {
    this.cancelReasonOptions = [];
    this.general
      .getCancelReasonOptions()
      .pipe(first())
      .subscribe(
        (data) => {
          if (data["status"] === true) {
            this.cancelReasonOptions = data["data"];
          } else {
            this.alert.danger(data["message"]);
          }
        },
        (err) => {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          if (msg !== "") {
            this.alert.danger(err.error.message);
          }
        }
      );
  }

  openCancelOrderPopup() {
    // this.cancelOrderItems = this.patches.reduce(
    //   (acc, obj) => acc.concat(obj.itemList),
    //   []
    // );
    this.cancelOrderItems = this.getCancelableItems;
    this.cancelOrderCurrencyCode =
      (this.cancelOrderItems.length > 0 &&
        this.cancelOrderItems[0].currencyCode) ||
      "";
    this.isOpenCancelRequestModal = true;
    this.getCancelReasonOption();
    this.makeCancelRequestForm();

    setTimeout(() => {
      this.cancelRequestForm
        .get("Phone")
        .setValue(this.orderInfo["customerPhone"]);
      this.cancelFormModal.show();
    }, 100);
  }

  onCancelOrderModalHidden() {
    this.cancelOrderItems = [];
    this.cancelFormModal.hide();
  }

  toggleCancelSelection(completed: boolean) {
    this.allCancelItemComplete = completed;
    if (completed) {
      this.allCancelItemSelected.selectAll();
    } else {
      this.allCancelItemSelected.deselectAll();
    }
  }

  isAllCancelSelected() {
    // console.log(
    //   this.allCancelItemSelected.selectedOptions.selected.map(
    //     (item) => item.value
    //   )
    // );
    return (
      this.allCancelItemSelected.selectedOptions.selected.length ===
      this.allCancelItemSelected.options.length
    );
  }

  onCancelSelectionListChange(event) {
    this.allCancelItemComplete = this.isAllCancelSelected();
  }

  someCancelComplete() {
    return (
      this.allCancelItemSelected &&
      this.allCancelItemSelected.selectedOptions.selected.length > 0 &&
      !this.allCancelItemComplete
    );
  }

  isCancelFormValid(): boolean {
    if (this.cancelRequestForm.invalid) {
      return false;
    }

    if (this.cancelOrderItems.length === 0) {
      return false;
    }

    if (
      this.allCancelItemSelected &&
      this.allCancelItemSelected.selectedOptions.selected.length === 0
    ) {
      return false;
    }

    return true;
  }

  onCancelRequestSubmit() {
    if (!this.isCancelFormValid()) {
      return;
    }

    const selectedItems =
      this.allCancelItemSelected.selectedOptions.selected.map(
        (item) => item.value
      );

    const orderDetailIdSet = [];
    selectedItems.forEach((item) => {
      orderDetailIdSet.push(item.orderDetailId);
    });

    const formData = new FormData();
    formData.append("ItemOptionId", this.cancelRequestForm.value.ItemOptionId);
    formData.append(
      "OrderDetailId",
      this.allCancelItemComplete ? "" : orderDetailIdSet.join(",")
    );
    formData.append("StoreId", "0");
    formData.append("OrderBaseDetailId", this.orderInfo["orderId"]);
    formData.append("Description", this.cancelRequestForm.value.Description);
    formData.append("Attachment", this.cancelRequestForm.value.Attachment);
    formData.append("Phone", this.cancelRequestForm.value.Phone);

    this.ls.showLoader();
    this.customer
      .cancelRequestTickets(formData)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data["status"] === true) {
            this.alert.success(data["message"]);
            this.cancelFormModal.hide();
            this.ls.hideLoader();
            this.getOrderDetail();
          } else {
            this.alert.danger(data["message"]);
            this.ls.hideLoader();
          }
        },
        (err) => {
          if (typeof err.error !== "undefined") {
            const msg =
              typeof err.error.Message !== "undefined"
                ? err.error.Message
                : err.error.message;
            this.alert.danger(msg);
          }
          this.ls.hideLoader();
        }
      );
  }

  private makeRateNowForm(item: any) {
    this.rateNowForm = this.fb.group({
      OrderId: [item.orderDetailId],
      Rate: [""],
      Comment: [null, Validators.required],
      Attachements: [null],
    });
  }

  openRateNowForm(item: any) {
    console.log(item);
    this.rateNowItemData = item;
    // console.log(this.returnItemData);
    this.makeRateNowForm(item);
    this.isOpenRateNowModal = true;

    setTimeout(() => {
      this.rateNowFormModal.show();
    }, 100);
  }

  onRateNowModalHidden() {
    this.rateNowItemData = null;
    this.isOpenRateNowModal = false;
    // this.returnRequestForm.reset();
  }

  isRateNowButtonShow(item: any): boolean {
    let isShow = false;
    if (
      item.statusId === 6 &&
      !item.isCancelledOrder &&
      !item.isReturnRequest
    ) {
      if (!item.isReviewStore) {
        isShow = true;
      }
    }
    return isShow;
  }

  rateNowFileProgress(secondaryFileInput: any) {
    this.rateNowFileData.push(secondaryFileInput.target.files[0] as File);
    this.rateNowImagePreview();
  }

  rateNowImagePreview() {
    if (this.rateNowFileData.length > 0) {
      this.rateNowPreviewUrl = [];
      for (let i = 0; i < this.rateNowFileData.length; i++) {
        const reader = new FileReader();
        reader.readAsDataURL(this.rateNowFileData[i]);
        reader.onload = (_event) => {
          this.rateNowPreviewUrl[i] = reader.result;
          if (this.rateNowPreviewUrl[i].length > 2 ** 21) {
            this.alert.danger("File exceeds the maximum size: 2mb.");
            this.removeRateNowImage(i);
          }
        };
      }
    }
  }

  removeRateNowImage(i) {
    this.rateNowFileData.splice(i, 1);
    this.rateNowPreviewUrl.splice(i, 1);
  }

  onRateNowSubmit() {
    if (this.rateNowForm.invalid) {
      return;
    }

    this.ls.showLoader();
    // console.log(this.returnRequestForm.value);
    const formData = new FormData();
    formData.append("OrderId", this.rateNowForm.value.OrderId);
    formData.append("Rate", this.rateNowForm.value.Rate);
    formData.append("Comment", this.rateNowForm.value.Comment);

    if (this.rateNowFileData.length > 0) {
      for (let i = 0; i < this.rateNowFileData.length; i++) {
        formData.append("Attachements", this.rateNowFileData[i]);
      }
    }

    this.customer
      .rateReviewToStoreOrderProduct(formData)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data["status"] === true) {
            this.alert.success(data["message"]);
            this.rateNowFormModal.hide();
            this.ls.hideLoader();
            this.getOrderDetail();
          } else {
            this.alert.danger(data["message"]);
            this.ls.hideLoader();
          }
        },
        (err) => {
          if (typeof err.error !== "undefined") {
            const msg =
              typeof err.error.Message !== "undefined"
                ? err.error.Message
                : err.error.message;
            this.alert.danger(msg);
          }
          this.ls.hideLoader();
        }
      );
  }
}

interface OrderCalculation {
  currencyCode: string;
  deliveryCharges: number;
  offerDiscount: number;
  packingCharges: number;
  assemblyCharge: number;
  paymentGatewayCharges: number;
  promotionalDiscount: number;
  subTotal: number;
  tax: number;
  taxInPer: any;
  totalAmount: number;
}

interface CalculationDetail {
  key: string;
  value: string;
  keyColor: string;
  valueColor: string;
}
