import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { constEnv } from "common/constants.env";
import { AuthenticationService } from "common/services/authentication.service";
import { NavItem } from "common/_models/nav-item";
import { IsLoadingService } from "@service-work/is-loading";
import { LanguageService } from "common/services/language.service";
import { GeneralService } from "common/services/general.service";
import {
  BsModalRef,
  BsModalService,
  ModalDirective,
} from "ngx-bootstrap/modal";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CustomerService } from "common/services/customers/customer.service";
import { first } from "rxjs/operators";
import { AlertService } from "@full-fledged/alerts";

@Component({
  selector: "app-siderbar",
  templateUrl: "./siderbar.component.html",
  styleUrls: ["./siderbar.component.css"],
})
export class SiderbarComponent implements OnInit {
  prifilePic = `${constEnv.IMAGE_PATH}/assets/profile-photo.png`;
  currentUserProfile;
  config = constEnv;
  navItems: NavItem[] = [
    {
      displayName: "Profile",
      iconName: "person",
      route: `${this.config.CUSTOMER_URL}/profile`,
    },
    {
      displayName: "Orders",
      iconName: "local_mall",
      route: `${this.config.CUSTOMER_URL}/orders`,
    },
    {
      displayName: "Address",
      iconName: "person_pin",
      route: `${this.config.CUSTOMER_URL}/address`,
    },
    {
      displayName: "Wishlist",
      iconName: "favorite",
      route: `${this.config.CUSTOMER_URL}/wishlist`,
    },
    {
      displayName: "Return / Replace Requests",
      iconName: "assignment_return",
      route: `${this.config.CUSTOMER_URL}/return-request-list`,
    },
    {
      displayName: "Cancel Requests",
      iconName: "cancel_presentation",
      route: `${this.config.CUSTOMER_URL}/cancel-request`,
    },
    {
      displayName: "Jobs",
      iconName: "task",
      route: `${this.config.CUSTOMER_URL}/job`,
    },
  ];

  modalRef: BsModalRef;

  @ViewChild("openPhotoModal", { static: false })
  openPhotoModal: ModalDirective;
  showOpenPhotoModal: boolean = false;

  changeProfilePicFrm: FormGroup;
  @ViewChild("fileInput", { static: true }) fileInput: ElementRef;

  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;

  constructor(
    private authenticationService: AuthenticationService,
    private isLoadingService: IsLoadingService,
    private langService: LanguageService,
    private gs: GeneralService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private customerService: CustomerService,
    private alertService: AlertService
  ) {
    this.changeProfilePicFrm = this.fb.group({
      profilePhoto: null,
    });
  }

  ngOnInit() {
    this.currentUserProfile =
      this.authenticationService.currentUserProfileValue;
  }
  logout() {
    this.isLoadingService.add();
    // this.langService.changeLanguage(this.langService.defaultLanguage);
    this.authenticationService.logout();
    const loginURL = `${this.config.BASE_URL}login`;

    const settingLang = this.gs.getSettings("default_language");

    const changeLangCode =
      settingLang !== "" ? settingLang : this.langService.defaultLanguage;

    this.langService.getAvailableLanguages().map((lang) => {
      if (changeLangCode === lang.lang_code) {
        this.langService._setLanguage(lang.lang_code);
        localStorage.setItem("defaultLang", JSON.stringify(lang));
      }
    });

    this.langService
      .getLanguageTranslation(this.langService.getCurrentLanguage())
      .subscribe((translationsJSON) => {
        this.langService.setLanguageTranslation(translationsJSON);
        window.location.href = loginURL;
      });
  }

  openModal() {
    this.showOpenPhotoModal = true;
  }

  onHidden() {
    this.showOpenPhotoModal = false;
    this.changeProfilePicFrm.reset();
    this.fileData = null;
    this.previewUrl = null;
    this.fileUploadProgress = null;
  }

  fileProgress(fileInput: any) {
    this.fileData = fileInput.target.files[0] as File;
    const allowedImageTypes = ["image/jpeg", "image/jpg", "image/png"];
    if (allowedImageTypes.includes(this.fileData.type)) {
      this.preview();
    } else {
      this.alertService.danger("Please upload only JPG, JPEG, or PNG image.");
      fileInput.target.value = null;
      this.fileData = null;
      this.previewUrl = null;
      this.changeProfilePicFrm.get("profilePhoto").setValue(null);
    }
  }

  preview() {
    // Show preview
    const mimeType = this.fileData.type;
    const reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
      this.changeProfilePicFrm.get("profilePhoto").setValue({
        filename: this.fileData.name,
        filetype: this.fileData.type,
        value: this.previewUrl.split(",")[1],
      });
    };
  }

  onSubmit() {
    this.isLoadingService.add();
    const formData = new FormData();
    formData.append("postedfile", this.fileData);
    this.customerService
      .changeProfilePhoto(formData)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data["status"] === true) {
            this.alertService.success(data["message"]);
            this.showOpenPhotoModal = false;
            location.reload();
          } else {
            this.alertService.danger(data["message"]);
          }
          this.isLoadingService.remove();
        },
        (err) => {
          const msg =
            typeof err.error.Message !== "undefined"
              ? err.error.Message
              : err.error.message;
          this.alertService.danger(msg);
          this.isLoadingService.remove();
        }
      );
  }
}
