export class CustomerAddress {
    address: string;
    addressId: number;
    addressTag: string;
    city: string;
    country: string;
    deliveryMessage: string;
    emailId: string;
    firstName: string;
    isDeliverable: boolean;
    lastName: string;
    location: string;
    mobileNo: string;
    states: string;
    zipCode: string;
    

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}