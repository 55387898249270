export class Notification {
  amount: string;
  deviceToken: string;
  deviceType: number;
  isRead: boolean;
  message: string;
  name: string;
  notificationId: number;
  notificationType: string;
  refId: number;
  sendDate: string;
  title: string;
  date: Date | string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
