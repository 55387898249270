import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { constEnv } from "common/constants.env";
import { AuthGuard } from "common/_helpers/auth.guard";

import { ListComponent } from "./list/list.component";
import { ActionComponent } from "./action/action.component";

const routeStore = constEnv.storeEndPoint;

const routes: Routes = [
  {
    path: `${routeStore}/shipping-rate`,
    children: [
      {
        path: ":action",
        children: [
          { path: ":id", component: ActionComponent },
          { path: "", component: ActionComponent },
        ],
      },
      { path: "", component: ListComponent },
    ],
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ShippingRateRoutingModule {}
