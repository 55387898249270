import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthGuard } from "common/_helpers/auth.guard";
import { constEnv } from "common/constants.env";

import { StoreComponent } from "./store/store.component";
import { TimeComponent } from "./time/time.component";
import { OrderComponent } from "./order/order.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { DocumentsComponent } from "./documents/documents.component";
import { BankDetailComponent } from "./bank-detail/bank-detail.component";
import { PickupAddressListComponent } from "./pickup-address-list/pickup-address-list.component";
import { PolicyComponent } from "./policy/policy.component";

const routeStore = constEnv.storeEndPoint;

const routes: Routes = [
  {
    path: `${routeStore}/settings/store`,
    component: StoreComponent,
    canActivate: [AuthGuard],
  },
  {
    path: `${routeStore}/settings/time`,
    component: TimeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: `${routeStore}/settings/order`,
    component: OrderComponent,
    canActivate: [AuthGuard],
  },
  {
    path: `${routeStore}/settings/change-password`,
    component: ChangePasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: `${routeStore}/settings/documents`,
    component: DocumentsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: `${routeStore}/settings/bank-detail`,
    component: BankDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: `${routeStore}/settings/pickup-address`,
    component: PickupAddressListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: `${routeStore}/settings/policy`,
    component: PolicyComponent,
    canActivate: [AuthGuard],
  },

  {
    path: `${routeStore}/settings`,
    redirectTo: `${routeStore}/settings/store`,
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class SettingsRoutingModule {}
