import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { constEnv } from 'common/constants.env';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'ngx-alerts';
import { StoreUserService } from 'common/services/store/storeUser.service';
import { first } from 'rxjs/operators';
import { TitleService } from 'common/services/title.service';
import { IsLoadingService } from '@service-work/is-loading';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  FGForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  constantConfig = constEnv;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private userService: StoreUserService,
    private isLoadingService: IsLoadingService,
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Forgot_Password');
    if ( this.isLoadingService.isLoading$() ) {
      this.isLoadingService.remove();
    }

    this.document.body.classList.add('forgot-password-body');

    this.FGForm = this.formBuilder.group({
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')
      ]))
    });
  }

  getErrorEmail() {
    return this.FGForm.get('email').hasError('required') ? '' :
      this.FGForm.get('email').hasError('pattern') ? this.titleService.getTranslatedString('EmailInValid') : '';
  }

  ngOnDestroy(): void {
    this.document.body.classList.remove('forgot-password-body');
  }

  get f() { return this.FGForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.FGForm.invalid) {
      return;
    }

    this.loading = true;
    this.isLoadingService.add();
    this.userService.forgotPassword( this.FGForm.value.email )
      .pipe(first())
      .subscribe(data => {
        this.alertService.success(data['message']);
        this.submitted = false;
        this.FGForm.reset();
        this.loading = false;
        this.isLoadingService.remove();
        this.router.navigate([`${this.constantConfig.STORE_URL}/login`]);
      },
        err => {
          const msg = (typeof err.error.message !== 'undefined') ? err.error.message : '';
          if (msg) {
            this.alertService.danger(msg);
          }
          this.loading = false;
          this.isLoadingService.remove();
        });
  }
}
