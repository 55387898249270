export class StoreDriver {
  id: number;
  firstName: string;
  email: string;
  phoneCode: string;
  phone: string;
  profileImage: string;
  lastName: string;
  isProfileVerified: boolean;
  langId: number;
  langName?: string;
  driverDocument?: StoreDriverDocument[];
  address: StoreDriverAddress;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  getFullName() {
    return this.firstName + " " + this.lastName;
  }

  getPhoneNumber() {
    return this.phoneCode + " " + this.phone;
  }
}

export interface StoreDriverAddress {
  addressLine: string;
  addressLine1: string;
  addressLine2: string;
  landmark: string;
  location: string;
  latitude: number;
  longitude: number;
  countryId: number;
  countryName: string;
  stateId: number;
  stateName: string;
  cityId: number;
  cityName: string;
  zipCode: string;
}

export interface StoreDriverDocument {
  docMgmtId: number;
  documentName: string;
  isExpiryDateRequired: boolean;
  id: number;
  driverId: number;
  uploadDocumentName: string;
  isVerified: boolean;
  isRejected: boolean;
  docExpiryDate: string;
}
