import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { ComponentModule } from "common/_components/components.module";
import { DataTablesModule } from "angular-datatables";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { MyDirectiveModule } from "common/directives/my-directive.module";
import { MaterialModule } from "common/modules/material.module";
import { TemplateDefaultModule } from "../../shared/template-default/template-default.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { ModalModule } from "ngx-bootstrap/modal";

import { PotentialListRoutingModule } from "./potential-list-routing.module";
import { ListComponent } from "./list/list.component";
import { PipeModule } from "common/_pipe/pipe.module";

@NgModule({
  declarations: [ListComponent],
  imports: [
    CommonModule,
    PotentialListRoutingModule,
    RouterModule,
    TranslateModule.forRoot(),
    ReactiveFormsModule,
    DataTablesModule,
    ComponentModule,
    MaterialModule,
    NgSelectModule,
    ModalModule.forRoot(),
    TemplateDefaultModule,
    BsDatepickerModule.forRoot(),
    MyDirectiveModule,
    PipeModule,
  ],
})
export class PotentialListModule {}
