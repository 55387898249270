import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { TranslateModule } from "@ngx-translate/core";
import { LoginTemplateComponent } from "common/_components/login-template/login-template.component";
import { MenuListItemComponent } from "common/_components/menu-list-item/menu-list-item.component";
import { ConfirmDialogComponent } from "common/_components/confirm-dialog/confirm-dialog.component";
import { MaterialModule } from "common/modules/material.module";
import { NavService } from "common/services/nav.service";
import { MapComponent } from "./maps/map.component";
import { MapControlComponent } from "./maps/map-control/map-control.component";

@NgModule({
  declarations: [
    LoginTemplateComponent,
    MenuListItemComponent,
    ConfirmDialogComponent,
    MapComponent,
    MapControlComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forRoot(),
    MaterialModule,
  ],
  exports: [
    LoginTemplateComponent,
    MenuListItemComponent,
    MapComponent,
    MapControlComponent,
  ],
  entryComponents: [ConfirmDialogComponent],
  providers: [NavService],
})
export class ComponentModule {}
