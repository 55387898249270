import { Component, OnInit, OnDestroy, Inject } from "@angular/core";

import { constEnv } from "common/constants.env";
import { ActivatedRoute } from "@angular/router";
import { GeneralService } from "common/services/general.service";

@Component({
  selector: "app-login-template",
  templateUrl: "./login-template.component.html",
  styleUrls: ["./login-template.component.css"],
})
export class LoginTemplateComponent implements OnInit {
  public constEnv = constEnv;

  currentYear = new Date().getFullYear();
  logoUrl = "";

  constructor(private route: ActivatedRoute, private gs: GeneralService) {}

  ngOnInit() {
    this.logoUrl = this.gs.getSiteLogo(true);
  }
}
