import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";

import { RouterModule } from "@angular/router";
import { MaterialModule } from "common/modules/material.module";
import { TranslateModule } from "@ngx-translate/core";

import { ComponentModule } from "common/_components/components.module";
import { TemplateDefaultModule } from "../../shared/template-default/template-default.module";
import { DataTablesModule } from "angular-datatables";
import { OrdersRoutingModule } from "./orders-routing.module";

import { OrdersComponent } from "./orders/orders.component";
import { DetailComponent } from "./detail/detail.component";

import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { ScheduleComponent } from "./schedule/schedule.component";
import { ModalModule } from "ngx-bootstrap/modal";
import { PopoverModule } from "ngx-bootstrap/popover";
import { NgSelectModule } from "@ng-select/ng-select";
import { PipeModule } from "common/_pipe/pipe.module";

@NgModule({
  declarations: [OrdersComponent, DetailComponent, ScheduleComponent],
  imports: [
    CommonModule,
    RouterModule,
    OrdersRoutingModule,
    TranslateModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    ComponentModule,
    TemplateDefaultModule,
    DataTablesModule,
    MaterialModule,
    BsDatepickerModule.forRoot(),
    ModalModule,
    NgSelectModule,
    PipeModule,
    PopoverModule.forRoot(),
  ],
})
export class OrdersModule {}
