import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { constEnv } from 'common/constants.env';
import { map } from 'rxjs/operators';

import { AuthenticationService } from 'common/services/authentication.service';

@Injectable({ providedIn: 'root' })
export class SpecificationService {
    currentUserProfile;
    configConstant = constEnv;
    constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
        this.currentUserProfile = this.authenticationService.currentUserProfileValue;
    }

    getAll( dataTablesParameters ) {

        let searchValue = ''; let searchBy = '';
        if ( dataTablesParameters.search.value !== '' ) {
            searchValue = dataTablesParameters.search.value;
            searchBy = dataTablesParameters.search.column;
        }
        
        const pageNo = (dataTablesParameters.start * 1) / (dataTablesParameters.length * 1);

        const searchParams = new HttpParams()
            .set('searchColumn', searchBy)
            .set('searchValue', searchValue)
            .set('pageNo', pageNo.toString())
            .set('pageSize', dataTablesParameters.length)
            .set('sortColumn', '')
            .set('sortOrder', '');

        return this.http.get(`${this.configConstant.apiStoreURL}/getspecnamelist`, {
            params: searchParams
        })
            .pipe(map(data => {
                return data;
            }));
    }

    getSpecification( specId ) {
        return this.http.get(`${this.configConstant.apiStoreURL}/getspecname/${specId}`)
            .pipe(map(data => {
                return data;
            }));
    }

    addEditSpecification( formData ) {
        return this.http.post(`${this.configConstant.apiStoreURL}/createspec`, formData)
            .pipe(map(data => {
                return data;
            }));
    }

    delete(id: number) {
        return this.http.post(`${this.configConstant.apiStoreURL}/category/delete/${id}`, {});
    }


    // SPecification Values

    getAllSpecValue( specId: number, dataTablesParameters ) {

        let searchValue = ''; let searchBy = '';
        if ( dataTablesParameters.search.value !== '' ) {
            searchValue = dataTablesParameters.search.value;
            searchBy = dataTablesParameters.search.column;
        }

        const pageNo = (dataTablesParameters.start * 1) / (dataTablesParameters.length * 1);

        const searchParams = new HttpParams()
            .set('searchColumn', searchBy)
            .set('searchValue', searchValue)
            .set('pageNo', pageNo.toString())
            .set('pageSize', dataTablesParameters.length)
            .set('sortColumn', '')
            .set('sortOrder', '');

        return this.http.get(`${this.configConstant.apiStoreURL}/getspecvaluelist/${specId}`, {
            params: searchParams
        })
            .pipe(map(data => {
                return data;
            }));
    }

    getCreateSpecification( specId ) {
        return this.http.get(`${this.configConstant.apiStoreURL}/createspecvalues/${specId}`)
            .pipe(map(data => {
                return data;
            }));
    }

    addEditSpecificationValue( formData ) {
        return this.http.post(`${this.configConstant.apiStoreURL}/createspecvalues`, formData)
            .pipe(map(data => {
                return data;
            }));
    }

    getSpecificationValue( SpecValueLangId ) {
        return this.http.get(`${this.configConstant.apiStoreURL}/getspecvalue`, {
            params: {
                SpecValueLangIds: SpecValueLangId
            }
        })
            .pipe(map(data => {
                return data;
            }));
    }

}
