import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { constEnv } from 'common/constants.env';
import { AuthGuard } from 'common/_helpers/auth.guard';
import { OrdersComponent } from './orders/orders.component';
import { DetailComponent } from "./detail/detail.component";
const ORDER_ENDPOINT = `${constEnv.customerEndPoint}/orders`;
const routes: Routes = [
  {
    path: `${ORDER_ENDPOINT}`,
    children: [
       { path: 'detail',
          children: [
             { path: ':id', component: DetailComponent },
             { path: '', redirectTo: `${ORDER_ENDPOINT}`, pathMatch: 'full'}
          ]
       },
       /*{ path: ':orderId/schedule', component: ScheduleComponent },*/
       { path: '', component: OrdersComponent }
    ],
    canActivate: [AuthGuard]
 },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrdersRoutingModule { }
