import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { constEnv } from 'common/constants.env';
import { map } from 'rxjs/operators';

import { AuthenticationService } from 'common/services/authentication.service';

@Injectable({ providedIn: 'root' })
export class WishlistService {
    currentUserProfile;
    configConstant = constEnv;
    constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
        this.currentUserProfile = this.authenticationService.currentUserProfileValue;
    }

    getWishlist( dataTablesParameters ) {

        let searchValue = ''; let searchBy = '';
        if ( dataTablesParameters.search.value !== '' ) {
            searchValue = dataTablesParameters.search.value;
            searchBy = dataTablesParameters.search.column;
        }

        const pageNo = (dataTablesParameters.start * 1) / (dataTablesParameters.length * 1);

        const searchParams = new HttpParams()
            // .set('searchColumn', searchBy)
            // .set('searchValue', searchValue)
            .set('PageNo', pageNo.toString())
            .set('PageSize', dataTablesParameters.length);
            // .set('sortColumn', '')
            // .set('sortOrder', '');

        return this.http.get(`${this.configConstant.apiStoreURL}/getwhishlist`, {
            params: searchParams
        })
            .pipe(map(data => {
                return data;
            }));
    }
}
