import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { constEnv } from 'common/constants.env';
import { map } from 'rxjs/operators';

import { AuthenticationService } from 'common/services/authentication.service';

@Injectable({ providedIn: 'root' })
export class CategoryService {
    currentUserProfile;
    configConstant = constEnv;
    constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
        this.currentUserProfile = this.authenticationService.currentUserProfileValue;
    }

    getAll( dataTablesParameters ) {

        let searchValue = ''; let searchBy = '';
        if ( dataTablesParameters.search.value !== '' ) {
            searchValue = dataTablesParameters.search.value;
            searchBy = dataTablesParameters.search.column;
        }
        const searchParams = new HttpParams()
            .set('searchColumn', searchBy)
            .set('searchValue', searchValue)
            .set('pageNo', dataTablesParameters.start)
            .set('pageSize', dataTablesParameters.length)
            .set('sortColumn', '')
            .set('sortOrder', '');

        return this.http.get(`${this.configConstant.apiStoreURL}/getallcategories`, {
            params: searchParams
        })
            .pipe(map(data => {
                return data;
            }));
    }

    get( catId ) {
        return this.http.get(`${this.configConstant.apiStoreURL}/getcategory/${catId}`)
            .pipe(map(data => {
                return data;
            }));
    }

    add( formData ) {
        return this.http.post(`${this.configConstant.apiStoreURL}/category/add`, formData)
            .pipe(map(data => {
                return data;
            }));
    }

    update( formData ) {
        return this.http.post(`${this.configConstant.apiStoreURL}/category/update`, formData)
            .pipe(map(data => {
                return data;
            }));
    }

    action( action, formData ) {
        if (action === 'add') {
            return this.add(formData);
        } else {
            return this.update(formData);
        }
    }

    delete(id: number) {
        return this.http.post(`${this.configConstant.apiStoreURL}/category/delete/${id}`, {});
    }

    changeStatus(cateID: number, status: number) {
        return this.http.post(`${this.configConstant.apiStoreURL}/category/status/${cateID}/${status}`, {});
    }

    // sub caregory

    getAllMainCategories( catId = 0 ) {
        return this.http.get(`${this.configConstant.apiStoreURL}/categories`)
            .pipe(map(data => {
                return data;
            }));
    }

    getSubCategoryAll( dataTablesParameters ) {

        let searchValue = ''; let searchBy = '';
        if ( dataTablesParameters.search.value !== '' ) {
            searchValue = dataTablesParameters.search.value;
            searchBy = dataTablesParameters.search.column;
        }
        const pageNo = (dataTablesParameters.start * 1) / (dataTablesParameters.length * 1);
        const searchParams = new HttpParams()
            .set('searchColumn', searchBy)
            .set('searchValue', searchValue)
            .set('pageNo', pageNo.toString())
            .set('pageSize', dataTablesParameters.length)
            .set('sortColumn', '')
            .set('sortOrder', '');

        return this.http.get(`${this.configConstant.apiStoreURL}/getallsubcategories`, {
            params: searchParams
        })
            .pipe(map(data => {
                return data;
            }));
    }

    subCategoryGet( subCatId ) {
        return this.http.get(`${this.configConstant.apiStoreURL}/getsubcategory/${subCatId}`)
            .pipe(map(data => {
                return data;
            }));
    }

    deleteSubCategory(id: number) {
        return this.http.post(`${this.configConstant.apiStoreURL}/subcategory/delete/${id}`, {});
    }

    changeStatusSubCategory(subCateID: number, status: number) {
        return this.http.post(`${this.configConstant.apiStoreURL}/subcategory/status/${subCateID}/${status}`, {});
    }

    subCategoeryAdd( formData ) {
        return this.http.post(`${this.configConstant.apiStoreURL}/subcategory/add`, formData)
            .pipe(map(data => {
                return data;
            }));
    }

    subCategoeryUpdate( formData ) {
        return this.http.post(`${this.configConstant.apiStoreURL}/subcategory/update`, formData)
            .pipe(map(data => {
                return data;
            }));
    }

    subCategoeryAction( action, formData ) {
        if (action === 'add') {
            return this.subCategoeryAdd(formData);
        } else {
            return this.subCategoeryUpdate(formData);
        }
    }
}
