import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { constEnv } from 'common/constants.env';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DeliveryCompanyService {

    configConstant = constEnv;

    apiUrl = this.configConstant.apiDeliveryCompanyURL;

    constructor(private http: HttpClient) {
        // this.apiUrl = this.configConstant.apiDeliveryCompanyURL;
    }

    register(user) {
        const requestHeader = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };

        const requestData = new HttpParams()
            .set('Email', user.email)
            .set('Password', user.password)
            .set('PhoneCode', user.countryCode)
            .set('Phone', user.mobileNo)
            .set('ServiceTypeId', user.ServiceTypeId)
            .set('Name', user.name);

        return this.http.post(`${this.apiUrl}/profile`, requestData, {
            headers: requestHeader
        })
            .pipe(map(data => {
                return data;
            }));
    }

    login(email, password) {
        const requestHeader = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };

        const requestData = new HttpParams()
            .set('Email', email)
            .set('Password', password);

        return this.http.post(`${this.configConstant.apiBaseURL}/deliverycompany/login`, requestData, {
            headers: requestHeader
        })
            .pipe(map(data => {
                console.log(data);
                return data;
            }));
    }

    verifyEmail( token: string ) {
        return this.http.get(`${this.apiUrl}/verify/${token}`)
            .pipe(map(data => {
                return data;
            }));
    }

    forgotPassword(email) {
        const requestHeader = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };

        const requestData = new HttpParams()
            .set('email', email);

        return this.http.post(`${this.apiUrl}/forgotpassword`, requestData, {
            headers: requestHeader,
            params: requestData
        })
            .pipe(map(data => {
                return data;
            }));
    }

    resetPassword(accessToken, newPassword) {
        const requestHeader = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };

        const requestData = new HttpParams()
            .set('AccessToken', accessToken)
            .set('NewPassword', newPassword);

        return this.http.post(`${this.apiUrl}/resetpassword`, requestData, {
            headers: requestHeader
        })
            .pipe(map(data => {
                return data;
            }));
    }
}
