import { DashboardProduct } from 'common/_models/store/dashboard/product';

export class Dashboard {
    mostReturnProductCount: DashboardProduct[];
    orderSalesData: DashboardOrderSalesData[];
    orderStatusCounter: any;
    orderStatusticsData: DashboardOrderStatCounter;
    productCounter: DashboardProductCounter;
    productStockCounter: DashboardProduct[];
    repeatCustomerCounter: DashboardRepeatCustomerCounter[];
    storePromotionsCount: DashboardStorePromotionCount[];
    topSellingProductCount: DashboardProduct[];
    valuableCustomer: DashboardValuableCustomer[];
    furniAgreement: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        this.mostReturnProductCount = input.mostReturnProductCount.map(item =>  new DashboardProduct().deserialize(item) );
        this.productStockCounter = input.productStockCounter.map(item =>  new DashboardProduct().deserialize(item) );
        this.topSellingProductCount = input.topSellingProductCount.map(item =>  new DashboardProduct().deserialize(item) );
        return this;
    }
}

interface DashboardOrderSalesData {
    month: string;
    orderCount: number;
}

// interface DashboardOrderStatusCounter {
//     cancelled: number;
//     completed: number;
//     pending: number;
//     processing: number;
//     refunded: number;
//     total: number;
// }

interface DashboardOrderStatCounter {
    month: string[];
    orderCount: number;
    totalEarning: string;
    value: number[];
}

interface DashboardProductCounter {
    active: number;
    inActive: number;
    total: number;
}

interface DashboardRepeatCustomerCounter {
    oneTime: number;
    repeating: number;
}

interface DashboardStorePromotionCount {
    couponCode: string;
    used: number;
    revenue: string;
}

interface DashboardValuableCustomer {
    first_name: string;
    id: number;
    orderCount: number;
    totalAmount: number;
}
