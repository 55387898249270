import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "common/modules/material.module";
import { RouterModule } from "@angular/router";
import { ComponentModule } from "common/_components/components.module";
import { ModalModule } from "ngx-bootstrap/modal";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

@NgModule({
  declarations: [HeaderComponent, FooterComponent, SidebarComponent],
  imports: [
    CommonModule,
    TranslateModule.forRoot(),
    MaterialModule,
    RouterModule,
    ComponentModule,
    ModalModule.forRoot(),
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
  ],
  exports: [HeaderComponent, FooterComponent, SidebarComponent],
})
export class TemplateDefaultModule {}
