import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { ComponentModule } from 'common/_components/components.module';
import { DataTablesModule } from 'angular-datatables';
import { TemplateDefaultModule } from '../../../shared/template-default/template-default.module';
import { MaterialModule } from 'common/modules/material.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { MyDirectiveModule } from 'common/directives/my-directive.module';
import { ModalModule } from 'ngx-bootstrap/modal';

import { LastMinuteDealRoutingModule } from './last-minute-deal-routing.module';
import { ListComponent } from './list/list.component';
import { ActionComponent } from './action/action.component';


@NgModule({
  declarations: [ListComponent, ActionComponent],
  imports: [
    CommonModule,
    LastMinuteDealRoutingModule,
    RouterModule,
    TranslateModule.forRoot(),
    ReactiveFormsModule,
    ComponentModule,
    ModalModule.forRoot(),
    DataTablesModule,
    TemplateDefaultModule,
    MaterialModule,
    NgSelectModule,
    MyDirectiveModule
  ]
})
export class LastMinuteDealModule { }
