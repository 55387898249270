import { NgModule } from '@angular/core';
import { NumberDirective } from './numbers-only.directive';
import { FloatDirective } from './floats-only.directive';

@NgModule({
  declarations: [NumberDirective, FloatDirective],
  imports: [],
  exports: [
    NumberDirective,
    FloatDirective
  ]
})
export class MyDirectiveModule { }
