import { Component, OnInit, ViewChild } from "@angular/core";

import { constEnv } from "common/constants.env";
import { LoaderService } from "common/services/loader.service";

import { TitleService } from "common/services/title.service";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { first } from "rxjs/operators";
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { AlertService } from "@full-fledged/alerts";
import { IsLoadingService } from "@service-work/is-loading";
import { ModalDirective } from "ngx-bootstrap/modal";
import { OrderService } from "common/services/store/order.service";
import { Earnings } from "common/_models/store/earnings";
import { Paging } from "../../products/list/list.component";
import { DatePipe } from "@angular/common";
import { DataTableDirective } from "angular-datatables";
import { Config } from "datatables.net";
import { CustomValidator } from "common/_helpers/custom.validator";
import { GeneralService } from "common/services/general.service";

@Component({
  selector: "app-earnings",
  templateUrl: "./earnings.component.html",
  styleUrls: ["./earnings.component.css"],
})
export class EarningsComponent implements OnInit {
  // dtOptions: DataTables.Settings = {};
  dtOptions: Config = {};
  // dtOptionsCancelOrder: DataTables.Settings = {};
  dtOptionsCancelOrder: Config = {};

  hasOrders = false;

  @ViewChild("autoShownModal", { static: false })
  autoShownModal: ModalDirective;
  isModalShown = false;

  totalEarnings = "0.00";
  totalOrders = 0;
  totalWithdrawals = "0.00";
  totalAvailableFunds = "0.00";
  totalAvailableFundsWOCC = 0;

  totalCancelOrders = 0;

  lists: Earnings[] = [];

  searchForm: FormGroup;
  withdrawForm: FormGroup;
  maxDate: Date;

  fromDate: Date;
  toDate: Date;
  bsRangeValue: Date[];

  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;

  dateFormat = "yyyy-MM-dd";

  totalCompletedEarnings = 0;
  totalCancelOrdersCount = 0;

  config = constEnv;
  public defaultCurrency = "";

  constructor(
    private ls: LoaderService,
    private titleService: TitleService,
    private fb: FormBuilder,
    private alert: AlertService,
    private isLoadingService: IsLoadingService,
    private order: OrderService,
    private datePipe: DatePipe,
    private gs: GeneralService
  ) {
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate());
  }

  ngOnInit() {
    this.titleService.setTitle("STORE_Earnings");

    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }

    this.defaultCurrency = this.gs.getDefaultCurrency();

    this.toDate = new Date();
    this.fromDate = new Date();
    this.fromDate.setDate(this.fromDate.getDate() - 7);

    this.bsRangeValue = [this.fromDate, this.toDate];

    // const paging = {page: 0, itemsPerPage: 10};
    // this.getEarnings( paging );
    this.makeSearchFrom();
    this.createDataTable();
    this.makeWithdrawForm();
  }

  makeSearchFrom() {
    this.searchForm = this.fb.group({
      orderFilterDates: [],
    });

    // this.searchForm.get('orderFilterDates').setValue( this.bsRangeValue );
  }

  makeWithdrawForm() {
    this.withdrawForm = this.fb.group(
      {
        // withdrawAmount: [null, Validators.required]
        withdrawAmount: new FormControl(null, [
          Validators.required,
          // Validators.pattern("^[1-9]*$"),
        ]),
      },
      { validators: [CustomValidator.OnlyZeroNotAllowed("withdrawAmount")] }
    );
  }

  createDataTable() {
    this.dtOptions = {
      pagingType: "full_numbers",
      ordering: false,
      lengthChange: false,
      searching: false,
      serverSide: true,
      processing: true,
      // pageLength: -1,
      language: {
        paginate: {
          previous: "<",
          first: "<<",
          last: ">>",
          next: ">",
        },
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.hasOrders = true;

        const searchparams = this.searchForm.value;
        if (searchparams.filterText !== "") {
          dataTablesParameters.search.column = searchparams.filterBy;
          dataTablesParameters.search.value = searchparams.filterText;
        }

        dataTablesParameters.search.dateTimeForm = "";
        dataTablesParameters.search.dateTimeTo = "";
        if (searchparams.orderFilterDates !== null) {
          const oDates = searchparams.orderFilterDates;
          dataTablesParameters.search.dateTimeForm = this.datePipe.transform(
            oDates[0],
            this.dateFormat
          );
          dataTablesParameters.search.dateTimeTo = this.datePipe.transform(
            oDates[1],
            this.dateFormat
          );
        }
        let paging = { totalCount: 0, totalRowsAfterFiltering: 0 } as Paging;
        this.order
          .getOrdersEarnings(dataTablesParameters)
          .pipe(first())
          .subscribe(
            (resp) => {
              const data = resp as EarningListResponse;

              if (data.status === true) {
                if (data.data.length > 0) {
                  this.lists = data.data.map((item) =>
                    new Earnings().deserialize(item)
                  );
                  paging = JSON.parse(data.paging) as Paging;
                  this.totalCompletedEarnings = paging.totalCount;
                  this.hasOrders = true;
                }
              } else {
                this.lists = [];
                this.hasOrders = false;
                this.alert.danger(data.message);
              }

              this.totalEarnings = data.totalBalance;
              this.totalOrders = data.orderCount;
              this.totalWithdrawals = data.withdrawFunds;
              this.totalAvailableFunds = data.availableFunds;
              this.totalAvailableFundsWOCC = data.availableFundsWCC;
              this.withdrawForm
                .get("withdrawAmount")
                .setValidators([Validators.max(data.availableFundsWCC)]);

              callback({
                recordsTotal: paging.totalCount,
                recordsFiltered: paging.totalRowsAfterFiltering,
                data: [],
              });

              // this.loader.hideLoader();
            },
            (err) => {
              // this.alert.danger(err.error.Message);
              // this.loader.hideLoader();
            }
          );

        callback({
          recordsTotal: 0,
          recordsFiltered: 0,
          data: [],
        });
      },
    };
  }

  get f() {
    return this.withdrawForm.controls;
  }

  getErrorWithdrawalAmount() {
    return this.withdrawForm.get("withdrawAmount").hasError("required")
      ? ""
      : this.withdrawForm.get("withdrawAmount").hasError("max")
      ? "WithdrawAmountLess"
      : // ? this.titleService.getTranslatedString("WithdrawAmountLess")
      this.withdrawForm.get("withdrawAmount").hasError("zeroError")
      ? "Withdwal amount should not be 0."
      : "";
  }

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
    this.withdrawForm.reset();
  }

  searchSubmit() {
    if (this.searchForm.invalid) {
      return;
    }

    // this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) =>
    this.datatableElement.dtInstance.then((dtInstance) => dtInstance.draw());
  }

  withdrawSubmit() {
    if (this.withdrawForm.invalid) {
      return;
    }

    const formData = {
      amount: this.withdrawForm.value.withdrawAmount,
    };

    this.ls.showLoader();
    this.order
      .sendWithdrawRequest(formData)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data["status"] === true) {
            this.alert.success(data["message"]);
          } else {
            this.alert.danger(data["message"]);
          }
          this.hideModal();
          this.ls.hideLoader();
        },
        (err) => {
          if (typeof err.error !== "undefined") {
            const msg =
              typeof err.error.Message !== "undefined"
                ? err.error.Message
                : err.error.message;
            if (msg !== "") {
              this.alert.danger(err.error.message);
            }
          }
          this.ls.hideLoader();
        }
      );
  }
}

interface EarningListResponse {
  totalBalance: string;
  orderCount: number;
  availableFunds: string;
  withdrawFunds: string;
  availableFundsWCC: number;
  status: boolean;
  message: string;
  data: Earnings[];
  paging: string;
}
