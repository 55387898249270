import { Component, OnInit } from '@angular/core';
import { IsLoadingService } from '@service-work/is-loading';
import { TitleService } from 'common/services/title.service';

@Component({
  selector: 'app-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.css']
})
export class CancelComponent implements OnInit {

  constructor(
    private isLoadingService: IsLoadingService,
    private titleService: TitleService,
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Payment Cancel');

    if ( this.isLoadingService.isLoading$() ) {
      this.isLoadingService.remove();
    }

  }

}
