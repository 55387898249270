import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { constEnv } from 'common/constants.env';
import { map } from 'rxjs/operators';

import { AuthenticationService } from 'common/services/authentication.service';

@Injectable({ providedIn: 'root' })
export class OfferService {
    currentUserProfile;
    configConstant = constEnv;
    constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
        this.currentUserProfile = this.authenticationService.currentUserProfileValue;
    }

    getOffers() {
        return this.http.get(`${this.configConstant.apiStoreURL}/storedealslist`)
            .pipe(map(data => {
                return data;
            }));
    }
}
