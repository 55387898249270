import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { constEnv } from 'common/constants.env';
import { AuthGuard } from 'common/_helpers/auth.guard';

import { ListComponent } from './list/list.component';
import { ActionComponent } from './action/action.component';

const routeBase = `${constEnv.storeEndPoint}/product`;

const routes: Routes = [
   {
      path: `${routeBase}/last-minute-deal`,
      children: [
         {
            path: ':action',
            children: [
               {path: ':id', component: ActionComponent},
               {path: '', component: ActionComponent}
            ]
         },
         {path: '', component: ListComponent}
      ],
      canActivate: [AuthGuard]
   },
   // {path: `${routeStore}/category`, component: ListComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LastMinuteDealRoutingModule { }
