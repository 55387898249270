import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ComponentModule } from 'common/_components/components.module';
import { DataTablesModule } from 'angular-datatables';
import { TemplateDefaultModule } from '../../../shared/template-default/template-default.module';
import { MaterialModule } from 'common/modules/material.module';

import { SpecificationRoutingModule } from './specification-routing.module';
import { ListComponent } from './list/list.component';
import { ValuesComponent } from './values/values.component';


@NgModule({
  declarations: [ListComponent, ValuesComponent],
  imports: [
    CommonModule,    
    RouterModule,
    TranslateModule.forRoot(),
    ModalModule.forRoot(),
    ReactiveFormsModule,
    ComponentModule,
    DataTablesModule,
    TemplateDefaultModule,
    MaterialModule,
    SpecificationRoutingModule
  ]
})
export class SpecificationModule { }
