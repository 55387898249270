import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PagesRoutingModule } from './pages-routing.module';
import { CmsComponent } from './cms/cms.component';

import { TemplateModule } from '../template/template.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings, RecaptchaFormsModule } from 'ng-recaptcha';
import { MaterialModule } from 'common/modules/material.module';
import { MyDirectiveModule } from 'common/directives/my-directive.module';

import { FaqComponent } from './faq/faq.component';
import { ContactComponent } from './contact/contact.component';
import { constEnv } from 'common/constants.env';


@NgModule({
  declarations: [CmsComponent, FaqComponent, ContactComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    CommonModule,
    PagesRoutingModule,
    TranslateModule.forRoot(),
    MaterialModule,
    MyDirectiveModule,
    TemplateModule
  ],
  providers: [{
    provide: RECAPTCHA_SETTINGS,
    useValue: {
      siteKey: constEnv.googleSiteKey,
    } as RecaptchaSettings,
  }]
})
export class PagesModule { }
