import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ProfileRoutingModule } from "./profile-routing.module";

import { TemplateDefaultModule } from "../../../shared/template-default/template-default.module";
import { ProfileComponent } from "./profile/profile.component";
import { InnerNavComponent } from "./inner-nav/inner-nav.component";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "common/modules/material.module";
import { ReactiveFormsModule } from "@angular/forms";
import { Ng2TelInputModule } from "ng2-tel-input";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { NgSelectModule } from "@ng-select/ng-select";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { MyDirectiveModule } from "common/directives/my-directive.module";

@NgModule({
  declarations: [ProfileComponent, InnerNavComponent, ChangePasswordComponent],
  imports: [
    CommonModule,
    ProfileRoutingModule,
    TemplateDefaultModule,
    TranslateModule,
    RouterModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    Ng2TelInputModule,
    NgxIntlTelInputModule,
    FormsModule,
    NgSelectModule,
    MyDirectiveModule,
  ],
})
export class ProfileModule {}
