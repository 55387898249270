import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { TitleService } from "common/services/title.service";
import { DOCUMENT, JsonPipe } from "@angular/common";
import { GeneralService } from "common/services/general.service";
import { IsLoadingService } from "@service-work/is-loading";

@Component({
  selector: "app-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.css"],
})
export class FaqComponent implements OnInit, OnDestroy {
  faqCategories = [];
  faqLists = [];

  constructor(
    private titleService: TitleService,
    @Inject(DOCUMENT) private document: Document,
    private isLoadingService: IsLoadingService,
    private general: GeneralService
  ) {}

  ngOnInit() {
    this.document.body.classList.add("front-body");
    this.titleService.setTitle("FAQ");
    if (this.isLoadingService.isLoading$()) {
      this.isLoadingService.remove();
    }

    this.loadFaq();
  }

  ngOnDestroy(): void {
    this.document.body.classList.remove("front-body");
  }

  loadFaq() {
    this.isLoadingService.add();

    this.general.getFaq().subscribe(
      (response) => {
        if (response["status"] === true) {
          const catIds = [];
          response["data"].forEach((single, index) => {
            if (catIds.indexOf(single.catId) === -1) {
              this.faqCategories.push({
                categoryName: single.categoryName,
                categoryId: single.catId,
              });

              this.faqLists[single.catId] = [];
            }
            this.faqLists[single.catId].push(single);

            catIds.push(single.catId);
          });
          this.faqLists = this.faqLists.filter(
            (value) => JSON.stringify(value) !== "{}"
          );
        }
        this.isLoadingService.remove();
      },
      (err) => {
        this.isLoadingService.remove();
      }
    );
  }

  faqCategoryClick(faqCategory) {
    const categoryId = faqCategory.categoryId;

    const flatData = this.faqLists.reduce((acc, val) => acc.concat(val), []);

    // this.selectedCategory = categoryId;
    // this.faqListByCategory = this.faqLists.filter((innerArray) =>
    //   innerArray.some((item) => item.catId === categoryId)
    // );
    // console.log(flatData);
    const selectedCategoryFaqs = flatData.filter(
      (item) => item.catId === categoryId
    );
    // console.log(selectedCategoryFaqs);
    return selectedCategoryFaqs;
  }
}
