import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from 'common/_helpers/auth.guard';
import { ListComponent } from './list/list.component';
import { ValuesComponent } from './values/values.component';
import { constEnv } from 'common/constants.env';

const routeBase = `${constEnv.storeEndPoint}/product`;

const routes: Routes = [
   {
      path: `${routeBase}/specification`,
      children: [
         {
            path: ':specid',
            children: [
               {path: 'values', component: ValuesComponent},
               {path: '', component: ValuesComponent}
            ]
         },
         {path: '', component: ListComponent}
      ],
      canActivate: [AuthGuard]
   },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SpecificationRoutingModule { }
