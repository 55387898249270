import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { constEnv } from "common/constants.env";
import { AuthGuard } from "common/_helpers/auth.guard";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { AerialViewComponent } from "./aerial-view/aerial-view.component";
import { FeedbackComponent } from "./feedback/feedback.component";
import { PrefrredDeliveryCompanyComponent } from "./prefrred-delivery-company/prefrred-delivery-company.component";
import { DeliveryZoneComponent } from "./delivery-zone/delivery-zone.component";

const STORE_ENDPOINT = constEnv.storeEndPoint;

const routes: Routes = [
  {
    path: `${STORE_ENDPOINT}`,
    children: [
      { path: "dashboard", component: DashboardComponent },
      { path: "aerial-view", component: AerialViewComponent },
      {
        path: "prefrred-delivery-company",
        component: PrefrredDeliveryCompanyComponent,
      },
      {
        path: "delivery-zone",
        component: DeliveryZoneComponent,
      },
      {
        path: "feedback",
        children: [
          { path: ":type", component: FeedbackComponent },
          { path: "", component: FeedbackComponent },
        ],
      },
      { path: "", redirectTo: `dashboard`, pathMatch: "full" },
    ],
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
