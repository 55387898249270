import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthenticationService } from "common/services/authentication.service";

import { constEnv } from "common/constants.env";
import { AlertService } from "@full-fledged/alerts";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  config = constEnv;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
      // authorised so return true
      const currentUserDetail = currentUser.userdetails;
      // console.log(currentUserDetail);
      const userType = currentUserDetail.userType;

      let redirectUrl = "";
      if (state.url.indexOf(userType) === -1) {
        switch (userType) {
          case "driver":
            redirectUrl = this.config.DRIVER_URL + "/dashboard";
            break;
          case "store":
            redirectUrl = this.config.STORE_URL + "/dashboard";
            break;
          case "designer":
            window.location.href =
              this.config.deliveryCompanyActionUrl + "/DesignerProfile/View";
            // redirectUrl = this.config.deliveryCompanyActionUrl;
            break;
          default:
            redirectUrl = this.config.BASE_URL;
            break;
        }

        this.router.navigate([`/${redirectUrl}`]);
      }

      if (
        currentUserDetail.isFirstimeLogin &&
        state.url.indexOf(
          `${this.config.STORE_URL}/settings/change-password`
        ) == -1
      ) {
        if (userType == "store") {
          redirectUrl = `${this.config.STORE_URL}/settings/change-password`;
          this.alertService.danger("Please change your password first.");
          this.router.navigate([`/${redirectUrl}`]);
          return false;
        }
      }

      if (
        currentUserDetail.isFirstimeLogin &&
        state.url.indexOf(`${this.config.CUSTOMER_URL}/change-password`) == -1
      ) {
        if (userType == "customer") {
          redirectUrl = `${this.config.CUSTOMER_URL}/change-password`;
          this.alertService.danger("Please change your password first.");
          this.router.navigate([`/${redirectUrl}`]);
          return false;
        }
      }

      return true;
    }

    let myBaseURL = "";
    if (state.url.indexOf(this.config.storeEndPoint) > -1) {
      myBaseURL = this.config.STORE_URL;
    } else if (state.url.indexOf(this.config.driverEndPoint) > -1) {
      myBaseURL = this.config.DRIVER_URL;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate([`/${myBaseURL}/login`], {
      queryParams: { returnUrl: state.url },
    });
    return false;
  }
}
