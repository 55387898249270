import { Component, OnInit } from "@angular/core";
import { GeneralService } from "common/services/general.service";
import { constEnv } from "common/constants.env";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "common/services/authentication.service";
import { LanguageService } from "common/services/language.service";
import { IsLoadingService } from "@service-work/is-loading";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  logoUrl;
  siteTitle;

  config = constEnv;

  constructor(
    private gs: GeneralService,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private langService: LanguageService,
    private isLoadingService: IsLoadingService
  ) {}

  ngOnInit() {
    this.logoUrl = this.gs.getSiteLogo(true);
    this.siteTitle = this.config.projectTitle;
  }

  logout() {
    this.isLoadingService.add();
    // this.langService.changeLanguage(this.langService.defaultLanguage);
    this.authenticationService.logout();
    const loginURL = `${this.config.STORE_URL}/login`;

    const settingLang = this.gs.getSettings("default_language");

    const changeLangCode =
      settingLang !== "" ? settingLang : this.langService.defaultLanguage;

    this.langService.getAvailableLanguages().map((lang) => {
      if (changeLangCode === lang.lang_code) {
        this.langService._setLanguage(lang.lang_code);
        localStorage.setItem("defaultLang", JSON.stringify(lang));
      }
    });

    this.langService
      .getLanguageTranslation(this.langService.getCurrentLanguage())
      .subscribe((translationsJSON) => {
        this.langService.setLanguageTranslation(translationsJSON);
        window.location.href = loginURL;
      });
  }
}
