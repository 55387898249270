import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { RoleGuard } from "common/_helpers/role.guard";
import { CatalogDetailComponent } from "./catalog-detail/catalog-detail.component";
import { ListComponent } from "./list/list.component";

const routes: Routes = [
  {
    path: "designer-catalog",
    children: [
      { path: ":catalogId", component: CatalogDetailComponent },
      { path: "", component: ListComponent },
    ],
    canActivate: [RoleGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DesignerCatalogRoutingModule {}
